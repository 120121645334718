import React from "react";
import ReactDOM from "react-dom";
import { sortBy } from "lodash";
import { Media } from "../../common/Media";
import { SliderPreview } from "../../common/SliderPreview";
import * as analytics from "utils/analytics";

export class FeedbackMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreviewMode: false,
      attachment: {},
    };
  }

  openPreview = (attachment) => {
    this.setState({
      isPreviewMode: true,
      attachment: attachment,
    });
    analytics.logMediaPreviewModeOpen(attachment.attachment);
  };

  closePreview = () => {
    this.setState({
      isPreviewMode: false,
    });
    analytics.logMediaPreviewModeClose();
  };

  render() {
    let { attachments, isCompareMode } = this.props;
    const { isPreviewMode } = this.state;

    attachments = sortBy(attachments, [
      function (a) {
        return a.attachment_type === "AU";
      },
    ]);

    return (
      <div className="feedback-media">
        {attachments.map((attachment) => (
          <Media
            attachment={attachment}
            openPreview={this.openPreview}
            mode="thumbnail"
            isCompareMode={isCompareMode}
          />
        ))}
        {isPreviewMode &&
          ReactDOM.createPortal(
            <SliderPreview
              {...this.props}
              {...this.state}
              closePreview={this.closePreview}
            />,
            window.portalContainer,
          )}
      </div>
    );
  }
}

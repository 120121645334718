import styled, { css } from "styled-components";

export const ToolTip = styled.span`
  background-color: #133b7d;
  padding: 5px 4px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: var(--text-color);
  position: absolute;
  max-width: 270px;
  right: 0;
  display: none;
  box-shadow: #00000014 0px 3px 6px;
  margin-top: 5px;
  &::after {
    content: "▼";
    position: absolute;
    top: 70%;
    font-size: 17px;
    color: #133b7d;
    right: 88%;
  }
`;

export const FormInput = styled.input`
  transition: all 100ms;
  border-radius: 3px;
  background-color: var(--text-color);
  border: 1px solid ${(props) => (props.isError ? "red" : "#e9e9e9")};
  padding: 0 26px;
  height: ${({ height }) => (height ? height : 46)}px;
  font-size: 14px;
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  color: #242424;
  &:hover {
    border-color: ${(props) =>
      props.disabled ? "#e9e9e9" : "rgb(179, 179, 179)"};
  }
  &:hover ${ToolTip} {
    display: block;
    bottom: -65%;
    left: 4%;
    margin-left: 20px;
  }
  ${(props) =>
    props.placeholder &&
    css`
      ::placeholder {
        color: #d3d3d3;
        font-family: "SegoeUI", Arial, Helvetica, sans-serif;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      border: 2px solid #f33;
    `}
`;

export const Label = styled.label`
  font-size: 12px;
  color: #666666;
  position: absolute;
  left: 20px;
  margin-bottom: 0;
  top: ${({ topLabelPosition }) =>
    topLabelPosition ? topLabelPosition : "-9"}px;
  background: rgb(255 255 255);
  padding: 0 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")};
  position: relative;
  margin-top: 40px;
  &:hover ${ToolTip} {
    display: block;
    top: -40px;
    right: 20px;
    transition: 0.5s ease-in-out right;
  }
`;

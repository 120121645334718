import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { CustomDropdown } from "components/common/Dropdown";
import { DropdownContainer } from "components/common/DropdownContainer";

import { setAnalysisType } from "components/common/filters/actions";

const TransactionalFilter = () => {
  const analysisType = useSelector(
    (state) => state.selectedFilters.analysisType,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAnalysisType(AnalysisTypes[0].type));
  }, []);

  const selectedAnalysis =
    AnalysisTypes.find((analysis) => analysis.type === analysisType) ||
    AnalysisTypes[0];

  return (
    <Container>
      <DropdownContainer>
        <CustomDropdown
          options={AnalysisTypes}
          getLabel={(o) => o.label}
          getKey={(o) => o.type}
          selectedOption={selectedAnalysis}
          onClick={(o) => dispatch(setAnalysisType(o.type))}
        />
      </DropdownContainer>
    </Container>
  );
};

const AnalysisTypes = [
  { type: "sales", label: "Total Sales" },
  { type: "average_invoice", label: "Average Invoice Value" },
  { type: "invoice_count", label: "Total Invoices" },
  { type: "customer_count", label: "Customer Count" },
];

export default TransactionalFilter;

const Container = styled.div`
  margin: 15px;
`;

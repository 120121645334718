import React from "react";
import { RowDiv } from "./styled";
import { useSelector } from "react-redux";

import * as editors from "./editors";
import * as views from "./views";

import { formModeSelector } from "components/RCA/selectors";

const Section = ({ section, handleNext, isError }) => {
  const mode = useSelector(formModeSelector);
  const SectionComponent = sections[mode][section.section_type];
  return (
    <RowDiv>
      <SectionComponent
        isError={isError}
        section={section}
        fields={section.rca_fields}
        handleNext={handleNext}
      />
    </RowDiv>
  );
};

const sections = {
  VIEW: {
    CUSTOM: views.CustomSection,
    ROOT_CAUSE: views.RootCausesSection,
    DEPLOYMENT_APPROVAL: views.CustomSection,
  },
  EDIT: {
    CUSTOM: editors.CustomSection,
    ROOT_CAUSE: editors.RootCausesSection,
    DEPLOYMENT_APPROVAL: editors.DeploymentApprovalSection,
  },
};

export default Section;

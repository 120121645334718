import { call, takeLatest, select, put } from "redux-saga/effects";
import * as actions from "./actions";
import * as apis from "./apis";
import * as constants from "./constants";
import { getOrganizationId } from "../Organization/selectors";
import { getQuestionnaire } from "./selectors";
import { validateSurvey } from "./validators";

function* submitQuestionnaire({ surveyId }) {
  try {
    const organizationId = yield select(getOrganizationId);
    let questionnaire = yield select(getQuestionnaire);

    const errors = validateSurvey(questionnaire);
    if (errors) {
      throw errors;
    }

    const response = yield call(
      apis.createUpdateSurveyApi,
      organizationId,
      questionnaire,
      surveyId,
    );

    if (!response.id) {
      throw response.data;
    }

    yield put(actions.submitQuestionnaireSuccess(response));
  } catch (error) {
    yield put(actions.submitQuestionnaireError(error));
  }
}

function* submitQuestionnaireWatcher() {
  yield takeLatest(constants.SB_SUBMIT_QUESTIONNAIRE, submitQuestionnaire);
}

function* getSurveyConfigs() {
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(apis.getSurveyConfigApi, organizationId);
    yield put(actions.getSurveyConfigsSuccess(response));
  } catch (error) {
    yield put(actions.getSurveyConfigsError(error));
  }
}

function* getSurveyConfigsWatcher() {
  yield takeLatest(constants.SB_GET_SURVEY_CONFIGS, getSurveyConfigs);
}

function* getSingleSurvey({ surveyId }) {
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(
      apis.getSingleSurveyApi,
      organizationId,
      surveyId,
    );
    yield put(actions.getQuestionnaireSuccess(response));
  } catch (error) {
    yield put(actions.getQuestionnaireError(error));
  }
}

function* getSingleSurveyWatcher() {
  yield takeLatest(constants.SB_GET_QUESTIONNAIRE, getSingleSurvey);
}

function* getSurveyList() {
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(apis.getSurveyList, organizationId, null, true);
    yield put(actions.getQuestionnaireListSuccess(response));
  } catch (error) {
    yield put(actions.getQuestionnaireListError(error));
  }
}

function* getSurveyListWatcher() {
  yield takeLatest(constants.SB_GET_QUESTIONNAIRE_LIST, getSurveyList);
}

function* getSurveys(action) {
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(
      apis.getSurveyList,
      organizationId,
      action.page,
    );
    yield put(actions.getAllSurveysSuccess(response));
  } catch (error) {
    yield put(actions.getAllSurveysError(error));
  }
}

function* getSurveysWatcher() {
  yield takeLatest(constants.SB_GET_ALL_SURVEYS, getSurveys);
}

function* toggleSurveyStatus(action) {
  const { surveyId, data } = action;
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(
      apis.createUpdateSurveyApi,
      organizationId,
      data,
      surveyId,
    );
    yield put(actions.toggleSurveyStatusSuccess(surveyId, response));
  } catch (error) {
    yield put(actions.toggleSurveyStatusError(error));
  }
}

function* toggleSurveyStatusWatcher() {
  yield takeLatest(constants.SB_TOGGLE_SURVEY_STATUS, toggleSurveyStatus);
}

export const sagas = [
  submitQuestionnaireWatcher,
  getSurveyConfigsWatcher,
  getSingleSurveyWatcher,
  getSurveyListWatcher,
  getSurveysWatcher,
  toggleSurveyStatusWatcher,
];

import { format } from "date-fns";
import de from "date-fns/locale/de";
import pl from "date-fns/locale/pl";
import i18n from "../i18n";

const localMap = {
  de: { locale: de, dateFormat: "d.MM.yyyy, H:mm 'Uhr'" },
  pl: { locale: pl, dateFormat: "d.MM.yyyy 'godz.' H:mm" },
  en: {},
};

export const getFormatedDate = (date, dateFormat = undefined) => {
  dateFormat =
    dateFormat ||
    localMap[i18n.language].dateFormat ||
    "EEE, MMM d, yyyy h:mm aa";
  return format(date, dateFormat, { ...localMap[i18n.language] });
};

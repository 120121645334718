import { getRequest, postRequest } from "../../utils/request";

// const updateUserDetail = (data) => {
//     axios
//         .put('v1/users/detail', data, {'Content-Type': 'multipart/form-data'})
//         .then(
//           res => {
//             dispatch(userDetailSuccess(res.data))
//             dispatch(getLoggedInUser(res.data.id))

//             if (unAuth) {
//               localStorage.removeItem('userId')
//               localStorage.removeItem('tokenExpiresAt')

//               dispatch({type: UNAUTH_USER})
//             } else {
//               return res.data
//             }
//           }
//         )
//     .catch(err => {
//         dispatch(userDetailFail(err.response.data))
//     })
// }

export const getUserDetail = (uuid) =>
  getRequest(`/v1/users/setup-profile/${uuid}`);

export const updateUserDetail = (uuid, payload) =>
  postRequest(`/v1/users/setup-profile/${uuid}`, payload);

import { startOfDay } from "date-fns";
import TimePickerOptions from "./TimePickerOptions";

export const getTimeOptionSinceDayStart = (day) => {
  const date = startOfDay(day).valueOf();
  let time = day - date;

  let timeOption =
    TimePickerOptions.find(() => {
      time -= 15 * 60 * 1000;
      return time < 0;
    }) || TimePickerOptions[TimePickerOptions.length - 1];

  return [timeOption, date];
};

export const timeOptionToMilliseconds = (time) => {
  return time.index * 1000 * 60 * 15;
};

export const getValidEndTime = (updatedStartTime, currentEndTime) => {
  let updatedEndTimeIdx = currentEndTime.index;
  if (updatedStartTime.index + 1 > currentEndTime.index) {
    updatedEndTimeIdx = updatedStartTime.index + DEFAULT_DELTA;
    if (updatedEndTimeIdx >= TimePickerOptions.length)
      updatedEndTimeIdx = TimePickerOptions.length - 1;
  }
  return TimePickerOptions[updatedEndTimeIdx];
};

export const MILLISECONDS_IN_A_DAY = 86399999;

const DEFAULT_DELTA = 4;

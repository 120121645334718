import styled, { css } from "styled-components";
import {
  displayFlex,
  flexAlign,
  flexJustify,
  flexDirection,
} from "../../../ui/utils";

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  bottom: 0px;
  right: 0px;
  top: 0px;
  margin-top: -10px;
  width: 100%;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 60%;
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("flex-start")}
  flex-wrap: wrap;
`;

export const TagsRow = styled.div`
  display: flex;
  max-width: 500px;
  overflow-x: scroll;
  flex-direction: row;
`;

export const AddedTag = styled.div`
  color: white;
  padding: 2px 7px;
  margin-left: 10px;
  white-space: nowrap;
  background-color: ${(props) => props.color};
`;

export const TagSelect = styled.div`
  display: inline-block;
  z-index: 10;
  top: 46px;
`;

export const TagInput = styled.input`
  border: none;
  z-index: 10;
  padding: 10px 0;
  background-color: transparent;

  &:before {
    content: "+";
  }

  &::placeholder {
    opacity: 1;
    text-align: right;
    color: var(--primary-color);
  }

  &:hover {
    &::placeholder {
      text-decoration: underline;
    }
  }

  ${(props) =>
    props.focus &&
    css`
      &::placeholder {
        color: #4d565c;
        opacity: 0.5;
        text-align: left;
        text-decoration: none;
      }
      padding: 10px 13px;
      background-color: #fff;
      box-shadow: rgba(34, 36, 38, 0.15) 0px 5px 10px 0px;
    `}
`;

export const DropdownTagContainer = styled.div`
  width: 100%;
  max-height: 220px;
  overflow-y: scroll;
  cursor: pointer;
  line-height: 1em;
  position: absolute;
  background-color: #fff;
  box-shadow: rgba(34, 36, 38, 0.15) 0px 5px 10px 0px;
  display: ${(props) => (props.open ? "block" : "none")};
`;

export const DropdownTagItem = styled.div`
  font-size: 0.9em;
  padding: 10px 13px;
  border-bottom: solid 1px rgb(241, 241, 243);
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  color: ${(props) => (props.isSelected ? "white" : "#4d565c")};

  ${(props) =>
    props.isSelected
      ? css`
          background-color: var(--primary-color);
        `
      : css`
          &:hover {
            background-color: rgb(239, 239, 239);
          }
        `}
`;

export const FilterDropdownItem = styled.button`
  display: block;
  width: 100%;
  padding: 0.375em 1em;
  clear: both;
  font-weight: 400;
  color: ${(props) => (props.selected ? "#fff" : "#707070")};
  text-align: inherit;
  background-color: ${(props) =>
    props.selected ? "var(--primary-color)" : "transparent"};
  border: 0;

  &:hover,
  &.selected {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const FilterItemRemove = styled.div`
  position: relative;
  background: none;
  border: none;
  width: 18px;
  height: 13px;
  line-height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-10px, 0, 0);
  display: inline-block;
  width: 0;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "x";
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    line-height: 100%;
    font-weight: 500;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  }
`;

export const FilterItem = styled.button`
  border: none;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgb(112 112 112 / 0.35)"};
  color: #ffffff;
  padding: 6px 12px;
  transition: all 0.3s ease-in-out;
  margin: 5px 0;
  margin-left: 10px;

  &:hover > ${FilterItemRemove} {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0px, 0, 0);
    width: 10px;
    margin-left: 15px;
  }
`;

export const FilterList = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexJustify("flex-end")}
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;

  &:hover > ${FilterItem}:not(:hover) {
    background-color: rgb(112 112 112 / 0.35);
  }
`;

export const FilterType = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("center")}
  ${flexJustify("flex-start")}
  position: relative;
  transition: all 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    background-color: rgb(180 181 181 / 0.18);
  }
`;

import * as constants from "./constants";

export const toggleSurveyStatus = (orgid, data, surveyId) => ({
  type: constants.SB_TOGGLE_SURVEY_STATUS,
  orgid,
  data,
  surveyId,
});

export const toggleSurveyStatusSuccess = (surveyId, data) => ({
  type: constants.SB_TOGGLE_SURVEY_STATUS_SUCCESS,
  surveyId,
  data,
});

export const toggleSurveyStatusError = (surveyId, error) => ({
  type: constants.SB_TOGGLE_SURVEY_STATUS_ERROR,
  surveyId,
  error,
});

export const changeSurveyPage = (page) => ({
  type: constants.SB_CHANGE_SURVEY_LIST_PAGE,
  page,
});

export const getAllSurveys = (orgId, page = 1) => ({
  type: constants.SB_GET_ALL_SURVEYS,
  orgId,
  page,
});

export const getAllSurveysSuccess = (data) => ({
  type: constants.SB_GET_ALL_SURVEYS_SUCCESS,
  data,
});

export const getAllSurveysError = (error) => ({
  type: constants.SB_GET_ALL_SURVEYS_ERROR,
  error,
});

export const setActiveTab = (tabKey) => {
  return {
    type: constants.SB_SET_ACTIVE_TAB,
    tabKey,
  };
};

export const setQuestionnaire = (survey) => {
  return {
    type: constants.SB_SET_QUESTIONNAIRE,
    payload: { survey },
  };
};

export const setQuestionnaireSelectedState = (surveySelectedState) => {
  return {
    type: constants.SB_SET_QUESTIONNAIRE_SELECTED_STATE,
    payload: { surveySelectedState },
  };
};

export const setQuestionnaireAntiSpammer = (criteriaName, isActive) => {
  return {
    type: constants.SB_SET_QUESTIONNAIRE_ANTI_SPAMMER,
    payload: { criteriaName, isActive },
  };
};

export const setQuestionnaireField = (field, val) => {
  return {
    type: constants.SB_SET_QUESTIONNAIRE_FIELD,
    payload: { field, val },
  };
};

export const removeQuestionnaireImage = (field) => {
  return {
    type: constants.SB_REMOVE_QUESTIONNAIRE_IMAGE,
    field,
  };
};

export const addQuestionForm = () => {
  return {
    type: constants.SB_ADD_QUESTION_FORM,
  };
};

export const setQuestionField = (field, val, questionVirtualId) => {
  return {
    type: constants.SB_SET_QUESTION_FIELD,
    payload: { field, val, questionVirtualId },
  };
};

export const setQuestionImage = (file, questionVirtualId) => ({
  type: constants.SB_SET_QUESTION_IMAGE,
  payload: {
    file,
    questionVirtualId,
  },
});

export const setQuestionImagePosition = (
  position,
  questionVirtualId,
  imageIndex,
) => ({
  type: constants.SB_SET_QUESTION_IMAGE_POSITION,
  payload: {
    position,
    imageIndex,
    questionVirtualId,
  },
});

export const setSurveyColors = (field, val) => {
  return {
    type: constants.SB_SET_SURVEY_COLORS,
    payload: { field, val },
  };
};

export const setSurveyFontStyles = (field, val) => {
  return {
    type: constants.SB_SET_SURVEY_FONT_STYLES,
    payload: { field, val },
  };
};

export const setSurveyFontSizes = (field, val) => {
  return {
    type: constants.SB_SET_SURVEY_FONT_SIZES,
    payload: { field, val },
  };
};

export const removeQuestionForm = (questionVirtualId) => {
  return {
    type: constants.SB_REMOVE_QUESTION_FORM,
    payload: { questionVirtualId },
  };
};

export const removeQuestionImage = (questionVirtualId, imageIndex) => ({
  type: constants.SB_REMOVE_QUESTION_IMAGE,
  payload: {
    questionVirtualId,
    imageIndex,
  },
});

export const setQuestionPosition = (oldQuestionIndex, newQuestionIndex) => ({
  type: constants.SB_SET_QUESTION_POSITION,
  payload: {
    oldQuestionIndex,
    newQuestionIndex,
  },
});

export const addOptionForm = (questionVirtualId) => ({
  type: constants.SB_ADD_OPTION_FORM,
  payload: { questionVirtualId },
});

export const addSubOptionForm = (questionVirtualId, optionVirtualId) => {
  return {
    type: constants.SB_ADD_SUB_OPTION_FORM,
    payload: { questionVirtualId, optionVirtualId },
  };
};

export const removeOptionForm = (questionVirtualId, optionVirtualId) => {
  return {
    type: constants.SB_REMOVE_OPTION_FORM,
    payload: { questionVirtualId, optionVirtualId },
  };
};

export const setOptionPosition = (
  questionVirtualId,
  oldOptionIndex,
  newOptionIndex,
) => ({
  type: constants.SB_SET_OPTION_POSITION,
  payload: {
    questionVirtualId,
    oldOptionIndex,
    newOptionIndex,
  },
});

export const removeSubOptionForm = (
  questionVirtualId,
  optionVirtualId,
  subOptionVirtualId,
) => {
  return {
    type: constants.SB_REMOVE_SUB_OPTION_FORM,
    payload: { questionVirtualId, optionVirtualId, subOptionVirtualId },
  };
};

export const setSubOptionPosition = (
  questionVirtualId,
  optionVirtualId,
  oldSubOptionIndex,
  newSubOptionIndex,
) => ({
  type: constants.SB_SET_SUB_OPTION_POSITION,
  payload: {
    questionVirtualId,
    optionVirtualId,
    oldSubOptionIndex,
    newSubOptionIndex,
  },
});

export const removeOptionImage = (questionVirtualId, optionVirtualId) => {
  return {
    type: constants.SB_REMOVE_OPTION_IMAGE,
    questionVirtualId,
    optionVirtualId,
  };
};

export const setOptionField = (
  field,
  val,
  questionVirtualId,
  optionVirtualId,
) => {
  return {
    type: constants.SB_SET_OPTION_FIELD,
    payload: { field, val, questionVirtualId, optionVirtualId },
  };
};

export const setSubOptionField = (
  field,
  val,
  questionVirtualId,
  optionVirtualId,
  subOptionVirtualId,
) => {
  return {
    type: constants.SB_SET_SUB_OPTION_FIELD,
    payload: {
      field,
      val,
      questionVirtualId,
      optionVirtualId,
      subOptionVirtualId,
    },
  };
};

export const resetQuestionnaire = () => ({
  type: constants.SB_RESET_QUESTIONNAIRE,
});

export const resetQuestionnaireSuccess = () => ({
  type: constants.SB_RESET_QUESTIONNAIRE_SUCCESS,
});

export const resetQuestionnaireError = () => ({
  type: constants.SB_RESET_QUESTIONNAIRE_ERROR,
});

export const submitQuestionnaire = (surveyId) => ({
  type: constants.SB_SUBMIT_QUESTIONNAIRE,
  surveyId,
});

export const submitQuestionnaireSuccess = (questionnaire) => ({
  type: constants.SB_SUBMIT_QUESTIONNAIRE_SUCCESS,
  questionnaire,
});

export const submitQuestionnaireError = (error) => ({
  type: constants.SB_SUBMIT_QUESTIONNAIRE_ERROR,
  error,
});

export const getSurveyConfigs = () => ({
  type: constants.SB_GET_SURVEY_CONFIGS,
});

export const getSurveyConfigsSuccess = (config) => ({
  type: constants.SB_GET_SURVEY_CONFIGS_SUCCESS,
  config,
});

export const getSurveyConfigsError = (error) => ({
  type: constants.SB_GET_SURVEY_CONFIGS_ERROR,
  error,
});

export const setSelectedQuestionVirtualId = (questionVirtualId) => {
  return {
    type: constants.SB_SET_SELECTED_QUESTION_VIRTUAL_ID,
    questionVirtualId,
  };
};

export const setSelectedOptionVirtualId = (optionVirtualId) => {
  return {
    type: constants.SB_SET_SELECTED_OPTION_VIRTUAL_ID,
    optionVirtualId,
  };
};

export const setSelectedSubOptionVirtualId = (subOptionVirtualId) => {
  return {
    type: constants.SB_SET_SELECTED_SUB_OPTION_VIRTUAL_ID,
    subOptionVirtualId,
  };
};

export const getQuestionnaire = (surveyId) => ({
  type: constants.SB_GET_QUESTIONNAIRE,
  surveyId,
});

export const getQuestionnaireSuccess = (questionnaire) => ({
  type: constants.SB_GET_QUESTIONNAIRE_SUCCESS,
  questionnaire,
});

export const getQuestionnaireError = (error) => ({
  type: constants.SB_GET_QUESTIONNAIRE_ERROR,
  error,
});

export const getQuestionnaireList = () => ({
  type: constants.SB_GET_QUESTIONNAIRE_LIST,
});

export const getQuestionnaireListSuccess = (surveys) => ({
  type: constants.SB_GET_QUESTIONNAIRE_LIST_SUCCESS,
  surveys,
});

export const getQuestionnaireListError = (error) => ({
  type: constants.SB_GET_QUESTIONNAIRE_LIST_ERROR,
  error,
});

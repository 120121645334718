import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { saveRCAForm, setFormFieldValue } from "components/RCA/actions";
import { formFieldValueSelector } from "components/RCA/selectors";

import { RCA_RATING_CHOICES } from "constants/constants";

import {
  Container,
  SectionBody,
  SectionTitle,
  RatingComponent,
} from "./styled";

const Rating = ({ clickable }) => {
  const dispatch = useDispatch();
  const currentRating = useSelector((state) =>
    formFieldValueSelector(state, "rating"),
  );

  const changeRating = (choice) => {
    dispatch(setFormFieldValue("rating", choice));
    dispatch(saveRCAForm());
  };

  const style = (choice) =>
    choice.label == currentRating?.label
      ? { backgroundColor: "#133B7D", color: "white" }
      : null;

  const title = clickable ? "Rate this Initiative" : "Initiative Rating";
  return (
    <Container>
      <SectionTitle>{title}</SectionTitle>
      <SectionBody>
        {RCA_RATING_CHOICES.map((choice) => (
          <RatingComponent
            clickable={clickable}
            style={style(choice)}
            onClick={clickable ? () => changeRating(choice) : null}
          >
            {choice.label}
          </RatingComponent>
        ))}
      </SectionBody>
    </Container>
  );
};

export default Rating;

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-custom-scroll/dist/customScroll.css";
import "services/firebase/firebase-messaging-sw-client";

import {
  ArcElement, BarElement, CategoryScale, Chart as ChartJS,
  Legend, LineElement, LinearScale, PointElement, Tooltip
} from "chart.js";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "./assets/stylesheets/app.css";
import "./i18n";

import App from "./components/App";
import { persistor, store } from "./store/store";
import ToastProvider from "components/common/Toast";
import { registerServiceWorker } from "serviceWorker";
import * as analytics from "utils/analytics";
import { AxiosInterceptor } from "utils/request";

ChartJS.register(
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
);

ChartJS.defaults.plugins.legend.position = "bottom";
ChartJS.defaults.plugins.legend.labels.boxWidth = 12;

analytics.initializeAnalytics(process.env.REACT_APP_ENV);

render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AxiosInterceptor>
          <ToastProvider />
          <App />
        </AxiosInterceptor>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root"),
);

registerServiceWorker();

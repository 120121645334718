import React, { useEffect } from "react";
import ReactWordcloud from "react-wordcloud";
import { connect } from "react-redux";

import { loadWordCloud } from "../../DashboardPage/actions";
import { NoDataFound } from "../NoDataFound";

import { options, getWordCloudData } from "./configurations";
import { WordCloudWrapper } from "./styled";
import { Card } from "../Card";
import SkeletonCloud from "../Skeleton/SkeletonCloud";
import { TOOLTIP_MESSAGE } from "constants/constants";

export const WordCloud = ({ loadWordCloud, wordCloud, selectedFilters }) => {
  const { isLoading, isError, data } = wordCloud;

  useEffect(() => {
    loadWordCloud();
  }, [selectedFilters]);

  const words = getWordCloudData(data);

  return (
    <Card heading="Top Keywords Used" tooltip={TOOLTIP_MESSAGE.TOP_KEYWORDS}>
      {isLoading ? (
        <SkeletonCloud />
      ) : !data.length || isError ? (
        <NoDataFound />
      ) : (
        <WordCloudWrapper>
          <ReactWordcloud options={options} words={words} />
        </WordCloudWrapper>
      )}
    </Card>
  );
};

const mapStateToProps = (state) => ({
  wordCloud: state.dashboard.wordCloud,
  selectedFilters: state.selectedFilters,
});

const mapDispatchToProps = {
  loadWordCloud,
};

export default connect(mapStateToProps, mapDispatchToProps)(WordCloud);

export const getConcernList = (state) => state.dashboard.concerns.list;

export const getSelectedConcern = (state) => {
  const { selectedParent, selectedChild } = state.dashboard.concerns;
  return selectedChild.id ? selectedChild : selectedParent;
};

export const getConcernDetail = (state) => {
  const selectedConcern = getSelectedConcern(state);
  return state.dashboard.concerns.details[selectedConcern.id];
};

export const isLoadingConcernDetail = (state) =>
  state.dashboard.concerns.loading || state.dashboard.concerns.loadingDetail;

export const isErrorConcernDetail = (state) =>
  state.dashboard.concerns.error || state.dashboard.concerns.errorDetail;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as S from "./styled";
import { createOrganizationTag } from "components/actions";
import { toggleFeedbackTag } from "components/FeedbackPage/actions";
import FiltersBar from "components/common/SelectedFiltersTags/FiltersBar";
import FeedbackTagGroup from "./FeedbackTagGroup";
import { Wrapper } from "./styled";

const FeedbackTags = ({ feedback }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const tags = useSelector((state) => state.organization.tags);
  const permissions = useSelector((state) => state.auth.user.permissions);

  const suggestedTags = getSuggestedTags(feedback.tags, tags, searchQuery);

  const close = () => {
    setOpen(false);
    setSearchQuery("");
  };

  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        close();
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const ref = useRef();

  if (!permissions.can_view_feedback_tags) {
    return null;
  }

  return (
    <S.Container>
      <TagsFiltersBar className="ui-filtersBar">
        <FeedbackTagGroup
          feedback={feedback}
          selectedTags={suggestedTags}
          closeCallBack={close}
          onTagClick={toggleFeedbackTag}
        />
      </TagsFiltersBar>

      {permissions.can_add_tag_to_feedback && (
        <S.TagSelect empty={!feedback.tags.length} ref={ref}>
          <S.TagInput
            maxLength={64}
            value={searchQuery}
            placeholder={open ? "Search" : "+ Add Tag"}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={() => setOpen(true)}
            focus={open}
          />
          <S.DropdownTagContainer open={open}>
            {suggestedTags.map((tag) => {
              return (
                <S.DropdownTagItem
                  key={tag.id}
                  isSelected={tag.isSelected}
                  onClick={() => {
                    dispatch(toggleFeedbackTag(feedback, tag, tag.isSelected));
                    close();
                  }}
                >
                  {tag.label}
                </S.DropdownTagItem>
              );
            })}
            {permissions.can_create_feedback_tags &&
              searchQuery &&
              !suggestedTags.some((t) => t.label === searchQuery) && (
                <S.DropdownTagItem
                  onClick={() => {
                    dispatch(createOrganizationTag(searchQuery, feedback));
                    close();
                  }}
                >
                  {searchQuery} (Create new)
                </S.DropdownTagItem>
              )}
          </S.DropdownTagContainer>
        </S.TagSelect>
      )}
    </S.Container>
  );
};

const TagsFiltersBar = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

export default FeedbackTags;

const getSuggestedTags = (feedbackTags, tags, searchQuery) =>
  [
    ...feedbackTags.map((tag) => ({ ...tag, isSelected: true })),
    ...tags
      .filter(
        (tag) =>
          !feedbackTags.length || !feedbackTags.some((t) => t.id === tag.id),
      )
      .slice(0, 16),
  ].filter(
    (t) => t.label.toLowerCase().indexOf(searchQuery.toLocaleLowerCase()) >= 0,
  );

import styled, { css } from "styled-components";

import {
  TableWrapper as FetchedWrapper,
  Table as FetchedTable,
  Tr as FetchedTr,
  Td as FetchedTd,
} from "components/common/Table";

export const Wrapper = styled(FetchedWrapper)`
  margin: 37px 0px;
  background-color: white;
  box-shadow: 0 4px 14px #00000026;
  padding-top: 16px;
`;

export const Heading = styled.div`
  font-size: 17px;
  color: currentColor;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  text-align: center;
  margin-bottom: 16px;
`;

export const Table = styled(FetchedTable)`
  width: 100%;
  border: 0;
  display: table;
`;

export const Thead = styled.thead`
  background-color: #e9e9e9;
`;

export const Tbody = styled.tbody``;

export const Tr = styled(FetchedTr)`
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #fff9dd;
        transition: all 0.3s ease-in-out;
      }
      &:hover ${Td} {
        border-left: 0;
      }
      &:hover ${Title} {
        background-color: #fff9dd;
        transition: all 0.3s ease-in-out;
    `}
`;

export const Th = styled.th`
  padding: 16px 5px;
  text-align: center;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #707070;
  border-bottom: 1px solid #b6b6b6;
`;

export const Td = styled(FetchedTd)`
  padding: 16px 5px;
  text-align: center;
  min-width: auto;
  width: auto !important;
  ${(props) => props.color && `color: ${props.color}`}
`;

export const Title = styled(Td)`
  background-color: #e9e9e9;
  width: 30% !important;
`;

import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "react-spinkit";

import { useQuery, formatNumber } from "utils/genericHelper";
import FeedbackListContainer from "components/FeedbackPage/FeedbackList/FeedbackList";
import FeedbackFilters from "./FeedbackFilters";
import FeedbackDetailList from "components/FeedbackPage/SelectedFeedback/FeedbackDetailList";
import {
  loadFeedbackList,
  loadUnprocessedStats,
  loadSelectedFeedback,
  clearSelectedFeedback,
  removeSelectedFeedback,
} from "components/FeedbackPage/actions";
import { getViewOnlyFeedbackUUID } from "components/FeedbackPage/selectors";
import NotificationListCard from "components/common/Notification/NotificationListCard";

import * as Styled from "./styled";

const Feedback = () => {
  const selectedRacks = useSelector((state) => state.dashboard.selectedRacks);
  const filters = useSelector((state) => state.selectedFilters.query);

  const [_isViewOnly, _setViewOnly] = useState(false);
  const dispatch = useDispatch();

  const notification = useQuery().get("notification");
  useEffect(() => {
    const { UUID, isViewOnly } = getViewOnlyFeedbackUUID();
    if (!isViewOnly) {
      dispatch(loadFeedbackList());
      dispatch(loadUnprocessedStats());
    }
    if (UUID) dispatch(loadSelectedFeedback(UUID));
    _setViewOnly(isViewOnly);
    return () => dispatch(clearSelectedFeedback());
  }, []);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const rackRoute = getRackRoute(selectedRacks);
    window.history.replaceState(null, null, rackRoute);
    dispatch(removeSelectedFeedback());
    dispatch(loadFeedbackList());
    dispatch(loadUnprocessedStats());

    return () => dispatch(clearSelectedFeedback());
  }, [filters]);

  return (
    <Row className="row feedbacks">
      <Col md={12} sm={12}>
        <Styled.Container>
          {!_isViewOnly && !notification && <FeedbackList />}
          {notification ? <NotificationListCard /> : null}
          <FeedbackDetailList
            fullScreen={_isViewOnly}
            isFromNotification={notification}
          />
        </Styled.Container>
      </Col>
    </Row>
  );
};

const getRackRoute = (selectedRacks) => {
  const racks = selectedRacks.filter((r) => r);
  return racks.reduce((route, r) => "/" + r.slug + route, "") || "/feedback";
};

const FeedbackList = () => {
  const feedbackList = useSelector((state) => state.feedback.feedbackList);
  const unprocessed = useSelector((state) => state.feedback.unprocessedStats);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const selectedRacks = useSelector((state) => state.dashboard.selectedRacks);

  const rackRoute = getRackRoute(selectedRacks);

  const { t } = useTranslation();

  return (
    <Col sm={12} md={4} className="feedback-list">
      <Styled.FeedbackList>
        {permissions.api_action_stats && <Unprocessed data={unprocessed} />}
        {(permissions.api_action_stats || permissions.can_sort_feedback) && (
          <FeedbackFilters />
        )}
        {feedbackList.isLoading ? (
          <Spinner name="wandering-cubes" fadeIn="none" />
        ) : feedbackList.data.length ? (
          <FeedbackListContainer rackRoute={rackRoute} />
        ) : (
          <Styled.NotFound>{t("noFeedbackFound")}</Styled.NotFound>
        )}
      </Styled.FeedbackList>
    </Col>
  );
};

const Unprocessed = ({ data }) => {
  const label = data.isLoading ? "--" : formatNumber(data.count);
  const { t } = useTranslation();
  return (
    <div className="list-stats-card">
      {t("unprocessedFeedback")}: {label}
    </div>
  );
};

export default Feedback;

import React, { useState } from "react";
import { Alert, FormControl, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate, useLocation } from "react-router-dom";
import CSSTransitionGroup from "react-transition-group/CSSTransition";

import { removeAuthLocalStorage } from "./saga";

import { loadAuth } from "components/LoginPage/actions";
import { ChangePasswordButton as Button, ToolTip } from "components/Profile/ResetPassword/styled";
import { telemetry } from "HOC/telemetry";


const LoginForm = () => {
  removeAuthLocalStorage();

  const [username_or_email, setUsernameEmail] = useState(() => sessionStorage.getItem("user-name") || "");
  const [password, setPassword] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const error = useSelector(state => state.auth.error);
  const organization = useSelector(state => state.organization.organization);

  const areFieldsFilled = username_or_email && password;
  const colors = organization.features.colors,
    logoFeatures = organization.features.dashboard.logo,
    login_logo = organization.login_logo,
    logo_landscape = organization.logo_landscape;


  const onSubmitHandler = (event) => {
    event.preventDefault();
    dispatch(loadAuth({ username_or_email, password }));
    telemetry.setOverallActiveStartTime();
  };

  return (
    <div id="login">
      <div className="login-holder">
        <CSSTransitionGroup
          classNames="animated"
          appear={true}
          timeout={{
            appear: 500,
            enter: 300,
            exit: 300,
          }}
          enter={false}
          exit={false}
        >
          <div className="inner cover">
            <div className="logo">
              <img
                src={login_logo || logo_landscape}
                style={{ width: `${logoFeatures.login}%` }}
                alt=""
              />
            </div>

            {error ? (
              <CSSTransitionGroup
                transitionName="animated"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}
                transitionEnter={false}
                transitionLeave={false}
              >
                <Alert variant="danger">
                  <p>{t(error)}</p>
                </Alert>
              </CSSTransitionGroup>
            ) : null}

            <form onSubmit={onSubmitHandler}>
              <FormGroup>
                <i
                  className="fa fa-user-circle-o"
                  style={{
                    color: colors.primary,
                  }}
                />
                <FormControl
                  type="text"
                  name="username_or_email"
                  placeholder={t("emailOrUsername")}
                  value={username_or_email}
                  onChange={(e) => setUsernameEmail(e.target.value)}
                />
              </FormGroup>

              <FormGroup>
                <i
                  className="fa fa-unlock"
                  style={{
                    color: colors.primary,
                  }}
                />
                <FormControl
                  type="password"
                  name="password"
                  placeholder={t("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <Button
                type="submit"
                bsPrefix="btn-danger btn-lg btn-block"
                style={{
                  backgroundColor: !areFieldsFilled
                    ? "slategray"
                    : colors.primary,
                  color: colors.text || colors.secondary,
                }}
                disabled={!areFieldsFilled}
              >
                {!areFieldsFilled && (
                  <ToolTip color={colors.primary}>
                    {t("addCredentials")}
                  </ToolTip>
                )}
                {t("login")}
              </Button>
            </form>
            <div className="col-md-12 text-center pt-md-1">
              <Link to="/forgot-password">{t("forgotPassword")}</Link>
            </div>
          </div>
        </CSSTransitionGroup>
      </div>
    </div>
  );
};


export default function Login() {
  const location = useLocation();
  const authenticated = useSelector(state => state.auth.authenticated);

  if (!authenticated) {
    return <LoginForm />;
  }

  const locationState = location.state;
  const redirect_to = locationState ? locationState.from.pathname + locationState.from.search : "/";
  return <Navigate to={redirect_to} />;
}

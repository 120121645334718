import numeral from "numeral";

export const getTotalItemsSold = (invoice) => {
  if (invoice.items.length) {
    let totalItemsSold = invoice.items.reduce((number, item) => {
      return (number += 1 * item.quantity);
    }, 0);
    return numeral(totalItemsSold).format("0,0");
  } else {
    return "--";
  }
};

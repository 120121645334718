import { all, fork, call } from "redux-saga/effects";

import { sagas as organization } from "components/saga";
import { sagas as auth } from "components/LoginPage/saga";
import { sagas as notification } from "components/common/Notification/saga";
import { sagas as filters } from "components/common/filters/saga";
import { sagas as dashboard } from "components/DashboardPage/saga";
import { sagas as feedback } from "components/FeedbackPage/saga";
import { sagas as customer } from "components/ManageUsersPage/sagas";
import { sagas as surveySagas } from "components/Survey/saga";
import { sagas as insightSagas } from "components/InsightsPage/Insights/saga";
import { sagas as surveyBuilder } from "components/SurveyBuilder/saga";
import { sagas as RCASagas } from "components/RCA/saga";

export function* rootSaga() {
  const sagas = [
    ...organization,
    ...auth,
    ...notification,
    ...filters,
    ...dashboard,
    ...feedback,
    ...surveySagas,
    ...customer,
    ...insightSagas,
    ...surveyBuilder,
    ...RCASagas,
  ];
  yield all(
    sagas.map((saga) =>
      fork(function* () {
        try {
          yield call(saga);
        } catch (e) {
          // console.log("Failed Saga: ", e);
        }
      }),
    ),
  );
}

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { keys, cloneDeep, upperFirst } from "lodash";
import { NoDataFound } from "../../common/NoDataFound";
import { Panel } from "../../common/Panel";
import { Line } from "react-chartjs-2";
import { TimeSeriesChartsOptions } from "./TimeSeriesChartsOptions";
import { loadTimeSeries } from "../actions";
import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import SkeletonGraph from "components/common/Skeleton/SkeletonGraph";
import { TooltipText } from "components/common/Tooltip";

class TimeSeriesGraph extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: props.analyzer.periods?.[0] || "daily" };
  }
  componentDidMount = () => {
    this.props.loadTimeSeries();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedFilters, loadTimeSeries } = this.props;
    if (selectedFilters.query !== prevProps.selectedFilters.query) {
      loadTimeSeries();
    }
  };

  selectTab = (activeTab) => this.setState({ activeTab });

  render() {
    const { isLoading, isError, apiData } = this.props.timeseries;

    const tooltipHeader = (
      <span title={TOOLTIP_MESSAGE.NPS_TIME_SERIES} className="tooltipRel">
        {this.props.analyzer?.heading || "Time Series"}
        <TooltipText tooltipText={TOOLTIP_MESSAGE.NPS_TIME_SERIES} />
      </span>
    );

    if (!apiData || isLoading || isError) {
      return (
        <Panel header={tooltipHeader}>
          {isLoading ? <SkeletonGraph /> : <NoDataFound />}
        </Panel>
      );
    }

    const periods = this.props.analyzer.periods || keys(apiData);

    return (
      <Panel header={tooltipHeader} className="timeseries">
        <Tabs
          id="timeseries"
          onSelect={this.selectTab}
          activeKey={this.state.activeTab}
        >
          {periods.map((period) => {
            return (
              <Tab eventKey={period} title={upperFirst(period)}>
                <Line
                  redraw={true}
                  data={cloneDeep(apiData[period])}
                  options={TimeSeriesChartsOptions}
                />
              </Tab>
            );
          })}
        </Tabs>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedFilters: state.selectedFilters,
  timeseries: state.dashboard.timeseries,
});

const mapDispatchToProps = {
  loadTimeSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSeriesGraph);

import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";

import PriorityListStats from "./PriorityListStats";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import { Panel } from "../../common/Panel";
import { loadPriorityList, setPriorityListAnalysis } from "../actions";

class PriorityList extends Component {
  getAPI() {
    const { loadPriorityList, priorityList } = this.props;
    const { analysisType } = priorityList;
    loadPriorityList(analysisType);
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters, priorityList, analysisType } = this.props;
    const prevPropsSelectedFilters = prevProps.selectedFilters,
      prevPropsAnalysisType = prevProps.analysisType;
    if (
      !isEqual(selectedFilters, prevPropsSelectedFilters) ||
      !isEqual(prevPropsAnalysisType, analysisType)
    ) {
      this.getAPI();
    }
  }

  render() {
    const { priorityList, setPriorityListAnalysis } = this.props;
    const { analysis, analysisType, fetching, error } = priorityList;

    return (
      <Panel
        header={
          <span title={TOOLTIP_MESSAGE.TOP_PRIORITIES_OF_THE_WEEK}>
            Top Priorities of the Week
          </span>
        }
      >
        <Tabs
          id="priority-list-tabs"
          activeKey={analysisType}
          onSelect={setPriorityListAnalysis}
        >
          {["overall", "inner", "outer"].map((key, i) => {
            return (
              <Tab
                key={i}
                eventKey={key}
                title={key[0].toUpperCase() + key.slice(1)}
              >
                <PriorityListStats
                  analysis={analysis}
                  fetching={fetching}
                  error={error}
                  analysisType={key}
                />
              </Tab>
            );
          })}
        </Tabs>
      </Panel>
    );
  }
}

const mapStateToProps = ({ dashboard, selectedFilters }) => ({
  priorityList: dashboard.priorityList,
  analysisType: dashboard.priorityList.analysisType,
  selectedFilters: selectedFilters,
});

export default connect(mapStateToProps, {
  loadPriorityList,
  setPriorityListAnalysis,
})(PriorityList);

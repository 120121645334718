import React from "react";

import { TimeAgo } from "components/common/TimeAgo";
import { pickScoreView } from "components/FeedbackPage/ScoreView";
import { FeedbackAction } from "constants/constants";
import { getFeedbackActionAttributes } from "utils/feedbackActionLabel";

import * as Styled from "./styled";
import { useSelector } from "react-redux";
import { getFeedbackActions } from "components/selectors";

const FeedbackListItem = ({
  feedback,
  rackRoute,
  isSelected,
  showActionStats,
  loadSelectedFeedback,
}) => {
  const feedbackClickHandler = (feedback) => {
    loadSelectedFeedback(feedback.url_uuid.slice(0, -2));
    sessionStorage.setItem("feedbackId", feedback.url_uuid.slice(0, -2));
    const path = `${rackRoute}/${feedback.url_uuid.slice(0, -2)}`;
    window.history.replaceState(null, "", path);
  };

  const ScoreView = pickScoreView(feedback);
  const FeedbackActionLabel = useSelector(getFeedbackActions);
  let feebackActionAttributes =
    feedback.feedback_action &&
    getFeedbackActionAttributes(
      feedback,
      FeedbackActionLabel,
      feedback.feedback_action.type,
    );
  const isUNP = feedback.feedback_action?.type === FeedbackAction.UNPROCESSED;
  const { primary, feedback_division, invite_division } = feedback.division;

  return (
    <Styled.FeedbackSummaryCard
      isUNP={isUNP}
      isSelected={isSelected}
      onClick={() => feedbackClickHandler(feedback)}
    >
      <Styled.FeedbackScoreSection>
        <Styled.FeedbackScore>
          {ScoreView && (
            <ScoreView
              scoreLabel={feedback.score_label}
              score={feedback.score}
            />
          )}
          <Styled.RespondentName>
            {feedback.respondent_info?.name || "--"}
          </Styled.RespondentName>
        </Styled.FeedbackScore>
        <Styled.FeedbackTime>
          <TimeAgo date={feedback.created_at} />
          {showActionStats && feedback.feedback_action && (
            <Styled.FeedbackActionText
              backgroundColor={feebackActionAttributes.backgroudColor}
              foregroundColor={feebackActionAttributes.foregroudColor}
            >
              {feebackActionAttributes.labelText}
            </Styled.FeedbackActionText>
          )}
        </Styled.FeedbackTime>
      </Styled.FeedbackScoreSection>

      <Styled.FeedbackDivisions>
        {primary && (
          <Styled.FeedbackDivision>
            <i className="fa fa-map-marker" />
            {primary.title}
          </Styled.FeedbackDivision>
        )}
        {invite_division && (
          <Styled.FeedbackDivision>
            <i className="fa fa-location-arrow" />
            {invite_division.title}
          </Styled.FeedbackDivision>
        )}
        {feedback_division && (
          <Styled.FeedbackDivision>
            <i className="fa fa-cube" />
            {feedback_division.title}
          </Styled.FeedbackDivision>
        )}
        {feedback.taker && (
          <Styled.FeedbackDivision>
            <i className="fa fa-user" />
            {feedback.taker.name}
          </Styled.FeedbackDivision>
        )}
      </Styled.FeedbackDivisions>
    </Styled.FeedbackSummaryCard>
  );
};

export default FeedbackListItem;

export const RCAViewSelector = (state) => state.RCA.selectedView;
export const projectListSelector = (state) => state.RCA.projectList;
export const projectFormSelector = (state) => state.RCA.selectedProject.form;
export const projectIDSelector = (state) => state.RCA.selectedProject.id;
export const projectValuesSelector = (state) =>
  state.RCA.selectedProject.values || {};
export const rcaSectionsSelector = (state) =>
  state.RCA.selectedProject.form?.data?.rca_sections;
export const rootCausesSelector = (state) =>
  state.RCA.selectedProject.values?.data?.root_causes;
export const formFieldValueSelector = (state, key) =>
  state.RCA.selectedProject.values.data?.[key];
export const allTeamLeadsSelector = (state) => state.RCA.allTeamLeads;
export const allUsersSelector = (state) => state.RCA.allUsers;
export const allTeamMembersSelector = (state) => state.RCA.allTeamMembers;
export const allConcernsSelector = (state) => state.RCA.allConcerns;
export const allLevelsSelector = (state) => state.RCA.allLevels;
export const specificLevelSelector = (state, levelName) =>
  state.RCA.allLevels.find((level) => level.name == levelName);
export const formRCAOptionsFieldSelector = (state, id) =>
  state.RCA.selectedProject.values.data.rca_options[id]?.content;
export const formModeSelector = (state) => state.RCA.mode;
export const questionnaireIDSelector = (state) =>
  state.dashboard.selectedDashboard?.id;
export const locationMatchErrorSelector = (state) =>
  state.RCA.locationMatchError;
export const currentLoggedInNameSelector = (state) =>
  state.auth.user.first_name + " " + state.auth.user.last_name;
export const currentLoggedInUserIdSelector = (state) => state.auth.user.id;
export const concernIdSelector = (state) =>
  state.RCA.selectedProject.values.data.concern?.id;
export const deploymentDateSelector = (state) =>
  state.RCA.selectedProject.values.data.deployment_start_date;
export const concernAnalysisSelector = (state) =>
  state.RCA.selectedProject.values.analysis;
export const concernAnalysisSummarySelector = (state) =>
  state.RCA.selectedProject.values.analysis?.summary;
export const concernAnalysisTimeSeriesSelector = (state) =>
  state.RCA.selectedProject.values.analysis?.time_series;
export const rcaTemplateIdSelector = (state) =>
  state.dashboard.selectedDashboard?.rca_template;
export const rcaProjectListCurrentPageSelector = (state) => state.RCA.currPage;
export const rcaTotalProjectsCountSelector = (state) => state.RCA.totalProjects;
export const selectUser = (state) => state.auth.user;
export const isFieldRequired = (state, key) => {
  const allFields = state.RCA.selectedProject.form.data.rca_sections.flatMap(
    (section) => section.rca_fields.flat(),
  );
  const selectedField = allFields.find((field) => field.slug == key);
  return selectedField?.is_required;
};

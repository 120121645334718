const size = {
  xs: "576px", // for mobiles
  sm: "576px", // for mobiles
  md: "768px", // for medium tablets
  lg: "992px", // for large tablets
  xl: "1200px", // for medium laptop
  xxl: "1400px", // for large laptop
  xxxl: "1900px", // for desktop
};

export const device = {
  xs: `(max-width: ${size.xs})`, // for mobiles
  sm: `(min-width: ${size.sm})`, // for mobiles
  md: `(min-width: ${size.md})`, // for medium tablets
  lg: `(min-width: ${size.lg})`, // for large tablets
  xl: `(min-width: ${size.xl})`, // for medium laptop
  xxl: `(min-width: ${size.xxl})`, // for large laptop
  xxxl: `(min-width: ${size.xxxl})`, // for desktop
};

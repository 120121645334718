import React from "react";
import TimePickerOptions from "./TimePickerOptions";

import { CustomDropdown } from "components/common/Dropdown";

const TimePicker = ({ selectedTime, startTime, onClick, disabled }) => {
  const options = startTime
    ? TimePickerOptions.slice(startTime.index + 1, TimePickerOptions.length)
    : TimePickerOptions.slice(0, -1);
  return (
    <CustomDropdown
      options={options}
      getKey={(t) => t.index}
      getLabel={(t) => t.label}
      selectedOption={selectedTime}
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default TimePicker;

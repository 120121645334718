export const initialState = {
  isLoading: false,
  isMoreLoading: false,
  isError: false,
  data: {
    unread_count: 0,
    results: [
      {
        sender: {},
        recepient: {},
        content_object: {},
        seen_at: true,
      },
    ],
  },
};

import React from "react";
import PropTypes from "prop-types";
import * as analytics from "utils/analytics";

import {
  Card as Wrapper,
  CardWrapper,
  CardHeader,
  CardBody,
  CardFooter,
  CardHeading,
  CardLoadingIcon,
  CardDataAction,
  CardVisualAction,
} from "./styled";
import { Dropdown as CardDropdown } from "./Dropdown";
import { TooltipText } from "../Tooltip";
import MiniSpinner from "components/common/MiniSpinner/MiniSpinner";

import { boldText } from "utils/genericHelper";

export const Card = ({
  children,
  heading,
  headingSize,
  graphs,
  selectGraph,
  selectedGraph,
  units,
  selectUnit,
  selectedUnit,
  type,
  backgroundColor,
  headingColor,
  dim,
  headingClass,
  tooltip,
  tooltipPosition,
  loadingMore,
}) => {
  const _heading = boldText(heading);

  return (
    <Wrapper
      className="ui-card"
      miniCard={type === "text"}
      backgroundColor={backgroundColor}
      dim={dim}
    >
      <CardWrapper className="ui-card-wrapper">
        {heading && (
          <CardHeader className="ui-card-header">
            {loadingMore && (
              <CardLoadingIcon>
                <MiniSpinner />
              </CardLoadingIcon>
            )}
            <CardHeading
              fontSize={headingSize}
              className={`ui-card-heading tooltipRel ${headingClass}`}
              headingColor={headingColor}
            >
              {_heading}
              {!dim && !!tooltip?.length && (
                <TooltipText tooltipText={tooltip} align={tooltipPosition} />
              )}
            </CardHeading>
            {selectedGraph && graphs && graphs.length > 1 && (
              <CardDataAction>
                <CardDropdown
                  options={graphs}
                  selectedOption={selectedGraph}
                  onClick={(graph) => {
                    analytics.logGraphSelect(graph, heading);
                    selectGraph(graph);
                  }}
                  getKey={(o) => o}
                  getLabel={(o) => o}
                  getImage={() => null}
                  getStatus={() => "active"}
                />
              </CardDataAction>
            )}
          </CardHeader>
        )}

        <CardBody
          className="ui-card-body"
          variations={type === "text"}
          charts={type === "graph"}
          hideOverflow={heading}
        >
          {children}
        </CardBody>

        <CardFooter className="ui-card-footer">
          {selectUnit && units && units.length > 1 && (
            <CardVisualAction>
              <CardDropdown
                options={units}
                selectedOption={selectedUnit}
                onClick={(unit) => {
                  analytics.logUnitSelect(unit, heading);
                  selectUnit(unit);
                }}
                getKey={(o) => o}
                getLabel={(o) => o}
                getImage={() => null}
                getStatus={() => "active"}
                align="topRight"
              />
            </CardVisualAction>
          )}
        </CardFooter>
      </CardWrapper>
    </Wrapper>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

import { createSelector } from "reselect";

const selectOrganization = (state) =>
  state.organization && state.organization.organization;

const getOrganizationId = createSelector(
  selectOrganization,
  (organizationState) => organizationState.id,
);

export { selectOrganization, getOrganizationId };

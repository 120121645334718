import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  FilterType,
  FilterLabel,
  FilterList,
  FilterItem,
  FilterItemRemove,
  FilterToolTip,
  FilterDropdownItem,
} from "./styled";
import FilterDropdown from "./Dropdown";

const TagGroup = ({
  color,
  filter,
  onTagClick,
  selectedTags,
  onFilterClick,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const { t } = useTranslation();
  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  return (
    <FilterType
      ref={ref}
      className="ui-filtersBar-type"
      onContextMenu={(e) => {
        e.preventDefault();
        setOpen(!open);
      }}
      onClick={() => {
        onFilterClick(
          filter.key,
          filter.tags
            .filter((tag) => selectedTags[tag.key])
            .map((tag) => tag.key),
        );
      }}
    >
      <FilterLabel className="ui-filtersBar-type_label">
        {filter.title}
        <FilterToolTip tooltipText={t("Click to remove")} />
      </FilterLabel>
      <FilterDropdown open={open} innerRef={ref}>
        {filter.tags.map((tag) => (
          <FilterDropdownItem
            key={tag.key}
            className="ui-dropdown-item"
            selected={selectedTags[tag.key]}
            onClick={(e) => {
              e.stopPropagation();
              onTagClick(filter.key, tag);
            }}
          >
            {tag.title}
          </FilterDropdownItem>
        ))}
      </FilterDropdown>

      <FilterList className="ui-filtersBar-type_list">
        {filter.tags
          .filter((tag) => selectedTags[tag.key])
          .map((tag) => (
            <FilterItem
              className={`ui-filtersBar-type_list_item ${filter.key}`}
              bgColor={color}
              onClick={(e) => {
                e.stopPropagation();
                onTagClick(filter.key, tag);
              }}
            >
              {tag.title}
              <FilterItemRemove />
            </FilterItem>
          ))}
      </FilterList>
    </FilterType>
  );
};

export default TagGroup;

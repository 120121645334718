import { getRequest, postRequest } from "utils/request";
import { isCustomerView } from "components/FeedbackPage/selectors";

export const feedbackListActionStat = (
  organizationId,
  filtersQuery,
  feedbackAction,
) => {
  let url = `/api/dashboard/v1/organizations/${organizationId}/feedback-action-stats/?action_type=${feedbackAction}&${filtersQuery}`;

  return getRequest(url);
};

export const loadMoreFeedbackApi = (
  organizationId,
  filtersQuery,
  detail,
  feedbackId,
) => {
  let url = `/organizations/${organizationId}/feedback/list/?${
    detail ? `detail=${detail}&` : ""
  }${feedbackId ? `feedback=${feedbackId}&` : ""}${filtersQuery}`;

  return getRequest(url);
};

export const nextPageApi = (nextUrl) => {
  return getRequest(nextUrl);
};

export const feedbackDetailApi = (organizationId, feedbackId, filtersQuery) => {
  let url = `/organizations/${organizationId}/feedback/${feedbackId}/detail?${filtersQuery}`;

  const disableAuth = isCustomerView();
  return getRequest(url, disableAuth);
};

export const getRemarksApi = (organizationId, feedbackId) => {
  let url = `/organizations/${organizationId}/feedback/${feedbackId}/remarks`;

  return getRequest(url);
};

export const createRemarksApi = (organizationId, feedbackId, text) => {
  let url = `/organizations/${organizationId}/feedback/${feedbackId}/remarks`;

  return postRequest(url, { text });
};

export const createActionApi = (organizationId, feedbackId, remark, type) => {
  let url = `/organizations/${organizationId}/feedback/${feedbackId}/actions/`;

  let body = { remark, type };

  return postRequest(url, body);
};

export const feedbackActionStatsApi = (organizationId, filtersQuery) => {
  let url = `/organizations/${organizationId}/feedback/action-stats/?${filtersQuery}`;

  return getRequest(url);
};

export const getChatMessages = (feedbackId) => {
  const url = `/messages/list?feedback=${feedbackId}`;

  return getRequest(url);
};

export const postMessages = (organizationId, payload) => {
  const url = `/messages/${organizationId}/feedback-message`;

  return postRequest(url, payload);
};

export const loadUsersApi = () => {
  const url = `/v1/users/tag/list`;
  return getRequest(url);
};

export const toggleFeedbackTagApi = (
  organizationId,
  feedbackId,
  tags,
  remove,
) => {
  const action = remove ? "remove" : "add";
  const url = `/organizations/${organizationId}/feedback/${feedbackId}/tags/${action}/`;
  return postRequest(url, tags);
};

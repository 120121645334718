import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { Bar } from "react-chartjs-2";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import _ from "lodash";

import { getSemanticAnalysisChartData } from "../../../utils/chartsData";
import { Panel } from "../../common/Panel";
import { loadSemanticAnalysis } from "../actions";

class SemanticAnalysis extends Component {
  constructor(props) {
    super(props);

    this.getApi = this.getApi.bind(this);
  }

  getApi() {
    const { loadSemanticAnalysis } = this.props;
    loadSemanticAnalysis();
  }

  componentDidMount() {
    this.getApi();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.selectedFilters, this.props.selectedFilters)) {
      this.getApi();
    }
  }

  render() {
    const { semanticAnalysis } = this.props;
    const { fetching, analysis } = semanticAnalysis;

    if (!analysis.length || fetching) {
      return (
        <Panel header="Customers Talking About">
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    const SemanticAnalysisOverlay = (
      <Tooltip id="top-concern-tooltip">
        <Table>
          <thead>
            <tr>
              <th>Segment</th>
              <th>Count</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            {analysis.map((concern) => (
              <tr>
                <td>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        height: 12,
                        width: 12,
                        backgroundColor: concern.color_code,
                        display: "inline-block",
                        border: "1px solid #fff",
                        position: "relative",
                        left: -5,
                        top: 2,
                      }}
                    />
                    <span>{concern.keyword}</span>&nbsp;
                  </div>
                </td>
                <td>{concern.stats.count}</td>
                <td>{concern.stats.percentage}%</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Tooltip>
    );

    return (
      <Panel
        header={
          <OverlayTrigger placement="top" overlay={SemanticAnalysisOverlay}>
            <span>Semantic Analysis</span>
          </OverlayTrigger>
        }
      >
        <Bar
          data={getSemanticAnalysisChartData(analysis)}
          options={{
            indexAxis: "y",
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            scales: {
              x: [
                {
                  grid: {
                    display: true,
                  },
                  ticks: {},
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Percentage",
                  },
                },
              ],
              y: [
                {
                  grid: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, dashboard }) => ({
  selectedFilters,
  semanticAnalysis: dashboard.semanticAnalysis,
});

export default connect(mapStateToProps, { loadSemanticAnalysis })(
  SemanticAnalysis,
);

export const initialState = {
  dashboards: [],
  selectedDashboard: null,
  selectedRacks: [null, null, null],
  data: {},
  error: {},
  loading: {},
  selectedUnits: JSON.parse(localStorage.getItem("selectedUnits")) || {},
  selectedGraphs: JSON.parse(localStorage.getItem("selectedGraphs")) || {},
  filterSets: {},
  wordCloud: {
    data: [],
    isLoading: false,
    isError: false,
  },
  comments: {
    isLoading: false,
    isMoreLoading: false,
    isError: false,
    data: {
      results: [],
    },
  },
  concerns: {
    error: false,
    loading: false,
    list: [],
    children: {},
    selectedParent: {},
    selectedChild: {},
    details: {},
    loadingDetail: false,
    errorDetail: false,
    loadingImpactOnScore: false,
    impactOnScore: null,
  },
  globalView: JSON.parse(localStorage.getItem("globalView")) || {
    unit: null,
    graph: null,
  },
  surveyQuestions: {
    surveys: {},
    error: false,
    loading: false,
  },
  projectsOverview: {
    data: {
      headers: [],
      data: [],
    },
    isLoading: false,
    isError: false,
  },
  media: {
    data: {
      results: [],
    },
    isLoading: false,
    isError: false,
  },
  timeseries: {
    isLoading: false,
    isError: false,
    apiData: null,
  },
  topConcerns: {
    analysisType: "overall",
    analysis: [],
    fetching: true,
    error: "",
  },
  semanticAnalysis: {
    analysis: [],
    fetching: true,
    error: false,
  },
  satisfactionLevel: {
    analysis: [],
    fetching: true,
    error: false,
  },
  priorityList: {
    analysisType: "overall",
    analysis: [],
    fetching: true,
    error: false,
  },
  nps: {
    fetching: true,
    data: null,
    isError: false,
  },
  genderGroupAnalysis: {
    fetching: true,
    analysis: {},
    isError: false,
  },
  dataHealth: {
    data: {},
    fetching: true,
    isError: false,
  },
  trueScore: {
    data: {},
    fetching: true,
    isError: false,
  },
  customerSegmentation: {
    fetching: true,
    isError: false,
    data: {},
  },
  competitorAnalysis: {
    data: {},
    isError: false,
    fetching: true,
  },
  benchmarks: {
    fetching: true,
    isError: false,
    data: {},
  },
  segmentAnalysis: {
    fetching: true,
    segmentAnalysis: {},
    isError: null,
  },
  inviteStats: {
    fetching: true,
    data: {},
    isError: null,
  },
  insights: {
    likes: false,
    feedback_level: null,
  },
};

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import {
  getIsOmniView,
  getFilterStatus,
  getDefaultDates,
} from "components/common/ResetFilter/selectors";
import { clearFilters } from "components/common/filters/actions";
import { TooltipText } from "components/common/Tooltip/Tooltip";

import { ReactComponent as ResetIcon } from "assets/svg/reload.svg";
import { ReactComponent as ArrowIcon } from "assets/svg/down_arrow.svg";
import {
  BootDropdown,
  BootDropdownMenu,
  BootDropdownToogle,
  OptionItem,
} from "./styled";
import { TOOLTIPS } from "constants/constants";

const ResetFilter = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef();
  const dispatch = useDispatch();
  const filters = useSelector(getFilterStatus);
  const isOmniView = useSelector(getIsOmniView);
  const defaultDates = useSelector(getDefaultDates);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  return (
    !isEmpty(filters.filter((f) => f.active)) && (
      <>
        <BootDropdown ref={ref} show={open} className="option-group tooltipRel">
          <div
            className="option"
            onClick={() => dispatch(clearFilters(isOmniView, ...defaultDates))}
          >
            <ResetIcon />
            {!open && (
              <TooltipText
                tooltipText={t(TOOLTIPS.RESET_FILTER)}
                align="bottomLeft"
              />
            )}
          </div>
          <BootDropdownToogle className="option" onClick={() => setOpen(!open)}>
            <ArrowIcon />
          </BootDropdownToogle>
          <BootDropdownMenu placement="bottom-end">
            {filters.map((filter) => (
              <OptionItem
                key={filter.id}
                selected={filter.active}
                onClick={
                  filter.active
                    ? () => filter.dispatch.map((d) => dispatch(d))
                    : null
                }
              >
                {t(filter.name)}
              </OptionItem>
            ))}
          </BootDropdownMenu>
        </BootDropdown>
      </>
    )
  );
};

export default ResetFilter;

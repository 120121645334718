import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  formFieldValueSelector,
  formRCAOptionsFieldSelector,
} from "components/RCA/selectors";

import { keys, values } from "lodash";

import { AccordianInfoCard } from "components/common/AccordianInfoCard";

const Field = ({ header, field, minHeight, value, fieldKey }) => {
  const [changedValue, setChangedValue] = useState("");

  useEffect(() => {
    if (fieldKey == "location") {
      const v = values(value)
        .flatMap((d) => d.titles)
        .reduce((t, d) => t + ", " + d, "")
        .slice(2);
      setChangedValue(v);
    } else if (fieldKey == "team_members") {
      const teamMembersNames = value.map((member) => member.full_name);
      setChangedValue(teamMembersNames.toString());
    } else if (fieldKey == "loop") {
      const loopOptions = [
        { label: "Inner", value: "I" },
        { label: "Outer", value: "O" },
      ];
      const selectedLoop = loopOptions.find((loop) => loop.value === value);
      setChangedValue(selectedLoop?.label);
    } else if (keys(value) && keys(value).includes("full_name")) {
      setChangedValue(value.full_name);
    } else if (keys(value) && keys(value).includes("name")) {
      setChangedValue(value.name);
    } else {
      setChangedValue(value);
    }
  }, []);

  return (
    <AccordianInfoCard
      header={header}
      body={changedValue}
      minHeight={minHeight}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  let value;

  value = formFieldValueSelector(state, ownProps.fieldKey) || null;
  if (
    value &&
    ["concern_current_state", "concern_desired_state"].includes(
      ownProps.fieldKey,
    )
  ) {
    value = parseFloat(value).toFixed(2) + "%";
  }
  if (!value && ownProps.fieldKey === "concern") {
    value = formFieldValueSelector(state, "other_concern") || null;
  } else if (!value) {
    value = formRCAOptionsFieldSelector(state, ownProps.field.id) || null;
  }
  return {
    value,
  };
};

export default connect(mapStateToProps)(Field);

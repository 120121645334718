export const SB_GET_ALL_SURVEYS = "SB_GET_ALL_SURVEYS",
  SB_GET_ALL_SURVEYS_SUCCESS = "SB_GET_ALL_SURVEYS_SUCCESS",
  SB_GET_ALL_SURVEYS_ERROR = "SB_GET_ALL_SURVEYS_ERROR",
  SB_CHANGE_SURVEY_LIST_PAGE = "SB_CHANGE_SURVEY_LIST_PAGE",
  SB_TOGGLE_SURVEY_STATUS = "SB_TOGGLE_SURVEY_STATUS",
  SB_TOGGLE_SURVEY_STATUS_SUCCESS = "SB_TOGGLE_SURVEY_STATUS_SUCCESS",
  SB_TOGGLE_SURVEY_STATUS_ERROR = "SB_TOGGLE_SURVEY_STATUS_ERROR",
  SB_SET_ACTIVE_TAB = "SB_SET_ACTIVE_TAB",
  SB_SET_QUESTIONNAIRE = "SB_SET_QUESTIONNAIRE",
  SB_SET_QUESTIONNAIRE_SELECTED_STATE = "SB_SET_QUESTIONNAIRE_SELECTED_STATE",
  SB_SET_QUESTIONNAIRE_FIELD = "SB_SET_QUESTIONNAIRE_FIELD",
  SB_REMOVE_QUESTIONNAIRE_IMAGE = "SB_REMOVE_QUESTIONNAIRE_IMAGE",
  SB_ADD_QUESTION_FORM = "SB_ADD_QUESTION_FORM",
  SB_REMOVE_QUESTION_FORM = "SB_REMOVE_QUESTION_FORM",
  SB_REMOVE_QUESTION_IMAGE = "SB_REMOVE_QUESTION_IMAGE",
  SB_SET_QUESTION_POSITION = "SB_SET_QUESTION_POSITION",
  SB_ADD_OPTION_FORM = "SB_ADD_OPTION_FORM",
  SB_REMOVE_OPTION_FORM = "SB_REMOVE_OPTION_FORM",
  SB_SET_OPTION_POSITION = "SB_SET_OPTION_POSITION",
  SB_ADD_SUB_OPTION_FORM = "SB_ADD_SUB_OPTION_FORM",
  SB_REMOVE_SUB_OPTION_FORM = "SB_REMOVE_SUB_OPTION_FORM",
  SB_SET_SUB_OPTION_POSITION = "SB_SET_SUB_OPTION_POSITION",
  SB_SET_QUESTION_FIELD = "SB_SET_QUESTION_FIELD",
  SB_SET_QUESTION_IMAGE = "SB_SET_QUESTION_IMAGE",
  SB_SET_QUESTION_IMAGE_POSITION = "SB_SET_QUESTION_IMAGE_POSITION",
  SB_SET_OPTION_FIELD = "SB_SET_OPTION_FIELD",
  SB_SET_SUB_OPTION_FIELD = "SB_SET_SUB_OPTION_FIELD",
  SB_RESET_QUESTIONNAIRE = "SB_RESET_QUESTIONNAIRE",
  SB_RESET_QUESTIONNAIRE_SUCCESS = "SB_RESET_QUESTIONNAIRE_SUCCESS",
  SB_RESET_QUESTIONNAIRE_ERROR = "SB_RESET_QUESTIONNAIRE_ERROR",
  SB_SUBMIT_QUESTIONNAIRE = "SB_SUBMIT_QUESTIONNAIRE",
  SB_SUBMIT_QUESTIONNAIRE_SUCCESS = "SB_SUBMIT_QUESTIONNAIRE_SUCCESS",
  SB_SUBMIT_QUESTIONNAIRE_ERROR = "SB_SUBMIT_QUESTIONNAIRE_ERROR",
  SB_GET_SURVEY_CONFIGS = "SB_GET_SURVEY_CONFIGS",
  SB_GET_SURVEY_CONFIGS_SUCCESS = "SB_GET_SURVEY_CONFIGS_SUCCESS",
  SB_GET_SURVEY_CONFIGS_ERROR = "SB_GET_SURVEY_CONFIGS_ERROR",
  SB_SET_SELECTED_QUESTION_VIRTUAL_ID = "SB_SET_SELECTED_QUESTION_VIRTUAL_ID",
  SB_SET_SELECTED_OPTION_VIRTUAL_ID = "SB_SET_SELECTED_OPTION_VIRTUAL_ID",
  SB_SET_SELECTED_SUB_OPTION_VIRTUAL_ID =
    "SB_SET_SELECTED_SUB_OPTION_VIRTUAL_ID",
  SB_REMOVE_OPTION_IMAGE = "SB_REMOVE_OPTION_IMAGE",
  SB_SET_SURVEY_COLORS = "SB_SET_SURVEY_COLORS",
  SB_SET_SURVEY_FONT_STYLES = "SB_SET_SURVEY_FONT_STYLES",
  SB_SET_SURVEY_FONT_SIZES = "SB_SET_SURVEY_FONT_SIZES",
  SB_EDIT_QUESTIONNAIRE = "SB_EDIT_QUESTIONNAIRE",
  SB_GET_QUESTIONNAIRE = "SB_GET_QUESTIONNAIRE",
  SB_GET_QUESTIONNAIRE_SUCCESS = "SB_GET_QUESTIONNAIRE_SUCCESS",
  SB_GET_QUESTIONNAIRE_ERROR = "SB_GET_QUESTIONNAIRE_ERROR",
  SB_GET_QUESTIONNAIRE_LIST = "SB_GET_QUESTIONNAIRE_LIST",
  SB_GET_QUESTIONNAIRE_LIST_SUCCESS = "SB_GET_QUESTIONNAIRE_LIST_SUCCESS",
  SB_GET_QUESTIONNAIRE_LIST_ERROR = "SB_GET_QUESTIONNAIRE_LIST_ERROR",
  SB_SET_QUESTIONNAIRE_ANTI_SPAMMER = "SB_SET_QUESTIONNAIRE_ANTI_SPAMMER";

// need to be replaced from backend data.
export const DEPENDENCY_TYPE = {
  QUESTION: "QU",
  OPTION: "OP",
  SUB_OPTION: "SO",
  DIVISION: "DI",
  CUSTOMER: "CU",
  MEDIUM: "ME",
  STAGE: "ST",
};

export const QUESTION_IMAGE_POSITION = {
  ABOVE: "A",
  BELOW: "B",
};

export const DEFAULT_QUESTION_IMAGE_CONFIGURATION = {
  width: "250",
  height: "250",
  position: QUESTION_IMAGE_POSITION.ABOVE,
  is_active: true,
};

export const APP_SCREEN_TYPES = {
  CHECK_BOX: "CB",
  TILE: "TI",
  IMAGE: "IM",
  NPS: "NP",
  EMOJI_NPS: "EM",
  AGE: "AG",
  GENDER: "GE",
  OPEN_ENDED: "OE",
  STAR: "ST",
  ICON: "IC",
  LIST: "LI",
  LABELLED_IMAGE: "LIM",
  TEXT_AREA: "TXA",
  TEXT_INPUT: "TXI",
  CONSENT: "CT",
  CONTACT_NUMBER: "CN",
  EMAIL_ADDRESS: "EA",
  MEDIA: "MD",
  PRODUCTS_LIST: "PR",
  DESCRIPTION: "DSC",
  MULTIPLE_QUESTION: "MQ",
};

export const REPRESENTATION_TYPES = [
  { code: "RO", choice: "Radio" },
  { code: "MCO", choice: "Multi-Color" },
];

export const QUESTION_KINDS = {
  DIVISION: "DI",
};

import React, { useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { updateSurveyInvite } from "./actions";
import { toast, Zoom } from "react-toastify";

const GROCommentModal = ({ initialText, inviteID, permissions }) => {
  const [text, setText] = useState(initialText);
  const [previousText, setPreviousText] = useState(initialText);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const maxLength = 255;

  const canSave = !isLoading && previousText !== text;
  const isAuthorized = permissions.can_add_survey_invite_comment;

  const toastProperties = {
    position: toast.POSITION.BOTTOM_CENTER,
    toastId: inviteID,
    autoClose: 2000,
    transition: Zoom,
    draggablePercent: 70,
    style: {
      width: "150px",
      margin: "auto",
      borderRadius: "10px",
    },
  };

  const handleSave = () => {
    setIsLoading(true);
    updateSurveyInvite(inviteID, { comment: text })
      .then((resp) => {
        if (resp.status >= 400) {
          throw resp.data?.detail;
        }
        toast.success("Updated", toastProperties);
        setPreviousText(text);
      })
      .catch((err) => {
        toast.error("Failed", toastProperties);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    setText(previousText);
    setShowModal(false);
  };
  const handleShow = () => setShowModal(true);

  return (
    <Col className="clear-col-padding" md={12} sm={12} xs={12}>
      <i className="fa fa-comments-o invoice-icon" onClick={handleShow} />
      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        backdrop={true}
      >
        <Modal.Header>
          <Modal.Title className="filter-header">Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="py-3 px-2">
            <Form.Group controlId="formText">
              <Form.Control
                type="text"
                placeholder="Add remarks..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={!isAuthorized}
                maxLength={maxLength}
              />
            </Form.Group>
            <Form.Text className="text-right">{`${maxLength - text.length} characters remaining`}</Form.Text>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          {isAuthorized && (
            <Button variant="success" onClick={handleSave} disabled={!canSave}>
              Save
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default GROCommentModal;

import React, { Component } from "react";
import { connect } from "react-redux";
import { keys } from "lodash";

import {
  loadAnalyzerData,
  selectAnalyzerUnit,
  selectAnalyzerGraph,
} from "../actions";
import Chart from "./charts";
import Table from "../Table/DataTable";
import { apiCalls } from "components/DashboardPage/apis";
import { Card, CardValue } from "components/common/Card";
import { NoDataFound } from "components/common/NoDataFound";
import { mixRedGreenColors } from "utils/genericHelper";
import SkeletonTable from "components/common/Skeleton/SkeletonTable";
import SkeletonText from "components/common/Skeleton/SkeletonText";
import SkeletonGraph from "components/common/Skeleton/SkeletonGraph";
import {
  CHECK_DATE_REFRESH_TIME,
  ALLTIME_DATE_REFRESH_TIME,
} from "components/common/DateFilter/DateFilter";

class Analyzer extends Component {
  intervalId = null;

  componentDidMount = () => {
    this.fetchData();
    const refreshTime = getRefreshTime(
      "analyzer",
      this.props.selectedFilters.dateFilterType,
      this.props.selectedFilters.defaultDateRange,
    );
    if (this.shouldLiveUpdate()) {
      this.intervalId = setInterval(() => this.fetchData(true), refreshTime);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.selectedFilters.query !== prevProps.selectedFilters.query &&
      this.props.selectedDashboardId === prevProps.selectedDashboardId
    ) {
      this.fetchData();
    }
  };

  componentWillUnmount = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    const previousCall = apiCalls[this.props.analyzer.slug];
    if (previousCall) previousCall.cancel();
  };

  fetchData = (autoFetch) => {
    this.props.loadAnalyzerData(this.props.analyzer, autoFetch);
  };

  shouldLiveUpdate = () => {
    const { analyzer, permissions } = this.props;
    return (
      !analyzer.live_update_permission ||
      permissions[analyzer.live_update_permission]
    );
  };

  renderLoading = () => {
    const { analyzer, graph } = this.props;
    let type = analyzer.type;
    if (graph === "table") type = graph;

    const Skeleton = skeletons[type];

    return <Skeleton />;
  };

  renderError = () => {
    return <CardValue suffix="!" caption="Error" />;
  };

  renderText = () => {
    const { data, unit, analyzer, loading } = this.props;
    const colors = analyzer.colors;
    const sizes = getValueSizeClass(data, unit);
    return data.map((d, i) => {
      const isPercentage = unit === "percentage";
      const suffix = isPercentage ? "%" : d.suffix;
      const value = d[unit];

      const textColor =
        colors && colors.length && colors.length > i
          ? colors[i]
          : this.getTextColor();
      const headingColor = this.getHeadingColor();

      return (
        <CardValue
          key={i}
          value={value}
          suffix={suffix}
          hover={d.hover}
          prefix={d.prefix}
          caption={d.label}
          sizeClasses={sizes}
          asHeader={!analyzer.heading}
          isPercentage={isPercentage}
          textColor={d.color || textColor}
          headingColor={headingColor}
          numberFormat={analyzer.number_format}
          tooltip={!analyzer.heading && !loading && analyzer.tooltips?.[i]}
          headingClass={
            !analyzer.heading &&
            getHeadingSizeClass(d.label, { lg: analyzer.sizes.lg - 2 })
          }
        />
      );
    });
  };

  renderGraph = () => {
    const { unit, graph, data, analyzer } = this.props;
    return <Chart analyzer={analyzer} unit={unit} graph={graph} data={data} />;
  };

  renderTable = () => {
    const { data, analyzer } = this.props;
    return (
      <Table
        data={data}
        analyzer={analyzer}
        heading={this.getAnalyzerHeading()}
      />
    );
  };

  renderers = {
    text: this.renderText,
    graph: this.renderGraph,
    table: this.renderTable,
  };

  renderData = () => this.renderers[this.props.analyzer.type]();

  noData = () => {
    return <NoDataFound />;
  };

  hasError = () => {
    const { analyzer, error, unit } = this.props;
    const { type } = analyzer;
    const data = this.props.data?.cumulative
      ? this.props.data.cumulative
      : this.props.data;
    return (
      error ||
      !data ||
      (type === "graph" && !data[unit]) ||
      (type === "text" && !data.length) ||
      (type === "text" && !data[0][unit] && data[0][unit] !== 0)
    );
  };

  getAnalyzerHeading = () => {
    const { analyzer, error, data } = this.props;
    if (error || data?.noData) {
      return analyzer.heading || analyzer.default_heading;
    }
    return analyzer.heading;
  };

  getBackgroudColor = () => {
    const { analyzer, data, unit } = this.props;
    const colors = analyzer.colors && analyzer.colors.background;
    const value = data?.length && data[0][unit];

    const color =
      analyzer.bg_color && analyzer.bg_color === "dynamic" && value
        ? mixRedGreenColors(value)
        : colors && value
        ? getRequiredColor(colors, value)
        : undefined;

    return color || "white";
  };

  getHeadingColor = () => {
    const { analyzer, data, unit } = this.props;
    if (analyzer.bg_color && analyzer.bg_color === "dynamic") {
      return "white";
    }
    const colors =
      analyzer.colors &&
      analyzer.colors.foreground &&
      analyzer.colors.foreground.heading;
    const value = data?.length && data[0][unit];

    let color = colors && value && getRequiredColor(colors, value);

    return color;
  };

  getTextColor = () => {
    const { analyzer, data, unit } = this.props;
    if (analyzer.bg_color && analyzer.bg_color === "dynamic") {
      return "white";
    }

    const colors =
      analyzer.colors &&
      analyzer.colors.foreground &&
      analyzer.colors.foreground.value;
    const value = data?.length && data[0][unit];

    let color = colors && value && getRequiredColor(colors, value);

    return color;
  };

  render = () => {
    const { analyzer, data, loading, unit, graph } = this.props;
    return (
      <Card
        units={analyzer.units}
        selectedUnit={unit}
        graphs={analyzer.graphs}
        selectedGraph={graph}
        heading={this.getAnalyzerHeading()}
        selectUnit={(u) => this.props.selectAnalyzerUnit(analyzer.slug, u)}
        selectGraph={(g) => this.props.selectAnalyzerGraph(analyzer.slug, g)}
        type={analyzer.type}
        dim={loading === true && data}
        backgroundColor={this.getBackgroudColor()}
        headingColor={this.getHeadingColor()}
        headingClass={getHeadingSizeClass(analyzer.heading, analyzer.sizes)}
        tooltip={analyzer.heading && analyzer.tooltips?.[0]}
        tooltipPosition={analyzer.heading && analyzer.tooltip_positions?.[0]}
        loadingMore={loading === "loadingMore"}
      >
        {!data && loading
          ? this.renderLoading()
          : data?.noData
          ? this.noData()
          : this.hasError()
          ? this.renderError()
          : this.renderData()}
      </Card>
    );
  };
}

const ANALYZER_REFRESH_INTERVAL = 15 * 60 * 1000;
const ANALYZER_REFRESH_INTERVAL_ALL_TIME = 60 * 60 * 1000;

const skeletons = {
  graph: SkeletonGraph,
  text: SkeletonText,
  table: SkeletonTable,
};

const mapDispatchToProps = {
  loadAnalyzerData,
  selectAnalyzerUnit,
  selectAnalyzerGraph,
};

const mapStateToProps = (state, ownProps) => {
  const [unit] = keys(state.dashboard.globalView.unit);
  const [graph] = keys(state.dashboard.globalView.graph);
  return {
    selectedFilters: state.selectedFilters,
    permissions: state.auth.user.permissions,
    data: state.dashboard.data[ownProps.analyzer.slug],
    error: state.dashboard.error[ownProps.analyzer.slug],
    loading: state.dashboard.loading[ownProps.analyzer.slug],
    selectedDashboardId: state.dashboard.selectedDashboard.id,
    unit: ownProps.analyzer.units.includes(unit)
      ? unit
      : state.dashboard.selectedUnits[ownProps.analyzer.slug] ||
        ownProps.analyzer.units[0],
    graph: ownProps.analyzer.graphs.includes(graph)
      ? graph
      : state.dashboard.selectedGraphs[ownProps.analyzer.slug] ||
        ownProps.analyzer.graphs[0],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analyzer);

const getHeadingSizeClass = (heading, boxSize) => {
  const sizes = { sm: 17, md: 17, lg: 19 };
  if (boxSize.lg === 2 && heading?.length > 15) {
    sizes.sm = 15;
    sizes.md = 16;
    sizes.lg = 16;
  }
  return Object.keys(sizes).reduce(
    (classes, key) => classes + `fontsize-${key}-${sizes[key]} `,
    "",
  );
};

const getValueSizeClass = (value) => {
  const sizes = { sm: 35, md: 35, lg: 35 };
  switch (typeof value) {
    case "number": {
      if (value > 99999) {
        sizes.sm = 30;
        sizes.md = 30;
        sizes.lg = 30;
      }
      break;
    }
    case "string": {
      if (value.length > 7) {
        sizes.sm = 30;
        sizes.md = 30;
        sizes.lg = 30;
      }
      break;
    }
  }
  return Object.keys(sizes).reduce(
    (classes, key) => classes + `fontsize-${key}-${sizes[key]} `,
    "",
  );
};

const getRequiredColor = (colors, value) => {
  let requiredColor;
  for (var color in colors) {
    if (color === undefined) continue;

    if (value >= color) requiredColor = colors[color];
  }

  return requiredColor || colors.default;
};

export const getRefreshTime = (type, dateFilterType, defaultDateRange) => {
  if (dateFilterType === "Default" && defaultDateRange === "All Time") {
    if (type === "analyzer") {
      return ANALYZER_REFRESH_INTERVAL_ALL_TIME;
    } else if (type === "datefilter") {
      return ALLTIME_DATE_REFRESH_TIME;
    }
  }
  if (type === "analyzer") {
    return ANALYZER_REFRESH_INTERVAL;
  } else if (type === "datefilter") {
    return CHECK_DATE_REFRESH_TIME;
  }
};

import React, { useState, useEffect, useRef } from "react";
import i18n from "i18n";
import {
  SelectWrapper,
  Select,
  Text,
  DropdownMenu,
  MenuItem,
  DropdownMenuItemList,
} from "./styled";

import { ReactComponent as BarIcon } from "assets/svg/bar.svg";
import { ReactComponent as PieIcon } from "assets/svg/pie.svg";
import { ReactComponent as LineIcon } from "assets/svg/line.svg";
import { ReactComponent as TableIcon } from "assets/svg/table.svg";
import { CountIcon } from "assets/svg/count.js";
import { ReactComponent as TrendIcon } from "assets/svg/trend.svg";
import { ReactComponent as PercentageIcon } from "assets/svg/percentage.svg";

export const Dropdown = ({
  options,
  selectedOption,
  onClick,
  getLabel,
  getKey,
  getStatus,
  align,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const renderedOptions = options.map((option) => {
    const label = getLabel(option);
    const key = getKey(option);
    const selected = getKey(option) === getKey(selectedOption) && "selected";

    const Icon = icons[label];
    return (
      <MenuItem
        key={key}
        className={`item custom_dropdown-item ${getStatus(option)} ${selected}`}
        onClick={() => onClick(option)}
      >
        {Icon && <Icon />}
        <span>{i18n.t(label)}</span>
      </MenuItem>
    );
  });

  const selectedLabel = selectedOption && getLabel(selectedOption);
  const Icon = icons[selectedLabel];

  return (
    <SelectWrapper className="custom_dropdown-wrapper" ref={ref} open={open}>
      <Select
        className="custom_dropdown"
        onClick={() => setOpen(!open)}
        open={open}
      >
        <Text className="custom_dropdown-text">{Icon && <Icon />}</Text>
        <DropdownMenu
          className="custom_dropdown-menu"
          open={open}
          align={align}
        >
          <DropdownMenuItemList className="custom_dropdown-menu-list">
            {renderedOptions}
          </DropdownMenuItemList>
        </DropdownMenu>
      </Select>
    </SelectWrapper>
  );
};

const icons = {
  pie: PieIcon,
  bar: BarIcon,
  line: LineIcon,
  donut: PieIcon,
  table: TableIcon,
  count: CountIcon,
  trend: TrendIcon,
  percentage: PercentageIcon,
};

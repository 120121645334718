import React from "react";
import SelectedFeedback from "./SelectedFeedback";
import Remarks from "components/FeedbackPage/Remarks/Remarks";
import { ChatBox } from "components/FeedbackPage/ChatBox";
import { isCustomerView } from "components/FeedbackPage/selectors";
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import * as Styled from "./styled";

const FeedbackCard = ({
  selectedFeedback,
  user,
  isDetailPage,
  onClick,
  selected,
  isFromNotification,
}) => {
  const visibility = React.useContext(VisibilityContext);
  return (
    <Styled.FeedbackDetailList
      onClick={() => onClick(visibility)}
      selected={selected}
      isFromNotification={isFromNotification}
    >
      <Styled.ScrollableFeedback
        selected={selected}
        isFromNotification={isFromNotification}
      >
        <SelectedFeedback
          selectedFeedback={selectedFeedback}
          selected={selected}
        />
        {!isDetailPage && (
          <>
            <Remarks feedback={selectedFeedback} />
            {user.permissions.can_message_respondents &&
              !selectedFeedback.hide_connect_box && (
                <ChatBox feedback={selectedFeedback} />
              )}
            {isCustomerView() &&
              selectedFeedback.follow_up_invite &&
              selectedFeedback.follow_up_invite.status === "Pending" && (
                <a href={selectedFeedback.follow_up_invite.short_link}>
                  {selectedFeedback.follow_up_invite.short_link}
                </a>
              )}
          </>
        )}
      </Styled.ScrollableFeedback>
    </Styled.FeedbackDetailList>
  );
};

export default FeedbackCard;

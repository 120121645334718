import styled from "styled-components";
import { Row } from "react-bootstrap";

export const Container = styled(Row)`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 20px;
`;

export const FeedbackList = styled.div`
  height: 80vh;
  overflow: scroll;
`;

export const NotFound = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 20px 10px;
  border: 1px solid #e7eaec;
  color: #4d5153;
  font-size: 14px;
  line-height: 20px;
`;

const getTimeSeriesChartData = (stats, userPermissions) => {
  let commonDataset = [
    {
      label: "NPS",
      fill: false,
      borderColor: "rgba(95, 124, 25, 1)",
      backgroundColor: "rgba(95, 124, 25, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["NPS"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["NPS"]),
    },
    {
      label: "Promoters",
      fill: false,
      borderColor: "rgba(75, 174, 79, 1)",
      backgroundColor: "rgba(75, 174, 79, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Promoters (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Promoters"]),
    },
    {
      label: "Passives",
      fill: false,
      borderColor: "rgba(255, 160, 55, 1)",
      backgroundColor: "rgba(255, 160, 55, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Passives (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Passives"]),
    },
    {
      label: "Detractors",
      fill: false,
      borderColor: "rgba(255, 64, 64, 1)",
      backgroundColor: "rgba(255, 64, 64, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Detractors (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Detractors"]),
    },
  ];
  if (userPermissions.includes("api_action_stats")) {
    commonDataset.push({
      label: "Recovered",
      fill: false,
      borderColor: "rgba(32, 149, 242, 1)",
      backgroundColor: "rgba(32, 149, 242, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Recovered (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Recovered"]),
    });
    commonDataset.push({
      label: "Not Recovered",
      fill: false,
      borderColor: "rgba(32, 70, 200, 1)",
      backgroundColor: "rgba(32, 70, 200, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Not recovered (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Not recovered"]),
    });
    commonDataset.push({
      label: "Unrecoverable",
      fill: false,
      borderColor: "rgba(95, 124, 138, 1)",
      backgroundColor: "rgba(95, 124, 138, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Unrecoverable (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Unrecoverable"]),
    });
    commonDataset.push({
      label: "Unprocessed",
      fill: false,
      borderColor: "rgba(95, 90, 90, 1)",
      backgroundColor: "rgba(95, 90, 90, 1)",
      borderWidth: 2,
      data: [...stats].reverse().map((s) => s["Unprocessed (%)"]),
      type: "line",
      count: [...stats].reverse().map((s) => s["Unprocessed"]),
    });
  }
  return {
    labels: [...stats]
      .reverse()
      .map((stat) =>
        stat.Tag.length > 16 ? stat.Tag.slice(0, 16) + "..." : stat.Tag,
      ),
    datasets: commonDataset,
    ranges: [...stats].reverse().map((s) => ({
      text: s.Span,
      label: s.Tag,
    })),
  };
};

const getKPITrendChartData = (stats) => ({
  labels: stats.map((stat) => stat.date),
  datasets: [
    {
      fill: true,
      lineTension: 0.1,
      backgroundColor: "rgba(75,192,192,0.4)",
      borderColor: "rgba(75,192,192,1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(75,192,192,1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: stats.map((stat) => stat.value),
    },
  ],
});

const getSatisfactionLevelChartData = (satisfactionLevel) => ({
  labels: ["Satisfaction Segments"],
  datasets: satisfactionLevel.options.map((option, index) => ({
    label: option,
    backgroundColor: satisfactionLevel.color_codes[index],
    data: [satisfactionLevel.stats.counts[index]],
  })),
});

const getCustomerSegmentationChartData = (customerSegmentations) => ({
  labels: customerSegmentations.options,
  datasets: [
    {
      backgroundColor: customerSegmentations.color_codes,
      data: customerSegmentations.counts.map((count) => count),
    },
  ],
});

const getGenderGroupAnalysisGraphData = (genderGroup) => ({
  labels: genderGroup.map((gg) => gg.option),
  datasets: [
    {
      label: "Female",
      backgroundColor: "#e81d62",
      data: genderGroup.map((gg) => gg.count.female_percentage),
    },
    {
      label: "Male",
      backgroundColor: "#26AAE2",
      data: genderGroup.map((gg) => gg.count.male_percentage),
    },
  ],
});

const getSemanticAnalysisChartData = (SemanticAnalysis) => ({
  labels: SemanticAnalysis.map((topConcern) => topConcern.keyword),
  datasets: [
    {
      backgroundColor: SemanticAnalysis.map(
        (topConcern) => topConcern.color_code,
      ),
      data: SemanticAnalysis.map((topConcern) => topConcern.stats.percentage),
    },
  ],
});

const getNpsChartData = (nps) => ({
  labels: ["Percentage"],
  datasets: nps.options.map((option, index) => ({
    label: option,
    backgroundColor: nps.color_codes[index],
    data: [nps.stats.percentages[index]],
  })),
});

const getCompetitorAnalysisChartData = (data) => ({
  labels: [""],
  datasets: data.map((_data) => ({
    label: _data.option,
    backgroundColor: [_data.color_code],
    data: [_data.stats],
  })),
});

export {
  getTimeSeriesChartData,
  getSatisfactionLevelChartData,
  getCustomerSegmentationChartData,
  getGenderGroupAnalysisGraphData,
  getSemanticAnalysisChartData,
  getNpsChartData,
  getCompetitorAnalysisChartData,
  getKPITrendChartData,
};

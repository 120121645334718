import React, { useEffect } from "react";
import { format } from "date-fns";
import { connect } from "react-redux";
import numeral from "numeral";
import { useNavigate } from "react-router-dom";

import {
  CommentsContainer,
  CommentBox,
  CommentItemHeader,
  CommentItemContent,
  CommentItemFooter,
  StyledLocation,
  TotalCount,
  Badge,
  StyledLeftItem,
  StyledTimeStamp,
} from "./styled";
import { loadComments, loadMoreComments } from "../../DashboardPage/actions";
import { NoDataFound } from "../NoDataFound";
import {
  Card,
  CardWrapper,
  CardHeader,
  CardHeading,
  CardLoadingIcon,
} from "../Card/styled";
import { TooltipText } from "../Tooltip";
import SkeletonComments from "../Skeleton/SkeletonComments";
import { TOOLTIP_MESSAGE } from "constants/constants";
import MiniSpinner from "components/common/MiniSpinner/MiniSpinner";

export const CommentItem = ({ item }) => {
  const navigate = useNavigate();

  const handleOnCommentClick = () => {
    navigate(
      `/feedback/customer-recovery/${item.feedback_encoded_url.slice(0, -2)}`,
    );
  };

  return (
    <CommentBox onClick={handleOnCommentClick}>
      <CommentItemHeader>
        <StyledTimeStamp>
          {format(new Date(item.created_at), "eeee, MMMM do yyyy, h:mm aa")}
        </StyledTimeStamp>
      </CommentItemHeader>
      <CommentItemContent>{item.comment}</CommentItemContent>
      <CommentItemFooter>
        {!!item.concerns.length && (
          <StyledLeftItem>
            {item.concerns.map((_item) => (
              <>
                <Badge>{_item}</Badge>
              </>
            ))}
          </StyledLeftItem>
        )}
        {(item.primary_division || item.division) && (
          <StyledLocation>
            {item.primary_division}
            <span>{item.division && ` / ${item.division}`}</span>
          </StyledLocation>
        )}
      </CommentItemFooter>
    </CommentBox>
  );
};

const Comments = ({
  comments,
  selectedFilters,
  loadComments,
  loadMoreComments,
}) => {
  const { isLoading, isMoreLoading, data } = comments;
  const { next, results } = data;

  useEffect(() => {
    loadComments();
  }, [selectedFilters]);

  const lazyLoad = (e) => {
    if (
      e.target.offsetHeight + e.target.scrollTop < e.target.scrollHeight ||
      !next
    ) {
      return;
    }
    loadMoreComments(next);
  };

  return (
    <Card className="ui-card">
      <CardWrapper className="ui-card-wrapper">
        <CardHeader className="ui-card-header">
          {isMoreLoading && (
            <CardLoadingIcon>
              <MiniSpinner />
            </CardLoadingIcon>
          )}
          <CardHeading
            className={`ui-card-heading ${!!data.count && "commentbox"}`}
            style={{ width: "100%" }}
          >
            <span className="tooltipRel">
              Comments
              <TooltipText tooltipText={TOOLTIP_MESSAGE.COMMENTS} />
            </span>
            {!!data.count && (
              <TotalCount>
                Total Count: <span>{numeral(data.count).format("0,0")}</span>
              </TotalCount>
            )}
          </CardHeading>
        </CardHeader>
        {isLoading ? (
          <SkeletonComments />
        ) : !data.results.length ? (
          <NoDataFound />
        ) : (
          <CommentsContainer onScroll={lazyLoad}>
            {results.map((item) => (
              <CommentItem item={item} />
            ))}
          </CommentsContainer>
        )}
      </CardWrapper>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  comments: state.dashboard.comments,
  selectedFilters: state.selectedFilters,
});

const mapDispatchToProps = {
  loadComments,
  loadMoreComments,
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);

import React, { Component } from "react";
import { connect } from "react-redux";
import { Doughnut } from "react-chartjs-2";
import Spinner from "react-spinkit";
import isEmpty from "lodash/isEmpty";
import { Panel } from "../../common/Panel";
import _ from "lodash";

import { getCustomerSegmentationChartData } from "../../../utils/chartsData";
import { loadCustomerSegmentation } from "../actions";

class CustomerSegmentation extends Component {
  getAPI() {
    const { loadCustomerSegmentation, selectedConcern } = this.props;
    loadCustomerSegmentation(selectedConcern);
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.selectedFilters, this.props.selectedFilters) ||
      this.props.selectedConcern !== prevProps.selectedConcern
    ) {
      this.getAPI();
    }
  }

  render() {
    const { customerSegmentation } = this.props;
    const { fetching, data } = customerSegmentation;

    if (isEmpty(data) || fetching) {
      return (
        <Panel header="Customer Segmentation">
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    return (
      <Panel header="Customer Segmentation">
        <Doughnut
          data={getCustomerSegmentationChartData(data)}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, insights, dashboard }) => ({
  selectedFilters,
  selectedConcern: insights.selectedConcern,
  customerSegmentation: dashboard.customerSegmentation,
});

export default connect(mapStateToProps, {
  loadCustomerSegmentation,
})(CustomerSegmentation);

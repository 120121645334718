import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import ShiftFilterDropDown from "./ShiftFilterDropDown";
import ShiftFilterLabel from "./ShiftFilterLabel";

import {
  setShiftFilter,
  resetShiftFilter,
} from "components/DashboardPage/actions";

import { BootDropdown, BootDropdownToogle, BootDropdownMenu } from "./styled";
import { TooltipText } from "../Tooltip";
import { TOOLTIPS } from "constants/constants";

class ShiftFilter extends Component {
  state = { visible: false };
  ref = createRef();

  componentDidMount = () => {
    document.body.addEventListener("click", this.onBodyClick);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener("click", this.onBodyClick);
  };

  onBodyClick = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.onHide();
    }
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  render = () => {
    if (!this.props.permissions[SHIFT_FILTER_PERMISSION]) {
      return null;
    }

    const { days, endTime, startTime } = this.props;
    return (
      <>
        <BootDropdown
          ref={this.ref}
          show={this.state.visible}
          className="tooltipRel"
        >
          <BootDropdownToogle
            className="option selected-range"
            onClick={() => this.setState({ visible: !this.state.visible })}
            id="shift-filter-dropdown"
          >
            <ShiftFilterLabel
              days={days}
              endTime={endTime}
              startTime={startTime}
            />
          </BootDropdownToogle>
          <BootDropdownMenu>
            {this.state.visible && (
              <ShiftFilterDropDown
                days={days}
                endTime={endTime}
                onHide={this.onHide}
                startTime={startTime}
                setShiftFilter={this.props.setShiftFilter}
                resetShiftFilter={this.props.resetShiftFilter}
              />
            )}
          </BootDropdownMenu>
          {!this.state.visible && (
            <TooltipText
              tooltipText={TOOLTIPS.SHIFT_FILTER}
              align="bottomLeft"
            />
          )}
        </BootDropdown>
      </>
    );
  };
}

const mapDispatchToProps = {
  setShiftFilter,
  resetShiftFilter,
};

const mapStateToProps = (state) => {
  return {
    days: state.selectedFilters.days,
    endTime: state.selectedFilters.endTime,
    startTime: state.selectedFilters.startTime,
    permissions: state.auth.user.permissions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftFilter);

const SHIFT_FILTER_PERMISSION = "filter_shift";

import { updateFirebaseToken } from "components/LoginPage/actions";
import { dispatchNotification } from "./utils";

import { initializeApp } from "firebase/app";
import {
  getMessaging,
  isSupported,
  getToken,
  onMessage,
} from "firebase/messaging";

const config = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = initializeApp(config);

class FirebaseService {
  constructor() {
    this.messaging = getMessaging(firebaseApp);
    this.registerFirebaseOnMessageHandler(firebaseApp);
  }

  requestFirebaseNotificationPermission = () => {
    return new Promise((resolve, reject) => {
      isSupported().then((supported) => {
        if (!supported) {
          // console.log("Firebase SDK not supported in your browser.");
          return reject;
        }
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            getToken(this.messaging, { vapidKey: config.apiKey })
              .then((firebaseToken) => {
                return resolve(firebaseToken);
              })
              .catch((err) => {
                return reject(err);
              });
          }
        });
      });
    });
  };

  registerFirebaseOnMessageHandler = (firebaseApp) => {
    isSupported().then((supported) => {
      if (!supported) {
        // console.log("Firebase SDK not supported in your browser.");
        return;
      }

      onMessage(
        this.messaging,
        (payload) => {
          dispatchNotification(payload);
        },
        (err) => {
          /*console.log({ err, context: "firebaseOnMessage" })*/
        },
      );
    });
  };

  setupFirebaseNotification(store) {
    this.requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        store.dispatch(updateFirebaseToken(firebaseToken));
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  }
}

const firebaseService = new FirebaseService();

export { firebaseService };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CascadedSelect from "components/common/CascadedSelect/CascadedSelect";

import { setFormFieldValue, loadConcern } from "components/RCA/actions";
import {
  formFieldValueSelector,
  allConcernsSelector,
  locationMatchErrorSelector,
  isFieldRequired,
} from "components/RCA/selectors";

import { DeleteModal } from "components/common/DeleteModal";

import { keys } from "lodash";

const ConcernField = ({ field }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const allConcerns = useSelector((state) => allConcernsSelector(state)) || [];

  const locations =
    useSelector((state) => formFieldValueSelector(state, "location")) || null;

  const locationTitles = keys(locations).flatMap(
    (level) => locations[level].titles,
  );

  const isLoopRequired = useSelector((state) => isFieldRequired(state, "loop"));
  const loop =
    useSelector((state) => formFieldValueSelector(state, "loop")) || null;

  const otherConcern =
    useSelector((state) => formFieldValueSelector(state, "other_concern")) ||
    null;

  const locationError = useSelector(locationMatchErrorSelector) || "";

  const selectedConcern = useSelector((state) =>
    formFieldValueSelector(state, fieldKey),
  ) || { id: "other", name: otherConcern };

  const other_concern_field = {
    size: { sm: 12, lg: 6 },
    label: "Problem to solve",
    key: "other_concern",
    type: "text",
  };

  const [isPopup, setIsPopup] = useState(false);

  const setOtherConcern = (value) => {
    dispatch(setFormFieldValue("other_concern", value));
    dispatch(setFormFieldValue(fieldKey, null));
  };

  const selectConcern = (concern) => {
    if (concern?.id === "other") {
      !isPopup && setIsPopup(true);
    } else {
      isPopup && setIsPopup(false);
      const _concern = { id: concern.id, name: concern.name };
      dispatch(setFormFieldValue(fieldKey, _concern));
      dispatch(setFormFieldValue("other_concern", null));
    }
  };

  useEffect(() => {
    if (locationTitles.length && (loop || !isLoopRequired) && !locationError) {
      dispatch(loadConcern());
    }
  }, [locations, loop, locationError]);

  const concerns = allConcerns.map(convertConcernToOption);
  concerns.push({
    id: "other",
    name: `${(otherConcern && otherConcern + " (edit)") || "Other"}️`,
  });

  const toolTip = isLoopRequired
    ? "Kindly fill in the loop and location field to select concern"
    : "Kindly fill in the location field to select concern";

  return (
    <>
      {isPopup && (
        <DeleteModal
          msg="Enter the problem you want to solve"
          isDelete={false}
          onPopupClose={() => setIsPopup(false)}
          onChange={setOtherConcern}
          value={otherConcern}
          field={other_concern_field}
          isConcern={true}
        />
      )}
      <CascadedSelect
        field={field}
        options={concerns}
        onSelect={selectConcern}
        selectedOption={selectedConcern}
        disabled={
          !locationTitles.length || (!loop && isLoopRequired) || locationError
        }
        toolTip={toolTip}
      />
    </>
  );
};

export default ConcernField;

const convertConcernToOption = (concern) => ({
  id: concern.id,
  name: concern.name,
  percentage: concern.percentage || "",
  nested: concern.children?.map(convertConcernToOption) || [],
});

import React from "react";
import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
      }}
    >
      <Skeleton width={"100%"} height={"45px"} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
    </div>
  );
};

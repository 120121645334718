import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { values } from "lodash";

import { CustomDropdown } from "components/common/Dropdown";
import { DropdownContainer } from "components/common/DropdownContainer";

import { setAnalysisType } from "components/common/filters/actions";

import {
  DurationalAnalysisType,
  DurationalAnalysisLabel,
} from "constants/constants";

const DurationalFilter = ({ analysisType }) => {
  const dispatch = useDispatch();

  const selectedAnalysis =
    AnalysisTypes.find((analysis) => analysis.type === analysisType) ||
    AnalysisTypes[0];

  return (
    <Container>
      <DropdownContainer>
        <CustomDropdown
          options={AnalysisTypes}
          getLabel={(o) => o.label}
          getKey={(o) => o.type}
          selectedOption={selectedAnalysis}
          onClick={(o) => dispatch(setAnalysisType(o.type))}
        />
      </DropdownContainer>
    </Container>
  );
};

export default DurationalFilter;

const AnalysisTypes = values(DurationalAnalysisType).map((type) => {
  return { type, label: DurationalAnalysisLabel[type] };
});

const Container = styled.div`
  margin: 15px;
`;

import { call, takeLatest, select, put } from "redux-saga/effects";
import { useNavigate } from "react-router-dom";

import * as analytics from "utils/analytics";
import * as apis from "./api";
import * as actions from "./actions";
import * as constants from "constants/constants";

import { store } from "store/store";
import { selectUserId } from "./selectors";
import { getFilters } from "utils/filtersHelperFuncs";
import { setInitialDateFilters } from "components/saga";
import { getOrganizationId } from "components/selectors";
import { storeDashboards } from "components/DashboardPage/saga";
import { isFalsy, getOrganizationSlug } from "utils/genericHelper";
import { firebaseService } from "services/firebase/firebase";

let lastHeartBeatIntervalId = null;

function updateLastHeartbeat() {
  return setInterval(() => {
    store.dispatch(actions.updateUserLastHeartbeat());
  }, 120000);
}

function* loadAuthUser(action) {
  try {
    const organizationId = yield select(getOrganizationId);
    const response = yield call(apis.authApi, action.params, organizationId);
    if (response?.data?.username || response?.data?.password) {
      const error = {
        detail: response.data.username
          ? "Username can't be empty"
          : "Password can't be empty",
      };

      yield put(actions.loadAuthError(error));
    } else if (response.status >= 400) {
      yield put(actions.loadAuthError(response.data.detail));
    } else {
      localStorage.setItem(
        `${getOrganizationSlug()}_tokenExpiresAt`,
        response.token_expires_at,
      );
      localStorage.setItem(`${getOrganizationSlug()}_userId`, response.user_id);
      localStorage.setItem(`${getOrganizationSlug()}_token`, response.token);
      yield put(actions.loadAuthSuccess(response));
      yield put(actions.loadUserInfo());
      if (response.user.reset_password) {
        useNavigate("/reset-password");
      }
    }
  } catch (error) {
    yield put(actions.loadAuthError(error));
  }
}

function* authWatcher() {
  yield takeLatest(constants.LOAD_AUTH, loadAuthUser);
}

export function removeAuthLocalStorage() {
  localStorage.removeItem(`${getOrganizationSlug()}_userId`);
  localStorage.removeItem(`${getOrganizationSlug()}_token`);
  localStorage.removeItem("roleAbbreviation");
  localStorage.removeItem("survey");
  localStorage.removeItem("surveyConfig");
  localStorage.removeItem("surveySelectedState");
  localStorage.removeItem(`${getOrganizationSlug()}_tokenExpiresAt`);
  localStorage.removeItem(`${getOrganizationSlug()}_filters`);
  localStorage.removeItem(`${getOrganizationSlug()}_organizationId`);
  localStorage.removeItem("oldDate");
  localStorage.removeItem("selectedGraphs");
  localStorage.removeItem("selectedUnits");
  localStorage.removeItem("state");
  localStorage.removeItem("globalView");
  localStorage.removeItem("submitted_invites");
}

function* unauthUser() {
  try {
    yield call(apis.unauthApi);
    removeAuthLocalStorage();

    yield put(actions.unauthUser());
    yield call(clearInterval, lastHeartBeatIntervalId);
  } catch (error) {
    yield put(actions.loadAuthError(error));
  }
}

function* unauthWatcher() {
  yield takeLatest(constants.UNAUTH_USER_REQUEST, unauthUser);
}

function* loadUserInfo() {
  try {
    const orgId = yield select(getOrganizationId);
    const userId = yield localStorage.getItem(
      `${getOrganizationSlug()}_userId`,
    ) || select(selectUserId);
    const { dashboards, ...user } = yield call(apis.userInfoApi, userId, orgId);
    // i18n.changeLanguage(user.preferred_lang);
    localStorage.setItem("roleAbbreviation", user.role.abbreviation);
    // yield put(actions.setLanguagesList(user.languages));
    yield storeDashboards(dashboards);
    const groups = user.feedback_groups.reduce((_groups, group) => {
      _groups[group.id] = group;
      return _groups;
    }, {});
    yield put(actions.storeGroups(groups));
    if (isFalsy(getFilters())) {
      yield put(actions.setDefaultGroup(user));
    }
    user.permissions = user.role.permissions.reduce((obj, permission) => {
      obj[permission] = true;
      return obj;
    }, {});
    yield setInitialDateFilters();
    yield put(actions.loadUserInfoSuccess(user));

    lastHeartBeatIntervalId = yield call(updateLastHeartbeat);
    firebaseService.setupFirebaseNotification(store);

    analytics.setAnalyticsValue({ userId: user.id });
    analytics.setAnalyticsValue({ metric2: user.id });
    analytics.setAnalyticsValue({ dimension2: user.id });
    analytics.setAnalyticsValue({ dimension3: user.email });
    analytics.setAnalyticsValue({ dimension6: user.username });
    analytics.setAnalyticsValue({
      dimension4: user.divisions.reduce((initialValue, division) => {
        return (initialValue = `${division.id}|${division.title},`);
      }, ""),
    });
    analytics.setAnalyticsValue({ dimension5: user.role.title });
    analytics.logDashboardOpened();
  } catch (error) {
    yield put(actions.loadUserInfoError(error));
  }
}

function* userInfoWatcher() {
  yield takeLatest(constants.LOAD_USER_INFO, loadUserInfo);
}

function* updateUserFirebaseToken(action) {
  try {
    yield call(apis.updateFirebaseTokenApi, action.firebaseToken);
  } catch (error) {
    // console.log({ error });
  }
}

function* updateUserFirebaseTokenWatcher() {
  yield takeLatest(
    constants.UPDATE_USER_FIREBASE_TOKEN,
    updateUserFirebaseToken,
  );
}

function* updateUserLastHeartbeat() {
  try {
    yield call(apis.updateLastHeartbeatApi);
  } catch (error) {
    // console.log({ error });
  }
}

function* updateUserLastHeartbeatWatcher() {
  yield takeLatest(
    constants.UPDATE_USER_LAST_HEARTBEAT,
    updateUserLastHeartbeat,
  );
}

export const sagas = [
  authWatcher,
  unauthWatcher,
  userInfoWatcher,
  updateUserFirebaseTokenWatcher,
  updateUserLastHeartbeatWatcher,
];

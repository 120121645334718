import isEmpty from "lodash/isEmpty";
import { differenceInSeconds } from "date-fns";

export var customRouteAnalytics = (function () {
  var telemetryRouteMap = {};

  return {
    startTracking(route) {
      telemetryRouteMap[route] = {};
      telemetryRouteMap[route].activeStartTime = new Date();
    },

    resetRoute(route) {
      telemetryRouteMap[route] = null;
    },

    isRouteExist(route) {
      return !isEmpty(telemetryRouteMap[route]);
    },

    getPageTimeDelta(route) {
      return differenceInSeconds(
        new Date(),
        telemetryRouteMap[route].activeStartTime,
      );
    },
  };
})();

import React from "react";

import placeholder from "../../assets/img/play.jpg";

const mimetypes = [
  "image/gif",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "audio/mp3",
  "audio/wav",
  "video/mpeg",
  "video/mpg",
  "video/mp4",
  "video/3gp",
  "video/avi",
];

const getMediaType = (url) => {
  const extensionIndex = url.lastIndexOf(".");
  const extension = url.slice(extensionIndex);
  return mimetypes.find((type) => type.search(extension) !== -1);
};

export class Media extends React.Component {
  render() {
    let { attachment, openPreview, mode, isCompareMode } = this.props;

    return (
      <span
        className={`media-container ${isCompareMode && "small"} ${
          mode !== "play" && "thumb"
        }`}
        onClick={mode === "play" ? null : () => openPreview(attachment)}
      >
        {attachment.attachment_type === "AU" && (
          <audio controls>
            <source src={attachment.attachment} type="audio/ogg" />
            <source src={attachment.attachment} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        )}
        {attachment.attachment_type === "VI" && mode !== "play" && (
          <img src={placeholder} alt="play video" />
        )}
        {attachment.attachment_type === "VI" && mode === "play" && (
          <video controls src={attachment.attachment}>
            Your browser does not support the video tag.
          </video>
        )}
        {attachment.attachment_type === "IM" && (
          <img src={attachment.attachment} />
        )}
      </span>
    );
  }
}

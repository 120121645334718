import React, { Component } from "react";
import { TooltipText } from "components/common/Tooltip";
import { TOOLTIP_MESSAGE } from "constants/constants";
import { loadConcernImpactOnScore } from "components/DashboardPage/actions";
import { connect } from "react-redux";
import { getSelectedConcern } from "./selectors";
import Skeleton from "react-loading-skeleton";

class ImpactOnScore extends Component {
  componentDidMount() {
    this.props.selectedConcern?.id &&
      this.props.loadConcernImpactOnScore(this.props.selectedConcern);
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.selectedConcern.id &&
        this.props.selectedConcern.id !== prevProps.selectedConcern.id) ||
      this.props.selectedLevel !== prevProps.selectedLevel
    )
      this.props.loadConcernImpactOnScore(this.props.selectedConcern);
  }

  render() {
    const { analyzer, isLoading, impactOnScore } = this.props;

    return (
      <>
        {!analyzer.hide_impact && (
          <div className="line-header-nps">
            <span className="title tooltipRel">
              IMPACT ON SCORE
              <TooltipText tooltipText={TOOLTIP_MESSAGE.IMPACT_ON_SCORE} />
            </span>
            <span style={{ fontSize: 26, fontWeight: "bold" }}>
              {isLoading ? (
                <Skeleton width="60px" height="30px" />
              ) : (
                impactOnScore ?? "--"
              )}
            </span>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  loadConcernImpactOnScore,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.dashboard.concerns.loadingImpactOnScore,
    impactOnScore: state.dashboard.concerns.impactOnScore,
    selectedConcern: getSelectedConcern(state),
    selectedLevel: state.dashboard.insights.feedback_level,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImpactOnScore);

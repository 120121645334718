import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { values } from "lodash";

import {
  toggleGenericTag,
  resetGenericTags,
} from "components/DashboardPage/actions";

import { Color } from "constants/constants";

import FiltersBar from "./FiltersBar";
import TagGroup from "./TagGroup";

class SelectedFiltersTags extends Component {
  render = () => {
    const { dashboard, selectedFilters } = this.props;

    const selectedFilteredTags = values(this.props.filterSets)
      .filter((f) => dashboard.id === "omni" || f.title !== "Project Filter")
      .map((filterSet) => {
        return filterSet.filters
          .filter((filter) => {
            const selectedTags = selectedFilters[filter.key] || {};
            const tags = filter.tags.filter((tag) => selectedTags[tag.key]);
            return tags.length;
          })
          .map((filter) => {
            const selectedTags = selectedFilters[filter.key];
            return (
              <TagGroup
                filter={filter}
                selectedTags={selectedTags}
                color={COLORS[filter.key]}
                onTagClick={this.props.toggleGenericTag}
                onFilterClick={this.props.resetGenericTags}
              />
            );
          });
      })
      .flat();

    return (
      <>
        {selectedFilteredTags.length > 0 && (
          <Row className="secondary-topbar">
            <Col lg={12} sm={12} md={12}>
              <FiltersBar className="ui-filtersBar">
                {selectedFilteredTags}
              </FiltersBar>
            </Col>
          </Row>
        )}
      </>
    );
  };
}

const mapDispatchToProps = {
  resetGenericTags,
  toggleGenericTag,
};

const mapStateToProps = (state) => {
  return {
    filterSets: state.dashboard.filterSets,
    selectedFilters: state.selectedFilters,
    dashboard: state.dashboard.selectedDashboard,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectedFiltersTags);

export const COLORS = {
  segments: Color.ROYAL_BLUE,
  dataHealth: Color.PURPLE,
  loop: Color.RED,
  age: Color.GREEN,
  gender: Color.DARK_GOLDEN_RED,
  nps: Color.CARIBBEAN_GREEN,
  locations: Color.ORANGE,
  store: Color.DIM_GREY,
  groups: Color.SALEM,
  questionnaires: Color.CORNFLOWER_BLUE,
  options: Color.CARNATION,
  tags: Color.SANDY_BROWN,
  actions: Color.ROYAL_BLUE,
};

import { call, put, select, takeLatest } from "redux-saga/effects";
import * as constants from "./constants";
import * as actions from "./actions";
import * as apis from "./apis";
import { loadDivisions } from "components/common/filters/saga";
import { getSingleSurveyApi } from "components/SurveyBuilder/apis";
import { getOrganizationId } from "components/Organization/selectors";
import * as parsers from "./parsers";

function* loadQuestionAnalyzers() {
  try {
    const organizationID = yield select(getOrganizationId);
    const questionnaireID = yield select(
      (state) => state.dashboard.selectedDashboard.id,
    );
    if (!questionnaireID) return;

    const response = yield call(
      apis.questionnaireApi,
      organizationID,
      questionnaireID,
    );
    const data = parsers.parseQuestionsToAnalyzers(response);
    yield put(actions.loadQuestionAnalyzersSuccess(data));
  } catch (error) {
    yield put(actions.loadQuestionAnalyzersError(error));
  }
}

function* questionAnalyzersWatcher() {
  yield takeLatest(constants.LOAD_QUESTION_ANALYZERS, loadQuestionAnalyzers);
}

function* loadSurveyScript(action) {
  try {
    const questionnaireId = action.payload;
    const organizationId = yield select(getOrganizationId);

    const questionnaire = yield call(
      getSingleSurveyApi,
      organizationId,
      questionnaireId,
    );

    let divisions = yield select((state) => state.divisions.divisions);
    if (!divisions) {
      yield loadDivisions();
      divisions = yield select((state) => state.divisions.divisions);
    }

    const data = parsers.parseQuestionnaireToScript(questionnaire, divisions);
    yield put(actions.loadSurveyScriptSuccess(data));
  } catch (error) {
    // console.log(error);
    yield put(actions.loadSurveyScriptError(error));
  }
}

function* questionnaireScriptWatcher() {
  yield takeLatest(constants.LOAD_SURVEY_SCRIPT, loadSurveyScript);
}

export const sagas = [questionAnalyzersWatcher, questionnaireScriptWatcher];

import React, { Component } from "react";
import { FormControl, FormGroup, Form, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CSSTransitionGroup from "react-transition-group/CSSTransition";
import { withTranslation } from "react-i18next";

import { updatePassword } from "components/Profile/ChangePassword/actions";
import { ToolTip, ChangePasswordButton } from "./styled";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: null,
        new_password: null,
        confirm_password: null,
      },
      error: null,
    };
  }

  onChangeHandler = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitHandler = async (e) => {
    const { updatePassword } = this.props;
    e.preventDefault();
    const { new_password } = this.state.data;
    if (new_password.length < 8) {
      this.setState({ error: "The new password should have length atleast 8" });
      return;
    }
    const error = await updatePassword(this.state.data);
    if (error === "Invalid password.") {
      this.setState({ error: "Your old password is not correct" });
    } else if (error) {
      this.setState({ error });
    } else {
      this.props.navigate("/login");
    }
  };

  render() {
    const { organization } = this.props;
    const { error, data } = this.state;
    const areFieldsFilled =
      data.password && data.new_password && data.confirm_password;
    const arePasswordsSame = data.new_password === data.confirm_password;
    const { t } = this.props;

    const colors = organization.features.colors,
      logoFeatures = organization.features.dashboard.logo,
      login_logo = organization.login_logo,
      logo_landscape = organization.logo_landscape;

    return (
      <div id="login">
        <div className="login-holder">
          <CSSTransitionGroup
            classNames="animated"
            appear={true}
            timeout={{
              appear: 500,
              enter: 300,
              exit: 300,
            }}
            enter={false}
            exit={false}
          >
            <div className="inner cover">
              <div className="logo" style={{ marginTop: "30%" }}>
                <img
                  src={login_logo || logo_landscape}
                  style={{ width: `${logoFeatures.login}%` }}
                  alt=""
                />
              </div>

              {error ? (
                <CSSTransitionGroup
                  transitionName="animated"
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={300}
                  transitionEnter={false}
                  transitionLeave={false}
                >
                  <Alert variant="danger">
                    <p>{t(error)}</p>
                  </Alert>
                </CSSTransitionGroup>
              ) : null}

              <div
                style={{
                  padding: "10px 15px",
                  borderStyle: "solid",
                  borderWidth: "1px 0",
                  color: "#735c0f",
                  backgroundColor: "#ffea7f",
                  borderColor: "rgba(176,136,0,0.3)",
                }}
              >
                <span>{t("needToChangePassword")}</span>
                <br></br>
                <span>{t("passwordDesp2")}</span>
                <br></br>
                <span>{t("passwordChangeLogout")}</span>
              </div>
              <Form
                onSubmit={this.onSubmitHandler}
                horizontal
                style={{ marginTop: "25px" }}
              >
                <FormGroup>
                  <i
                    className="fa fa-unlock"
                    style={{
                      color: colors.primary,
                    }}
                  />
                  <FormControl
                    type="password"
                    name="password"
                    placeholder={t("password")}
                    onChange={this.onChangeHandler}
                  />
                </FormGroup>

                <FormGroup>
                  <i
                    className="fa fa-unlock"
                    style={{
                      color: colors.primary,
                    }}
                  />
                  <FormControl
                    type="password"
                    name="new_password"
                    placeholder={t("newPassword")}
                    onChange={this.onChangeHandler}
                  />
                </FormGroup>

                <FormGroup>
                  <i
                    className="fa fa-unlock"
                    style={{
                      color: colors.primary,
                    }}
                  />
                  <FormControl
                    type="password"
                    name="confirm_password"
                    placeholder={t("confirmPassword")}
                    onChange={this.onChangeHandler}
                  />
                  <FormControl.Feedback />
                </FormGroup>

                <ChangePasswordButton
                  disabled={!areFieldsFilled || !arePasswordsSame}
                  type="submit"
                  bsPrefix="btn-danger btn-lg btn-block"
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.text || colors.secondary,
                  }}
                >
                  {!areFieldsFilled ? (
                    <ToolTip color={colors.primary}>
                      Kindly fill in all the fields
                    </ToolTip>
                  ) : !arePasswordsSame ? (
                    <ToolTip color={colors.primary}>
                      The new passwords do not match
                    </ToolTip>
                  ) : null}
                  {t("changePassword")}
                </ChangePasswordButton>
              </Form>
              <div className="col-md-12 text-center pt-md-1">
                <Link to={"/forgot-password"}>{t("forgotPassword")}</Link>
              </div>
            </div>
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updatePassword,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  organization: state.organization.organization,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(ResetPassword));

import styled, { css } from "styled-components";
import { Dropdown } from "react-bootstrap";

export const OptionItem = styled.div`
  background: white;
  color: #666;
  cursor: pointer;
  padding: 5px 10px;

  ${(props) =>
    props.selected &&
    css`
      background: var(--primary-color);
      color: white;
    `}
`;

export const BootDropdownMenu = styled(Dropdown.Menu)`
  font-size: 13px;
  padding: 0;
  z-index: 9;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  margin: 5px 0 !important;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 0;
    right: auto;
    top: -20px;
    z-index: -1;
  }
`;

export const BootDropdownToogle = styled(Dropdown.Toggle)`
  padding-left: 0;
  min-width: auto !important;

  svg {
    width: 12px !important;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
  }
`;

export const BootDropdown = styled(Dropdown)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

import React from "react";
import { useSelector } from "react-redux";
import Spinner from "react-spinkit";

import AnalyzerRows from "components/Rack/AnalyzerRows";
import { NoDataFound } from "components/common/NoDataFound";
import InsightFilters from "components/common/InsightFilters";

const InsightsAnalysis = ({ analyzers }) => {
  const isLoading = useSelector((state) => state.dashboard.concerns.loading);
  const isConcernSelected = useSelector(
    (state) => state.selectedFilters.concern,
  );

  if (isConcernSelected) {
    return (
      <>
        <InsightFilters />
        <AnalyzerRows analyzers={analyzers} />
      </>
    );
  }

  if (isLoading) {
    return (
      <div>
        <Spinner name="wandering-cubes" fadeIn="none" />
      </div>
    );
  }

  return <NoDataFound />;
};

export default InsightsAnalysis;

import { FeedbackActionFilterLabel } from "constants/constants";

export const feedbackActionLabelText = (
  feedback,
  FeedbackActionLabel,
  action,
) => {
  const feedbackActionTypeFlag =
    feedback.feedback_action &&
    typeof FeedbackActionLabel[feedback.feedback_action.type] === "string";

  if (feedbackActionTypeFlag) {
    return FeedbackActionLabel[action];
  }
  if (action in FeedbackActionLabel) {
    return "label_text" in FeedbackActionLabel[action]
      ? FeedbackActionLabel[action]["label_text"]
      : FeedbackActionFilterLabel[action];
  }
  return FeedbackActionFilterLabel[action];
};

export const feebackActionBackgroudColor = (
  feedback,
  FeedbackActionLabel,
  action,
) => {
  const feedbackActionTypeFlag =
    feedback.feedback_action &&
    typeof FeedbackActionLabel[feedback.feedback_action.type] === "string";
  if (feedbackActionTypeFlag) {
    return "var(--primary-color)";
  }
  if (action in FeedbackActionLabel) {
    return "background_color" in FeedbackActionLabel[action]
      ? FeedbackActionLabel[action]["background_color"]
      : "var(--primary-color)";
  }
  return "var(--primary-color)";
};

export const feebackActionForegroudColor = (
  feedback,
  FeedbackActionLabel,
  action,
) => {
  const feedbackActionTypeFlag =
    feedback.feedback_action &&
    typeof FeedbackActionLabel[feedback.feedback_action.type] === "string";
  if (feedbackActionTypeFlag) {
    return "white";
  }
  if (action in FeedbackActionLabel) {
    return "foreground_color" in FeedbackActionLabel[action]
      ? FeedbackActionLabel[action]["foreground_color"]
      : "white";
  }
  return "white";
};

export const getFeedbackActionAttributes = (
  feedback,
  FeedbackActionLabel,
  action,
) => {
  let labelText = FeedbackActionFilterLabel[action];
  let foregroudColor = "white";
  let backgroudColor = "var(--primary-color)";
  const feedbackActionTypeFlag =
    feedback.feedback_action &&
    typeof FeedbackActionLabel[feedback.feedback_action.type] === "string";
  if (feedbackActionTypeFlag) {
    labelText = FeedbackActionLabel[action];
    return {
      labelText,
      foregroudColor,
      backgroudColor,
    };
  }
  if (action in FeedbackActionLabel) {
    labelText =
      "label_text" in FeedbackActionLabel[action]
        ? FeedbackActionLabel[action]["label_text"]
        : FeedbackActionFilterLabel[action];
    foregroudColor =
      "foreground_color" in FeedbackActionLabel[action]
        ? FeedbackActionLabel[action]["foreground_color"]
        : "white";
    backgroudColor =
      "background_color" in FeedbackActionLabel[action]
        ? FeedbackActionLabel[action]["background_color"]
        : "var(--primary-color)";
    return {
      labelText,
      foregroudColor,
      backgroudColor,
    };
  }
  return {
    labelText,
    foregroudColor,
    backgroudColor,
  };
};

const CURRENT_YEAR = new Date().getFullYear();

export const GET_YEARS = (startingYear) =>
  [...Array(CURRENT_YEAR - startingYear + 1).keys()].map((i) => {
    const year = i + startingYear;
    return { label: year, id: year };
  });

export const MONTHS = [
  { label: "Jan", id: 1 },
  { label: "Feb", id: 2 },
  { label: "Mar", id: 3 },
  { label: "Apr", id: 4 },
  { label: "May", id: 5 },
  { label: "Jun", id: 6 },
  { label: "Jul", id: 7 },
  { label: "Aug", id: 8 },
  { label: "Sep", id: 9 },
  { label: "Oct", id: 10 },
  { label: "Nov", id: 11 },
  { label: "Dec", id: 12 },
];

export const DAYS = [
  { label: "Mon", key: "M", id: 2 },
  { label: "Tue", key: "T", id: 3 },
  { label: "Wed", key: "W", id: 4 },
  { label: "Thu", key: "T", id: 5 },
  { label: "Fri", key: "F", id: 6 },
  { label: "Sat", key: "S", id: 7 },
  { label: "Sun", key: "S", id: 1 },
];

export const timeSpanToString = (spans, TIME_SPAN) => {
  const string = TIME_SPAN.reduce((str, _, currentIndex) => {
    const currentSpan = TIME_SPAN[currentIndex];
    const previousSpan = TIME_SPAN[currentIndex - 1];
    const isCurrentSelected = spans[currentSpan.id];
    const isPreviousSelected = spans[previousSpan?.id];
    const isConcatenated = str[str.length - 2] === "-";
    const isLast = currentIndex === TIME_SPAN.length - 1;

    if (isCurrentSelected) {
      if (!isPreviousSelected) {
        str += `, ${currentSpan.label}`;
      }

      if (isPreviousSelected && !isConcatenated) {
        str += " - ";
      }

      if (isPreviousSelected && isLast) {
        str += `${currentSpan.label}`;
      }
    } else {
      if (isConcatenated) {
        const concatenationEndsAt = TIME_SPAN[currentIndex - 1];
        str += `${concatenationEndsAt.label}`;
      }
    }

    return str;
  }, "");

  if (string.length) {
    return string.slice(2, string.length);
  }
  return string;
};

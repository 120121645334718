import { getRequest, postRequest } from "../../utils/request";

export const authApi = (params, organizationId) => {
  let url = `v1/users/auth/?organization=${organizationId}`;

  return postRequest(url, params);
};

export const unauthApi = () => {
  let url = `v1/users/auth/`;

  return getRequest(url);
};

export const userInfoApi = (id, orgId) => {
  let url = `v1/users/${id}/info?organization=${orgId || ""}`;

  return getRequest(url);
};

export const updateFirebaseTokenApi = (firebase_token) => {
  let url = `v1/users/update-firebase-token`;

  return postRequest(url, { firebase_token, type: "web" });
};

export const updateLastHeartbeatApi = () => {
  let url = `v1/users/update-last-heartbeat`;
  return getRequest(url);
};

import React from "react";
import {
  Overlay,
  Modal,
  Title,
  ProjectName,
  ButtonWrapper,
  DeleteButton,
  CancelButton,
} from "./styled";

import { FloatingLabel } from "components/common/FloatingLabel";

export const DeleteModal = ({
  projectName,
  msg,
  isDelete,
  onPopupClose,
  onDelete,
  onCancel,
  remarks,
  isConcern,
  field,
  value,
  onChange,
}) => {
  let buttons = isDelete ? (
    <ButtonWrapper>
      <DeleteButton onClick={onDelete}>Delete</DeleteButton>
      <CancelButton onClick={onCancel}>No</CancelButton>
    </ButtonWrapper>
  ) : (
    <ButtonWrapper>
      <CancelButton onClick={onPopupClose}>
        {isConcern ? "Confirm" : "Close"}
      </CancelButton>
    </ButtonWrapper>
  );

  let concernInput = isConcern ? (
    <FloatingLabel
      key={field.key}
      label={field.label}
      value={value}
      onChange={onChange}
      placeholder={field.label}
      readOnly={false}
    />
  ) : null;
  return (
    <Overlay>
      <Modal>
        <Title>
          <ProjectName>{projectName}</ProjectName>
          {msg}
          <p></p>
          {remarks && <ProjectName>Remarks by Team Lead: </ProjectName>}
          {remarks}
        </Title>
        {concernInput}
        {buttons}
      </Modal>
    </Overlay>
  );
};

import styled from "styled-components";

export const ApplyButton = styled.button`
  font-size: 12px;
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  color: var(--text-color);
  background-color: #133b7d;
  border: 1px solid #133b7d;
  border-radius: 4px;
  width: 72px;
`;

export const CancelButton = styled(ApplyButton)`
  color: #133b7d;
  background-color: var(--text-color);
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 11px 27px;
  background-color: var(--text-color);
  border: 1px solid #efefef;
`;

export const DateWrapper = styled.div`
  position: absolute;
  z-index: 10;
  box-shadow: 3px 8px 12px #00000029;
`;

export const ComponentWrapper = styled.div`
  position: relative;
`;

export const Label = styled.label`
  font-size: 12px;
  color: #666666;
  position: absolute;
  left: 20px;
  margin-bottom: 0;
  top: ${({ topLabelPosition }) =>
    topLabelPosition ? topLabelPosition : "-9"}px;
  background: rgb(255 255 255);
  padding: 0 8px;
  z-index: 2;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")};
  position: relative;
  margin-top: 40px;
`;

import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import * as constants from "./constants";
import * as actions from "./actions";
import * as apis from "./apis";
import { getOrganizationId } from "components/Organization/selectors";
import { getFiltersQueryString } from "components/common/filters/selectors";
import { selectAuth } from "components/LoginPage/selectors";
import { FeedbackAction } from "constants/constants";
import { sortBy } from "lodash";

function* loadFeedbackList() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.loadMoreFeedbackApi,
      organizationId,
      filters,
    );
    yield put(actions.loadFeedbackListSuccess(apiData));
  } catch (error) {
    yield put(actions.loadFeedbackListError(error));
  }
}

function* feedbackListWatcher() {
  yield takeLatest(constants.LOAD_FEEDBACK_LIST, loadFeedbackList);
}

function* loadUnprocessedStats() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.feedbackListActionStat,
      organizationId,
      filters,
      FeedbackAction.UNPROCESSED,
    );
    yield put(actions.loadUnprocessedStatsSuccess(apiData[0].count));
  } catch (error) {
    yield put(actions.loadUnprocessedStatsError(error));
  }
}

function* unprocessedStatsWatcher() {
  yield takeLatest(constants.LOAD_UNPROCESSED_STATS, loadUnprocessedStats);
}

function* loadMoreFeedbacks(action) {
  try {
    const apiData = yield call(apis.nextPageApi, action.nextUrl);
    yield put(actions.loadMoreFeedbacksSuccess(apiData));
  } catch (error) {
    yield put(actions.loadMoreFeedbacksError(error));
  }
}

function* moreFeedbacksWatcher() {
  yield takeLatest(constants.LOAD_MORE_FEEDBACKS, loadMoreFeedbacks);
}

function* loadSelectedFeedback(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const auth = yield select(selectAuth);
    const organizationId = yield select(getOrganizationId);
    const selectedFeedback = yield call(
      apis.feedbackDetailApi,
      organizationId,
      action.uuid,
      filters,
    );
    const apiData = {
      selectedFeedback,
    };
    yield put(actions.loadSelectedFeedbackSuccess(apiData));
    if (auth.authenticated) {
      if (auth.user.permissions.respondent_previous_feedback) {
        yield put(
          actions.loadMoreSelectedFeedback(selectedFeedback.id, filters),
        );
      }
    }
  } catch (error) {
    yield put(actions.loadSelectedFeedbackError(error));
  }
}

function* selectedFeedbackWatcher() {
  yield takeLatest(constants.LOAD_SELECTED_FEEDBACK, loadSelectedFeedback);
}

function* loadMoreSelectedFeedback(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.loadMoreFeedbackApi,
      organizationId,
      action.noFilters ? "" : filters,
      true,
      action.feedbackId,
    );
    yield put(actions.loadMoreSelectedFeedbackSuccess(apiData));
  } catch (error) {
    yield put(actions.loadMoreSelectedFeedbackError(error));
  }
}

function* loadMoreSelectedFeedbackWatcher() {
  yield takeLatest(
    constants.LOAD_MORE_SELECTED_FEEDBACK,
    loadMoreSelectedFeedback,
  );
}

function* loadNextPageSelectedFeedback(action) {
  try {
    const apiData = yield call(apis.nextPageApi, action.url);
    yield put(actions.loadMoreSelectedFeedbackSuccess(apiData));
  } catch (error) {
    yield put(actions.loadMoreSelectedFeedbackError(error));
  }
}

function* loadNextPageSelectedFeedbackWatcher() {
  yield takeLatest(
    constants.LOAD_NEXT_PAGE_SELECTED_FEEDBACK,
    loadNextPageSelectedFeedback,
  );
}

function* loadRemarks(action) {
  try {
    const organizationId = yield select(getOrganizationId);
    const remarks = yield call(
      apis.getRemarksApi,
      organizationId,
      action.feedbackId,
    );
    yield put(
      actions.loadRemarksSuccess({ remarks, feedbackId: action.feedbackId }),
    );
  } catch (error) {
    yield put(actions.loadRemarksError(error));
  }
}

function* remarksWatcher() {
  yield takeEvery(constants.LOAD_REMARKS, loadRemarks);
}

function* loadChatMessages(action) {
  try {
    const data = yield call(apis.getChatMessages, action.feedbackId);
    const chatMessages = sortBy(data.results, ["id"]);
    yield put(actions.loadChatMessagesSuccess(chatMessages, action.feedbackId));
  } catch (error) {
    // console.log(error);
    yield put(actions.loadChatMessagesError(error));
  }
}

function* loadChatMessagesWatcher() {
  yield takeEvery(constants.LOAD_CHAT_MESSAGES, loadChatMessages);
}

function* submitRemarks(action) {
  const type = action.payload.action;
  const feedbackId = action.payload.feedback.id;
  const organizationId = yield select(getOrganizationId);

  try {
    let apiData;
    if (!type) {
      apiData = yield call(
        apis.createRemarksApi,
        organizationId,
        feedbackId,
        action.payload.text,
      );
    } else {
      apiData = yield call(
        apis.createActionApi,
        organizationId,
        feedbackId,
        action.payload.text,
        type,
      );
    }
    yield put(actions.submitRemarksSuccess({ remark: apiData, feedbackId }));
  } catch (error) {
    yield put(actions.submitRemarksError({ feedbackId }));
  }
}

function* submitRemarksWatcher() {
  yield takeLatest(constants.SUBMIT_REMARKS, submitRemarks);
}

function* postChatMessage(action) {
  const feedbackId = action.payload.feedback;
  const organizationId = yield select(getOrganizationId);

  try {
    const apiData = yield call(
      apis.postMessages,
      organizationId,
      action.payload,
    );
    yield put(
      actions.postChatMessageSuccess({ chatMessage: apiData, feedbackId }),
    );
  } catch (error) {
    yield put(actions.postChatMessageError({ feedbackId }));
  }
}

function* postChatMessageWatcher() {
  yield takeLatest(constants.POST_CHAT_MESSAGE, postChatMessage);
}

function* toggleFeedbackTag(action) {
  const { feedback, tag, remove } = action.payload;
  const organizationId = yield select(getOrganizationId);
  try {
    yield call(
      apis.toggleFeedbackTagApi,
      organizationId,
      feedback.id,
      { ids: [tag.id] },
      remove,
    );
  } catch (error) {
    //
  }
}

function* toggleFeedbackTagWatcher() {
  yield takeLatest(constants.TOGGLE_FEEDBACK_TAG, toggleFeedbackTag);
}

export const sagas = [
  unprocessedStatsWatcher,
  feedbackListWatcher,
  loadMoreSelectedFeedbackWatcher,
  loadNextPageSelectedFeedbackWatcher,
  moreFeedbacksWatcher,
  selectedFeedbackWatcher,
  remarksWatcher,
  submitRemarksWatcher,
  loadChatMessagesWatcher,
  postChatMessageWatcher,
  toggleFeedbackTagWatcher,
];

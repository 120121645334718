import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { keys } from "lodash";
import { useTranslation } from "react-i18next";

import * as analytics from "utils/analytics";
import GenericFilter from "components/common/GenericFilter/GenericFilter";
import { loadOrganizationTags } from "components/actions";
import { updateOrPush } from "utils/genericHelper";

import {
  storeFilterSet,
  setGenericFilters,
  resetGenericFilters,
} from "components/DashboardPage/actions";

import { TOOLTIPS, MediaInputLabel } from "constants/constants";
import { ReactComponent as SystemFilterIcon } from "assets/svg/system_filter.svg";
import {
  getFeedbackActionFilterLabel,
  getInviteTypeFilterLabel,
} from "components/selectors";

const SystemFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const permissions = useSelector((state) => state.auth.user.permissions);
  const selectedFilters = useSelector((state) => state.selectedFilters);

  const filterSet = useSelector(
    (state) => state.dashboard.filterSets[SystemFilterSet.title],
  );

  useEffect(() => {
    const defaultFilterSet = filterFilterSet(SystemFilterSet, permissions);
    dispatch(storeFilterSet(defaultFilterSet));
  }, []);

  const feedbackActions = useSelector(getFeedbackActionFilterLabel);
  const inviteTypes = useSelector(getInviteTypeFilterLabel);

  useEffect(() => {
    if (!filterSet) return;

    const alreadyAdded = filterSet.filters.find((f) => f.key === "actions");
    if (alreadyAdded) return;

    const _filterSet = addActionFilter(t, filterSet, feedbackActions);
    dispatch(storeFilterSet(_filterSet));
  }, [filterSet, feedbackActions]);

  useEffect(() => {
    if (!filterSet || !permissions?.filter_invite_type) return;

    const alreadyAdded = filterSet.filters.find(
      (f) => f.key === "invite_types",
    );
    if (alreadyAdded) return;

    const _filterSet = addInviteTypeFilter(filterSet, inviteTypes);
    dispatch(storeFilterSet(_filterSet));
  }, [filterSet, inviteTypes]);

  const feedbackTags = useSelector((state) => state.organization.tags);

  useEffect(() => {
    if (permissions.can_view_feedback_tags) {
      dispatch(loadOrganizationTags());
    }
  }, []);

  useEffect(() => {
    if (!filterSet) return;

    const _filterSet = addTagsFilter(filterSet, feedbackTags);
    dispatch(storeFilterSet(_filterSet));
  }, [feedbackTags]);

  useEffect(() => {
    if (!filterSet) return;

    const _filterSet = addMediaInputFilter(filterSet, MediaInputLabel);
    dispatch(storeFilterSet(_filterSet));
  }, [filterSet?.filters[3]]);

  return (
    <GenericFilter
      id="system-filter"
      filterSet={filterSet}
      Icon={SystemFilterIcon}
      tooltip={TOOLTIPS.SYSTEM_FILTER}
      selectedFilters={selectedFilters}
      applyFilter={(selectedFilters) => {
        logFilterChange(filterSet, selectedFilters);
        dispatch(setGenericFilters(selectedFilters));
      }}
      resetFilter={(keys) => dispatch(resetGenericFilters(keys))}
    />
  );
};
export default SystemFilter;

export const SystemFilterSet = {
  title: "System Filter",
  filters: [
    {
      key: "segments",
      title: "Segment",
      permission: "filter_nps_segment",
      tags: [
        { key: "PRO", title: "Promoters" },
        { key: "PAS", title: "Passives" },
        { key: "DET", title: "Detractors" },
      ],
    },
    {
      key: "dataHealth",
      title: "Data Health",
      permission: "filter_data_health",
      tags: [
        { key: 1, title: "High Grade" },
        { key: 2, title: "Low to Mid Grade" },
      ],
    },
    {
      key: "loop",
      title: "Loop",
      permission: "filter_loop",
      tags: [
        { key: "I", title: "Inner" },
        { key: "O", title: "Outer" },
      ],
    },
  ],
};

export const addTagsFilter = (filterSet, feedbackTags) => {
  return {
    ...filterSet,
    filters: updateOrPush(
      [...filterSet.filters],
      {
        key: "tags",
        title: "Tag",
        permission: "can_view_feedback_tags",
        tags: feedbackTags.map((tag) => {
          return {
            key: `${tag.id}`,
            title: tag.label,
          };
        }),
      },
      (filter) => filter.key === "tags",
    ),
  };
};

export const addActionFilter = (t, filterSet, feedbackActions) => {
  return {
    ...filterSet,
    filters: updateOrPush(
      [...filterSet.filters],

      {
        key: "actions",
        title: t("feedbackStatus"),
        permission: "can_view_feedback_tags",
        tags: keys(feedbackActions).map((actionKey) => {
          return {
            key: actionKey,
            title:
              (typeof feedbackActions[actionKey] === "string" &&
                feedbackActions[actionKey]) ||
              feedbackActions[actionKey]["label_text"],
          };
        }),
      },
      (filter) => filter.key === "actions",
    ),
  };
};

export const addInviteTypeFilter = (filterSet, inviteTypes) => {
  return {
    ...filterSet,
    filters: updateOrPush(
      [...filterSet.filters],

      {
        key: "invite_types",
        title: "Survey Source",
        permission: "filter_invite_type",
        tags: keys(inviteTypes).map((inviteTypeKey) => {
          return {
            key: inviteTypeKey,
            title: inviteTypes[inviteTypeKey],
          };
        }),
      },
      (filter) => filter.key === "invite_types",
    ),
  };
};

export const addMediaInputFilter = (filterSet, mediaInputTags) => {
  return {
    ...filterSet,
    filters: updateOrPush(
      [...filterSet.filters],

      {
        key: "media_filter",
        title: "Input Filter",
        permission: "filter_media_input",
        tags: keys(mediaInputTags).map((tagKey) => {
          return {
            key: tagKey,
            title: mediaInputTags[tagKey],
          };
        }),
      },
      (filter) => filter.key === "media_filter",
    ),
  };
};

const filterFilterSet = (filterSet, permissions) => {
  return {
    ...filterSet,
    filters: filterSet.filters.filter(
      (filter) => !filter.permission || permissions[filter.permission],
    ),
  };
};

const logFilterChange = (filterSet, selectedFilters) =>
  filterSet.filters.forEach((filter) =>
    analytics.logFilterChange(filter.title, selectedFilters[filter.key], true),
  );

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import Select from "react-select";

import { ToolTip } from "components/common/FloatingLabel/styled";
import { Wrapper, Label } from "./styled";

import {
  setFormFieldValue,
  loadTeamMembers,
  setTeamMembersEmpty,
} from "components/RCA/actions";
import {
  formFieldValueSelector,
  allTeamMembersSelector,
} from "components/RCA/selectors";

const TeamMembersField = ({ field, teamLeadId }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const [isOpen, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorDisplay, setErrorDisplay] = useState("none");

  const ref = useRef();

  const allTeamMembers = useSelector((state) => allTeamMembersSelector(state));

  const selectedTeamMembers =
    useSelector((state) => formFieldValueSelector(state, fieldKey)) || [];

  const setSelectedTeamMembers = (allSelected) => {
    dispatch(
      setFormFieldValue(
        fieldKey,
        allSelected
          ? allSelected.map((selected) => ({
              full_name: selected.label,
              id: selected.value,
            }))
          : [],
      ),
    );
  };

  useEffect(() => {
    dispatch(setTeamMembersEmpty());
    if (teamLeadId) {
      dispatch(loadTeamMembers(teamLeadId.id));
    }

    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, [teamLeadId]);

  const options = allTeamMembers
    .filter((teamMember) => teamMember.id !== teamLeadId?.id)
    .map((teamMember) => {
      return {
        label: teamMember.full_name,
        value: teamMember.id,
      };
    });

  const selectedOptions = selectedTeamMembers.map((teamMember) => {
    return {
      label: teamMember.full_name,
      value: teamMember.id,
    };
  });

  const customStyles = (value) => ({
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${value ? "#f33" : "#e9e9e9"}`,
      minHeight: "46px",
      padding: "2px 24px",
    }),
  });

  const checkForError = (e) => {
    if (!selectedOptions.length) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <Wrapper
      ref={ref}
      onClick={() => setOpen(!isOpen)}
      onMouseEnter={() => setErrorDisplay("block")}
      onMouseLeave={() => setErrorDisplay("none")}
    >
      {!teamLeadId && <ToolTip>Kindly select a team lead first</ToolTip>}
      <Label htmlFor={fieldKey}>{field.name}</Label>
      <Select
        options={options}
        value={selectedOptions}
        onChange={setSelectedTeamMembers}
        labelledBy={"Select"}
        onBlur={checkForError}
        isMulti
        styles={customStyles(isError)}
        isDisabled={!selectedTeamMembers.length && !allTeamMembers.length}
      />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  teamLeadId: formFieldValueSelector(state, "team_lead"),
});

export default connect(mapStateToProps)(TeamMembersField);

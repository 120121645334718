import styled from "styled-components";
import {
  TableWrapper as FetchedWrapper,
  Table as FetchedTable,
  Thead as FetchedThead,
  Tbody as FetchedTbody,
  Th as FetchedTh,
  Tr as FetchedTr,
  Td as FetchedTd,
} from "../../common/Table";

export const Td = styled(FetchedTd)``;

export const Tr = styled(FetchedTr)``;

export const Th = styled(FetchedTh)``;

export const Tbody = styled(FetchedTbody)``;

export const Thead = styled(FetchedThead)``;

export const Table = styled(FetchedTable)``;

export const TableWrapper = styled(FetchedWrapper)`
  margin-bottom: 16px;
  box-shadow: 0px 4px 15px 3px rgba(34, 36, 38, 0.08);
  max-height: 375px;
`;

export const ErrorMessage = styled.div`
  padding: 20px;
`;

import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonSlider = () => {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        margin: "auto",
      }}
    >
      <Skeleton width={"100%"} height={"270px"} />
      <Skeleton width={"100%"} height={"30px"} style={{ marginTop: "10px" }} />
    </div>
  );
};

export default SkeletonSlider;

import * as constants from "constants/constants";

export const isCustomerView = () => {
  return window.location.pathname.search("customer-feedback") !== -1;
};

export const getViewOnlyFeedbackUUID = () => {
  const slug = window.location.pathname.split("/").pop();
  const isUUID =
    slug.length >= constants.UUID_CHAR_LENGTH &&
    window.location.pathname.includes("feedback");
  const UUID = isUUID && slug.split(constants.QUERY_PARAM_DELIMITER)[0];
  const isViewOnly = UUID && UUID !== sessionStorage.getItem("feedbackId");
  return { UUID, isViewOnly };
};

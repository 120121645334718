import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { DropdownButton, Dropdown, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { values } from "lodash";

import { setFeedbackFilter } from "components/common/filters/actions";

import * as analytics from "utils/analytics";
import * as constants from "constants/constants";

const FeedbackFilters = () => {
  const selectedFilters = useSelector((state) => state.selectedFilters);
  const selectedSortBy = selectedFilters[constants.FeedbackFilter.SORTY_BY];

  const permissions = useSelector((state) => state.auth.user.permissions);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const setSortBy = (filter) => {
    const label = constants.SortByFilterLabel[filter];
    analytics.logFilterChange("Feedback Sort By", label);
    dispatch(setFeedbackFilter(constants.FeedbackFilter.SORTY_BY, filter));
  };

  return (
    <Col className="select-holder">
      <div className="select-block">
        {permissions.can_sort_feedback && (
          <div className="field">
            <label htmlFor="sort" className="label">
              {t("sortBy")}
            </label>
            <DropdownButton
              title={t(constants.SortByFilterLabel[selectedSortBy])}
              className="select"
              variant="default"
              size="sm"
              id="sort"
            >
              {SortByFilters.map((filter) => {
                return (
                  <Dropdown.Item
                    eventKey={filter}
                    key={filter}
                    onClick={() => setSortBy(filter)}
                  >
                    {t(constants.SortByFilterLabel[filter])}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </div>
        )}
      </div>
    </Col>
  );
};

export default FeedbackFilters;

const SortByFilters = values(constants.SortByFilter);

import React, { Component, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import CSSTransitionGroup from "react-transition-group/CSSTransition";
import Spinner from "react-spinkit";
import { differenceInDays } from "date-fns";
import { isEmpty } from "lodash/lang";

import { toast, Zoom } from "react-toastify";

import { getCustomerInvites, sendReminder } from "./actions";
import { connect } from "react-redux";
import { getQueryString } from "../../utils/filtersHelperFuncs";
import { Notification } from "../common/Notification";
import { PopUp } from "../common/PopUp";
import { NotificationAll } from "../common/NotificationAll";
import InvoiceModal from "../FeedbackPage/invoice/InvoiceModal";
import { getPhoneNumber } from "../../utils/genericHelper";
import GROCommentModal from "./GroCommentModal";

class CustomerInvites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterToggle: false,
      currentFilter: null,
      loader: false,
      selectedCompanyId: null,
      selectedPage: 1,
      tooltipText: this.defaultTooltipText,
      allNotificationsSent: false,
      isOpenPopUp: false,
    };
  }

  defaultTooltipText = "Click to copy";

  componentDidMount() {
    const { selectedFilters } = this.props;
    this.setState({ loader: true });
    this.props
      .getCustomerInvites(
        getQueryString(selectedFilters),
        this.state.selectedPage,
      )
      .then(() => {
        this.setState({ loader: false });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSelectedFilters = prevProps.selectedFilters;

    const { selectedFilters } = this.props;
    const pageChanged = this.state.selectedPage !== prevState.selectedPage;

    const filterChanged = selectedFilters !== prevSelectedFilters;
    if (filterChanged) {
      this.setState({ selectedPage: 1 });
    }

    if (pageChanged || (this.state.selectedPage === 1 && filterChanged)) {
      this.setState({ loader: true });

      this.props
        .getCustomerInvites(
          getQueryString(selectedFilters),
          this.state.selectedPage,
        )
        .then(() => {
          this.setState({ loader: false });
        });
    }
  }

  handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    this.setState({ selectedPage });
  };

  resetTooltip = () => {
    if (this.state.tooltipText !== this.defaultTooltipText)
      this.setState({ tooltipText: this.defaultTooltipText });
  };

  copyLink = (link) => {
    if (link) {
      const toastProperties = {
        position: toast.POSITION.BOTTOM_CENTER,
        toastId: link,
        autoClose: 2000,
        transition: Zoom,
        draggablePercent: 70,
        style: {
          width: "150px",
          margin: "auto",
          borderRadius: "10px",
        },
      };
      var success = true;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link);
        toast.success("Copied", toastProperties);
        this.setState({ tooltipText: "Copied!" });
      } else if (document.execCommand) {
        const textArea = document.createElement("textarea");
        textArea.value = link;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        success = document.execCommand("copy");
        if (success) {
          toast.success("Copied", toastProperties);
          this.setState({ tooltipText: "Copied!" });
        }
        document.body.removeChild(textArea);
      }
    }

    if (!success && !navigator.clipboard && !document.execCommand) {
      alert("Copying to clipboard is not supported on your browser.");
    }
  };

  togglePopUp = () => {
    this.setState({ showPopUp: !this.state.showPopUp });
  };

  handleConfirm = (e) => {
    this.handleNotification(e);
    this.setState({ allNotificationsSent: true });
    this.setState({ showPopUp: !this.state.showPopUp });
  };

  handleNotification = (e, id) => {
    e.preventDefault();
    const { sendReminder, selectedFilters } = this.props;
    sendReminder(id, getQueryString(selectedFilters));
  };

  renderList() {
    const { permissions, user } = this.props;
    const { customersData } = this.props.customerInvitesReducer;
    const { loader, showPopUp } = this.state;

    const CGR = "CGR";
    const CONTACT_INFO = "Contact Info";
    const INVOICE_LIST = "Invoice List";
    const LAST_ORDER = "Last Order Received";
    const LAST_RECOVERY = "Last Recovery Status";
    const LAST_REMINDER = "Last Reminder";
    const LAST_RESPONSE = "Last Response Received";
    const NPS = "NPS";
    const REMINDER_ALL = "Reminder All";
    const RESPONDENT = "Respondent";
    const STATUS = "Status";
    const SURVEY_LINK = "Survey Link";
    const COMMENT = "Comment";

    const headings = {
      [CGR]: [RESPONDENT, CONTACT_INFO, STATUS, COMMENT, SURVEY_LINK],
      default: [
        RESPONDENT,
        CONTACT_INFO,
        NPS,
        STATUS,
        LAST_ORDER,
        LAST_RESPONSE,
        LAST_RECOVERY,
        LAST_REMINDER,
        INVOICE_LIST,
        COMMENT,
        SURVEY_LINK,
        REMINDER_ALL,
      ],
    };

    const protectedHeadings = {
      [SURVEY_LINK]: permissions.can_view_survey_link,
      [REMINDER_ALL]: permissions.can_send_reminder,
    };

    const valuesToRender =
      headings[user.role.abbreviation] || headings["default"];

    const getRowComponent = (data) => {
      const commonProps = {
        data: data,
        permissions: permissions,
        resetTooltip: this.resetTooltip,
        copyLink: this.copyLink,
        tooltipText: this.state.tooltipText,
        user: user,
        key: data?.customer_id,
      };
      const componentMap = {
        [CGR]: {
          component: CustomerGRORow,
          props: { ...commonProps },
        },
        default: {
          component: CustomerRow,
          props: {
            handleNotification: this.handleNotification,
            allSent: this.state.allNotificationsSent,
            ...commonProps,
          },
        },
      };

      const componentObj =
        componentMap[user.role.abbreviation] || componentMap["default"];
      const RowComponent = componentObj.component;
      const rowProps = componentObj.props;
      return <RowComponent {...rowProps} />;
    };

    return (
      <Col xs={12} md={12} className="customers clear-col-padding">
        <div className="box">
          {customersData ? (
            <div>
              {loader ? (
                <Spinner
                  className={"z-top"}
                  name="wandering-cubes"
                  fadeIn="none"
                />
              ) : null}
              <Table
                responsive
                bordered
                hover
                style={{ opacity: loader ? 0.3 : 1 }}
              >
                <thead>
                  <tr>
                    {valuesToRender.map((value) => {
                      if (value in protectedHeadings) {
                        if (!protectedHeadings[value]) {
                          return null;
                        }
                        if (value === REMINDER_ALL) {
                          return (
                            <th>
                              <NotificationAll
                                onClick={this.togglePopUp}
                                disabled={isEmpty(customersData)}
                              />
                            </th>
                          );
                        }
                      }
                      return <th>{value}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {customersData.map((data) => {
                    return getRowComponent(data);
                  })}
                </tbody>
              </Table>
              {!loader ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.props.customerInvitesReducer.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  forcePage={this.state.selectedPage - 1}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              ) : null}
              {showPopUp ? (
                <PopUp
                  content="This will send reminder sms/email to all customers, are you sure you want to proceed?"
                  handleCancel={this.togglePopUp}
                  handleConfirm={(e) => this.handleConfirm(e)}
                />
              ) : null}
            </div>
          ) : (
            <Spinner name="wandering-cubes" fadeIn="none" />
          )}
        </div>
      </Col>
    );
  }

  render() {
    return (
      <CSSTransitionGroup
        classNames="animated"
        appear={true}
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
        enter={false}
        exit={false}
      >
        {this.renderList()}
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = ({
  selectedFilters,
  customerInvitesReducer,
  auth,
}) => ({
  selectedFilters: selectedFilters,
  customerInvitesReducer: customerInvitesReducer,
  permissions: auth.user.permissions,
  user: auth.user,
});

const mapDispatchToProps = {
  getCustomerInvites,
  sendReminder,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInvites);

const getContactDetails = (data) => {
  const { company } = data;
  return {
    cell_phone: (company && company.cell_phone) || data.cell_phone,
    email: (company && company.email) || data.email,
  };
};

const renderContactDetails = (data) => {
  const { cell_phone, email } = getContactDetails(data);

  if (!cell_phone && !email) return "--";

  return (
    <div>
      {cell_phone && (
        <span className="contact">
          <a href={`tel:${getPhoneNumber(cell_phone)}`}>
            {getPhoneNumber(cell_phone)}
          </a>
        </span>
      )}
      {email && (
        <span className="contact email">
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      )}
    </div>
  );
};

const renderCustomerName = (data) => {
  return (
    <Row className="action-wrapper">
      <Col xs={12} md={12}>
        <h4 className="name">{data.name}</h4>
        {data.company && (
          <span>
            <span className="subtext">{data.company.name}</span>
            <span className="subtext">
              ID:{" "}
              {data.company.unique_id || (
                <span className="label label-danger">None</span>
              )}
            </span>
            <span className="subtext">
              {" "}
              Manager Code:{" "}
              {data.company.manager_code ? data.company.manager_code : "--"}
            </span>
          </span>
        )}
      </Col>
    </Row>
  );
};

const daysAgo = (date) => {
  let now = new Date();
  let days = differenceInDays(now, new Date(date));
  return days > 0 ? (days === 1 ? "Yesterday" : `${days} days ago`) : "Today";
};

const formatDate = (dateString) => {
  const format = { day: "numeric", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("default", format);
};

const CustomerRow = ({
  data,
  permissions,
  handleNotification,
  resetTooltip,
  copyLink,
  tooltipText,
  allSent,
}) => {
  const [notificationSent, setNotificationSent] = useState(false);
  return (
    <tr>
      <td>{renderCustomerName(data)}</td>
      <td>{renderContactDetails(data)}</td>
      <td>{data.score !== null ? data.score : "--"}</td>
      <td>{data.invite_status || "--"}</td>
      <td>
        <h4 className="name">
          {data.last_order ? daysAgo(data.last_order) : "--"}
        </h4>
      </td>
      <td>{data.last_responded ? daysAgo(data.last_responded) : "--"}</td>
      <td>{data.last_recovery_status || "--"}</td>
      <td>
        <div>
          {data.reminder_count ? "Reminder #" + data.reminder_count : "--"}
        </div>
        <span className="subtext">
          {data.last_reminded_on ? formatDate(data.last_reminded_on) : "--"}
        </span>
      </td>
      <td>
        <InvoiceModal customerId={data.customer_id} />
      </td>
      <td>
        <GROCommentModal
          initialText={data.comment}
          inviteID={data.survey_id}
          permissions={permissions}
        />
      </td>
      {permissions.can_view_survey_link && (
        <td style={{ width: "250px" }} onMouseLeave={resetTooltip}>
          <a
            title={tooltipText}
            className="link"
            onClick={() => copyLink(data.survey_link)}
          >
            {data.survey_link || "--"}
          </a>
        </td>
      )}
      {permissions.can_send_reminder &&
        ((notificationSent || allSent) && data.invite_status === "Pending" ? (
          <td>Sent</td>
        ) : (
          <td>
            <Notification
              onClick={(e) => {
                handleNotification(e, data.survey_id);
                setNotificationSent(true);
              }}
              id={data.survey_id}
              status={data.invite_status === "Pending"}
            />
          </td>
        ))}
    </tr>
  );
};

const CustomerGRORow = ({
  data,
  permissions,
  resetTooltip,
  copyLink,
  tooltipText,
  user,
}) => {
  return (
    <tr>
      <td>{renderCustomerName(data)}</td>
      <td>{renderContactDetails(data)}</td>
      <td>{data.invite_status || "--"}</td>
      <td>
        <GROCommentModal
          initialText={data.comment}
          inviteID={data.survey_id}
          permissions={permissions}
        />
      </td>
      {permissions.can_view_survey_link && (
        <td onMouseLeave={resetTooltip}>
          <a
            className="link"
            href={`${data.survey_link}?sb=${user.id}`}
            target="_blank"
            rel="noreferrer"
          >
            Open Link
          </a>
          <br />
          <a
            title={tooltipText}
            className="link"
            onClick={() => copyLink(data.survey_link)}
          >
            Copy Link
          </a>
        </td>
      )}
    </tr>
  );
};

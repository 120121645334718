import produce from "immer";
import * as constants from "./constants";
import { initialState } from "./initialState";

/* eslint-disable default-case, no-param-reassign */
export const feedbackReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_UNPROCESSED_STATS: {
        draft.unprocessedStats.isLoading = true;
        break;
      }

      case constants.LOAD_UNPROCESSED_STATS_ERROR: {
        draft.unprocessedStats.isLoading = false;
        draft.unprocessedStats.count = 0;
        break;
      }

      case constants.LOAD_UNPROCESSED_STATS_SUCCESS: {
        draft.unprocessedStats.isLoading = false;
        draft.unprocessedStats.count = action.payload;
        break;
      }

      case constants.LOAD_FEEDBACK_LIST:
        draft.feedbackList.isLoading = true;
        draft.feedbackList.data = initialState.feedbackList.data;
        break;

      case constants.LOAD_FEEDBACK_LIST_SUCCESS:
        draft.feedbackList.isLoading = false;
        draft.feedbackList.data = action.data.results;
        draft.feedbackList.nextUrl = action.data.next;
        break;

      case constants.LOAD_FEEDBACK_LIST_ERROR:
        draft.feedbackList.isLoading = false;
        draft.feedbackList.isError = true;
        break;

      case constants.LOAD_SELECTED_FEEDBACK:
        draft.selectedFeedback.isLoading = true;
        draft.selectedFeedback.data = [];
        break;

      case constants.LOAD_SELECTED_FEEDBACK_SUCCESS:
        draft.selectedFeedback.isLoading = false;
        draft.selectedFeedback.data = [action.data.selectedFeedback];
        break;

      case constants.LOAD_SELECTED_FEEDBACK_ERROR:
        draft.selectedFeedback.isLoading = false;
        draft.selectedFeedback.isError = true;
        break;

      case constants.LOAD_NEXT_PAGE_SELECTED_FEEDBACK:
        draft.selectedFeedback.isMoreSelectedFeedbackLoading = true;
        draft.selectedFeedback.isError = false;
        break;

      case constants.LOAD_MORE_SELECTED_FEEDBACK:
        draft.selectedFeedback.isMoreSelectedFeedbackLoading = true;
        break;

      case constants.LOAD_MORE_SELECTED_FEEDBACK_SUCCESS:
        draft.selectedFeedback.isMoreSelectedFeedbackLoading = false;
        draft.selectedFeedback.data = draft.selectedFeedback.data.concat(
          action.data.results,
        );
        draft.selectedFeedback.next = action.data.next;
        draft.selectedFeedback.previous = action.data.previous;
        break;

      case constants.LOAD_MORE_SELECTED_FEEDBACK_ERROR:
        draft.selectedFeedback.isMoreSelectedFeedbackLoading = false;
        draft.selectedFeedback.isError = true;
        break;

      case constants.REMOVE_SELECTED_FEEDBACK:
        draft.selectedFeedback = initialState.selectedFeedback;
        break;

      case constants.LOAD_REMARKS:
        draft.selectedFeedback.isRemarksLoading = true;
        break;

      case constants.LOAD_CHAT_MESSAGES:
        draft.selectedFeedback.isChatMessagesLoading = true;
        break;

      case constants.LOAD_REMARKS_SUCCESS: {
        draft.selectedFeedback.isRemarksLoading = false;
        draft.selectedFeedback.isError = false;
        let actionableRemark = action.data.remarks.filter(
          (remark) => remark.action,
        );
        let feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.data.feedbackId,
        );
        let feedbackListFeedback = draft.feedbackList.data.find(
          (feedback) => feedback.id === action.data.feedbackId,
        );
        feedback.remarks = action.data.remarks;
        if (actionableRemark && actionableRemark?.length) {
          const _action = actionableRemark.at(-1).action;
          feedback.feedback_action = _action;
          if (feedbackListFeedback) {
            feedbackListFeedback.feedback_action = _action;
          }
        }
        break;
      }

      case constants.LOAD_REMARKS_ERROR:
        draft.selectedFeedback.isRemarksLoading = false;
        draft.selectedFeedback.isError = true;
        break;

      case constants.LOAD_CHAT_MESSAGES_SUCCESS: {
        draft.selectedFeedback.isRemarksLoading = false;
        draft.selectedFeedback.isError = false;
        const feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.feedbackId,
        );

        feedback.chatMessages = action.chatMessages;
        break;
      }

      case constants.LOAD_CHAT_MESSAGES_ERROR:
        draft.selectedFeedback.isChatMessagesLoading = false;
        draft.selectedFeedback.isError = true;
        break;

      case constants.SUBMIT_REMARKS: {
        let feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.payload.feedback.id,
        );
        // add placeholder remark
        feedback.remarks.push({
          id: "temp" + new Date().getTime(),
          text: action.payload.text,
          user: action.payload.user,
        });
        break;
      }

      case constants.SUBMIT_REMARKS_SUCCESS: {
        const { feedbackId, remark } = action.payload;
        const findFeedback = (feedback) => feedback.id === feedbackId;

        const feedback = draft.selectedFeedback.data.find(findFeedback);
        const feedbackAction = { type: action.payload.remark.type };

        const placeholderRemark = feedback.remarks[feedback.remarks.length - 1];
        placeholderRemark.id = remark.id;
        placeholderRemark.action = feedbackAction;
        placeholderRemark.created_at = remark.created_at;

        if (feedbackAction.type) {
          const feedbackSummary = draft.feedbackList.data.find(findFeedback);
          feedbackSummary.feedback_action = feedbackAction;

          const feedbackDetail = draft.selectedFeedback.data.find(findFeedback);
          feedbackDetail.feedback_action = feedbackAction;
        }

        break;
      }

      case constants.SUBMIT_REMARKS_ERROR: {
        let feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.payload.feedbackId,
        );
        // remove placeholder remark
        feedback && feedback.remarks.pop();
        break;
      }

      case constants.POST_CHAT_MESSAGE: {
        let feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.payload.feedback,
        );
        // add placeholder chatMessage
        feedback.chatMessages.push({
          id: "temp" + new Date().getTime(),
          message: action.payload.message,
          communication_mode: action.payload.communication_mode,
          created_at: new Date(),
        });
        break;
      }

      case constants.POST_CHAT_MESSAGE_SUCCESS: {
        const { feedbackId, chatMessage } = action.payload;
        const findFeedback = (feedback) => feedback.id === feedbackId;

        const feedback = draft.selectedFeedback.data.find(findFeedback);

        const placeholderChatMessage =
          feedback.chatMessages[feedback.chatMessages.length - 1];
        placeholderChatMessage.id = chatMessage.id;
        placeholderChatMessage.created_at = chatMessage.created_at;

        break;
      }

      case constants.POST_CHAT_MESSAGE_ERROR: {
        let feedback = draft.selectedFeedback.data.find(
          (feedback) => feedback.id === action.payload.feedbackId,
        );
        // remove placeholder chat Message
        feedback.chatMessages.pop();
        break;
      }

      case constants.LOAD_MORE_FEEDBACKS:
        draft.feedbackList.isMoreFeedbackLoading = true;
        break;

      case constants.LOAD_MORE_FEEDBACKS_SUCCESS:
        draft.feedbackList.isMoreFeedbackLoading = false;
        draft.feedbackList.data.push(...action.data.results);
        draft.feedbackList.nextUrl = action.data.next;
        break;

      case constants.LOAD_MORE_FEEDBACKS_ERROR:
        draft.feedbackList.isMoreFeedbackLoading = false;
        draft.feedbackList.isError = true;
        break;

      case constants.TOGGLE_FEEDBACK_TAG: {
        const { feedback, tag, remove } = action.payload;
        const findFeedback = (f) => f.id === feedback.id;

        let dFeedback = draft.selectedFeedback.data.find(findFeedback);

        if (remove)
          dFeedback.tags = feedback.tags.filter((t) => t.id !== tag.id);
        else dFeedback.tags.push(tag);

        break;
      }

      case constants.CLEAR_SELECTED_FEEDBACK:
        draft.selectedFeedback.data = [];
    }
  });
};

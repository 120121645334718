import { initialState } from "../../initialState/initialState";
import { CUSTOMER_INVITES_SUCCESS } from "../../constants/constants";

const customerInvitesReducer = (
  state = initialState.customerInvites,
  action,
) => {
  switch (action.type) {
    case CUSTOMER_INVITES_SUCCESS:
      return {
        ...state,
        ...action.customersInvites,
      };

    default:
      return state;
  }
};

export default customerInvitesReducer;

import styled from "styled-components";

export const InfoCard = styled.div`
  width: ${({ width }) => (width ? width : "auto")};
  margin-bottom: 20px;
  border-radius: 3px;
  background-color: var(--text-color);
`;

export const Heading = styled.h6`
  background-color: #d3d3d3;
  padding: 4px 14px;
  color: #242424;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
`;

export const Body = styled.h5`
  padding: 8px 14px;
  font-size: 15px;
  font-weight: bold;
  color: #242424;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  vertical-align: middle;
`;

export const displayFlex = () => `
  display: -o-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
`;

export const flexDirection = (direction) => `
  -webkit-flex-direction: ${direction};
  -moz-flex-direction: ${direction};
  -ms-flex-direction: ${direction};
  -o-flex-direction: ${direction};
  -khtml-flex-direction: ${direction};
  flex-direction: ${direction};
`;

export const flexAlign = (alignItem) => `
  -webkit-align-items: ${alignItem};
  -moz-align-items: ${alignItem};
  -ms-align-items: ${alignItem};
  -o-align-items: ${alignItem};
  -khtml-align-items: ${alignItem};
  align-items: ${alignItem};
`;

export const flexJustify = (justifyContent) => `
  -webkit-justify-content: ${justifyContent};
  -moz-justify-content: ${justifyContent};
  -ms-justify-content: ${justifyContent};
  -o-justify-content: ${justifyContent};
  -khtml-justify-content: ${justifyContent};
  justify-content: ${justifyContent};
`;

export const transition = (set) => `
  -webkit-transition: ${set};
  -moz-transition: ${set};
  -ms-transition: ${set};
  -o-transition: ${set};
  transition: ${set};
`;

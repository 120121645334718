import styled from "styled-components";

export const UserProfileImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: fill;
  object-position: center;
  border: 1px solid var(--secondary-color);
  background: var(--primary-color);
`;

export const UserProfileInitials = styled.span`
  font-size: 50px;
  color: var(--primary-color);
  background: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

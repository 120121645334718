import React from "react";
import { FilterDropdownMenu } from "./styled";

export const FilterDropdown = ({ children, ...rest }) => {
  return (
    <FilterDropdownMenu className="ui-dropdown-menu" {...rest}>
      {children}
    </FilterDropdownMenu>
  );
};

export default FilterDropdown;

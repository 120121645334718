import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { NotificationList } from "./NotificationList";
import * as analytics from "utils/analytics";
import { selectNotifications } from "./selectors";

const NotificationMenu = () => {
  const topNotification = useSelector(selectNotifications).find((n) =>
    n?.notification_dashboard_link?.includes("share-feedback"),
  );
  const { t } = useTranslation();

  return (
    <div className="notifications menu">
      <NotificationList redirect source="menu" />
      {topNotification && (
        <div className="notifications--button">
          <Link
            exact
            className="see-all"
            onClick={analytics.logSeeAllButtonClick}
            to={topNotification?.notification_dashboard_link}
          >
            {t("See All Activity")}
          </Link>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;

import React from "react";
import { Wrapper, Label, FormInput } from "./styled";

import { upperFirst } from "lodash";

export const FloatingLabel = ({
  width,
  height,
  topLabelPosition,
  label,
  value,
  onChange,
  placeholder,
  readOnly,
  onClick,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  disabled,
  error,
  ...props
}) => {
  return (
    <Wrapper width={width}>
      <Label for="floating-label" topLabelPosition={topLabelPosition}>
        {label}
      </Label>
      <FormInput
        type="text"
        id={label}
        name={label}
        placeholder={placeholder}
        height={height}
        value={value}
        onBlur={onBlur}
        onChange={(e) => onChange(upperFirst(e.target.value))}
        readOnly={readOnly}
        onClick={onClick}
        isError={error}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        disabled={disabled}
      />
      {props.children}
    </Wrapper>
  );
};

import { getRemarks } from "./remarks";
import {
  SELECTED_FEEDBACK,
  CLEAR_SELECTED_FEEDBACK,
  FETCHING_SELECTED_FEEDBACK,
} from "../../constants/constants";
import { feedbackDetailApi } from "../../components/FeedbackPage/apis";

const _success = (feedback) => ({
  type: SELECTED_FEEDBACK,
  feedback,
});

const _fetching = () => ({ type: FETCHING_SELECTED_FEEDBACK });

const _clear = () => ({ type: CLEAR_SELECTED_FEEDBACK });

export const setSelectedFeedback = (feedback) => {
  const feedbackId = feedback.id;

  return (dispatch) => {
    dispatch(_fetching());

    feedbackDetailApi(feedbackId).then((res) => {
      dispatch(_success(res.data));
      dispatch(getRemarks(feedbackId));
    });
  };
};

export const clearSelectedFeedback = () => (dispatch) => dispatch(_clear());

import React, { Component } from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: "" || props.value,
    };

    this.setSearchValue = this.setSearchValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { resetValue } = this.props;
    if (resetValue !== prevProps.resetValue) {
      this.setState({
        searchValue: "",
      });
    }
  }

  setSearchValue(e) {
    const { onChange } = this.props;
    this.setState({ searchValue: e.target.value });
    if (onChange) {
      onChange(e.target.value);
    }
  }

  handleSubmit(e) {
    const { onSubmit } = this.props;
    e.preventDefault();
    onSubmit(this.state.searchValue);
  }

  reset(e) {
    const { onSubmit } = this.props;
    this.setState({
      searchValue: "",
    });
    onSubmit("");
  }

  render() {
    const { colors, t } = this.props;
    const { searchValue } = this.state;
    return (
      <Form className="search-form" noValidate>
        <FormControl
          type="text"
          placeholder={t("searchHere")}
          value={searchValue}
          onChange={this.setSearchValue}
          style={{
            borderTopLeftRadius: "25px",
            borderBottomLeftRadius: "25px",
            boxShadow: "none",
            border: "1px solid #ced4da",
          }}
        />
        <i
          className="fa fa-times cross-icon"
          style={{ display: searchValue ? "block" : "none" }}
          onClick={this.reset}
        />
        <Button
          type="submit"
          title="Search"
          onClick={this.handleSubmit}
          style={{
            background: colors && colors.primary,
            color: colors && colors.text,
            borderColor: colors && colors.primary,
            borderTopRightRadius: "25px",
            borderBottomRightRadius: "25px",
            boxShadow: "none",
          }}
        >
          <i className="fa fa-search" style={{ marginBottom: "4px" }} />
        </Button>
      </Form>
    );
  }
}

export const SearchBoxWithTranslation = withTranslation()(SearchBox);

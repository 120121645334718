import React from "react";
import smileyGreen from "assets/img/smiley-green.jpg";
import smileyRed from "assets/img/smiley-red.jpg";
import smileyYellow from "assets/img/smiley-yellow.jpg";
import starGreen from "assets/img/star-green.jpg";
import starOrange from "assets/img/star-orange.jpg";
import starRed from "assets/img/star-red.jpg";
import { ScoreLabel, ScoreKind } from "constants/constants";
import { ImageWrapper, Image, FeedbackNumber, ScoreNumber } from "./styled";

const smileysMapping = {
  [ScoreLabel.POSITIVE]: smileyGreen,
  [ScoreLabel.NEUTRAL]: smileyYellow,
  [ScoreLabel.NEGATIVE]: smileyRed,
};

const starMapping = {
  [ScoreLabel.POSITIVE]: starGreen,
  [ScoreLabel.NEUTRAL]: starOrange,
  [ScoreLabel.NEGATIVE]: starRed,
};

const counterClassMapping = {
  [ScoreLabel.POSITIVE]: "green",
  [ScoreLabel.NEUTRAL]: "orange",
  [ScoreLabel.NEGATIVE]: "red",
};

const SmileyScoreView = ({ scoreLabel }) => (
  <ImageWrapper>
    <Image src={smileysMapping[scoreLabel]} />
  </ImageWrapper>
);

const NumericScoreView = ({ scoreLabel, score }) =>
  score === null ? (
    <SmileyScoreView scoreLabel={scoreLabel} />
  ) : (
    <ScoreNumber color={counterClassMapping[scoreLabel]}>{score}</ScoreNumber>
  );

const StarScoreView = ({ scoreLabel, score }) =>
  score === null ? (
    <SmileyScoreView scoreLabel={scoreLabel} />
  ) : (
    <ImageWrapper>
      <Image src={starMapping[scoreLabel]} />
      <FeedbackNumber color={counterClassMapping[scoreLabel]}>
        {score}
      </FeedbackNumber>
    </ImageWrapper>
  );

const ScoreViews = {
  [ScoreKind.NPS]: NumericScoreView,
  [ScoreKind.STAR]: StarScoreView,
};

export const pickScoreView = (feedback) => {
  if (feedback.score_label !== null) {
    return ScoreViews[feedback.score_kind] || NumericScoreView;
  }
};

export default ScoreViews;

import React from "react";

import AnalyzerRows from "components/Rack/AnalyzerRows";
import TransactionalFilter from "components/Transactional/TransactionalFilter";

const TransactionalDurational = ({ rack }) => {
  return (
    <>
      <TransactionalFilter />
      <AnalyzerRows analyzers={rack.analyzers} />
    </>
  );
};

export default TransactionalDurational;

import { call, select, put, takeLatest } from "redux-saga/effects";
import { format, parse } from "date-fns";

import * as constants from "constants/constants";
import * as apis from "./apis";
import * as actions from "./actions";
import * as selectors from "./selectors";

import {
  projectIDSelector,
  projectValuesSelector,
  questionnaireIDSelector,
  currentLoggedInNameSelector,
  deploymentDateSelector,
  concernAnalysisSelector,
  currentLoggedInUserIdSelector,
  formFieldValueSelector,
  rcaProjectListCurrentPageSelector,
} from "components/RCA/selectors";

import { values as getValues, keys } from "lodash";
import { getOrganizationId } from "components/Organization/selectors";

function* loadRCAProjectList() {
  let currPage = yield select(rcaProjectListCurrentPageSelector);
  let questionnaireID = yield select(questionnaireIDSelector);
  questionnaireID = questionnaireID === "omni" ? "" : questionnaireID;
  const data = yield call(apis.RCAProjectListApi, questionnaireID, currPage);
  yield put(actions.loadRCAProjectListSuccess(data));
}
function* loadRCAProjectListWatcher() {
  yield takeLatest(constants.LOAD_RCA_PROJECT_LIST, loadRCAProjectList);
}

function* loadRCAProject(action) {
  const full_name = yield select(currentLoggedInNameSelector);
  const id = yield select(currentLoggedInUserIdSelector);
  const owner = { id, full_name };
  const data = yield call(apis.RCAProjectApi, action.templateId);
  yield put(actions.loadRCAProjectSuccess(data, owner));
}

function* loadRCAProjectWatcher() {
  yield takeLatest(constants.LOAD_RCA_PROJECT, loadRCAProject);
}

function* loadRCAProjectData(action) {
  try {
    const data = yield call(apis.RCAProjectDataApi, action.payload.id);
    yield put(actions.loadRCAProjectDataSuccess(data));
  } catch (e) {
    yield put(actions.viewProjectList());
  }
}

function* loadRCAProjectDataWatcher() {
  yield takeLatest(constants.LOAD_RCA_PROJECT_DATA, loadRCAProjectData);
}

function* loadTeamLeads() {
  const organizationId = yield select(getOrganizationId);
  const data = yield call(apis.teamLeadsApi, organizationId);
  yield put(actions.loadTeamLeadsSuccess(data));
}

function* loadTeamLeadsWatcher() {
  yield takeLatest(constants.LOAD_TEAM_LEADS, loadTeamLeads);
}

function* loadTeamMembers(action) {
  const data = yield call(apis.teamMembersApi, action.teamLeadID);
  const loggedInUserId = yield select(currentLoggedInUserIdSelector);
  yield put(actions.loadTeamMembersSuccess(data, loggedInUserId));
}

function* loadTeamMembersWatcher() {
  yield takeLatest(constants.LOAD_TEAM_MEMBERS, loadTeamMembers);
}

function* loadConcern() {
  const organizationId = yield select(getOrganizationId);
  let questionnaireID = yield select(questionnaireIDSelector);
  questionnaireID = questionnaireID === "omni" ? "" : questionnaireID;
  const divisions = yield select((state) =>
    formFieldValueSelector(state, "location"),
  ) || "";
  const divisionIds = keys(divisions);
  const divisionParams = divisionIds
    .reduce(
      (a, b) =>
        a +
        divisions[b].titles.reduce(
          (c, d) => c + `${divisions[b]?.level}:${d}|`,
          "",
        ),
      "",
    )
    .slice(0, -1);
  const loop = yield select((state) => formFieldValueSelector(state, "loop")) ||
    "";
  const data = yield call(
    apis.concernApi,
    organizationId,
    questionnaireID,
    divisionParams,
    loop,
  );
  yield put(actions.loadConcernSuccess(data));
}

function* loadConcernWatcher() {
  yield takeLatest(constants.LOAD_CONCERN, loadConcern);
}

function* loadLevels() {
  const organizationId = yield select(getOrganizationId);
  let questionnaireID = yield select(questionnaireIDSelector);
  let rcaTemplateId = yield select(selectors.rcaTemplateIdSelector);
  questionnaireID = questionnaireID === "omni" ? "" : questionnaireID;
  const data = yield call(
    apis.levelsApi,
    organizationId,
    questionnaireID,
    rcaTemplateId,
  );
  yield put(actions.loadLevelsSuccess(data));
}

function* loadLevelsWatcher() {
  yield takeLatest(constants.LOAD_LEVELS, loadLevels);
}

function* loadUsers() {
  const organizationId = yield select(getOrganizationId);
  const data = yield call(apis.usersApi, organizationId);
  yield put(actions.loadUsersSuccess(data));
}

function* loadUsersWatcher() {
  yield takeLatest(constants.LOAD_USERS, loadUsers);
}

function* verifyLocation(action) {
  const organizationId = yield select(getOrganizationId);
  const locationJSON = JSON.stringify(action.location);
  try {
    const response = yield call(
      apis.verifyLocationApi,
      organizationId,
      locationJSON,
    );
  } catch {
    yield put(
      actions.setLocationMatchError(
        "The selected location combination is invalid, please select another.",
      ),
    );
  }
}

function* verifyLocationWatcher() {
  yield takeLatest(constants.VERIFY_VALID_LOCATION, verifyLocation);
}

function* saveRCAForm() {
  const id = yield select(projectIDSelector);
  const values = yield select(projectValuesSelector);
  const questionnaireID = yield select(questionnaireIDSelector);
  const data = {
    ...values.data,
    rca_template: values.data.rca_template.id,
    questionnaire: questionnaireID,
    project_start_date: values.data.project_start_date || null,
    deployment_end_date: values.data.deployment_end_date || null,
    deployment_start_date: values.data.deployment_start_date || null,
    adjustment_user: values.data.adjustment_user?.id,
    check_result_user: values.data.check_result_user?.id,
    team_lead: values.data.team_lead?.id,
    team_members: values.data.team_members.map((member) => member.id),
    concern: values.data.concern?.id || null,
    rca_options: getValues(values.data.rca_options).map((option) => ({
      ...option,
      content: option.content?.id || option.content,
    })),
    location: getValues(values.data.location),
    rating: values.data.rating?.value,
    owner: values.data.owner?.id,
  };
  const response = yield call(apis.saveRCAFormApi, id, data);
  if (response.status === 400) {
    yield put(actions.setLocationMatchError(response.data));
  } else {
    yield put(actions.selectRCAProject(response.id));
    yield put(actions.setLocationMatchError(""));
    yield put(actions.toggleMode("VIEW"));
  }
}

function* saveRCAFormWatcher() {
  yield takeLatest(constants.SAVE_RCA_FORM, saveRCAForm);
}

function* deleteRCAProject() {
  const id = yield select(projectIDSelector);
  yield call(apis.deleteRCAProjectApi, id);
  yield put(actions.loadRCAProject(2));
}

function* deleteRCAProjectWatcher() {
  yield takeLatest(constants.DELETE_RCA_PROJECT, deleteRCAProject);
}

function* loadConcernAnalysis() {
  const concernAnalysis = yield select(concernAnalysisSelector);
  let questionnaireID = yield select(questionnaireIDSelector);
  questionnaireID = questionnaireID === "omni" ? "" : questionnaireID;
  if (!concernAnalysis) {
    const deploymentDate = yield select(deploymentDateSelector);
    const projectId = yield select(projectIDSelector);
    try {
      const analysisData = yield call(
        apis.analysisApi,
        projectId,
        format(parse(deploymentDate, "yyyy-MM-dd", new Date()), "T"),
        questionnaireID,
      );
      yield put(actions.loadConcernAnalysisSuccess(analysisData));
    } catch {
      yield put(actions.loadConcernAnalysisError());
    }
  }
}

function* loadConcernAnalysisWatcher() {
  yield takeLatest(constants.LOAD_CONCERN_ANALYSIS, loadConcernAnalysis);
}

export const sagas = [
  loadRCAProjectListWatcher,
  loadRCAProjectWatcher,
  loadRCAProjectDataWatcher,
  saveRCAFormWatcher,
  loadTeamLeadsWatcher,
  loadTeamMembersWatcher,
  loadConcernWatcher,
  loadLevelsWatcher,
  loadUsersWatcher,
  deleteRCAProjectWatcher,
  loadConcernAnalysisWatcher,
  verifyLocationWatcher,
];

import React from "react";
import i18n from "i18n";

export const CountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="24"
    viewBox="0 0 15 24"
  >
    <text
      id="n"
      fill="#707070"
      fontSize="18"
      fontFamily="Roboto-Regular, Roboto"
    >
      <tspan x="0" y="19">
        {i18n.t("n")}
      </tspan>
    </text>
  </svg>
);

import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { isFalsy } from "../../utils/genericHelper";
import { isCustomerView } from "../FeedbackPage/selectors";

class HeaderWithScrollbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
      isCalledBefore: false,
    };
  }

  setEventListener = () => {
    let { isCalledBefore } = this.state;
    let card = document.querySelector('[class*="details"][class*="scollbar"]');
    if (!isCalledBefore && card) {
      card.addEventListener("scroll", () => {
        this.setState({
          isScrolled: !this.state.isScrolled,
        });
      });
      this.setState({ isCalledBefore: true });
    }
  };

  recaculatePositions = (scrollBarRef) => {
    let cards = scrollBarRef.querySelectorAll(".card");
    let cardPositions = [];
    cards.forEach((card) => cardPositions.push(card.offsetLeft));
    return cardPositions;
  };

  moveTo = (direction) => {
    let scrollBarRef = document.querySelector(
      '[class*="details"][class*="scollbar"]',
    );
    let cardPositions = this.recaculatePositions(scrollBarRef);
    let scrollBarLeftPos = scrollBarRef.scrollLeft;
    let move = 0;
    if (direction === "right") {
      cardPositions.forEach((cardPos, index, cardPositions) => {
        let next = cardPositions[index + 1];
        if (scrollBarLeftPos < next && scrollBarLeftPos >= cardPos) {
          move = next;
        }
      });
    } else if (direction === "left") {
      cardPositions.forEach((cardPos, index, cardPositions) => {
        let prev = cardPositions[index - 1];
        if (scrollBarLeftPos > prev && scrollBarLeftPos <= cardPos) {
          move = prev;
        }
        scrollBarRef.scrollTo({
          top: 0,
          left: move,
          behavior: "smooth",
        });
        this.setState({
          isScrolled: true,
        });
      });
    }
  };

  moveToRight = (e) => {
    this.moveTo("right");
  };

  moveToLeft = (e) => {
    this.moveTo("left");
  };

  render() {
    let scrollBarRef = document.querySelector(
      '[class*="details"][class*="scollbar"]',
    );
    let isScrollBar =
      scrollBarRef && scrollBarRef.scrollWidth !== scrollBarRef.offsetWidth;
    const { organization, auth } = this.props;
    let logoFeatures = organization.features.dashboard.logo,
      logo = organization.logo_landscape;
    let { isScrolled } = this.state;
    this.setEventListener();
    return (
      <header
        id="header"
        className={`responsive-header tc ${isCustomerView(auth) && "visible"}`}
      >
        {isScrollBar && (
          <span
            className={`scroll-btn left ${isScrolled}`}
            style={{
              display:
                scrollBarRef.scrollLeft < scrollBarRef.offsetWidth / 2
                  ? "none"
                  : "block",
            }}
            onClick={this.moveToLeft}
          >
            <i className="fa fa-chevron-left" />
          </span>
        )}
        <div className="logo-container logo-wrapper">
          <img id="logo" src={logo} width={`${logoFeatures.topbar}%`} alt="" />
        </div>
        {isScrollBar && (
          <span
            className={`scroll-btn right ${isScrolled}`}
            style={{
              display:
                scrollBarRef.scrollLeft + scrollBarRef.offsetWidth !==
                scrollBarRef.scrollWidth
                  ? "block"
                  : "none",
            }}
            onClick={this.moveToRight}
          >
            <i className="fa fa-chevron-right" />
          </span>
        )}
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  organization: state.organization.organization,
  selectedFeedback: state.feedback.selectedFeedback,
});

export default connect(mapStateToProps, null)(HeaderWithScrollbar);

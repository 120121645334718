import {
  QUESTIONAIRE_SUCCESS,
  QUESTIONAIRE_FAILURE,
  QUESTIONAIRE_PERCENTAGE_SUCCESS,
  CHANGE_DIVISION,
  QUESTION_DETAIL_SUCCESS,
  GET_QUESTIONNAIRE_PERCENTAGES,
} from "../constants/constants";
import { initialState } from "../initialState/initialState";

const questionaireReducer = (state = initialState.questionaire, action) => {
  switch (action.type) {
    case QUESTIONAIRE_SUCCESS:
      return {
        ...state,
        data: action.data,
      };
    case QUESTIONAIRE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case QUESTIONAIRE_PERCENTAGE_SUCCESS:
      return {
        ...state,
        divisionStats: action.data,
      };

    case GET_QUESTIONNAIRE_PERCENTAGES:
      return {
        ...state,
        divisionStats: action.data,
        division: action.data[0] && action.data[0].division,
      };

    case CHANGE_DIVISION:
      return {
        ...state,
        division: action.data,
      };

    case QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        data: state.data.map((questionnaire) => {
          return {
            ...questionnaire,
            questions: questionnaire.questions.map((question) => {
              if (question.question_id === action.data.id) {
                return action.data;
              } else if (question.id === action.data.id) {
                return action.data;
              }
              return question;
            }),
          };
        }),
      };

    default:
      return state;
  }
};

export default questionaireReducer;

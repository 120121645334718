import React, { Component } from "react";
import * as analytics from "utils/analytics";
import { connect } from "react-redux";
import { CustomDropdown } from "./Dropdown";
import { DropdownContainer } from "./DropdownContainer";
import { setFeedbackLevelFilter } from "components/InsightsPage/Insights/actions";
import {
  setLikesFilters,
  loadInsightConcerns,
  selectInsightConcern,
  selectInsightChildConcern,
} from "components/DashboardPage/actions";

class InsightFilters extends Component {
  state = { selectedLevel: null, selectedLikesOption: likesOptions[1] };

  componentDidMount = () => {
    this.setSelectedLevel();
    this.setSelectedLikesOption();
  };

  componentDidUpdate = (prevProps) => {
    const { selectedLevel, selectedLikesOption } = this.props;
    const { selectedDashboard, selectedFilters } = this.props;

    const filtersChanged =
      selectedFilters.query !== prevProps.selectedFilters.query &&
      (selectedFilters.concern === prevProps.selectedFilters.concern ||
        !selectedFilters.concern);

    const rackSwitched =
      selectedDashboard.id !== prevProps.selectedDashboard.id;

    const levelChanged = selectedLevel !== prevProps.selectedLevel;
    const likesChanged = selectedLikesOption !== prevProps.selectedLikesOption;

    if (levelChanged || rackSwitched) {
      this.setSelectedLevel();
    }
    if (likesChanged || rackSwitched) {
      this.setSelectedLikesOption();
    }
    if (filtersChanged) {
      this.fetchConcerns();
    }
  };

  fetchConcerns = () => {
    if (this.props.selectedLevel || this.props.rack.hide_level) {
      this.props.loadInsightConcerns();
    }
  };

  setSelectedLevel = () => {
    if (this.props.rack.hide_level) {
      return;
    }
    const selectedLevel = this.props.levels.find(
      (level) =>
        level.feedback_level ===
        (this.props.selectedLevel || this.props.defaultSelectedLevel),
    );
    this.props.setFeedbackLevelFilter(selectedLevel.feedback_level);
    this.setState({ selectedLevel });
  };

  setSelectedLikesOption = () => {
    if (this.props.rack.hide_like) {
      return;
    }
    const selectedLikesOption = likesOptions.find(
      (option) => option.value === this.props.selectedLikesOption,
    );
    this.setLikesFilters(selectedLikesOption);
    this.setState({ selectedLikesOption });
  };

  setLikesFilters = (likeFilter) => {
    const { selectedParentConcern, concerns } = this.props;
    const [childConcern] = concerns.children[selectedParentConcern.id] || [];
    const concern = childConcern || selectedParentConcern;
    this.props.setLikesFilters(likeFilter.value, concern, !childConcern);
  };

  render = () => {
    const { concerns, selectedParentConcern } = this.props;
    const childConcerns = concerns.children[selectedParentConcern.id] || [];

    return (
      <DropdownContainer>
        {this.state.selectedLevel && !this.props.rack.hide_level && (
          <CustomDropdown
            options={this.props.levels}
            getLabel={(o) => o.name}
            getKey={(o) => o.feedback_level}
            selectedOption={this.state.selectedLevel}
            onClick={(o) => {
              analytics.logFilterChange("Feedback level", o.feedback_level);
              this.props.setFeedbackLevelFilter(o.feedback_level);
            }}
          />
        )}
        {!this.props.rack.hide_like && (
          <CustomDropdown
            options={likesOptions}
            getLabel={(o) => o.label}
            getKey={(o) => o.label}
            selectedOption={this.state.selectedLikesOption}
            onClick={(o) => {
              analytics.logFilterChange("Feedback Sentiment", o.label);
              this.setLikesFilters(o);
            }}
          />
        )}
        {concerns.list.length > 0 && (
          <CustomDropdown
            options={concerns.list}
            getLabel={(o) => o.name}
            getKey={(o) => o.id}
            getPercentage={(o) => o.percentage}
            selectedOption={this.props.selectedParentConcern}
            onClick={(o) => {
              analytics.logFilterChange("Concern", o.name);
              this.props.selectInsightConcern(o);
            }}
            getType={(o) => o.percentage}
          />
        )}
        {childConcerns.length > 0 && !this.state.selectedLikesOption.value && (
          <CustomDropdown
            options={childConcerns}
            getLabel={(o) => o.name}
            getKey={(o) => o.id}
            getPercentage={(o) => o.percentage}
            selectedOption={this.props.selectedChildConcern || {}}
            onClick={(o) => this.props.selectInsightChildConcern(o)}
            getType={(o) => o.percentage}
          />
        )}
      </DropdownContainer>
    );
  };
}

const likesOptions = [
  { id: 0, label: "Positive", value: true },
  { id: 1, label: "Negative", value: false },
];

const mapDispatchToProps = {
  setLikesFilters,
  loadInsightConcerns,
  selectInsightConcern,
  setFeedbackLevelFilter,
  selectInsightChildConcern,
};

const mapStateToProps = (state) => {
  const currentGroupId = state.selectedFilters.group;
  const currentGroup = state.groups[currentGroupId];
  const levels = state.auth.user.level;
  return {
    levels,
    selectedLevel: state.dashboard.insights.feedback_level,
    defaultSelectedLevel:
      currentGroup?.default_feedback_level ||
      (levels.length && levels[0].feedback_level) ||
      1,
    concerns: state.dashboard.concerns,
    selectedFilters: state.selectedFilters,
    selectedLikesOption: state.dashboard.insights.likes,
    selectedDashboard: state.dashboard.selectedDashboard,
    selectedChildConcern: state.dashboard.concerns.selectedChild,
    selectedParentConcern: state.dashboard.concerns.selectedParent,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightFilters);

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { Wrapper, Label } from "./styled";

import { setFormFieldValue, loadUsers } from "components/RCA/actions";

import { formFieldValueSelector } from "components/RCA/selectors";

const UserField = ({ field }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const [isOpen, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const ref = useRef();

  const teamMembers =
    useSelector((state) => formFieldValueSelector(state, "team_members")) || [];
  const teamLead =
    useSelector((state) => formFieldValueSelector(state, "team_lead")) || null;
  const owner =
    useSelector((state) => formFieldValueSelector(state, "owner")) || null;

  const allUsers = [...teamMembers, teamLead, owner].filter(
    (user, index, self) =>
      user &&
      index ===
        self.findIndex(
          (u) => u?.full_name === user?.full_name && u?.id === user?.id,
        ),
  );

  const selectedUser =
    useSelector((state) => formFieldValueSelector(state, fieldKey)) || null;

  const selectUser = (user) =>
    dispatch(
      setFormFieldValue(fieldKey, {
        full_name: user.label,
        id: user.value,
      }),
    );

  useEffect(() => {
    dispatch(loadUsers());
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const userOptions = allUsers.map((user) => {
    return {
      label: user?.full_name,
      value: user?.id,
    };
  });

  const defaultValue = userOptions.find(
    (user) => user?.value == selectedUser?.id,
  );

  const customStyles = (value) => ({
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${value ? "red" : "#e9e9e9"}`,
      minHeight: "46px",
      padding: "2px 24px",
    }),
  });

  const checkForError = (e) => {
    if (!defaultValue) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <Wrapper ref={ref} onClick={() => setOpen(!isOpen)}>
      <Label htmlFor={fieldKey}>{field.name}</Label>
      <Select
        value={defaultValue}
        name={fieldKey}
        onBlur={checkForError}
        options={userOptions}
        classNamePrefix="select"
        onChange={selectUser}
        styles={customStyles(isError)}
      />
    </Wrapper>
  );
};

export default UserField;

import styled from "styled-components";
import { Button } from "react-bootstrap";

export const ToolTip = styled.span`
  background-color: ${(props) => props.color};
  padding: 5px 4px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: var(--text-color);
  position: absolute;
  bottom: -30%;
  max-width: 135px;
  right: 0;
  display: none;
  box-shadow: #00000014 0px 3px 6px;
  &::after {
    content: "◀";
    position: absolute;
    bottom: 15%;
    left: -7%;
    font-size: 17px;
    color: #133b7d;
  }
`;

export const ChangePasswordButton = styled(Button)`
  transition: all 100ms;
  width: 100%;
  &:hover ${ToolTip} {
    display: block;
    bottom: 15%;
    right: -26%;
    transition: 0.5s ease-in-out right;
  }
`;

export const TOOLTIP_MESSAGE = {
  TRUE_SCORE:
    "True score reflects the real NPS score which is calculated after removing low quality feedback from the data collected. A high true score is always better than a low true score.",
  DATA_HEALTH:
    "Data health reflects the percentage of high quality data collected out of all data collected. High quality data can be referred to as the data that is fit for running any analysis as opposed to low quality data which can lead to incorrect results. High data health is better than low data health.",
  TOTAL_FEEDBACK:
    "Number of total feedback collected, including both high and low quality data.",
  RESPONSE_RATE_OVERALL:
    "Overall response rate is the percentage of responses recieved when someone reached out to the customer to either to resolve their issue or to get more information regarding their feedback. A high response rate is better than low response rate.",
  RESPONSE_RATE_DISTINCT:
    "Distinct response rate is the percentage of responses received from a unique customer when someone reached out either to resolve their issue or to get more information regarding their feedback. A high response rate is better than low response rate.",
  INVITE_STATS_TOTAL:
    "Total number of feedback requests sent to the customer to either resolve their issue or to get more information regarding their feedback.",
  INVITE_STATS_PENDING:
    "Total number of feedback requests sent to customer either to resolve their issue or to get more information regarding their feedback against which the customer did not respond.",
  INVITE_STATS_RECEIVED:
    "Total number of feedback requests sent to customer either to resolve their issue or to get more information regarding their feedback against which the customer responded.",
  FEEDBACK_SEGMENTS:
    "Number of Detractors, Passives and Promoters on the Net Promoter Score scale. When asked whether someone would recommend our services to their friends and family on a scale of 1-10, a person responding within 0-6 is a Detractor, a person responding with a 7 or 8 is a Passive and a person responding with a 9 or 10 is a Promoter.",
  RECOVERY_TIME:
    "Recovery time is the average time it takes to resolve a customer's complaint or conern. Low recovery time is better than high recovery time.",
  RECOVERY_RATE:
    "Recovery rate is the percentage of customers whose concern was resolved within the allocated time frame. It is calculated as a fraction of customers whose complaint were resolved by the number of all customers who had a complaint. A high recovery rate is better than low recovery rate.",
  NET_PROMOTER_SCORE:
    "The Net Promoter Score is an index ranging from -100 to 100 that measures the willingness of customers to recommend a company's products or services to others. NPS score above 0 is considered good, +50 is considered excellent and score above 90 is usually considered world class. Higher NPS score means that more of the customer base is loyal to the brand.",
  NPS_TIME_SERIES:
    "The Time Series graph shows the numbers of detractors, passives, promoters, recoverd, not recovered, unrecoverable and unprocessed over a period of time.",
  TOP_PRIORITIES_OF_THE_WEEK:
    "List of concerns that affect the net promoter score the most. The company should refer to this list to prioritize which concerns to resolve first in order to observe the highest change in net promoter score. Inner loops list concerns that are resolvable by the company itself while outer loop concerns are the ones that are not directly resolvable by the company itself.",
  TOP_CONCERNS:
    "The table below lists down the top concerns that most affect the score. It uses historical data to predict how much score would increase if that concern is fully resolved.",
  BENCHMARK_ACHIEVED: "Percentage of targeted responses achieved.",
  RECOVERED:
    "Number of customers whose concerns have been resolved within the allocated time frame.",
  UNRECOVERABLE:
    "Number of customers whose concerns could not have been resolved within the allocated time frame.",
  UNPROCESSED:
    "Number of customers that are still pending contact in response to their feedback, given their allocated time frame is not over.",
  NOT_RECOVERED:
    "Number of customers who could not be recovered in the allocated time period.",
  GENDER_GROUP_ANALYSIS: "Percentage of respondents by Age and Gender.",
  IMPACT_ON_SCORE:
    "Impact on score tells you how much does a certain concern affect the NPS.",
  PERCENTAGE: "Percentage of responses received regarding a specific concern.",
  SEGMENT_PIE_CHART:
    "Percentage of responses received regarding a specific segment for a given concern.",
  TOP_KEYWORDS:
    "Shows you the top keywords used by the customers when responding to the survey",
  COMMENTS: "Shows you the comments given by customers against the survey",
  PICTURE_SLIDER: "Shows you the media attached by customers in their feedback",
};

export const TOOLTIPS = {
  SHIFT_FILTER: "Filter by shift time or day",
  LOGO: "Go to Omni-view",
  QUESTIONNAIRE_PICKER: "Select a specific project",
  NOTIFICATION_ICON: "Notifications",
  RESET_FILTER: "Select to remove filters",
  PROFILE_PICTURE: "Dashboard Account",
  DATE_FILTER: "Select a date range",
  QUESTION_FILTER:
    "Select to filter stats for customer demographics and survey questions",
  SYSTEM_FILTER: "Filter for NPS Segment, Data Heath, Loops",
  PROJECT_FILTER: "Project filter",
  GLOBAL_VIEW_SWITCHER: "Select to change view type",
  GLOBAL_VIEW_SWITCHER_UNIT:
    "Select to view stats as count or percentage of all records",
  GLOBAL_VIEW_SWITCHER_GRAPH: "Select to choose a chart type",
  DOWNLOAD_REPORT: "Get your report emailed",
  AGGREGATE_FILTER:
    "Horizontal (row) and vertical (column) combined by union (OR)",
  SEGREGATE_FILTER:
    "Horizontal (row) combined by intersection (AND), vertical (column) combined by union (OR)",
};

export const NPS_FILTERS = {
  npsSegment: {
    title: "NPS Segment",
    values: [
      { id: "PRO", tag: "Promoters" },
      { id: "PAS", tag: "Passives" },
      { id: "DET", tag: "Detractors" },
    ],
  },
  csatSegment: {
    title: "CSAT Segment",
    values: [
      { id: "UNS", tag: "Unsatisfied customers (1-6)" },
      { id: "NEU", tag: "Neutral customers (7-8)" },
      { id: "SAT", tag: "Satisfied customer (9-10)" },
    ],
  },
  dataHealth: {
    title: "Data Health",
    values: [
      { id: 1, tag: "High Grade" },
      { id: 2, tag: "Low to Mid Grade" },
    ],
  },
  loop: {
    title: "Loop",
    values: [
      { id: "I", tag: "Inner" },
      { id: "O", tag: "Outer" },
    ],
  },
  age: {
    title: "Age",
    values: [
      { id: "Under18", tag: "Under 18" },
      { id: "18to24", tag: "18 to 24" },
      { id: "25to34", tag: "25 to 34" },
      { id: "35to44", tag: "35 to 44" },
      { id: "45to54", tag: "45 to 54" },
      { id: "55to64", tag: "55 to 64" },
      { id: "65+", tag: "65 & older" },
      { id: "nottosay", tag: "Prefer not to say" },
    ],
  },
  gender: {
    title: "Gender",
    values: [
      { id: "M", tag: "Male" },
      { id: "F", tag: "Female" },
    ],
  },
};

export const SEARCH_QUERY = "SEARCH_QUERY",
  LOAD_ORGANIZATION = "LOAD_ORGANIZATION",
  LOAD_ORGANIZATION_SUCCESS = "LOAD_ORGANIZATION_SUCCESS",
  LOAD_ORGANIZATION_ERROR = "LOAD_ORGANIZATION_ERROR",
  LOAD_CONCERNS = "LOAD_CONCERNS",
  LOAD_CONCERNS_SUCCESS = "LOAD_CONCERNS_SUCCESS",
  LOAD_CONCERNS_ERROR = "LOAD_CONCERNS_ERROR",
  LOAD_AUTH = "LOAD_AUTH",
  LOAD_AUTH_SUCCESS = "LOAD_AUTH_SUCCESS",
  SET_LANGUAGES_LIST = "SET_LANGUAGES_LIST",
  LOAD_AUTH_ERROR = "LOAD_AUTH_ERROR",
  UNAUTH_USER_REQUEST = "UNAUTH_USER_REQUEST",
  UNAUTH_USER = "UNAUTH_USER",
  UPDATE_USER_LAST_HEARTBEAT = "UPDATE_USER_LAST_HEARTBEAT",
  LOAD_USER_INFO = "LOAD_USER_INFO",
  LOAD_USER_INFO_SUCCESS = "LOAD_USER_INFO_SUCCESS",
  LOAD_USER_INFO_ERROR = "LOAD_USER_INFO_ERROR",
  UPDATE_USER_FIREBASE_TOKEN = "UPDATE_USER_FIREBASE_TOKEN",
  LOAD_DIVISIONS = "LOAD_DIVISIONS",
  LOAD_DIVISIONS_SUCCESS = "LOAD_DIVISIONS_SUCCESS",
  LOAD_DIVISIONS_ERROR = "LOAD_DIVISIONS_ERROR",
  STORE_GROUPS = "STORE_GROUPS",
  LOAD_COMMENTS = "LOAD_COMMENTS",
  LOAD_COMMENTS_SUCCESS = "LOAD_COMMENTS_SUCCESS",
  LOAD_COMMENTS_ERROR = "LOAD_COMMENTS_ERROR",
  LOAD_MORE_COMMENTS = "LOAD_MORE_COMMENTS",
  LOAD_MORE_COMMENTS_SUCCESS = "LOAD_MORE_COMMENTS_SUCCESS",
  LOAD_MORE_COMMENTS_ERROR = "LOAD_MORE_COMMENTS_ERROR",
  LOAD_PROJECTS_OVERVIEW = "LOAD_PROJECTS_OVERVIEW",
  LOAD_PROJECTS_OVERVIEW_SUCCESS = "LOAD_PROJECTS_OVERVIEW_SUCCESS",
  LOAD_PROJECTS_OVERVIEW_ERROR = "LOAD_PROJECTS_OVERVIEW_ERROR",
  LOAD_MEDIA = "LOAD_MEDIA",
  LOAD_MEDIA_SUCCESS = "LOAD_MEDIA_SUCCESS",
  LOAD_MEDIA_ERROR = "LOAD_MEDIA_ERROR",
  CLEAR_MEDIA_ERROR = "CLEAR_MEDIA_ERROR",
  LOAD_TIME_SERIES = "LOAD_TIME_SERIES",
  LOAD_TIME_SERIES_SUCCESS = "LOAD_TIME_SERIES_SUCCESS",
  LOAD_TIME_SERIES_ERROR = "LOAD_TIME_SERIES_ERROR",
  LOAD_TOP_CONCERNS = "LOAD_TOP_CONCERNS",
  LOAD_TOP_CONCERNS_SUCCESS = "LOAD_TOP_CONCERNS_SUCCESS",
  LOAD_TOP_CONCERNS_ERROR = "LOAD_TOP_CONCERNS_ERROR",
  SET_TOP_CONCERNS_ANALYSIS = "SET_TOP_CONCERNS_ANALYSIS",
  LOAD_SEMANTIC_ANALYSIS = "LOAD_SEMANTIC_ANALYSIS",
  LOAD_SEMANTIC_ANALYSIS_SUCCESS = "LOAD_SEMANTIC_ANALYSIS_SUCCESS",
  LOAD_SEMANTIC_ANALYSIS_ERROR = "LOAD_SEMANTIC_ANALYSIS_ERROR",
  LOAD_SATISFACTION_LEVEL = "LOAD_SATISFACTION_LEVEL",
  LOAD_SATISFACTION_LEVEL_SUCCESS = "LOAD_SATISFACTION_LEVEL_SUCCESS",
  LOAD_SATISFACTION_LEVEL_ERROR = "LOAD_SATISFACTION_LEVEL_ERROR",
  LOAD_PRIORITY_LIST = "LOAD_PRIORITY_LIST",
  LOAD_PRIORITY_LIST_SUCCESS = "LOAD_PRIORITY_LIST_SUCCESS",
  LOAD_PRIORITY_LIST_ERROR = "LOAD_PRIORITY_LIST_ERROR",
  SET_PRIORITY_LIST_ANALYSIS = "SET_PRIORITY_LIST_ANALYSIS",
  LOAD_NPS = "LOAD_NPS",
  LOAD_NPS_SUCCESS = "LOAD_NPS_SUCCESS",
  LOAD_NPS_ERROR = "LOAD_NPS_ERROR",
  LOAD_GENDER_GROUP_ANALYSIS = "LOAD_GENDER_GROUP_ANALYSIS",
  LOAD_GENDER_GROUP_ANALYSIS_SUCCESS = "LOAD_GENDER_GROUP_ANALYSIS_SUCCESS",
  LOAD_GENDER_GROUP_ANALYSIS_ERROR = "LOAD_GENDER_GROUP_ANALYSIS_ERROR",
  LOAD_DATA_HEALTH = "LOAD_DATA_HEALTH",
  LOAD_DATA_HEALTH_SUCCESS = "LOAD_DATA_HEALTH_SUCCESS",
  LOAD_DATA_HEALTH_ERROR = "LOAD_DATA_HEALTH_ERROR",
  LOAD_TRUE_SCORE = "LOAD_TRUE_SCORE",
  LOAD_TRUE_SCORE_SUCCESS = "LOAD_TRUE_SCORE_SUCCESS",
  LOAD_TRUE_SCORE_ERROR = "LOAD_TRUE_SCORE_ERROR",
  LOAD_CUSTOMER_SEGMENTATION = "LOAD_CUSTOMER_SEGMENTATION",
  LOAD_CUSTOMER_SEGMENTATION_SUCCESS = "LOAD_CUSTOMER_SEGMENTATION_SUCCESS",
  LOAD_CUSTOMER_SEGMENTATION_ERROR = "LOAD_CUSTOMER_SEGMENTATION_ERROR",
  LOAD_COMPETITOR_ANALYSIS = "LOAD_COMPETITOR_ANALYSIS",
  LOAD_COMPETITOR_ANALYSIS_SUCCESS = "LOAD_COMPETITOR_ANALYSIS_SUCCESS",
  LOAD_COMPETITOR_ANALYSIS_ERROR = "LOAD_COMPETITOR_ANALYSIS_ERROR",
  LOAD_BENCHMARKS = "LOAD_BENCHMARKS",
  LOAD_BENCHMARKS_SUCCESS = "LOAD_BENCHMARKS_SUCCESS",
  LOAD_BENCHMARKS_ERROR = "LOAD_BENCHMARKS_ERROR",
  LOAD_SEGMENT_ANALYSIS = "LOAD_SEGMENT_ANALYSIS",
  LOAD_SEGMENT_ANALYSIS_SUCCESS = "LOAD_SEGMENT_ANALYSIS_SUCCESS",
  LOAD_SEGMENT_ANALYSIS_FAILURE = "LOAD_SEGMENT_ANALYSIS_FAILURE",
  CLEAR_SEGMENT_ANALYSIS = "CLEAR_SEGMENT_ANALYSIS",
  LOAD_INVITE_STATS = "LOAD_INVITE_STATS",
  LOAD_INVITE_STATS_SUCCESS = "LOAD_INVITE_STATS_SUCCESS",
  LOAD_INVITE_STATS_FAILURE = "LOAD_INVITE_STATS_FAILURE",
  QUESTIONAIRE_SUCCESS = "questionaire_success",
  QUESTIONAIRE_FAILURE = "questionaire_failure",
  QUESTION_DETAIL_SUCCESS = "QUESTION_DETAIL_SUCCESS",
  QUESTION_DETAIL_FAILURE = "QUESTION_DETAIL_FAILURE",
  GET_QUESTIONNAIRE_PERCENTAGES = "GET_QUESTIONNAIRE_PERCENTAGES",
  QUESTIONAIRE_PERCENTAGE_SUCCESS = "QUESTIONAIRE_PERCENTAGE_SUCCESS",
  CHANGE_DIVISION = "CHANGE_DIVISION",
  FETCHING_SELECTED_FEEDBACK = "fetching_selected_feedback",
  SELECTED_FEEDBACK = "selected_feedback",
  CLEAR_SELECTED_FEEDBACK = "clear_selected_feedback",
  ORGANIZATION_SUCCESS = "organization_success",
  VERIFYING_ORGANIZATION = "verifying_organization",
  ORGANIZATION_FAILURE = "ORGANIZATION_FAILURE",
  FETCH_ORGANIZATION_FEATURES = "FETCH_ORGANIZATION_FEATURES",
  RESET_ORGANIZATION_FEATURE_SUCCESS = "RESET_ORGANIZATION_FEATURE_SUCCESS",
  SUBMIT_FEEDBACK_GROUP_SUCCESS = "SUBMIT_FEEDBACK_GROUP_SUCCESS",
  STORE_ORGANIZATION_DIVISIONS = "STORE_ORGANIZATION_DIVISIONS",
  CHANGE_DIVISIONS_PAGE = "CHANGE_DIVISIONS_PAGE",
  STORE_ORGANIZATION_LEVELS = "STORE_ORGANIZATION_LEVELS",
  STORE_POSSIBLE_PARENT_DIVISIONS = "STORE_POSSIBLE_PARENT_DIVISIONS",
  SUBMIT_DIVISION = "SUBMIT_DIVISION",
  FETCH_DIVISION = "FETCH_DIVISION",
  RESET_DIVISION = "RESET_DIVISION",
  STORE_ORGANIZATION_USERS = "STORE_ORGANIZATION_USERS",
  CHANGE_USERS_PAGE = "CHANGE_USERS_PAGE",
  STORE_USER_ROLES_ALERT = "STORE_USER_ROLES_ALERT",
  STORE_USER_ROLES = "STORE_USER_ROLES",
  STORE_USER_ROLE_DETAILS = "STORE_USER_ROLE_DETAILS",
  STORE_USER_DIVISIONS = "STORE_USER_DIVISIONS",
  SET_DIVISIONS_LOADING = "SET_DIVISIONS_LOADING",
  STORE_FETCHED_USER = "STORE_FETCHED_USER",
  CLEAR_FETCHED_USER = "CLEAR_FETCHED_USER",
  SAVE_UPDATE_USER_SUCCESS = "SAVE_UPDATE_USER_SUCCESS",
  SET_IS_ACTIVE_STATUS_SUCCESS = "SET_IS_ACTIVE_STATUS_SUCCESS",
  STORE_PRIMARY_DIVISIONS = "STORE_PRIMARY_DIVISIONS",
  SET_ANALYZER_FIELD = "SET_ANALYZER_FIELD",
  ADD_ANALYZER = "ADD_ANALYZER",
  SET_ANALYZER_FIELDS_VALUE = "SET_ANALYZER_FIELDS_VALUE",
  REMOVE_ANALYZER = "REMOVE_ANALYZER",
  FETCH_FEEDBACK_GROUP = "FETCH_FEEDBACK_GROUP",
  RESET_FEEDBACK_GROUP_ANALYZER = "RESET_FEEDBACK_GROUP_ANALYZER",
  FETCH_LEVELS = "FETCH_LEVELS",
  SUBMIT_LEVEL_SUCCESS = "SUBMIT_LEVEL_SUCCESS",
  FETCH_LEVEL = "FETCH_LEVEL",
  RESET_LEVEL = "RESET_LEVEL",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  RESET_UPDATE_ORGANIZATION_SUCCESS = "RESET_UPDATE_ORGANIZATION_SUCCESS",
  FETCHING_INSIGHTS = "fetching_insights",
  INSIGHTS_SUCCESS = "insights_success",
  INSIGHTS_FAILURE = "insights_failure",
  CLEAR_INSIGHTS = "clear_insights",
  LOAD_CONCERN_IMPACT_ON_SCORE = "load_concern_impact_on_score",
  LOAD_CONCERN_IMPACT_ON_SCORE_SUCCESS = "load_concern_impact_on_score_success",
  LOAD_CONCERN_IMPACT_ON_SCORE_FAILURE = "load_concern_impact_on_score_failure",
  FETCHING_KPI_DETAIL = "fetching_kpi_detail",
  KPI_DETAIL_SUCCESS = "kpi_detail_success",
  SELECTED_KPI_UPDATE = "selected_kpi_update",
  SELECTED_CHILD_CONCERN = "SELECTED_CHILD_CONCERN",
  FETCHING_KPI_FEEDBACK = "fetching_kpi_feedback",
  KPI_FEEDBACK_SUCCESS = "kpi_feedback_success",
  SET_FEEDBACK_LEVEL_FILTER = "set_feedback_level_filter",
  LOAD_SURVEY_QUESTIONS = "LOAD_SURVEY_QUESTIONS",
  LOAD_SURVEY_QUESTIONS_SUCCESS = "LOAD_SURVEY_QUESTIONS_SUCCESS",
  LOAD_SURVEY_QUESTIONS_ERROR = "LOAD_SURVEY_QUESTIONS_ERROR",
  SET_LIKES_FILTER = "set_likes_filter",
  CUSTOMERS_SUCCESS = "customers_success",
  DISTRICTS_SUCCESS = "filters_success",
  SET_SELECTED_DISTRICT = "set_selected_district",
  STORES_SUCCESS = "stores_success",
  SET_SELECTED_STORE = "set_selected_store",
  DEPARTMENTS_SUCCESS = "departments_success",
  SET_SELECTED_DEPARTMENT = "set_selected_department",
  CLEAR_SELECTED_DISTRICT = "clear_selected_district",
  CLEAR_SELECTED_STORE = "clear_selected_store",
  CLEAR_SELECTED_DEPARTMENT = "clear_selected_department",
  SET_DIVISION_LEVEL = "set_division_level",
  CLEAR_SELECTED_FILTERS = "CLEAR_SELECTED_FILTERS",
  MANAGE_USERS_SUCCESS = "manage_users_success",
  SET_FILTER = "SET_FILTER",
  SET_DATE_TIME_FILTER = "SET_DATE_TIME_FILTER",
  SET_GENERIC_FILTERS = "SET_GENERIC_FILTERS",
  RESET_GENERIC_FILTERS = "RESET_GENERIC_FILTERS",
  STORE_FILTER_SET = "STORE_FILTER_SET",
  TOGGLE_GENERIC_TAG = "TOGGLE_GENERIC_TAG",
  RESET_GENERIC_TAGS = "RESET_GENERIC_TAGS",
  SET_PRESET_FILTER = "SET_PRESET_FILTER",
  SET_SHIFT_FILTER = "SET_SHIFT_FILTER",
  RESET_SHIFT_FILTER = "RESET_SHIFT_FILTER",
  SET_FEEDBACK_FILTER = "SET_FEEDBACK_FILTER",
  SET_GLOBAL_VIEW = "SET_GLOBAL_VIEW",
  SET_ANALYSIS_TYPE = "SET_ANALYSIS_TYPE",
  CLEAR_ACTION = "clear_action",
  CLEAR_LOCATIONS = "CLEAR_LOCATIONS",
  SET_QUESTIONNAIRE_FIELD = "SET_QUESTIONNAIRE_FIELD",
  ADD_QUESTION_FORM = "ADD_QUESTION_FORM",
  REMOVE_QUESTION_FORM = "REMOVE_QUESTION_FORM",
  SET_QUESTION_FIELD = "SET_QUESTION_FIELD",
  SET_OPTION_FIELD = "SET_OPTION_FIELD",
  SET_SUB_OPTION_FIELD = "SET_SUB_OPTION_FIELD",
  ADD_OPTION_FORM = "ADD_OPTION_FORM",
  REMOVE_OPTION_FORM = "REMOVE_OPTION_FORM",
  ADD_SUB_OPTION_FORM = "ADD_SUB_OPTION_FORM",
  REMOVE_SUB_OPTION_FORM = "REMOVE_SUB_OPTION_FORM",
  STORE_SURVEY_CONFIGS = "STORE_SURVEY_CONFIGS",
  RESET_QUESTION_KIND = "RESET_QUESTION_KIND",
  SUBMIT_SURVEY_SUCCESS = "SUBMIT_SURVEY_SUCCESS",
  SUBMIT_SUCCESS = "SUBMIT_SUCCESS",
  STORE_SURVEYS = "STORE_SURVEYS",
  UPDATE_SUCCESSFUL = "UPDATE_SUCCESSFUL",
  CHANGE_SURVEY_PAGE = "CHANGE_SURVEY_PAGE",
  FETCHING_SURVEYS_TOGGLE = "FETCHING_SURVEYS_TOGGLE",
  EDIT_SURVEY = "EDIT_SURVEY",
  REMOVE_OPTION_IMAGE = "REMOVE_OPTION_IMAGE",
  ADD_DEPENDENT_OPTIONS = "ADD_DEPENDENT_OPTIONS",
  REMOVE_DEPENDENT_OPTIONS = "REMOVE_DEPENDENT_OPTIONS",
  STORE_ORG_DASHBOARD = "STORE_ORG_DASHBOARD",
  STORE_DASHBOARDS = "STORE_DASHBOARDS",
  SELECT_DASHBOARD = "SELECT_DASHBOARD",
  SELECT_RACKS = "SELECT_RACKS",
  SELECT_ANALYZER_UNIT = "SELECT_ANALYZER_UNIT",
  SELECT_ANALYZER_GRAPH = "SELECT_ANALYZER_GRAPH",
  LOAD_ANALYZER_DATA = "LOAD_ANALYZER_DATA",
  LOAD_DURATIONAL_ANALYZER_DATA = "LOAD_DURATIONAL_ANALYZER_DATA",
  LOAD_ANALYZER_DATA_SUCCESS = "LOAD_ANALYZER_DATA_SUCCESS",
  LOAD_ANALYZER_DATA_ERROR = "LOAD_ANALYZER_DATA_ERROR",
  LOAD_MORE_ANALYZER_DATA = "LOAD_MORE_ANALYZER_DATA",
  LOAD_MORE_ANALYZER_DATA_SUCCESS = "LOAD_MORE_ANALYZER_DATA_SUCCESS",
  LOAD_MORE_ANALYZER_DATA_ERROR = "LOAD_MORE_ANALYZER_DATA_ERROR",
  LOAD_WORDCLOUD = `LOAD_WORDCLOUD`,
  LOAD_WORDCLOUD_SUCCESS = `LOAD_WORDCLOUD_SUCCESS`,
  LOAD_WORDCLOUD_ERROR = `LOAD_WORDCLOUD_ERROR`,
  LOAD_INSIGHT_CONCERNS = "LOAD_INSIGHT_CONCERNS",
  LOAD_INSIGHT_CONCERNS_SUCCESS = "LOAD_INSIGHT_CONCERNS_SUCCESS",
  LOAD_INSIGHT_CHILD_CONCERNS_SUCCESS = "LOAD_INSIGHT_CHILD_CONCERNS_SUCCESS",
  LOAD_INSIGHT_CONCERNS_ERROR = "LOAD_INSIGHT_CONCERNS_ERROR",
  SELECT_INSIGHT_CONCERN = "SELECT_INSIGHT_CONCERN",
  SELECT_INSIGHT_CHILD_CONCERN = "SELECT_INSIGHT_CHILD_CONCERN",
  RESET_SELECTED_CONCERN = "RESET_SELECTED_CONCERN",
  LOAD_INSIGHT_CONCERN_DETAIL = "LOAD_INSIGHT_CONCERN_DETAIL",
  LOAD_INSIGHT_CONCERN_DETAIL_SUCCESS = "LOAD_INSIGHT_CONCERN_DETAIL_SUCCESS",
  LOAD_INSIGHT_CONCERN_DETAIL_ERROR = "LOAD_INSIGHT_CONCERN_DETAIL_ERROR",
  NPS_FILTER_TITLE = "NPS Segment",
  FEEDBACK_ACTION_FILTER_TITLE = "Action Type",
  DATA_HEALTH_SUCCESS = "data_health_success",
  FETCHING_DATA_HEALTH = "fetching_data_health",
  DATA_HEALTH_FAILURE = "data_health_failure",
  CUSTOMER_INVITES_SUCCESS = "customer_invites_success",
  LOAD_RCA_PROJECT_LIST = "LOAD_RCA_PROJECT_LIST",
  LOAD_RCA_PROJECT_LIST_SUCCESS = "LOAD_RCA_PROJECT_LIST_SUCCESS",
  LOAD_RCA_PROJECT_LIST_ERROR = "LOAD_RCA_PROJECT_LIST_ERROR",
  LOAD_RCA_PROJECT = "LOAD_RCA_PROJECT",
  LOAD_RCA_PROJECT_SUCCESS = "LOAD_RCA_PROJECT_SUCCESS",
  LOAD_RCA_PROJECT_ERROR = "LOAD_RCA_PROJECT_ERROR",
  LOAD_RCA_PROJECT_DATA = "LOAD_RCA_PROJECT_DATA",
  LOAD_RCA_PROJECT_DATA_SUCCESS = "LOAD_RCA_PROJECT_DATA_SUCCESS",
  LOAD_RCA_PROJECT_DATA_ERROR = "LOAD_RCA_PROJECT_DATA_ERROR",
  SELECT_RCA_PROJECT = "SELECT_RCA_PROJECT",
  START_NEW_PROJECT = "START_NEW_PROJECT",
  VIEW_PROJECT_LIST = "VIEW_PROJECT_LIST",
  SET_FORM_FIELD_VALUE = "SET_FORM_FIELD_VALUE",
  SET_RCA_OPTIONS_FIELD_VALUE = "SET_RCA_OPTIONS_FIELD_VALUE",
  DELETE_RCA_PROJECT = "DELETE_RCA_PROJECT",
  LOAD_TEAM_LEADS = "LOAD_TEAM_LEADS",
  LOAD_TEAM_LEADS_SUCCESS = "LOAD_TEAM_LEADS_SUCCESS",
  LOAD_TEAM_MEMBERS = "LOAD_TEAM_MEMBERS",
  LOAD_TEAM_MEMBERS_SUCCESS = "LOAD_TEAM_MEMBERS_SUCCESS",
  LOAD_CONCERN = "LOAD_CONCERN",
  LOAD_CONCERN_SUCCESS = "LOAD_CONCERN_SUCCESS",
  LOAD_LEVELS = "LOAD_LEVELS",
  LOAD_LEVELS_SUCCESS = "LOAD_LEVELS_SUCCESS",
  LOAD_USERS = "LOAD_USERS",
  LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS",
  LOAD_CONCERN_ANALYSIS = "LOAD_CONCERN_ANALYSIS",
  LOAD_CONCERN_ANALYSIS_SUCCESS = "LOAD_CONCERN_ANALYSIS_SUCCESS",
  LOAD_CONCERN_ANALYSIS_ERROR = "LOAD_CONCERN_ANALYSIS_ERROR",
  SET_TEAM_MEMBERS_EMPTY = "SET_TEAM_MEMBERS_EMPTY",
  SAVE_RCA_FORM = "SAVE_RCA_FORM",
  SWITCH_RCA_VIEW = "SWITCH_RCA_VIEW",
  SAVE_RCA_FORM_SUCCESS = "SAVE_RCA_FORM_SUCCESS",
  SAVE_RCA_FORM_ERROR = "SAVE_RCA_FORM_ERROR",
  TOGGLE_MODE = "TOGGLE_MODE",
  LOAD_ORGANIZATION_TAGS = "LOAD_ORGANIZATION_TAGS",
  LOAD_ORGANIZATION_TAGS_SUCCESS = "LOAD_ORGANIZATION_TAGS_SUCCESS",
  CREATE_ORGANIZATION_TAG = "CREATE_ORGANIZATION_TAG",
  CREATE_ORGANIZATION_TAG_SUCCESS = "CREATE_ORGANIZATION_TAG_SUCCESS",
  CREATE_ORGANIZATION_TAG_ERROR = "CREATE_ORGANIZATION_TAG_ERROR",
  SET_LOCATION_MATCH_ERROR = "SET_LOCATION_MATCH_ERROR",
  MIN_ROOT_CAUSES_DEPTH = 3,
  MAX_ROOT_CAUSES_DEPTH = 5,
  UPDATE_PROJECT_LIST_PAGE = "UPDATE_PROJECT_LIST_PAGE",
  VERIFY_VALID_LOCATION = "VERIFY_VALID_LOCATION",
  DATA_HEALTH_OVERLAY =
    "Data Health shows the quality of your data. It shows the percentage of feedback that clear the minimum data quality standards. The higher it is, the better.",
  TRUE_NPS_OVERLAY =
    "True NPS figure shows your actual NPS with the low quality feedback removed.",
  NPS_SEGMENTS = {
    DETRACTORS: "Detractors",
    PASSIVES: "Passives",
    PROMOTERS: "Promoters",
  },
  ZERO_SEQUENCE_KINDS = ["AG", "GE", "CI", "LN"];

export const META_OPTION_ACTION = { SELECT_ALL: "A", SELECT_NONE: "N" };
export const UUID_CHAR_LENGTH = 22;
export const QUERY_PARAM_DELIMITER = "?";

const QuestionKind = {
  OPEN_ENDED: "OE",
  COMPANY_ID: "CI",
  CONTACT_NUMBER: "RCN",
  EMAIL_ADDRESS: "REA",
  NAME: "RNM",
  COMMENT: "COM",
};

export const OPEN_ENDED_QUESTION_KINDS = [
  QuestionKind.OPEN_ENDED,
  QuestionKind.COMPANY_ID,
  QuestionKind.CONTACT_NUMBER,
  QuestionKind.EMAIL_ADDRESS,
  QuestionKind.NAME,
  QuestionKind.COMMENT,
];

export const MediaInput = {
  COMMENT: "COM",
  IMAGE: "IM",
  AUDIO: "AU",
  VIDEO: "VI",
};

export const MediaInputLabel = {
  [MediaInput.COMMENT]: "Comment",
  [MediaInput.IMAGE]: "Image",
  [MediaInput.AUDIO]: "Audio",
  [MediaInput.VIDEO]: "Video",
};

export const FeedbackAction = {
  RECOVERED: "REC",
  UNRECOVERABLE: "UNR",
  UNPROCESSED: "UNP",
  NOT_RECOVERED: "NRC",
  IN_PROGRESS: "INP",
};

export const FeedbackActionLabel = {
  [FeedbackAction.RECOVERED]: "Recovered",
  [FeedbackAction.UNRECOVERABLE]: "Unrecoverable",
  [FeedbackAction.UNPROCESSED]: "Unprocessed",
  [FeedbackAction.NOT_RECOVERED]: "Not Recovered",
  [FeedbackAction.IN_PROGRESS]: "In Progress",
};

export const FeedbackActionFilter = {
  ...FeedbackAction,
  DO_NOT_CALL: "DNC",
};

export const FeedbackActionFilterLabel = {
  ...FeedbackActionLabel,
  [FeedbackActionFilter.DO_NOT_CALL]: "Do not call",
};

export const SortByFilter = {
  PRIORITY: "P",
  RECENT: "R",
  OLDEST: "O",
};

export const SortByFilterLabel = {
  [SortByFilter.PRIORITY]: "priority",
  [SortByFilter.RECENT]: "recent",
  [SortByFilter.OLDEST]: "oldest",
};

export const FeedbackFilter = {
  ACTION_TYPE: "actionType",
  SORTY_BY: "sortBy",
};

export const ScoreLabel = {
  POSITIVE: 0,
  NEUTRAL: 1,
  NEGATIVE: 2,
};

export const ScoreKind = {
  NPS: "NP",
  STAR: "ST",
  CSAT: "CT",
};

export const QuestionOptionType = {
  OPTION: "QO",
  SUB_OPTION: "SO",
};

export const RCAProjectStatus = {
  DEPLOYMENT_PENDING: "DEPLOYMENT_PENDING",
  DEPLOYED: "DEPLOYED",
  PROGRESS: "PROGRESS",
  DEPLOYMENT_DENIED: "DEPLOYMENT_DENIED",
};

export const RCA_RATING_CHOICES = [
  { label: "A+", value: 10 },
  { label: "A", value: 9 },
  { label: "A-", value: 8 },
  { label: "B+", value: 7 },
  { label: "B", value: 6 },
  { label: "B-", value: 5 },
  { label: "C+", value: 4 },
  { label: "C", value: 3 },
  { label: "C-", value: 2 },
  { label: "D", value: 1 },
  { label: "F", value: 0 },
];

export const DateFilterType = {
  CUSTOM: "Custom",
  DEFAULT: "Default",
  PRESET: "Preset",
};

export const FilterNames = {
  DATE_FILTER_PERMISSION: "filter_date",
  ALL_TIME_DATE_FILTER_PERMISSION: "filter_all_time_date",
};

export const NotificationActivity = {
  USER_MENTION: "UM",
  CONNECT_BOX: "CB",
  RCA__DEPLOYMENT_APPROVAL__TEAM_LEAD: "RCA_DA_TL",
  RCA__DEPLOYMENT_DENIED__PROJECT_OWNER: "RCA_DD_PO",
  RCA__DEPLOYMENT_DENIED__PROJECT_MEMBERS: "RCA_DD_PM",
  RCA__DEPLOYED__PROJECT_OWNER: "RCA_D_PO",
  RCA__DEPLOYED__PROJECT_MEMBERS: "RCA_D_PM",
  RCA__REMOVAL__TEAM_LEAD: "RCA_R_TL",
  RCA__ADDITION__TEAM_LEAD: "RCA_A_TL",
  RCA__REMOVAL__TEAM_MEMBERS: "RCA_R_TM",
  RCA__ADDITION__TEAM_MEMBERS: "RCA_A_TM",
};

export const ChurnCategory = {
  HIGH: 2,
  MEDIUM: 1,
  LOW: 0,
};

export const ChurnCategoryLabel = {
  [ChurnCategory.HIGH]: "High",
  [ChurnCategory.MEDIUM]: "Medium",
  [ChurnCategory.LOW]: "Low",
};

export const DurationalAnalysisType = {
  COUNT: "count",
  NPS: "nps",
  RESPONSE_RATE: "response_rate",
  RECOVERY_RATE: "recovery_rate",
  AVERAGE_RECOVERY_TIME: "average_recovery_time",
};

export const DurationalAnalysisLabel = {
  [DurationalAnalysisType.COUNT]: "Feedback",
  [DurationalAnalysisType.NPS]: "NPS",
  [DurationalAnalysisType.RESPONSE_RATE]: "Response Rate",
  [DurationalAnalysisType.RECOVERY_RATE]: "Recovery Rate",
  [DurationalAnalysisType.AVERAGE_RECOVERY_TIME]: "Average Recovery Time",
};

export const Color = {
  ROYAL_BLUE: "royalblue",
  PURPLE: "purple",
  RED: "red",
  GREEN: "green",
  DARK_GOLDEN_RED: "darkgoldenrod",
  CARIBBEAN_GREEN: "#00BFA5",
  ORANGE: "orange",
  SALEM: "#0F9D58",
  CORNFLOWER_BLUE: "#4285F4",
  CARNATION: "#F7665E",
  SANDY_BROWN: "#f68e35",
};

import React from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useTranslation } from "react-i18next";
import {
  DateRange as BaseDateRange,
  DateRangePicker as BaseDateRangePicker,
  createStaticRanges,
} from "react-date-range";
import { startOfDay } from "date-fns";
import de from "date-fns/locale/de";
import enUS from "date-fns/locale/en-US";
import pl from "date-fns/locale/pl";

const DateRangePicker = ({ startDate, endDate, onChange, minDate, ranges }) => {
  const Picker = ranges.length ? BaseDateRangePicker : BaseDateRange;
  const { i18n } = useTranslation();

  const localMap = {
    en: enUS,
    de: de,
    pl: pl,
  };

  const staticRanges = React.useMemo(() => {
    return createStaticRanges(
      ranges.map((a) => {
        return {
          label: a.title,
          range: () => {
            return {
              startDate: startOfDay(new Date(a.start_date)),
              endDate: startOfDay(new Date(a.end_date)),
            };
          },
        };
      }),
    );
  }, [ranges]);

  return (
    <>
      <Picker
        locale={localMap[i18n.language]}
        months={2}
        maxDate={new Date()}
        minDate={new Date(minDate)}
        direction="horizontal"
        editableDateInputs={true}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        ranges={[
          {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
            key: "selection",
          },
        ]}
        inputRanges={[]}
        onChange={(range) => onChange(range.selection)}
        staticRanges={staticRanges}
      />
    </>
  );
};

export default DateRangePicker;

import React, { useState } from "react";
import { startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";

import * as analytics from "utils/analytics";
import TimePicker from "components/common/DateFilter/TimePicker/TimePicker";
import * as utils from "components/common/DateFilter/TimePicker/TimePickerUtils";
import TimePickerOptions from "components/common/DateFilter/TimePicker/TimePickerOptions";
import { DAYS } from "components/common/DateFilter/PresetFilter/PresetFilterUtils";

import {
  ShiftFilterContainer,
  DatePickerWrapper,
  DatePickerLabel,
  DayPickerContainer,
  DayPickerLabel,
  DayPickerItem,
  DayPickerItemsContainer,
  TimePickerContainer,
  CheckBoxContainer,
  Label,
  CheckBox,
  CheckBoxWrapper,
  CheckBoxLabel,
} from "./styled";

function ShiftFilterDropDown(props) {
  const { t } = useTranslation();

  const [startTime] = props.startTime
    ? utils.getTimeOptionSinceDayStart(props.startTime)
    : [DEFAULT_START_SHIFT];

  const [endTime] = props.endTime
    ? utils.getTimeOptionSinceDayStart(props.endTime)
    : [DEFAULT_END_SHIFT];

  const days = props.days || {};
  const [useTime, setUseTime] = useState(!!props.startTime);

  const setDay = (day) => {
    const days = { ...props.days };
    days[day.id] = !days[day.id];

    days[day.id] && !useTime && setUseTime(true);
    applyFilter(startTime, endTime, days, true);
  };

  const applyFilter = (startTime, endTime, days, useTime) => {
    let start;
    let end;
    if (useTime) {
      const day = startOfDay(new Date()).valueOf();
      end = day + utils.timeOptionToMilliseconds(endTime);
      start = day + utils.timeOptionToMilliseconds(startTime);
    }

    analytics.logShiftFilter(start, end, days);
    props.setShiftFilter(start, end, useTime ? days : {});

    if (!useTime) {
      props.onHide();
    }
  };

  const toggleTime = () => {
    setUseTime(!useTime);
    applyFilter(startTime, endTime, days, !useTime);
  };

  return (
    <ShiftFilterContainer>
      <CheckBoxContainer>
        <Label onClick={toggleTime}>
          {useTime ? t("turnOffShiftTime") : t("turnOnShiftTime")}
        </Label>
        <CheckBoxWrapper>
          <CheckBox
            id="checkbox"
            type="checkbox"
            checked={useTime}
            onClick={toggleTime}
          />
          <CheckBoxLabel htmlFor="checkbox" />
        </CheckBoxWrapper>
      </CheckBoxContainer>
      <TimePickerContainer switchedOn={useTime}>
        <DatePickerWrapper>
          <DatePickerLabel>{t("startTime")}</DatePickerLabel>
          <TimePicker
            selectedTime={startTime}
            onClick={(_startTime) => {
              const _endTime = utils.getValidEndTime(_startTime, endTime);
              !useTime && setUseTime(true);
              applyFilter(_startTime, _endTime, days, true);
            }}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePickerLabel>{t("endTime")}</DatePickerLabel>
          <TimePicker
            selectedTime={endTime}
            startTime={startTime}
            onClick={(option) => {
              !useTime && setUseTime(true);
              applyFilter(startTime, option, days, true);
            }}
          />
        </DatePickerWrapper>
      </TimePickerContainer>
      <DayPickerContainer>
        <DayPickerLabel>{t("selectDay")}</DayPickerLabel>
        <DayPickerItemsContainer>
          {DAYS.map((day) => {
            const isSelected = days[day.id];
            return (
              <DayPickerItem
                onClick={() => setDay(day)}
                isSelected={isSelected}
              >
                {day.key}
              </DayPickerItem>
            );
          })}
        </DayPickerItemsContainer>
      </DayPickerContainer>
    </ShiftFilterContainer>
  );
}

export default ShiftFilterDropDown;

const DEFAULT_START_SHIFT = TimePickerOptions[36];
const DEFAULT_END_SHIFT = TimePickerOptions[68];

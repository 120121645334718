import React from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericFilter from "components/common/GenericFilter/GenericFilter";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";

import { setGlobalView } from "components/DashboardPage/actions";
import { TOOLTIPS } from "constants/constants";

import { ReactComponent as UnitIcon } from "assets/svg/unit.svg";
import { ReactComponent as GraphIcon } from "assets/svg/graph.svg";
import { ReactComponent as GlobalViewIcon } from "assets/svg/global_view.svg";

const GlobalViewSwitcher = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentRack = useSelector((state) =>
    state.dashboard.selectedRacks.find((r) => r),
  );
  const selectedView = useSelector((state) => state.dashboard.globalView);
  return (
    <GenericFilter
      id="global_view_switcher"
      noScroll
      filterSet={FilterSet}
      Icon={GlobalViewIcon}
      selectedFilters={selectedView}
      applyFilter={(selectedView) =>
        dispatch(setGlobalView(selectedView, currentRack))
      }
      resetFilter={() => dispatch(setGlobalView(defaultView, currentRack))}
      tooltip={t(TOOLTIPS.GLOBAL_VIEW_SWITCHER)}
    />
  );
};

export default GlobalViewSwitcher;

const defaultView = { unit: { default: true }, graph: { default: true } };

const FilterSet = {
  filters: [
    {
      key: "unit",
      singleSelect: true,
      icon: UnitIcon,
      tooltip: TOOLTIPS.GLOBAL_VIEW_SWITCHER_UNIT,
      tags: [
        { key: "default", title: i18n.t("Default") },
        { key: "count", title: i18n.t("Count") },
        { key: "percentage", title: i18n.t("Percentage") },
      ],
    },
    {
      key: "graph",
      singleSelect: true,
      icon: GraphIcon,
      tooltip: TOOLTIPS.GLOBAL_VIEW_SWITCHER_GRAPH,
      tags: [
        { key: "default", title: i18n.t("Default") },
        { key: "bar", title: i18n.t("barChart") },
        { key: "donut", title: i18n.t("donutChart") },
        { key: "line", title: i18n.t("lineChart") },
        { key: "table", title: i18n.t("tableChart") },
      ],
    },
  ],
};

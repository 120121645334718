import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import AnalyzerRows from "components/Rack/AnalyzerRows";
import DurationalFilter from "components/Durational/DurationalFilter";
import { setAnalysisType } from "components/common/filters/actions";

import {
  DurationalAnalysisType as AnalysisType,
  DurationalAnalysisLabel as AnalysisLabel,
} from "constants/constants";

const Durational = ({ rack }) => {
  const analysisType = useSelector(
    (state) => state.selectedFilters.analysisType,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!analysisType) dispatch(setAnalysisType(AnalysisType.COUNT));
  }, [analysisType]);

  if (!analysisType) {
    return null;
  }

  const analyzers = rack.analyzers.map((analyzer) => {
    const parser = analyzerParser[analysisType];
    return parser(analyzer);
  });

  return (
    <>
      <DurationalFilter analysisType={analysisType} />
      <AnalyzerRows analyzers={analyzers} />
    </>
  );
};

export default Durational;

const analyzerParser = {
  [AnalysisType.COUNT]: (analyzer) => {
    const label = AnalysisLabel[AnalysisType.COUNT];
    return {
      ...analyzer,
      slug: analyzer.slug + "/" + AnalysisType.COUNT,
      heading: analyzer.heading.replace("Values", label),
      y_axis_label: "Count",
    };
  },

  [AnalysisType.NPS]: (analyzer) => {
    const label = AnalysisLabel[AnalysisType.NPS];
    return {
      ...analyzer,
      units: ["count"],
      slug: analyzer.slug + "/" + AnalysisType.NPS,
      heading: analyzer.heading.replace("Values", label),
      number_format: "0,0.0",
      y_axis_label: label,
    };
  },

  [AnalysisType.RECOVERY_RATE]: (analyzer) => {
    const label = AnalysisLabel[AnalysisType.RECOVERY_RATE];
    return {
      ...analyzer,
      units: ["count"],
      slug: analyzer.slug + "/" + AnalysisType.RECOVERY_RATE,
      heading: analyzer.heading.replace("Values", label),
      number_format: "0,0.0",
      y_axis_label: label,
      suffix: "%",
    };
  },

  [AnalysisType.RESPONSE_RATE]: (analyzer) => {
    const label = AnalysisLabel[AnalysisType.RESPONSE_RATE];
    return {
      ...analyzer,
      units: ["count"],
      slug: analyzer.slug + "/" + AnalysisType.RESPONSE_RATE,
      heading: analyzer.heading.replace("Values", label),
      number_format: "0,0.0",
      y_axis_label: label,
      suffix: "%",
    };
  },

  [AnalysisType.AVERAGE_RECOVERY_TIME]: (analyzer) => {
    const label = AnalysisLabel[AnalysisType.AVERAGE_RECOVERY_TIME];
    const slug = analyzer.slug + "/" + AnalysisType.AVERAGE_RECOVERY_TIME;

    return {
      ...analyzer,
      slug,
      units: ["count"],
      heading: analyzer.heading.replace("Values", label),
      number_format: "0,0.0",
      y_axis_label: label,
      suffix: " hrs",
    };
  },
};

import numeral from "numeral";

export const TimeSeriesChartsOptions = {
  maintainAspectRatio: false,
  tooltip: {
    enabled: false,
    placement: "left",
    caretSize: 5,
    cornerRadius: 6,
    custom: function (tooltipModel) {
      // Tooltip Element
      let tooltipEl = document.getElementById("chartjs-custom-tooltip");

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement("div");
        tooltipEl.id = "chartjs-custom-tooltip";
        tooltipEl.innerHTML = '<table class="tooltip-table"></table>';
        document.body.appendChild(tooltipEl);
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set caret Position
      tooltipEl.classList.remove("above", "below", "no-transform");
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
      } else {
        tooltipEl.classList.add("no-transform");
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      // Set Text
      if (tooltipModel.body) {
        let titleLines = tooltipModel.title || [];
        let bodyLines = tooltipModel.body.map(getBody);
        let dataPoint = tooltipModel.dataPoints[0];

        let innerHtml = "<thead>";
        let labelIndex = dataPoint.index;
        let tooltipRange = this._data.ranges[labelIndex];
        let tooltipBody = this._data.datasets.map((ds) => {
          return {
            count: numeral(ds.count[labelIndex]).format("0,0"),
            label: ds.label,
            percentage: ds.data[labelIndex],
            backgroundColor: ds.backgroundColor,
          };
        });

        let nps = this._data.datasets.find(
          (ds) => ds.label.toLowerCase() === "nps",
        );
        innerHtml +=
          '<tr class="header"><th colspan="2">' + tooltipRange.label + "</th>";
        innerHtml +=
          tooltipRange.label !== tooltipRange.text
            ? "<th>" + tooltipRange.text + "</th>"
            : "<th></th>";
        innerHtml += "</tr>";
        innerHtml +=
          '<tr class="header"><th colspan="2"><span style="display: inline-block; border: 1px solid white; vertical-align:middle; margin-right: 5px; width: 15px; height: 15px; background:' +
          nps.backgroundColor +
          '"></span>NPS</th><td>' +
          nps.data[labelIndex] +
          "</td></tr>";
        innerHtml += "<th>" + "Label" + "</th>";
        innerHtml += "<th>" + "Count" + "</th>";
        innerHtml += "<th>" + "Percentage" + "</th>";
        innerHtml += "</thead><tbody>";

        tooltipBody.forEach(function (body, i) {
          if (body.label.toLowerCase() !== "nps") {
            innerHtml += "<tr>";
            innerHtml +=
              '<td><span style="display: inline-block; border: 1px solid white; vertical-align:middle; margin-right: 5px; width: 15px; height: 15px; background:' +
              body.backgroundColor +
              '"></span>' +
              body.label +
              "</td>";
            innerHtml += "<td>" + body.count + "</td>";
            innerHtml += "<td>" + body.percentage + "%</td>";
            innerHtml += "</tr>";
          }
        });
        innerHtml += "</tbody>";

        let tableRoot = tooltipEl.querySelector("table");
        tableRoot.innerHTML = innerHtml;
      }

      // `this` will be the overall tooltip
      let position = this._chart.canvas.getBoundingClientRect();
      let clickPointPosition =
        position.left + window.pageXOffset + tooltipModel.caretX;
      // Display, position, and set styles for font
      tooltipEl.style.opacity = 0.9;
      tooltipEl.style.position = "absolute";
      tooltipEl.style.left =
        (clickPointPosition < tooltipEl.offsetWidth
          ? clickPointPosition
          : clickPointPosition - tooltipEl.offsetWidth) + "px";
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY + "px";
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
      tooltipEl.style.padding =
        tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
      tooltipEl.style.pointerEvents = "none";
    },
  },
  scales: {
    y: {
      grid: {
        display: false,
      },
      beginAtZero: false,
      ticks: {},
      title: {
        display: true,
        text: "%",
      },
    },
  },
  elements: {
    point: {
      radius: 2,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

import UserField from "./UserField";
import DeploymentDates from "./DeploymentDatesField";

const Fields = {
  DEPLOYMENT_DATES: DeploymentDates,
  CHECK_RESULT_USER: UserField,
  ADJUSTMENT_USER: UserField,
};

export default Fields;

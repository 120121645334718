import { FeedbackActionFilterLabel } from "constants/constants";
import { createSelector } from "reselect";

export const selectOrganization = (state) => state.organization.organization;

export const getOrganizationId = createSelector(
  selectOrganization,
  (organization) => organization.id,
);

export const getFeedbackActions = createSelector(
  selectOrganization,
  (organization) => organization.feedback_actions,
);

export const getInviteTypeFilterLabel = createSelector(
  selectOrganization,
  (organization) => organization.invite_types,
);

export const getFeedbackActionFilterLabel = createSelector(
  getFeedbackActions,
  (feedbackActionLabel) => ({
    ...FeedbackActionFilterLabel,
    ...feedbackActionLabel,
  }),
);

import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import { NoDataFound } from "../../common/NoDataFound";
import { Panel } from "../../common/Panel";

import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import { getGenderGroupAnalysisGraphData } from "../../../utils/chartsData";
import { loadGenderGroupAnalysis } from "../actions";

class GenderGroupAnalysis extends Component {
  getAPI() {
    const { loadGenderGroupAnalysis, selectedConcern } = this.props;
    loadGenderGroupAnalysis(selectedConcern);
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.selectedFilters, this.props.selectedFilters) ||
      this.props.selectedConcern !== prevProps.selectedConcern
    ) {
      this.getAPI();
    }
  }

  render() {
    const { genderGroupAnalysis } = this.props;
    const { fetching, analysis, isError } = genderGroupAnalysis;

    const tooltipHeader = (
      <span title={TOOLTIP_MESSAGE.GENDER_GROUP_ANALYSIS}>
        Gender Group Analysis
      </span>
    );

    if (fetching) {
      return (
        <Panel header={tooltipHeader}>
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    if (isError) {
      return (
        <Panel header={tooltipHeader}>
          <NoDataFound />
        </Panel>
      );
    }

    return (
      <Panel header={tooltipHeader}>
        <Bar
          data={getGenderGroupAnalysisGraphData(analysis)}
          options={{
            maintainAspectRatio: false,
            tooltip: {
              mode: "index",
            },
            scales: {
              x: [
                {
                  grid: {
                    display: false,
                  },
                },
              ],
              y: [
                {
                  ticks: {},
                  title: {
                    display: true,
                    text: "Percentage",
                  },
                  beginAtZero: true,
                },
              ],
            },
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, insights, dashboard }) => ({
  selectedFilters,
  genderGroupAnalysis: dashboard.genderGroupAnalysis,
  selectedConcern: insights.selectedChildConcern || insights.selectedConcern,
});

export default connect(mapStateToProps, { loadGenderGroupAnalysis })(
  GenderGroupAnalysis,
);

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          // table: {
          //   area: "Area",
          // },
          startTime: "Start Time",
          endTime: "End Time",
          selectDay: "Select Day",
          emailOrUsername: "Email or Username",
          noFeedbackFound: "No feedback found.",
          selectFeedbackFromList: "Please select a feedback from list",
          selectDateRange: "Select a date range",
          goToOmniView: "Go to Omni-view",
          password: "Password",
          passwordDesp:
            "Password must contain at least 8 characters. You will be logged out on changing your password.",
          passwordDesp2: "Password must contain at least 8 characters.",
          confirmPassword: "Confirm Password",
          newPassword: "New Password",
          currentPassword: "Current Password",
          update: "Update",
          changePassword: "Change Password",
          phoneNumber: "Phone Number",
          preferredLanguage: "Preferred Language",
          firstName: "First Name",
          lastName: "Last Name",
          username: "Username",
          dashboardAccount: "Dashboard Account",
          notifications: "Notifications",
          noDataFound: "No Data Found",
          "Empty Chart": "Empty Chart",
          logout: "Logout",
          profile: "Profile",
          searchHere: "search here",
          cancel: "Cancel",
          apply: "Apply",
          allTime: "All Time",
          "All Time": "All Time",
          "Last Quarter": "Last Quarter",
          "This Quarter": "This Quarter",
          "Last 30 days": "Last 30 days",
          "Last Month": "Last Month",
          "This Month": "This Month",
          "Last 7 Days": "Last 7 Days",
          "Last Week": "Last Week",
          "This Week": "This Week",
          "Select to remove filters": "Select to remove filters",
          "Get your report emailed": "Get your report emailed",
          preset: "Preset",
          custom: "Custom",
          default: "Default",
          "System Filter": "System Filter",
          locationFilter: "Location Filter",
          feedbackStatus: "Feedback Status",
          Default: "Default",
          Count: "Count",
          Percentage: "Percentage",
          barChart: "Bar Chart",
          donutChart: "Donut Chart",
          pieChart: "Pie Chart",
          lineChart: "Line Chart",
          tableChart: "Table",
          addNotes: "Add notes",
          resetAll: "Reset All",
          dateFilter: "Date Filter",
          LocationFilter: "Location Filter",
          questionFilter: "Question Filter",
          shiftFilter: "Shift Filter",
          pictureSlider: "Picture Slider",
          viewFeedback: "View Feedback",
          "Select to change view type": "Select to change view type",
          "Click to remove": "Click to remove",
          Jan: "Jan",
          Feb: "Feb",
          Mar: "Mar",
          Apr: "Apr",
          May: "May",
          Jun: "Jun",
          Jul: "Jul",
          Aug: "Aug",
          Sep: "Sep",
          Oct: "Oct",
          Nov: "Nov",
          Dec: "Dec",
          ToggleSortBy: "Toggle SortBy",
          turnOnShiftTime: "Turn on Shift Time",
          turnOffShiftTime: "Turn off Shift Time",
          unprocessedFeedback: "Unprocessed Feedback",
          sortBy: "Sort by",
          priority: "Priority",
          recent: "Recent",
          oldest: "Oldest",
          topConcerns: "Top Concerns",
          doNotCall: "Do not call",
          Monday: "Monday",
          Tuesday: "Tuesday",
          Wednesday: "Wednesday",
          Thursday: "Thursday",
          Friday: "Friday",
          Saturday: "Saturday",
          Sunday: "Sunday",
          hr: "Hr",
          hrs: "Hrs",
          min: "Min",
          mins: "Mins",
          day: "Day",
          days: "Days",
          Others: "Others",
          bar: "Bar",
          donut: "Donut",
          table: "Table",
          pie: "Pie",
          line: "Line",
          count: "Count",
          percentage: "Percentage",
          trend: "Trend",
          n: "n",
          "The password must contain at least 1 lowercase letter, a-z.":
            "The password must contain at least 1 lowercase letter, a-z.",
          "The password must contain at least 1 uppercase letter, A-Z.":
            "The password must contain at least 1 uppercase letter, A-Z.",
          "The password must contain at least 1 digit, 0-9.":
            "The password must contain at least 1 digit, 0-9.",
          "Enter a valid username. This value may contain only letters, numbers and ./_/@ characters.":
            "Enter a valid username. This value may contain only letters, numbers and ./_/@ characters.",
          "The password must contain at least 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?":
            "The password must contain at least 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?",
          "This password is too short. It must contain at least 13 characters.":
            "This password is too short. It must contain at least 13 characters.",
          "This password is too common.": "This password is too common.",
          "This password is entirely numeric.":
            "This password is entirely numeric.",
          "Invalid password.": "Invalid password",
          "Account with this email or username does not exists":
            "Account with this email or username does not exists",
          "Incorrect username and password combination.":
            "Incorrect username and password combination.",
          "User account is disabled.": "User account is disabled.",
          "Load More": "Load More",
          Loading: "Loading",
          "See All Activity": "See All Activity",
          "This email already exists in your organization.":
            "This email already exists in your organization.",
          "SMS Type": "SMS Type",
          "One Way": "One Way",
          "Two Way": "Two Way",
          sendToCustomer: "SEND TO CUSTOMER",
          inquireCustomer: "Inquire Customer",
          whatWhenWhereWhyHappened:
            "What happened? When did it happen? Where did it take place? Why did that happen?",
          forgotPassword: "Forgot Your Password?",
          login: "Login",
          addCredentials: "PLEASE ADD USERNAME OR EMAIL AND PASSWORD",
          needToChangePassword: "You need to change your password to continue",
          passwordChangeLogout:
            "You will be logged out on changing your password",
          reset: "Reset",
          uploadSquareImage: "Please upload a square image",
          imageSize1MB: "Image size should not exceed 1MB",
          fileSizeSquareImg:
            "File size shouldn't exceed 1MB. Please upload square image",
          removePic: "Remove Picture",
          usernameChangeLogout:
            "You will be logged out on changing your username",
          emailSent: "An email is sent to your address.",
          goToHomePage: "Click here to get to home page",
          wrongEmail: "Your email address is not correct",
        },
      },
      de: {
        translation: {
          // table: {
          //   area: "Area",
          // },
          startTime: "Dienstbeginn",
          endTime: "Deinstende",
          selectDay: "Wähle den Tag",
          emailOrUsername: "E-Mail oder Benutzername",
          noFeedbackFound: "Kein Feedback gefunden",
          selectFeedbackFromList:
            "Bitte wählen Sie ein Feedback aus der Liste aus",
          selectDateRange:
            "Wähle einen Datumsbereich aus, nachdem du filtern möchtest.",
          goToOmniView: "Gehen Sie zur Gesamt-Ansicht",
          password: "Passwort",
          "Select to remove filters":
            "Klicke hier, um den zuvor eingestellten Filter zu entfernen.",
          "Get your report emailed": "Report per email erhalten",
          passwordDesp:
            "Das Passwort muss mindestens 8 Zeichen enthalten. Sie werden nach dem Ändern Ihres Passworts abgemeldet.",
          passwordDesp2: "Das Passwort muss mindestens 8 Zeichen enthalten.",
          confirmPassword: "Passwort widerholen",
          newPassword: "Neues Kennwort",
          currentPassword: "Aktuelles Passwort",
          update: "Aktualisieren",
          changePassword: "Passwort ändern",
          phoneNumber: "Telefonnummer",
          preferredLanguage: "Bevorzugte Sprache",
          firstName: "Vorname",
          lastName: "Nachname",
          username: "Nutzername",
          dashboardAccount: "Benutzerkonto",
          notifications: "Beachrichtigungen",
          noDataFound: "Keine Daten gefunden",
          "Empty Chart": "Leeres Diagramm",
          logout: "Ausloggen",
          profile: "Profil",
          searchHere: "Hier suchen",
          cancel: "Abbrechen",
          apply: "Anwenden",
          allTime: "Gesamte Zeit",
          "All Time": "Gesamte Zeit",
          "Last Quarter": "Letztes Quartal",
          "This Quarter": "Dieses Quartal",
          "Last 30 days": "Letzten 30 Tage",
          "Last Month": "Letzter Monat",
          "This Month": "Dieser Monat",
          "Last 7 days": "Letzten 7 Tage",
          "Last Week": "Letzte Woche",
          "This Week": "Diese Woche",
          preset: "Monatlich",
          custom: "Benutzerdefiniert",
          default: "Voreingestellt",
          "System Filter": "Systemfilter",
          locationFilter: "Standortfilter",
          feedbackStatus: "Status der Reklamation",
          Default: "Vorgabe",
          Count: "Anzahl",
          Percentage: "Prozentsatz",
          barChart: "Balkendiagramm",
          pieChart: "Kreisdiagramm",
          donutChart: "Donutdiagramm",
          lineChart: "Liniendiagramm",
          tableChart: "Tabellarisch",
          addNotes: "Notizen hinzufügen",
          resetAll: "Alle zurücksetzen",
          dateFilter: "Anzahl",
          LocationFilter: "Trend",
          questionFilter: "Frage fitler",
          shiftFilter: "Zeitverschiebungsfilter",
          pictureSlider: "Bilder",
          viewFeedback: "Beschwerde anzeigen",
          "Select to change view type": "Ändern der Datenansicht",
          "Click to remove": "Klicken Sie hier, um zu entfernen",
          Jan: "Jan",
          Feb: "Feb",
          Mar: "Mär",
          Apr: "Apr",
          May: "Mai",
          Jun: "Jun",
          Jul: "Jul",
          Aug: "Aug",
          Sep: "Sep",
          Oct: "Okt",
          Nov: "Nov",
          Dec: "Dez",
          ToggleSortBy: "Sortierung ein-/ausblenden",
          turnOnShiftTime: "Diestzeit aktivieren",
          turnOffShiftTime: "Schalten Sie die Schichtzeit aus",
          unprocessedFeedback: "Unbearbeitetes Feedback",
          sortBy: "Sortieren nach",
          priority: "Priorität",
          recent: "Neueste",
          oldest: "Älteste",
          topConcerns: "Häufigste Probleme",
          doNotCall: "Nicht anrufen",
          Monday: "Montag",
          Tuesday: "Dienstag",
          Wednesday: "Mittwoch",
          Thursday: "Donnerstag",
          Friday: "Freitag",
          Saturday: "Samstag",
          Sunday: "Sonntag",
          hr: "Std",
          hrs: "Std",
          min: "Min",
          mins: "Min",
          day: "Tag",
          days: "Tag(e)",
          Others: "Andere",
          bar: "Balken",
          donut: "Krapfen",
          table: "Tabellarisch",
          pie: "Kuchen",
          line: "Linien",
          count: "Anzahl",
          percentage: "Prozentsatz",
          trend: "Trend",
          n: "a",
          "The password must contain at least 1 lowercase letter, a-z.":
            "Das Passwort muss mindestens einen Kleinbuchstaben (a-z) enthalten.",
          "The password must contain at least 1 uppercase letter, A-Z.":
            "Das Passwort muss mindestens einen Großbuchstaben (A-Z) enthalten.",
          "The password must contain at least 1 digit, 0-9.":
            "Das Passwort muss mindestens 1 Ziffer enthalten, 0-9.",
          "Enter a valid username. This value may contain only letters, numbers and ./_/@ characters.":
            "Geben Sie einen gültigen Benutzernamen ein. Dieser Wert darf nur Buchstaben, Zahlen und ./_/@-Zeichen enthalten.",
          "The password must contain at least 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?":
            "Das Passwort muss mindestens 1 Symbol enthalten: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?",
          "This password is too short. It must contain at least 13 characters.":
            "Dieses Passwort ist zu kurz. Es muss mindestens 13 Zeichen enthalten.",
          "This password is too common.": "Dieses Passwort ist zu häufig.",
          "This password is entirely numeric.":
            "Dieses Passwort ist ausschließlich numerisch. Es fehlen Buchstaben und Zeichen.",
          "Invalid password.": "falsches Passwort",
          "Account with this email or username does not exists":
            "Konto mit dieser E-Mail-Adresse oder diesem Benutzernamen existiert nicht",
          "Incorrect username and password combination.":
            "Falsche Kombination aus Benutzername und Passwort",
          "User account is disabled.": "Benutzerkonto ist deaktiviert.",
          "Load More": "Mehr laden",
          Loading: "Wird geladen",
          "See All Activity": "Alle Aktivitäten anzeigen",
          "This email already exists in your organization.":
            "Diese E-Mail-Adresse wird bereits verwendet.",
          "SMS Type": "SMS Typ",
          "One Way": "Einweg",
          "Two Way": "Zwei Wege",
          sendToCustomer: "AN KUNDEN SENDEN",
          inquireCustomer: "Kunde erkundigen",
          whatWhenWhereWhyHappened:
            "Was ist passiert? Wann ist es passiert? Wo hat es statt gefunden? Warum ist das passiert?",
          login: "Anmeldung",
          forgotPassword: "Haben Sie Ihr Passwort vergessen?",
          addCredentials:
            "BITTE BENUTZERNAME ODER E-MAIL UND PASSWORT HINZUFÜGEN",
          needToChangePassword:
            "Sie müssen Ihr Passwort ändern, um fortzufahren",
          passwordChangeLogout:
            "Beim Ändern Ihres Passworts werden Sie abgemeldet",
          reset: "Zurücksetzen",
          uploadSquareImage: "Bitte laden Sie ein quadratisches Bild hoch",
          imageSize1MB: "Die Bildgröße sollte 1 MB nicht überschreiten",
          fileSizeSquareImg:
            "Die Dateigröße sollte 1 MB nicht überschreiten. Bitte laden Sie ein quadratisches Bild hoch",
          removePic: "Bild entfernen",
          usernameChangeLogout:
            "Beim Ändern Ihres Benutzernamens werden Sie abgemeldet",
          emailSent: "Eine E-Mail wird an Ihre Adresse gesendet.",
          goToHomePage: "Klicken Sie hier, um zur Startseite zu gelangen",
          wrongEmail: "Ihre E-Mail-Adresse ist nicht korrekt",
        },
      },
      pl: {
        translation: {
          // table: {
          //   area: "Area",
          // },
          startTime: "Filtruj od godz",
          endTime: "Filtruj do godz",
          selectDay: "Wybierz dzień",
          emailOrUsername: "Email lub nazwa użytkownika",
          noFeedbackFound: "Nie znaleziono feedbacku",
          selectFeedbackFromList: "Proszę wybrać feedback z listy",
          selectDateRange: "Wybierz zakres dat",
          goToOmniView: "Przejdź do widoku ogólnego",
          password: "Hasło",
          "Get your report emailed": "Otrzymaj raporty na maila",
          "Select to remove filters": "Wybierz, aby usunąć filtry",
          passwordDesp:
            "Hasło musi zawierać co najmniej 8 znaków. Po zmianie hasła zostaniesz wylogowany.",
          passwordDesp2: "Hasło musi zawierać co najmniej 8 znaków.",
          confirmPassword: "Potwierdź hasło",
          newPassword: "Nowe hasło",
          currentPassword: "Aktualne hasło",
          update: "Aktualizacja",
          changePassword: "Zmień hasło",
          phoneNumber: "Numer telefonu",
          firstName: "Imię",
          lastName: "Nazwisko",
          username: "Nazwa użytkownika",
          dashboardAccount: "Konto panelu operatorskiego",
          notifications: "Powiadomienia",
          noDataFound: "Nie znaleziono danych",
          "Empty Chart": "Pusty wykres",
          logout: "Wyloguj się",
          profile: "Profil",
          searchHere: "Szukaj tutaj",
          cancel: "Anuluj",
          apply: "Zastosuj",
          allTime: "Cały czas",
          "All Time": "Cały czas",
          "Last Quarter": "Ostatni kwartał",
          "This Quarter": "Ten kwartał",
          "Last 30 days": "Ostatnie 30 dni",
          "Last Month": "Ostatni miesiąc",
          "This Month": "Ten miesiąc",
          "Last 7 days": "Ostatnie 7 dni",
          "Last Week": "Ostatni tydzień",
          "This Week": "W tym tygodniu",
          preset: "Kaledarzowo",
          custom: "Indiwidualnie",
          default: "Standardowo",
          "System Filter": "Filtr systemowy",
          locationFilter: "Filtr lokalizacji",
          feedbackStatus: "Status feedbacku",
          Default: "Standardowo",
          Count: "Liczba",
          Percentage: "Procent",
          barChart: "Wykres słupkowy",
          pieChart: "Wykres kołowy",
          donutChart: "Wykres pierścieniowy",
          lineChart: "Wykres liniowy",
          tableChart: "Tabelka",
          addNotes: "Dodaj notatki",
          resetAll: "Resetuj wszystko",
          dateFilter: "Filtr daty",
          LocationFilter: "Filtr lokalizacji",
          questionFilter: "Filtr pytań",
          shiftFilter: "Shift Filter",
          pictureSlider: "Suwak obrazów",
          viewFeedback: "Wyświetlanie informacji zwrotnej",
          "Select to change view type": "Wybierz, aby zmienić typ widoku",
          Jan: "styczeń",
          Feb: "luty",
          Mar: "marzec",
          Apr: "kwiecień",
          May: "maj",
          Jun: "czerwiec",
          Jul: "lipiec",
          Aug: "sierpień",
          Sep: "wrzesień",
          Oct: "październik",
          Nov: "listopad",
          Dec: "grudzień",
          ToggleSortBy: "Przełącz sortowanie",
          turnOnShiftTime: "Włączenie czasu zmiany",
          turnOffShiftTime: "Wyłącz czas zmiany",
          unprocessedFeedback: "Nierozpatrzone zgłoszenia",
          sortBy: "Sortuj według",
          priority: "Priorytetu",
          recent: "Najnowszych",
          oldest: "Najstarsze",
          topConcerns: "Najczęstsze problemy",
          doNotCall: "Nie dzwonić",
          Monday: "Poniedziałek",
          Tuesday: "Wtorek",
          Wednesday: "Środa",
          Thursday: "Czwartek",
          Friday: "Piątek",
          Saturday: "Sobota",
          Sunday: "Niedziela",
          hr: "godz",
          hrs: "godz",
          min: "min",
          mins: "min",
          day: "dzień",
          days: "dni",
          Others: "Inne",
          bar: "Słupkowy",
          donut: "Pierścieniowy",
          table: "Tabelka",
          pie: "Ciasto",
          line: "Liniowy",
          count: "Liczbowo",
          percentage: "Procentowo",
          trend: "Tendencja",
          n: "l",
          "The password must contain at least 1 lowercase letter, a-z.":
            "Hasło musi zawierać co najmniej 1 małą literę, a-z.",
          "The password must contain at least 1 uppercase letter, A-Z.":
            "Hasło musi zawierać co najmniej 1 dużą literę, A-Z",
          "The password must contain at least 1 digit, 0-9.":
            "Hasło musi zawierać co najmniej 1 cyfrę, 0-9.",
          "Enter a valid username. This value may contain only letters, numbers and ./_/@ characters.":
            "Wprowadź prawidłową nazwę użytkownika. Wartość ta może zawierać tylko litery, cyfry i znaki ./_/@.",
          "The password must contain at least 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?":
            "Hasło musi zawierać przynajmniej 1 symbol: ()[]{}|`~!@#$%^&*_-+=;:'\",<>./?",
          "This password is too short. It must contain at least 13 characters.":
            "Twoje proponowane hasło jest zbyt krótkie. Ono powinno zawierać co najmniej 13 znaków.",
          "This password is too common.":
            "To hasło jest zbyt powszechne w użyciu.",
          "This password is entirely numeric.":
            "Hasło to jest w całości numeryczne. Brakują litery i znaki.",
          "Invalid password.": "nieprawidłowe hasło",
          "Account with this email or username does not exists":
            "Konto o tym adresie e-mail lub nazwie użytkownika nie istnieje",
          "Incorrect username and password combination.":
            "Nieprawidłowa kombinacja nazwy użytkownika i hasła",
          "User account is disabled.": "Konto użytkownika jest wyłączone.",
          "Load More": "Zobacz więcej",
          Loading: "Ładowanie",
          "See All Activity": "Zobacz całą aktywność",
          "This email already exists in your organization.":
            "Ten adres e-mail już istnieje.",
          "SMS Type": "Typ SMSu",
          "One Way": "Jednokierunkowa",
          "Two Way": "Dwukierunkowy",
          sendToCustomer: "WYŚLIJ DO KLIENTA",
          inquireCustomer: "Zapytaj klienta",
          whatWhenWhereWhyHappened:
            "Co się stało? Kiedy to się stało? Gdzie miało to miejsce? Dlaczego tak się stało?",
          login: "Zaloguj sie",
          forgotPassword: "Zapomniałeś hasła?",
          addCredentials: "PROSZĘ DODAĆ NAZWĘ UŻYTKOWNIKA LUB E-MAIL I HASŁO",
          needToChangePassword: "Aby kontynuować, musisz zmienić hasło",
          passwordChangeLogout: "Po zmianie hasła zostaniesz wylogowany",
          reset: "Resetowanie",
          uploadSquareImage: "Prześlij kwadratowy obraz",
          imageSize1MB: "Rozmiar obrazu nie powinien przekraczać 1 MB",
          fileSizeSquareImg:
            "Rozmiar pliku nie powinien przekraczać 1 MB. Prześlij kwadratowy obraz",
          removePic: "Usuń zdjęcie",
          usernameChangeLogout:
            "Po zmianie nazwy użytkownika zostaniesz wylogowany",
          emailSent: "Wiadomość e-mail zostanie wysłana na Twój adres.",
          goToHomePage: "Kliknij tutaj, aby przejść do strony głównej",
          wrongEmail: "Twój adres e-mail jest nieprawidłowy",
        },
      },
    },
  });

export default i18n;

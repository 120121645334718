import * as constants from "./constants";

export const loadQuestionAnalyzers = () => {
  return {
    type: constants.LOAD_QUESTION_ANALYZERS,
  };
};

export const loadQuestionAnalyzersSuccess = (data) => {
  return {
    type: constants.LOAD_QUESTION_ANALYZERS_SUCCESS,
    data,
  };
};

export const loadQuestionAnalyzersError = (err) => {
  return {
    type: constants.LOAD_QUESTION_ANALYZERS_ERROR,
    err,
  };
};

export const loadSurveyScript = (questionnaireId) => {
  return {
    type: constants.LOAD_SURVEY_SCRIPT,
    payload: questionnaireId,
  };
};

export const loadSurveyScriptSuccess = (survey) => {
  return {
    type: constants.LOAD_SURVEY_SCRIPT_SUCCESS,
    payload: survey,
  };
};

export const loadSurveyScriptError = (error) => {
  return {
    type: constants.LOAD_SURVEY_SCRIPT_ERROR,
    payload: error,
  };
};

import { initialState } from "./initialState";
import * as constants from "./constants";

export const InvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.LOAD_INVOICES:
      return Object.assign({}, state, { isPending: true });

    case constants.LOAD_INVOICES_SUCCESS:
      return Object.assign({}, state, {
        isPending: false,
        apiData: action.data,
      });

    case constants.LOAD_INVOICES_ERROR:
      return Object.assign({}, state, {
        isPending: false,
        isError: true,
      });

    case constants.LOAD_MORE_INVOICES:
      return Object.assign({}, state, { isMoreFeedbackPending: true });

    case constants.LOAD_MORE_INVOICES_SUCCESS:
      return {
        ...state,
        isMoreFeedbackPending: false,
        apiData: {
          ...state.apiData,
          next: action.data.next,
          results: state.apiData.results.concat(action.data.results),
        },
      };

    case constants.LOAD_MORE_INVOICES_ERROR:
      return Object.assign({}, state, {
        isMoreFeedbackPending: false,
        isError: true,
      });

    default:
      return state;
  }
};

import React from "react";
import { ToolTipTextWrapper } from "./styled";
import PropTypes from "prop-types";

// align = topRight || bottomRight || bottomLeft || bottomCenter
// default is topLeft

export const TooltipText = ({ align, tooltipText }) => {
  return (
    <ToolTipTextWrapper
      className="tooltip-text-wrapper"
      tooltipText={tooltipText}
      align={align}
    />
  );
};

TooltipText.propTypes = {
  tooltipText: PropTypes.node.isRequired,
};

import styled, { css } from "styled-components";
import {
  TableWrapper as FetchedWrapper,
  Table as FetchedTable,
  Tr as FetchedTr,
  Td as FetchedTd,
} from "components/common/Table";

export const Wrapper = styled(FetchedWrapper)`
  padding: 0 40px;
  margin-top: 37px;
`;

export const NewProjectButton = styled.button`
  background: var(--primary-color);
  border-radius: 2px;
  color: var(--text-color);
  border: 0;
  float: right;
  margin-bottom: 27px;
  padding: 12px 39px;
  border: 2px solid var(--primary-color);
  font-size: 15px;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  transition: all 100ms ease-in-out;
  &:hover {
    background: var(--text-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
  }
`;

export const Table = styled(FetchedTable)`
  width: 100%;
  border: 0;
  box-shadow: 0 4px 14px #00000026;
  display: table;
`;

export const Thead = styled.thead`
  background-color: #efefef;
`;

export const Tbody = styled.tbody``;

export const Tr = styled(FetchedTr)`
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #fff9dd;
        transition: all 0.3s ease-in-out;
      }
      &:hover ${Title} {
        background-color: #fff9dd;
        transition: all 0.3s ease-in-out;
      }
    `}
  ${(props) =>
    props.isHighlighted &&
    css`
      background-color: #fff9dd;
      transition: all 0.3s ease-in-out;
      ${Title} {
        background-color: #fff9dd;
        transition: all 0.3s ease-in-out;
      }
    `}
`;

export const Th = styled.th`
  padding: 16px 20px;
  text-align: center;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #707070;
  border-bottom: 1px solid #b6b6b6;
`;

export const Td = styled(FetchedTd)`
  padding: 16px 5px;
  text-align: center;
  min-width: auto;
  width: auto !important;
`;

export const Title = styled(Td)`
  background-color: #efefef;
`;

export const Status = styled(Td)`
  color: ${(props) => props.color};
`;

export const RCANotConfigured = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.empty ? "60" : "80")}vh !important;  
  font-weight: bolder;
  color: currentColor;
  font-size: 19px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-family: "SegoeUI-Light", Arial, Helvetica, sans-serif; 
}`;

import React, { useRef } from "react";
import { FilterType, FilterList, FilterItem, FilterItemRemove } from "./styled";
import { useDispatch } from "react-redux";
import { Color } from "constants/constants";
import { values } from "lodash";

const FeedbackTagGroup = ({
  feedback,
  onTagClick,
  selectedTags,
  closeCallBack,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const Colors = values(Color);
  return (
    <FilterType ref={ref} className="ui-filtersBar-type">
      <FilterList className="ui-filtersBar-type_list">
        {feedback.tags.map((tag, index) => (
          <FilterItem
            className={`ui-filtersBar-type_list_item ${tag.id}`}
            bgColor={Colors[tag.id ? tag.id % Colors.length : "dimgrey"]}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                onTagClick(feedback, tag, selectedTags[index].isSelected),
              );
              closeCallBack();
            }}
          >
            {tag.label}
            <FilterItemRemove />
          </FilterItem>
        ))}
      </FilterList>
    </FilterType>
  );
};

export default FeedbackTagGroup;

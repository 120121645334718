import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
`;

export const Card = styled.div`
  margin-bottom: 16px;
  height: calc(100% - 16px);
  color: #707070;
  background-color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 35px 10px;
`;

export const Heading = styled.div`
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 0;
  width: 85%;
  text-align: center;
  color: var(--primary-color);
`;

export const HeadingSubtext = styled.div`
  text-align: "center";
`;

export const CardContent = styled.div`
  margin-top: 10px;
  width: 50%;
`;

export const OptionContainer = styled.span`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const Option = styled.span`
  display: flex;
  align-items: center;
`;

export const OptionText = styled.span`
  margin: 2px 10px;
  font-size: 17px;
`;

export const OptionSubText = styled.div`
  margin: 2px 22px;
  font-size: 12px;
`;

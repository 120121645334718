import styled, { css } from "styled-components";

export const MenuItemValue = styled.span`
  float: right;
  margin-left: 10px;
`;

export const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.1em;
  line-height: 1.2;
  text-transform: none;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  border-top: 1px solid #f1f1f3;
  white-space: normal;
  word-wrap: normal;
  padding: 10px 0;

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  &:first-child {
    border-top-width: 0;
  }

  &.selected,
  &:hover {
    background-color: #efefef;
  }
`;

export const DropdownMenuItemList = styled.div`
  padding: 0 13px;
`;

export const DropdownMenuLabel = styled.div`
  position: relative;
  cursor: default;
  color: var(--primary-color);
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  padding: 10px 13px;
  font-size: 0.9em;
  line-height: 1.2;

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  &.selected,
  &:hover {
    background-color: #efefef;
  }
`;

export const DropdownMenu = styled.div`
  max-height: 608px;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: none;
  position: absolute;
  top: 100%;
  margin-top: 13px;
  left: auto;
  right: 0;
  min-width: 100%;
  width: max-content;
  background-color: #ffffff;

  ${(props) =>
    props.open &&
    css`
      display: block;
    `}
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 0.28571429rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: 700;
  text-transform: none;
`;

export const Text = styled.div`
  display: inline-block;
  -webkit-transition: none;
  transition: none;

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
`;

export const Select = styled.div`
  cursor: pointer;
  position: relative;
  outline: 0;
  text-align: left;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  min-width: 14em;
  min-height: 2.71428571em;
  background: #fff;
  display: inline-block;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  width: 100%;

  i {
    position: absolute;
    margin: auto 1em;
    bottom: 0;
    top: 0;
    right: 0;
    height: 12px;
    transition: all 0.3s ease;
  }

  &::after {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -13px;
    right: 9px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    content: "";
  }

  ${(props) =>
    props.open &&
    css`
      ${DropdownMenu} {
        -webkit-box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
        box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
        display: block !important;
        visibility: visible !important;
      }

      i {
        transform: scale(-1);
      }

      &::after {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    `}
`;

export const SelectWrapper = styled.div`
  clear: both;
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.open &&
    css`
      z-index: 9 !important;
    `}
`;

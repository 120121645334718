import CustomTextField from "./CustomTextField";
import TextField from "./TextField";
import CustomUserField from "./CustomUserField";
import DepartmentField from "./DepartmentField";
import DateField from "./DateField";
import TeamLeadField from "./TeamLeadField";
import TeamMembersField from "./TeamMembersField";
import ConcernField from "./ConcernField";
import LoopField from "./LoopField";

const Fields = {
  TEXT_FIELD: CustomTextField,
  USER_FIELD: CustomUserField,
  PROJECT_NAME: TextField,
  TEAM_LEAD: TeamLeadField,
  TEAM_MEMBERS: TeamMembersField,
  PROJECT_START_DATE: DateField,
  LOCATION: DepartmentField,
  CONCERN: ConcernField,
  OWNER: TextField,
  LOOP: LoopField,
  CONCERN_CURRENT_STATE: TextField,
  CONCERN_DESIRED_STATE: TextField,
};

export default Fields;

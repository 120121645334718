import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

import { loadQuestionAnalyzers } from "./actions";

import MiniSpinner, { SpinnerContainer } from "components/common/MiniSpinner";
import Analyzer from "components/DashboardPage/Analyzer/Analyzer";
import { NoDataFound } from "components/common/NoDataFound";

const SurveyResults = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.survey.results.loading);
  const error = useSelector((state) => state.survey.results.error);
  const analyzers = useSelector((state) => state.survey.results.analyzers);
  const questionnaire = useSelector(
    (state) => state.selectedFilters.questionnaires,
  );

  useEffect(() => {
    dispatch(loadQuestionAnalyzers());
  }, [questionnaire]);

  return (
    <div className="charts">
      {loading ? (
        <SpinnerContainer>
          <MiniSpinner />
        </SpinnerContainer>
      ) : error ? (
        <NoDataFound />
      ) : (
        <Row className="justify-content-center">
          {analyzers.map((analyzer) => {
            return (
              <Col
                key={analyzer.slug}
                lg={analyzer.sizes.lg}
                sm={analyzer.sizes.sm}
              >
                <Analyzer analyzer={analyzer} />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default SurveyResults;

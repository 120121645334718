import React, { Component } from "react";
import { Col, Row, DropdownButton, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Link as DomRouterLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { setFilter, clearFilters } from "./filters/actions";
import { unauthRequest } from "../LoginPage/actions";
import * as analytics from "utils/analytics";
import NotificationDropDown from "./Notification/NotificationDropDown";
import { calculateOverallTimeDelta } from "../../HOC/telemetry";
import QuestionnairePicker from "./QuestionnairePicker";
import { getSelectedRacksByLevel } from "components/Rack/selectors";
import { selectRacks, selectDashboard } from "components/DashboardPage/actions";
import {
  RackLink,
  RackLinksContainer,
} from "components/common/SecondaryHeader";
import { debounce } from "utils/genericHelper";
import { indexesRoutes } from "routes";
import { UserAvatar } from "../common/UserAvatar";
import { getViewOnlyFeedbackUUID } from "components/FeedbackPage/selectors";
import { TooltipText } from "./Tooltip";
import withRouter from "withRouter";

class Topbar extends Component {
  constructor(props) {
    super(props);
    let [, level2Rack, level1Rack] = this.props.selectedRacks;
    this.state = {
      level1Racks: level1Rack?.racks,
      level2Racks: level2Rack?.racks,
      prevScrollPos: 0,
      visible: true,
    };
  }

  handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;
    const { selectedRacks } = this.props;
    const [, level2Rack, level1Rack] = selectedRacks;
    if (
      level1Rack?.racks != this.state.level1Racks ||
      level2Rack?.racks != this.state.level2Racks
    ) {
      return;
    }
    this.setState({
      visible:
        (this.state.prevScrollPos > currentScrollPos &&
          this.state.prevScrollPos - currentScrollPos >= 50) ||
        currentScrollPos < 10,
      prevScrollPos: currentScrollPos,
    });
  }, 50);

  componentDidMount = () => {
    this.updateSelectedRacks();
    window.addEventListener("scroll", this.handleScroll);
  };

  componentDidUpdate = (prevProps) => {
    const { location, dashboard, selectedRacks } = this.props;
    if (selectedRacks !== prevProps.selectedRacks) {
      const [, level2Rack, level1Rack] = selectedRacks;
      this.setState({
        level1Racks: level1Rack?.racks,
        level2Racks: level2Rack?.racks,
      });
    }

    if (location !== prevProps.location || dashboard !== prevProps.dashboard) {
      this.updateSelectedRacks();
    }
  };

  updateSelectedRacks = (route) => {
    const rootRacks = this.getRacks();
    const racks = getSelectedRacksByLevel(rootRacks, route);
    this.props.selectRacks(racks);
  };

  getRacks = () => {
    return (this.props.dashboard && this.props.dashboard.racks) || [];
  };

  logoutHandler = (e) => {
    const { location } = this.props;
    e.preventDefault();
    this.props.unauthRequest();

    const overallAnalytics = calculateOverallTimeDelta();
    analytics.logLogoutAction(location.pathname);
    analytics.logOverallActiveTime(overallAnalytics.activeTimeDiff);
  };

  render() {
    const {
      organization,
      user,
      location,
      selectedRacks,
      selectDashboard,
      dashboards,
    } = this.props;
    if (!user || !organization) {
      return;
    }

    const { t } = this.props;
    const racks = this.getRacks();
    const [level3Rack, level2Rack, level1Rack] = selectedRacks;
    const { colors, dashboard } = organization.features;
    const { first_name, last_name, role, avatar } = user;

    const navActiveClass = {
      background: colors.primary,
      borderBottom: `5px solid ${colors.secondary}`,
      color: colors.secondary,
    };

    const { isViewOnly } = getViewOnlyFeedbackUUID();
    const onMouseLeaveRack1 = () => {
      if (!this.state.level2Racks.length) {
        resetRacks();
      }
    };

    const resetRacks = () => {
      this.setState({
        level1Racks: level1Rack?.racks,
        level2Racks: level2Rack?.racks,
      });
    };

    const onMouseEnterRack1 = () => {
      if (
        level1Rack?.racks != this.state.level1Racks &&
        this.state.level2Racks.length
      ) {
        this.setState({
          level2Racks: [],
        });
      }
    };

    const onMouseLeaveRack2 = () => {
      if (level1Rack?.racks != this.state.level1Racks) {
        this.setState({
          level2Racks: [],
        });
      }
    };

    const LINK = indexesRoutes[
      location?.pathname?.split("/").filter((p) => p.length)[0]
    ]
      ? DomRouterLink
      : Link;

    const navbarStyles = {
      position: "sticky",
      width: "100%",
      backgroundColor: "grey",
      textAlign: "center",
      transition: "top 0.6s",
      zIndex: "100",
    };

    return (
      <div
        onMouseLeave={resetRacks}
        onMouseEnter={() =>
          this.setState({
            visible: true,
          })
        }
        style={{
          ...navbarStyles,
          top: this.state.visible
            ? "0"
            : `-${selectedRacks.filter((rack) => rack).length * 56}px`,
        }}
      >
        <header
          id="header"
          className={isViewOnly ? "hide" : ""}
          style={{
            background: `${colors.primary} no-repeat center`,
          }}
        >
          <div style={{ padding: "0 30px" }}>
            <Row>
              <Col sm={2} xs={2} md={2}>
                <div className="logo-container tooltipRel">
                  <DomRouterLink
                    exact
                    to={this.props.dashboards[0].racks[0].route}
                    onClick={() => {
                      analytics.logGoHomeAction();
                      selectDashboard(dashboards[0], true);
                    }}
                  >
                    <img
                      id="logo"
                      src={organization.logo_landscape}
                      width={`${dashboard.logo.topbar}%`}
                      alt=""
                    />
                  </DomRouterLink>
                  <TooltipText
                    tooltipText={t("goToOmniView")}
                    align="bottomLeft"
                  />
                </div>
              </Col>
              <Col sm={2}>
                <div className="store-view-dropdown tooltipRel">
                  <QuestionnairePicker />
                </div>
              </Col>
              <Col sm={8} className="navigation-panel">
                <div
                  className="navigation-options"
                  style={{ color: colors.text || "whitesmoke" }}
                  onMouseEnter={resetRacks}
                >
                  {racks.map((rack) => {
                    const isSelected =
                      level1Rack && rack.slug === level1Rack.slug;
                    const onClick = isSelected
                      ? undefined
                      : () => this.updateSelectedRacks(rack.route);

                    const onMouseEnter = () => {
                      this.setState({
                        level1Racks: rack?.racks,
                        level2Racks: [],
                      });
                    };

                    const onMouseLeave = () => {
                      if (!rack.racks.length) {
                        resetRacks();
                      }
                    };

                    return (
                      <LINK
                        to={rack.route}
                        exact
                        style={isSelected ? navActiveClass : {}}
                        className="navigation-option tooltipRel"
                        onClick={onClick}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                      >
                        {rack.name}
                        {!!rack.tooltip?.length && (
                          <TooltipText
                            tooltipText={rack.tooltip}
                            align="topRight"
                          />
                        )}
                      </LINK>
                    );
                  })}
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      height: "100%",
                    }}
                  >
                    {user.role.permissions.includes(
                      "can_send_notifications",
                    ) ? (
                      <NotificationDropDown />
                    ) : null}
                    <div className="user tooltipRel">
                      <DropdownButton title={<UserAvatar user={user} />}>
                        <Dropdown.Header>
                          <span
                            className="user-name"
                            style={{ color: "black" }}
                          >
                            {first_name} {last_name}
                            <span
                              style={{ display: "block" }}
                              className="text-muted"
                            >
                              {role.title}
                            </span>
                          </span>
                        </Dropdown.Header>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          as={DomRouterLink}
                          href="/profile"
                          to="/profile"
                        >
                          <i className="fa fa-user" /> {t("profile")}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={this.logoutHandler}>
                          <span>
                            <i className="fa fa-sign-out" /> {t("logout")}
                          </span>
                        </Dropdown.Item>
                      </DropdownButton>
                      <TooltipText
                        tooltipText={t("dashboardAccount")}
                        align="bottomRight"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </header>
        <Row
          id="rackslist-row"
          style={{
            position: "sticky",
            top: "70px",
            left: "0",
            right: "0",
            zIndex: "10",
            transition: "all .5s ease-in-out",
          }}
        >
          <Col sm={12}>
            {this.state.level1Racks && this.state.level1Racks.length > 0 && (
              <RackLinksContainer
                className="header-secondary"
                onMouseEnter={onMouseEnterRack1}
                onMouseLeave={onMouseLeaveRack1}
              >
                {this.state.level1Racks.map((rack) => {
                  const isSelected =
                    level2Rack && rack.route === level2Rack.route;
                  let classes = "header-secondary-link tooltipRel";
                  if (isSelected) classes += " selected";
                  const onClick = isSelected
                    ? undefined
                    : () => this.updateSelectedRacks(rack.route);

                  const onMouseEnter = () => {
                    this.setState({
                      level2Racks: rack?.racks,
                    });
                  };

                  return (
                    <LINK
                      to={rack.route}
                      exact
                      onClick={onClick}
                      onMouseEnter={onMouseEnter}
                    >
                      <RackLink className={classes} level1Rack={true}>
                        {rack.name}
                        {!!rack.tooltip?.length && (
                          <TooltipText
                            tooltipText={rack.tooltip}
                            align="bottomRight"
                          />
                        )}
                      </RackLink>
                    </LINK>
                  );
                })}
              </RackLinksContainer>
            )}
            {this.state.level2Racks && this.state.level2Racks.length > 0 && (
              <RackLinksContainer
                className="header-secondary"
                onMouseLeave={onMouseLeaveRack2}
              >
                {this.state.level2Racks.map((rack) => {
                  const isSelected =
                    level3Rack && rack.slug === level3Rack.slug;
                  let classes = "header-secondary-link tooltipRel";
                  if (isSelected) classes += " selected";
                  const onClick = isSelected
                    ? undefined
                    : () => this.updateSelectedRacks(rack.route);

                  return (
                    <LINK to={rack.route} exact onClick={onClick}>
                      <RackLink className={classes}>
                        {rack.name}
                        {!!rack.tooltip?.length && (
                          <TooltipText
                            tooltipText={rack.tooltip}
                            align="bottomRight"
                          />
                        )}
                      </RackLink>
                    </LINK>
                  );
                })}
              </RackLinksContainer>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const Link = (props) => (
  <a
    style={props.style}
    className={props.className}
    onClick={props.onClick}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
  >
    {props.children}
  </a>
);

const mapStateToProps = (state) => ({
  organization: state.organization.organization,
  dashboard: state.dashboard.selectedDashboard,
  selectedRacks: state.dashboard.selectedRacks,
  dashboards: state.dashboard.dashboards,
});

export default withRouter(
  connect(mapStateToProps, {
    setFilter,
    clearFilters,
    unauthRequest,
    selectRacks,
    selectDashboard,
  })(withTranslation()(Topbar)),
);

import { createSelector } from "reselect";

export const selectQuestionnaireId = createSelector(
  (state) => state.dashboard.selectedDashboard,
  (selectedDashboard) => selectedDashboard.id,
);

export const selectUserName = createSelector(
  (state) => state.auth.user,
  (user) => user.first_name,
);

import styled from "styled-components";

export const DateRange = styled.div`
  color: #696969;
  ${(props) =>
    props.isSelected &&
    "background-color: var(--primary-color) !important; color: white;"}
  display: block;
  font-size: 13px;
  padding: 10px 15px 10px 15px;
  border-bottom: 1px solid lightgrey;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: var(--primary-color) !important;
  }
`;

export const DefaultFilterTab = styled.div`
  display: block;
  justify-content: space-between;
  min-width: 300px;
  margin-top: 5px;
`;

export const StyledDate = styled.div`
  display: inline;
  float: right;
  color: #b0b0b0;
  ${(props) => props.isSelected && "color: white;"}
  ${DateRange}:hover & {
    color: white;
  }
`;

import styled from "styled-components";
import { Button } from "react-bootstrap";

export const PopUpBox = styled.div`
  position: fixed;
  background-color: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const Box = styled.div`
  position: relative;
  width: 50%;
  top: 20%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
`;

export const ButtonBox = styled.div`
  text-align: left;
  padding: 0.6em 0.9em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-flow: row wrap;
`;

export const ActionButton = styled(Button)`
  border-color: transparent;
  background: #ccc;
  color: #fff;
  border-radius: 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.confirm {
    background-color: #89c120;
    border-color: #89c120;
  }
`;

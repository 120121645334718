import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import DefaultFilter from "./DefaultFilter/DefaultFilter";
import CustomFilter from "./CustomFilter/CustomFilter";
import PresetFilter from "./PresetFilter/PresetFilter";
import CustomLabel from "./CustomFilter/CustomLabel";
import PresetLabel from "./PresetFilter/PresetLabel";
import {
  BootDropdown,
  BootDropdownToogle,
  BootDropdownMenu,
  DateFilterTabs,
  DateFilterTab,
} from "./styled";
import { TooltipText } from "../Tooltip";
import { TOOLTIPS, DateFilterType, FilterNames } from "constants/constants";
import { setDateTimeFilter } from "components/DashboardPage/actions";
import { getUpdatedDates } from "./DefaultFilter/DefaultFilter";
import { getRefreshTime } from "components/DashboardPage/Analyzer/Analyzer";

localStorage.setItem("oldDate", new Date());
export const CHECK_DATE_REFRESH_TIME = 15 * 60 * 1000;
export const ALLTIME_DATE_REFRESH_TIME = 60 * 60 * 1000;

class DateFilter extends Component {
  constructor(props) {
    super(props);
    const activeTab = this.props.filterType;
    const startingYear = new Date(props.organizationCreatedAt).getFullYear();
    this.state = { visible: false, activeTab, startingYear };
  }

  dateChanged = () => {
    const currentDate = new Date();
    const savedOldDate = new Date(Date.parse(localStorage.getItem("oldDate")));

    if (currentDate.toDateString() !== savedOldDate.toDateString()) {
      localStorage.setItem("oldDate", currentDate);
      return true;
    }
    return false;
  };

  checkDateChange = () => {
    if (this.dateChanged()) {
      const updatedDates = getUpdatedDates();
      this.props.setDateTimeFilter(
        ...updatedDates,
        DateFilterType.CUSTOM,
        null,
      );
    }
  };

  ref = createRef();

  componentDidMount = () => {
    document.body.addEventListener("click", this.onBodyClick);
    let refreshTime = getRefreshTime(
      "datefilter",
      this.props.filterType,
      this.props.defaultDateRange,
    );
    this.intervalId = setInterval(this.checkDateChange, refreshTime);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener("click", this.onBodyClick);
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  onBodyClick = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.onHide(this.props.filterType);
    }
  };

  onHide = (activeTab = DateFilterType.CUSTOM) => {
    if (this.state.visible) {
      this.setState({ visible: !this.state.visible, activeTab });
    }
  };

  render = () => {
    if (!this.props.permissions[FilterNames.DATE_FILTER_PERMISSION]) {
      return null;
    }

    const { startDate, endDate, months, years } = this.props;
    const { activeTab, visible } = this.state;
    const { t } = this.props;

    const Label =
      this.props.filterType === DateFilterType.PRESET
        ? PresetLabel
        : CustomLabel;

    return (
      <>
        <BootDropdown
          ref={this.ref}
          show={this.state.visible}
          className="tooltipRel"
        >
          <BootDropdownToogle
            onClick={() => this.setState({ visible: !this.state.visible })}
            className="option selected-range"
            id="date-picker-dropdown"
          >
            <Label
              years={years}
              months={months}
              endDate={endDate}
              startDate={startDate}
              startingYear={this.state.startingYear}
            />
          </BootDropdownToogle>
          <BootDropdownMenu className="date-filter--modal">
            <DateFilterTabs
              activeKey={this.state.activeTab}
              onSelect={(activeTab) => this.setState({ activeTab })}
            >
              <DateFilterTab
                eventKey={DateFilterType.DEFAULT}
                title={t("default")}
              >
                {visible && activeTab === DateFilterType.DEFAULT && (
                  <DefaultFilter
                    hide={this.onHide}
                    selectedKey={this.state.selectedKey}
                  />
                )}
              </DateFilterTab>
              <DateFilterTab
                eventKey={DateFilterType.CUSTOM}
                title={t("custom")}
              >
                {visible && activeTab === DateFilterType.CUSTOM && (
                  <CustomFilter hide={this.onHide} />
                )}
              </DateFilterTab>
              <DateFilterTab
                eventKey={DateFilterType.PRESET}
                title={t("preset")}
              >
                {visible && activeTab === DateFilterType.PRESET && (
                  <PresetFilter
                    hide={this.onHide}
                    startingYear={this.state.startingYear}
                  />
                )}
              </DateFilterTab>
            </DateFilterTabs>
          </BootDropdownMenu>
          {!this.state.visible && (
            <TooltipText
              tooltipText={t("selectDateRange")}
              align="bottomLeft"
            />
          )}
        </BootDropdown>
      </>
    );
  };
}

const mapDispatchToProps = {
  setDateTimeFilter,
};

const mapStateToProps = (state) => {
  return {
    filterType: state.selectedFilters.dateFilterType,
    years: state.selectedFilters.years,
    months: state.selectedFilters.months,
    endDate: state.selectedFilters.endDate,
    startDate: state.selectedFilters.startDate,
    permissions: state.auth.user.permissions,
    organizationCreatedAt: state.organization.organization.created_at,
    defaultDateRange: state.selectedFilters.defaultDateRange,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(DateFilter));

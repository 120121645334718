import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  Col,
  Alert,
} from "react-bootstrap";
import { omit } from "lodash";

import { getUserDetail, updateUserDetail } from "./apis";
import { Header } from "../common/Header";
import { Panel } from "../common/Panel";
import DefaultAvatar from "../../assets/img/user-avatar.png";
import { getBase64 } from "../../utils/genericHelper";

class SetupProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      isSamePassword: null,
      isError: false,
      message: "",
    };
  }

  componentDidMount() {
    this.getAPI();
  }

  getAPI = async () => {
    const { match } = this.props;
    var apiData = await getUserDetail(match.params.uuid);
    this.setState({
      data: apiData.user,
      message: apiData.message,
    });
  };

  onChange = (e) => {
    const { target } = e;
    const { name, value, type } = target;

    if (type === "file" && target.files[0]) {
      getBase64(target.files[0]).then((data) =>
        this.setState({
          data: {
            ...this.state.data,
            [name]: data,
          },
        }),
      );
    } else if (name === "name") {
      this.setState({
        data: {
          ...this.state.data,
          first_name: value.split(" ")[1] ? value.split(" ")[0] : value,
          last_name: value.split(" ")[1]
            ? value.split(" ").slice(1).join(" ")
            : "",
        },
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [name]: value,
        },
      });
    }
  };

  confirmPassword = (e) => {
    const { data } = this.state;
    if (e.target.value !== data.password) {
      this.setState({
        isSamePassword: false,
      });
    } else {
      this.setState({
        isSamePassword: true,
      });
    }
  };

  submitUserDetail = async (e) => {
    e.preventDefault();
    const { match } = this.props;
    const { data } = this.state;
    let payload = omit(data, ["role"]);
    if (payload.avatar && payload.avatar.search("data:image/") === -1) {
      payload = omit(payload, ["avatar"]);
    }
    var response = await updateUserDetail(match.params.uuid, payload);

    if (response.status === "C") {
      this.setState({
        message: response.message,
        isError: false,
      });
      sessionStorage.setItem("user-name", this.state.data.username);
      setTimeout(() => {
        this.props.navigate("/login");
      }, 1000);
    } else {
      this.setState({
        message: response.message,
        isError: true,
      });
    }
  };

  render() {
    const { organization, auth } = this.props;
    const { message, data, isSamePassword, isError } = this.state;
    const { t } = this.props;

    const {
      first_name,
      last_name,
      role,
      email,
      password,
      confirm_password,
      avatar,
    } = data || {};

    if (auth.authenticated) {
      return <Navigate to={"/"} />;
    }

    return (
      <div>
        <Header organization={organization} />
        <div className="setup-profile">
          <Panel>
            {data ? <h3>Complete your Profile</h3> : null}
            {message ? (
              <Alert variant={isError ? "danger" : "success"}>{message}</Alert>
            ) : null}
            {data ? (
              <Form onSubmit={this.submitUserDetail} autocomplete="off">
                <div className="fields-wrapper">
                  <Col md={6} mdPush={6}>
                    <FormGroup controlId="avatar">
                      <img
                        src={avatar || DefaultAvatar}
                        className="avatar-image"
                      />
                      <div className="button-holder">
                        <FormControl
                          type="file"
                          name="avatar"
                          className="image-field"
                          onChange={this.onChange}
                          accept="image/*"
                          autocomplete="nope"
                        />
                        <label
                          className="btn image-select-label"
                          htmlFor="avatar"
                        >
                          <i className="fa fa-picture-o" aria-hidden="true"></i>{" "}
                          Select an Image
                        </label>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6} mdPull={6}>
                    <FormGroup controlId="name">
                      <FormControl
                        type="text"
                        name="name"
                        value={
                          first_name || last_name
                            ? `${first_name || ""}${
                                last_name ? " " + last_name : ""
                              }`
                            : ""
                        }
                        placeholder="Enter Name"
                        onChange={this.onChange}
                        autocomplete="nope"
                      />
                    </FormGroup>
                    <FormGroup controlId="role">
                      <FormControl
                        type="text"
                        name="role"
                        value={role && role.title}
                        placeholder="Role"
                        disabled
                        read-only
                        autocomplete="nope"
                      />
                    </FormGroup>
                    <FormGroup controlId="email">
                      <FormControl
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        onChange={this.onChange}
                        required
                        disabled
                        read-only
                        autocomplete="nope"
                      />
                    </FormGroup>
                    <FormGroup controlId="company">
                      <FormControl
                        type="text"
                        name="company"
                        value={organization.name}
                        placeholder="Company"
                        required
                        disabled
                        read-only
                        autocomplete="nope"
                      />
                    </FormGroup>
                    <FormGroup controlId="password">
                      <FormControl
                        type="password"
                        name="password"
                        value={password}
                        placeholder="Enter Password"
                        onChange={this.onChange}
                        required
                        autocomplete="new-password"
                      />
                    </FormGroup>
                    <FormGroup
                      controlId="confirm-password"
                      validationState={
                        isSamePassword
                          ? "success"
                          : isSamePassword === false
                          ? "error"
                          : ""
                      }
                    >
                      <FormControl
                        type="password"
                        name="confirm-password"
                        id="confirm-password"
                        value={confirm_password}
                        placeholder={t("confirmPassword")}
                        onChange={this.confirmPassword}
                        autocomplete="new-password"
                        inputRef={(ref) => (this.confirmPasswordRef = ref)}
                      />
                    </FormGroup>
                    {isSamePassword === false ? (
                      <p className="error">
                        <i className="fa fa-times-circle-o not-allowed" />
                        The password didn&apos;t match.
                        <a
                          href="javascript:void()"
                          onClick={() => this.confirmPasswordRef.focus()}
                        >
                          Try again?
                        </a>
                      </p>
                    ) : null}
                    <Button
                      className="btn btn-primary add-user-btn"
                      type="submit"
                      disabled={isSamePassword === false}
                    >
                      LET&apos;S GO
                    </Button>
                  </Col>
                </div>
              </Form>
            ) : null}
          </Panel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ organization, auth }) => ({
  organization: organization.organization,
  auth: auth,
});

export default connect(mapStateToProps, null)(withTranslation()(SetupProfile));

import styled, { css } from "styled-components";
import {
  displayFlex,
  flexAlign,
  flexJustify,
  flexDirection,
} from "../../../ui/utils";

export const MenuItem = styled.div`
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  height: auto;
  text-align: left;
  line-height: 1em;
  color: #707070;
  font-size: 0.9em;
  line-height: 1.2;
  text-transform: none;
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-touch-callout: none;
  white-space: normal;
  word-wrap: normal;
  padding: ${(props) => (props.image ? "10px 10px 10px 45px" : "10px")};
  padding: 10px 25px 10px 45px;

  text-transform: capitalize;

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 10px;
    width: 15px;
    height: 15px;
    object-fit: contain;
    object-position: center;
  }

  &.selected,
  &:hover {
    background-color: var(--primary-color);
    color: #ffffff;

    svg text,
    svg path {
      fill: currentColor;
    }
  }

  span {
    text-transform: capitalize;
    display: inline-block;
  }
`;

export const DropdownMenuItemList = styled.div`
  padding: 0;
`;

export const DropdownMenu = styled.div`
  max-height: 608px;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: none;
  position: absolute;
  top: 100%;
  margin: 13px 0 15px;
  left: auto;
  right: 0;
  min-width: 100%;
  background-color: #ffffff;

  ${(props) =>
    props.open &&
    css`
      display: block;
    `}

  &::before {
    position: absolute;
    top: -9px;
    right: 9px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #ffffff;
    border-left: 9px solid transparent;
    content: "";
  }

  ${(props) =>
    props.align == "topRight" &&
    css`
      top: auto;
      bottom: 100%;

      &::before {
        transform: scale(-1);
        top: unset;
        bottom: -9px;
      }
    `}
`;

export const Text = styled.div`
  -webkit-transition: none;
  transition: none;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 1.2em;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  max-width: 1.3ch;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all;

  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  text-transform: capitalize;

  svg {
    margin: auto;
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
    position: absolute;
    left: -5px;
    top: -5px;
    bottom: -5px;
    right: -5px;

    text,
    path {
      fill: currentColor;
    }
  }
`;

export const Select = styled.div`
  cursor: pointer;
  position: relative;
  outline: 0;
  text-align: left;
  -webkit-transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: width 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  transition: box-shadow 0.1s ease, width 0.1s ease,
    -webkit-box-shadow 0.1s ease;
  -webkit-tap-highlight-color: transparent;
  word-wrap: break-word;
  line-height: 1em;
  white-space: normal;
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0);
  background: #fff;
  display: inline-block;
  color: currentColor;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  padding: 10px;
  vertical-align: middle;
  z-index: 1;
  width: 35px;
  height: 35px;
  border: 1px solid #f1f1f1;

  ${(props) =>
    props.open &&
    css`
      background-color: var(--primary-color);
      color: #ffffff;
      border: 1px solid var(--primary-color);

      svg text {
        fill: currentColor;
      }

      ${DropdownMenu} {
        -webkit-box-shadow: 0 5px 10px 0 rgba(34, 36, 38, 0.15);
        box-shadow: 0 5px 12px 8px rgba(34, 36, 38, 0.15);
        display: block !important;
        visibility: visible !important;
      }
    `}
`;

export const SelectWrapper = styled.div`
  clear: both;
  margin: 0 0 1em;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.open &&
    css`
      z-index: 9 !important;
    `}
`;

export const CardLoadingIcon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const CardVisualAction = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 10px;
  margin: auto auto 0;
  height: 35px;
  width: 35px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
`;

export const CardDataAction = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 35px;
  width: 35px;
  z-index: 6;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
`;

export const CardValueCaption = styled.text`
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  font-size: 12px;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  color: inherit;

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}
`;

export const CardValue = styled.text`
  font-size: 45px;
  font-weight: bold;
  font-family: "SegoeUI-Light", Arial, Helvetica, sans-serif;
  color: inherit;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  @media screen and (min-width: 992px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 35px;
  }

  @media screen and (min-width: 1400px) {
    font-size: 48px;
  }

  ${(props) =>
    props.textColor &&
    css`
      color: ${props.textColor};
    `}
`;

export const CardValueWrapper = styled.div`
  ${displayFlex()}
  ${flexDirection("column")}
  ${flexAlign("center")}
  ${flexJustify("center")}
  margin: 0 15px;
  color: currentColor;

  ${(props) =>
    props.status === "danger" &&
    css`
      color: #ff4040;
    `}

  ${(props) =>
    props.status === "dormont" &&
    css`
      color: #ffa037;
    `}

  ${(props) =>
    props.status === "success" &&
    css`
      color: #4bae4f;
    `}

  @media screen and (min-width: 992px) {
    margin: 0 10px;
  }

  @media screen and (min-width: 1200px) {
    margin: 0 10px;
  }

  @media screen and (min-width: 1400px) {
    margin: 0 15px;
  }
`;

export const CardFooter = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("center")}
  width: 100%;
`;

export const CardBody = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("center")}
  ${(props) => props.hideOverflow && "overflow: hidden;"}
  margin: 0 0 15px;
  width: 100%;
  flex: 1;

  ${(props) =>
    props.variations &&
    css`
      ${flexDirection("row")}
      ${flexAlign("flex-start")}
    ${flexJustify("space-around")}
    `}

  ${(props) =>
    props.charts &&
    css`
      ${flexDirection("row")}
      ${flexAlign("flex-start")}
    ${flexJustify("center")}
    `}
`;

export const CardHeading = styled.h3`
  color: currentColor;
  ${(props) => props.primaryColor && "color: blue;"}

  ${(props) =>
    props.headingColor &&
    css`
      color: ${props.headingColor};
    `}

  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  max-width: 85%;
  // -webkit-touch-callout: none;
  // -webkit-user-select: none;
  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;

  b {
    color: #656565;
  }

  @media screen and (min-width: 992px) {
    font-size: 14px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }

  @media screen and (min-width: 1400px) {
    font-size: 17px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 18px;
  }
`;

export const CardHeader = styled.div`
  position: relative;
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("center")}
  margin: 0 0 15px;
  width: 100%;
`;

export const CardWrapper = styled.div`
  ${displayFlex()}
  ${flexDirection("column")}
  ${flexAlign("flex-start")}
  ${flexJustify("flex-start")}
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
`;

export const Card = styled.div`
  position: relative;
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("center")}
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-bottom: 16px;
  height: calc(100% - 16px);
  padding: 20px 10px;
  color: #707070;
  background-color: #fff;
  transition: background 0.5s;
  transition: opacity 0.5s;
  min-height: 180px;

  ${(props) =>
    props.dim &&
    css`
      opacity: 0.5;
    `}

  ${(props) =>
    props.miniCard &&
    css`
      min-height: 155px;
      max-height: 155px;
    `}

  &:hover {
    ${CardVisualAction}, ${CardDataAction} {
      opacity: 1;
      visibility: visible;
    }
  }

  ${(props) =>
    props.headingColor &&
    css`
      color: ${props.headingColor};
    `}

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  @media screen and (min-width: 1400px) {
    ${(props) =>
      props.miniCard &&
      css`
        min-height: 160px;
        max-height: 160px;
      `}
  }
`;

export const ValueText = styled.text`
  transition: all 300ms;
  white-space: nowrap;
  &:hover {
    font-size: ${(props) => (props.size ? props.size : "inherit")};
  }
`;

import styled, { css } from "styled-components";
import { Modal, ModalBody, Button, Dropdown } from "react-bootstrap";
import { device } from "ui/theme/mediaQueries";
import { hexToRGBValues } from "components/Organization/helper";
import { rgba, lighten, cssVar, shade } from "polished";

export const FilterTag = styled.div`
  curson: pointer;
  width: 100%;
  padding: 5px;
  text-align: center;
  font-family: "SegoeUI", Arial, Helvetica, sans-serif;
  font-size: inherit;
  color: inherit;

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: var(--primary-color);
      color: #fff;
    `}

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? rgba(cssVar("--primary-color"), 0.7)
        : rgba(cssVar("--primary-color"), 0.1)};
    color: ${(props) => (props.selected ? "#fff" : "inherit")};
  }

  &:active {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const FiltersTagContainer = styled.div`
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  margin-top: auto;
`;

export const FilterTagTitle = styled.div`
  width: 100%;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-family: inherit;
  font-size: inherit;
  color: var(--primary-color);

  svg {
    margin-bottom: 10px;
  }
`;

export const FiltersTagWrapper = styled.div`
  text-align: center;
  min-width: 150px;
  font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: #707070;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100%;

  &:not(:last-child) {
    border-right: 1px solid #efefef;
  }
`;

export const FiltersTagTypes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 95vw;
  ${(props) => props.noScroll || "overflow: auto;"}
  padding-bottom: 1rem;
  border-bottom: 1px solid #efefef;

  &:only-child {
    margin: 0 auto;
  }

  @media ${device.xl} {
    max-width: 65vw;
  }
`;

export const ActionButton = styled(Button)`
  border-color: transparent;
  background: #ccc;
  color: #fff;
  border-radius: 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.apply {
    background-color: #89c120;
    border-color: #89c120;
  }
`;

export const ActionContainer = styled.div`
  margin-left: auto;
  padding: 0.35rem 0;
`;

export const FiltersModalFooter = styled.div`
  text-align: left;
  padding: 0.6em 0.9em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column wrap;
`;

export const FilterModalHeader = styled.div`
  padding: 1rem;
  width: 100%;
  text-align: center;
  color: var(--primary-color);
  font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-bottom: 1px solid #efefef;
`;

export const FiltersModalBody = styled(ModalBody)`
  padding: 1rem 0 0;
  display: flex;
`;

export const FiltersModal = styled(Modal)`
  .modal {
    &-dialog {
      max-width: unset;
    }
    &-content {
      border: none;
      border-radius: 0.25em;
    }
  }
`;

export const BootDropdownMenu = styled(Dropdown.Menu)`
  font-size: 13px;
  padding: 0;
  z-index: 9;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  margin: 5px 0 !important;
  width: max-content;

  right: auto !important;
  left: 50% !important;
  -webkit-transform: translate3d(-50%, 47px, 0) !important;
  -o-transform: translate3d(-50%, 47px, 0) !important;
  transform: translate3d(-50%, 47px, 0) !important;
`;

export const BootDropdownToogle = styled(Dropdown.Toggle)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
  }
`;

export const BootDropdown = styled(Dropdown)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &.show::after {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    z-index: 9;
    margin: auto;
  }
`;

export const Label = styled.label`
  display: inline-block;
  text-transform: none;
  padding: 0 1em 0 1em;

  text-align: center;
  color: var(--primary-color);
  font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
  font-size: 1em;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 33px;
  height: 15px;
  border-radius: 15px;
  background: #efefef;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 0;
    background: #adadad;
    transition: 0.2s;
    margin-top: -2px;
    bottom: 0;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 33px;
  height: 14px;

  &:checked + ${CheckBoxLabel} {
    background: rgba(137, 193, 32, 0.4);

    &::after {
      margin-left: 15px;
      background: #89c120;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
  justify-content: center;
  text-align: center;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 0.833em;
`;

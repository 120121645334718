import styled from "styled-components";
import { Button, Dropdown } from "react-bootstrap";

export const AddButton = styled(Button)`
  background: #5cb85c;
  border-radius: 0px;
  position: relative;
  margin-bottom: 5px;
`;

export const ActionDropdown = styled(Dropdown)``;

export const DropdownToggle = styled(Dropdown.Toggle)`
  background: #5cb85c;
  border-radius: 0px;
  padding: 6px 9px;
  border: 1px solid #5cb85c;
  margin-left: 10px;
`;

export const DropdownMenu = styled(Dropdown.Menu)``;

export const DropdownItem = styled(Dropdown.Item)``;

export const UserTagContainer = styled.div`
  display: flex;
`;

export const UserTag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3px 10px;
`;

export const Container = styled.div`
  padding: 6px 12px;
  border: 1px solid #5cb85c;
  border-radius: 3px;
  margin: 0px 20px 20px 20px;
`;

export const RemarkContainer = styled.div`
  border-bottom: 1px solid #5cb85c;
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
`;

export const RemarkSubContainer = styled.div`
  width: 100%;
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

export const RemarkDetail = styled.div`
  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  font-size: 15px;
`;

export const Date = styled.div`
  margin: 0px 10px;
  font-size: 12px;
`;

export const RemarkText = styled.div`
  b {
    font-weight: normal;
    background-color: #f5f5f5;
  }
`;

export const RemarkAction = styled.div`
  margin-left: auto;

  i {
    margin-right: 5px;
  }
`;

export const Loading = styled.div`
  border-bottom: 1px solid #5cb85c;
  margin-bottom: 10px;
  padding-bottom: 10px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ReadMoreContainer = styled.span`
  cursor: pointer;
`;

export const ReadMoreHint = styled.span`
  text-decoration: underline;
`;

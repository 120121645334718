import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  format,
  subWeeks,
  startOfDay,
  endOfDay,
  subDays,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfISOWeek,
  endOfISOWeek,
} from "date-fns";

import * as analytics from "utils/analytics";
import { DateFilterType, FilterNames } from "constants/constants";
import { setDateTimeFilter } from "components/DashboardPage/actions";
import { DateRange, DefaultFilterTab, StyledDate } from "./styled";
import {
  ModalFooter,
  ActionButton,
  ActionContainer,
} from "components/common/DateFilter/styled";

const DefaultFilter = ({
  startDate,
  endDate,
  defaultDateRange,
  setDateTimeFilter,
  hide,
  permissions,
}) => {
  const [selectedRange, setSelectedRange] = useState();
  const { t } = useTranslation();

  const applyFilter = () => {
    if (selectedRange) {
      setDateTimeFilter(
        ...selectedRange.dates,
        DateFilterType.DEFAULT,
        selectedRange.key,
      );

      analytics.logDefaultDateRangeFilter(selectedRange.key);
      hide(DateFilterType.DEFAULT);
    }
  };

  useEffect(() => {
    const selectedDateRange = DefaultDateRanges.find(
      (range) =>
        range.dates[0] === startDate &&
        range.dates[1] === endDate &&
        range.key === defaultDateRange,
    );
    setSelectedRange(selectedDateRange);
  }, [startDate, endDate]);

  return (
    <DefaultFilterTab>
      {DefaultDateRanges.map((range) => {
        const isSelected = range.key === selectedRange?.key;
        if (
          range.key == DEFAULT_RANGE_KEYS.ALL_TIME &&
          !permissions[FilterNames.ALL_TIME_DATE_FILTER_PERMISSION]
        )
          return;
        return (
          <DateRange
            key={range.key}
            isSelected={isSelected}
            onClick={() => !isSelected && setSelectedRange(range)}
          >
            {`${t(range.key)}`}
            <StyledDate isSelected={isSelected}>
              {`${dateFormat(range.dates)}`}{" "}
            </StyledDate>
          </DateRange>
        );
      })}
      <ModalFooter>
        <ActionContainer>
          <ActionButton onClick={hide} className="btn btn-primary">
            {t("cancel")}
          </ActionButton>
          <ActionButton onClick={applyFilter} className="btn btn-primary apply">
            {t("apply")}
          </ActionButton>
        </ActionContainer>
      </ModalFooter>
    </DefaultFilterTab>
  );
};

const mapDispatchToProps = {
  setDateTimeFilter,
};

const mapStateToProps = (state) => {
  return {
    endDate: state.selectedFilters.endDate,
    startDate: state.selectedFilters.startDate,
    defaultDateRange: state.selectedFilters.defaultDateRange,
    permissions: state.auth.user.permissions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultFilter);

const dateFormat = (dateRange) => {
  if (!dateRange.some((r) => r)) {
    return "";
  }
  const [startDate, endDate] = dateRange;

  return `${format(startDate, "MMM do")} - ${format(endDate, "MMM do")}`;
};

const getThisPeriod = (previousKey, key, subtractFunc, startFunc, endFunc) => {
  const dates = [
    startFunc(subtractFunc(new Date(), previousKey)).valueOf(),
    ![0, 7, 30].includes(previousKey)
      ? endFunc(subtractFunc(new Date(), previousKey)).valueOf()
      : endOfDay(new Date()).valueOf(),
  ];
  return dates;
};

export const getUpdatedDates = (key = DEFAULT_RANGE_KEYS.LAST_WEEK) => {
  return DEFAULT_DATE_RANGES[key]();
};

export const DEFAULT_RANGE_KEYS = {
  THIS_WEEK: "This Week",
  LAST_WEEK: "Last Week",
  LAST_7_DAYS: "Last 7 days",
  THIS_MONTH: "This Month",
  LAST_MONTH: "Last Month",
  LAST_30_DAYS: "Last 30 days",
  THIS_QUARTER: "This Quarter",
  LAST_QUARTER: "Last Quarter",
  ALL_TIME: "All Time",
};

const DEFAULT_DATE_RANGES = {
  [DEFAULT_RANGE_KEYS.THIS_WEEK]: () =>
    getThisPeriod(0, "weeks", subWeeks, startOfISOWeek, endOfISOWeek),
  [DEFAULT_RANGE_KEYS.LAST_WEEK]: () =>
    getThisPeriod(1, "weeks", subWeeks, startOfISOWeek, endOfISOWeek),
  [DEFAULT_RANGE_KEYS.LAST_7_DAYS]: () =>
    getThisPeriod(7, "days", subDays, startOfDay, endOfDay),
  [DEFAULT_RANGE_KEYS.THIS_MONTH]: () =>
    getThisPeriod(0, "months", subMonths, startOfMonth, endOfMonth),
  [DEFAULT_RANGE_KEYS.LAST_MONTH]: () =>
    getThisPeriod(1, "months", subMonths, startOfMonth, endOfMonth),
  [DEFAULT_RANGE_KEYS.LAST_30_DAYS]: () =>
    getThisPeriod(30, "days", subDays, startOfDay, endOfDay),
  [DEFAULT_RANGE_KEYS.THIS_QUARTER]: () =>
    getThisPeriod(0, "Q", subQuarters, startOfQuarter, endOfQuarter),
  [DEFAULT_RANGE_KEYS.LAST_QUARTER]: () =>
    getThisPeriod(1, "Q", subQuarters, startOfQuarter, endOfQuarter),
  [DEFAULT_RANGE_KEYS.ALL_TIME]: () => [null, null],
};

export const DefaultDateRanges = [
  {
    key: DEFAULT_RANGE_KEYS.THIS_WEEK,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.THIS_WEEK](),
  },
  {
    key: DEFAULT_RANGE_KEYS.LAST_WEEK,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.LAST_WEEK](),
  },
  {
    key: DEFAULT_RANGE_KEYS.LAST_7_DAYS,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.LAST_7_DAYS](),
  },
  {
    key: DEFAULT_RANGE_KEYS.THIS_MONTH,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.THIS_MONTH](),
  },
  {
    key: DEFAULT_RANGE_KEYS.LAST_MONTH,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.LAST_MONTH](),
  },
  {
    key: DEFAULT_RANGE_KEYS.LAST_30_DAYS,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.LAST_30_DAYS](),
  },
  {
    key: DEFAULT_RANGE_KEYS.THIS_QUARTER,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.THIS_QUARTER](),
  },
  {
    key: DEFAULT_RANGE_KEYS.LAST_QUARTER,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.LAST_QUARTER](),
  },
  {
    key: DEFAULT_RANGE_KEYS.ALL_TIME,
    dates: DEFAULT_DATE_RANGES[DEFAULT_RANGE_KEYS.ALL_TIME](),
  },
];

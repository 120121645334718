import {
  SUBMIT_FEEDBACK_GROUP_SUCCESS,
  FETCH_ORGANIZATION_FEATURES,
  SET_ANALYZER_FIELD,
  ADD_ANALYZER,
  SET_ANALYZER_FIELDS_VALUE,
  REMOVE_ANALYZER,
  FETCH_FEEDBACK_GROUP,
  RESET_FEEDBACK_GROUP_ANALYZER,
  FETCH_LEVELS,
  SUBMIT_LEVEL_SUCCESS,
  FETCH_LEVEL,
  UPDATE_ORGANIZATION,
  RESET_UPDATE_ORGANIZATION_SUCCESS,
  RESET_LEVEL,
  STORE_ORGANIZATION_DIVISIONS,
  STORE_ORGANIZATION_LEVELS,
  STORE_POSSIBLE_PARENT_DIVISIONS,
  SUBMIT_DIVISION,
  FETCH_DIVISION,
  RESET_DIVISION,
  STORE_ORGANIZATION_USERS,
  CHANGE_USERS_PAGE,
  STORE_USER_ROLES_ALERT,
  STORE_USER_ROLES,
  STORE_USER_ROLE_DETAILS,
  STORE_USER_DIVISIONS,
  SET_DIVISIONS_LOADING,
  STORE_FETCHED_USER,
  CLEAR_FETCHED_USER,
  SAVE_UPDATE_USER_SUCCESS,
  STORE_PRIMARY_DIVISIONS,
  RESET_ORGANIZATION_FEATURE_SUCCESS,
  SET_IS_ACTIVE_STATUS_SUCCESS,
} from "../constants/constants";
import * as constants from "../constants/constants";
import { formatAnalyzerName } from "./Organization/helper";
import { initialState } from "../initialState/initialState";

const organizationReducer = (state = initialState.organization, action) => {
  switch (action.type) {
    case constants.LOAD_ORGANIZATION_SUCCESS:
      return Object.assign({}, state, {
        verifyingOrganization: false,
        organization: action.organization,
        isOrganizationAPIError: false,
      });

    case constants.LOAD_ORGANIZATION_ERROR:
      return {
        ...state,
        verifyingOrganization: false,
        isOrganizationAPIError: true,
      };

    case constants.LOAD_ORGANIZATION:
      return Object.assign({}, state, {
        verifyingOrganization: true,
      });

    case FETCH_ORGANIZATION_FEATURES: {
      const { data } = action;

      return {
        ...state,
        organization: {
          ...state.organization,
          pages: data.pages,
          analyzers: data.analyzers,
          fields: data.fields,
          feedbackGroupAnalyzers: [{}],
          submitFeedbackGroupSuccess: false,
          submitLevelSuccess: false,
          submitOrganizationFeaturesSucess: true,
          feedbackLevels: data.feedback_levels,
          lastLevel: data.max_level,
        },
      };
    }

    case SET_ANALYZER_FIELD: {
      const { analyzer, fields, index } = action.payload;
      const _feedbackGroupAnalyzers = state.organization.feedbackGroupAnalyzers;

      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: _feedbackGroupAnalyzers.map(
            (analyzerData, i) => {
              return i === index
                ? { [analyzer]: { fields: fields } }
                : analyzerData;
            },
          ),
        },
      };
    }
    case ADD_ANALYZER: {
      const feedbackGroupAnalyzers = state.organization.feedbackGroupAnalyzers;

      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: feedbackGroupAnalyzers.concat({}),
        },
      };
    }

    case SET_ANALYZER_FIELDS_VALUE: {
      const { name, value, index, analyzer } = action.payload;
      const analyzers = state.organization.feedbackGroupAnalyzers;
      const analyzerName = formatAnalyzerName(analyzer);

      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: analyzers.map((analyzerData, i) => {
            return i === index
              ? {
                  ...analyzerData,
                  [analyzerName]: {
                    fields: {
                      ...analyzerData[analyzerName].fields,
                      [name]: value,
                    },
                  },
                }
              : analyzerData;
          }),
        },
      };
    }

    case REMOVE_ANALYZER: {
      const { index } = action.payload;
      const feedbackGroupAnalyzers = state.organization.feedbackGroupAnalyzers;

      let analyzers = feedbackGroupAnalyzers.slice();
      analyzers.splice(index, 1);

      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: analyzers,
        },
      };
    }
    case FETCH_FEEDBACK_GROUP: {
      const { data } = action;

      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: data["charts"],
          feedbackGroupData: data,
        },
      };
    }

    case SUBMIT_FEEDBACK_GROUP_SUCCESS: {
      const { feedbackGroupId, group } = action.data;
      const feedback_groups = state.organization.feedback_groups;

      return {
        ...state,
        organization: {
          ...state.organization,
          feedback_groups: feedbackGroupId
            ? feedback_groups.map((grp) => {
                return grp.id === group.id ? group : grp;
              })
            : [...state.organization.feedback_groups, group],
          submitFeedbackGroupSuccess: true,
        },
      };
    }

    case STORE_ORGANIZATION_DIVISIONS: {
      const { divisions } = action.payload;

      return {
        ...state,
        divisions: {
          ...state.divisions,
          divisions: divisions,
        },
      };
    }
    case constants.CHANGE_DIVISIONS_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        divisions: {
          ...state.divisions,
          currPage: page,
        },
      };
    }
    case STORE_ORGANIZATION_LEVELS: {
      const { levels } = action.payload;

      return {
        ...state,
        levels,
        submitDivisionSuccess: false,
      };
    }
    case STORE_POSSIBLE_PARENT_DIVISIONS: {
      const { possibleParentDivisions } = action.payload;

      return {
        ...state,
        possibleParentDivisions,
      };
    }

    case SET_IS_ACTIVE_STATUS_SUCCESS: {
      const userResults = [...state.users.results];
      const userIndex = userResults.findIndex(
        (user) => user.id == action.payload,
      );
      let user = userResults[userIndex];
      userResults[userIndex] = { ...user, is_active: !user.is_active };

      return {
        ...state,
        users: {
          ...state.users,
          results: userResults,
        },
      };
    }
    case SUBMIT_DIVISION:
      return {
        ...state,
        submitDivisionSuccess: true,
      };

    case FETCH_DIVISION:
      return {
        ...state,
        divisionData: action.payload.division,
      };

    case RESET_DIVISION:
      return {
        ...state,
        divisionData: {},
      };

    case RESET_FEEDBACK_GROUP_ANALYZER:
      return {
        ...state,
        organization: {
          ...state.organization,
          feedbackGroupAnalyzers: [{}],
          feedbackGroupData: {},
        },
      };

    case FETCH_LEVELS:
      return {
        ...state,
        organization: {
          ...state.organization,
          levels: action.data,
        },
      };

    case SUBMIT_LEVEL_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          submitLevelSuccess: true,
        },
      };

    case FETCH_LEVEL:
      return {
        ...state,
        organization: {
          ...state.organization,
          levelData: action.data,
        },
      };

    case UPDATE_ORGANIZATION: {
      const { logo, logo_landscape, description, colors } = action.data;

      return {
        ...state,
        organization: {
          ...state.organization,
          logo,
          logo_landscape,
          description,
          updateOrganizationSuccess: true,
          features: {
            ...state.organization.features,
            colors: {
              primary: colors.primary,
              secondary: colors.secondary,
            },
          },
        },
      };
    }

    case RESET_UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          updateOrganizationSuccess: false,
        },
      };

    case RESET_LEVEL:
      return {
        ...state,
        organization: {
          ...state.organization,
          levelData: {},
          submitLevelSuccess: false,
        },
      };
    case STORE_ORGANIZATION_USERS: {
      const { users } = action.payload;
      return {
        ...state,
        users: {
          ...users,
          currPage: state.users.currPage,
        },
        userSaveUpdateSuccessful: false,
      };
    }
    case CHANGE_USERS_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        users: {
          ...state.users,
          currPage: page,
        },
      };
    }
    case STORE_USER_ROLES_ALERT: {
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          alert: action.payload,
        },
      };
    }
    case STORE_USER_ROLES: {
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          list: action.payload,
        },
      };
    }
    case STORE_USER_ROLE_DETAILS: {
      return {
        ...state,
        userRoles: {
          ...state.userRoles,
          details: action.payload,
        },
      };
    }
    case SET_DIVISIONS_LOADING: {
      return {
        ...state,
        divisionsLoading: true,
      };
    }
    case STORE_USER_DIVISIONS: {
      const { divisions, appendMode } = action.payload;
      const divisionsData = appendMode
        ? [
            ...(state.userDivisions?.results || []),
            ...(divisions.results || []),
          ]
        : divisions.results || [];

      return {
        ...state,
        userDivisions: {
          ...divisions,
          results: divisionsData,
        },
        divisionsLoading: false,
      };
    }
    case STORE_FETCHED_USER: {
      const { user } = action.payload;

      return {
        ...state,
        fetchedUser: user,
      };
    }
    case RESET_ORGANIZATION_FEATURE_SUCCESS:
      return {
        ...state,
        organization: {
          ...state.organization,
          submitOrganizationFeaturesSucess: false,
        },
      };

    case CLEAR_FETCHED_USER: {
      return {
        ...state,
        fetchedUser: {},
      };
    }
    case SAVE_UPDATE_USER_SUCCESS: {
      return {
        ...state,
        userSaveUpdateSuccessful: true,
      };
    }
    case STORE_PRIMARY_DIVISIONS: {
      const { primaryDivisions } = action.payload;
      return {
        ...state,
        primaryDivisions,
      };
    }
    case constants.LOAD_CONCERNS: {
      return {
        ...state,
        concerns: {
          ...state.concerns,
          isPending: true,
        },
      };
    }
    case constants.LOAD_CONCERNS_SUCCESS: {
      return {
        ...state,
        concerns: {
          ...state.concerns,
          isPending: false,
          data: action.data,
        },
      };
    }
    case constants.LOAD_CONCERNS_ERROR: {
      return {
        ...state,
        concerns: {
          ...state.concerns,
          isPending: false,
          isError: true,
        },
      };
    }
    case constants.LOAD_ORGANIZATION_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case constants.CREATE_ORGANIZATION_TAG: {
      return {
        ...state,
        tags: [...state.tags, action.payload.tag],
      };
    }
    case constants.CREATE_ORGANIZATION_TAG_SUCCESS: {
      const { tag } = action.payload;
      return {
        ...state,
        tags: state.tags.map((t) => (!t.id && t.label === tag.label ? tag : t)),
      };
    }
    case constants.CREATE_ORGANIZATION_TAG_ERROR: {
      return {
        ...state,
        tags: state.tags.filter((t) => t.id),
      };
    }
    default:
      return state;
  }
};

export default organizationReducer;

import React, { Component } from "react";
import { connect } from "react-redux";
import { TooltipText } from "components/common/Tooltip";
import { TOOLTIP_MESSAGE } from "constants/constants";
import numeral from "numeral";
import { getSelectedConcern, isLoadingConcernDetail } from "./selectors";
import Skeleton from "react-loading-skeleton";

class ConcernTrendPercentage extends Component {
  render() {
    const { selectedConcern, isLoading } = this.props;

    return (
      <div className="line-header-complaints">
        <span className="title tooltipRel">
          PERCENTAGE
          <TooltipText tooltipText={TOOLTIP_MESSAGE.PERCENTAGE} />
        </span>
        <span
          title={numeral(selectedConcern?.count).format("0,0")}
          style={{ fontSize: 26, fontWeight: "bold" }}
        >
          {isLoading ? (
            <Skeleton width="60px" height="30px" />
          ) : (
            selectedConcern?.percentage ?? "--"
          )}
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: isLoadingConcernDetail(state),
    selectedConcern: getSelectedConcern(state),
  };
};

export default connect(mapStateToProps)(ConcernTrendPercentage);

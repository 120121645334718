import React from "react";
import * as utils from "./PresetFilterUtils";

const PresetLabel = ({ months, years, startingYear }) => {
  const sYears = utils.timeSpanToString(years, utils.GET_YEARS(startingYear));
  const sMonths = utils.timeSpanToString(months, utils.MONTHS);
  return (
    <>
      {sMonths || sYears ? (
        <>
          {sMonths && <span>{sMonths}</span>}
          {sYears && <span>{sYears}</span>}
        </>
      ) : (
        "-"
      )}
    </>
  );
};

export default PresetLabel;

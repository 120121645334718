import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonCloud = () => {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <Skeleton width={"30%"} height={"45px"} />
      <Skeleton width={"20%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <Skeleton width={"22%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <Skeleton width={"25%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <Skeleton width={"40%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <br />
      <Skeleton width={"15%"} height={"45px"} />
      <Skeleton width={"30%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <Skeleton width={"20%"} height={"45px"} style={{ marginLeft: "5px" }} />
      <br />
    </div>
  );
};

export default SkeletonCloud;

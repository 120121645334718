import React from "react";
import { useTranslation } from "react-i18next";
import { format, startOfDay, endOfDay } from "date-fns";

const CustomLabel = ({ startDate, endDate }) => {
  const { t } = useTranslation();
  if (!startDate && !endDate) return t("allTime");

  const [sStartDate, sEndDate] = customDatesToString(startDate, endDate);
  return (
    <>
      {sStartDate || sEndDate ? (
        <>
          {sStartDate === sEndDate ? (
            <span>{sStartDate}</span>
          ) : (
            <>
              {sStartDate && <span>{sStartDate}</span>}
              {sEndDate && <span>{sEndDate}</span>}
            </>
          )}
        </>
      ) : (
        "-"
      )}
    </>
  );
};

export default CustomLabel;

export const customDatesToString = (startDate, endDate) => {
  const mStartDate = new Date(startDate);
  const mEndDate = new Date(endDate);

  const isStartTimeSet = startDate !== startOfDay(mStartDate).valueOf();

  const isEndTimeSet = endDate !== endOfDay(mEndDate).valueOf();

  const sStartDate = format(
    mStartDate,
    isStartTimeSet || isEndTimeSet ? DATE_TIME_FORMAT : DATE_FORMAT,
  );
  const sEndDate = format(
    mEndDate,
    isEndTimeSet ? DATE_TIME_FORMAT : DATE_FORMAT,
  );

  return [sStartDate, sEndDate];
};

const DATE_FORMAT = "MMM do yyyy";
const DATE_TIME_FORMAT = "MMM do yyyy, h:mm a";

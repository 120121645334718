import styled, { css } from "styled-components";

export const Container = styled.div`
  opacity: 1;
  transition: opacity 0.25s;

  ${(props) =>
    props.dim &&
    css`
      opacity: 0;
    `}
`;

import React from "react";
import { InvoiceDetail } from "./InvoiceDetail";
import { isTruthy } from "../../../utils/genericHelper";
import CustomScroll from "react-custom-scroll";
import { NoDataFound } from "../../common/NoDataFound";
import { LoadMore } from "../../common/LoadMore";

export const InvoiceDetailList = ({
  invoices,
  searchValue,
  primaryColor,
  loadMoreInvoices,
  close,
  selectedFilters,
}) => {
  return (
    <CustomScroll heightRelativeToParent="350px">
      {isTruthy(invoices.apiData.results) ? (
        invoices.apiData.results.map((invoice) => (
          <InvoiceDetail
            invoice={invoice}
            searchValue={searchValue}
            close={close}
            selectedFilters={selectedFilters}
          />
        ))
      ) : (
        <NoDataFound />
      )}
      <div className="invoice-loadmore">
        <LoadMore
          isLoading={invoices.isMoreFeedbackPending}
          nextUrl={invoices.apiData.next}
          onClickHandler={loadMoreInvoices}
          primaryColor={primaryColor}
        />
      </div>
    </CustomScroll>
  );
};

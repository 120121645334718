import React, { Component } from "react";
import { connect } from "react-redux";

import { loadMoreFeedbacks, loadSelectedFeedback } from "../actions";
import FeedbackListItem from "./FeedbackListItem";
import CSSTransitionGroup from "react-transition-group/CSSTransition";
import { LoadMore } from "../../common/LoadMore";

class FeedbackList extends Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler(nextUrl) {
    const { loadMoreFeedbacks } = this.props;
    loadMoreFeedbacks(nextUrl);
  }

  render() {
    const { feedbacks, nextUrl, isLoading, loadSelectedFeedback } = this.props;
    const { organization, permissions, selectedFeedbacks } = this.props;

    const [selectedFeedback] = selectedFeedbacks;

    let colors = organization.features.colors;
    const primaryColor = colors && colors.primary;

    return (
      <CSSTransitionGroup
        classNames="animated"
        appear={true}
        timeout={{
          appear: 500,
          enter: 300,
          exit: 300,
        }}
        enter={false}
        exit={false}
      >
        <div>
          {feedbacks.map((feedback) => {
            return (
              <FeedbackListItem
                key={feedback.id}
                feedback={feedback}
                rackRoute={this.props.rackRoute}
                isSelected={feedback.id === selectedFeedback?.id}
                loadSelectedFeedback={loadSelectedFeedback}
                showActionStats={permissions.api_action_stats}
              />
            );
          })}
          <LoadMore
            nextUrl={nextUrl}
            onClickHandler={this.onClickHandler}
            isLoading={isLoading}
            primaryColor={primaryColor}
          />
        </div>
      </CSSTransitionGroup>
    );
  }
}

const mapStateToProps = (state) => ({
  feedbacks: state.feedback.feedbackList.data,
  selectedFeedbacks: state.feedback.selectedFeedback.data,
  nextUrl: state.feedback.feedbackList.nextUrl,
  isLoading: state.feedback.feedbackList.isLoading,
  selectedFilters: state.selectedFilters,
  organization: state.organization.organization,
  permissions: state.auth.user.permissions,
});

export default connect(mapStateToProps, {
  loadSelectedFeedback,
  loadMoreFeedbacks,
})(FeedbackList);

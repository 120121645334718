import produce from "immer";
import { isEmpty, keys, mapValues } from "lodash";
import * as constants from "../../constants/constants";
import { getTimeSeriesChartData } from "../../utils/chartsData";
import { initialState } from "./initialState";

/* eslint-disable default-case, no-param-reassign */
export const dashboardReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.STORE_ORG_DASHBOARD:
        draft.dashboards = [action.payload, ...draft.dashboards];
        break;

      case constants.STORE_DASHBOARDS:
        draft.dashboards = [...action.payload];
        break;

      case constants.SELECT_DASHBOARD:
        draft.selectedDashboard = action.payload.dashboard;
        break;

      case constants.SELECT_RACKS:
        draft.selectedRacks = action.payload;
        break;

      case constants.LOAD_ANALYZER_DATA: {
        const analyzerSlug = action.payload.slug;
        if (!action.autoFetch) draft.loading[analyzerSlug] = true;
        delete draft.error[analyzerSlug];
        break;
      }

      case constants.LOAD_ANALYZER_DATA_SUCCESS: {
        const { analyzerSlug, data } = action.payload;
        delete draft.loading[analyzerSlug];
        draft.data[analyzerSlug] = data;
        break;
      }

      case constants.LOAD_ANALYZER_DATA_ERROR: {
        const { analyzerSlug, error } = action.payload;
        draft.error[analyzerSlug] = error;
        delete draft.loading[analyzerSlug];
        break;
      }

      case constants.LOAD_MORE_ANALYZER_DATA: {
        const { analyzer } = action.payload;
        draft.loading[analyzer.slug] = "loadingMore";
        break;
      }

      case constants.LOAD_MORE_ANALYZER_DATA_SUCCESS: {
        const { analyzer, data } = action.payload;
        draft.data[analyzer.slug].nextURL = data.nextURL;
        if (analyzer.type === "table") {
          draft.data[analyzer.slug].data.push(...data.data);
        } else {
          draft.data[analyzer.slug].labels.push(...data.labels);
          analyzer.units.forEach((unit) => {
            data[unit].forEach((d, i) => {
              draft.data[analyzer.slug][unit][i].values.push(...d.values);
            });
          });
        }
        delete draft.loading[analyzer.slug];
        break;
      }

      case constants.LOAD_MORE_ANALYZER_DATA_ERROR: {
        const { analyzer } = action.payload;
        delete draft.loading[analyzer.slug];
        break;
      }

      case constants.SELECT_ANALYZER_UNIT: {
        const { analyzerSlug, unit } = action.payload;

        if (state.selectedUnits[analyzerSlug] === unit) break;

        const globalView = { ...state.globalView, unit: null };
        const selectedUnits = {
          ...state.selectedUnits,
          [analyzerSlug]: unit,
        };

        draft.globalView = globalView;
        draft.selectedUnits = selectedUnits;

        localStorage.setItem("globalView", JSON.stringify(globalView));
        localStorage.setItem("selectedUnits", JSON.stringify(selectedUnits));
        break;
      }

      case constants.SELECT_ANALYZER_GRAPH: {
        const { analyzerSlug, graph } = action.payload;

        if (state.selectedGraphs[analyzerSlug] === graph) break;

        const globalView = { ...state.globalView, graph: null };
        const selectedGraphs = {
          ...state.selectedGraphs,
          [analyzerSlug]: graph,
        };

        draft.globalView = globalView;
        draft.selectedGraphs = selectedGraphs;

        localStorage.setItem("globalView", JSON.stringify(globalView));
        localStorage.setItem("selectedGraphs", JSON.stringify(selectedGraphs));
        break;
      }

      case constants.SET_GLOBAL_VIEW: {
        const { globalView, currentRack } = action.payload;

        const [unit] = keys(globalView.unit);
        const [graph] = keys(globalView.graph);

        const selectedUnits = { ...state.selectedUnits };
        const selectedGraphs = { ...state.selectedGraphs };

        currentRack.analyzers.forEach((analyzer) => {
          if (analyzer.units.includes(unit))
            selectedUnits[analyzer.slug] = unit;
          else if (unit === "default")
            selectedUnits[analyzer.slug] = analyzer.units[0];

          if (analyzer.graphs.includes(graph))
            selectedGraphs[analyzer.slug] = graph;
          else if (graph === "default")
            selectedGraphs[analyzer.slug] = analyzer.graphs[0];
        });

        draft.globalView = globalView;
        draft.selectedUnits = selectedUnits;
        draft.selectedGraphs = selectedGraphs;

        localStorage.setItem("globalView", JSON.stringify(globalView));
        localStorage.setItem("selectedUnits", JSON.stringify(selectedUnits));
        localStorage.setItem("selectedGraphs", JSON.stringify(selectedGraphs));

        break;
      }

      case constants.STORE_FILTER_SET: {
        const { filterSet } = action.payload;
        draft.filterSets[filterSet.title] = filterSet;
        break;
      }

      case constants.LOAD_COMMENTS: {
        draft.comments.isLoading = true;
        break;
      }

      case constants.LOAD_COMMENTS_SUCCESS: {
        draft.comments.isLoading = false;
        draft.comments.isError = false;
        draft.comments.data = action.data;
        break;
      }

      case constants.LOAD_COMMENTS_ERROR: {
        draft.comments.isLoading = false;
        draft.comments.isError = true;
        break;
      }

      case constants.LOAD_MORE_COMMENTS: {
        draft.comments.isMoreLoading = true;
        break;
      }

      case constants.LOAD_MORE_COMMENTS_SUCCESS: {
        draft.comments.isMoreLoading = false;
        draft.comments.isError = false;
        draft.comments.data.results.push(...action.data.results);
        draft.comments.data.next = action.data.next;
        break;
      }

      case constants.LOAD_MORE_COMMENTS_ERROR: {
        draft.comments.isMoreLoading = false;
        draft.comments.isError = true;
        break;
      }

      case constants.LOAD_CONCERN_IMPACT_ON_SCORE:
        draft.concerns.loadingImpactOnScore = true;
        break;

      case constants.LOAD_CONCERN_IMPACT_ON_SCORE_SUCCESS:
        draft.concerns.loadingImpactOnScore = false;
        draft.concerns.impactOnScore = action.score;
        break;

      case constants.LOAD_INSIGHT_CONCERNS: {
        draft.concerns = { ...initialState.concerns };
        draft.concerns.loading = true;
        break;
      }

      case constants.LOAD_INSIGHT_CONCERNS_SUCCESS: {
        const { concerns } = action.payload;
        draft.concerns.list = concerns;
        draft.concerns.loading = false;
        break;
      }

      case constants.LOAD_INSIGHT_CHILD_CONCERNS_SUCCESS: {
        const { parentId, concerns } = action.payload;
        draft.concerns.loading = false;
        draft.concerns.error = false;
        draft.concerns.children[parentId] = concerns;
        draft.concerns.selectedChild = concerns.length ? concerns[0] : {};
        break;
      }

      case constants.LOAD_INSIGHT_CONCERNS_ERROR: {
        draft.concerns.error = true;
        draft.concerns.loading = false;
        break;
      }

      case constants.SELECT_INSIGHT_CONCERN: {
        draft.concerns.selectedParent = action.payload.concern;
        // draft.concerns.isLoading = true;
        break;
      }

      case constants.SELECT_INSIGHT_CHILD_CONCERN: {
        draft.concerns.selectedChild = action.payload.concern;
        break;
      }

      case constants.LOAD_INSIGHT_CONCERN_DETAIL: {
        draft.concerns.loadingDetail = true;
        draft.concerns.errorDetail = false;
        break;
      }

      case constants.LOAD_INSIGHT_CONCERN_DETAIL_SUCCESS: {
        const { concern, detail } = action.payload;
        draft.concerns.loadingDetail = false;
        draft.concerns.errorDetail = false;
        draft.concerns.details[concern.id] = detail;
        break;
      }

      case constants.LOAD_INSIGHT_CONCERN_DETAIL_ERROR: {
        const { concern, error } = action.payload;
        draft.concerns.loadingDetail = false;
        draft.concerns.errorDetail = true;
        draft.concerns.details[concern.id] = { error };
        break;
      }

      case constants.LOAD_SURVEY_QUESTIONS: {
        draft.surveyQuestions.loading = true;
        draft.surveyQuestions.error = false;
        break;
      }

      case constants.LOAD_SURVEY_QUESTIONS_SUCCESS: {
        const { questionnaireId, questions } = action.payload;
        draft.surveyQuestions.surveys[questionnaireId] = questions;
        draft.surveyQuestions.loading = false;
        break;
      }

      case constants.LOAD_SURVEY_QUESTIONS_ERROR: {
        draft.surveyQuestions.error = true;
        break;
      }

      case constants.SET_LIKES_FILTER: {
        if (action.payload.likes) {
          draft.concerns = initialState.concerns;
        } else {
          const key = action.payload.isParent
            ? "selectedParent"
            : "selectedChild";
          draft.concerns[key] = action.payload.resetConcern;
        }
        draft.insights.likes = action.payload.likes;
        break;
      }

      case constants.SET_FEEDBACK_LEVEL_FILTER: {
        draft.concerns = initialState.concerns;
        draft.insights.feedback_level = action.value;
        break;
      }

      case constants.LOAD_WORDCLOUD: {
        draft.wordCloud.isLoading = true;
        break;
      }

      case constants.LOAD_WORDCLOUD_SUCCESS: {
        draft.wordCloud.isLoading = false;
        draft.wordCloud.isError = false;
        draft.wordCloud.data = action.data;
        break;
      }

      case constants.LOAD_WORDCLOUD_ERROR: {
        draft.wordCloud.isLoading = false;
        draft.wordCloud.isError = true;
        break;
      }

      case constants.LOAD_PROJECTS_OVERVIEW: {
        draft.projectsOverview.isLoading = true;
        draft.projectsOverview.data = {
          data: [],
          headers: [],
        };
        break;
      }

      case constants.LOAD_PROJECTS_OVERVIEW_SUCCESS: {
        draft.projectsOverview.isLoading = false;
        draft.projectsOverview.isError = false;
        draft.projectsOverview.data = action.data;
        draft.projectsOverview.data.data = action.data.data;
        break;
      }

      case constants.LOAD_PROJECTS_OVERVIEW_ERROR: {
        draft.projectsOverview.isLoading = false;
        draft.projectsOverview.isError = true;
        break;
      }

      case constants.LOAD_MEDIA: {
        draft.media.isLoading = true;
        draft.media.data = [];
        break;
      }

      case constants.LOAD_MEDIA_SUCCESS: {
        draft.media.isLoading = false;
        draft.media.data = action.data;
        break;
      }

      case constants.LOAD_MEDIA_ERROR: {
        draft.media.isLoading = false;
        draft.media.isError = true;
        break;
      }

      case constants.CLEAR_MEDIA_ERROR: {
        draft.media.isError = false;
        break;
      }

      case constants.LOAD_TIME_SERIES:
        draft.timeseries.isLoading = true;
        break;

      case constants.LOAD_TIME_SERIES_SUCCESS:
        draft.timeseries.isLoading = false;
        draft.timeseries.apiData = mapValues(action.data.apiData, (data) =>
          getTimeSeriesChartData(data, action.data.userPermissions),
        );
        break;

      case constants.LOAD_TIME_SERIES_ERROR:
        draft.timeseries.isLoading = false;
        draft.timeseries.isError = true;
        break;

      case constants.LOAD_TOP_CONCERNS:
        draft.topConcerns.fetching = true;
        break;

      case constants.LOAD_TOP_CONCERNS_SUCCESS:
        draft.topConcerns.fetching = false;
        draft.topConcerns.analysis = action.data;
        break;

      case constants.LOAD_TOP_CONCERNS_ERROR:
        draft.topConcerns.fetching = false;
        draft.topConcerns.error = true;
        break;

      case constants.SET_TOP_CONCERNS_ANALYSIS:
        draft.topConcerns.analysisType = action.analysisType;
        draft.topConcerns.fetching = true;
        break;

      case constants.LOAD_SEMANTIC_ANALYSIS:
        draft.semanticAnalysis.fetching = true;
        break;

      case constants.LOAD_SEMANTIC_ANALYSIS_SUCCESS:
        draft.semanticAnalysis.fetching = false;
        draft.semanticAnalysis.analysis = action.data;
        break;

      case constants.LOAD_SEMANTIC_ANALYSIS_ERROR:
        draft.semanticAnalysis.fetching = false;
        draft.semanticAnalysis.error = true;
        break;

      case constants.LOAD_SATISFACTION_LEVEL:
        draft.satisfactionLevel.fetching = true;
        break;

      case constants.LOAD_SATISFACTION_LEVEL_SUCCESS:
        draft.satisfactionLevel.fetching = false;
        draft.satisfactionLevel.analysis = action.data;
        break;

      case constants.LOAD_SATISFACTION_LEVEL_ERROR:
        draft.satisfactionLevel.fetching = false;
        draft.satisfactionLevel.error = true;
        break;

      case constants.LOAD_PRIORITY_LIST:
        draft.priorityList.fetching = true;
        break;

      case constants.LOAD_PRIORITY_LIST_SUCCESS:
        draft.priorityList.fetching = false;
        draft.priorityList.analysis = action.data;
        break;

      case constants.LOAD_PRIORITY_LIST_ERROR:
        draft.priorityList.fetching = false;
        draft.priorityList.error = true;
        break;

      case constants.SET_PRIORITY_LIST_ANALYSIS:
        draft.priorityList.analysisType = action.analysisType;
        break;

      case constants.LOAD_NPS:
        draft.nps.fetching = true;
        break;

      case constants.LOAD_NPS_SUCCESS:
        draft.nps.fetching = false;
        draft.nps.data = action.data;
        break;

      case constants.LOAD_NPS_ERROR:
        draft.nps.fetching = false;
        draft.nps.isError = true;
        break;

      case constants.LOAD_GENDER_GROUP_ANALYSIS:
        draft.genderGroupAnalysis.fetching = true;
        break;

      case constants.LOAD_GENDER_GROUP_ANALYSIS_SUCCESS: {
        let isTruthyAnalysis = action.data.reduce(
          (isTruthyAnalysis, genderGroup) => {
            if (
              genderGroup.count.female_percentage ||
              genderGroup.count.male_percentage
            ) {
              isTruthyAnalysis = true;
            }
            return isTruthyAnalysis;
          },
          false,
        );
        draft.genderGroupAnalysis.fetching = false;
        draft.genderGroupAnalysis.analysis = action.data;
        draft.genderGroupAnalysis.isError = !isTruthyAnalysis;
        break;
      }

      case constants.LOAD_GENDER_GROUP_ANALYSIS_ERROR:
        draft.genderGroupAnalysis.fetching = false;
        draft.genderGroupAnalysis.isError = true;
        break;

      case constants.LOAD_DATA_HEALTH:
        draft.dataHealth.fetching = true;
        break;

      case constants.LOAD_DATA_HEALTH_SUCCESS:
        draft.dataHealth.fetching = false;
        draft.dataHealth.data = action.data;
        break;

      case constants.LOAD_DATA_HEALTH_ERROR:
        draft.dataHealth.fetching = false;
        draft.dataHealth.isError = true;
        break;

      case constants.LOAD_TRUE_SCORE:
        draft.trueScore.fetching = true;
        break;

      case constants.LOAD_TRUE_SCORE_SUCCESS:
        draft.trueScore.fetching = false;
        draft.trueScore.data = action.data;
        break;

      case constants.LOAD_TRUE_SCORE_ERROR:
        draft.trueScore.fetching = false;
        draft.trueScore.isError = true;
        break;

      case constants.LOAD_CUSTOMER_SEGMENTATION:
        draft.customerSegmentation.fetching = true;
        break;

      case constants.LOAD_CUSTOMER_SEGMENTATION_SUCCESS: {
        let isTruthyCustomerSegmentation = action.data.counts.reduce(
          (isTruthyAnalysis, count) => {
            if (count) {
              isTruthyAnalysis = true;
            }
            return isTruthyAnalysis;
          },
          false,
        );
        draft.customerSegmentation.fetching = false;
        draft.customerSegmentation.data = action.data;
        draft.customerSegmentation.isError = !isTruthyCustomerSegmentation;
        break;
      }

      case constants.LOAD_CUSTOMER_SEGMENTATION_ERROR:
        draft.customerSegmentation.fetching = false;
        draft.customerSegmentation.isError = true;
        break;

      case constants.LOAD_COMPETITOR_ANALYSIS:
        draft.competitorAnalysis.fetching = true;
        break;

      case constants.LOAD_COMPETITOR_ANALYSIS_SUCCESS:
        draft.competitorAnalysis.fetching = false;
        draft.competitorAnalysis.data = action.data;
        draft.competitorAnalysis.isError = isEmpty(action.data);
        break;

      case constants.LOAD_COMPETITOR_ANALYSIS_ERROR:
        draft.competitorAnalysis.fetching = false;
        draft.competitorAnalysis.isError = true;
        break;

      case constants.LOAD_BENCHMARKS:
        draft.benchmarks.fetching = true;
        break;

      case constants.LOAD_BENCHMARKS_SUCCESS:
        draft.benchmarks.fetching = false;
        draft.benchmarks.data = action.data;
        break;

      case constants.LOAD_BENCHMARKS_ERROR:
        draft.benchmarks.fetching = false;
        draft.benchmarks.isError = true;
        break;

      case constants.LOAD_SEGMENT_ANALYSIS:
        draft.segmentAnalysis.fetching = true;
        break;

      case constants.LOAD_SEGMENT_ANALYSIS_SUCCESS:
        draft.segmentAnalysis.fetching = false;
        draft.segmentAnalysis.segmentAnalysis = action.data;
        break;

      case constants.LOAD_SEGMENT_ANALYSIS_FAILURE:
        draft.segmentAnalysis.fetching = false;
        draft.segmentAnalysis.isError = true;
        break;

      case constants.CLEAR_SEGMENT_ANALYSIS:
        draft.segmentAnalysis.segmentAnalysis = {};
        break;

      case constants.LOAD_INVITE_STATS:
        draft.inviteStats.fetching = true;
        break;

      case constants.LOAD_INVITE_STATS_SUCCESS:
        draft.inviteStats.fetching = false;
        draft.inviteStats.data = action.data;
        break;

      case constants.LOAD_INVITE_STATS_FAILURE:
        draft.inviteStats.fetching = false;
        draft.inviteStats.isError = true;
        break;
    }
  });
};

import styled, { css } from "styled-components";

export const Td = styled.td`
  padding: 14px 20px;
  min-width: calc(100vw / 9.5);
  cursor: pointer;
  border-bottom: 1px solid #b4b5b5;
  width: 100% !important;

  &:not(:first-child),
  &:not(:nth-child(2)) {
    text-align: center;
  }

  ${(props) =>
    props.fixed &&
    css`
      position: sticky;
      top: auto;
      left: 0;
      z-index: 1;
      background-color: #efefef;
      color: #707070;
      font-weight: bold;
    `}

  ${(props) =>
    props.success &&
    css`
      color: #89c120;
    `}

    ${(props) =>
    props.error &&
    css`
      color: #e61f1f;
    `}

    ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const Tr = styled.tr`
  min-width: 100%;

  &:last-child ${Td} {
    border-bottom: none;
  }

  &:hover ${Td} {
    background-color: var(--secondary-color-light);
  }
`;

export const Th = styled.th`
  padding: 18px 20px;
  font-weight: bold;
  color: #707070;
  min-width: calc(100vw / 9.5);
  background-color: #efefef;
  width: 100% !important;

  &:not(:first-child),
  &:not(:nth-child(2)) {
    text-align: center;
  }

  ${(props) =>
    props.fixed &&
    css`
      position: sticky;
      top: auto;
      left: 0;
      z-index: 3;
    `}
`;

export const Tbody = styled.tbody`
  display: inline-block;
  min-width: 100%;
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 3;
  display: inline-block;
  min-width: 100%;
  margin-bottom: 3px;
`;

export const Table = styled.table`
  min-width: 100%;
  color: #4d4f5c;
  background-color: #ffffff;
  max-height: 375px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  max-width: 100%;
  display: block;
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

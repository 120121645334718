import Rack from "components/Rack/Rack";
import Login from "components/LoginPage/Login";
import SetupProfile from "components/Profile/SetupProfile";
import ForgotPassword from "components/ForgotPasswordPage/ForgotPassword";
import Profile from "components/Profile/Profile";
import ResetPassword from "components/Profile/ResetPassword/ResetPassword";
import { lazy } from "react";
import { LazyLoading } from "components/common/lazyLoading";

const ManageUsers = lazy(() =>
  import("components/ManageUsersPage/ManageUsers"),
);
const SurveyBuilderView = lazy(() =>
  import("components/SurveyBuilder/SurveyBuilderView"),
);
const Feedback = lazy(() =>
  import("components/FeedbackPage/Feedback/FeedbackPage"),
);
const SurveyList = lazy(() =>
  import("components/SurveyBuilder/list/SurveyListPage"),
);
const CustomerInvites = lazy(() =>
  import("components/CustomerInvitesPage/CustomerInvites"),
);
const FeedbackGroupList = lazy(() =>
  import("components/Organization/FeedbackGroups/FeedbackGroupList"),
);
const AddFeedbackGroup = lazy(() =>
  import("components/Organization/FeedbackGroups/AddFeedbackGroup"),
);
const DivisionList = lazy(() =>
  import("components/Organization/DivisionManager/DivisionList"),
);
const AddDivision = lazy(() =>
  import("components/Organization/DivisionManager/AddDivision"),
);
const EditFeedbackGroup = lazy(() =>
  import("components/Organization/FeedbackGroups/EditFeedbackGroup"),
);
const LevelList = lazy(() =>
  import("components/Organization/Levels/LevelList"),
);
const AddLevel = lazy(() => import("components/Organization/Levels/AddLevel"));
const EditLevel = lazy(() =>
  import("components/Organization/Levels/EditLevel"),
);
const EditOrganization = lazy(() =>
  import("components/Organization/EditOrganization"),
);
const EditDivision = lazy(() =>
  import("components/Organization/DivisionManager/EditDivision"),
);
const UserList = lazy(() =>
  import("components/Organization/UserManager/UserList"),
);
const AddUser = lazy(() =>
  import("components/Organization/UserManager/AddUser"),
);
const EditUser = lazy(() =>
  import("components/Organization/UserManager/EditUser"),
);
const UserRoles = lazy(() => import("components/Organization/UserRoles"));
const RolesForm = lazy(() =>
  import("components/Organization/UserRoles/RolesForm"),
);
const QuestionnairePercentage = lazy(() =>
  import("components/QuestionairePercentage/QuestionnairePercentage"),
);

const routes = [
  {
    path: "/login",
    component: Login,
    tracker: true,
    auth: true,
    private: false,
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    tracker: true,
    auth: true,
    private: false,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    tracker: true,
    auth: true,
    private: false,
  },
  {
    path: "/setup-profile/:uuid",
    component: SetupProfile,
    tracker: true,
    auth: false,
    private: false,
  },
  {
    path: "/customer-feedback/:id",
    component: LazyLoading(Feedback),
    tracker: true,
    auth: true,
    private: false,
  },
  {
    path: "/share-feedback/:id",
    component: LazyLoading(Feedback),
    tracker: true,
    auth: true,
    private: true,
  },
  {
    path: "/customers",
    component: LazyLoading(CustomerInvites),
    tracker: true,
    auth: true,
    private: true,
  },
  {
    path: "/feedback-groups",
    component: LazyLoading(FeedbackGroupList),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/divisions",
    component: LazyLoading(DivisionList),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/add-division",
    component: LazyLoading(AddDivision),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/divisions/:id/edit",
    component: LazyLoading(EditDivision),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/add-feedback-group",
    component: LazyLoading(AddFeedbackGroup),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/feedback-groups/:id/edit",
    component: LazyLoading(EditFeedbackGroup),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/levels",
    component: LazyLoading(LevelList),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/add-level",
    component: LazyLoading(AddLevel),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/levels/:id/edit",
    component: LazyLoading(EditLevel),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/users",
    component: LazyLoading(UserList),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/users/add",
    component: LazyLoading(AddUser),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/users/:id/edit",
    component: LazyLoading(EditUser),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/user-roles",
    component: LazyLoading(UserRoles),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/user-roles/add",
    component: LazyLoading(RolesForm),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/user-roles/:id/edit",
    component: LazyLoading(RolesForm),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/organization/edit",
    component: LazyLoading(EditOrganization),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/questionnaire-percentage",
    component: LazyLoading(QuestionnairePercentage),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/profile",
    component: Profile,
    tracker: true,
    auth: true,
    private: true,
  },
  {
    path: "/survey-builder",
    component: LazyLoading(SurveyBuilderView),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/survey-builder/:survey_id",
    component: LazyLoading(SurveyBuilderView),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/survey-list",
    component: LazyLoading(SurveyList),
    tracker: false,
    auth: true,
    private: true,
  },
  {
    path: "/manage-users",
    component: LazyLoading(ManageUsers),
    tracker: true,
    auth: true,
    private: true,
  },
  {
    path: "*",
    component: Rack,
    tracker: false,
    auth: true,
    private: true,
    withRoute: true,
  },
];

export const indexesRoutes = routes
  .filter((r) => r.path.length > 1)
  .reduce((_routes, _route) => {
    const path = _route.path.split("/").filter((p) => p.length)[0];
    _routes[path] = true;
    return _routes;
  }, {});
export default routes;

import { call, put, select, takeLatest } from "redux-saga/effects";
import * as constants from "./constants";
import * as actions from "./actions";
import * as apis from "./apis";
import { getOrganizationId } from "../Organization/selectors";

function* loadCustomerUsers() {
  try {
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.getCustomerUsers, organizationId);
    yield put(actions.loadCustomerUserSuccess(apiData));
  } catch (error) {
    yield put(actions.loadCustomerUserError(error));
  }
}

function* customerUsersWatcher() {
  yield takeLatest(constants.LOAD_CUSTOMER_USERS, loadCustomerUsers);
}

export const sagas = [customerUsersWatcher];

import React from "react";
import ReactTable from "./ReactTable";

const Table = (props) => {
  const { data, unit, analyzer } = props;
  const deps = [data, unit];
  const rows = React.useMemo(() => parseRows(data, unit, analyzer), deps);
  const columns = React.useMemo(() => parseColumns(data, unit, analyzer), deps);

  return (
    <ReactTable
      unit={unit}
      data={rows}
      columns={columns}
      analyzer={analyzer}
      nextURL={data.nextURL}
    />
  );
};

export default Table;

const parseColumns = (data, unit, analyzer) => {
  const unitData = data[unit];
  const name = analyzer.pivot_column || "-";
  const cols = [{ Header: name, accessor: name, sticky: "left" }];
  unitData.forEach((d) => cols.push({ Header: d.label, accessor: d.label }));
  return cols;
};

const parseRows = (data, unit, analyzer) => {
  const unitData = data[unit];
  const name = analyzer.pivot_column || "-";
  return data.labels.map((label, i) => {
    const d = { [name]: label };
    unitData.forEach((c) => {
      let value = c.values[i] ?? "--";
      if (typeof value === "number") {
        value = parseFloat(value);
      }
      d[c.label] = value;
    });
    return d;
  });
};

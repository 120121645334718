import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const LoadMore = ({
  nextUrl,
  isLoading,
  onClickHandler,
  primaryColor,
  mode,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        mode === "vertical"
          ? "load-more vertical text-center"
          : "load-more text-center"
      }
    >
      {nextUrl ? (
        <Button
          variant="info"
          size="sm"
          style={{
            background: primaryColor,
            borderColor: primaryColor,
            marginBottom: "5px",
          }}
          disabled={isLoading}
          onClick={() => onClickHandler(nextUrl)}
        >
          {isLoading ? <i className="fa fa-spinner fa-pulse fa-fw" /> : null}
          {isLoading ? t("Loading") + "..." : t("Load More")}
        </Button>
      ) : null}
    </div>
  );
};

import { createSelector } from "reselect";
import { isEmpty } from "lodash";

import {
  setPresetFilter,
  resetShiftFilter,
  setDateTimeFilter,
  resetGenericFilters,
} from "components/DashboardPage/actions";
import { DateFilterType } from "constants/constants";
import { getDateRange } from "utils/filtersHelperFuncs";

export const getIsOmniView = createSelector(
  (state) => state.dashboard,
  (dashboard) => dashboard.selectedDashboard,
  (selectedDashboard) => selectedDashboard.id === "omni",
);

const isNotDefaultDate = (startDate, endDate, range) => {
  const dates = getDateRange(range);
  return !(dates[0] === startDate && dates[1] === endDate);
};

export const getFilterStatus = createSelector(
  (state) => [
    state.selectedFilters,
    state.organization.organization.features.dashboard?.date_range,
  ],
  ([selectedFilters, range]) => [
    {
      name: "dateFilter",
      active:
        (selectedFilters.startDate &&
          isNotDefaultDate(
            selectedFilters.startDate,
            selectedFilters.endDate,
            range,
          )) ||
        !isEmpty(selectedFilters.months) ||
        !isEmpty(selectedFilters.years),
      dispatch: [
        setPresetFilter({}, {}),
        setDateTimeFilter(...getDateRange(range), DateFilterType.CUSTOM),
      ],
    },
    {
      name: "LocationFilter",
      active: !isEmpty(selectedFilters.locations),
      dispatch: [resetGenericFilters(["locations"])],
    },
    {
      name: "questionFilter",
      active:
        !isEmpty(selectedFilters.age) ||
        !isEmpty(selectedFilters.gender) ||
        !isEmpty(selectedFilters.nps) ||
        !isEmpty(selectedFilters.options),
      dispatch: [resetGenericFilters(["age", "gender", "nps", "options"])],
    },
    {
      name: "System Filter",
      active:
        !isEmpty(selectedFilters.segments) ||
        !isEmpty(selectedFilters.loop) ||
        !isEmpty(selectedFilters.dataHealth),
      dispatch: [resetGenericFilters(["segments", "dataHealth", "loop"])],
    },
    {
      name: "shiftFilter",
      active:
        !isEmpty(selectedFilters.days) ||
        selectedFilters.startTime ||
        selectedFilters.endTime,
      dispatch: [resetShiftFilter()],
    },
  ],
);

export const getDefaultDates = createSelector(
  (state) => state.organization.organization.features.dashboard?.date_range,
  (dateRange) => getDateRange(dateRange),
);

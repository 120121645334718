import React, { useEffect, useState } from "react";
import moment from "moment";

import { Form, Button, Dropdown, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import * as analytics from "utils/analytics";
import { loadChatMessages, postChatMessage } from "./actions";
import { CUSTOMER_MESSAGE_TYPES } from "./constants";
import { isCustomerView } from "./selectors";

export const ChatBox = ({ feedback, auth }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [communicationMode, setCommunicationMode] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(loadChatMessages(feedback.id));
  }, [feedback.id]);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      postChatMessage({
        feedback: feedback.id,
        message,
        communication_mode: communicationMode,
      }),
    );
    analytics.logMessageSentToCustomer(message, communicationMode);
    setMessage("");
  };

  return (
    <div className="chat-box">
      {feedback.chatMessages?.length ? (
        <div className="chat-list-container">
          {feedback.chatMessages.map((reply) => (
            <div
              className={`message tri ${reply.is_respondent_message && "user"}`}
            >
              {reply.is_respondent_message && <p>{reply.sender.full_name}:</p>}
              <span>{reply.message}</span>
              <span className="timestamp">
                {moment(reply.created_at).format("llll")}
                {reply && (
                  <i
                    className={`fa ${
                      reply.communication_mode === 1
                        ? "fa-long-arrow-up"
                        : "fa-arrows-v"
                    }`}
                  />
                )}
              </span>
            </div>
          ))}
        </div>
      ) : null}
      {!isCustomerView(auth) && (
        <div className="text-area-contianer">
          <Form onSubmit={onSubmit}>
            <textarea
              className="form-control"
              placeholder={t("inquireCustomer")}
              rows="3"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="button-container">
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle variant="info" id="dropdown-basic">
                  {communicationMode && (
                    <i
                      className={`fa ${
                        communicationMode === 1
                          ? "fa-long-arrow-up"
                          : "fa-arrows-v"
                      }`}
                    />
                  )}
                  {communicationMode
                    ? t(
                        CUSTOMER_MESSAGE_TYPES.find(
                          (m) => m.value === communicationMode,
                        ).key,
                      )
                    : t("SMS Type")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {CUSTOMER_MESSAGE_TYPES.map((type) => {
                    return (
                      <Dropdown.Item
                        eventKey={type.value}
                        key={type.value}
                        onClick={() => {
                          setCommunicationMode(type.value);
                          analytics.logCommunicationModeChange(type.value);
                        }}
                      >
                        <i
                          className={`fa ${
                            type.value === 1
                              ? "fa-long-arrow-up"
                              : "fa-arrows-v"
                          }`}
                        />
                        {t(type.key)}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
                <Button
                  variant="info"
                  type="submit"
                  disabled={!message || !communicationMode}
                >
                  {t("sendToCustomer")}
                </Button>
              </Dropdown>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

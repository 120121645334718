import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { isEqual } from "lodash";
import { startOfDay, endOfDay } from "date-fns";

import * as analytics from "utils/analytics";
import TimePicker from "components/common/DateFilter/TimePicker/TimePicker";
import DateRangePicker from "components/common/DateFilter/DateRangePicker/DateRangePicker";
import * as utils from "components/common/DateFilter/TimePicker/TimePickerUtils";
import { setDateTimeFilter } from "components/DashboardPage/actions";
import { DateFilterType } from "constants/constants";

import {
  CheckBoxContainer,
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
  Label,
  ModalFooter,
  DatePickerWrapper,
  DatePickerLabel,
  TimePickerContainer,
  ActionContainer,
  ActionButton,
} from "../styled";

import { customDatesToString } from "./CustomLabel";

class CustomFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  componentDidUpdate = (prevProps) => {
    if (!isEqual(prevProps, this.props)) {
      const { startDate, startTime, endDate, endTime, activities } =
        this.getStateFromProps(this.props);
      this.setState({ startDate, startTime, endDate, endTime, activities });
    }
  };

  getStateFromProps = (props) => {
    const [startTime, startDate] = utils.getTimeOptionSinceDayStart(
      props.startDate || startOfDay(new Date()).valueOf(),
    );
    const [endTime, endDate] = utils.getTimeOptionSinceDayStart(
      props.endDate || endOfDay(new Date()).valueOf(),
    );
    const isEndTimeSet =
      props.endDate && props.endDate !== endOfDay(new Date(endDate)).valueOf();

    const selectedQuestionnaireIds = Object.keys(
      this.props.selectedQuestionnaires,
    ).map((q) => parseInt(q));

    const activities = this.props.activities.filter((activity) =>
      selectedQuestionnaireIds.includes(activity.questionnaire),
    );

    return {
      startDate,
      startTime,
      endDate,
      endTime,
      useTime: isEndTimeSet,
      activities,
    };
  };

  applyFilter = () => {
    const { startDate, startTime, endDate, endTime, useTime } = this.state;
    let start = startDate;
    let end = endDate;

    if (useTime) {
      start += utils.timeOptionToMilliseconds(startTime);
      end += utils.timeOptionToMilliseconds(endTime);
    } else {
      end += utils.MILLISECONDS_IN_A_DAY;
    }

    const dateLabel = customDatesToString(start, end).join(" - ");
    analytics.logCustomDateRangeFilter(dateLabel);

    this.props.setDateTimeFilter(start, end, DateFilterType.CUSTOM);
    this.props.hide(DateFilterType.CUSTOM);
  };

  render = () => {
    const { startDate, startTime, endDate, endTime, useTime } = this.state;
    const isSameDay = startDate === endDate;
    const { t } = this.props;

    return (
      <div>
        <div
          className="dateRangePicker"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            minDate={this.props.organizationCreatedAt}
            ranges={this.state.activities}
            onChange={(range) => {
              const _endTime = isSameDay
                ? utils.getValidEndTime(startTime, endTime)
                : endTime;
              this.setState({
                startDate: range.startDate.getTime(),
                endDate: range.endDate.getTime(),
                endTime: _endTime,
              });
            }}
          />
        </div>
        <CheckBoxContainer>
          <Label onClick={() => this.setState({ useTime: !useTime })}>
            {useTime ? t("turnOffShiftTime") : t("turnOnShiftTime")}
          </Label>
          <CheckBoxWrapper>
            <CheckBox
              id="checkbox"
              type="checkbox"
              checked={useTime}
              onClick={() => this.setState({ useTime: !useTime })}
            />
            <CheckBoxLabel htmlFor="checkbox" />
          </CheckBoxWrapper>
        </CheckBoxContainer>
        <ModalFooter>
          {useTime && (
            <TimePickerContainer>
              <DatePickerWrapper>
                <DatePickerLabel>{t("startTime")}</DatePickerLabel>
                <TimePicker
                  disabled={!useTime}
                  selectedTime={startTime}
                  onClick={(_startTime) => {
                    const _endTime =
                      startDate === endDate
                        ? utils.getValidEndTime(_startTime, endTime)
                        : endTime;
                    this.setState({ startTime: _startTime, endTime: _endTime });
                  }}
                />
              </DatePickerWrapper>
              <DatePickerWrapper>
                <DatePickerLabel>{t("endTime")}</DatePickerLabel>
                <TimePicker
                  disabled={!useTime}
                  selectedTime={endTime}
                  startTime={isSameDay && startTime}
                  onClick={(option) => {
                    this.setState({ endTime: option });
                  }}
                />
              </DatePickerWrapper>
            </TimePickerContainer>
          )}
          <ActionContainer>
            <ActionButton
              onClick={() => this.props.hide()}
              className="btn btn-primary"
            >
              {t("cancel")}
            </ActionButton>
            <ActionButton
              onClick={this.applyFilter}
              className="btn btn-primary apply"
            >
              {t("apply")}
            </ActionButton>
          </ActionContainer>
        </ModalFooter>
      </div>
    );
  };
}

const mapDispatchToProps = {
  setDateTimeFilter,
};

const mapStateToProps = (state) => {
  return {
    endDate: state.selectedFilters.endDate,
    startDate: state.selectedFilters.startDate,
    selectedQuestionnaires: state.selectedFilters.questionnaires,
    activities: state.organization.organization.activities,
    organizationCreatedAt: state.organization.organization.created_at,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CustomFilter));

import { createSelector } from "reselect";
import { initialState } from "../../initialState/initialState";

const selectAuth = (state) => state.auth || initialState.auth;
const selectUser = (state) => state.auth.user || initialState.auth.user;
export const selectUserId = (state) => state.auth.user.id;
const selectRole = (state) => state.auth.user.role;

const getPermissions = createSelector(
  selectRole,
  (roleState) => roleState.permissions,
);

export { selectRole, selectAuth, getPermissions };

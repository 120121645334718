import styled, { css } from "styled-components";

export const ToolTipTextWrapper = styled.span`
  visibility: hidden;
  width: max-content;
  min-width: auto;
  max-width: 250px;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 7px 12px 13px;
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 5px);
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.4s;
  box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  z-index: 999;

  &::before {
    content: "${(props) => props.tooltipText && props.tooltipText}";
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    font-family: "SegoeUI", Arial, Helvetica, sans-serif;
    white-space: pre-wrap;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--primary-color) transparent transparent transparent;
  }

  ${(props) =>
    props.align === "topRight" &&
    css`
      left: auto;
      right: 0;

      &::after {
        left: auto;
        right: 10px;
        margin-left: auto;
        margin-right: -5px;
      }
    `}

  ${(props) =>
    props.align === "bottomLeft" &&
    css`
      bottom: unset;
      top: calc(100% + 5px);

      &::after {
        top: unset;
        bottom: 100%;
        transform: scale(-1);
      }
    `}

  ${(props) =>
    props.align === "bottomRight" &&
    css`
      bottom: unset;
      top: calc(100% + 5px);
      left: auto;
      right: 0;

      &::after {
        top: unset;
        bottom: 100%;
        transform: scale(-1);
        left: auto;
        right: 10px;
        margin-left: auto;
        margin-right: -5px;
      }
    `}

  ${(props) =>
    props.align === "bottomCenter" &&
    css`
      bottom: unset;
      top: calc(100% + 5px);
      margin: 0 auto;
      left: 0;
      right: 0;

      &::after {
        top: unset;
        bottom: 100%;
        transform: scale(-1);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 10px;
      }
    `}
`;

import React from "react";
import { useDispatch, connect } from "react-redux";

import { setRCAOptionsFieldValue } from "components/RCA/actions";

import { formRCAOptionsFieldSelector } from "components/RCA/selectors";

import { FloatingLabel } from "components/common/FloatingLabel";

const TextField = ({ field, content }) => {
  const dispatch = useDispatch();

  const onChange = (content) =>
    dispatch(setRCAOptionsFieldValue(field.id, content));

  return (
    <FloatingLabel
      key={field.slug}
      label={field.name}
      value={content}
      onChange={onChange}
      placeholder={field.placeholder}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const content = formRCAOptionsFieldSelector(state, ownProps.field.id) || "";
  return { content };
};

export default connect(mapStateToProps)(TextField);

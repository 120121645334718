import React from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";

import {
  formFieldValueSelector,
  concernAnalysisTimeSeriesSelector,
  concernIdSelector,
} from "components/RCA/selectors";

import { parse, format } from "date-fns";
import { keys } from "lodash";

import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";

import {
  Card,
  CardWrapper,
  CardHeader,
  CardBody,
  CardHeading,
} from "components/common/Card";
import SkeletonGraph from "components/common/Skeleton/SkeletonGraph";

const ImpactGraph = () => {
  const timeSeries = useSelector(concernAnalysisTimeSeriesSelector);

  const concernId = useSelector(concernIdSelector);

  let deploymentStartDate = useSelector((state) =>
    formFieldValueSelector(state, "deployment_start_date"),
  );
  let deploymentEndDate = useSelector((state) =>
    formFieldValueSelector(state, "deployment_end_date"),
  );

  deploymentStartDate = parse(deploymentStartDate, "yyyy-MM-dd", new Date());
  deploymentEndDate = parse(deploymentEndDate, "yyyy-MM-dd", new Date());

  const dates = keys(timeSeries);
  const data = dates.map((date) => timeSeries[date][concernId]?.count);

  const state = {
    labels: dates,
    datasets: [{ ...datasetOptions, data }],
  };

  let startDate = format(deploymentStartDate, "M/yyyy");
  let deployDate = format(deploymentEndDate, "M/yyyy");

  const startIndex = dates.findIndex((d) => d.includes(startDate));
  const deployIndex = dates.findIndex((d) => d.includes(deployDate));

  return (
    <>
      <CardSection>
        {timeSeries ? (
          <Line
            data={state}
            height={300}
            width={600}
            options={{
              ...chartOptions,
              plugins: {
                datalabels: {
                  ...datalabelsOptions,
                  formatter: (_, context) => {
                    if (
                      context.dataIndex === startIndex &&
                      context.dataIndex === deployIndex
                    )
                      return "Project Deployed";

                    if (context.dataIndex === startIndex)
                      return "Project Started";

                    if (context.dataIndex === deployIndex)
                      return "Project Deployed";

                    return null;
                  },
                },
              },
            }}
          />
        ) : (
          <SkeletonGraph />
        )}
      </CardSection>
    </>
  );
};

const CardSection = ({ children }) => {
  return (
    <Card className="ui-card">
      <CardWrapper className="ui-card-wrapper">
        <CardHeader className="ui-card-header">
          <CardHeading className={`ui-card-heading`}>
            Before and After RCA
          </CardHeading>
        </CardHeader>
        <CardBody className="ui-card-body" charts>
          {children}
        </CardBody>
      </CardWrapper>
    </Card>
  );
};

export default ImpactGraph;

const datasetOptions = {
  label: "Complaints",
  fill: false,
  backgroundColor: "rgba(75,192,192,1)",
  borderColor: "rgba(0,0,0,1)",
  borderWidth: 2,
  lineTension: 0,
};

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  legend: { display: false },
  elements: { point: { radius: 2 } },
  layout: { padding: { top: 30 } },
  scales: {
    x: [{ offset: true, grid: { display: false } }],
    y: [
      {
        suggestedMax: 10,
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    ],
  },
};

const datalabelsOptions = {
  display: true,
  color: "#8c8c8c",
  align: "end",
  anchor: "end",
  backgroundColor: "#e9e9e9",
  borderWidth: 1,
  borderRadius: 3,
  borderColor: "#8c8c8c",
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import SkeletonTable from "../../common/Skeleton/SkeletonTable";
import { TableWrapper, Table, Thead, Tbody, Th, Tr, Td } from "./styled";
import { ErrorMessage } from "./styled";
import { NoDataFound } from "../../common/NoDataFound";
import { getDashboardObj } from "./selectors";
import { mixRedGreenColors, humanizeSeconds } from "utils/genericHelper";
import { TimeAgo } from "components/common/TimeAgo";

export const ProjectOverviewTable = ({
  analyzer,
  selectedFilters,
  projectsOverview,
  dashboards,
  loadProjectsOverview,
  selectDashboard,
}) => {
  useEffect(() => {
    loadProjectsOverview(analyzer);
  }, [selectedFilters.query]);

  const { t } = useTranslation();

  let columnKeys = analyzer.query?.split("columns=");
  columnKeys = (columnKeys?.[1] || "").split("|");
  const errorMessage = projectsOverview.isError
    ? t("noDataFound")
    : columnKeys?.length <= 1
    ? "Something went wrong"
    : null;

  return (
    <TableWrapper
      style={{
        padding: projectsOverview.isLoading ? "15px" : "0",
        backgroundColor: projectsOverview.isLoading && "#fff",
      }}
    >
      {projectsOverview.isLoading ? (
        <SkeletonTable />
      ) : errorMessage ? (
        <ErrorMessage>
          <NoDataFound message={errorMessage} />
        </ErrorMessage>
      ) : (
        <Table className="table ui-table sticky">
          <Thead className="ui-table-thead">
            <Tr className="ui-table-tr">
              {(analyzer.column_labels || "")
                .split("|")
                .map((header, index) => (
                  <Th className="ui-table-th" fixed={index === 0}>
                    {t(header)}
                  </Th>
                ))}
            </Tr>
          </Thead>
          <Tbody className="ui-table-tbody">
            {projectsOverview.data.data.map((row) => (
              <Tr
                className="ui-table-tr"
                onClick={(e) =>
                  selectDashboard(
                    getDashboardObj(dashboards, projectsOverview.data, row),
                    true,
                  )
                }
              >
                {row.map((element, index) => {
                  const key = columnKeys[index];
                  return (
                    <Td
                      className="ui-table-td"
                      fixed={index === 0}
                      success={key !== statusColoumn ? null : element}
                      error={key !== statusColoumn ? null : !element}
                      color={
                        dynamicColoredColumns.includes(key) &&
                        element !== null &&
                        mixRedGreenColors(parseInt(element, 10))
                      }
                    >
                      {valueFormatter[key]?.(element) || (element ?? "--")}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </TableWrapper>
  );
};

const valueFormatter = {
  // eslint-disable-next-line  react/display-name
  last_response: (value) => <TimeAgo date={value} />,
  status: (value) => (value ? "Active" : "Inactive"),
  feedback_count: (value) => numeral(value).format("0,0"),
  overall_count: (value) => numeral(value).format("0,0"),
  recovery_time: (value) => secondsToString(value),
  avg_duration: (value) => secondsToString(value),
  true_nps: (value) => percentageStringFormatter(value),
  nps: (value) => percentageStringFormatter(value),
  data_health: (value) => percentageStringFormatter(value),
  response_rate: (value) => percentageStringFormatter(value),
  recovery_rate: (value) => percentageStringFormatter(value),
  deployment_ratio: (value) => percentageStringFormatter(value),
  responses: (value) => numeral(value).format("0,0"),
  invites_count: (value) => numeral(value).format("0,0"),
};

const percentageStringFormatter = (value) => {
  if (value === null) return null;
  let unit = "";
  if (value.endsWith("%")) {
    value = value.slice(0, -1);
    unit = "%";
  }
  if (!isNaN(value)) {
    value = numeral(parseFloat(value)).format("0,0.0");
  }
  return `${value}${unit}`;
};

const secondsToString = (seconds) => {
  if (seconds === null) return null;
  let [duration, unit] = humanizeSeconds(seconds);
  if (!isNaN(duration)) {
    duration = numeral(parseFloat(duration)).format("0,0.0");
  }
  return `${duration} ${unit}`;
};

const dynamicColoredColumns = ["true_nps", "nps", "data_health"];
const statusColoumn = "status";

import React, { Component } from "react";
import { Panel } from "../../common/Panel";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import isEmpty from "lodash/isEmpty";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import { getCompetitorAnalysisChartData } from "../../../utils/chartsData";
import { loadCompetitorAnalysis } from "../actions";

class CompetitorAnalysis extends Component {
  getAPI() {
    const { loadCompetitorAnalysis } = this.props;
    loadCompetitorAnalysis();
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters } = this.props;
    if (!_.isEqual(prevProps.selectedFilters, selectedFilters)) {
      this.getAPI();
    }
  }

  render() {
    const { competitorAnalysis } = this.props;
    const { fetching, data } = competitorAnalysis;

    if (isEmpty(data) || fetching) {
      return (
        <Panel header="Competitor Analysis">
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    let chartData = getCompetitorAnalysisChartData(data);

    return (
      <Panel header="Competitive NPS">
        <Bar
          data={chartData}
          height={250}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: [
                {
                  beginAtZero: true,
                  ticks: {},
                  title: {
                    display: true,
                    text: "NPS",
                  },
                },
              ],
            },
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedFilters: state.selectedFilters,
  competitorAnalysis: state.dashboard.competitorAnalysis,
});

export default connect(mapStateToProps, {
  loadCompetitorAnalysis,
})(CompetitorAnalysis);

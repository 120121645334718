import { CUSTOMERS_SUCCESS } from "../../../constants/constants";

const customersReducer = (state = [], action) => {
  switch (action.type) {
    case CUSTOMERS_SUCCESS:
      return action.customers;

    default:
      return state;
  }
};

export default customersReducer;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { formFieldValueSelector } from "components/RCA/selectors";
import {
  MultipleElementsContainer,
  ParentWrapper,
  FamilyContainer,
  Wrapper,
  ElementHeading,
  ListItem,
} from "components/RCA/Form/views/styled";

import { size, keys } from "lodash";

const RootCauseSection = () => {
  const [topLevelConcernIDs, setTopLevelConcernIDs] = useState([1]);

  const causes =
    useSelector((state) => formFieldValueSelector(state, "root_causes")) ||
    null;

  const selectedConcern = useSelector((state) =>
    formFieldValueSelector(state, "concern"),
  );
  const selectedOtherConcern = useSelector((state) =>
    formFieldValueSelector(state, "other_concern"),
  );

  const selectedConcernName =
    selectedConcern?.name || selectedOtherConcern || "";

  useEffect(() => {
    const _topLevelConcernIDs = keys(causes)
      .filter((concernID) => concernID.split(".").length === 1)
      .map((ID) => Number(ID));

    setTopLevelConcernIDs(_topLevelConcernIDs);
  }, []);

  const createSubCauses = (rootCauses) => {
    if (!rootCauses.length) {
      return [];
    }

    return rootCauses.map((causeId) => {
      const rootCauseID = causes[causeId]?.rootCauses[0];
      const verticalLine = rootCauseID ? causes[rootCauseID]?.cause : false;
      return (
        causes[causeId]?.cause && (
          <Wrapper>
            <ListItem verticalLine={verticalLine}>
              {causes[causeId]?.cause}
            </ListItem>
            <ParentWrapper>
              {createSubCauses(causes[causeId]?.rootCauses)}
            </ParentWrapper>
          </Wrapper>
        )
      );
    });
  };

  const createCauses = () => {
    return (
      causes &&
      topLevelConcernIDs?.map((levelId) => {
        const rootCauseID = causes[levelId]?.rootCauses[0];
        const verticalLine = rootCauseID ? causes[rootCauseID]?.cause : false;
        return (
          causes[levelId]?.cause && (
            <Wrapper>
              <ListItem verticalLine={verticalLine}>
                {causes[levelId].cause}
              </ListItem>
              <ParentWrapper>
                {createSubCauses(causes[levelId].rootCauses)}
              </ParentWrapper>
            </Wrapper>
          )
        );
      })
    );
  };

  return (
    <MultipleElementsContainer>
      <FamilyContainer>
        <ParentWrapper>
          <ElementHeading>{selectedConcernName}</ElementHeading>
          {createCauses()}
        </ParentWrapper>
      </FamilyContainer>
    </MultipleElementsContainer>
  );
};

export default RootCauseSection;

import localforage from "localforage";
import { applyMiddleware, compose, createStore } from "redux";
import freeze from "redux-freeze";
import { persistReducer, persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

import reactotron from "./reactotron";
import rootReducer from "../reducers/rootReducer";
import { rootSaga } from "./saga";

let middlewares = [];

if (process.env.NODE_ENV !== "production") {
  middlewares.push(freeze);
}

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "root",
  storage: localforage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

middlewares.push(thunk);
middlewares.push(sagaMiddleware);

let middleware = applyMiddleware(...middlewares);

if (process.env.REACT_APP_ENV === "dev") {
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware = compose(middleware, window.__REDUX_DEVTOOLS_EXTENSION__());
  } else {
    middleware = compose(middleware, reactotron.createEnhancer());
  }
}

function configureStore(initialState) {
  return {
    ...createStore(persistedReducer, initialState, middleware),
    runSaga: sagaMiddleware.run,
  };
}

const store = configureStore();
store.runSaga(rootSaga);
const persistor = persistStore(store);

export { persistor, store };

import React, { Component } from "react";
import { Col } from "react-bootstrap";

import { connect } from "react-redux";
import * as analytics from "utils/analytics";

import { setFilter, setSearchQuery } from "components/common/filters/actions";
import { SearchBoxWithTranslation } from "./SearchBox";

class SearchBar extends Component {
  setSearchValue = (search) => {
    this.props.setSearchQuery(search);
    analytics.logSearchFilter(search);
  };

  render = () => {
    const { colors, selectedFilters } = this.props;
    return (
      <Col md={2} className="options">
        <SearchBoxWithTranslation
          onSubmit={this.setSearchValue}
          colors={colors}
          resetValue={selectedFilters.feedbackGroup}
        />
      </Col>
    );
  };
}

const mapStateToProps = (state) => ({
  selectedFilters: state.selectedFilters,
});

const mapDispatchToProps = {
  setFilter,
  setSearchQuery,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);

import React from "react";

export const UserAvatar = ({ user }) => {
  return (
    <div className="user-account">
      <span className="user-img">
        {user?.avatar ? (
          <img src={user.avatar} alt="" />
        ) : (
          <span className="user-initials">
            {user?.first_name ? user.first_name[0] : null}
          </span>
        )}
      </span>
    </div>
  );
};

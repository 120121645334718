import React, { useEffect } from "react";
import * as analytics from "utils/analytics";
import { useDispatch, useSelector } from "react-redux";
import GenericFilter from "components/common/GenericFilter/GenericFilter";

import {
  setGenericFilters,
  resetGenericFilters,
  storeFilterSet,
} from "components/DashboardPage/actions";
import { TOOLTIPS } from "constants/constants";

import { ReactComponent as ProjectFilterIcon } from "assets/svg/project_filter.svg";

const ProjectFilter = () => {
  const dispatch = useDispatch();

  const groups = useSelector((state) => state.groups);
  const questionnaires = useSelector((state) => state.dashboard.dashboards);

  useEffect(() => {
    const filterSet = createFilterSetForProjects(groups, questionnaires);
    dispatch(storeFilterSet(filterSet));
  }, [groups, questionnaires]);

  const permissions = useSelector((state) => state.auth.user.permissions);
  const selectedFilters = useSelector((state) => state.selectedFilters);

  const filterSet = useSelector(
    (state) => state.dashboard.filterSets[ProjectFilterSet.title],
  );

  if (!permissions[PROJECT_FILTER_PERMISSION]) {
    return null;
  }

  return (
    <GenericFilter
      id="project-filter"
      filterSet={filterSet}
      Icon={ProjectFilterIcon}
      selectedFilters={selectedFilters}
      applyFilter={(selectedFilters) => {
        analytics.logProjectFilterChange(
          selectedFilters.questionnaires,
          selectedFilters.groups,
        );
        dispatch(setGenericFilters(selectedFilters));
      }}
      resetFilter={(keys) => dispatch(resetGenericFilters(keys))}
      tooltip={TOOLTIPS.PROJECT_FILTER}
    />
  );
};

export default ProjectFilter;

export const createFilterSetForProjects = (
  groups = {},
  questionnaires = [],
) => {
  return {
    ...ProjectFilterSet,
    filters: [
      {
        key: "groups",
        title: "Customer Segments",
        tags: Object.keys(groups).map((id) => {
          return {
            key: id,
            title: groups[id].title,
          };
        }),
      },
      {
        key: "questionnaires",
        title: "Projects",
        tags: questionnaires
          .filter((questionnaire) => questionnaire.id !== "omni")
          .map((questionnaire) => {
            return {
              key: questionnaire.id,
              title: questionnaire.title,
            };
          }),
      },
    ],
  };
};

const ProjectFilterSet = {
  title: "Project Filter",
  filters: [],
};

const PROJECT_FILTER_PERMISSION = "filter_project";

import produce from "immer";
import * as constants from "./constants";
import { initialState } from "./initialState";

/* eslint-disable default-case, no-param-reassign */
export const customerUserReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_CUSTOMER_USERS:
        draft.isLoading = true;
        break;

      case constants.LOAD_CUSTOMER_USERS_SUCCESS:
        draft.isLoading = false;
        draft.users = action.data;
        break;

      case constants.LOAD_CUSTOMER_USERS_ERROR:
        draft.isLoading = false;
        draft.isError = true;
        break;

      case constants.LOAD_NEW_USER_PAGE:
        draft.mainPage = false;
        break;
    }
  });
};

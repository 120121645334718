export const options = {
  enableTooltip: true,
  deterministic: true,
  fontFamily: "times new roman",
  fontSizes: [14, 80],
  fontStyle: "normal",
  fontWeight: "normal",
  padding: 2,
  rotations: 1,
  rotationAngles: [0, 0],
  scale: "linear",
  spiral: "rectangular",
  transitionDuration: 1000,
};

export const getWordCloudData = (words) => {
  return words.map((word) => ({
    text: word.keyword,
    value: word.stats.count,
  }));
};

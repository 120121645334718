import { getQueryVariable } from "utils/filtersHelperFuncs";
import { getRequest } from "utils/request";

export const getInvoicesApi = ({
  division,
  customer,
  search_text,
  invite,
  group,
}) => {
  let feedbackGroup = parseInt(getQueryVariable("feedback_group"), 10);
  let url = `/api/customers/invoices/?invite=${invite || ""}&search_text=${
    search_text || ""
  }&customer=${customer || ""}&division=${division || ""}&group=${
    feedbackGroup || group || ""
  }`;
  return getRequest(url);
};

export const getMoreInvoicesApi = (nextUrl) => {
  return getRequest(nextUrl);
};

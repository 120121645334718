import React from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonComments = () => {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        textAlign: "left",
        margin: "auto",
      }}
    >
      <Skeleton
        width={"20%"}
        height={"15px"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Skeleton
        width={"100%"}
        height={"45px"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Skeleton
        width={"35%"}
        height={"15px"}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        width={"10%"}
        height={"15px"}
        style={{ marginLeft: "auto", marginBottom: "10px", float: "right" }}
      />
      <br />
      <br />
      <Skeleton
        width={"20%"}
        height={"15px"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Skeleton
        width={"100%"}
        height={"45px"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Skeleton
        width={"35%"}
        height={"15px"}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        width={"10%"}
        height={"15px"}
        style={{ marginLeft: "auto", marginBottom: "10px", float: "right" }}
      />
    </div>
  );
};

export default SkeletonComments;

import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import numeral from "numeral";

import PrivateRoute from "../HOC/PrivateRoute";
import WithTracker from "../HOC/WithTracker";
import Topbar from "./common/Topbar";
import SecondaryTopbar from "./common/filters/SecondaryTopbar";
import HelpComponent from "./common/help";
import { OrganizationNotFound } from "../components/common/OrganizationNotFound";
import { unauthRequest, loadUserInfo } from "./LoginPage/actions";
import { getOrganization } from "./actions";
import HeaderWithScrollbar from "./common/HeaderWithScrollbar";
import {
  calculateOverallTimeDelta,
  calculatePageTimeDelta,
  setOverallActiveStartTime,
  setPageStartTime,
  getOverallTimeDelta,
  getPageTimeDelta,
} from "../HOC/telemetry";
import routes, { indexesRoutes } from "routes";
import {
  isCustomerView,
  getViewOnlyFeedbackUUID,
} from "components/FeedbackPage/selectors";
import * as analytics from "utils/analytics";
import withRouter from "withRouter";

const publicRoutes = routes
  .filter((route) => !route.private)
  .map((route) => route.path.replace("/", ""));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { isLanguageSet: false };
  }

  componentDidMount = () => {
    const { getOrganization, loadUserInfo } = this.props;
    getOrganization();
    loadUserInfo();

    setOverallActiveStartTime();

    document.addEventListener("visibilitychange", this.handlePageVisibility);
    window.addEventListener("unload", this.trackPageView);
  };

  handlePageVisibility() {
    setOverallActiveStartTime();
    setPageStartTime();
    calculateOverallTimeDelta();
    calculatePageTimeDelta();
  }

  setLanguage() {
    this.setState({
      isLanguageSet: true,
    });
  }

  trackPageView = (e) => {
    const { authenticated } = this.props;
    const overallAnalytics = getOverallTimeDelta();
    const pageAnalytics = getPageTimeDelta();
    if (authenticated) {
      analytics.logOverallActiveTime(overallAnalytics.activeTimeDiff);
    }
    analytics.logOverallPageViewTime(
      pageAnalytics.page,
      pageAnalytics.activeTimeDiff,
    );
  };

  preloader = true;
  hidePreloader = () => {
    const root = document.getElementById("root");
    root.classList.remove("hidden");
    const preloader = document.getElementById("preloader");
    if (preloader) {
      preloader.outerHTML = "";
    }
    this.preloader = false;
  };

  renderTopbar = (user, authenticated, hideLoader, route) => {
    if (!user.id || !authenticated || (route && publicRoutes.includes(route)))
      return null;
    hideLoader && this.preloader && this.hidePreloader();
    return <Topbar user={user} />;
  };

  render() {
    const {
      auth,
      authenticated,
      user,
      organization,
      verifyingOrganization,
      isOrganizationAPIError,
      organizationControls,
      i18n,
    } = this.props;
    const { isViewOnly } = getViewOnlyFeedbackUUID();

    const pagePath = window.location.pathname
      .split("/")
      .filter((p) => p.length)[0];
    const hideLoaderBeforeUserAuth = indexesRoutes[pagePath];

    if (organizationControls && !this.state.isLanguageSet) {
      this.setLanguage();
      i18n.changeLanguage(organizationControls.language_code);
    }

    const languageCode = i18n.language;
    if (numeral.locale() != languageCode && languageCode == "de") {
      numeral.register("locale", languageCode, {
        delimiters: {
          thousands: ".",
          decimal: ",",
        },
        abbreviations: {
          thousand: "k",
          million: "m",
          billion: "b",
          trillion: "t",
        },
      });
      numeral.locale(languageCode);
    }

    if (verifyingOrganization) {
      return <div />;
    } else if (isOrganizationAPIError) {
      this.hidePreloader();
      return <OrganizationNotFound />;
    } else {
      hideLoaderBeforeUserAuth && this.preloader && this.hidePreloader();
      return (
        <div>
          {!!(isViewOnly || isCustomerView(auth)) && <HeaderWithScrollbar />}
          {this.renderTopbar(
            user,
            authenticated,
            !hideLoaderBeforeUserAuth,
            pagePath,
          )}
          <div
            className="sticky-portal"
            ref={(ref) => (window.stickyContainer = ref)}
          />
          <section id={authenticated ? "content-wrapper" : null}>
            {authenticated && user.id && !indexesRoutes[pagePath] && (
              <SecondaryTopbar user={user} organization={organization} />
            )}
            <Routes>
              {routes.map((route) => {
                let Component = route.component;
                if (route.tracker) Component = WithTracker(Component);
                if (route.withRoute) Component = withRouter(Component);
                return (
                  <Route
                    path={route.path}
                    element={
                      route.private ? (
                        <PrivateRoute
                          auth={route.auth && auth}
                          component={Component}
                          path
                        />
                      ) : (
                        <Component />
                      )
                    }
                    key={route.path}
                    auth={route.auth && auth}
                  />
                );
              })}
            </Routes>
          </section>
          <HelpComponent
            colors={organization.features && organization.features.colors}
          />
          <footer className="footer">
            <a
              href="https://sentimeter.io/data-privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              {"Data & Privacy Policy"}
            </a>
          </footer>
          <div
            className="portal-container"
            ref={(ref) => (window.portalContainer = ref)}
          ></div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  authenticated: state.auth.authenticated,
  user: state.auth.user,
  organization: state.organization.organization,
  verifyingOrganization: state.organization.verifyingOrganization,
  isOrganizationAPIError: state.organization.isOrganizationAPIError,
  organizationControls: state.organization.organization.controls,
});

const mapDispatchToProps = {
  unauthRequest,
  loadUserInfo,
  getOrganization,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)),
);

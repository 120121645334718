import styled, { css } from "styled-components";

export const Container = styled.div`
  border-radius: 4px;
  margin: 45px 0;
  box-shadow: #00000029 0px 3px 6px;
`;

export const SectionTitle = styled.div`
  background-color: #e9e9e9;
  color: #242424;
  padding: 10px 0;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;
`;

export const SectionBody = styled.div`
  background-color: var(--text-color);
  padding: 45px 140px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media screen and (max-width: 1200px) {
    padding: 45px 100px;
  }

  @media screen and (max-width: 991px) {
    padding: 40px 70px;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 26px;
  }
`;

export const RatingComponent = styled.div`
  height: 56px;
  width: 57px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 0;
  outline: 0;
  color: #242424;
  font-size: 19px;
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 45px;
    height: 45px;
    font-size: 16px;
  }

  @media screen and (max-width: 991px) {
    width: 40px;
    height: 40px;
  }

  @media screen and (max-width: 768px) {
    margin: 10px;
    width: calc((100% / 4) - 20px);
  }
`;

import React, { useEffect } from "react";
import * as analytics from "utils/analytics";
import { useDispatch, useSelector } from "react-redux";
import { map, filter, flatMap, groupBy, flow } from "lodash/fp";

import GenericFilter from "components/common/GenericFilter/GenericFilter";

import {
  storeFilterSet,
  setGenericFilters,
  resetGenericFilters,
  loadSurveyQuestions,
} from "components/DashboardPage/actions";

import { TOOLTIPS } from "constants/constants";
import { ReactComponent as QuestionFilterIcon } from "assets/svg/question_filter.svg";

const QuestionFilter = () => {
  const dispatch = useDispatch();

  const questionnaireId = useSelector(
    (state) => state.dashboard.selectedDashboard.id,
  );
  const questions = useSelector(
    (state) =>
      state.dashboard.surveyQuestions.surveys[questionnaireId]?.questions,
  );

  useEffect(() => {
    if (!questions) {
      dispatch(loadSurveyQuestions(questionnaireId));
    }
  }, [questionnaireId]);

  const permissions = useSelector((state) => state.auth.user.permissions);
  const selectedFilters = useSelector((state) => state.selectedFilters);

  useEffect(() => {
    const filterSet = createFilterSetForQuestions(questions, questionnaireId, permissions);
    dispatch(storeFilterSet(filterSet));
  }, [questions, permissions]);

  const filterSet = useSelector(
    (state) => state.dashboard.filterSets[QuestionFilterSet.title],
  );

  return (
    <GenericFilter
      id="question-filter"
      filterSet={filterSet}
      Icon={QuestionFilterIcon}
      selectedFilters={selectedFilters}
      applyFilter={(filters) => {
        analytics.logFilterChange("Age", selectedFilters.age, true);
        analytics.logFilterChange("Gender", selectedFilters.gender, false);

        dispatch(setGenericFilters(filters));
      }}
      resetFilter={(keys) => dispatch(resetGenericFilters(keys))}
      tooltip={TOOLTIPS.QUESTION_FILTER}
    />
  );
};

export default QuestionFilter;

export const createFilterSetForQuestions = (questions, questionnaireId, permissions) => {
  const permission = "filter_question";
  let _questions = questions?.length
    ? questions.map(
        (question) =>
          replacableQuestions[question.text_filter] || { ...question, permission },
      )
    : QuestionFilterSet.filters;

  if(questionnaireId === "omni" && questions?.length) {
    _questions = [...QuestionFilterSet.filters, ..._questions];
  }

  _questions = flow(
    filter((f) => !f.permission || !permissions || permissions[f.permission]),
    flatMap((question) => [
      {
        ...question,
        options: question.options.map((o) => ({
          id: o.id,
          text: o.text_filter || o.text,
        })),
      },
      {
        id: question.id + ":sub_options",
        text_filter: question.sub_options_text_filter || "Drill Downs",
        options: question.options.flatMap((o) =>
          (o.sub_options || []).map((so) => ({
            id: so.id,
            text: o.text + " > " + (so.text_filter || so.text),
          })),
        ),
      },
    ]),
    filter((question) => question.options.length),
    groupBy("text_filter"),
    map((group) =>
      group.reduce((combinedQuestion, question) => ({
        ...combinedQuestion,
        options: [...combinedQuestion.options, ...question.options],
      })),
    ),
    map((question) => ({
      ...question,
      options: flow(
        groupBy("text"),
        map((group) =>
          group.reduce((combinedOption, option) => ({
            ...combinedOption,
            id: combinedOption.id + "|" + option.id,
          })),
        ),
      )(question.options),
    })),
  )(_questions);

  return {
    ...QuestionFilterSet,
    filters: _questions.map((question) => {
      return {
        key: question.key || "options",
        title: question.text_filter,
        tags: question.options.map((option) => {
          return {
            key: option.id,
            title: option.text,
          };
        }),
      };
    }),
  };
};

const QuestionFilterSet = {
  title: "Question Filter",
  filters: [
    {
      id: "age",
      key: "age",
      text_filter: "Age",
      permission: "filter_age",
      options: [
        { id: "Under 18", text: "Under 18" },
        { id: "18 to 24", text: "18 to 24" },
        { id: "25 to 34", text: "25 to 34" },
        { id: "35 to 44", text: "35 to 44" },
        { id: "45 to 54", text: "45 to 54" },
        { id: "55 to 64", text: "55 to 64" },
        { id: "65 & older", text: "65 & older" },
        { id: "Prefer not to say", text: "Prefer not to say" },
      ],
    },
    {
      id: "gender",
      key: "gender",
      text_filter: "Gender",
      permission: "filter_gender",
      options: [
        { id: "Male", text: "Male" },
        { id: "Female", text: "Female" },
      ],
    },
    {
      id: "nps",
      key: "nps",
      text_filter: "NPS",
      permission: "filter_nps",
      options: [
        { id: "0", text: "0" },
        { id: "1", text: "1" },
        { id: "2", text: "2" },
        { id: "3", text: "3" },
        { id: "4", text: "4" },
        { id: "5", text: "5" },
        { id: "6", text: "6" },
        { id: "7", text: "7" },
        { id: "8", text: "8" },
        { id: "9", text: "9" },
        { id: "10", text: "10" },
      ],
    },
  ],
};

const replacableQuestions = {
  Age: QuestionFilterSet.filters[0],
  Gender: QuestionFilterSet.filters[1],
  NPS: QuestionFilterSet.filters[2],
};

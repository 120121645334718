import React, { Component } from "react";
import * as analytics from "utils/analytics";
import { connect } from "react-redux";

import RCA from "components/RCA/RCA";
import SurveyDemo from "components/Survey/SurveyDemo";
import SurveyScript from "components/Survey/SurveyScript";
import SurveyResults from "components/Survey/SurveyResults";
import Feedback from "components/FeedbackPage/Feedback/FeedbackPage";
import TransactionalDurational from "components/Transactional/Durational";
import Durational from "components/Durational/Durational";

import CustomerInvites from "components/CustomerInvitesPage/CustomerInvites";

import AnalyzerRows from "components/Rack/AnalyzerRows";
import InsightsAnalysis from "components/Insights/Analysis";

import { Container } from "./styled";

class Rack extends Component {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  firstLoad = true;

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedRacks !== prevProps.selectedRacks) {
      const state = this.getStateFromProps(this.props);

      // Just use for get the previous rack route
      // to find the time spend on each route
      const { rack } = this.getStateFromProps(prevProps);

      analytics.logPageSwitch(rack, state.rack);

      if (this.firstLoad && state.rack) {
        this.firstLoad = false;
        this.setState(state);
      } else {
        this.setState({ switching: true });
        setTimeout(() => this.setState(state), 250);
      }
    }
  };

  getStateFromProps = (props) => {
    const rack = props.selectedRacks.find((r) => r);
    return { rack, switching: false };
  };

  renderCustomRack = (rack) => {
    return (
      <div className="charts">
        <AnalyzerRows analyzers={rack.analyzers} />
      </div>
    );
  };

  renderSpecializedRack = (rack) => {
    const SpecializedRack = specializedRacks[rack.type];
    return <SpecializedRack match={this.props.match} rack={rack} />;
  };

  renderRack = (rack) => {
    if (!rack) return <div />;
    return rack.type === "custom" || rack.type === "insights"
      ? this.renderCustomRack(rack)
      : this.renderSpecializedRack(rack);
  };

  render = () => {
    const { rack, switching } = this.state;
    return <Container dim={switching}>{this.renderRack(rack)}</Container>;
  };
}

const specializedRacks = {
  rca: RCA,
  feedback: Feedback,
  durational: Durational,
  survey_demo: SurveyDemo,
  customer: CustomerInvites,
  insights: InsightsAnalysis,
  survey_script: SurveyScript,
  survey_results: SurveyResults,
  transactional_durational: TransactionalDurational,
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    selectedRacks: state.dashboard.selectedRacks,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rack);

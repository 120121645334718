import React from "react";

export const OrganizationNotFound = () => {
  return (
    <div className="organization-not-found">
      Whoops, Organization Not Found
      <span>Looks like you have a typo in your organization name</span>
    </div>
  );
};

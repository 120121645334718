import styled, { css } from "styled-components";
import RightIconImage from "../../../assets/img/right-arrow.png";

export const ItemText = styled.p`
  color: #242424;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  font-size: 13px;
  position: relative;
  margin-bottom: 0;
  ${(props) =>
    props.hasChildren &&
    css`
      background-image: url("${RightIconImage}");
      background-repeat: no-repeat;
      background-position: 100%;
    `}
`;

export const SubDropdownMenu = styled.li`
  padding: 4px 11px;
  color: rgb(51 51 51);
  padding: 4px 10px;
  background: linear-gradient(to left, #fff 50%, #e6e6e6 50%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  &:hover {
    cursor: pointer;
    background-position: left;
  }
`;

export const SubDropdownContainer = styled.ul`
  position: absolute;
  top: 0;
  left: 101%;
  z-index: 10;
  float: left;
  min-width: 100%;
  list-style: none;
  background-color: var(--text-color);
  box-shadow: 0px 10px 10px 0px lightgrey;
  padding-inline-start: 0;
`;

export const DropdownMenu = styled.li`
  padding: 4px 11px;
  position: relative;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s ease width;
  }
  &:hover {
    cursor: pointer;
    &::before {
      width: 101%;
      background: #e6e6e6;
    }
  }
`;

export const DropdownContainer = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  list-style: none;
  background-color: var(--text-color);
  box-shadow: 0px 10px 10px 0px lightgray;
  display: inline-table;
  padding-inline-start: 0;
  min-width: 50%;
`;

export const Container = styled.div`
  position: relative;
`;

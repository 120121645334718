import produce from "immer";

import * as constants from "constants/constants";
import initialState from "./initialState";

import { format } from "date-fns";
import { keyBy } from "lodash";

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_RCA_PROJECT_LIST:
        draft.projectList.loading = true;
        draft.projectList.error = false;
        break;
      case constants.LOAD_RCA_PROJECT:
        draft.selectedProject.form.loading = true;
        draft.selectedProject.form.error = false;
        break;
      case constants.LOAD_RCA_PROJECT_DATA:
        draft.selectedProject.values.loading = true;
        draft.selectedProject.values.error = false;
        break;

      case constants.LOAD_RCA_PROJECT_LIST_SUCCESS:
        draft.projectList.loading = false;
        draft.projectList.error = false;
        draft.projectList.data = action.payload.results;
        draft.totalProjects = action.payload.count;
        break;

      case constants.LOAD_RCA_PROJECT_LIST_ERROR:
        draft.projectList.loading = false;
        draft.projectList.error = true;
        break;

      case constants.LOAD_RCA_PROJECT_SUCCESS:
        draft.selectedProject.form.loading = false;
        draft.selectedProject.form.error = false;
        draft.locationMatchError = "";
        draft.selectedProject.form.data = action.payload;
        draft.selectedProject.form.data.rca_sections =
          action.payload.rca_sections;
        draft.selectedProject.values.data = {
          ...initialState.selectedProject.values.data,
          rca_template: action.payload,
          status: "PROGRESS",
          owner: action.owner || null,
          project_start_date: format(new Date(), "yyyy-MM-dd"),
          location: {},
          team_members: [],
        };

        break;

      case constants.LOAD_RCA_PROJECT_ERROR:
        draft.selectedProject.form.loading = false;
        draft.selectedProject.form.error = true;
        break;

      case constants.TOGGLE_MODE:
        draft.mode = action.mode;
        break;

      case constants.LOAD_RCA_PROJECT_DATA_SUCCESS:
        draft.selectedProject.values.loading = false;
        draft.selectedProject.values.error = false;
        draft.selectedProject.form.data.rca_sections =
          action.payload.rca_template.rca_sections;
        draft.locationMatchError = "";
        draft.selectedProject.values.data = {
          ...action.payload,
          rca_options: keyBy(action.payload.rca_options, "rca_field"),
          location: keyBy(action.payload.location, "level"),
        };
        break;

      case constants.LOAD_RCA_PROJECT_DATA_ERROR:
        draft.selectedProject.values.loading = false;
        draft.selectedProject.values.error = true;
        break;

      case constants.SELECT_RCA_PROJECT:
        draft.selectedProject.id = action.payload;
        draft.selectedView = "form";
        break;

      case constants.START_NEW_PROJECT:
        draft.selectedProject.id = null;
        draft.selectedView = "form";
        draft.mode = "EDIT";
        break;

      case constants.VIEW_PROJECT_LIST:
        draft.selectedView = "list";
        draft.selectedProject = initialState.selectedProject;
        break;

      case constants.SET_FORM_FIELD_VALUE:
        draft.selectedProject.values.data[action.payload.key] =
          action.payload.value;
        break;

      case constants.SET_RCA_OPTIONS_FIELD_VALUE: {
        const { id, content } = action.payload;
        const option = {
          ...state.selectedProject.values.data.rca_options[id],
          rca_field: id,
          content,
        };
        draft.selectedProject.values.data.rca_options[id] = option;
        break;
      }

      case constants.LOAD_CONCERN_ANALYSIS_SUCCESS:
        draft.selectedProject.values.analysis = action.payload;
        break;

      case constants.LOAD_CONCERN_ANALYSIS_ERROR:
        draft.selectedProject.values.analysis = null;
        break;

      case constants.SWITCH_RCA_VIEW:
        draft.selectedView = "list";
        break;

      case constants.LOAD_TEAM_LEADS_SUCCESS:
        draft.allTeamLeads = action.teamLeads;
        break;
      case constants.LOAD_TEAM_MEMBERS_SUCCESS:
        draft.allTeamMembers = action.teamMembers.filter(
          (teamMember) => teamMember.id !== action.userId,
        );
        break;
      case constants.LOAD_CONCERN_SUCCESS:
        draft.allConcerns = action.concerns;
        break;
      case constants.LOAD_LEVELS_SUCCESS:
        draft.allLevels = action.levels;
        draft.selectedProject.values.data = {
          ...draft.selectedProject.values.data,
          location: (action.levels || []).reduce(
            (prev, curr) => ({
              ...prev,
              [curr.level]: {
                level: curr.level,
                titles: [],
              },
            }),
            {},
          ),
        };
        break;
      case constants.LOAD_USERS_SUCCESS:
        draft.allUsers = action.users;
        break;
      case constants.SET_LOCATION_MATCH_ERROR:
        draft.locationMatchError = action.locationError;
        break;
      case constants.SET_TEAM_MEMBERS_EMPTY:
        draft.allTeamMembers = [];
        break;

      case constants.DELETE_RCA_PROJECT:
        draft.projectList.data = [
          ...state.projectList.data.filter(
            (project) => project.id !== state.selectedProject?.id,
          ),
        ];
        break;
      case constants.UPDATE_PROJECT_LIST_PAGE:
        draft.currPage = action.page;
        break;
    }
  });
};

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .custom_dropdown-wrapper {
    z-index: 5;
    position: relative;
    width: 200px;
    margin: 0;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .custom_dropdown {
    border-radius: 25px;
    border-color: var(--primary-color);

    .fa {
      color: var(--primary-color);
    }

    ${(props) =>
      props.open &&
      css`
        .custom_dropdown-wrapper {
          z-index: 10;
        }
      `}
  }

  .custom_dropdown-menu {
    -webkit-box-shadow: 0 5px 12px 8px rgba(34, 36, 38, 0.15);
    box-shadow: 0 5px 12px 8px rgba(34, 36, 38, 0.15);

    &::before {
      border-bottom-color: #fff;
    }
  }

  .custom_dropdown-text {
    color: var(--primary-color);
    font-family: "SegoeUI", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 1.1em;
    height: 1.22em;
    line-height: 1.2;
    max-width: 18ch;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    word-break: break-all;
    overflow: hidden;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      right: 0;
      width: 30px;
      height: 100%;
      top: 0;
      bottom: 0;
      background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(transparent),
        to(white)
      );
      background-image: -webkit-linear-gradient(left, transparent, white);
      background-image: -o-linear-gradient(left, transparent, white);
      background-image: linear-gradient(to right, transparent, white);
    }
  }

  .custom_dropdown-menu-list {
    padding: 0;
    max-height: 350px;
    overflow: auto;
  }

  .custom_dropdown-item {
    color: #4d565c;
    font-family: "SegoeUI", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 0.9em;
    position: relative;
    padding: 10px 13px;

    &:hover,
    &.selected {
      color: #fff;
      background-color: var(--primary-color);
    }
  }
`;

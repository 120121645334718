import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import isEmpty from "lodash/isEmpty";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import { getNpsChartData } from "../../../utils/chartsData";
import { Panel } from "../../common/Panel";
import { loadNPS } from "../actions";
import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import numeral from "numeral";

class NPS extends Component {
  state = { isTooltipVisible: false };

  getAPI() {
    const { loadNPS } = this.props;
    loadNPS();
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedFilters, this.props.selectedFilters)) {
      this.getAPI();
    }
  }

  renderNPSOverlay = () => {
    if (this.state.isTooltipVisible) {
      const { nps } = this.props;
      const { data } = nps;
      const counts = data.stats.counts;
      const colorCodes = data.color_codes;
      const percentages = data.stats.percentages;

      return (
        <div
          style={{
            position: "absolute",
            opacity: 0.9,
            pointerEvents: "none",
            zIndex: 10,
            top: "130px",
            left: "38px",
          }}
        >
          <Table className="tooltip-table">
            <thead>
              <tr>
                <th>Segment</th>
                <th>Count</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {data.options.map((opt, i) => (
                <tr>
                  <td key={i}>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          height: 12,
                          width: 12,
                          backgroundColor: colorCodes[i],
                          display: "inline-block",
                          border: "1px solid #fff",
                          position: "relative",
                          left: -5,
                          top: 2,
                        }}
                      />
                      <span>{opt}</span>&nbsp;
                    </div>
                  </td>
                  <td>{numeral(counts[i]).format("0,0")}</td>
                  <td>{percentages[i]}%</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      );
    }
  };
  render() {
    const { nps } = this.props;
    const { fetching, data } = nps;
    if (isEmpty(data) || fetching) {
      return (
        <Panel header="Net Promoter Score">
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    const percentage = data.stats.nps;
    const chartsData = getNpsChartData(data);

    const setTooltipVisibility = (flag) => {
      if (this.state.isTooltipVisible !== flag) {
        this.setState({ isTooltipVisible: flag });
      }
    };

    return (
      <Panel
        header={
          <span title={TOOLTIP_MESSAGE.NET_PROMOTER_SCORE}>
            Net Promoter Score: {percentage || 0}
          </span>
        }
      >
        {this.renderNPSOverlay()}
        <Bar
          data={chartsData}
          height={250}
          options={{
            indexAxis: "y",
            maintainAspectRatio: false,
            tooltip: {
              enabled: false,
              placement: "left",
              caretSize: 5,
              cornerRadius: 6,
              custom: function (tooltipModel) {
                tooltipModel.opacity === 0
                  ? setTooltipVisibility(false)
                  : setTooltipVisibility(true);
              },
            },
            scales: {
              x: [
                {
                  grid: {
                    display: true,
                  },
                  beginAtZero: true,
                  max: 100,
                  ticks: {},
                  stacked: true,
                  barPercentage: 1.0,
                  title: {
                    display: true,
                    text: "Percentage",
                  },
                },
              ],
              y: [
                {
                  display: false,
                  stacked: true,
                  barPercentage: 1.0,
                  categoryPercentage: 1.0,
                },
              ],
            },
          }}
        />
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, dashboard }) => ({
  selectedFilters,
  nps: dashboard.nps,
});

export default connect(mapStateToProps, {
  loadNPS,
})(NPS);

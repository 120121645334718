import { keyBy, values } from "lodash";
import { flow, groupBy, mapValues, map } from "lodash/fp";

import { QuestionOptionType } from "constants/constants";
import * as apis from "./apis";

export const parseQuestionsToAnalyzers = (questions) => {
  return questions.flatMap((question) => {
    const api = question.is_open_ended
      ? apis.openEndedQuestionResultApi
      : apis.closeEndedQuestionResultApi;

    const name = question.is_open_ended
      ? "open_ended_question_result"
      : "close_ended_question_result";

    const graphs = ["table", "bar", "donut"];
    if (!question.is_open_ended) {
      graphs.push("line");
    }
    const max_bars = question.text_short == "NPS" ? 11 : 8;

    const generateAnalyzer = (extras) => ({
      name,
      graphs,
      slug: `question_results/${question.id}`,
      api: api(question.id),
      query: "",
      units: ["percentage", "count"],
      type: "graph",
      sizes: { sm: 12, lg: 8 },
      max_bars,
      heading: question.text_short || question.text,
      pivot_column: "Options",
      tooltips: question.text && question.text_short ? [question.text] : [],
      ...extras,
    });

    const analyzers = question.has_sub_options
      ? [
          generateAnalyzer({
            query: `options_type=${QuestionOptionType.OPTION}`,
          }),
          generateAnalyzer({
            query: `options_type=${QuestionOptionType.SUB_OPTION}`,
            heading: `${question.text_short || question.text} (Drill-down)`,
            slug: `question_results/${question.id}/drill-down`,
          }),
        ]
      : [generateAnalyzer()];
    return analyzers;
  });
};

export const parseQuestionnaireToScript = (questionnaire, divisions) => {
  let _divisions = divisions.flatMap((division) =>
    division.divisions.map((d) => ({
      id: d.id,
      kind: division.name,
      text: d.title,
    })),
  );
  _divisions = keyBy(_divisions, "id");

  let questions = questionnaire.questions
    .flatMap((question) => [
      {
        id: question.virtual_id,
        text: question.text,
        depends_upon: question.depends_upon,
        options: question.options.map((o) => ({
          id: `${question.virtual_id}/${o.virtual_id}`,
          text: o.text,
          depends_upon: o.depends_upon,
        })),
      },
      {
        id: question.virtual_id + ":sub_options",
        text:
          question.text +
          ` (${question.sub_options_text_filter || "Drill Downs"})`,
        depends_upon: question.depends_upon,
        options: question.options.flatMap(
          (option) =>
            option.sub_options.map((subOption) => ({
              id: `${question.virtual_id}/${option.virtual_id}/${subOption.virtual_id}`,
              text: subOption.text,
              depends_upon: [
                {
                  type: "OP",
                  question: question.virtual_id,
                  option: option.virtual_id,
                },
                // ...subOption.depends_upon,
              ],
            })) || [],
        ),
      },
    ])
    .filter((question) => question.options.length)
    .map((question, index) => {
      return { ...question, index: index + 1 };
    });

  let options = questions.flatMap((question) => question.options);
  options = keyBy(options, "id");

  let questionIds = questions.map((question) => question.id);
  questions = keyBy(questions, "id");

  const dependables = { questions, options, divisions: _divisions };

  return questionIds.map((qId) => {
    const question = questions[qId];
    return {
      ...question,
      dependencies: annotateDependencies(question.depends_upon, dependables),
      options: question.options.map((option) => {
        return {
          ...option,
          dependencies: annotateDependencies(option.depends_upon, dependables),
        };
      }),
    };
  });
};

const annotateDependencies = (dependencies, dependables) => {
  const _dependencies = flow(
    map((dep) => ({ ...dep, action: dep.disable ? "hide" : "show" })),
    groupBy("type"),
    mapValues((deps) =>
      flow(
        groupBy("action"),
        mapValues((deps) =>
          deps.map((dep) => dependenyAnnotators[dep.type](dep, dependables)),
        ),
      )(deps),
    ),
  )(dependencies);

  ["OP", "SO"].forEach((type) => {
    if (_dependencies[type])
      _dependencies[type] = groupSameQuestionOptions(_dependencies[type]);
  });

  return _dependencies;
};

const groupSameQuestionOptions = (dependencies) => {
  const grouped = { hide: { questions: {} }, show: { questions: {} } };

  ["show", "hide"].map((action) => {
    const actionwiseDependencies = dependencies[action] || [];
    actionwiseDependencies.forEach(({ question }) => {
      grouped[action].questions[question.id] = { ...question, options: [] };
    });
    actionwiseDependencies.forEach(({ question, option }) => {
      grouped[action].questions[question.id].options.push(option);
    });
    grouped[action] = values(grouped[action].questions);
  });

  return grouped;
};

const dependenyAnnotators = {
  QU: (dependency, { questions }) => ({
    question: questions[dependency.question],
  }),

  OP: (dependency, { questions, options }) => ({
    question: questions[dependency.question],
    option: options[dependency.question + "/" + dependency.option],
  }),

  SO: (dependency, { questions, options }) => ({
    question: questions[dependency.question + ":sub_options"],
    option:
      options[
        `${dependency.question}/${dependency.option}/${dependency.sub_option}`
      ],
  }),

  DI: (dependency, { divisions }) => ({
    division: divisions[dependency.division],
  }),

  CU: (dependency) => dependency,
};

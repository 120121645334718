import React from "react";
import { isString } from "lodash";

export const HighLightText = ({ value, match }) => {
  if (isString(value) && match && value.includes(match)) {
    const startIndex = value.indexOf(match);
    const endIndex = startIndex + match.length;
    const beforeMatch = value.slice(0, startIndex);
    const afterMatch = value.slice(endIndex);

    return (
      <span>
        {beforeMatch}
        <span className="highlight-text">{match}</span>
        {afterMatch}
      </span>
    );
  }
  return <span>{value}</span>;
};

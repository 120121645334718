import { indexesRoutes } from "routes";
import * as constants from "constants/constants";

export const getSelectedRacksByLevel = (racks, route) => {
  const _route = route || window.location.pathname;
  const slugs = _route.split("/").filter((r) => r.length);
  const isUUID =
    slugs.length &&
    slugs[slugs.length - 1].length >= constants.UUID_CHAR_LENGTH &&
    _route.includes("feedback");

  const UUID = isUUID && slugs.pop().split(constants.QUERY_PARAM_DELIMITER)[0];

  if (slugs.length && indexesRoutes[slugs[0]]) {
    return [null, null, null];
  }

  const level1Slug = getSlugAtLevel(1, { racks }, slugs);
  const level1Rack = getSelectedRack(level1Slug, { racks });

  const level2Slug = getSlugAtLevel(2, level1Rack, slugs);
  const level2Rack = getSelectedRack(level2Slug, level1Rack);

  const level3Slug = getSlugAtLevel(3, level2Rack, slugs);
  const level3Rack = getSelectedRack(level3Slug, level2Rack);

  let path = "";
  if (level1Rack) path += `/${level1Rack.slug}`;
  if (level2Rack) path += `/${level2Rack.slug}`;
  if (level3Rack) path += `/${level3Rack.slug}`;
  if (UUID) path += `/${UUID}`;
  if (window.location.search) path += window.location.search;

  const isUserClick = !!route;
  const historyAction = isUserClick ? "pushState" : "replaceState";
  if (path.length && slugs.length)
    window.history[historyAction](null, null, path);

  return [level3Rack, level2Rack, level1Rack];
};

const getSlugAtLevel = (level, parent, slugs) => {
  const index = level - 1;
  return (
    (slugs.length > index && slugs[index]) ||
    (parent && parent.racks.length > 0 && parent.racks[0].slug)
  );
};

const getSelectedRack = (slug, parentRack) => {
  return (
    (parentRack && slug && parentRack.racks.find((r) => r.slug === slug)) ||
    (parentRack && parentRack.racks[0])
  );
};

import { getRequest } from "utils/request";

export const questionnaireApi = (organizationID, questionaireID) => {
  const url = `/organizations/${organizationID}/questionnaires/${questionaireID}/questions`;
  return getRequest(url);
};

export const closeEndedQuestionResultApi = (questionID) =>
  `dashboard/v1/questions/${questionID}/close-ended-options-distribution`;

export const openEndedQuestionResultApi = (questionID) =>
  `dashboard/v1/questions/${questionID}/open-ended-options-distribution`;

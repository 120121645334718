import { dispatchNotification } from "./utils";

try {
  const channel = new BroadcastChannel("firebase-messaging-sw");
  channel.addEventListener("message", ({ data: payload }) => {
    dispatchNotification(payload);
  });
} catch (err) {
  // console.log("Broadcast Channel not supported in your browser");
}

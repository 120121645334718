import { connect } from "react-redux";

import { loadMedia } from "../actions";

import { Slider } from "./Slider";

const mapStateToProps = (state) => ({
  media: state.dashboard.media,
  selectedFilters: state.selectedFilters,
});

const mapDispatchToProps = {
  loadMedia,
};

export const MediaSlider = connect(mapStateToProps, mapDispatchToProps)(Slider);

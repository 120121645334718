import React from "react";
import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <div
      style={{
        display: "block",
        width: "100%",
        textAlign: "center",
      }}
    >
      <Skeleton width={"60px"} height={"60px"} />
    </div>
  );
};

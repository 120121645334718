import { CUSTOMER_INVITES_SUCCESS } from "constants/constants";
import { getOrganizationSlug } from "utils/genericHelper";
import { getRequest, patchRequest } from "utils/request";

const INVITES_API = "/customers/respondents/list/";
const INVITES_API_PAGE_LIMIT = 12;

const _customerInvitesSuccess = (response) => ({
  type: CUSTOMER_INVITES_SUCCESS,
  customersInvites: {
    customersData: response.results,
    pageCount: Math.ceil(response.count / INVITES_API_PAGE_LIMIT) || 0,
  },
});

const getCustomerInvites = (filtersQuery, selectedPage) => {
  return (dispatch) => {
    const url = `${INVITES_API}?${filtersQuery}&page=${selectedPage || "1"}`;
    return getRequest(url).then((data) =>
      dispatch(_customerInvitesSuccess(data)),
    );
  };
};

const sendReminder = (id, filtersQuery) => () => {
  const url = `customers/${localStorage.getItem(
    `${getOrganizationSlug()}_organizationId`,
  )}/send-invites/${id ? id + "/" : ""}?${filtersQuery}`;

  return getRequest(url);
};

const updateSurveyInvite = (inviteID, data) => {
  const url = `/customers/invite/${inviteID}/`;
  return patchRequest(url, data);
};

export { getCustomerInvites, sendReminder, updateSurveyInvite };

import {
  SUBMIT_FEEDBACK_GROUP_SUCCESS,
  FETCH_ORGANIZATION_FEATURES,
  RESET_ORGANIZATION_FEATURE_SUCCESS,
  SET_ANALYZER_FIELD,
  ADD_ANALYZER,
  SET_ANALYZER_FIELDS_VALUE,
  REMOVE_ANALYZER,
  FETCH_FEEDBACK_GROUP,
  RESET_FEEDBACK_GROUP_ANALYZER,
  FETCH_LEVELS,
  SUBMIT_LEVEL_SUCCESS,
  FETCH_LEVEL,
  UPDATE_ORGANIZATION,
  RESET_UPDATE_ORGANIZATION_SUCCESS,
  RESET_LEVEL,
  STORE_ORGANIZATION_DIVISIONS,
  CHANGE_DIVISIONS_PAGE,
  STORE_ORGANIZATION_LEVELS,
  STORE_POSSIBLE_PARENT_DIVISIONS,
  SUBMIT_DIVISION,
  FETCH_DIVISION,
  RESET_DIVISION,
  STORE_ORGANIZATION_USERS,
  CHANGE_USERS_PAGE,
  STORE_USER_ROLES_ALERT,
  STORE_USER_ROLES,
  STORE_USER_ROLE_DETAILS,
  STORE_USER_DIVISIONS,
  SET_DIVISIONS_LOADING,
  SET_IS_ACTIVE_STATUS_SUCCESS,
  STORE_FETCHED_USER,
  CLEAR_FETCHED_USER,
  SAVE_UPDATE_USER_SUCCESS,
  STORE_PRIMARY_DIVISIONS,
  LOAD_ORGANIZATION_TAGS,
  LOAD_ORGANIZATION_TAGS_SUCCESS,
} from "../constants/constants";
import * as constants from "../constants/constants";
import {
  fetchLevelApi,
  getFeedbackGroup,
  getLevelsApi,
  getOrganizationFeatures,
  submitFeedbackGroupApi,
  submitLevelApi,
  updateOrganizationApi,
  fetchOrganizationDivisionsApi,
  getOrganizationLevelsApi,
  getDivisionsByLevelApi,
  submitDivisionApi,
  fetchDivisionApi,
  getUsersApi,
  getUserRolesApi,
  saveUserRolesApi,
  toggleUserStatus,
  saveUpdateUserApi,
  getUserDetailApi,
  getPrimaryDivisionsApi,
} from "./apis";
import {
  getLevelValueFromLevel,
  transformFeedbackGroupData,
} from "./Organization/helper";

const _alertNonFieldErrors = (err) => {
  const errors =
    (err &&
      err.response &&
      err.response.data &&
      err.response.data.non_field_errors) ||
    null;
  if (errors) {
    const errorMessage = errors.reduce((err1, err2) => err1 + "\n" + err2, "");
    alert(errorMessage);
  }
};

const _setAnalyzerField = (data) => ({
  type: SET_ANALYZER_FIELD,
  payload: data,
});

const _addAnalyzer = () => ({ type: ADD_ANALYZER });

const _setAnalyzerFieldsValue = (data) => ({
  type: SET_ANALYZER_FIELDS_VALUE,
  payload: data,
});

const _removeAnalyzer = (data) => ({ type: REMOVE_ANALYZER, payload: data });

export const getOrganization = () => {
  return {
    type: constants.LOAD_ORGANIZATION,
  };
};

export const getOrganizationSuccess = (organization) => {
  return {
    type: constants.LOAD_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const getOrganizationError = (error) => {
  return {
    type: constants.LOAD_ORGANIZATION_ERROR,
    error,
  };
};

export const fetchOrganizationFeatures = (feedbackGroupId) => {
  return (dispatch) => {
    getOrganizationFeatures()
      .then((resp) => {
        const data = resp.data;
        dispatch({ type: FETCH_ORGANIZATION_FEATURES, data });
        if (feedbackGroupId) {
          dispatch(fetchFeedbackGroup(feedbackGroupId));
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

const fetchFeedbackGroup = (id) => {
  return (dispatch) => {
    getFeedbackGroup(id)
      .then((resp) => {
        const data = transformFeedbackGroupData(resp.data);
        dispatch({ type: FETCH_FEEDBACK_GROUP, data });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const submitFeedbackGroup = (data, feedbackGroupId) => {
  return (dispatch) => {
    submitFeedbackGroupApi(data, feedbackGroupId)
      .then((resp) => {
        const group = resp.data;
        const action = feedbackGroupId ? "Updated" : "Created";
        alert(`FeedbackGroup ${action} Successfully`);
        const data = { feedbackGroupId, group };
        dispatch({ type: SUBMIT_FEEDBACK_GROUP_SUCCESS, data });
      })
      .catch((err) => {
        alert("Form is invalid");
      });
  };
};

export const loadConcerns = () => {
  return {
    type: constants.LOAD_CONCERNS,
  };
};

export const loadConcernsSuccess = (data) => {
  return {
    type: constants.LOAD_CONCERNS_SUCCESS,
    data,
  };
};

export const loadConcernsError = (err) => {
  return {
    type: constants.LOAD_CONCERNS_ERROR,
    err,
  };
};

export const setAnalyzerField = (data) => {
  return (dispatch) => {
    dispatch(_setAnalyzerField(data));
  };
};

export const setAnalyzerFieldsValue = (data) => {
  return (dispatch) => {
    dispatch(_setAnalyzerFieldsValue(data));
  };
};

export const addAnalyzer = (data) => {
  return (dispatch) => {
    dispatch(_addAnalyzer(data));
  };
};

export const removeAnalyzer = (data) => {
  return (dispatch) => {
    dispatch(_removeAnalyzer(data));
  };
};

export const resetFeedbackGroupAnalyzer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_FEEDBACK_GROUP_ANALYZER });
  };
};

export const getLevels = (id) => {
  return (dispatch) => {
    getLevelsApi(id)
      .then((resp) => {
        const data = resp.data;
        dispatch({ type: FETCH_LEVELS, data });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const submitLevel = (data, levelId) => {
  return (dispatch) => {
    submitLevelApi(data, levelId)
      .then((resp) => {
        const action = levelId ? "Updated" : "Created";
        alert(`Level ${action} Successfully`);
        dispatch({ type: SUBMIT_LEVEL_SUCCESS });
      })
      .catch((err) => {
        alert("Form is invalid");
      });
  };
};

export const resetOrganizationFeatureSuccess = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ORGANIZATION_FEATURE_SUCCESS,
    });
  };
};

export const fetchLevel = (organizationId, levelId) => {
  return (dispatch) => {
    fetchLevelApi(organizationId, levelId)
      .then((resp) => {
        const data = resp.data;
        dispatch({ type: FETCH_LEVEL, data });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const updateOrganization = (data) => {
  return (dispatch) => {
    updateOrganizationApi(data)
      .then((resp) => {
        alert("Organization Updated Successfully");
        const { data } = resp;
        dispatch({ type: UPDATE_ORGANIZATION, data });
      })
      .catch((err) => {
        // console.log(err);
      });
  };
};

export const resetUpdateOrganizationSuccess = () => {
  return (dispatch) => {
    dispatch({ type: RESET_UPDATE_ORGANIZATION_SUCCESS });
  };
};

export const resetLevel = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LEVEL });
  };
};

export const fetchOrganizationDivisions = (orgid, currPage = 1) => {
  return (dispatch) => {
    fetchOrganizationDivisionsApi(orgid, currPage)
      .then((resp) => {
        const divisions = resp.data;

        dispatch({
          type: STORE_ORGANIZATION_DIVISIONS,
          payload: { divisions },
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  };
};

export const changeDivisionsPage = (page) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_DIVISIONS_PAGE,
      payload: { page },
    });
  };
};

export const fetchOrganizationLevels = (orgId, level) => {
  return (dispatch) => {
    getOrganizationLevelsApi(orgId).then((resp) => {
      const levels = resp.data;
      dispatch({
        type: STORE_ORGANIZATION_LEVELS,
        payload: { levels },
      });

      const levelValue = level ? getLevelValueFromLevel(levels, level) - 1 : 0;
      dispatch(fetchPossibleParentDivisions(orgId, levelValue));
    });
  };
};

export const fetchPossibleParentDivisions = (orgId, level) => {
  return (dispatch) => {
    getDivisionsByLevelApi(orgId, level).then((resp) => {
      const possibleParentDivisions = resp.data;

      dispatch({
        type: STORE_POSSIBLE_PARENT_DIVISIONS,
        payload: { possibleParentDivisions },
      });
    });
  };
};

export const submitDivision = (data, divisionId) => {
  return (dispatch) => {
    submitDivisionApi(data, divisionId)
      .then((resp) => {
        const action = divisionId ? "Updated" : "Created";
        alert(`Division ${action} Successfully`);
        const division = resp.data;

        divisionId = divisionId || division.id;

        dispatch({
          type: SUBMIT_DIVISION,
          payload: { division, divisionId },
        });
      })
      .catch((err) => alert("Form is invalid"));
  };
};

export const fetchDivision = (data) => {
  return (dispatch) => {
    fetchDivisionApi(data).then((resp) => {
      const division = resp.data;
      dispatch({
        type: FETCH_DIVISION,
        payload: { division },
      });
    });
  };
};

export const resetDivision = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DIVISION });
  };
};

export const getUsers = (orgId, currpage = 1, query = "") => {
  return (dispatch) => {
    getUsersApi(orgId, currpage, query).then((resp) => {
      dispatch({
        type: STORE_ORGANIZATION_USERS,
        payload: { users: resp.data },
      });
    });
  };
};

export const changeUsersPage = (page) => {
  return {
    type: CHANGE_USERS_PAGE,
    payload: { page },
  };
};

export const setDivisionsLoading = () => {
  return (dispatch) => {
    dispatch({ type: SET_DIVISIONS_LOADING });
  };
};

export const getDivisionsByOrganization = (
  orgId,
  appendMode = false,
  page = 1,
  search = "",
) => {
  return (dispatch) => {
    getDivisionsByLevelApi(orgId, "", page, search).then((resp) => {
      dispatch({
        type: STORE_USER_DIVISIONS,
        payload: { divisions: resp.data, appendMode },
      });
    });
  };
};

export const getPrimaryDivisions = (orgId) => {
  return (dispatch) => {
    getPrimaryDivisionsApi(orgId).then((resp) => {
      dispatch({
        type: STORE_PRIMARY_DIVISIONS,
        payload: { primaryDivisions: resp.data },
      });
    });
  };
};

export const userRolesAlert = (type, message) => {
  return {
    type: STORE_USER_ROLES_ALERT,
    payload: { type, message },
  };
};

export const setUserRolesAlert = (type = "", message = "") => {
  return (dispatch) => {
    dispatch(userRolesAlert(type, message));
  };
};

export const getUserRoles = (roleId = null) => {
  return (dispatch) => {
    getUserRolesApi(roleId)
      .then((resp) => {
        const type = roleId ? STORE_USER_ROLE_DETAILS : STORE_USER_ROLES;
        dispatch({
          type,
          payload: resp.data,
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  };
};

export const saveUserRoles = (roleId, data) => {
  return (dispatch) => {
    return saveUserRolesApi(roleId, data)
      .then((resp) => {
        switch (resp.status) {
          case 200:
            dispatch(
              userRolesAlert(
                "success",
                "User Role has been successfully updated.",
              ),
            );
            return { status: true };
          case 201:
            dispatch(
              userRolesAlert(
                "success",
                "User Role has been successfully updated.",
              ),
            );
            return { status: true };
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          return { status: false, error: err.response.data };
        }
        return { status: false };
      });
  };
};

export const setIsActiveOfUser = (orgId, userId) => {
  return (dispatch) => {
    toggleUserStatus(userId, orgId)
      .then((resp) => {
        dispatch({
          type: SET_IS_ACTIVE_STATUS_SUCCESS,
          payload: userId,
        });
      })
      .catch((err) => _alertNonFieldErrors(err));
  };
};

export const saveUpdateUser = (data, orgId, isEditPage, userId = "") => {
  return (dispatch) => {
    saveUpdateUserApi(userId, orgId, data, isEditPage)
      .then((resp) => {
        alert("Form submitted successfully");
        dispatch({
          type: SAVE_UPDATE_USER_SUCCESS,
        });
      })
      .catch((err) => _alertNonFieldErrors(err));
  };
};

export const getUserDetail = (userId, orgId) => {
  return (dispatch) => {
    getUserDetailApi(userId, orgId)
      .then((resp) => {
        dispatch({
          type: STORE_FETCHED_USER,
          payload: { user: resp.data },
        });
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  };
};

export const clearFetchedUser = () => {
  return {
    type: CLEAR_FETCHED_USER,
  };
};

export const loadOrganizationTags = () => {
  return {
    type: LOAD_ORGANIZATION_TAGS,
  };
};

export const loadOrganizationTagsSuccess = (tags) => {
  return {
    type: LOAD_ORGANIZATION_TAGS_SUCCESS,
    payload: tags,
  };
};

export const createOrganizationTag = (tag, feedback) => {
  return {
    type: constants.CREATE_ORGANIZATION_TAG,
    payload: { tag: { label: tag }, feedback },
  };
};

export const createOrganizationTagSuccess = (tag, feedback) => {
  return {
    type: constants.CREATE_ORGANIZATION_TAG_SUCCESS,
    payload: { tag, feedback },
  };
};

export const createOrganizationTagError = (feedback) => {
  return {
    type: constants.CREATE_ORGANIZATION_TAG_ERROR,
    payload: { feedback },
  };
};

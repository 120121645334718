import { differenceInSeconds } from "date-fns";

const initialState = {
  overallTime: {
    activeStartTime: null,
    activeTimeDiff: 0,
    idleStartTime: null,
    idleTimeDiff: null,
  },
  pageTime: {
    activeStartTime: null,
    activeTimeDiff: 0,
  },
};

export const setOverallActiveStartTime = () => {
  if (document.visibilityState === "visible") {
    initialState.overallTime.activeStartTime = new Date();
  }
};

export const setPageStartTime = (page) => {
  if (document.visibilityState === "visible") {
    initialState.pageTime.activeStartTime = new Date();
  }
  if (page) {
    initialState.pageTime.page = page;
  }
};

export const calculateOverallTimeDelta = () => {
  if (initialState.overallTime.activeStartTime) {
    initialState.overallTime.activeTimeDiff =
      initialState.overallTime.activeTimeDiff +
      differenceInSeconds(new Date(), initialState.overallTime.activeStartTime);
  }
  return initialState.overallTime;
};

export const calculatePageTimeDelta = () => {
  if (initialState.pageTime.activeStartTime) {
    initialState.pageTime.activeTimeDiff =
      initialState.pageTime.activeTimeDiff +
      differenceInSeconds(new Date(), initialState.pageTime.activeStartTime);
  }
  return initialState.pageTime;
};

export const getOverallTimeDelta = () => {
  return initialState.overallTime;
};

export const getPageTimeDelta = () => {
  return initialState.pageTime;
};

export const telemetry = {
  setPageStartTime,
  setOverallActiveStartTime,
  calculateOverallTimeDelta,
  calculatePageTimeDelta,
};

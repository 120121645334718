import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import * as constants from "../../constants/constants";
import * as actions from "./actions";
import * as parsers from "./parsers";
import * as apis from "./apis";
import { getOrganizationId, selectOrganization } from "../Organization/selectors";
import { getFiltersQueryString } from "../common/filters/selectors";
import { getPermissions } from "../LoginPage/selectors";
import axios from "axios";

export function* storeDashboards(dashboards) {
  if (!dashboards[0].id) {
    dashboards[0].id = "omni";
  }

  if (dashboards.length === 2 && dashboards[0].id === "omni") {
    dashboards = dashboards.slice(1);
  }

  const _dashboards = dashboards.map((dashboard) => {
    const racks = dashboard.charts.map((r) =>
      parsers.rackParser(r, "", dashboard.id),
    );
    return { ...dashboard, racks };
  });

  yield put(actions.storeDashboards(_dashboards));

  const state = yield select();
  const selectedQuestionnaires = Object.keys(
    state.selectedFilters.questionnaires || {},
  );

  const selectedQuestionnaire =
    selectedQuestionnaires.length === 1
      ? parseInt(selectedQuestionnaires[0])
      : null;

  const dashboard =
    _dashboards.find((d) => d.id === selectedQuestionnaire) || _dashboards[0];
  yield put(actions.selectDashboard(dashboard));
}

function* loadAnalyzerData(action) {
  const analyzer = action.payload;
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);

    let response = null;

    if (analyzer.context === "static") {
      response = { value: analyzer.value };
    } else {
      response = yield call(
        apis.analyzerApi,
        analyzer,
        organizationId,
        filters,
      );
    }

    const data = parsers.analyzerResponseParser(response, analyzer);
    yield put(actions.loadAnalyzerDataSuccess(analyzer.slug, data));
  } catch (err) {
    if (axios.isCancel(err)) return;
    if (err.response?.status === 404) {
      yield put(
        actions.loadAnalyzerDataSuccess(analyzer.slug, { noData: true }),
      );
      return;
    }

    const error = parsers.analyzerErrorParser(err, analyzer);
    console.error("NETWORK CALL ERROR", err);
    yield put(actions.loadAnalyzerDataError(analyzer.slug, error));
  }
}

function* analyzerWatcher() {
  yield takeEvery(constants.LOAD_ANALYZER_DATA, loadAnalyzerData);
}

function* loadMoreAnalyzerData(action) {
  const { analyzer, nextURL } = action.payload;
  try {
    const response = yield call(apis.analyzerNextApi, nextURL);
    const data = parsers.analyzerResponseParser(response, analyzer);
    yield put(actions.loadMoreAnalyzerDataSuccess(analyzer, data));
  } catch (err) {
    const error = parsers.analyzerErrorParser(err, analyzer);
    yield put(actions.loadMoreAnalyzerDataError(analyzer, error));
  }
}

function* analyzerLazyLoadingWatcher() {
  yield takeLeading(constants.LOAD_MORE_ANALYZER_DATA, loadMoreAnalyzerData);
}

function* loadInsightConcerns(action) {
  const parentId = action.payload && action.payload.concern.id;
  const onSuccess = parentId
    ? actions.loadInsightChildConcernsSuccess
    : actions.loadInsightConcernsSuccess;
  try {
    const organizationId = yield select(getOrganizationId);
    const filters = yield select(getFiltersQueryString);
    let concerns = yield call(
      apis.insightConcernsApi,
      organizationId,
      filters,
      parentId,
    );
    if (parentId && concerns.length) {
      concerns = [
        { ...action.payload.concern, name: "All", hideType: true },
        ...concerns,
      ];
    }
    yield put(onSuccess(concerns, parentId));

    if (concerns.length) {
      const selectConcern = parentId
        ? actions.selectInsightChildConcern
        : actions.selectInsightConcern;

      yield put(selectConcern(concerns[0]));
    } else if (!parentId) {
      yield put(actions.resetSelectedConcern());
    }
  } catch (error) {
    yield put(actions.loadInsightConcernsError(error));
  }
}

function* selectInsightConcern(action) {
  yield loadInsightConcerns(action);
}

function* loadInsightConcernDetail(action) {
  const concern = action.payload.concern;
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    let detail = yield call(
      apis.insightConcernDetailApi,
      organizationId,
      filters,
    );
    yield put(actions.loadInsightConcernDetailSuccess(concern, detail));
  } catch (error) {
    yield put(actions.loadInsightConcernDetailError(concern, error));
  }
}

function* insightConcernsWatcher() {
  yield takeLatest(constants.LOAD_INSIGHT_CONCERNS, loadInsightConcerns);
}

function* selectInsightConcernsWatcher() {
  yield takeLatest(constants.SELECT_INSIGHT_CONCERN, selectInsightConcern);
}

function* insightConcernDetailWatcher() {
  yield takeLatest(
    constants.LOAD_INSIGHT_CONCERN_DETAIL,
    loadInsightConcernDetail,
  );
}

function* loadConcernImpactOnScore() {
  try {
    const filters = yield select(getFiltersQueryString);
    const orgId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.insightConcernImpactOnScoreApi,
      orgId,
      filters,
    );
    yield put(actions.loadConcernImpactOnScoreSuccess(apiData.score));
  } catch (error) {
    yield put(actions.loadConcernImpactOnScoreFailure(error));
  }
}

function* concernImpactOnScoreWatcher() {
  yield takeLatest(
    constants.LOAD_CONCERN_IMPACT_ON_SCORE,
    loadConcernImpactOnScore,
  );
}

function* loadSurveyQuestions(action) {
  const questionnaireId = action.payload.questionnaireId;
  const organization = yield select(selectOrganization);
  const organizationId = organization.id;
  try {
    let questions = null;
    if (questionnaireId === "omni") {
      questions = yield call(
        apis.omniViewQuestionFilterQuestions,
        organizationId,
        organization.features.dashboard.omni_view_question_filter_ids,
      );
    }
    else {
      questions = yield call(
        apis.surveyQuestionsApi,
        organizationId,
        questionnaireId,
      );
    }
    yield put(actions.loadSurveyQuestionsSuccess(questionnaireId, questions));
  } catch (error) {
    yield put(actions.loadSurveyQuestionsError());
  }
}

function* surveyQuestionsWatcher() {
  yield takeLatest(constants.LOAD_SURVEY_QUESTIONS, loadSurveyQuestions);
}

function* loadWordCloud() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.wordCloudApi, organizationId, filters);
    yield put(actions.loadWordCloudSuccess(apiData));
  } catch (error) {
    yield put(actions.loadWordCloudError(error));
  }
}

function* wordCloudWatcher() {
  yield takeLatest(constants.LOAD_WORDCLOUD, loadWordCloud);
}

function* loadProjectsOverview({ analyzer }) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.projectsOverviewApi,
      organizationId,
      `${analyzer.query || ""}&${filters}`,
    );
    yield put(actions.loadProjectsOverviewSuccess(apiData));
  } catch (error) {
    yield put(actions.loadProjectsOverviewError(error));
  }
}

function* projectsOverviewWatcher() {
  yield takeLatest(constants.LOAD_PROJECTS_OVERVIEW, loadProjectsOverview);
}

function* loadMedia() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.mediaApi, organizationId, filters);
    yield put(actions.loadMediaSuccess(apiData));
    yield put(actions.clearMediaError());
  } catch (error) {
    yield put(actions.loadMediaError(error));
  }
}

function* mediaWatcher() {
  yield takeLatest(constants.LOAD_MEDIA, loadMedia);
}

function* loadComments() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.commentsApi, organizationId, filters);
    yield put(actions.loadCommentsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadCommentsError(error));
  }
}

function* commentsWatcher() {
  yield takeLatest(constants.LOAD_COMMENTS, loadComments);
}

function* loadMoreComments(action) {
  try {
    const apiData = yield call(apis.moreCommentsApi, action.nextUrl);
    yield put(actions.loadMoreCommentsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadMoreCommentsError(error));
  }
}

function* moreCommentsWatcher() {
  yield takeLatest(constants.LOAD_MORE_COMMENTS, loadMoreComments);
}

function* loadTimeSeries() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const userPermissions = yield select(getPermissions);
    const apiData = yield call(apis.timeSeriesApi, organizationId, filters);
    let successData = {
      userPermissions,
      apiData,
    };
    yield put(actions.loadTimeSeriesSuccess(successData));
  } catch (error) {
    yield put(actions.loadTimeSeriesError(error));
  }
}

function* timeseriesWatcher() {
  yield takeLatest(constants.LOAD_TIME_SERIES, loadTimeSeries);
}

function* loadTopConcerns(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.topConcernsApi,
      organizationId,
      filters,
      action.analysisType,
    );
    yield put(actions.loadTopConcernsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadTopConcernsError(error));
  }
}

function* topConcernsWatcher() {
  yield takeLatest(constants.LOAD_TOP_CONCERNS, loadTopConcerns);
}

function* loadSemanticAnalaysis() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.SemanticAnalysisApi,
      organizationId,
      filters,
    );
    yield put(actions.loadSemanticAnalysisSuccess(apiData));
  } catch (error) {
    yield put(actions.loadSemanticAnalysisError(error));
  }
}

function* semanticAnalysisWatcher() {
  yield takeLatest(constants.LOAD_SEMANTIC_ANALYSIS, loadSemanticAnalaysis);
}

function* loadSatisfactionLevel() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.satisfactionApi, organizationId, filters);
    yield put(actions.loadSatisfactionLevelSuccess(apiData));
  } catch (error) {
    yield put(actions.loadSatisfactionLevelError(error));
  }
}

function* satisfactionLevelWatcher() {
  yield takeLatest(constants.LOAD_SATISFACTION_LEVEL, loadSatisfactionLevel);
}

function* loadPriorityList(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.priorityListApi,
      organizationId,
      filters,
      action.analysisType,
    );
    yield put(actions.loadPriorityListSuccess(apiData));
  } catch (error) {
    yield put(actions.loadPriorityListError(error));
  }
}

function* priorityListWatcher() {
  yield takeLatest(constants.LOAD_PRIORITY_LIST, loadPriorityList);
}

function* loadNPS() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.npsApi, organizationId, filters);
    yield put(actions.loadNPSSuccess(apiData));
  } catch (error) {
    yield put(actions.loadNPSError(error));
  }
}

function* NPSWatcher() {
  yield takeLatest(constants.LOAD_NPS, loadNPS);
}

function* loadGenderGroupAnalysis(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const selectedConcern = action.selectedConcern;
    const apiData = yield call(
      apis.genderGroupAnalysisApi,
      organizationId,
      filters,
      selectedConcern && selectedConcern.id,
    );
    yield put(actions.loadGenderGroupAnalysisSuccess(apiData));
  } catch (error) {
    yield put(actions.loadGenderGroupAnalysisError(error));
  }
}

function* genderGroupAnalysisWatcher() {
  yield takeLatest(
    constants.LOAD_GENDER_GROUP_ANALYSIS,
    loadGenderGroupAnalysis,
  );
}

function* loadDataHealth() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.dataHealthApi, organizationId, filters);
    yield put(actions.loadDataHealthSuccess(apiData));
  } catch (error) {
    yield put(actions.loadDataHealthError(error));
  }
}

function* dataHealthWatcher() {
  yield takeLatest(constants.LOAD_DATA_HEALTH, loadDataHealth);
}

function* loadTrueScore() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.trueScoreApi, organizationId, filters);
    yield put(actions.loadTrueScoreSuccess(apiData));
  } catch (error) {
    yield put(actions.loadTrueScoreError(error));
  }
}

function* trueScoreWatcher() {
  yield takeLatest(constants.LOAD_TRUE_SCORE, loadTrueScore);
}

function* loadCustomerSegmentation(action) {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const selectedConcern = action.selectedConcern;
    const apiData = yield call(
      apis.customerSegmentationApi,
      organizationId,
      filters,
      selectedConcern.id,
    );
    yield put(actions.loadCustomerSegmentationSuccess(apiData));
  } catch (error) {
    yield put(actions.loadCustomerSegmentationError(error));
  }
}

function* customerSegmentationWatcher() {
  yield takeLatest(
    constants.LOAD_CUSTOMER_SEGMENTATION,
    loadCustomerSegmentation,
  );
}

function* loadCompetitorAnalysis() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.competitorAnalysisApi,
      organizationId,
      filters,
    );
    yield put(actions.loadCompetitorAnalysisSuccess(apiData));
  } catch (error) {
    yield put(actions.loadCompetitorAnalysisError(error));
  }
}

function* competitorAnalysisWatcher() {
  yield takeLatest(constants.LOAD_COMPETITOR_ANALYSIS, loadCompetitorAnalysis);
}

function* loadBenchmarks() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.benchmarkApi, organizationId, filters);
    yield put(actions.loadBenchmarksSuccess(apiData));
  } catch (error) {
    yield put(actions.loadBenchmarksError(error));
  }
}

function* benchmarksWatcher() {
  yield takeLatest(constants.LOAD_BENCHMARKS, loadBenchmarks);
}

function* loadSegmentAnalysis() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(
      apis.segmentAnalysisApi,
      organizationId,
      filters,
    );
    yield put(actions.loadSegmentAnalysisSuccess(apiData));
  } catch (error) {
    yield put(actions.loadSegmentAnalysisError(error));
  }
}

function* segmentAnalysisWatcher() {
  yield takeLatest(constants.LOAD_SEGMENT_ANALYSIS, loadSegmentAnalysis);
}

function* loadInviteStats() {
  try {
    const filters = yield select(getFiltersQueryString);
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.getInviteStatsApi, organizationId, filters);
    yield put(actions.loadInviteStatsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadInviteStatsError(error));
  }
}

function* inviteStatsWatcher() {
  yield takeLatest(constants.LOAD_INVITE_STATS, loadInviteStats);
}

export const sagas = [
  analyzerWatcher,
  analyzerLazyLoadingWatcher,
  insightConcernsWatcher,
  selectInsightConcernsWatcher,
  insightConcernDetailWatcher,
  concernImpactOnScoreWatcher,
  surveyQuestionsWatcher,
  wordCloudWatcher,
  projectsOverviewWatcher,
  mediaWatcher,
  commentsWatcher,
  moreCommentsWatcher,
  timeseriesWatcher,
  topConcernsWatcher,
  semanticAnalysisWatcher,
  satisfactionLevelWatcher,
  priorityListWatcher,
  NPSWatcher,
  genderGroupAnalysisWatcher,
  dataHealthWatcher,
  trueScoreWatcher,
  customerSegmentationWatcher,
  competitorAnalysisWatcher,
  benchmarksWatcher,
  segmentAnalysisWatcher,
  inviteStatsWatcher,
];

import React from "react";
import { useSelector } from "react-redux";

import Form from "./Form";
import ProjectList from "./ProjectList";

import { RCAViewSelector } from "./selectors";

const RCA = () => {
  const selectedView = useSelector(RCAViewSelector);
  const SelectedView = views[selectedView];
  return <SelectedView />;
};

const views = {
  form: Form,
  list: ProjectList,
};

export default RCA;

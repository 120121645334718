import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { Wrapper, Label } from "./styled";

import { setRCAOptionsFieldValue, loadUsers } from "components/RCA/actions";
import { formFieldValueSelector } from "components/RCA/selectors";

const UserField = ({ field }) => {
  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);

  const ref = useRef();

  const teamMembers =
    useSelector((state) => formFieldValueSelector(state, "team_members")) || [];
  const teamLead =
    useSelector((state) => formFieldValueSelector(state, "team_lead")) || null;
  const owner = useSelector((state) => formFieldValueSelector(state, "owner"));

  const allUsers = [...teamMembers, teamLead, owner].filter(
    (user, index, self) =>
      user &&
      index ===
        self.findIndex(
          (u) => u.full_name === user.full_name && u.id === user.id,
        ),
  );

  const selectedUser =
    useSelector((state) => formFieldValueSelector(state, field.key)) || null;

  const selectUser = (user) =>
    dispatch(
      setRCAOptionsFieldValue(field.id, {
        full_name: user.label,
        id: user.value,
      }),
    );

  useEffect(() => {
    dispatch(loadUsers());
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const userOptions = allUsers.map((user) => {
    return {
      label: user.full_name,
      value: user.id,
    };
  });

  const defaultValue = userOptions.find(
    (user) => user.value == selectedUser.id,
  );

  return (
    <Wrapper ref={ref} onClick={() => setOpen(!isOpen)}>
      <Label htmlFor={field.key}>{field.name}</Label>
      <Select
        value={defaultValue}
        name={field.name}
        options={userOptions}
        classNamePrefix="select"
        onChange={selectUser}
        placeholder={field.placeholder}
      />
    </Wrapper>
  );
};

export default UserField;

const initialState = {
  allTeamLeads: [],
  allTeamMembers: [],
  allLevels: [],
  allUsers: [],
  allConcerns: [],
  mode: "VIEW",
  selectedView: "list",
  locationMatchError: "",
  currPage: 1,
  totalProjects: 0,
  projectList: {
    data: null,
    loading: false,
    error: false,
  },
  completeSections: [],
  selectedProject: {
    id: null,
    form: { data: {}, loading: false, error: false },
    values: {
      loading: false,
      error: false,
      data: {
        rca_template: null,
        project_name: null,
        owner: null,
        concern: null,
        other_concern: null,
        concern_reason: null,
        concern_current_state: null,
        concern_desired_state: null,
        division: null,
        team_lead: null,
        team_members: null,
        root_causes: null,
        status: null,
        adjustment_user: null,
        check_result_user: null,
        project_start_date: null,
        deployment_start_date: null,
        deployment_end_date: null,
        rca_options: {},
      },
    },
  },
};

export default initialState;

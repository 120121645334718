import { call, takeLatest, select, put } from "redux-saga/effects";
import * as actions from "./actions";
import * as apis from "./apis";
import * as constants from "../../../constants/constants";
import { getOrganizationId } from "../../selectors";
import { getFiltersQueryString } from "../../common/filters/selectors";

function* getConcernDetail(action) {
  try {
    const orgId = yield select(getOrganizationId);
    const filters = yield select(getFiltersQueryString);
    const apiData = yield call(
      apis.ConcernDetailApi,
      action.concernId,
      orgId,
      filters,
    );
    yield put(actions.successConcernDetail(apiData));
  } catch (error) {
    // console.log(error);
  }
}

function* concernDetailWatcher() {
  yield takeLatest(constants.FETCHING_KPI_DETAIL, getConcernDetail);
}

function* getInsights() {
  try {
    const orgId = yield select(getOrganizationId);
    const filters = yield select(getFiltersQueryString);
    const apiData = yield call(apis.getConcernListApi, orgId, filters);
    yield put(actions.successInsights(apiData));
    yield put(actions.updateSelectedKpi(apiData[0]));
    yield put(actions.fetchConcernDetail(apiData[0].id));
  } catch (error) {
    yield put(actions.failureInsight(error));
  }
}

function* insightsWatcher() {
  yield takeLatest(constants.FETCHING_INSIGHTS, getInsights);
}

export const sagas = [insightsWatcher, concernDetailWatcher];

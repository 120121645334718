import * as constants from "constants/constants";
import { DEFAULT_GROUP } from "./constants";

export const loadAuth = (params) => ({ type: constants.LOAD_AUTH, params });

export const loadAuthError = (error) => ({
  type: constants.LOAD_AUTH_ERROR,
  error,
});

export const loadAuthSuccess = (user) => ({
  type: constants.LOAD_AUTH_SUCCESS,
  user,
});

export const updateUserLastHeartbeat = () => ({
  type: constants.UPDATE_USER_LAST_HEARTBEAT,
});

export const loadUserInfo = () => ({
  type: constants.LOAD_USER_INFO,
});

export const loadUserInfoSuccess = (user) => ({
  type: constants.LOAD_USER_INFO_SUCCESS,
  user,
});

export const loadUserInfoError = (error) => ({
  type: constants.LOAD_USER_INFO_ERROR,
  error,
});

export const setDefaultGroup = (user) => ({
  type: DEFAULT_GROUP,
  user,
});

export const storeGroups = (groups) => ({
  type: constants.STORE_GROUPS,
  payload: groups,
});

export const setLanguagesList = (languages) => ({
  type: constants.SET_LANGUAGES_LIST,
  payload: languages,
});

export const unauthRequest = () => {
  return {
    type: constants.UNAUTH_USER_REQUEST,
  };
};

export const unauthUser = () => ({
  type: constants.UNAUTH_USER,
});

export const updateFirebaseToken = (firebaseToken) => ({
  type: constants.UPDATE_USER_FIREBASE_TOKEN,
  firebaseToken,
});

import React, { Component } from "react";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import Spinner from "react-spinkit";
import numeral from "numeral";

import { loadInsightConcernDetail } from "components/DashboardPage/actions";

import { Panel } from "components/common/Panel";
import { getKPITrendChartData } from "utils/chartsData";
import { TOOLTIP_MESSAGE } from "constants/constants";
import { NoDataFound } from "components/common/NoDataFound";
import { TooltipText } from "components/common/Tooltip";
import ConcernTrendHeader from "./ConcernTrendHeader";
import {
  getConcernList,
  getConcernDetail,
  getSelectedConcern,
  isLoadingConcernDetail,
  isErrorConcernDetail,
} from "./selectors";

class ConcernTrend extends Component {
  componentDidMount = () => {
    this.props.selectedConcern.id &&
      this.props.loadInsightConcernDetail(this.props.selectedConcern);
  };

  componentDidUpdate = (prevProps) => {
    if (
      (this.props.selectedConcern.id &&
        this.props.selectedConcern.id !== prevProps.selectedConcern.id) ||
      this.props.selectedLevel !== prevProps.selectedLevel
    )
      this.props.loadInsightConcernDetail(this.props.selectedConcern);
  };

  isLoading = () => {
    const { loading, selectedConcern, concernList } = this.props;
    return loading || (concernList.length && !selectedConcern.id);
  };

  render = () => {
    const { concernDetail, analyzer } = this.props;

    return (
      <Panel
        className="line-chart"
        header={
          <span className="insight-line-chart-container">
            <ConcernTrendHeader analyzer={analyzer} />
          </span>
        }
      >
        {this.isLoading() ? (
          <Spinner name="wandering-cubes" fadeIn="none" />
        ) : !concernDetail || this.props.error ? (
          <NoDataFound />
        ) : (
          <div className="line-chart-body">
            <Line
              data={getKPITrendChartData(concernDetail.trend)}
              height={200}
              options={options}
            />
          </div>
        )}
      </Panel>
    );
  };

  getLineChartHeader = () => {
    const { analyzer, concernDetail, selectedConcern } = this.props;
    return (
      <div className="line-header" style={{ marginTop: 0 }}>
        {!analyzer.hide_impact && (
          <div className="line-header-nps">
            <span className="title tooltipRel">
              IMPACT ON SCORE
              <TooltipText tooltipText={TOOLTIP_MESSAGE.IMPACT_ON_SCORE} />
            </span>
            <span style={{ fontSize: 26, fontWeight: "bold" }}>
              {concernDetail?.score ?? "--"}
            </span>
          </div>
        )}
        <div className="line-header-complaints">
          <span className="title tooltipRel">
            PERCENTAGE
            <TooltipText tooltipText={TOOLTIP_MESSAGE.PERCENTAGE} />
          </span>
          <span
            title={numeral(selectedConcern?.count).format("0,0")}
            style={{ fontSize: 26, fontWeight: "bold" }}
          >
            {selectedConcern?.percentage ?? "--"}
          </span>
        </div>
      </div>
    );
  };
}

const mapDispatchToProps = {
  loadInsightConcernDetail,
};

const mapStateToProps = (state) => {
  return {
    loading: isLoadingConcernDetail(state),
    error: isErrorConcernDetail(state),
    selectedLevel: state.dashboard.insights.feedback_level,
    selectedConcern: getSelectedConcern(state),
    concernList: getConcernList(state),
    concernDetail: getConcernDetail(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConcernTrend);

const options = {
  responsive: true,
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  tooltip: {
    callbacks: {
      title: (items, data) => {
        return data.labels[items[0].index];
      },
      label: (item, data) => {
        const value = data.datasets[item.datasetIndex].data[item.index];
        return `Percentage: ${numeral(value).format("0,0.0")}%`;
      },
    },
  },
  scales: {
    y: [
      {
        grid: {
          display: true,
          drawBorder: false,
        },
        beginAtZero: true,
        suggestedMax: 100,
        ticks: {},
        title: {
          display: true,
          text: "Percentage",
        },
      },
    ],
    x: [
      {
        grid: {
          display: false,
        },
        beginAtZero: true,
        maxTicksLimit: 12,
        ticks: {},
        title: {
          display: true,
          text: "Date",
        },
      },
    ],
  },
  elements: {
    line: {
      tension: 0,
      borderWidth: 2,
    },
    point: {
      radius: 2,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
  },
};

import React from "react";
import { useSelector } from "react-redux";

import { ComponentWrapper } from "./styled";

import { formFieldValueSelector } from "components/RCA/selectors";

import { FloatingLabel } from "components/common/FloatingLabel";

const DateField = ({ field }) => {
  const fieldKey = field.field_type.toLowerCase();

  const selectedStartDate =
    useSelector((state) => formFieldValueSelector(state, fieldKey)) || null;

  return (
    <ComponentWrapper>
      <FloatingLabel
        id={fieldKey}
        label={field.name}
        placeholder={field.placeholder}
        value={selectedStartDate || ""}
        readOnly={true}
      />
    </ComponentWrapper>
  );
};

export default DateField;

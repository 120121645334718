import React, { useState, useEffect, useRef } from "react";
import { DateRange } from "react-date-range";
import { useTranslation } from "react-i18next";
import { useDispatch, connect } from "react-redux";
import {
  ComponentWrapper,
  DateWrapper,
  ButtonWrapper,
  ApplyButton,
  CancelButton,
} from "./styled";

import { format } from "date-fns";

import { setFormFieldValue } from "components/RCA/actions";
import { formFieldValueSelector } from "components/RCA/selectors";

import { FloatingLabel } from "components/common/FloatingLabel";

const DeploymentDatesField = ({
  field,
  selectedStartDate,
  selectedEndDate,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const fieldKey = field.field_type.toLowerCase();

  const [isOpen, setOpen] = useState(false);

  const ref = useRef();

  const [dates, setDates] = useState([
    {
      startDate: selectedStartDate ? new Date(selectedStartDate) : new Date(),
      endDate: selectedEndDate ? new Date(selectedEndDate) : new Date(),
      key: "selection",
    },
  ]);

  const setDatesInForm = () => {
    dispatch(
      setFormFieldValue(
        "deployment_start_date",
        format(dates[0].startDate, "yyyy-MM-dd"),
      ),
    );
    dispatch(
      setFormFieldValue(
        "deployment_end_date",
        format(dates[0].endDate, "yyyy-MM-dd"),
      ),
    );
    setOpen(false);
  };

  useEffect(() => {
    setDates([
      {
        startDate: selectedStartDate ? new Date(selectedStartDate) : new Date(),
        endDate: selectedEndDate ? new Date(selectedEndDate) : new Date(),
        key: "selection",
      },
    ]);
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, [selectedEndDate, selectedStartDate]);

  return (
    <ComponentWrapper ref={ref}>
      <div onClick={() => setOpen(!isOpen)}>
        <FloatingLabel
          id={fieldKey}
          label={field.name}
          value={
            selectedStartDate
              ? selectedStartDate + " to " + selectedEndDate
              : ""
          }
          placeholder={field.name}
          readOnly={true}
          width="400px"
        />
      </div>

      {isOpen && (
        <DateWrapper className="dateRangePicker">
          <DateRange
            months={2}
            direction="horizontal"
            editableDateInputs={false}
            onChange={(item) => setDates([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={dates}
          />
          <ButtonWrapper>
            <CancelButton onClick={() => setOpen(false)}>
              {t("cancel")}
            </CancelButton>
            <ApplyButton onClick={setDatesInForm}>{t("apply")}</ApplyButton>
          </ButtonWrapper>
        </DateWrapper>
      )}
    </ComponentWrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedStartDate:
    formFieldValueSelector(state, "deployment_start_date") || null,
  selectedEndDate: formFieldValueSelector(state, "deployment_end_date") || null,
});

export default connect(mapStateToProps)(DeploymentDatesField);

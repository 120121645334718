import {
  CLEAR_SELECTED_FILTERS,
  SEARCH_QUERY,
  SET_FILTER,
  CLEAR_LOCATIONS,
} from "constants/constants";
import * as constants from "constants/constants";

const setSearchQuery = (search) => ({
  type: SEARCH_QUERY,
  search,
});

export const loadDivisions = () => ({
  type: constants.LOAD_DIVISIONS,
});

export const loadDivisionsSuccess = (divisions) => ({
  type: constants.LOAD_DIVISIONS_SUCCESS,
  divisions,
});

export const loadDivisionsError = (error) => ({
  type: constants.LOAD_DIVISIONS_ERROR,
  error,
});

const clearFilters = (isOmni, startDate, endDate) => ({
  type: CLEAR_SELECTED_FILTERS,
  payload: { isOmni, startDate, endDate },
});

const setFilter = (filter) => ({
  type: SET_FILTER,
  filter,
});

export const setFeedbackFilter = (filterType, filter) => ({
  type: constants.SET_FEEDBACK_FILTER,
  payload: { filterType, filter },
});

const setAnalysisType = (analysisType) => ({
  type: constants.SET_ANALYSIS_TYPE,
  analysisType,
});

const clearLocationsFilter = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOCATIONS });
  };
};

export {
  setFilter,
  setSearchQuery,
  clearFilters,
  clearLocationsFilter,
  setAnalysisType,
};

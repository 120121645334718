import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import { withTranslation } from "react-i18next";

import { loadTopConcerns, setTopConcernsAnalysis } from "../actions";
import TopConcernsStats from "./TopConcernsStats";
import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import { Panel } from "../../common/Panel";

class TopConcerns extends Component {
  constructor(props) {
    super(props);

    this.getApiData = this.getApiData.bind(this);
  }

  getApiData() {
    const { loadTopConcerns, topConcerns } = this.props;
    loadTopConcerns(topConcerns.analysisType);
  }

  componentDidMount() {
    this.getApiData();
  }

  componentDidUpdate(prevProps) {
    let prevSelectedFilters = prevProps.selectedFilters;
    const { analysisType } = this.props.topConcerns;
    const prevPropsAnalysisType = prevProps.topConcerns.analysisType;
    if (
      this.props.selectedFilters !== prevSelectedFilters ||
      prevPropsAnalysisType !== analysisType
    ) {
      this.getApiData();
    }
  }

  render() {
    const { topConcerns, setTopConcernsAnalysis } = this.props;
    const { fetching, analysis, analysisType, error } = topConcerns;
    const { t } = this.props;
    return (
      <Panel
        header={
          <span title={TOOLTIP_MESSAGE.TOP_CONCERNS}>{t("topConcerns")}</span>
        }
      >
        <Tabs
          id="priority-list-tabs"
          activeKey={analysisType}
          onSelect={setTopConcernsAnalysis}
        >
          {["overall", "inner", "outer"].map((key, i) => {
            return (
              <Tab
                key={i}
                eventKey={key}
                title={key[0].toUpperCase() + key.slice(1)}
              >
                {!fetching ? (
                  <TopConcernsStats
                    analysis={analysis}
                    error={error}
                    analysisType={key}
                  />
                ) : (
                  <Spinner name="wandering-cubes" fadeIn="none" />
                )}
              </Tab>
            );
          })}
        </Tabs>
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, dashboard }) => ({
  selectedFilters,
  topConcerns: dashboard.topConcerns,
});

export default connect(mapStateToProps, {
  loadTopConcerns,
  setTopConcernsAnalysis,
})(withTranslation()(TopConcerns));

import styled, { css } from "styled-components";
import UpArrow from "assets/img/arrow-up.png";
import Delete from "assets/img/delete.png";
import Download from "assets/img/download.png";
import DownloadHover from "assets/img/download-hover.png";

// Accrodian Styles Start

export const Wrapper = styled.div`
  padding: 43px 55px 0;
  @media screen and (max-width: 991px) {
    padding: 23px 15px 0;
  }
  @media screen and (max-width: 991px) {
    padding: 23px 0 0;
  }
`;

export const Container = styled.section`
  border-radius: 3px;
  background-color: white;
  margin-bottom: 15px;
  ${(props) =>
    props.isOpen &&
    css`
      background-color: var(--text-color);
    `}
  border: 1px solid #E9E9E9;
`;

export const Span = styled.span`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #242424;
  font-weight: 400;
  ${(props) =>
    props.isOpen &&
    css`
      font-weight: 700;
    `}
`;

export const Title = styled.span`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #242424;
  font-size: 15px;
  font-weight: 700;
  ${(props) =>
    props.isOpen &&
    css`
      font-weight: 400;
    `}
`;

export const SectionTitleContainer = styled.div`
  padding: 15px 24px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor};
  background-size: 200%;
  transition: 0.1s ease-out;
  ${(props) =>
    !props.isEnabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
      &:hover ${ToolTip} {
        display: block;
        transition: 0.5s ease-in-out right;
      }
    `}
  ${(props) =>
    props.hoverChange &&
    css`
      &:hover {
        background-position: left;
        background-color: #d5d5d5;
      }
    `}
  &::after {
    content: "";
    background-image: url(${UpArrow});
    background-repeat: no-repeat;
    position: absolute;
    right: 24px;
    top: 21px;
    height: 20px;
    width: 20px;
  }
  ${(props) =>
    props.isOpen &&
    css`
      &::after {
        top: 11px;
        right: 25px;
        transform: rotate(180deg);
        transition: 0.25s all ease-in-out;
      }
    `}
`;

export const Section = styled.div``;

// Accrodian Styles End

export const RowDiv = styled.div``;

export const HR = styled.hr`
  color: black;
  margin: 0;
  padding: 0;
`;

// Approve Screen Styles Start

export const CardWrapper = styled.div`
  padding: 23px 33px;
  margin-bottom: 50px;
  width: 100%;
  box-shadow: 0 5px 10px lightgray;
  border-radius: 3px;
  background-color: var(--text-color);
`;

export const CardHeadingBold = styled.span`
  font-size: 18px;
  color: #242424;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 600;
`;

export const CardHeading = styled.p`
  font-size: 18px;
  text-align: center;
  color: #242424;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 100;
`;

export const CardBody = styled.div`
  padding-right: 5px;
  padding-left: 5px;
`;

export const CardFieldset = styled.fieldset`
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;

  & + & {
    margin-top: 24px;
  }

  &:nth-last-of-type(2) {
    margin-top: 32px;
  }

  &:last-of-type {
    text-align: center;
  }
`;

export const PlaceholderText = styled.span`
  font-size: 15px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: absolute;
  left: 17px;
  top: 26%;
  color: #505050;
`;

export const CardInput = styled.input`
  padding: 11px 100px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  transition: border 0.25s ease-in;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
`;

export const CardButtons = styled.div`
  margin-top: 50px;
  width: 100%;
  font-size: 12px;
  text-align: center;
`;

export const CardOptions = styled.ul`
  padding: 0;
  margin: 16px 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style-type: none;
`;

export const CardOptionsItem = styled.li`
  &:nth-of-type(n + 2) {
    margin-left: 16px;
  }
`;

export const CardButtonAccept = styled.button`
  width: 85px;
  height: 33px;
  font-size: 15px;
  color: #fff;
  background-color: #133b7d;
  border: 1px solid #133b7d;
  border-radius: 3px;
  &:hover {
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    background-color: #06265b;
  }
`;

export const CardButtonDeny = styled(CardButtonAccept)`
  margin-left: 22px;
  color: #133b7d;
  background-color: var(--text-color);
  border: 1px solid #133b7d;
  &:hover {
    background-color: #133b7d;
    color: var(--text-color);
    transition: all 0.25s ease-in-out;
    cursor: pointer;
  }
`;

// Approve Screen Styles End

// Screen Buttons Start

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProjectName = styled.h6`
  font-size: 35px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #242424;
  ${(props) => props.untitled && "color: #24242436; font-style: italic;"}
`;

export const ScreenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0 45px;
`;

export const SaveButton = styled.button`
  transition: all 100ms ease-in-out;
  background-color: #133b7d;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: var(--text-color);
  width: 127px;
  height: 37px;
  border: 1px solid #133b7d;
  border-radius: 3px;
  &:hover {
    background: var(--text-color);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
  }
`;

export const IconButtonDescription = styled.span`
  border-radius: 2px;
  padding: 2px 8px;
  background-color: #133b7d;
  color: var(--text-color);
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  position: absolute;
  left: -62%;
  transition: transform 0.3s ease-in-out;
  transform: translateY(-50px);
  display: none;
  min-width: 80px;
`;

export const IconButton = styled.button`
  border: 1px solid #133b7d;
  background-color: #133b7d;
  border-radius: 3px;
  background-image: url(${Delete});
  width: 38px;
  height: 28px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 22px;
  position: relative;
  /* Delete Image Is Required */
  &:hover ${IconButtonDescription} {
    display: block;
    transform: translateY(15px);
  }
`;

export const EditButton = styled.button`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 15px;
  background-color: #e9e9e9;
  color: #242424;
  width: 90px;
  height: 28px;
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  margin-left: 18px;
  transition: 100ms all;
  &:hover {
    background-color: #d3d3d3;
  }
`;

// Screen Buttons End

export const SectionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 40px 0px 35px;
  position: relative;
  @media screen and (max-width: 1059px) {
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    padding: 40 33px 35px;
  }
`;

export const ToolTip = styled.span`
  background-color: #133b7d;
  padding: 5px 4px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: var(--text-color);
  position: absolute;
  bottom: -30%;
  max-width: 135px;
  right: 0;
  display: none;
  box-shadow: #00000014 0px 3px 6px;
  &::after {
    content: "◀";
    position: absolute;
    bottom: 15%;
    left: -7%;
    font-size: 17px;
    color: #133b7d;
  }
`;

export const SectionButton = styled.button`
  border: 1px solid ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  color: ${(props) => (props.disabled ? "#313131" : "var(--text-color)")};
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 15px;
  border-radius: 3px;
  height: 34px;
  position: relative;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#06265b")};
  }
  &:hover ${ToolTip} {
    display: block;
    right: -110%;
    transition: 0.5s ease-in-out right;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const PopupBox = styled.div`
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const Box = styled.div`
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
`;

export const CloseIcon = styled.span`
  content: "x";
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ededed;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
`;

export const RightTitle = styled.span`
  text-align: right;
`;

export const LeftTitle = styled.span`
  text-align: left;
`;

export const BoldText = styled.span`
  font-size: 16px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #242424;
  font-style: italic;
  font-weight: 500;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ItalicText = styled.span`
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: italic;
  color: #7c7c7c;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const Holder = styled.span`
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
`;

export const ProjectDetailsWrapper = styled.div`
  margin-right: 57px;
  @media screen and (max-width: 991px) {
    margin-right: 27px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 22px;
  }
`;

export const NextButton = styled.button`
  border: 1px solid #133b7d;
  background-color: #133b7d;
  color: var(--text-color);
  font-size: 15px;
  padding: 8px 48px;
  margin-bottom: 45px;
  font-size: 15px;
  margin-top: 50px;
  &:hover {
    background-color: #06265b;
  }
`;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { isEmpty } from "lodash";

import {
  saveRCAForm,
  viewProjectList,
  loadRCAProjectData,
  toggleMode,
  setFormFieldValue,
  loadRCAProject,
  deleteRCAProject,
  switchRCAView,
  loadRCAProjectList,
  startNewProject,
} from "components/RCA/actions";

import {
  projectFormSelector,
  projectIDSelector,
  formModeSelector,
  projectValuesSelector,
  formFieldValueSelector,
  rootCausesSelector,
  formRCAOptionsFieldSelector,
  locationMatchErrorSelector,
  rcaTemplateIdSelector,
  questionnaireIDSelector,
  selectUser,
} from "components/RCA/selectors";

import {
  Wrapper,
  Container,
  Section as StyledSection,
  SectionTitleContainer,
  Title,
  Span,
  CardWrapper,
  CardHeadingBold,
  CardHeading,
  CardBody,
  CardFieldset,
  PlaceholderText,
  CardInput,
  CardButtonAccept,
  CardButtonDeny,
  ScreenWrapper,
  CardButtons,
  ProjectName,
  ButtonWrapper,
  SaveButton,
  EditButton,
  IconButton,
  IconButtonDescription,
  ProjectDetailsWrapper,
  Holder,
  ItalicText,
  BoldText,
  ToolTip,
} from "./styled";

import { DeleteModal } from "components/common/DeleteModal";
import Spinner, { SpinnerContainer } from "components/common/MiniSpinner";

import Section from "./Sections";
import ImpactGraph from "./ImpactGraph";
import ImpactTable from "./ImpactTable";
import Rating from "./Rating";

import { GoBackButton } from "components/common/GoBackButton";

import { RCAProjectStatus, MIN_ROOT_CAUSES_DEPTH } from "constants/constants";

import { keys, range } from "lodash";

const Form = ({ isErrorInSection }) => {
  const dispatch = useDispatch();

  if (window.location.search) {
    window.history.pushState({}, document.title, "/rca");
  }

  const [selectedSectionIndex, selectSection] = useState(0);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const id = useSelector(projectIDSelector);
  const mode = useSelector(formModeSelector);
  const form = useSelector(projectFormSelector);
  const values = useSelector(projectValuesSelector);
  const user = useSelector(selectUser);
  const locationMatchError = useSelector(locationMatchErrorSelector);
  const rcaTemplateId = useSelector(rcaTemplateIdSelector);
  const questionnaireID = useSelector(questionnaireIDSelector);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    dispatch(viewProjectList());
  }, [questionnaireID]);

  useEffect(() => {
    if (id && id != "null") {
      dispatch(toggleMode("VIEW"));
      dispatch(loadRCAProjectData(id));
    } else {
      dispatch(startNewProject());
      dispatch(loadRCAProject(rcaTemplateId));
    }
  }, [id]);

  useEffect(() => {
    setDisableSaveButton(locationMatchError && mode === "EDIT");
  }, [locationMatchError, mode]);

  if (form.loading || isEmpty(form.data) || values.loading) {
    return (
      <SpinnerContainer>
        <Spinner size="55" />
      </SpinnerContainer>
    );
  }

  const {
    status,
    team_lead,
    owner,
    team_members,
    project_name,
    project_start_date,
    deployment_end_date,
    project_deployment_remarks,
  } = values.data;

  const setProjectStatus = (updatedStatus) => {
    dispatch(setFormFieldValue("status", updatedStatus));
    dispatch(saveRCAForm());
  };

  const handleDelete = () => {
    dispatch(deleteRCAProject());
    dispatch(switchRCAView());
    dispatch(loadRCAProjectList());
  };

  const handleRequest = (updatedStatus, remarksType) => {
    dispatch(setFormFieldValue(remarksType, remarks));
    setProjectStatus(updatedStatus);
  };

  const handleSaveButtonRequest = () => {
    setDisableSaveButton(true);
    dispatch(saveRCAForm());
  };

  const [requestMsg, onRequestApprove, onRequestDeny] =
    status === RCAProjectStatus.DEPLOYMENT_PENDING
      ? [
          `has requested for deployment of`,
          () =>
            handleRequest(
              RCAProjectStatus.DEPLOYED,
              "project_deployment_remarks",
            ),
          () =>
            handleRequest(
              RCAProjectStatus.DEPLOYMENT_DENIED,
              "project_deployment_remarks",
            ),
        ]
      : [];

  const allUserIds = [...team_members, team_lead, owner].map((u) => u?.id);

  const isEditable = allUserIds.includes(user?.id);
  const isOwnerLoggedIn = owner?.id === user?.id;
  const isLeadLoggedIn = user?.id === team_lead?.id;

  const showDeploymentDeniedMsg =
    isOwnerLoggedIn && status === RCAProjectStatus.DEPLOYMENT_DENIED;

  const [deniedMsg, onDeniedMsgClose, deniedRemarks] = showDeploymentDeniedMsg
    ? [
        " was denied for deployment. Update your project details and submit again for deployment",
        () => setProjectStatus(RCAProjectStatus.PROGRESS),
        project_deployment_remarks,
      ]
    : [];

  const sections = form.data.rca_sections;

  const showPostDeploymentStats =
    status === RCAProjectStatus.DEPLOYED &&
    new Date(deployment_end_date).getTime() <= new Date().getTime();

  const showSaveButton = mode === "EDIT";
  const showDeleteButton =
    isEditable &&
    mode === "VIEW" &&
    user?.permissions.can_edit_rca_project &&
    status === RCAProjectStatus.PROGRESS;

  const canOthersEdit = isEditable && status === RCAProjectStatus.PROGRESS;
  const showEditButton =
    user?.permissions.can_edit_rca_project && mode === "VIEW" && canOthersEdit;

  return (
    <Wrapper>
      {deniedMsg && (
        <DeleteModal
          projectName={project_name}
          msg={deniedMsg}
          isDelete={false}
          onPopupClose={onDeniedMsgClose}
          remarks={deniedRemarks}
        />
      )}
      {deleteConfirm && (
        <DeleteModal
          projectName={project_name}
          msg=" is still in progress. Are you sure you want to delete it"
          onDelete={handleDelete}
          isDelete={true}
          onCancel={() => setDeleteConfirm(false)}
        />
      )}
      <GoBackButton onClick={() => dispatch(viewProjectList())}>
        Go back
      </GoBackButton>
      <ScreenWrapper>
        <ProjectName untitled={!project_name}>
          {project_name || "Untitled"}
        </ProjectName>
        <ButtonWrapper>
          {showSaveButton && (
            <SaveButton
              onClick={() => handleSaveButtonRequest()}
              disabled={disableSaveButton}
            >
              Save
            </SaveButton>
          )}
          {showEditButton && (
            <EditButton onClick={() => dispatch(toggleMode("EDIT"))}>
              Edit
            </EditButton>
          )}

          {showDeleteButton && (
            <IconButton onClick={() => setDeleteConfirm(true)}>
              <IconButtonDescription>Delete Project</IconButtonDescription>
            </IconButton>
          )}
        </ButtonWrapper>
      </ScreenWrapper>
      {sections.map((section, i) => {
        const isOpened = mode === "VIEW" || selectedSectionIndex === i;
        const bgColor = !isOpened || mode === "VIEW" ? "#e9e9e9" : "white";

        const isSectionComplete =
          isErrorInSection && !isErrorInSection[section.title];

        const arePreviousSectionsCompleted = range(0, i).reduce(
          (a, b) => a && !isErrorInSection[sections[b].title],
          true,
        );

        const isEnabled = arePreviousSectionsCompleted;

        const onClick = isEnabled ? () => selectSection(i) : null;

        return (
          <Container key={section.title}>
            <SectionTitleContainer
              isEnabled={isOpened || isEnabled}
              bgColor={bgColor}
              onClick={onClick}
              isOpen={!isOpened}
              hoverChange={mode !== "VIEW" && isEnabled}
            >
              <ToolTip>Kindly fill in the previous section to continue</ToolTip>
              <div>
                <Title>Step {i + 1}:</Title> <Span>{section.title}</Span>
              </div>
              {section.title === "Project Details" && mode === "VIEW" && (
                <ProjectDetailsWrapper>
                  <Holder>
                    <ItalicText>Project Start Date: </ItalicText>
                    <BoldText>{project_start_date}</BoldText>
                  </Holder>
                  <Holder>
                    <ItalicText>Approval Status: </ItalicText>
                    <BoldText>{status?.replace("_", " ") || ""}</BoldText>
                  </Holder>
                </ProjectDetailsWrapper>
              )}
            </SectionTitleContainer>
            {isOpened && (
              <StyledSection>
                <Section
                  isError={!isSectionComplete}
                  section={section}
                  handleNext={() => selectSection(i + 1)}
                />
              </StyledSection>
            )}
          </Container>
        );
      })}
      {isLeadLoggedIn && requestMsg && (
        <CardWrapper>
          <CardHeading>
            <CardHeadingBold>{owner?.full_name} </CardHeadingBold>
            {requestMsg}
            <CardHeadingBold> {project_name}</CardHeadingBold>
          </CardHeading>
          <CardBody>
            <CardFieldset>
              <PlaceholderText>Remarks:</PlaceholderText>
              <CardInput
                type="text"
                placeholder="Add your remarks about the project here"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
              />
            </CardFieldset>
            <CardButtons>
              <CardButtonAccept type="button" onClick={onRequestApprove}>
                Approve
              </CardButtonAccept>
              <CardButtonDeny type="button" onClick={onRequestDeny}>
                Deny
              </CardButtonDeny>
            </CardButtons>
          </CardBody>
        </CardWrapper>
      )}
      {showPostDeploymentStats && (
        <>
          <Rating clickable={isLeadLoggedIn} />
          <ImpactTable />
          <ImpactGraph />
        </>
      )}
    </Wrapper>
  );
};

const checkRootCauses = (rootCauses) => {
  if (!rootCauses) {
    return true;
  } else {
    let currentDepth = 1;
    let causeId = 1;
    while (currentDepth <= MIN_ROOT_CAUSES_DEPTH) {
      const subRootCauses = rootCauses[causeId]?.rootCauses;
      const cause = rootCauses[causeId]?.cause;
      if (!subRootCauses?.length || cause === "") {
        return true;
      } else {
        causeId = subRootCauses[0];
        currentDepth += 1;
      }
    }
  }
  return false;
};

const mapStateToProps = (state) => {
  const form = projectFormSelector(state);
  const isErrorArr = form?.data?.rca_sections?.map((section) => {
    let isError;
    if (section.title === "Root Cause Analysis") {
      const rootCauses = rootCausesSelector(state);
      isError = checkRootCauses(rootCauses);
    } else {
      isError = section.rca_fields
        ?.filter((field) => field.is_required)
        .some((field) => {
          const fieldKey = field.field_type.toLowerCase();
          let fieldValue;
          if (field.slug === "what-actions-need-to-be-taken") {
            return !formRCAOptionsFieldSelector(state, field.id) || null;
          } else if (fieldKey === "deployment_dates") {
            const startDate =
              formFieldValueSelector(state, "deployment_start_date") || null;
            const endDate =
              formFieldValueSelector(state, "deployment_end_date") || null;
            return !startDate && !endDate;
          } else {
            fieldValue = formFieldValueSelector(state, fieldKey) || null;
            if (fieldKey == "location") {
              fieldValue = keys(fieldValue).flatMap(
                (location) => fieldValue[location].titles,
              );
            } else if (fieldKey == "concern" && !fieldValue) {
              fieldValue =
                formFieldValueSelector(state, "other_concern") || null;
            } else if (
              fieldKey === "concern_current_state" ||
              fieldKey === "concern_desired_state"
            ) {
              return !fieldValue;
            }
            return (
              locationMatchErrorSelector(state)?.length || isEmpty(fieldValue)
            );
          }
        });
    }
    return { [section.title]: isError };
  });
  const isErrorInSection = isErrorArr ? Object.assign({}, ...isErrorArr) : null;

  return { isErrorInSection };
};

export default connect(mapStateToProps)(Form);

import React from "react";
import { isEqual } from "lodash";
import * as analytics from "utils/analytics";

import { getQueryString } from "../../utils/filtersHelperFuncs";
import { getRequest, BASE_URL } from "utils/request";
import { MdAttachEmail, MdMarkEmailRead } from "react-icons/md";
import { TOOLTIPS } from "constants/constants";
import { TooltipText } from "components/common/Tooltip/Tooltip";
import { withTranslation } from "react-i18next";

class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloaded: false,
      convertToButton: false,
      displayText: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedFilters } = this.props;
    const prevselectedFilters = prevProps.selectedFilters;
    if (!isEqual(selectedFilters, prevselectedFilters)) {
      this.setState(() => ({
        isDownloaded: false,
        convertToButton: false,
        displayText: false,
      }));
    }
  }

  downloadCSV = async (e) => {
    const { isDownloaded } = this.state;
    if (isDownloaded) {
      e.preventDefault();
    } else {
      const queryTokenResponse = await getRequest("/v1/users/query-token/");
      const queryToken = queryTokenResponse?.query_token;
      const selectedFilters = `${getQueryString(this.props.selectedFilters)}`;
      const reportUrl = `${BASE_URL}/v1/organizations/${this.props.orgId}/reports/feedback-detail-report?${selectedFilters}&query_token=${queryToken}`;
      await getRequest(reportUrl);

      this.setState({
        isDownloaded: true,
      });
      analytics.logReportDownload(selectedFilters);

      setTimeout(() => {
        this.setState({
          convertToButton: true,
        });
      }, 500);
      setTimeout(() => {
        this.setState({
          displayText: true,
        });
      }, 1300);
    }
  };

  render() {
    const { isDownloaded, convertToButton, displayText } = this.state;
    const { t } = this.props;

    return (
      <a
        className={`download-button-wrapper ${isDownloaded && "downloaded"}`}
        onClick={this.downloadCSV}
        style={{ backgroundColor: "var(--filters-color)" }}
      >
        <div className="button-filler"></div>
        <div
          className="download tooltipRel"
          style={convertToButton ? { display: "none" } : {}}
        >
          <MdAttachEmail
            style={{ height: "60%", width: "60%", margin: "20%" }}
          />
          <TooltipText
            tooltipText={t(TOOLTIPS.DOWNLOAD_REPORT)}
            align="bottomLeft"
          />
        </div>
        <div className="text" style={displayText ? { display: "block" } : {}}>
          <MdMarkEmailRead style={{ height: "2em", width: "2em" }} /> Emailed
        </div>
      </a>
    );
  }
}

export default withTranslation()(DownloadButton);

import React from "react";
import numeral from "numeral";
import { join, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import i18n from "../i18n";

export const getOrganizationSlug = () => {
  let slug = "";
  if (process.env.REACT_APP_ENV === "dev") {
    slug = process.env.REACT_APP_ORGANIZATION_SLUG;
  } else {
    slug = window.location.hostname.split(".")[0];
  }
  return slug;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const isBase64 = (str) => {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getPhoneNumber = (phone) => {
  if (phone[0] !== "+" && phone[0] !== "0") {
    return `+${phone}`;
  } else {
    return phone;
  }
};

export const isEmailValid = (email) => {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

export const getPhoneNumberLink = (number) =>
  (number && `tel:${getPhoneNumber(number)}`) || "";

export const getEmailLink = (email) =>
  (email && isEmailValid(email) && `mailto:${email}`) || "";

export const isFalsy = (value) => {
  if (!value || (typeof value === "object" && isEmpty(value))) {
    return true;
  }
  return false;
};

export const isTruthy = (value) => {
  if (!value || (typeof value === "object" && isEmpty(value))) {
    return false;
  }
  return true;
};

export const boldText_DEPRECATED = (text) => {
  if (!text) {
    return "";
  }

  let counter = 1;
  while (counter < text.length) {
    if (text.indexOf("*") === -1) {
      break;
    }

    if (counter % 2 === 0) {
      text = text.replace("*", "</span>");
    } else {
      text = text.replace("*", '<span class="bold">');
    }
    counter++;
  }
  return text;
};

export const taggedUserInBoldText = (message, source) => {
  let final_message = message;
  let counter = 1;
  let message_length = source === "menu" ? 178 : 185;
  const maxAllowedUserTag = source === "menu" ? 2 : 4;
  let spanTagLength = 26;
  while (counter < message.length) {
    if (final_message.search(/user-id=\d+/) !== -1) {
      final_message = final_message.replace(/user-id=\d+/, 'class="bold"');
    } else {
      break;
    }
    if (counter <= maxAllowedUserTag) {
      message_length += spanTagLength;
    }
    counter++;
  }
  if (final_message.length > message_length) {
    final_message = final_message.slice(0, message_length) + "...";
  }
  return {
    __html: final_message,
  };
};

export const toSlug = (text) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

export const limitLength = (str, length) => str.substring(0, length);

export const boldText = (text) =>
  (text || "")
    .split("*")
    .map((word, index) => (index % 2 ? <b>{word}</b> : word));

export const formatNumber = (value, format = "0,0.[0]") =>
  numeral(value).format(format);

export const formatPercentage = (value) => numeral(value).format("0,0.0") + "%";

export const formatNumeral = (
  value,
  options = { isPercentage: false, format: undefined },
) =>
  options.isPercentage
    ? formatPercentage(value)
    : formatNumber(value, options.format);

export const humanReadableList = (list) =>
  list?.length > 1
    ? join(list.slice(0, -1), ", ") + " and " + list.slice(-1)
    : list[0];

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAYS = HOUR * 24;
const THREE_DAYS = DAYS * 3;

export const humanizeSeconds = (seconds, defaultUnit) => {
  seconds = parseFloat(seconds) || 0;
  let duration = seconds;

  let unit = "s";
  if (defaultUnit === "day" || (!defaultUnit && seconds >= THREE_DAYS)) {
    duration = seconds / DAYS;
    unit = "day";
  } else if (defaultUnit === "hr" || (!defaultUnit && seconds >= HOUR)) {
    duration = seconds / HOUR;
    unit = "hr";
  } else if (defaultUnit === "min" || (!defaultUnit && seconds >= MINUTE)) {
    duration = seconds / MINUTE;
    unit = "min";
  }

  if (duration !== 1 && unit !== "s") {
    unit += "s";
  }
  unit = i18n.t(unit) || unit;
  return [duration.toFixed(1), unit];
};

const BILLION = 1000000000;
const MILLION = 1000000;
const THOUSAND = 1000;

export const abbreviateNumber = (number) => {
  let abbreviation = "";
  let n = number || 0;
  n = parseFloat(n);

  if (n > BILLION) {
    abbreviation = "b";
    n /= BILLION;
  } else if (n > MILLION) {
    abbreviation = "m";
    n /= MILLION;
  } else if (n > THOUSAND) {
    abbreviation = "k";
    n /= THOUSAND;
  }
  return [n, abbreviation];
};

export const mixRedGreenColors = (percentage) => {
  const red = "E61F1F";
  const green = "89C120";
  return mixColors(red, green, percentage);
};

export const mixColors = (colorA, colorB, percentage) => {
  percentage = percentage / 100;
  if (percentage < 0) percentage = 0;

  colorA = [
    parseInt(colorA[0] + colorA[1], 16),
    parseInt(colorA[2] + colorA[3], 16),
    parseInt(colorA[4] + colorA[5], 16),
  ];
  colorB = [
    parseInt(colorB[0] + colorB[1], 16),
    parseInt(colorB[2] + colorB[3], 16),
    parseInt(colorB[4] + colorB[5], 16),
  ];

  var colorC = [
    (1 - percentage) * colorA[0] + percentage * colorB[0],
    (1 - percentage) * colorA[1] + percentage * colorB[1],
    (1 - percentage) * colorA[2] + percentage * colorB[2],
  ];

  return "#" + toHex(colorC[0]) + toHex(colorC[1]) + toHex(colorC[2]);
};

export const abbreviateAmount = (amount, currency, format) => {
  const [n, abbr] = abbreviateNumber(amount);
  return [currency, formatNumber(n, format), abbr].join("");
};

const toHex = (num) => {
  var hex = Math.round(num).toString(16);
  if (hex.length == 1) hex = "0" + hex;
  return hex;
};

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const pickDeep = (obj, key) =>
  key.split(".").reduce((o, k) => o?.[k], obj);

export const updateOrPush = (array, item, find) => {
  const index = array.findIndex(find);
  if (index < 0) {
    array.push(item);
  } else {
    array[index] = item;
  }
  return array;
};

export const guessTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export { format as formatDate } from "date-fns";
export { formatDistance as formatDateDistance } from "date-fns";

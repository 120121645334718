import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinkit";

import { NoDataFound } from "components/common/NoDataFound";
import * as analytics from "utils/analytics";
import { loadMoreNotifications } from "./actions";
import NotificationCard from "./NotificationCard";

export const NotificationList = ({ source, redirect }) => {
  const dispatch = useDispatch();

  const lazyLoad = (e, nextUrl) => {
    if (
      !nextUrl ||
      e.target.offsetHeight + e.target.scrollTop !== e.target.scrollHeight
    )
      return;

    dispatch(loadMoreNotifications(nextUrl));
    analytics.logNotificationAPICall();
  };

  const notification = useSelector((state) => state.notification);

  if (!notification.data.results.length) {
    return (
      <ul className="notifications--list">
        <NoDataFound />
      </ul>
    );
  }

  return (
    <ul
      className="notifications--list"
      onScroll={(e) => lazyLoad(e, notification.data.next)}
    >
      {notification.data.results.map((notification) => (
        <NotificationCard
          redirect={redirect}
          notification={notification}
          source={source}
        />
      ))}
      {notification.isMoreLoading && (
        <li style={{ position: "relative", height: "60px" }}>
          <Spinner name="wandering-cubes" fadeIn="none" />
        </li>
      )}
    </ul>
  );
};

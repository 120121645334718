import React from "react";
import { Button } from "react-bootstrap";

export const NotificationAll = ({ disabled, onClick }) => {
  return (
    <Button
      className="btn btn-primary notification-all"
      disabled={disabled}
      onClick={(e) => onClick(e)}
    >
      Reminder to all <i className="fa fa-bell-o" />
    </Button>
  );
};

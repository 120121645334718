import React, { useEffect, useRef } from "react";
import * as analytics from "utils/analytics";
import { useDispatch } from "react-redux";
import { useTable, useBlockLayout, useSortBy } from "react-table";
import { useSticky } from "react-table-sticky";
import { TableWrapper, Table, Thead, Tbody, Tr, Th, Td } from "./styled";
import { ReactComponent as UnsortIcon } from "assets/svg/sort_both.svg";
import { ReactComponent as SortUpIcon } from "assets/svg/sort_up.svg";
import { ReactComponent as SortDownIcon } from "assets/svg/sort_down.svg";
import * as utils from "utils/genericHelper";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import InvoiceModal from "components/FeedbackPage/invoice/InvoiceModal";

import { loadMoreAnalyzerData } from "components/DashboardPage/actions";

// https://github.com/tannerlinsley/react-table/issues/3087
const mapColumns = (columns) =>
  columns.map((column) => {
    if (!column.accessor?.includes("[")) return column;
    return {
      ...column,
      accessor: (rows) => rows[column.Header],
    };
  });

const ReactTable = ({ columns, data, heading, analyzer, nextURL, unit }) => {
  const { t } = useTranslation();
  const mappedColumns = React.useMemo(() => mapColumns(columns), [columns]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: mappedColumns,
        data,
      },
      useBlockLayout,
      useSticky,
      useSortBy,
    );

  const tableRef = useRef();

  useEffect(() => {
    const onScroll = (e) =>
      nextURL &&
      e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight &&
      dispatch(loadMoreAnalyzerData(analyzer, nextURL));

    if (tableRef.current) tableRef.current.addEventListener("scroll", onScroll);
    return () => {
      if (tableRef.current)
        tableRef.current.removeEventListener("scroll", onScroll);
    };
  }, [nextURL]);

  const dispatch = useDispatch();

  return (
    <TableWrapper
      ref={tableRef}
      tableHeight={{
        lg: "300px",
        md: "275px",
        sm: "250px",
      }}
    >
      <Table {...getTableProps()} className="table ui-table sticky">
        <Thead className="ui-table-thead">
          {headerGroups ? (
            headerGroups.map((headerGroup) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                className="ui-table-tr"
              >
                {headerGroup.headers.map((column) => {
                  if (column.isSorted) {
                    analytics.logTableSort(column.Heading, heading);
                  }
                  return (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="ui-table-th"
                    >
                      <text>
                        {t(column.render("Header"))}
                        <span
                          className={`tableSorters ${
                            column.isSorted ? "sorted" : ""
                          }`}
                        >
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SortDownIcon title={t("ToggleSortBy")} />
                            ) : (
                              <SortUpIcon title={t("ToggleSortBy")} />
                            )
                          ) : (
                            <UnsortIcon title={t("ToggleSortBy")} />
                          )}
                        </span>
                      </text>
                    </Th>
                  );
                })}
              </Tr>
            ))
          ) : (
            <Skeleton width={"100%"} height={"45px"} />
          )}
        </Thead>

        <Tbody {...getTableBodyProps()} className="ui-table-tbody">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} className="ui-table-tr">
                {row.cells.map((cell, index) => {
                  const Cell =
                    typeof cell.value === "object" && cell.value !== null
                      ? SpecialCells[cell.value.cellType]
                      : DefaultCell;

                  return (
                    <Td {...cell.getCellProps()} className="ui-table-td">
                      <Cell
                        unit={unit}
                        value={cell.value}
                        analyzer={analyzer}
                        isPivotColumn={!index}
                      />
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableWrapper>
  );
};

const DefaultCell = ({ value, unit, isPivotColumn, analyzer }) => {
  const { prefix, number_format, abbreviate_numbers } = analyzer;
  if (typeof value === "string") {
    let val = value;
    if (val.endsWith("%")) {
      unit = "percentage";
      val = val.slice(0, -1);
    }
    if (!isNaN(val)) {
      value = parseFloat(val);
    }
  }
  if (isPivotColumn || typeof value !== "number") return value;

  const isPercentage = unit === "percentage";

  let number = "";
  if (abbreviate_numbers) {
    const [n, abbreviation] = utils.abbreviateNumber(value);
    number = utils.formatNumber(n, "0,0.0") + abbreviation;
  } else {
    number = utils.formatNumeral(value, {
      format: number_format,
      isPercentage,
    });
  }

  if (prefix && !isPercentage) return prefix + number;
  return number;
};

const InvoiceDetailCell = ({ value }) => {
  return <InvoiceModal customerId={value.customerId} />;
};

const LastOrderDateCell = ({ value }) => {
  const now = new Date();
  const date = new Date(value.date);
  const timeAgo = utils.formatDateDistance(now, date);

  return (
    <>
      <div>{utils.formatDate(date, "L MMM Y")}</div>
      <div>({timeAgo} ago)</div>
    </>
  );
};

const SpecialCells = {
  invoice_details: InvoiceDetailCell,
  last_order_date: LastOrderDateCell,
};

export default ReactTable;

import {
  AUTH_USER,
  UNAUTH_USER,
  USER_SUCCESS,
  AUTH_FAILURE,
} from "./constants";

import * as constants from "constants/constants";

const INITIAL_STATE = {
  authenticated: false,
  user: { permissions: {} },
  error: null,
  fetching: true,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.LOAD_AUTH:
      return {
        ...state,
        fetching: true,
      };

    case constants.LOAD_AUTH_ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };

    case constants.LOAD_AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
      };

    case constants.SET_LANGUAGES_LIST:
      return {
        ...state,
        languages: action.payload,
      };

    case constants.UNAUTH_USER:
      return {
        ...state,
        authenticated: false,
        fetching: false,
      };

    case constants.LOAD_USER_INFO:
      return {
        ...state,
        fetching: true,
      };

    case constants.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.user,
        fetching: false,
        authenticated: true,
      };

    case constants.LOAD_USER_INFO_ERROR: {
      return {
        ...state,
        fetching: false,
      };
    }

    default:
      return state;
  }
};

export default authReducer;

import { getRequest } from "../../../utils/request";

export const getNotifications = (organizationId) => {
  let url = `/organizations/${organizationId}/notification/list/`;
  return getRequest(url);
};

export const getMoreNotifications = (url) => {
  return getRequest(url);
};

export const markAsReadNotification = (organizationId, notificationId) => {
  let url = `/organizations/${organizationId}/notification/${notificationId}/mark-as-read`;
  return getRequest(url);
};

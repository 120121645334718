import * as constants from "constants/constants";

export const storeOrgDashboard = (dashboard) => {
  return {
    type: constants.STORE_ORG_DASHBOARD,
    payload: dashboard,
  };
};

export const storeDashboards = (dashboards) => {
  return {
    type: constants.STORE_DASHBOARDS,
    payload: dashboards,
  };
};

export const selectDashboard = (dashboard, isSwitch) => {
  return {
    type: constants.SELECT_DASHBOARD,
    payload: { dashboard, isSwitch },
  };
};

export const selectRacks = (racks) => {
  return {
    type: constants.SELECT_RACKS,
    payload: racks,
  };
};

export const loadAnalyzerData = (analyzer, autoFetch) => {
  return {
    type: constants.LOAD_ANALYZER_DATA,
    payload: analyzer,
    autoFetch,
  };
};

export const loadAnalyzerDataSuccess = (analyzerSlug, data) => {
  return {
    type: constants.LOAD_ANALYZER_DATA_SUCCESS,
    payload: { analyzerSlug, data },
  };
};

export const loadAnalyzerDataError = (analyzerSlug, error) => {
  return {
    type: constants.LOAD_ANALYZER_DATA_ERROR,
    payload: { analyzerSlug, error },
  };
};

export const loadMoreAnalyzerData = (analyzer, nextURL) => {
  return {
    type: constants.LOAD_MORE_ANALYZER_DATA,
    payload: { analyzer, nextURL },
  };
};

export const loadMoreAnalyzerDataSuccess = (analyzer, data) => {
  return {
    type: constants.LOAD_MORE_ANALYZER_DATA_SUCCESS,
    payload: { analyzer, data },
  };
};

export const loadMoreAnalyzerDataError = (analyzer, error) => {
  return {
    type: constants.LOAD_MORE_ANALYZER_DATA_ERROR,
    payload: { analyzer, error },
  };
};

export const selectAnalyzerUnit = (analyzerSlug, unit) => {
  return {
    type: constants.SELECT_ANALYZER_UNIT,
    payload: { analyzerSlug, unit },
  };
};

export const selectAnalyzerGraph = (analyzerSlug, graph) => {
  return {
    type: constants.SELECT_ANALYZER_GRAPH,
    payload: { analyzerSlug, graph },
  };
};

export const loadConcernImpactOnScore = (concern) => ({
  type: constants.LOAD_CONCERN_IMPACT_ON_SCORE,
  payload: { concern },
});

export const loadConcernImpactOnScoreSuccess = (score) => ({
  type: constants.LOAD_CONCERN_IMPACT_ON_SCORE_SUCCESS,
  score,
});

export const loadConcernImpactOnScoreFailure = (err) => ({
  type: constants.LOAD_CONCERN_IMPACT_ON_SCORE_FAILURE,
  err,
});

export const loadInsightConcerns = (parent) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERNS,
    payload: parent,
  };
};

export const loadInsightConcernsSuccess = (concerns) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERNS_SUCCESS,
    payload: { concerns },
  };
};

export const loadInsightConcernsError = (error) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERNS_ERROR,
    payload: { error },
  };
};

export const loadInsightChildConcernsSuccess = (concerns, parentId) => {
  return {
    type: constants.LOAD_INSIGHT_CHILD_CONCERNS_SUCCESS,
    payload: { concerns, parentId },
  };
};

export const selectInsightConcern = (concern) => {
  return {
    type: constants.SELECT_INSIGHT_CONCERN,
    payload: { concern },
  };
};

export const selectInsightChildConcern = (concern) => {
  return {
    type: constants.SELECT_INSIGHT_CHILD_CONCERN,
    payload: { concern },
  };
};

export const resetSelectedConcern = () => {
  return {
    type: constants.RESET_SELECTED_CONCERN,
  };
};

export const loadInsightConcernDetail = (concern) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERN_DETAIL,
    payload: { concern },
  };
};

export const loadInsightConcernDetailSuccess = (concern, detail) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERN_DETAIL_SUCCESS,
    payload: { concern, detail },
  };
};

export const loadInsightConcernDetailError = (concern) => {
  return {
    type: constants.LOAD_INSIGHT_CONCERN_DETAIL_ERROR,
    payload: { concern },
  };
};

export const loadSurveyQuestions = (questionnaireId) => {
  return {
    type: constants.LOAD_SURVEY_QUESTIONS,
    payload: { questionnaireId },
  };
};

export const loadSurveyQuestionsSuccess = (questionnaireId, questions) => {
  return {
    type: constants.LOAD_SURVEY_QUESTIONS_SUCCESS,
    payload: { questionnaireId, questions },
  };
};

export const loadSurveyQuestionsError = () => {
  return {
    type: constants.LOAD_SURVEY_QUESTIONS_ERROR,
  };
};

export const setLikesFilters = (likes, resetConcern, isParent) => {
  return {
    type: constants.SET_LIKES_FILTER,
    payload: { likes, resetConcern, isParent },
  };
};

export const setGenericFilters = (filters) => {
  return {
    type: constants.SET_GENERIC_FILTERS,
    payload: { filters },
  };
};

export const resetGenericFilters = (keys) => {
  return {
    type: constants.RESET_GENERIC_FILTERS,
    payload: { keys },
  };
};

export const storeFilterSet = (filterSet) => {
  return {
    type: constants.STORE_FILTER_SET,
    payload: { filterSet },
  };
};

export const toggleGenericTag = (filterKey, tag) => {
  return {
    type: constants.TOGGLE_GENERIC_TAG,
    payload: { filterKey, tag },
  };
};

export const resetGenericTags = (filterKey, tags) => {
  return {
    type: constants.RESET_GENERIC_TAGS,
    payload: { filterKey, tags },
  };
};

export const setDateTimeFilter = (
  startDate,
  endDate,
  dateFilterType,
  defaultDateRange,
) => {
  return {
    type: constants.SET_DATE_TIME_FILTER,
    payload: { startDate, endDate, dateFilterType, defaultDateRange },
  };
};

export const setPresetFilter = (months, years) => {
  return {
    type: constants.SET_PRESET_FILTER,
    payload: { months, years },
  };
};

export const setShiftFilter = (startTime, endTime, days) => {
  return {
    type: constants.SET_SHIFT_FILTER,
    payload: { startTime, endTime, days },
  };
};

export const resetShiftFilter = () => {
  return {
    type: constants.RESET_SHIFT_FILTER,
  };
};

export const setGlobalView = (globalView, currentRack) => {
  return {
    type: constants.SET_GLOBAL_VIEW,
    payload: { globalView, currentRack },
  };
};

export const loadProjectsOverview = (analyzer) => {
  return {
    type: constants.LOAD_PROJECTS_OVERVIEW,
    analyzer,
  };
};

export const loadProjectsOverviewSuccess = (data) => {
  return {
    type: constants.LOAD_PROJECTS_OVERVIEW_SUCCESS,
    data,
  };
};

export const loadProjectsOverviewError = (err) => {
  return {
    type: constants.LOAD_PROJECTS_OVERVIEW_ERROR,
    err,
  };
};

export const loadMedia = () => {
  return {
    type: constants.LOAD_MEDIA,
  };
};

export const loadMediaSuccess = (data) => {
  return {
    type: constants.LOAD_MEDIA_SUCCESS,
    data,
  };
};

export const loadMediaError = (err) => {
  return {
    type: constants.LOAD_MEDIA_ERROR,
    err,
  };
};

export const clearMediaError = () => {
  return {
    type: constants.CLEAR_MEDIA_ERROR,
  };
};

export const loadTimeSeries = () => {
  return {
    type: constants.LOAD_TIME_SERIES,
  };
};

export const loadTimeSeriesSuccess = (data) => {
  return {
    type: constants.LOAD_TIME_SERIES_SUCCESS,
    data,
  };
};

export const loadTimeSeriesError = (err) => {
  return {
    type: constants.LOAD_TIME_SERIES_ERROR,
    err,
  };
};

export const loadTopConcerns = (analysisType) => {
  return {
    type: constants.LOAD_TOP_CONCERNS,
    analysisType,
  };
};

export const setTopConcernsAnalysis = (analysisType) => {
  return {
    type: constants.SET_TOP_CONCERNS_ANALYSIS,
    analysisType,
  };
};

export const loadTopConcernsSuccess = (data) => {
  return {
    type: constants.LOAD_TOP_CONCERNS_SUCCESS,
    data,
  };
};

export const loadTopConcernsError = (err) => {
  return {
    type: constants.LOAD_TOP_CONCERNS_ERROR,
    err,
  };
};

export const loadSemanticAnalysis = () => {
  return {
    type: constants.LOAD_SEMANTIC_ANALYSIS,
  };
};

export const loadSemanticAnalysisSuccess = (data) => {
  return {
    type: constants.LOAD_SEMANTIC_ANALYSIS_SUCCESS,
    data,
  };
};

export const loadSemanticAnalysisError = (err) => {
  return {
    type: constants.LOAD_SEMANTIC_ANALYSIS_ERROR,
    err,
  };
};

export const loadSatisfactionLevel = () => {
  return {
    type: constants.LOAD_SATISFACTION_LEVEL,
  };
};

export const loadSatisfactionLevelSuccess = (data) => {
  return {
    type: constants.LOAD_SATISFACTION_LEVEL_SUCCESS,
    data,
  };
};

export const loadSatisfactionLevelError = (err) => {
  return {
    type: constants.LOAD_SATISFACTION_LEVEL_ERROR,
    err,
  };
};

export const loadPriorityList = (analysisType) => {
  return {
    type: constants.LOAD_PRIORITY_LIST,
    analysisType,
  };
};

export const loadPriorityListSuccess = (data) => {
  return {
    type: constants.LOAD_PRIORITY_LIST_SUCCESS,
    data,
  };
};

export const loadPriorityListError = (err) => {
  return {
    type: constants.LOAD_PRIORITY_LIST_ERROR,
    err,
  };
};

export const setPriorityListAnalysis = (analysisType) => {
  return {
    type: constants.SET_PRIORITY_LIST_ANALYSIS,
    analysisType,
  };
};

export const loadNPS = () => {
  return {
    type: constants.LOAD_NPS,
  };
};

export const loadNPSSuccess = (data) => {
  return {
    type: constants.LOAD_NPS_SUCCESS,
    data,
  };
};

export const loadNPSError = (err) => {
  return {
    type: constants.LOAD_NPS_ERROR,
    err,
  };
};

export const loadGenderGroupAnalysis = (selectedConcern) => {
  return {
    type: constants.LOAD_GENDER_GROUP_ANALYSIS,
    selectedConcern,
  };
};

export const loadGenderGroupAnalysisSuccess = (data) => {
  return {
    type: constants.LOAD_GENDER_GROUP_ANALYSIS_SUCCESS,
    data,
  };
};

export const loadGenderGroupAnalysisError = (err) => {
  return {
    type: constants.LOAD_GENDER_GROUP_ANALYSIS_ERROR,
    err,
  };
};

export const loadDataHealth = () => {
  return {
    type: constants.LOAD_DATA_HEALTH,
  };
};

export const loadDataHealthSuccess = (data) => {
  return {
    type: constants.LOAD_DATA_HEALTH_SUCCESS,
    data,
  };
};

export const loadDataHealthError = (err) => {
  return {
    type: constants.LOAD_DATA_HEALTH_ERROR,
    err,
  };
};

export const loadTrueScore = () => {
  return {
    type: constants.LOAD_TRUE_SCORE,
  };
};

export const loadTrueScoreSuccess = (data) => {
  return {
    type: constants.LOAD_TRUE_SCORE_SUCCESS,
    data,
  };
};

export const loadTrueScoreError = (err) => {
  return {
    type: constants.LOAD_TRUE_SCORE_ERROR,
    err,
  };
};

export const loadCustomerSegmentation = (selectedConcern) => {
  return {
    type: constants.LOAD_CUSTOMER_SEGMENTATION,
    selectedConcern,
  };
};

export const loadCustomerSegmentationSuccess = (data) => {
  return {
    type: constants.LOAD_CUSTOMER_SEGMENTATION_SUCCESS,
    data,
  };
};

export const loadCustomerSegmentationError = (err) => {
  return {
    type: constants.LOAD_CUSTOMER_SEGMENTATION_ERROR,
    err,
  };
};

export const loadCompetitorAnalysis = () => {
  return {
    type: constants.LOAD_COMPETITOR_ANALYSIS,
  };
};

export const loadCompetitorAnalysisSuccess = (data) => {
  return {
    type: constants.LOAD_COMPETITOR_ANALYSIS_SUCCESS,
    data,
  };
};

export const loadCompetitorAnalysisError = (err) => {
  return {
    type: constants.LOAD_COMPETITOR_ANALYSIS_ERROR,
    err,
  };
};

export const loadBenchmarks = () => {
  return {
    type: constants.LOAD_BENCHMARKS,
  };
};

export const loadBenchmarksSuccess = (data) => {
  return {
    type: constants.LOAD_BENCHMARKS_SUCCESS,
    data,
  };
};

export const loadBenchmarksError = (err) => {
  return {
    type: constants.LOAD_BENCHMARKS_ERROR,
    err,
  };
};

export const loadWordCloud = () => {
  return {
    type: constants.LOAD_WORDCLOUD,
  };
};

export const loadWordCloudSuccess = (data) => {
  return {
    type: constants.LOAD_WORDCLOUD_SUCCESS,
    data,
  };
};

export const loadWordCloudError = (err) => {
  return {
    type: constants.LOAD_WORDCLOUD_ERROR,
    err,
  };
};

export const loadSegmentAnalysis = () => {
  return {
    type: constants.LOAD_SEGMENT_ANALYSIS,
  };
};

export const loadSegmentAnalysisSuccess = (data) => {
  return {
    type: constants.LOAD_SEGMENT_ANALYSIS_SUCCESS,
    data,
  };
};

export const loadSegmentAnalysisError = (err) => {
  return {
    type: constants.LOAD_SEGMENT_ANALYSIS_FAILURE,
    err,
  };
};

export const clearSegmentAnalysis = () => {
  return {
    type: constants.CLEAR_SEGMENT_ANALYSIS,
  };
};

export const loadInviteStats = () => {
  return {
    type: constants.LOAD_INVITE_STATS,
  };
};

export const loadInviteStatsSuccess = (data) => {
  return {
    type: constants.LOAD_INVITE_STATS_SUCCESS,
    data,
  };
};

export const loadInviteStatsError = (err) => {
  return {
    type: constants.LOAD_INVITE_STATS_FAILURE,
    err,
  };
};

export const loadComments = () => ({
  type: constants.LOAD_COMMENTS,
});

export const loadCommentsSuccess = (data) => ({
  type: constants.LOAD_COMMENTS_SUCCESS,
  data,
});

export const loadCommentsError = (error) => ({
  type: constants.LOAD_COMMENTS_ERROR,
  error,
});

export const loadMoreComments = (nextUrl) => ({
  type: constants.LOAD_MORE_COMMENTS,
  nextUrl,
});

export const loadMoreCommentsSuccess = (data) => ({
  type: constants.LOAD_MORE_COMMENTS_SUCCESS,
  data,
});

export const loadMoreCommentsError = (error) => ({
  type: constants.LOAD_MORE_COMMENTS_ERROR,
  error,
});

import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { isBefore, addHours } from "date-fns";

import SearchBar from "components/common/SearchBar";
import { getOrganizationSlug } from "utils/genericHelper";
import { AlertMessage } from "components/common/AlertMessage";
import DownloadButton from "components/common/DownloadButton";
import InsightFilters from "components/common/InsightFilters";
import SelectedFiltersTags from "components/common/SelectedFiltersTags/SelectedFiltersTags";
import ResetFilter from "components/common/ResetFilter/ResetFilter";
import DateFilter from "components/common/DateFilter/DateFilter";
import SystemFilter from "components/common/SystemFilter/SystemFilter";
import QuestionFilter from "components/common/QuestionFilter/QuestionFilter";
import ProjectFilter from "components/common/ProjectFilter/ProjectFilter";
import GlobalViewSwitcher from "components/common/GlobalViewSwitcher/GlobalViewSwitcher";
import LocationFilter from "../LocationFilter/LocationFilter";
import ShiftFilter from "../ShiftFilter/ShiftFilter";
import { getViewOnlyFeedbackUUID } from "components/FeedbackPage/selectors";

import withRouter from "withRouter";

class SecondaryTopbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: localStorage.getItem(`${getOrganizationSlug()}_organizationId`),
      show: false,
    };
  }

  isActivityPermissions() {
    const { user } = this.props;
    return user.role && user.role.permissions.includes("filter_activity");
  }

  isAlertMessage() {
    const { user, location } = this.props;
    const { analytics_event } = user;
    const isDashboardPage = ["/dashboard", "/"].includes(location.pathname);
    if (!analytics_event) {
      return;
    }

    if (isDashboardPage) {
      const isActiveLink =
        analytics_event.expiry_time &&
        isBefore(
          new Date(),
          addHours(
            new Date(analytics_event.created_at),
            analytics_event.expiry_time,
          ),
        );
      if (!analytics_event.expiry_time || isActiveLink) {
        return true;
      }
    }
  }

  render() {
    const rack = this.props.selectedRacks.find((r) => r);

    const { isViewOnly } = getViewOnlyFeedbackUUID();
    if (isViewOnly || noFiltersRacks.includes(rack?.type)) {
      return null;
    }

    const showSearchBar = searchBarRacks.includes(rack?.type);

    const { user, selectedFilters, organization } = this.props;
    const { selectedDashboardId } = this.props;

    let colors = organization.features.colors;
    return (
      <div style={{ color: `${colors.text || "whitesmoke"}` }}>
        <Row className="secondary-topbar">
          {this.isAlertMessage() && (
            <AlertMessage analytics_event={user.analytics_event} />
          )}
          {showSearchBar ? <SearchBar colors={colors} /> : null}
          <Col
            md={showSearchBar ? 8 : this.isAlertMessage() ? 8 : 12}
            className="options"
          >
            {user.role.permissions.includes("can_download_reports") ? (
              <DownloadButton
                selectedFilters={selectedFilters}
                orgId={this.props.orgId}
              />
            ) : null}
            <ResetFilter />
            {selectedDashboardId === "omni" && <ProjectFilter />}
            <GlobalViewSwitcher />
            <DateFilter />
            <div className="option-group">
              <LocationFilter />
              <QuestionFilter />
              {user.role.permissions.includes("filter_system") ? (
                <SystemFilter />
              ) : null}
            </div>
            <ShiftFilter />
          </Col>
        </Row>
        <SelectedFiltersTags />
        {rack?.type === "insights" && (
          <Row className="secondary-topbar">
            <Col lg={12} sm={12} md={12}>
              <InsightFilters rack={rack} />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const searchBarRacks = ["feedback", "customer"];
const noFiltersRacks = ["survey_demo", "survey_script", "rca"];

const mapStateToProps = (state) => ({
  selectedFilters: state.selectedFilters,
  selectedRacks: state.dashboard.selectedRacks,
  selectedDashboardId: state.dashboard.selectedDashboard.id,
  orgId: state.organization.organization.id,
});

export default withRouter(connect(mapStateToProps)(SecondaryTopbar));

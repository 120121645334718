import React from "react";

import Fields from "./Fields";

import {
  FieldWrapper,
  BodyWrapper,
  ButtonWrapper,
  NextButton,
  ToolTip,
} from "./styled.js";

const CustomSection = ({ fields, handleNext, isError }) => {
  return (
    <BodyWrapper>
      {fields.map((field) => {
        const Field = Fields[field.field_type];
        return (
          <FieldWrapper>
            <Field key={field.key} field={field} />
          </FieldWrapper>
        );
      })}
      <ButtonWrapper>
        <NextButton onClick={handleNext} disabled={isError}>
          Next
          {isError ? (
            <ToolTip>Kindly fill in all the fields to continue</ToolTip>
          ) : (
            ""
          )}
        </NextButton>
      </ButtonWrapper>
    </BodyWrapper>
  );
};

export default CustomSection;

import axios from "axios";

import { getRequest, postRequest } from "../utils/request";
import { getOrganizationSlug } from "../utils/genericHelper";

export const organizationApi = (slug) => {
  let url = `v1/organizations/verify/${slug}`;

  return getRequest(url, true);
};

// Moved into organization/actions file.
export const getOrganizationFeatures = () => {
  let url = `/v1/organizations/features/`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getOrganizationConcerns = (organizationId) => {
  let url = `/v1/organizations/${organizationId}/concerns`;

  return getRequest(url, false);
};

export const submitFeedbackGroupApi = (data, feedbackGroupId) => {
  let url = feedbackGroupId
    ? `/v1/organizations/feedback_group/${feedbackGroupId}/`
    : "/v1/organizations/feedback_group/";

  return axios({
    url,
    method: feedbackGroupId ? "PUT" : "POST",
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getFeedbackGroupsList = () => {
  let url = `/v1/organizations/feedback_group/`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getFeedbackGroup = (id) => {
  let url = `/v1/organizations/feedback_group/${id}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getLevelsApi = (organizationId) => {
  let url = `/v1/organizations/${organizationId}/divisions/level/`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const submitLevelApi = (data, levelId) => {
  const baseUrl = `/v1/organizations/${data.organization}/divisions/level/`;
  let url = levelId ? `${baseUrl}${levelId}/` : `${baseUrl}`;

  return axios({
    url,
    method: levelId ? "PUT" : "POST",
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const fetchLevelApi = (organizationId, id) => {
  let url = `/v1/organizations/${organizationId}/divisions/level/${id}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const updateOrganizationApi = (data) => {
  let url = `/v1/organizations/${data.organization}/`;

  return axios({
    url,
    method: "PUT",
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const fetchOrganizationDivisionsApi = (orgId, page = 1) => {
  let url = page
    ? `/v1/organizations/${orgId}/divisions/division/?children=1&page=${page}`
    : `/v1/organizations/${orgId}/divisions/division/?children=1`;
  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getOrganizationLevelsApi = (orgId) => {
  let url = `/v1/organizations/${orgId}/divisions/level/?level_gt=0`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getDivisionsByLevelApi = (
  orgId,
  level = "",
  page = 1,
  search = "",
) => {
  let url = `/v1/organizations/${orgId}/divisions/division/?level=${level}&page=${page}&search=${search}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getPrimaryDivisionsApi = (orgId) => {
  let url = `/v1/organizations/${orgId}/divisions/division/?feedback_level=1`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const submitDivisionApi = (data, divisionId) => {
  const baseUrl = `/v1/organizations/${data.organization}/divisions/division/`;
  let url = divisionId ? `${baseUrl}${divisionId}/` : `${baseUrl}`;

  return axios({
    url,
    method: divisionId ? "PUT" : "POST",
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const fetchDivisionApi = (data) => {
  let url = `/v1/organizations/${data.orgId}/divisions/division/${data.divisionId}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getUsersApi = (orgId, currpage, query) => {
  let url = `/v1/users/users_info?organization=${orgId}&page=${currpage}&q=${query}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getUserRolesApi = (roleId) => {
  let url =
    parseInt(roleId) > 0
      ? `v1/users/user_roles/${roleId}/`
      : `v1/users/user_roles/`;
  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const saveUserRolesApi = (roleId, data) => {
  const isEdit = parseInt(roleId) > 0;
  const url = isEdit
    ? `v1/users/user_roles/${roleId}/`
    : "v1/users/user_roles/";
  const method = isEdit ? "PUT" : "POST";
  return axios({
    url,
    method,
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const toggleUserStatus = (userId, orgId) => {
  let url = `v1/users/users_info/${userId}/toggle-user-status/?organization=${orgId}`;

  return axios({
    url,
    method: "PUT",
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const saveUpdateUserApi = (userId, orgId, data, isEditPage) => {
  let url = `v1/users/users_info/${
    userId ? userId + "/" : ""
  }?organization=${orgId}`;

  return axios({
    url,
    method: isEditPage ? "PUT" : "POST",
    data,
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const getUserDetailApi = (userId, orgId) => {
  let url = `v1/users/users_info/${userId}?organization=${orgId}`;

  return axios.get(url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem(
        `${getOrganizationSlug()}_token`,
      )}`,
    },
  });
};

export const loadOrganizationTagsApi = () => {
  let url = `/v1/organizations/tag`;
  return getRequest(url);
};

export const createOrganizationTagApi = (tag) => {
  let url = `/v1/organizations/tag/`;
  return postRequest(url, tag);
};

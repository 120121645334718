import * as constants from "./constants";

export const loadNotifications = () => {
  return {
    type: constants.LOAD_NOTIFICATIONS,
  };
};

export const loadNotificationsSuccess = (data) => {
  return {
    type: constants.LOAD_NOTIFICATIONS_SUCCESS,
    data,
  };
};

export const loadNotificationsError = (err) => {
  return {
    type: constants.LOAD_NOTIFICATIONS_ERROR,
    err,
  };
};

export const markNotificationAsRead = (id) => ({
  type: constants.MARK_NOTIFICATION_AS_READ,
  id,
});

export const newNotification = (notification) => ({
  type: constants.NEW_NOTIFICATION,
  notification,
});

export const loadMoreNotifications = (url) => ({
  type: constants.LOAD_MORE_NOTIFICATIONS,
  url,
});

export const loadMoreNotificationsSuccess = (data) => ({
  type: constants.LOAD_MORE_NOTIFICATIONS_SUCCESS,
  data,
});

export const loadMoreNotificationsError = (err) => ({
  type: constants.LOAD_MORE_NOTIFICATIONS_ERROR,
  err,
});

export const remoteMarkNotificationAsRead = (notificationId) => ({
  type: constants.REMOTE_MARK_NOTIFICATION_AS_READ,
  notificationId,
});

import React, { Component } from "react";
import { Table, Popover, OverlayTrigger } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import CustomScroll from "react-custom-scroll";
import _ from "lodash";
import { loadBenchmarks } from "../actions";
import { TOOLTIP_MESSAGE } from "../../../constants/constants";
import { Panel } from "../../common/Panel";
import numeral from "numeral";

class Benchmarks extends Component {
  getAPI() {
    const { loadBenchmarks } = this.props;
    loadBenchmarks();
  }

  componentDidMount() {
    this.getAPI();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.selectedFilters, this.props.selectedFilters)) {
      this.getAPI();
    }
  }

  render() {
    const { benchmarks } = this.props;
    const { fetching, data } = benchmarks;

    const tooltipHeader = (
      <span title={TOOLTIP_MESSAGE.BENCHMARK_ACHIEVED}>Benchmark Achieved</span>
    );

    if (!data.length || fetching) {
      return (
        <Panel header={tooltipHeader}>
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    const statsPopover = (stats) => {
      const rolesToShow = Object.keys(stats)
        .filter((role) => stats[role])
        .filter((role) => !["segments", "percentage", "total"].includes(role));
      return rolesToShow.map(
        (role, i) =>
          `${role.toUpperCase()}: ${numeral(stats[role]).format("0,0")}`,
      );
    };

    return (
      <Panel header={tooltipHeader}>
        <div className="benchmarks">
          <CustomScroll heightRelativeToParent="100%">
            <Table responsive hover>
              <tbody>
                {data.map((benchmark) => {
                  return (
                    <tr key={benchmark.id}>
                      <td>{benchmark.title}</td>
                      <td
                        className="priority-value benchmarks"
                        title={statsPopover(benchmark.stats)}
                      >
                        {benchmark.stats.percentage}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CustomScroll>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, dashboard }) => ({
  selectedFilters,
  benchmarks: dashboard.benchmarks,
});

export default connect(mapStateToProps, {
  loadBenchmarks,
})(Benchmarks);

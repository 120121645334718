import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import numeral from "numeral";
import { getOrganizationSlug, isTruthy } from "../../../utils/genericHelper";
import InvoiceModal from "../invoice/InvoiceModal";

export const FeedbackTransactionAmount = ({ feedback }) => {
  const currency = getOrganizationSlug() === "axle-olio" ? "Rm." : "Rs.";
  const { t } = useTranslation();
  if (
    !feedback.feedback_transaction &&
    !feedback.transaction_monthly &&
    !feedback.transaction_yearly
  ) {
    return null;
  }

  return (
    <Row className="feedback-detail-transaction-box">
      <Col className="clear-col-padding-right" md={3} sm={12} xs={12}>
        <h5>Feedback Transaction</h5>
        {feedback.feedback_transaction
          ? `${currency} ${numeral(feedback.feedback_transaction).format(
              "0,0",
            )}`
          : "--"}
      </Col>
      <Col className="clear-col-padding" md={3} sm={12} xs={12}>
        <h5>{t("lastMonth")}</h5>
        {feedback.transaction_monthly
          ? `${currency} ${numeral(feedback.transaction_monthly).format("0,0")}`
          : "--"}
      </Col>
      <Col className="clear-col-padding" md={3} sm={12} xs={12}>
        <h5>Last 12 Months</h5>
        {feedback.transaction_yearly
          ? `${currency} ${numeral(feedback.transaction_yearly).format("0,0")}`
          : "--"}
      </Col>
      {isTruthy(feedback.invite) ? (
        <InvoiceModal
          inviteId={feedback.invite}
          isFeedbackInvoice={true}
          customerId={feedback.customer.id}
        />
      ) : null}
    </Row>
  );
};

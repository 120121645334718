import produce from "immer";

import { initialState } from "./initialState";
import * as constants from "./constants";

export const notificationReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_NOTIFICATIONS:
        draft.isLoading = true;
        break;

      case constants.LOAD_NOTIFICATIONS_SUCCESS:
        draft.isLoading = false;
        draft.data = action.data;
        break;

      case constants.LOAD_NOTIFICATIONS_ERROR:
        draft.isLoading = false;
        draft.isError = true;
        break;

      case constants.NEW_NOTIFICATION:
        draft.data.results.unshift(action.notification);
        draft.data.unread_count += 1;
        break;

      case constants.MARK_NOTIFICATION_AS_READ: {
        let notification = draft.data.results.find(
          (notification) => notification.id === action.id,
        );
        notification.seen_at = new Date();
        break;
      }

      case constants.LOAD_MORE_NOTIFICATIONS:
        draft.isMoreLoading = true;
        break;

      case constants.LOAD_MORE_NOTIFICATIONS_SUCCESS:
        draft.isMoreLoading = false;
        draft.data.results = draft.data.results.concat(action.data.results);
        draft.data.next = action.data.next;
        draft.data.previous = action.data.previous;
        break;

      case constants.LOAD_MORE_NOTIFICATIONS_ERROR:
        draft.isMoreLoading = false;
        draft.isError = true;
        break;
    }
  });
};

import React from "react";

import { MultipleElementsContainer, SingleElement, FullWidth } from "./styled";

import Field from "./Field";
import RootCauseSection from "./RootCausesView/RootCausesView";

export const RootCausesSection = (section) => {
  return <RootCauseSection />;
};

export const CustomSection = (section) => {
  return (
    <MultipleElementsContainer>
      {section.fields.map((field) => {
        const fieldKey = field.field_type.toLowerCase();
        if (fieldKey == "deployment_dates") {
          return (
            <>
              <SingleElement isFullWidth={field.is_full_width}>
                <Field
                  header={"Starting Date"}
                  field={field}
                  minHeight="35px"
                  fieldKey={"deployment_start_date"}
                />
              </SingleElement>
              <SingleElement isFullWidth={field.is_full_width}>
                <Field
                  header={"Expected ending date of analysis"}
                  field={field}
                  minHeight="35px"
                  fieldKey={"deployment_end_date"}
                />
              </SingleElement>
            </>
          );
        }
        return (
          <SingleElement isFullWidth={field.is_full_width}>
            <Field
              header={field.name}
              field={field}
              minHeight="35px"
              fieldKey={fieldKey}
            />
          </SingleElement>
        );
      })}
    </MultipleElementsContainer>
  );
};

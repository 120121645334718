import React, { Component } from "react";
import { Table } from "react-bootstrap";
import CustomScroll from "react-custom-scroll";
import numeral from "numeral";

class TopConcernsStats extends Component {
  render() {
    const { analysis } = this.props;

    return (
      <div className="priority-list">
        <CustomScroll heightRelativeToParent="100%">
          {Array.isArray(analysis) ? (
            <Table responsive hover>
              <tbody>
                {analysis.map((concern, i) => {
                  return (
                    <tr key={i} style={{ padding: 9 }}>
                      <td>
                        {concern.name} <br />
                        <span className="parent-option">
                          {concern.parent_name}
                        </span>
                      </td>
                      <td className="priority-value">
                        {numeral(concern.count).format("0,0")}{" "}
                        <span>{concern.percentage} %</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div className="error-message">
              <i className="fa fa-warning" />
              {analysis.detail}
            </div>
          )}
        </CustomScroll>
      </div>
    );
  }
}

export default TopConcernsStats;

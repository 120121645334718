import { isEmpty, isNil, omitBy } from "lodash";

export const transformAnalyzersData = (data) => {
  let finalData = {};

  if (isEmpty(data[0])) {
    return finalData;
  }

  Object.values(data).map(function (analyzer) {
    const key = Object.keys(analyzer)[0];
    if (!isEmpty(key)) {
      finalData[key] = analyzer[key]["fields"];
    }
  });

  return finalData;
};

export const transformFeedbackGroupData = (data) => {
  let finalData = {};

  Object.entries(data).map(function ([keyName, value]) {
    if (["charts", "pages"].includes(keyName)) {
      if (keyName === "pages") {
        let pages = [];
        Object.entries(data[keyName]).map(function ([key, value]) {
          if (value) {
            pages.push(key);
          }
        });
        finalData["pages"] = pages;
      } else {
        let charts = [];
        Object.entries(data[keyName]).map(function ([key, value]) {
          if (key !== "kpi_insights") {
            let fields = { fields: value };
            charts.push({ [key]: fields });
          }
        });
        finalData["charts"] = charts;
      }
    } else {
      finalData[keyName] = value;
    }
  });

  return finalData;
};

export const formatAnalyzerName = (analyzer) => {
  const analyzerName =
    typeof analyzer === "object" ? Object.keys(analyzer).toString() : analyzer;
  return analyzerName;
};

export const transformPages = (pages) => {
  let finalPages = {};

  pages.map(function (page) {
    finalPages[page] = true;
  });

  return finalPages;
};

export const transformObjectToArray = (data) => {
  let finalData = [];

  Object.entries(data).map(function ([key, value]) {
    finalData.push(key);
  });

  return finalData;
};

export const getLevelValueFromLevel = (levels, levelId) => {
  let level = "";

  Object.entries(levels).map(function ([index, lvl]) {
    if (lvl.id === parseInt(levelId)) {
      level = lvl.level;
    }
  });

  return level;
};

export const removeNullValues = (object) => {
  return omitBy(object, isNil);
};

export const hasTruthyKey = (obj) =>
  Object.keys(obj || {}).some((key) => obj[key]);

export const isFloat = (num) => {
  return typeof num === "number" && num % 1 !== 0;
};

export const toSlug = (text) =>
  text
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

export const hexToRGB = (hex) => {
  if (hex.length !== 7 && hex[0] !== "#") {
    return hex;
  }

  const r = parseInt(hex[1] + hex[2], 16);
  const g = parseInt(hex[3] + hex[4], 16);
  const b = parseInt(hex[5] + hex[6], 16);
  return `rgb(${r}, ${g}, ${b})`;
};

// Usage reference for RGB functions
// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors

/* eslint-disable no-unused-expressions */
export const RGB_Linear_Blend = (p, c0, c1) => {
  var i = parseInt,
    r = Math.round,
    P = 1 - p,
    [a, b, c, d] = c0.split(","),
    [e, f, g, h] = c1.split(","),
    x = d || h,
    j = x
      ? "," +
        (!d
          ? h
          : !h
          ? d
          : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ")")
      : ")";
  return (
    "rgb" +
    (x ? "a(" : "(") +
    r(
      i(a[3] == "a" ? a.slice(5) : a.slice(4)) * P +
        i(e[3] == "a" ? e.slice(5) : e.slice(4)) * p,
    ) +
    "," +
    r(i(b) * P + i(f) * p) +
    "," +
    r(i(c) * P + i(g) * p) +
    j
  );
};

/* eslint-disable no-unused-expressions */
export const RGB_Linear_Shade = (p, c) => {
  var i = parseInt,
    r = Math.round,
    // eslint-disable-next-line no-redeclare
    [a, b, c, d] = c.split(","),
    P = p < 0,
    t = P ? 0 : 255 * p,
    // eslint-disable-next-line no-redeclare
    P = P ? 1 + p : 1 - p;
  return (
    "rgb" +
    (d ? "a(" : "(") +
    r(i(a[3] == "a" ? a.slice(5) : a.slice(4)) * P + t) +
    "," +
    r(i(b) * P + t) +
    "," +
    r(i(c) * P + t) +
    (d ? "," + d : ")")
  );
};

/* eslint-disable no-unused-expressions */
export const RGB_Log_Blend = (p, c0, c1) => {
  var i = parseInt,
    r = Math.round,
    P = 1 - p,
    [a, b, c, d] = c0.split(","),
    [e, f, g, h] = c1.split(","),
    x = d || h,
    j = x
      ? "," +
        (!d
          ? h
          : !h
          ? d
          : r((parseFloat(d) * P + parseFloat(h) * p) * 1000) / 1000 + ")")
      : ")";
  return (
    "rgb" +
    (x ? "a(" : "(") +
    r(
      (P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 +
        p * i(e[3] == "a" ? e.slice(5) : e.slice(4)) ** 2) **
        0.5,
    ) +
    "," +
    r((P * i(b) ** 2 + p * i(f) ** 2) ** 0.5) +
    "," +
    r((P * i(c) ** 2 + p * i(g) ** 2) ** 0.5) +
    j
  );
};

/* eslint-disable no-unused-expressions */
export const RGB_Log_Shade = (p, c) => {
  var hex;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(c)) {
    hex = c.substring(1).split("");
    if (hex.length == 3) {
      hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]];
    }
    hex = "0x" + hex.join("");
    c =
      "rgb(" + [(hex >> 16) & 255, (hex >> 8) & 255, hex & 255].join(",") + ")";
  }
  var i = parseInt,
    r = Math.round,
    // eslint-disable-next-line no-redeclare
    [a, b, c, d] = c.split(","),
    P = p < 0,
    t = P ? 0 : p * 255 ** 2,
    // eslint-disable-next-line no-redeclare
    P = P ? 1 + p : 1 - p;
  return (
    "rgb" +
    (d ? "a(" : "(") +
    r((P * i(a[3] == "a" ? a.slice(5) : a.slice(4)) ** 2 + t) ** 0.5) +
    "," +
    r((P * i(b) ** 2 + t) ** 0.5) +
    "," +
    r((P * i(c) ** 2 + t) ** 0.5) +
    (d ? "," + d : ")")
  );
};

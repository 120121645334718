import {
  FETCHING_USER_DETAIL,
  USER_DETAIL_SUCCESS,
  UPDATING_USER_DETAIL,
  USER_DETAIL_FAIL,
} from "./constants";
import { UNAUTH_USER } from "constants/constants";
import { loadUserInfo } from "components/LoginPage/actions";
import { getRequest, axiosPutRequest } from "utils/request";

const fetchingUserDetail = () => ({ type: FETCHING_USER_DETAIL });

const updatingUserDetail = () => ({ type: UPDATING_USER_DETAIL });

const userDetailSuccess = (user) => ({
  type: USER_DETAIL_SUCCESS,
  user,
});

const userDetailFail = (err) => ({
  type: USER_DETAIL_FAIL,
  err,
});

const getUserDetail = () => {
  return (dispatch) => {
    dispatch(fetchingUserDetail());

    const url = "/v1/users/detail";
    return getRequest(url)
      .then((data) => {
        dispatch(userDetailSuccess(data));
        return data;
      })
      .catch((err) => {
        /*console.log(err)*/
      });
  };
};

const updateUserDetail = (params, file, unAuth) => {
  return (dispatch) => {
    dispatch(updatingUserDetail());

    let data = new FormData();

    if (file && file instanceof File) {
      data.append("avatar", file);
    } else {
      if (params["avatar"] === null) {
        params["avatar"] = "";
      } else {
        delete params["avatar"];
      }
    }

    for (let key in params) {
      data.append(key, params[key]);
    }

    const url = "v1/users/detail";
    return axiosPutRequest(url, data)
      .then((res) => {
        dispatch(userDetailSuccess(res.data));
        dispatch(loadUserInfo());

        if (unAuth) {
          localStorage.removeItem("userId");
          localStorage.removeItem("tokenExpiresAt");

          dispatch({ type: UNAUTH_USER });
        } else {
          return res.data;
        }
      })
      .catch((err) => {
        dispatch(userDetailFail(err.response.data));
      });
  };
};

export { getUserDetail, updateUserDetail };

import React from "react";
import { InfoCard, Heading, Body } from "./styled";

export const AccordianInfoCard = ({ width, header, body, minHeight }) => {
  return (
    <InfoCard width={width}>
      <Heading>{header}</Heading>
      <Body minHeight={minHeight}>{body || "--"}</Body>
    </InfoCard>
  );
};

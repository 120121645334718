import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { loadNotifications } from "./actions";
import { selectUnreadNotifications } from "./selectors";
import NotificationMenu from "./NotificationMenu";
import * as analytics from "utils/analytics";
import { TooltipText } from "components/common/Tooltip";
import { TOOLTIPS } from "constants/constants";

const NotificationDropDown = () => {
  const [isOpen, setOpen] = useState(false);
  const unread = useSelector(selectUnreadNotifications);
  const { t } = useTranslation();

  const ref = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadNotifications());

    const onBodyClick = (event) => {
      if (ref.current.contains(event.target))
        analytics.logNotificationDropDownOpen();
      else setOpen(false);
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  return (
    <button
      className={`tooltipRel notification-button ${
        unread ? "wrapper__button" : ""
      }`}
      type="button"
      ref={ref}
      onClick={() => setOpen(!isOpen)}
    >
      <i className="fa fa-bell" />
      {unread ? <span className="counter">{unread}</span> : null}
      {isOpen && <NotificationMenu />}
      {!isOpen && (
        <TooltipText tooltipText={t("notifications")} align="bottomRight" />
      )}
    </button>
  );
};

export default NotificationDropDown;

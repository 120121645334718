import produce, { createDraft, finishDraft } from "immer";
import * as constants from "./constants";
import {
  initialState,
  defaultQuestionForm,
  defaultOptionForm,
  defaultSubOptionForm,
} from "./initialState";
import { maxBy, remove } from "lodash";
import { isFalsy } from "../../utils/genericHelper";

const recalculateIndex = (data) => {
  data.forEach((_d, index) => {
    _d.index = index + 1;
  });
};

const recalculateSectionIndex = (data, isDragUp) => {
  data.forEach((d) => {
    d.section_index += isDragUp ? 1 : -1;
  });
};

const getMaxIndex = (data) => {
  return isFalsy(data) ? 0 : maxBy(data, (d) => d.index).index;
};

/* eslint-disable default-case, no-param-reassign */
export const surveyBuilderReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.SB_GET_ALL_SURVEYS_SUCCESS: {
        draft.surveyList.surveys = action.data;
        break;
      }

      case constants.SB_GET_ALL_SURVEYS_ERROR: {
        draft.surveyList.error = action.error;
        break;
      }

      case constants.SB_TOGGLE_SURVEY_STATUS_SUCCESS: {
        const { surveyId, data } = action;
        let surveyIndex = draft.surveyList.surveys.findIndex(
          (s) => s.id === surveyId,
        );
        draft.surveyList.surveys[surveyIndex] = data;
        break;
      }

      case constants.SB_CHANGE_SURVEY_LIST_PAGE: {
        draft.surveyList.currentPage = action.page;
        break;
      }

      case constants.SB_SET_ACTIVE_TAB: {
        draft.ui.tabs.activeTab = action.tabKey;
        break;
      }

      case constants.SB_SET_QUESTIONNAIRE_ANTI_SPAMMER: {
        const { criteriaName, isActive } = action.payload;
        const antispammerIndex =
          draft.questionnaire.questionnaire_antispammers.findIndex(
            (antispammer) => antispammer.criteria === criteriaName,
          );

        draft.questionnaire.questionnaire_antispammers[
          antispammerIndex
        ].is_active = isActive;
        break;
      }

      case constants.SB_GET_QUESTIONNAIRE: {
        draft.ui.loading.questionnaire = true;
        break;
      }
      case constants.SB_GET_QUESTIONNAIRE_SUCCESS: {
        draft.ui.loading.questionnaire = false;
        draft.questionnaire = action.questionnaire;

        // checking if the config api is resolved after reset.
        if (
          draft.questionnaire.colors === null &&
          !isFalsy(draft.config.surveyColors)
        )
          draft.questionnaire.colors = draft.config.surveyColors;

        if (
          draft.questionnaire.font_sizes === null &&
          !isFalsy(draft.config.surveyFontSizes)
        )
          draft.questionnaire.font_sizes = draft.config.surveyFontSizes;

        if (
          draft.questionnaire.font_styles === null &&
          !isFalsy(draft.config.surveyFontStyles)
        )
          draft.questionnaire.font_styles = draft.config.surveyFontStyles;

        if (
          isFalsy(draft.questionnaire.questionnaire_antispammers) &&
          !isFalsy(draft.config.surveyAntiSpammers)
        )
          draft.questionnaire.questionnaire_antispammers =
            draft.config.surveyAntiSpammers;

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_GET_QUESTIONNAIRE_ERROR: {
        draft.ui.loading.questionnaire = false;
        draft.ui.error.questionnaire = action.error;
        break;
      }

      case constants.SB_GET_QUESTIONNAIRE_LIST: {
        draft.ui.loading.surveys = true;
        break;
      }
      case constants.SB_GET_QUESTIONNAIRE_LIST_SUCCESS: {
        draft.ui.loading.surveys = false;
        draft.config.surveys = action.surveys;
        break;
      }
      case constants.SB_GET_QUESTIONNAIRE_LIST_ERROR: {
        draft.ui.loading.surveys = false;
        draft.ui.error.surveys = action.error;
        break;
      }

      case constants.SB_RESET_QUESTIONNAIRE: {
        localStorage.removeItem("survey");
        localStorage.removeItem("surveySelectedState");
        localStorage.removeItem("surveyConfig");

        return finishDraft(createDraft(initialState));
      }
      case constants.SB_RESET_QUESTIONNAIRE_SUCCESS: {
        draft.ui.success.questionnaire = false;
        break;
      }
      case constants.SB_RESET_QUESTIONNAIRE_ERROR: {
        draft.ui.error.questionnaire = false;
        break;
      }
      case constants.SB_SET_QUESTIONNAIRE: {
        const { survey } = action.payload;
        draft.questionnaire = survey;
        break;
      }
      case constants.SB_SET_QUESTIONNAIRE_SELECTED_STATE: {
        const { surveySelectedState } = action.payload;
        draft.selected = surveySelectedState;
        break;
      }
      case constants.SB_SET_QUESTIONNAIRE_FIELD: {
        const { field, val } = action.payload;
        draft.questionnaire[field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_REMOVE_QUESTIONNAIRE_IMAGE: {
        draft.questionnaire[action.field] = null;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SET_SURVEY_COLORS: {
        const { field, val } = action.payload;
        draft.questionnaire.colors[field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SET_SURVEY_FONT_STYLES: {
        const { field, val } = action.payload;
        draft.questionnaire.font_styles[field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SET_SURVEY_FONT_SIZES: {
        const { field, val } = action.payload;
        draft.questionnaire.font_sizes[field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_ADD_QUESTION_FORM: {
        const maxIndex = getMaxIndex(draft.questionnaire.questions);
        draft.questionnaire.questions.push({
          ...defaultQuestionForm,
          index: maxIndex + 1,
          virtual_id: maxIndex + 1,
          section_index: maxIndex + 1,
        });
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_REMOVE_QUESTION_FORM: {
        const { questionVirtualId } = action.payload;
        remove(
          draft.questionnaire.questions,
          (q) => q.virtual_id === questionVirtualId,
        );

        if (draft.selected.question === questionVirtualId) {
          // reset the selection
          draft.selected.question = -1;
          draft.selected.option = -1;
          draft.selected.subOption = -1;
        }

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_SET_QUESTION_POSITION: {
        const { oldQuestionIndex, newQuestionIndex } = action.payload;
        const newSectionIndex =
          draft.questionnaire.questions[newQuestionIndex].section_index;

        draft.questionnaire.questions[oldQuestionIndex].section_index =
          newSectionIndex;
        if (newQuestionIndex > oldQuestionIndex) {
          const questionsBelowTheNewQuestion =
            draft.questionnaire.questions.slice(
              oldQuestionIndex + 1,
              newQuestionIndex + 1,
            );

          recalculateSectionIndex(questionsBelowTheNewQuestion, false);
        } else if (oldQuestionIndex > newQuestionIndex) {
          const questionsAboveTheNewQuestion =
            draft.questionnaire.questions.slice(
              newQuestionIndex,
              oldQuestionIndex,
            );

          recalculateSectionIndex(questionsAboveTheNewQuestion, true);
        }
        const [question] = draft.questionnaire.questions.splice(
          oldQuestionIndex,
          1,
        );
        draft.questionnaire.questions.splice(newQuestionIndex, 0, question);
        recalculateIndex(draft.questionnaire.questions);
        if (draft.ui.error.questionnaire) {
          const questions = draft.ui.error.questionnaire.questions;
          const [questionError] =
            questions && questions.splice(oldQuestionIndex, 1);
          questions && questions.splice(newQuestionIndex, 0, questionError);
        }
        break;
      }
      case constants.SB_SET_QUESTION_FIELD: {
        const { field, val, questionVirtualId } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        draft.questionnaire.questions[questionIndex][field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SET_QUESTION_IMAGE: {
        const { file, questionVirtualId } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        draft.questionnaire.questions[questionIndex]["question_images"].push(
          file,
        );
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SET_QUESTION_IMAGE_POSITION: {
        const { position, questionVirtualId, imageIndex } = action.payload;
        const question = draft.questionnaire.questions.find(
          (q) => q.virtual_id === questionVirtualId,
        );
        question.question_images[imageIndex].position = position;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_REMOVE_QUESTION_IMAGE: {
        const { questionVirtualId, imageIndex } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        let questionImages =
          draft.questionnaire.questions[questionIndex]["question_images"];
        questionImages[imageIndex].is_active = false;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_ADD_OPTION_FORM: {
        const { questionVirtualId } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const maxIndex = getMaxIndex(
          draft.questionnaire.questions[questionIndex].options,
        );

        const newOption = {
          ...defaultOptionForm,
          index: maxIndex + 1,
          virtual_id: maxIndex + 1,
        };
        draft.questionnaire.questions[questionIndex].options.push(newOption);

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_REMOVE_OPTION_FORM: {
        const { questionVirtualId, optionVirtualId } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        remove(
          draft.questionnaire.questions[questionIndex].options,
          (o) => o.virtual_id === optionVirtualId,
        );

        if (draft.selected.option === optionVirtualId) {
          // reset the selection
          draft.selected.option = -1;
          draft.selected.subOption = -1;
        }

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_SET_OPTION_POSITION: {
        const { questionVirtualId, oldOptionIndex, newOptionIndex } =
          action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const question = draft.questionnaire.questions[questionIndex];
        const option = question.options[oldOptionIndex];
        question.options.splice(oldOptionIndex, 1);
        question.options.splice(newOptionIndex, 0, option);
        recalculateIndex(question.options);
        if (draft.ui.error.questionnaire) {
          const questions = draft.ui.error.questionnaire.questions;
          const questionError = questions && questions[questionIndex];
          const [optionError] =
            questionError && questionError.options.splice(oldOptionIndex, 1);
          questionError &&
            questionError.options.splice(newOptionIndex, 0, optionError);
        }
        break;
      }
      case constants.SB_SET_OPTION_FIELD: {
        const { field, val, questionVirtualId, optionVirtualId } =
          action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const optionIndex = draft.questionnaire.questions[
          questionIndex
        ].options.findIndex((o) => o.virtual_id === optionVirtualId);

        draft.questionnaire.questions[questionIndex].options[optionIndex][
          field
        ] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_ADD_SUB_OPTION_FORM: {
        const { questionVirtualId, optionVirtualId } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const optionIndex = draft.questionnaire.questions[
          questionIndex
        ].options.findIndex((o) => o.virtual_id === optionVirtualId);
        const maxIndex = getMaxIndex(
          draft.questionnaire.questions[questionIndex].options[optionIndex]
            .sub_options,
        );

        const newSubOption = {
          ...defaultSubOptionForm,
          index: maxIndex + 1,
          virtual_id: maxIndex + 1,
        };
        draft.questionnaire.questions[questionIndex].options[
          optionIndex
        ].sub_options.push(newSubOption);
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_REMOVE_SUB_OPTION_FORM: {
        const { questionVirtualId, optionVirtualId, subOptionVirtualId } =
          action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const optionIndex = draft.questionnaire.questions[
          questionIndex
        ].options.findIndex((o) => o.virtual_id === optionVirtualId);
        remove(
          draft.questionnaire.questions[questionIndex].options[optionIndex]
            .sub_options,
          (so) => so.virtual_id === subOptionVirtualId,
        );

        if (draft.selected.subOption === subOptionVirtualId) {
          // reset selection if selected suboption is removed
          draft.selected.subOption = -1;
        }

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_SET_SUB_OPTION_POSITION: {
        const {
          questionVirtualId,
          optionVirtualId,
          oldSubOptionIndex,
          newSubOptionIndex,
        } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const question = draft.questionnaire.questions[questionIndex];
        const optionIndex = question.options.findIndex(
          (o) => o.virtual_id === optionVirtualId,
        );
        const option = question.options[optionIndex];
        const [subOption] = option.sub_options.splice(oldSubOptionIndex, 1);
        option.sub_options.splice(newSubOptionIndex, 0, subOption);
        recalculateIndex(option.sub_options);

        if (draft.ui.error.questionnaire) {
          const questions = draft.ui.error.questionnaire.questions;
          const questionError = questions && questions[questionIndex];
          const optionError =
            questionError && questionError.options[optionIndex];
          const [subOptionError] =
            optionError && optionError.sub_options.splice(oldSubOptionIndex, 1);
          optionError &&
            optionError.sub_options.splice(
              newSubOptionIndex,
              0,
              subOptionError,
            );
        }
        break;
      }
      case constants.SB_SET_SUB_OPTION_FIELD: {
        const {
          field,
          val,
          questionVirtualId,
          optionVirtualId,
          subOptionVirtualId,
        } = action.payload;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const optionIndex = draft.questionnaire.questions[
          questionIndex
        ].options.findIndex((o) => o.virtual_id === optionVirtualId);
        const subOptionIndex = draft.questionnaire.questions[
          questionIndex
        ].options[optionIndex].sub_options.findIndex(
          (so) => so.virtual_id === subOptionVirtualId,
        );

        draft.questionnaire.questions[questionIndex].options[
          optionIndex
        ].sub_options[subOptionIndex][field] = val;
        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SUBMIT_QUESTIONNAIRE: {
        draft.ui.isSubmitting = true;
        break;
      }
      case constants.SB_SUBMIT_QUESTIONNAIRE_SUCCESS: {
        draft.ui.isSubmitting = false;
        draft.ui.error.questionnaire = null;
        draft.ui.success.questionnaire = true;
        draft.questionnaire = action.questionnaire;

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
      case constants.SB_SUBMIT_QUESTIONNAIRE_ERROR: {
        draft.ui.isSubmitting = false;
        draft.ui.success.questionnaire = false;
        draft.ui.error.questionnaire = action.error;
        break;
      }
      case constants.SB_GET_SURVEY_CONFIGS_SUCCESS: {
        const configs = action.config;
        draft.config.surveyTypes = configs.questionnaire_types;
        draft.config.surveyAntiSpammers = configs.questionnaire_antispammers;
        draft.config.roles = configs.user_roles;
        draft.config.feedbackGroups = configs.feedback_groups;
        draft.config.divisions = configs.primary_divisions;
        draft.config.questionKinds = configs.question_configs.kind;
        draft.config.levels = configs.levels;
        draft.config.questionTypes = configs.question_configs.type;
        draft.config.questionScreenTypes = configs.question_configs.screen_type;
        draft.config.optionScoreTypes = configs.option_configs.option_score;
        draft.config.optionLoopTypes = configs.option_configs.option_loops;
        draft.config.chartTypes = configs.chart_types;
        draft.config.surveyColors = configs.survey_colors;
        draft.config.surveyFontStyles = configs.survey_font_styles;
        draft.config.surveyFontSizes = configs.survey_font_sizes;
        draft.config.supportedFontStyles = configs.supported_font_styles;
        draft.config.userRolePermissions = configs.user_role_permissions;

        if (draft.questionnaire.colors === null)
          draft.questionnaire.colors = configs.survey_colors;

        if (draft.questionnaire.font_styles === null)
          draft.questionnaire.font_styles = configs.survey_font_styles;

        if (draft.questionnaire.font_sizes === null)
          draft.questionnaire.font_sizes = configs.survey_font_sizes;

        if (isFalsy(draft.questionnaire.questionnaire_antispammers))
          draft.questionnaire.questionnaire_antispammers =
            configs.questionnaire_antispammers;

        localStorage.setItem("surveyConfig", JSON.stringify(draft.config));
        break;
      }
      case constants.SB_SET_SELECTED_QUESTION_VIRTUAL_ID: {
        draft.selected.question = action.questionVirtualId;
        draft.selected.option = -1;
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_SET_SELECTED_OPTION_VIRTUAL_ID: {
        const selectedQuestionIndex = draft.selected.question;
        if (selectedQuestionIndex === -1) {
          draft.selected.option = -1;
          return;
        }

        draft.selected.option = action.optionVirtualId;
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_SET_SELECTED_SUB_OPTION_VIRTUAL_ID: {
        const selectedOptionIndex = draft.selected.option;
        if (selectedOptionIndex === -1) {
          draft.selected.subOption = -1;
          return;
        }

        draft.selected.subOption = action.subOptionVirtualId;
        localStorage.setItem(
          "surveySelectedState",
          JSON.stringify(draft.selected),
        );
        break;
      }
      case constants.SB_REMOVE_OPTION_IMAGE: {
        const { questionVirtualId, optionVirtualId } = action;
        const questionIndex = draft.questionnaire.questions.findIndex(
          (q) => q.virtual_id === questionVirtualId,
        );
        const optionIndex = draft.questionnaire.questions[
          questionIndex
        ].options.findIndex((o) => o.virtual_id === optionVirtualId);

        draft.questionnaire.questions[questionIndex].options[
          optionIndex
        ].image = null;

        localStorage.setItem("survey", JSON.stringify(draft.questionnaire));
        break;
      }
    }
  });
};

import { call, takeLatest, select, put } from "redux-saga/effects";

import * as apis from "./apis";
import * as actions from "./actions";
import * as constants from "constants/constants";
import { getOrganizationId } from "components/Organization/selectors";
import { getFiltersQueryString } from "components/common/filters/selectors";

export function* loadDivisions() {
  try {
    const organizationId = yield select(getOrganizationId);
    const filters = yield select(getFiltersQueryString);
    const data = yield call(apis.getDivisionsAPI, organizationId, filters);
    yield put(actions.loadDivisionsSuccess(data));
  } catch (error) {
    yield put(actions.loadDivisionsError(error));
  }
}

function* divisionsWatcher() {
  yield takeLatest(constants.LOAD_DIVISIONS, loadDivisions);
}

export const sagas = [divisionsWatcher];

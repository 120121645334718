import styled from "styled-components";

export const TotalCount = styled.div`
  font-size: 14px;
  text-align: right;
  display: inline-block;
  float: right;
  position: absolute;
  right: 0;
  top: 2px;
  bottom: 0;
  margin: auto;
  left: auto;

  span {
    color: var(--primary-color);
    font-weight: bold;
  }
`;

export const CommentBox = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid #707070;
  }
  padding: 5px 20px;
  cursor: pointer;
`;

export const CommentItemFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CommentItemHeader = styled.div`
  margin-bottom: 5px;
  display: block;
`;

export const CommentItemContent = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  padding: 3px 0;
`;

export const CommentsContainer = styled.div`
  border: 1px solid #707070;
  overflow-y: scroll;
  max-height: 300px;
  width: 100%;
`;

export const StyledLocation = styled.div`
  text-align: right;
  font-size: 14px;
  float: right;
  display: inline-block;
  margin-left: auto;
`;

export const Badge = styled.div`
  font-size: 12px;
  border-radius: 2px;
  display: inline-block;
  font-family: "SegoeUI-Bold", Arial, Helvetica, sans-serif;
  color: var(--primary-color);

  &:not(:last-child) {
    margin-right: 5px;
    &::after {
      content: ",";
    }
  }
`;

export const StyledRightItem = styled.div`
  display: inline-block;
  float: right;
  flex: 1;
`;

export const StyledLeftItem = styled.div`
  display: inline-block;
  margin-right: 10px;
  flex: 1;
`;

export const StyledTimeStamp = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
`;

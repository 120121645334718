import { range } from "lodash";
import { pipe, filter, flatMap, keyBy } from "lodash/fp";

import { DEPENDENCY_TYPE } from "components/SurveyBuilder/constants";

const validateStageDependencyUsages = (survey) => {
  if (!survey.multi_stage) return { valid: true };

  const getSurveyStages = pipe(
    flatMap((question) => question.depends_upon),
    filter((dependency) => dependency.type === DEPENDENCY_TYPE.STAGE),
    keyBy((dependency) => dependency.stage),
  );
  const stages = getSurveyStages(survey.questions);

  const unusedStages = range(1, survey.max_stage + 1).filter((s) => !stages[s]);
  const valid = !unusedStages.length;

  const msg = valid
    ? ""
    : "Total number of survey stages is set to " +
      survey.max_stage +
      " but following stages have no questions assigned to them: Stage " +
      unusedStages.join(", Stage ");

  return { valid, msg, type: "Multi Stage" };
};

const validators = [validateStageDependencyUsages];

export const validateSurvey = (survey) =>
  validators
    .map((validator) => validator(survey))
    .filter((status) => !status.valid)
    .reduce((errors, error) => {
      // Match error reporting style to backend
      const errorObj = errors || {};
      errorObj[error.type] = [error.msg];
      return errorObj;
    }, null);

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { last } from "lodash";
import { Col, Row } from "react-bootstrap";

import { TooltipText } from "components/common/Tooltip";
import MiniSpinner, { SpinnerContainer } from "components/common/MiniSpinner";
import { loadSurveyScript } from "components/Survey/actions";
import { boldText } from "utils/genericHelper";

import { ReactComponent as CheckBox } from "assets/svg/circle.svg";

import * as Styled from "./styled";

const SurveyScript = () => {
  const dispatch = useDispatch();

  const questionnaireId = useSelector(
    (state) => state.dashboard.selectedDashboard.id,
  );
  const script = useSelector((state) => state.survey.script);

  useEffect(() => {
    dispatch(loadSurveyScript(questionnaireId));
  }, [questionnaireId]);

  if (script.loading || !script.questions.length) {
    return (
      <SpinnerContainer>
        <MiniSpinner />
      </SpinnerContainer>
    );
  }

  return (
    <Styled.Container>
      <Row className="justify-content-center">
        {script.questions.map((question) => {
          const questionDependencies = dependenciesToJSX(question.dependencies);
          return (
            <Col key={question.id} lg={8} ref={(r) => (nodes[question.id] = r)}>
              <Styled.Card>
                <Styled.Heading>
                  Q.{question.index} {boldText(question.text)}
                </Styled.Heading>
                {questionDependencies && (
                  <Styled.HeadingSubtext>
                    {questionDependencies}
                  </Styled.HeadingSubtext>
                )}
                <Styled.CardContent>
                  {question.options.map((option) => {
                    const optionDependecies = dependenciesToString(
                      option.dependencies,
                    );
                    return (
                      <>
                        <Styled.OptionContainer>
                          <Styled.Option
                            className="tooltipRel"
                            ref={(r) => (nodes[option.id] = r)}
                          >
                            <CheckBox width={12} height={12} />
                            <Styled.OptionText>{option.text}</Styled.OptionText>
                            {optionDependecies && (
                              <Styled.OptionSubText>
                                <TooltipText
                                  align="bottomLeft"
                                  tooltipText={optionDependecies}
                                />
                              </Styled.OptionSubText>
                            )}
                          </Styled.Option>
                        </Styled.OptionContainer>
                      </>
                    );
                  })}
                </Styled.CardContent>
              </Styled.Card>
            </Col>
          );
        })}
      </Row>
    </Styled.Container>
  );
};

const dependenciesToJSX = (dependencies) => {
  // TODO: Support other dependencies
  if (dependencies.OP) {
    const _dependencies = dependencies.OP;
    if (_dependencies.show?.length || _dependencies.hide?.length) {
      return (
        <>
          {"If "}
          <DependencyJSX.OP dependencies={_dependencies} />
        </>
      );
    }
  }
};

const dependenciesToString = (dependencies) => {
  // TODO: Support other dependencies
  if (dependencies.OP) {
    const _dependencies = dependencies.OP;
    if (_dependencies.show?.length || _dependencies.hide?.length) {
      return "If " + DependencyString.OP(_dependencies);
    }
  }
};

const OptionDependencyJSX = ({ dependencies }) => {
  const { show, hide } = dependencies;
  return (
    <span>
      {show.map((question) =>
        optionJSX(question, "show", question === last(show)),
      )}
      {hide.length && show.length ? " and " : ""}
      {hide.map((question) =>
        optionJSX(question, "hide", question === last(hide)),
      )}
    </span>
  );
};

const OptionDependencyString = (dependencies) => {
  const { show, hide } = dependencies;
  let text = "";

  show.forEach((question) => {
    text += optionString(question, "show", question === last(show));
  });
  text += hide.length && show.length ? " and " : "";
  hide.forEach((question) => {
    text += optionString(question, "hide", question === last(hide));
  });

  return text;
};

const DependencyJSX = {
  OP: OptionDependencyJSX,
};
const DependencyString = {
  OP: OptionDependencyString,
};

const optionJSX = (question, action, isLast) => {
  const hide = action === "hide";
  return (
    <>
      {question.options.map((option) => {
        return (
          <span
            style={{ cursor: "pointer", color: "var(--primary-color)" }}
            onClick={() => scrollToNode(question.id)}
          >
            {option.text}
            {option === last(question.options) ? " " : ", "}
          </span>
        );
      })}
      {"of "}
      <span
        className="tooltipRel"
        style={{ cursor: "pointer", color: "var(--primary-color)" }}
        onClick={() => scrollToNode(question.id)}
      >
        Q.{question.index}
        <TooltipText tooltipText={question.text} />
      </span>
      {` is ${hide ? "not " : ""}selected ${
        !isLast ? (hide ? "and " : "or ") : ""
      }`}
    </>
  );
};

const optionString = (question, action, isLast) => {
  const hide = action === "hide";
  let text = "";
  question.options.forEach((option) => {
    text += option.text;
    text += option === last(question.options) ? " " : ", ";
  });

  text += "of ";

  text += "Q." + question.index;
  text += ` is ${hide ? "not " : ""}selected ${
    !isLast ? (hide ? "and " : "or ") : ""
  }`;

  return text;
};

export default SurveyScript;

const nodes = {};

const scrollToNode = (id) => {
  const node = nodes[id];
  const y = node?.offsetTop || 0;
  window.scrollTo({ top: y - 50, behavior: "smooth" });
};

import styled, { css } from "styled-components";

export const SliderWrapper = styled.div`
  width: 100%;
  padding: 0px 10px;
  position: relative;

  .carousel .control-dots {
    bottom: 45px;
  }

  .carousel-status {
    color: black;
  }

  .carousel .slide {
    background: none;
  }

  .carousel img {
    width: auto;
  }

  .carousel .slide img {
    width: auto;
    height: 270px;
    width: auto;
  }

  .carousel.carousel-slider .control-arrow {
    bottom: 40px;
  }
`;

export const SliderLabel = styled.div`
  color: var(--primary-color);
  background: white;
  padding: 10px solid;
  width: 100%;
  text-align: center;
  line-height: 2;
  margin-top: 10px;
`;

export const ViewFeedbackLabel = styled.div`
  color: var(--primary-color);
  background: white;
  padding-right: 10px;
  width: 100%;
  text-align: center;
  line-height: 2;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

export const SliderButton = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 10px;
  display: none;
  cursor: pointer;
  transition: background 10s ease-out;

  ${(props) =>
    props.show &&
    css`
      display: block;
      background: rgba(0, 0, 0, 0.6);
    `}
`;

export const SliderIcon = styled.i`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
`;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { useTranslation } from "react-i18next";

import { submitRemarks, loadRemarks } from "components/FeedbackPage/actions";
import { FeedbackAction } from "constants/constants";
import { getFeedbackActionFilterLabel } from "components/selectors";
import { loadUsersApi } from "components/FeedbackPage/apis";
import { UserAvatar } from "components/common/UserAvatar";
import { getActionIcon } from "utils/dynamicCssClasses";
import { boldText } from "utils/genericHelper";
import { feedbackActionLabelText } from "utils/feedbackActionLabel";
import * as analytics from "utils/analytics";

import * as S from "./styled";
import { getFormatedDate } from "utils/dateUtils";

const Remarks = ({ feedback }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRemarks(feedback.id));
  }, [feedback.id]);

  const [users, setUsers] = useState([]);
  useEffect(() => {
    loadUsersApi().then((users) => {
      users.forEach((user) => (user.display = user.full_name));
      setUsers(users);
    });
  }, []);

  const permissions = useSelector((state) => state.auth.user.permissions);

  const FeedbackActionLabel = useSelector(getFeedbackActionFilterLabel);

  const isLoading = useSelector(
    (state) => state.feedback.selectedFeedback.isRemarksLoading,
  );

  if (isLoading) {
    return (
      <S.Container>
        <S.Loading>Loading previous converstations...</S.Loading>
        <RemarksInput disable feedback={feedback} users={users} />
      </S.Container>
    );
  }

  return (
    <S.Container>
      {feedback.remarks?.map((remark) => {
        const name =
          (remark.user.first_name || "") + " " + (remark.user.last_name || "");

        return (
          <S.RemarkContainer>
            <UserAvatar user={remark.user} />

            <S.RemarkSubContainer>
              <S.RemarkDetail>
                <S.Name>{name}</S.Name>
                <S.Date>
                  {remark.created_at
                    ? getFormatedDate(new Date(remark.created_at))
                    : ""}
                </S.Date>
                {remark.action && permissions?.api_action_stats && (
                  <S.RemarkAction>
                    <i
                      className={`fa fa-${getActionIcon(remark.action.type)}`}
                    />
                    {feedbackActionLabelText(
                      feedback,
                      FeedbackActionLabel,
                      remark.action.type,
                    )}
                  </S.RemarkAction>
                )}
              </S.RemarkDetail>
              <S.RemarkText>
                <ReadMoreText>{remark.text}</ReadMoreText>
              </S.RemarkText>
            </S.RemarkSubContainer>
          </S.RemarkContainer>
        );
      })}
      <RemarksInput feedback={feedback} users={users} />
    </S.Container>
  );
};

const RemarksInput = ({ feedback, users, disable }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");

  const dispatch = useDispatch();

  const onSubmit = (action) => {
    setText("");
    dispatch(submitRemarks({ action, text, feedback, user }));

    analytics.logFeedbackComments(text);
    if (action) {
      analytics.logFeedbackAction(action);
    }
  };

  const filterUsers = (query) =>
    permissions?.can_send_notifications
      ? users.filter(
          (u) =>
            u.display.toLowerCase().indexOf(query.toLocaleLowerCase()) >= 0,
        )
      : [];

  const user = useSelector((state) => state.auth.user);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const FeedbackActionLabel = useSelector(getFeedbackActionFilterLabel);
  const specificationMap = useSelector(
    (state) => state.organization.organization.features.specification_map,
  );

  const addNotesPlaceholder =
    (specificationMap &&
      specificationMap["ADD_NOTES_COMMENT_BOX_PLACEHOLDER"]) ||
    t("whatWhenWhereWhyHappened");

  return (
    <div>
      <MentionsInput
        value={text}
        allowSpaceInQuery
        style={styles.mentions}
        allowSuggestionsAboveCursor
        onChange={(e) => setText(e.target.value)}
        placeholder={addNotesPlaceholder}
      >
        <Mention
          trigger="@"
          data={filterUsers}
          style={styles.mentionedName}
          markup="<span user-id=__id__>__display__</span>"
          renderSuggestion={(user) => {
            return (
              <S.UserTagContainer>
                <UserAvatar user={user} />
                <S.UserTag>
                  <div>{user.display}</div>
                  <small>{user.role}</small>
                </S.UserTag>
              </S.UserTagContainer>
            );
          }}
        />
      </MentionsInput>
      <S.Buttons>
        <S.AddButton
          variant="success"
          disabled={!text.length || disable}
          onClick={() => onSubmit()}
        >
          {t("addNotes")}
        </S.AddButton>
        {(feedback.feedback_action?.type === FeedbackAction.UNPROCESSED ||
          feedback.feedback_action?.type === FeedbackAction.IN_PROGRESS) &&
          permissions?.api_action_stats && (
            <S.ActionDropdown>
              <S.DropdownToggle variant="info" />
              <S.DropdownMenu>
                {UserAssignableActions.map((action) => {
                  return (
                    action !== feedback.feedback_action?.type && (
                      <S.DropdownItem
                        eventKey={action}
                        key={action}
                        disabled={!text.length || disable}
                        onClick={() => onSubmit(action)}
                      >
                        {feedbackActionLabelText(
                          feedback,
                          FeedbackActionLabel,
                          action,
                        )}
                      </S.DropdownItem>
                    )
                  );
                })}
              </S.DropdownMenu>
            </S.ActionDropdown>
          )}
      </S.Buttons>
    </div>
  );
};

const UserAssignableActions = [
  FeedbackAction.IN_PROGRESS,
  FeedbackAction.RECOVERED,
  FeedbackAction.UNRECOVERABLE,
];

const highlightTaggedUsers = (text) => {
  const regex = /<span user-id=\d+>(.*?)<\/span>/;
  let result = text;
  let match;
  while (null !== (match = regex.exec(result))) {
    result = result.replace(match[0], `*${match[1]}*`);
  }
  return boldText(result);
};

const styles = {
  mentionedName: {
    backgroundColor: "#f5f5f5",
  },
  mentions: {
    height: 75,
    "&multiLine": {
      input: {
        border: "none",
        overflowY: "scroll",
      },
    },
    suggestions: {
      list: {
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
        maxHeight: "200px",
        overflow: "auto",
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          color: "var(--primary-color)",
          backgroundColor: "#f5f5f5",
        },
      },
    },
  },
};

export default Remarks;

const ReadMoreText = ({ children }) => {
  const [readMore, toggleReadMore] = useState(false);

  if (!children) {
    return "";
  }

  if (children.length < 350) {
    return highlightTaggedUsers(children);
  }

  return (
    <S.ReadMoreContainer onClick={() => toggleReadMore(!readMore)}>
      <span>{highlightTaggedUsers(children.slice(0, 350))}</span>
      {!readMore && (
        <>
          <span>... </span>
          <S.ReadMoreHint>(Read more)</S.ReadMoreHint>
        </>
      )}
      {readMore && <span>{highlightTaggedUsers(children.slice(350))})</span>}
    </S.ReadMoreContainer>
  );
};

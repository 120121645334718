import React from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Spinner from "react-spinkit";

const PrivateRoute = (props) => {
  let { component: Component, auth, ...rest } = props;
  const resetPassword = auth.user.reset_password;
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  return auth.authenticated || auth.fetching ? (
    auth.fetching ? (
      <Spinner name="wandering-cubes" fadeIn="none" />
    ) : resetPassword ? (
      <Navigate to={{ pathname: "/reset-password" }} />
    ) : (
      <Component
        {...props}
        {...rest}
        navigate={navigate}
        match={{ params: params }}
      />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};
export default PrivateRoute;

import styled from "styled-components";

export const FeedbackSummaryCard = styled.div`
  cursor: pointer;
  padding: 20px 20px 1px 20px;
  background-color: white;
  margin-bottom: 15px;
  border-left: 6px solid white;
  ${(props) => props.isUNP && "border-left-color: var(--primary-color)"}
  ${(props) => props.isSelected && "border-left-color: #E7E7E7"}
`;

export const FeedbackTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  right: 20px;
  margin-top: -10px;
`;

export const FeedbackActionText = styled.div`
  margin-top: 5px;
  background-color: ${(props) => props.backgroundColor};
  padding: 2px 7px;
  color: ${(props) => props.foregroundColor};
`;

export const FeedbackScoreSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FeedbackScore = styled.div`
  display: flex;
  align-items: center;
`;

export const RespondentName = styled.div`
  margin-left: 15px;
  font-size: 16px;
`;

export const FeedbackDivisions = styled.div`
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
`;

export const FeedbackDivision = styled.div`
  display: flex;
  font-size: 13px;
  padding: 3px 4px;
  margin-right: 5px;
  line-height: 1;
  i {
    margin-right: 5px;
  }
`;

import React from "react";
import { format } from "date-fns";

import * as utils from "components/common/DateFilter/PresetFilter/PresetFilterUtils";

const ShiftFilterLabel = ({ startTime, endTime, days }) => {
  const shiftFilterString = shiftFilterToString(startTime, endTime, days);
  const { sDays, sStartTime, sEndTime } = shiftFilterString;
  const isTurnedOn = startTime || Object.keys(days || {}).some((d) => days[d]);

  const label = isTurnedOn ? (
    <>
      {sStartTime && sEndTime && (
        <span>
          {sStartTime} - {sEndTime}
        </span>
      )}
      {sDays && <span>{sDays}</span>}
    </>
  ) : (
    "Add Shift Time/Day"
  );

  return <>{label}</>;
};

export const shiftFilterToString = (startTime, endTime, days) => {
  const sDays = days ? utils.timeSpanToString(days, utils.DAYS) : "";
  const sStartTime = startTime ? format(startTime, TIME_FORMAT) : "";
  const sEndTime = endTime ? format(endTime, TIME_FORMAT) : "";
  return { sDays, sStartTime, sEndTime };
};

export default ShiftFilterLabel;

const TIME_FORMAT = "h:mm a";

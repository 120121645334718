import styled from "styled-components";

const colorMapping = {
  green: "#45dd80",
  orange: "#f68e35",
  red: "#f44a27",
};

export const ScoreNumber = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #69bc6c;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #fff;
  ${(props) =>
    props.color !== null && `background-color: ${colorMapping[props.color]};`}
`;

export const FeedbackNumber = styled.p`
  font-size: 20px;
  line-height: 50px;
  ${(props) => props.color !== null && `color: ${colorMapping[props.color]};`}
`;

export const Image = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
`;

export const ImageWrapper = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  vertical-align: top;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  position: relative;
  z-index: 0;
  img {
    height: auto;
    max-width: 100%;
  }
`;

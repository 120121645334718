import { keys, values } from "lodash";

// CHARTS
// ==========================

import * as utils from "utils/genericHelper";
import * as constants from "constants/constants";
import i18n from "../../i18n";

const defaultAnalyzerParser = (chart) => {
  let { sm, lg, type, query, units, analyzer, visualization, ..._chart } =
    chart;

  if (type === "analyzer" && !visualization?.length) {
    visualization = ["table"];
  }

  return {
    name: analyzer,
    query: query || "",
    units: !units || !units.length ? ["count"] : units,
    type: type === "analyzer" ? "graph" : type || "text",
    graphs: visualization,
    sizes: { sm, lg },
    ..._chart,
  };
};

const analyzerParserMap = {};

export const analyzerParser = (analyzer, rackRoute, dashboardId) => {
  const parser = analyzerParserMap[analyzer.analyzer] || defaultAnalyzerParser;
  const a = parser(analyzer, dashboardId);
  a.slug = dashboardId + rackRoute + ":" + a.name + "-" + a.index;
  return a;
};

export const rackParser = (rack, parent, dashboardId) => {
  if (rack.racks.length === 1) {
    rack = { ...rack.racks[0], name: rack.name };
  }

  const slug = utils.toSlug(rack.name);
  const route = parent + "/" + slug;
  const racks = rack.racks.map((r) => rackParser(r, route, dashboardId));
  const analyzers = rack.analyzers.map((analyzer, index) => {
    const _analyzer = { ...analyzer, index };
    return analyzerParser(_analyzer, route, dashboardId);
  });

  return { type: "custom", ...rack, slug, route, racks, analyzers };
};

// DATA
// ==========================

const defaultResponseParser = {
  text: (data) => data,
  table: (data) => _defaultTableParser(data),
  graph: (data) => _defaultTimeSeriesParser(data),
};

const responseParserMap = {
  true_score: (data) => {
    return [{ count: data.true_score }];
  },

  nps: (data) => {
    return [{ count: data.stats.nps }];
  },

  static_analyzer: (data) => {
    return [{ count: data.value }];
  },

  data_health: (data) => {
    return [{ count: data.data_health }];
  },

  feedback_score_average: (data, analyzer) => {
    const avgScore = data[analyzer.score_type || "star"];
    return [{ count: avgScore.count }];
  },

  feedback_count_stats: (data) => {
    const trend = data[0].trend;
    const arrow = trend > 0 ? "↑" : "↓";
    return [
      {
        count: data[0].count,
        trend:
          trend === null ? "-" : `${arrow} ${Math.abs(parseFloat(trend))}%`,
      },
    ];
  },

  response_rate: (data) => {
    return [
      { percentage: data.real_response_rate, label: "Overall" },
      { percentage: data.distinct_response_rate, label: "Distinct" },
    ];
  },

  country_average_nps: (data) => {
    const color = data.delta_nps < 0 ? RED : GREEN;
    const prefix = data.delta_nps > 0 ? "+" : undefined;
    return [
      {
        label: "Country Average NPS",
        count: data.average_nps ?? "-",
      },
      {
        label: "Δ Country Average NPS",
        count: data.delta_nps ?? "-",
        color,
        prefix,
      },
    ];
  },

  future_priority_concerns: (data) => _defaultChartParser(data),

  option_other_text_stats: (data) => _defaultChartParser(data, "text"),

  feedback_sentiment_stats: (data) => _parsePercentage(data),

  reachable_respondent_stats: (data) => _parsePercentage(data),

  feedback_action_stats: (data) => _parsePercentage(data),

  feedback_segments_stats: (data) => {
    const _data = _parsePercentage(data);
    return _data.filter((d) => d.label !== "Total");
  },

  division_level_wise_analysis: (data) => _humanizeColumnTime(data),

  multi_division_level_wise_analysis: (data) => _humanizeColumnTime(data),

  division_level_wise_loop_ratio: (data) => data,

  brand_other_text_option_insights: (data) => _defaultChartParser(data, "text"),

  age_trend_analysis: (data) => _ageGenderParser(data),

  gender_trend_analysis: (data) => _ageGenderParser(data),

  gender_group_analysis: (data) => {
    const response = {};
    const ageLabels = keys(data.analysis);
    response.labels = ageLabels;
    response.count = [
      {
        label: "Male",
        backgroundColor: "#26AAE2",
        values: _formatByAnalysisType(
          ageLabels.map((age) => data.analysis[age].Male.value),
          data.analysis_type,
        ),
      },
      {
        label: "Female",
        backgroundColor: "#e81d62",
        values: _formatByAnalysisType(
          ageLabels.map((age) => data.analysis[age].Female.value),
          data.analysis_type,
        ),
      },
    ];

    if (data.analysis_type === constants.DurationalAnalysisType.COUNT) {
      response.percentage = [
        {
          label: "Male",
          backgroundColor: "#26AAE2",
          values: ageLabels.map((age) => data.analysis[age].Male.percentage),
        },
        {
          label: "Female",
          backgroundColor: "#e81d62",
          values: ageLabels.map((age) => data.analysis[age].Female.percentage),
        },
      ];
    }
    return response;
  },

  top_concerns_by_sentiment_analysis: (data, analyzer) =>
    _multiColumnBar(data, analyzer),

  feedback_recovery_stats: (data, analyzer) => {
    const labels = analyzer.value_labels || [
      "Recovery Rate",
      "Avg. Recovery Time",
    ];

    if (data.avg_recovery_time === null) {
      return [
        { count: data.recovery_rate, label: labels[0] },
        { count: "-", label: labels[1] },
      ];
    }
    const [time, unit] = utils.humanizeSeconds(data.avg_recovery_time, "hr");
    return [
      { count: data.recovery_rate, label: labels[0] },
      { count: time, suffix: " " + unit, label: labels[1] },
    ];
  },

  feedback_recovery_rate: (data, analyzer) => {
    const label =
      (analyzer.value_labels && analyzer.value_labels[0]) || "Recovery Rate";
    return [{ count: data.recovery_rate, label: label }];
  },

  feedback_recovery_time: (data, analyzer) => {
    const label =
      (analyzer.value_labels && analyzer.value_labels[1]) ||
      "Avg. Recovery Time";
    let result = {
      count: "-",
      label: label,
    };
    if (data.avg_recovery_time !== null) {
      const [time, unit] = utils.humanizeSeconds(data.avg_recovery_time, "hr");
      result["count"] = time;
      result["suffix"] = " " + unit;
    }
    return [result];
  },

  child_concern_stats: (data) => _defaultChartParser(data, "name"),

  average_survey_time: (data) => {
    const [count, suffix] = utils.humanizeSeconds(data.avg_survey_time);
    return [{ count, suffix: " " + suffix }];
  },

  benchmark: (data) => {
    const response = {};
    response.labels = data.map((d) => d.title);
    response.count = [
      { label: "Count", values: data.map((d) => d.stats.total) },
    ];
    response.percentage = [
      { label: "Percentage", values: data.map((d) => d.stats.percentage) },
    ];
    return response;
  },

  concern_stats: (data) => {
    const response = {};
    response.labels = data.stats.map((d) => d.name);
    response.count = [
      { label: "Count", values: data.stats.map((d) => d.name) },
    ];
  },

  durational_count_hour_bracket_wise_analysis: (data, analyzer) => {
    const action =
      data.analysis_type === constants.DurationalAnalysisType.COUNT
        ? "sum"
        : "average";

    const formatter = analysisTypeFormatters[data.analysis_type];
    const analysis = _hours_bracket_wise_analysis[action](data, analyzer).map(
      (bracketData) => ({
        ...bracketData,
        count: formatter?.(bracketData.value) || bracketData.value,
      }),
    );

    return analysis;
  },

  durational_count_hour_wise_analysis: (data) => {
    data.analysis = _sortHoursByStartingHour(data.analysis);
    return _durationParser(data);
  },

  durational_count_day_wise_analysis: (data) => _durationParser(data, true),

  durational_count_day_type_wise_analysis: (data) => _durationParser(data),

  durational_count_week_wise_analysis: (data) => _durationParser(data),

  durational_count_month_day_wise_analysis: (data) => _durationParser(data),

  durational_count_month_wise_analysis: (data) => _durationParser(data),

  feedback_benchmark_stats: (data) => _humanizeColumnTime(data),

  durational_benchmark_stats: (data) => _humanizeColumnTime(data),

  open_ended_question_result: (data) => _defaultChartParser(data, "name"),

  total_sales_stats: (...args) => _abbreviateValues(...args),

  total_sales_by_nps: (...args) => _abbreviateValues(...args),

  average_invoice_by_segments: (...args) => _abbreviateValues(...args),

  total_sales_by_segments: (...args) => _abbreviateValues(...args),

  total_customers_stats: (data) => _defaultChartParser(data),

  total_sales_by_concern_sentiment: (...args) =>
    _salesDataParser(...args, "name"),

  total_sales_by_nps_score: (...args) => _salesDataParser(...args),

  transaction_hour_bracket_wise_analysis: (data, analyzer) => {
    return analyzer.brackets.map((b) => {
      return {
        label: b.label,
        count:
          data
            .filter((d) => b.value.includes(d.label_value))
            .reduce((a, b) => a + b.count || 0, 0) || "--",
      };
    });
  },

  transaction_day_wise_analysis: (...args) => _salesDataParser(...args),

  transaction_day_type_wise_analysis: (...args) => _salesDataParser(...args),

  transaction_hour_wise_analysis: (...args) => _salesDataParser(...args),

  transaction_week_wise_analysis: (...args) => _salesDataParser(...args),

  transaction_month_day_wise_analysis: (...args) => _salesDataParser(...args),

  transaction_month_wise_analysis: (...args) => _salesDataParser(...args),

  nps_sales_trend: (data) => {
    const dates = keys(data);

    if (!dates.length) {
      return { noData: true };
    }

    return {
      labels: dates,
      count: [
        {
          label: "NPS",
          yAxisID: "nps",
          values: dates.map((date) => data[date].NPS.nps || 0),
        },
        {
          label: "Sales",
          yAxisID: "sales",
          values: dates.map((date) => data[date]["Total Sales"].sales || 0),
        },
      ],
    };
  },

  churn_sales_at_risk: (...args) => _abbreviateValues(...args),

  churn_average_gap_between_orders: (data) => _churnGapBtwOrdersParser(data),

  churn_average_gap_between_by_category: (data) =>
    _churnGapBtwOrdersParser(data),

  churn_trend_analysis: (data, analyzer) => {
    const periods = keys(data);
    return {
      labels: periods,
      percentage: [
        {
          label: analyzer.label || "Churn Rate",
          values: periods.map(
            (period) => data[period].churn_percentage.average || 0,
          ),
        },
      ],
    };
  },

  churn_customer_details: (data, analyzer) => {
    const column_keys = (analyzer.column_keys || "").split("|");

    const response = {
      headers: (analyzer.column_labels || "").split("|"),
      data: {
        ...data,
        results: data.results.map((row) => {
          return column_keys.map((key) => {
            const v = utils.pickDeep(row, key);
            return _churnCustomerTableCols[key]?.(v, analyzer, row) || v;
          });
        }),
      },
    };

    return _defaultTableParser(response);
  },
};

const _churnCustomerTableCols = {
  churn_category: (v) => constants.ChurnCategoryLabel[v],
  total_amount: (v, a) => utils.abbreviateAmount(v, a.currency),
  average_gap_between_orders: (v) => utils.humanizeSeconds(v).join(" "),
  average_invoice_amount: (v, a) => utils.abbreviateAmount(v, a.currency),
  invoice_details: (_, __, r) => {
    return { cellType: "invoice_details", customerId: r.customer.id };
  },
  last_order_date: (v) => {
    return { cellType: "last_order_date", date: v };
  },
};

const _durationParser = (data, isTranslationEnabled = false) => {
  const response = {};

  response.labels = isTranslationEnabled
    ? data.analysis.map((d) => i18n.t(d.label))
    : data.analysis.map((d) => d.label);

  response.count = [
    {
      label: "Values",
      values: _formatByAnalysisType(
        data.analysis.map((d) => d.value),
        data.analysis_type,
      ),
    },
  ];

  if (data.analysis_type === constants.DurationalAnalysisType.COUNT) {
    response.percentage = [
      { label: "Values", values: data.analysis.map((d) => d.percentage) },
    ];
  }
  return response;
};

const _salesDataParser = (data, analyzer, labelKey = "label") => {
  const response = {};
  response.labels = data.map((d) => d[labelKey]);
  const label = analyzer.value_columns?.[0] || "Sales Amount";
  response.count = [{ label, values: data.map((d) => d.count) }];
  if (data[0].percentage) {
    response.percentage = [
      { label: "Percentage", values: data.map((d) => d.percentage) },
    ];
  }
  return response;
};

const _churnGapBtwOrdersParser = (data) => {
  return data.map((d) => {
    const [count, suffix] = utils.humanizeSeconds(d.count, "day");
    return { count, suffix: " " + suffix, label: d.label };
  });
};

export const _defaultChartParser = (data, labelKey = "label") => {
  const stats = data.results ? data.results : data;

  if (data.detail || !stats.length) {
    return { noData: true };
  }

  const response = {};
  response.nextURL = data.next;
  response.labels = stats.map((d) => d[labelKey]);
  response.count = [
    {
      label: "Count",
      values: stats.map((d) => d.count),
    },
  ];
  response.percentage = [
    {
      label: "Percentage",
      values: stats.map((d) => d.percentage),
    },
  ];
  return response;
};

const _defaultTableParser = (data) => {
  if (data.detail) {
    return { noData: true };
  }
  const noNextPage = data.data.next === null;
  if (data.data.next || noNextPage) {
    return {
      headers: data.headers,
      data: data.data.results,
      nextURL: data.data.next,
    };
  }
  return {
    headers: data.headers,
    data: data.data,
  };
};

const _timeSeriesParser = (data, labelKey = "label") => {
  const periods = keys(data.time_series);
  const datasetLabels = data.cumulative.map((d) => d[labelKey]);

  return {
    labels: periods,
    count: datasetLabels.map((datasetLabel) => {
      const res = {
        label: datasetLabel,
        values: periods.map(
          (period) => data.time_series[period][datasetLabel]?.count || 0,
        ),
      };
      return res;
    }),
    percentage: datasetLabels.map((datasetLabel) => {
      const res = {
        label: datasetLabel,
        values: periods.map(
          (period) => data.time_series[period][datasetLabel]?.percentage || 0,
        ),
      };
      return res;
    }),
  };
};

const _defaultTimeSeriesParser = (data) => {
  if (data.detail || !data.cumulative?.length || !data.time_series) {
    return { noData: true };
  }

  const cumulative = _defaultChartParser(data.cumulative);
  const time_series = _timeSeriesParser(data);

  return { cumulative, time_series };
};

const _sortHoursByStartingHour = (data) => {
  const startingHour = "09 AM";
  const shiftingIndex = data.findIndex((d) => d.label === startingHour);
  return [
    ...data.slice(shiftingIndex, data.length),
    ...data.slice(0, shiftingIndex),
  ];
};

const _humanizeColumnTime = (data) => {
  const _data = _defaultTableParser(data);
  const timeRegex = /(^| )time( |$)/i; // time either at start, end or surrouned by " "
  const timeIdx = _data.headers.findIndex((header) => header.match(timeRegex));

  _data.data.forEach((row) => {
    const time = row[timeIdx];
    if (time !== null) {
      const [duration, unit] = utils.humanizeSeconds(parseFloat(time), "hr");
      row[timeIdx] = `${duration} ${unit}`;
    }
  });
  return _data;
};

const _ageGenderParser = (data) => {
  const response = {};
  response.labels = keys(data.analysis);
  response.count = [
    {
      label: "Values",
      values: _formatByAnalysisType(
        values(data.analysis).map((d) => d.value),
        data.analysis_type,
      ),
    },
  ];

  if (data.analysis_type === constants.DurationalAnalysisType.COUNT) {
    response.percentage = [
      {
        label: "Percentage",
        values: _formatByAnalysisType(
          values(data.analysis).map((d) => d.percentage),
          data.analysis_type,
        ),
      },
    ];
  }

  return response;
};

const analysisTypeFormatters = {
  [constants.DurationalAnalysisType.RECOVERY_RATE]: (v) =>
    utils.formatPercentage(v),

  [constants.DurationalAnalysisType.RESPONSE_RATE]: (v) =>
    utils.formatPercentage(v),

  [constants.DurationalAnalysisType.AVERAGE_RECOVERY_TIME]: (v) => {
    const [duration, unit] = utils.humanizeSeconds(v, "hr");
    return `${duration} ${unit}`;
  },
};

const _formatByAnalysisType = (values, analysisType) => {
  const formatter = analysisTypeFormatters[analysisType];
  return formatter ? values.map(formatter) : values;
};

const _hours_bracket_wise_analysis = {
  sum: (data, analyzer) => {
    return analyzer.brackets.map((bracket) => {
      const bracketData = {
        label: bracket.label,
        value: data.analysis
          .filter((d) => bracket.value.includes(d.label_value))
          .reduce((a, b) => a + (b.value || 0), 0),
      };

      if (data.analysis_type === constants.DurationalAnalysisType.COUNT) {
        bracketData.percentage = data.analysis
          .filter((d) => bracket.value.includes(d.label_value))
          .map((d) => parseFloat(d.percentage))
          .reduce((a, b) => a + b, 0);
      }

      return bracketData;
    });
  },
  average: (data, analyzer) => {
    const response = analyzer.brackets.map((bracket, bracketIdx) => {
      let total = 0;
      const average =
        data.analysis
          .filter((d) => bracket.value.includes(d.label_value))
          .filter((d) =>
            data.analysis_type === "nps"
              ? d.value !== null && d.value !== undefined
              : d.value,
          )
          .map((d) => parseFloat(d.value))
          .reduce((a, b) => {
            total += 1;
            return a + b;
          }, 0) / (total || 1);

      return {
        label: bracket.label,
        value: average,
      };
    });

    return response;
  },
};

const _abbreviateValues = (data, analyzer) => {
  return data.map((d) => {
    const prefix = analyzer.prefix;
    const [count, suffix] = utils.abbreviateNumber(d.count);
    return { ...d, hover: suffix && d.count, prefix, suffix, count };
  });
};

const _parsePercentage = (data) => {
  return data.map((d) => {
    return { ...d, percentage: parseFloat(d.percentage) };
  });
};

export const analyzerResponseParser = (data, analyzer) => {
  const parser =
    responseParserMap[analyzer.name] || defaultResponseParser[analyzer.type];
  return parser(data, analyzer);
};

export const analyzerErrorParser = (error) => error;

const RED = "#E61F1F";
const GREEN = "#95be43";

const _multiColumnBar = (data, analyzer) => {
  const response = {
    count: [],
    percentage: [],
  };

  const recordLabels = keys(data.analysis);
  if (recordLabels.length <= 0) return;

  const labelNames = keys(data.analysis[recordLabels[0]]);
  response.labels = recordLabels;

  for (var index in labelNames) {
    const label_ = labelNames[index];
    const color = analyzer.colors[index];

    response.count.push({
      label: label_,
      backgroundColor: color,
      values: _formatByAnalysisType(
        recordLabels.map(
          (recordLabel) => data.analysis[recordLabel][label_].value,
        ),
        data.analysis_type,
      ),
    });

    if (data.analysis_type === constants.DurationalAnalysisType.COUNT) {
      response.percentage.push({
        label: label_,
        backgroundColor: color,
        values: recordLabels.map(
          (recordLabel) => data.analysis[recordLabel][label_].percentage,
        ),
      });
    }
  }
  return response;
};

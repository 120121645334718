import styled from "styled-components";

export const LeftArrowImage = styled.img`
  margin-right: 10px;
`;

export const Button = styled.button`
  border: 1px solid var(--primary-color);
  background: var(--primary-color);
  color: var(--text-color);
  width: 108px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover ${LeftArrowImage} {
    transform: translateX(-10px);
    transition: all 0.3s ease-in-out;
  }
`;

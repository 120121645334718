import { format } from "date-fns";
import { forIn } from "lodash";

import { FeedbackFilter } from "constants/constants";
import { getOrganizationSlug } from "./genericHelper";
import { DateFilterType } from "constants/constants";
import { DefaultDateRanges } from "components/common/DateFilter/DefaultFilter/DefaultFilter";

const serializeFilters = (filter) => {
  return {
    level: filter.level,
    sort_by: filter[FeedbackFilter.SORTY_BY],
    shift_end: filter.endTime,
    shift_start: filter.startTime,
    shift_days: objKeysToString(filter.days),
    feedback_level: filter.feedback_level,
    likes: filter.likes,
    survey_type: filter.surveyType,
    search_text: filter.search,
    condition: filter.condition,
    kpi: filter.kpi,
    concern: filter.concern,
    is_spam:
      Object.keys(filter.dataHealth || {}).reduce(
        (value, health) => (value += parseInt(health)),
        0,
      ) || 3, //3 - All data
    age: objKeysToString(filter.age),
    loop: objKeysToString(filter.loop),
    nps: objKeysToString(filter.segments),
    tag: objKeysToString(filter.tags),
    action: objKeysToString(filter.actions),
    invite_type: objKeysToString(filter.invite_types),
    media_filter: objKeysToString(filter.media_filter),
    nps_score: objKeysToString(filter.nps),
    option: objKeysToString(filter.options),
    gender: objKeysToString(filter.gender),
    division: objKeysToString(filter.locations),
    group: objKeysToString(filter.groups),
    questionnaire: objKeysToString(filter.questionnaires),
    analysis_type: filter.analysisType,
    ...getDateFilters(filter),
  };
};

const objKeysToString = (filter) =>
  Object.keys(filter || {})
    .filter((tag) => filter[tag])
    .reduce((q, tag) => `${q}|${tag}`, "")
    .slice(1);

const getQueryString = (obj) => {
  let queryString = "";
  forIn(serializeFilters(obj), (value, key) => {
    if (
      (typeof value === "string" && value.length) ||
      typeof value === "boolean" ||
      typeof value === "number"
    )
      queryString += `${key}=${encodeURIComponent(value)}&`;
  });
  return queryString.slice(0, -1);
};

const getFilters = () => {
  return JSON.parse(localStorage.getItem(`${getOrganizationSlug()}_filters`));
};

const setFilters = (filters) => {
  return localStorage.setItem(
    `${getOrganizationSlug()}_filters`,
    JSON.stringify(filters),
  );
};

const isActivityDate = (date, activities) => {
  return activities.find((activity) => {
    return (
      format(new Date(activity.start_date), "dd/MM/yyyy") ===
        format(new Date(date.startDate), "dd/MM/yyyy") &&
      format(new Date(activity.end_date), "dd/MM/yyyy") ===
        format(new Date(date.endDate), "dd/MM/yyyy")
    );
  });
};

const getQueryVariable = (variable) => {
  var query = window.location.search.split("?")[1];
  var vars = query ? query.split("&") : [];
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

const getDateRange = (range) =>
  DefaultDateRanges.find((dateRange) => dateRange.key.toLowerCase() === range)
    ?.dates || [null, null];

const getDateFilters = (filter) =>
  filter.dateFilterType === DateFilterType.PRESET
    ? {
        preset: true,
        years: objKeysToString(filter.years),
        months: objKeysToString(filter.months),
      }
    : {
        start_date: filter.startDate,
        end_date: filter.endDate,
      };

export {
  getQueryString,
  getDateRange,
  setFilters,
  getFilters,
  isActivityDate,
  getQueryVariable,
};

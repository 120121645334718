import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Wrapper,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Title,
} from "./styled";

import { loadConcernAnalysis } from "components/RCA/actions";
import {
  formFieldValueSelector,
  concernAnalysisSummarySelector,
} from "components/RCA/selectors";
import SkeletonTable from "components/common/Skeleton/SkeletonTable";

const ImpactTable = () => {
  const dispatch = useDispatch();

  const analysisSummary = useSelector(concernAnalysisSummarySelector);

  useEffect(() => {
    dispatch(loadConcernAnalysis());
  }, [analysisSummary]);

  const concern = useSelector((state) =>
    formFieldValueSelector(state, "concern"),
  )?.name;

  return (
    <>
      {" "}
      {analysisSummary && (
        <Wrapper>
          <Heading>{concern} Analysis</Heading>
          {analysisSummary?.header ? (
            <Table>
              <Thead>
                <Tr>
                  {analysisSummary.header.map((head) => {
                    return <Th>{head}</Th>;
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {analysisSummary.data.map((row) => {
                  return (
                    <Tr>
                      {row.map((cell, index) => {
                        const cellType = !index
                          ? "FIRST"
                          : index === row.length - 1
                          ? "LAST"
                          : "MIDDLE";
                        return formatCell(cell, cellType);
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <SkeletonTable />
          )}
        </Wrapper>
      )}{" "}
    </>
  );
};

const formatCell = (cell, type) => {
  if (type === "FIRST") {
    return <Title>{cell}</Title>;
  } else if (type === "MIDDLE") {
    return <Td>{cell}</Td>;
  }

  let value = cell;
  let style = "DEFAULT";
  if (cell > 0) {
    style = "NEGATIVE";
  } else if (cell < 0) {
    style = "POSITIVE";
    value = Math.abs(cell);
  }
  const [color, arrow] = cellStyles[style];
  return (
    <Td color={color}>
      {arrow} {value}
    </Td>
  );
};

const cellStyles = {
  POSITIVE: ["green", "▼"],
  NEGATIVE: ["red", "▲"],
  DEFAULT: [null, null],
};

export default ImpactTable;

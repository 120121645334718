import React from "react";
import { Media } from "./Media";
import * as analytics from "utils/analytics";

export class SliderPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachment: props.attachment,
    };
  }

  componentDidMount() {
    document.addEventListener("keyup", this.moveTo);
  }

  componentDidUpdate(prevProps) {
    const { attachment } = this.props;
    const prevAttachment = prevProps.attachment;

    if (attachment !== prevAttachment) {
      this.setState({
        attachment: attachment,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.moveTo);
  }

  moveTo = (e) => {
    const { attachments } = this.props;
    const { attachment } = this.state;
    const currentIndex = attachments.findIndex((_a) => _a.id === attachment.id);
    if (e.keyCode === 37 && currentIndex > 0) {
      this.setState({
        attachment: attachments[currentIndex - 1],
      });
      analytics.logMediaViewed(attachments[currentIndex - 1].attachment);
    } else if (e.keyCode === 39 && currentIndex + 1 < attachments.length) {
      this.setState({
        attachment: attachments[currentIndex + 1],
      });
      analytics.logMediaViewed(attachments[currentIndex + 1].attachment);
    }
  };

  changeMedia = (attachment) => {
    this.setState({
      attachment: attachment,
    });
    analytics.logMediaViewed(attachment.attachment);
  };

  render() {
    const { attachments, closePreview } = this.props;
    const { attachment } = this.state;
    return (
      <div className="slider-preview">
        <div className="backdrop" onClick={closePreview} />
        <div className="media">
          <div className="full-size">
            <Media attachment={attachment} mode="play" />
          </div>
          <ul className="thumbnail-list">
            {attachments.map((_attachment) => (
              <li
                className={attachment.id === _attachment.id ? "active" : null}
              >
                <Media
                  attachment={_attachment}
                  openPreview={this.changeMedia}
                  mode="thumbnail"
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

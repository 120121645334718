import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { formatDistance } from "date-fns";
import { Link } from "react-router-dom";

import * as analytics from "utils/analytics";
import { UserAvatar } from "components/common/UserAvatar";
import { getQueryVariable } from "utils/filtersHelperFuncs";
import { selectDashboard } from "components/DashboardPage/actions";
import { boldText_DEPRECATED, taggedUserInBoldText } from "utils/genericHelper";
import {
  markNotificationAsRead,
  remoteMarkNotificationAsRead,
} from "./actions";

const NotificationCard = ({ notification, source, redirect }) => {
  const {
    id,
    sender,
    message,
    created_at,
    seen_at,
    notification_dashboard_link,
    content_object,
  } = notification;

  const questionnaireID = content_object?.questionnaire;

  const Div = styled.div`
    text-decoration: none;
    color: #000;
  `;

  const notificationRedirectLink = notification_dashboard_link?.includes(
    "localhost",
  )
    ? notification_dashboard_link?.split("localhost:3000")[1]
    : notification_dashboard_link?.split("sentimeter.io")[1];

  const uuid = notificationRedirectLink?.includes("share-feedback")
    ? notificationRedirectLink?.split("share-feedback/")[1]?.split("?")[0]
    : null;

  const dispatch = useDispatch();
  const dashboards = useSelector((state) => state.dashboard.dashboards);

  const onNotificationRead = () => {
    dispatch(markNotificationAsRead(id));
    dispatch(remoteMarkNotificationAsRead(id));

    analytics.logNotificationLinkClick(source, id);

    if (questionnaireID) {
      const dashboard = dashboards.find((d) => d.id === questionnaireID);
      if (dashboard) dispatch(selectDashboard(dashboard, true));
    }

    window.history.replaceState(null, "", notificationRedirectLink);
  };

  const isSelectedNotification =
    notification.id === parseInt(getQueryVariable("notification"), 10);

  const Wrapper = !uuid || redirect ? Link : Div;
  return (
    <Wrapper to={notificationRedirectLink} exact onClick={onNotificationRead}>
      <li
        className={
          !seen_at
            ? "holder new"
            : isSelectedNotification
            ? "holder active"
            : "holder"
        }
      >
        {sender ? <UserAvatar user={sender} /> : null}
        <div className="info">
          <p
            className="type"
            dangerouslySetInnerHTML={taggedUserInBoldText(
              boldText_DEPRECATED(message),
              source,
            )}
          ></p>
          <p className="date">
            {created_at &&
              formatDistance(new Date(created_at), new Date(), {
                addSuffix: true,
              })}
          </p>
        </div>
        {!seen_at && <div className="green-dot"></div>}
      </li>
    </Wrapper>
  );
};

export default NotificationCard;

import { getRequest } from "../../utils/request";

import axios from "axios";

export const apiCalls = {};
export const analyzerApi = (analyzer, organizationId, filtersQuery) => {
  const api = analyzer.api.replace("{organization_id}", organizationId);
  let url = `/${api}?${analyzer.query}&${filtersQuery}`;

  const previousCall = apiCalls[analyzer.slug];
  if (previousCall) previousCall.cancel();

  apiCalls[analyzer.slug] = axios.CancelToken.source();

  return getRequest(url, false, apiCalls[analyzer.slug].token);
};

export const analyzerNextApi = (url) => getRequest(url);

export const insightConcernsApi = (organizationId, filtersQuery, parentId) => {
  const parentQuery = parentId ? `parent_concern=${parentId}&` : "";
  const _filtersQuery = filtersQuery.replace(/concern=\d+(&|)/, "");
  let url = `/v1/organizations/${organizationId}/insights/concerns/?${parentQuery}&${_filtersQuery}`;

  return getRequest(url);
};

export const insightConcernDetailApi = (organizationId, filtersQuery) => {
  let url = `/v1/organizations/${organizationId}/insights/concern-stats/?${filtersQuery}`;

  return getRequest(url);
};

export const insightConcernImpactOnScoreApi = (
  organizationId,
  filtersQuery,
) => {
  let url = `/v1/organizations/${organizationId}/insights/concern-impact-on-score/?${filtersQuery}`;
  return getRequest(url);
};

export const surveyQuestionsApi = (organizationId, questionnaireId) => {
  const url = `/api/organizations/${organizationId}/questionnaires/v1/question_list/?questionnaire=${questionnaireId}`;

  return getRequest(url);
};

export const omniViewQuestionFilterQuestions = (organizationId, questionIds) => {
  const ids = questionIds.join(",");
  const url = `/api/organizations/${organizationId}/questionnaires/omni-view/questions/?ids=${ids}`;

  return getRequest(url);
};

export const wordCloudApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/feedback-semantic-analysis/?${filtersQuery}`;

  return getRequest(url);
};

export const projectsOverviewApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/questionnaire-stats/?${filtersQuery}`;

  return getRequest(url);
};

export const mediaApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/feedback-media-list/?${filtersQuery}`;
  return getRequest(url);
};

export const benchmarkApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/benchmark/?${filtersQuery}`;

  return getRequest(url);
};

export const competitorAnalysisApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/competitor-analysis/?${filtersQuery}`;

  return getRequest(url);
};

export const customerSegmentationApi = (
  organizationId,
  filtersQuery,
  concern,
) => {
  let url = `/dashboard/v1/organizations/${organizationId}/customer-segmentation/?${filtersQuery}&concern=${
    concern || ""
  }`;

  return getRequest(url);
};

export const dataHealthApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/data-health/?${filtersQuery}`;

  return getRequest(url);
};

export const trueScoreApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/true-score/?${filtersQuery}`;

  return getRequest(url);
};

export const segmentAnalysisApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/segment-analysis/?${filtersQuery}`;

  return getRequest(url);
};

export const genderGroupAnalysisApi = (
  organizationId,
  filtersQuery,
  concern,
) => {
  let url = `/dashboard/v1/organizations/${organizationId}/gender-group-analysis/?${filtersQuery}&concern=${
    concern || ""
  }`;

  return getRequest(url);
};

export const npsApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/net-promoters-score/?${filtersQuery}`;

  return getRequest(url);
};

export const satisfactionApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/satisfaction/?${filtersQuery}`;

  return getRequest(url);
};

export const SemanticAnalysisApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/semantic-analysis/?${filtersQuery}`;

  return getRequest(url);
};

export const timeSeriesApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/time-series-analysis/?${filtersQuery}`;
  return getRequest(url);
};

export const priorityListApi = (organizationId, filtersQuery, loop) => {
  let url = `/dashboard/v1/organizations/${organizationId}/priority-list/?${filtersQuery}&loop=${loop}`;

  return getRequest(url);
};

export const topConcernsApi = (organizationId, filtersQuery, loop) => {
  let url = `/dashboard/v1/organizations/${organizationId}/top-concerns/?${filtersQuery}&loop=${loop}`;

  return getRequest(url);
};

export const getInviteStatsApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/survey-invite/?${filtersQuery}`;
  return getRequest(url);
};

export const commentsApi = (organizationId, filtersQuery) => {
  let url = `/dashboard/v1/organizations/${organizationId}/feedback-comments-list/?${filtersQuery}`;
  return getRequest(url);
};

export const moreCommentsApi = (url) => getRequest(url);

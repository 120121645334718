import { connect } from "react-redux";
import { loadProjectsOverview, selectDashboard } from "../actions";

import { ProjectOverviewTable as ProjectOverview } from "./ProjectOverviewTable";

const mapStateToProps = ({ selectedFilters, dashboard }, { analyzer }) => ({
  analyzer,
  selectedFilters,
  projectsOverview: dashboard.projectsOverview,
  dashboards: dashboard.dashboards,
});

const mapDispatchToProps = {
  loadProjectsOverview,
  selectDashboard,
};

export const ProjectsOverviewTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectOverview);

import React, { useState, useEffect } from "react";
import i18n from "../../i18n";

import { format, formatDistance } from "date-fns";
import de from "date-fns/locale/de";
import pl from "date-fns/locale/pl";
import { getFormatedDate } from "utils/dateUtils";

const localMap = {
  de: { locale: de },
  pl: { locale: pl },
  en: {},
};

const getRelativeTime = (date) =>
  formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    ...localMap[i18n.language],
  });

export const TimeAgo = ({ date }) => {
  const [TimeAgo, updateTimeAgo] = useState(getRelativeTime(date));
  useEffect(() => {
    setInterval(() => {
      updateTimeAgo(getRelativeTime(date));
    }, 50 * 1000);
  }, []);

  return (
    <span
      title={
        date && getFormatedDate(new Date(date), "eeee, MMMM do yyyy, h:mm aa")
      }
    >
      {date ? TimeAgo : "--"}
    </span>
  );
};

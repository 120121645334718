export const initialState = {
  organization: {
    verifyingOrganization: true,
    isOrganizationAPIError: false,
    organization: {
      features: {
        colors: {},
        dashboard: {
          logo: {},
        },
      },
      feedback_groups: [],
    },
    divisions: {
      currPage: 1,
      divisions: [],
    },
    levels: [],
    possibleParentDivisions: [],
    users: {
      currPage: 1,
      count: 0,
      results: [],
    },
    userRoles: {
      alert: { type: "", message: "" },
      list: [],
      details: {},
    },
    userDivisions: {
      prev: null,
      next: null,
      count: 0,
      results: [],
    },
    fetchedUser: {},
    userSaveUpdateSuccessful: false,
    primaryDivisions: [],
    feedbackGroupsList: [],
    tags: [],
    concerns: {
      data: [],
      isError: false,
      isPending: false,
    },
  },
  districts: {
    districts: [],
    selectedDistrict: null,
  },
  stores: {
    stores: [],
    selectedStore: null,
  },
  departments: {
    departments: [],
    selectedDepartment: null,
  },
  divisions: {
    divisions: [],
  },
  selectedFilters: {
    query: "",
    condition: "or",
    filterSets: {},
    division: null,
    level: null,
    startDate: null,
    endDate: null,
    months: {},
    years: {},
    dateFilterType: null,
    defaultDateRange: "This Week",
    actionType: null,
    sortBy: "P",
    tag: null,
    feedback_level: null,
    groups: {},
    search: "",
    concern: null,
    questionnaires: {},
    startTime: null,
    endTime: null,
    days: null,
  },
  groups: {},
  insights: {
    fetchingInsights: false,
    isFetchingConcernDetail: false,
    concernList: [],
    concernDetail: {},
    selectedConcern: null,
    selectedChildConcern: null,
    fetchingFeedback: true,
    feedback: {},
    insightsError: null,
  },
  profile: {
    fetchingUserProfile: false,
    updatingUserProfile: false,
    detail: {},
    errors: {},
  },
  questionaire: {
    data: null,
    error: null,
    divisionStats: null,
    division: null,
  },
  customerInvites: {
    customersData: null,
    selectedPage: 1,
    pageCount: 0,
  },
};

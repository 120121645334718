import React, { Component } from "react";
import * as analytics from "utils/analytics";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import * as utils from "./PresetFilterUtils";
import { DateFilterType } from "constants/constants";
import { hasTruthyKey } from "components/Organization/helper";
import { setPresetFilter } from "components/DashboardPage/actions";
import {
  ActionButton,
  ActionContainer,
  ModalFooter,
  PresetFilterWrapper,
  PresetFilterContainer,
  PresetCalendar,
  PresetCalendarContainer,
  PresetCalendarItem,
} from "../styled";

class PresetFilter extends Component {
  constructor(props) {
    super(props);
    const timeSpans = [
      { key: "months", values: utils.MONTHS },
      { key: "years", values: utils.GET_YEARS(props.startingYear) },
    ];
    this.state = { ...this.getStateFromProps(props), timeSpans };
  }

  getStateFromProps = (props) => {
    const { months, years } = props;
    return { months, years };
  };

  setPeriod = (span, spanTypeKey) => {
    const periodState = { ...this.state[spanTypeKey] };
    periodState[span.id] = !periodState[span.id];
    this.setState({ [spanTypeKey]: periodState });
  };

  applyFilter = () => {
    const yearsString = utils.timeSpanToString(
      this.state.years,
      utils.GET_YEARS(this.props.startingYear),
    );
    const monthsString = utils.timeSpanToString(
      this.state.months,
      utils.MONTHS,
    );
    analytics.logFilterChange(
      "Preset Date",
      `${yearsString} - ${monthsString}`,
      false,
    );

    this.props.setPresetFilter(this.state.months, this.state.years);
    this.props.hide(DateFilterType.PRESET);
  };

  isSomethingSelected = () => {
    const { months, years } = this.state;
    return hasTruthyKey(months) || hasTruthyKey(years);
  };

  render = () => {
    const isSelected = this.isSomethingSelected();
    const { t } = this.props;
    return (
      <PresetFilterWrapper>
        <PresetFilterContainer>
          {this.state.timeSpans.map((spanType) => {
            const timeSpanState = this.state[spanType.key];
            return (
              <PresetCalendarContainer>
                {spanType.values.map((span) => {
                  return (
                    <PresetCalendar
                      onClick={() => this.setPeriod(span, spanType.key)}
                    >
                      <PresetCalendarItem
                        style={{
                          backgroundColor: timeSpanState[span.id]
                            ? "var(--primary-color)"
                            : null,
                          color: timeSpanState[span.id] ? "#fff" : null,
                        }}
                      >
                        {t(span.label)}
                      </PresetCalendarItem>
                    </PresetCalendar>
                  );
                })}
              </PresetCalendarContainer>
            );
          })}
        </PresetFilterContainer>
        <ModalFooter>
          <ActionContainer>
            <ActionButton
              onClick={() => this.props.hide()}
              className="btn btn-primary"
            >
              {t("cancel")}
            </ActionButton>
            <ActionButton
              disabled={!isSelected}
              onClick={this.applyFilter}
              className="btn btn-primary apply"
            >
              {t("apply")}
            </ActionButton>
          </ActionContainer>
        </ModalFooter>
      </PresetFilterWrapper>
    );
  };
}

const mapDispatchToProps = {
  setPresetFilter,
};

const mapStateToProps = (state) => {
  return {
    months: state.selectedFilters.months,
    years: state.selectedFilters.years,
    organizationCreatedAt: state.organization.organization.created_at,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(PresetFilter));

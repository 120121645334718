import { combineReducers } from "redux";

import organization from "../components/reducer";
import auth from "../components/LoginPage/reducers";
import { feedbackReducer } from "../components/FeedbackPage/reducer";
import { notificationReducer } from "../components/common/Notification/reducer";
import selectedFeedback from "../components/FeedbackPage/SelectedFeedback/reducers";
import insights from "../components/InsightsPage/Insights/reducers";
import customers from "../components/InsightsPage/Customers/reducers";
import selectedFilters from "./selectedFiltersReducer";
import groupsReducer from "./groupReducer";
import profile from "../components/Profile/reducers";
import divisions from "./divisionsReducer";
import { customerUserReducer } from "../components/ManageUsersPage/reducer";
import survey from "components/Survey/reducer";
import customerInvitesReducer from "../components/CustomerInvitesPage/reducers";
import { dashboardReducer } from "../components/DashboardPage/reducer";
import { InvoiceReducer } from "../components/FeedbackPage/invoice/reducer";
import { UNAUTH_USER } from "../constants/constants";
import questionaireReducer from "./questionnaireReducer";
import { surveyBuilderReducer } from "../components/SurveyBuilder/reducer";
// import { telemetryReducer } from './telemetry';
import RCA from "components/RCA/reducer";

const appReducer = combineReducers({
  organization,
  auth,
  selectedFilters,
  feedback: feedbackReducer,
  survey,
  notification: notificationReducer,
  dashboard: dashboardReducer,
  customerUser: customerUserReducer,
  selectedFeedback,
  insights,
  customers,
  divisions,
  profile,
  questionaireReducer,
  groups: groupsReducer,
  customerInvitesReducer,
  invoices: InvoiceReducer,
  surveyBuilder: surveyBuilderReducer,
  RCA,
});

const rootReducer = (state, action) => {
  if (action.type === UNAUTH_USER) {
    state = { organization: state.organization };
  }

  return appReducer(state, action);
};

export default rootReducer;

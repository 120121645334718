import React from "react";
import Skeleton from "react-loading-skeleton";

export default () => {
  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        alignItems: "flex-end",
        justifyContent: "center",
        width: "100%",
        textAlign: "center",
        margin: "25px 0 12px",
      }}
    >
      <Skeleton width={"40px"} height={"80px"} style={{ margin: "0 10px" }} />
      <Skeleton width={"40px"} height={"260px"} style={{ margin: "0 10px" }} />
      <Skeleton width={"40px"} height={"150px"} style={{ margin: "0 10px" }} />
      <Skeleton width={"40px"} height={"210px"} style={{ margin: "0 10px" }} />
    </div>
  );
};

import produce from "immer";
import * as constants from "./constants";
import { initialState } from "./initialState";

export default (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case constants.LOAD_QUESTION_ANALYZERS:
        draft.results.loading = true;
        draft.results.error = false;
        break;

      case constants.LOAD_QUESTION_ANALYZERS_SUCCESS:
        draft.results.loading = false;
        draft.results.analyzers = action.data;
        break;

      case constants.LOAD_QUESTION_ANALYZERS_ERROR:
        draft.results.loading = false;
        draft.results.error = true;
        break;

      case constants.LOAD_SURVEY_SCRIPT:
        draft.script.loading = true;
        draft.script.error = false;
        break;

      case constants.LOAD_SURVEY_SCRIPT_SUCCESS:
        draft.script.loading = false;
        draft.script.error = false;
        draft.script.questions = action.payload;
        break;

      case constants.LOAD_SURVEY_SCRIPT_ERROR:
        draft.script.loading = false;
        draft.script.error = true;
        draft.script.questions = action.payload;
        break;
    }
  });
};

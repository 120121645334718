import React, { Component } from "react";
import * as analytics from "utils/analytics";
import { connect } from "react-redux";

import { selectDashboard } from "../DashboardPage/actions";

import { CustomDropdown } from "./Dropdown";
import { TooltipText } from "./Tooltip";
import { TOOLTIPS } from "constants/constants";

class QuestionnairePicker extends Component {
  state = { visible: false };
  render = () => {
    const { selectedDashboard } = this.props;

    return (
      <>
        {selectedDashboard && (
          <>
            <CustomDropdown
              options={this.props.dashboards}
              getLabel={(option) => option.title}
              getType={(option) => option.group_title}
              getKey={(option) => option.id}
              getStatus={(option) =>
                option.id === "omni"
                  ? "all"
                  : option.is_active
                  ? "active"
                  : "deactive"
              }
              onClick={(q) => {
                if (q.id !== selectedDashboard.id) {
                  analytics.logSwitchProjectFromPicker(q);
                  this.props.selectDashboard(q, true);
                }
              }}
              selectedOption={selectedDashboard}
              onToggle={(isOpen) => this.setState({ visible: isOpen })}
            />
            {!this.state.visible && (
              <TooltipText
                tooltipText={TOOLTIPS.QUESTIONNAIRE_PICKER}
                align="bottomLeft"
              />
            )}
          </>
        )}
      </>
    );
  };
}

const mapDispatchToProps = {
  selectDashboard,
};

const mapStateToProps = (state) => {
  return {
    dashboards: state.dashboard.dashboards,
    selectedDashboard: state.dashboard.selectedDashboard,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuestionnairePicker);

import { toPairs, startCase, isEmpty, omit, some } from "lodash";
import { isFalsy } from "../../utils/genericHelper";

const getTabs = (state) => state.surveyBuilder.ui.tabs.list;

const getActiveTab = (state) => state.surveyBuilder.ui.tabs.activeTab;

const getQuestionnaireIsSubmitting = (state) =>
  state.surveyBuilder.ui.isSubmitting;
const getQuestionnaireSuccess = (state) =>
  state.surveyBuilder.ui.success.questionnaire;
const getQuestionnaireError = (state) =>
  state.surveyBuilder.ui.error.questionnaire;
const getQuestionnaireLoading = (state) =>
  state.surveyBuilder.ui.loading.questionnaire;

const _getFormattedError = (obj) =>
  toPairs(obj).reduce((errorMsg, error) => {
    return `${errorMsg ? errorMsg + "\n" : ""} ${startCase(error[0])}: ${
      error[1]
    }`;
  }, "");

const getSurveyDetailError = (surveyBuilder) =>
  surveyBuilder.ui.error &&
  _getFormattedError(omit(surveyBuilder.ui.error.questionnaire, ["questions"]));

const anyQuestionHasError = (error) => error && some(error.questions);

const getQuestionError = (error, questionIndex) => {
  if (!error || isEmpty(error.questions)) return;
  if (!isEmpty(error.questions[questionIndex])) {
    return _getFormattedError(
      omit(error.questions[questionIndex], ["options"]),
    );
  }
};

const getOptionError = (error, questionIndex, optionIndex) => {
  if (!error || isEmpty(error.questions)) return;

  const question = error.questions[questionIndex];
  if (!isEmpty(question)) {
    const option = question.options && question.options[optionIndex];
    if (!isEmpty(option)) {
      return _getFormattedError(omit(option, ["sub_options"]));
    }
  }
};

const getSubOptionError = (
  error,
  questionIndex,
  optionIndex,
  subOptionIndex,
) => {
  if (!error || isEmpty(error.questions)) return;

  const question = error.questions[questionIndex];
  if (!isEmpty(question)) {
    const option = question.options && question.options[optionIndex];
    if (!isEmpty(option)) {
      const subOption =
        option.sub_options && option.sub_options[subOptionIndex];
      if (!isEmpty(subOption)) {
        return _getFormattedError(subOption);
      }
    }
  }
};

const getQuestionnaire = (state) => state.surveyBuilder.questionnaire;
const getQuestionnaireQuestions = (state) =>
  state.surveyBuilder.questionnaire.questions;

const getQuestionnaireAntiSpammers = (state) =>
  state.surveyBuilder.questionnaire.questionnaire_antispammers;

const getSelectedQuestion = (state) => {
  const selectedQuestionVirtualId = state.surveyBuilder.selected.question;
  if (selectedQuestionVirtualId === -1) return null;

  const questions = getQuestionnaireQuestions(state);
  const question = questions.filter(
    (q) => q.virtual_id === selectedQuestionVirtualId,
  )[0];

  return !isFalsy(question) ? question : null;
};

const getQuestionOptions = (state) => {
  const question = getSelectedQuestion(state);

  return question ? question.options : [];
};

const getSelectedOption = (state) => {
  const selectedOptionVirtualId = state.surveyBuilder.selected.option;
  if (selectedOptionVirtualId === -1) return null;

  const options = getQuestionOptions(state);
  const option = options.filter(
    (o) => o.virtual_id === selectedOptionVirtualId,
  )[0];

  return !isFalsy(option) ? option : null;
};

const getSubOptions = (state) => {
  const selectedOption = getSelectedOption(state);

  return selectedOption ? selectedOption.sub_options : [];
};

const getSelectedSubOption = (state) => {
  const selectedSubOptionVirtualId = state.surveyBuilder.selected.subOption;
  if (selectedSubOptionVirtualId === -1) return null;

  const subOptions = getSubOptions(state);
  const subOption = subOptions.filter(
    (so) => so.virtual_id === selectedSubOptionVirtualId,
  )[0];

  return !isFalsy(subOption) ? subOption : null;
};

const getSurveyConfig = (state) => state.surveyBuilder.config;
const getQuestionTypes = (state) => getSurveyConfig(state).questionTypes;
const getQuestionKinds = (state) => getSurveyConfig(state).questionKinds;
const getQuestionLevels = (state) => getSurveyConfig(state).levels;
const getQuestionScreenTypes = (state) =>
  getSurveyConfig(state).questionScreenTypes;
const getQuestionChartTypes = (state) => getSurveyConfig(state).chartTypes;
const getOptionLoopTypes = (state) => getSurveyConfig(state).optionLoopTypes;
const getOptionScoreTypes = (state) => getSurveyConfig(state).optionScoreTypes;
const getSurveyTypes = (state) => getSurveyConfig(state).surveyTypes;
const getSurveyRoles = (state) => getSurveyConfig(state).roles;
const getSurveyFeedbackGroups = (state) =>
  getSurveyConfig(state).feedbackGroups;
const getSurveyDivisions = (state) => getSurveyConfig(state).divisions;
const getSupportedFontStyles = (state) =>
  getSurveyConfig(state).supportedFontStyles;

const getSurveyFontStyles = (state) => getQuestionnaire(state).font_styles;
const getSurveyFontSizes = (state) => getQuestionnaire(state).font_sizes;
const getSurveyColors = (state) => getQuestionnaire(state).colors;

const getSurveysLoading = (state) => state.surveyBuilder.ui.loading.surveys;
const getSurveysError = (state) => state.surveyBuilder.ui.error.surveys;
const getSurveys = (state) => getSurveyConfig(state).surveys;

export {
  getTabs,
  getActiveTab,
  getQuestionnaire,
  getSurveyDetailError,
  anyQuestionHasError,
  getQuestionError,
  getOptionError,
  getSubOptionError,
  getQuestionnaireQuestions,
  getQuestionnaireAntiSpammers,
  getSelectedQuestion,
  getQuestionOptions,
  getSelectedOption,
  getSelectedSubOption,
  getSubOptions,
  getQuestionTypes,
  getQuestionKinds,
  getQuestionLevels,
  getQuestionScreenTypes,
  getQuestionChartTypes,
  getOptionLoopTypes,
  getOptionScoreTypes,
  getSurveyTypes,
  getSurveyRoles,
  getSurveyFeedbackGroups,
  getSurveyDivisions,
  getSurveyColors,
  getQuestionnaireIsSubmitting,
  getQuestionnaireSuccess,
  getQuestionnaireError,
  getSurveysLoading,
  getSurveysError,
  getSurveys,
  getQuestionnaireLoading,
  getSurveyFontSizes,
  getSurveyFontStyles,
  getSupportedFontStyles,
};

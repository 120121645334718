import styled from "styled-components";

import { ToolTip } from "components/common/FloatingLabel/styled";

export const DateWrapper = styled.div`
  position: absolute;
  z-index: 10;
  box-shadow: 3px 8px 12px #00000029;
`;

export const Label = styled.label`
  font-size: 12px;
  color: #666666;
  position: absolute;
  left: 20px;
  margin-bottom: 0;
  top: ${({ topLabelPosition }) =>
    topLabelPosition ? topLabelPosition : "-9"}px;
  background: rgb(255 255 255);
  padding: 0 8px;
  z-index: 2;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")};
  position: relative;
  margin-top: 40px;

  & .css-1qjl6l7-control {
    background-color: white;
  }

  & .css-1wa3eu0-placeholder {
    color: #d3d3d3;
  }

  &:hover ${ToolTip} {
    display: block;
    top: -40px;
    right: 20px;
    transition: 0.5s ease-in-out right;
  }
`;

export const ComponentWrapper = styled.div`
  position: relative;
`;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { Wrapper, Label } from "./styled";

import { setFormFieldValue, loadTeamLeads } from "components/RCA/actions";
import {
  formFieldValueSelector,
  allTeamLeadsSelector,
} from "components/RCA/selectors";

const TeamLeadField = ({ field }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const [isOpen, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const ref = useRef();

  const allTeamLeads =
    useSelector((state) => allTeamLeadsSelector(state)) || [];

  const selectedTeamLead =
    useSelector((state) => formFieldValueSelector(state, fieldKey)) || null;

  const selectTeamLead = (teamLead) => {
    dispatch(setFormFieldValue("team_members", []));
    dispatch(
      setFormFieldValue(fieldKey, {
        full_name: teamLead.label,
        id: teamLead.value,
      }),
    );
  };

  useEffect(() => {
    dispatch(loadTeamLeads());
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const teamLeadOptions = allTeamLeads.map((teamLead) => {
    return {
      label: teamLead.full_name,
      value: teamLead.id,
    };
  });

  let defaultValue;
  if (selectedTeamLead) {
    defaultValue = teamLeadOptions.find(
      (teamLead) => teamLead.value == selectedTeamLead.id,
    );
  }

  const customStyles = (value) => ({
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${value ? "red" : "#e9e9e9"}`,
      minHeight: "46px",
      padding: "2px 24px",
    }),
  });

  const checkForError = (e) => {
    if (!defaultValue) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <Wrapper ref={ref} onClick={() => setOpen(!isOpen)}>
      <Label htmlFor={fieldKey}>{field.name}</Label>
      <Select
        value={defaultValue}
        name="team_lead"
        options={teamLeadOptions}
        classNamePrefix="select"
        onBlur={checkForError}
        onChange={selectTeamLead}
        styles={customStyles(isError)}
      />
    </Wrapper>
  );
};

export default TeamLeadField;

import React from "react";

import { PopUpBox, Box, ButtonBox, ActionButton } from "./styled";

export const PopUp = (props) => {
  return (
    <PopUpBox>
      <Box>
        {props.content}
        <ButtonBox>
          <ActionButton
            className="btn btn-primary"
            onClick={props.handleCancel}
          >
            Cancel
          </ActionButton>
          <ActionButton
            className="btn btn-primary confirm"
            onClick={props.handleConfirm}
          >
            Confirm
          </ActionButton>
        </ButtonBox>
      </Box>
    </PopUpBox>
  );
};

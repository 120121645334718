import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { values, keyBy } from "lodash";

import {
  LocationWrapper,
  Container,
  MultiSelectWrapper,
  CategorySelect,
  Category,
  DescriptionSelect,
  Description,
  DoneButton,
} from "../styled";

import {
  loadLevels,
  setLocationMatchError,
  setFormFieldValue,
  verifyValidLocation,
} from "components/RCA/actions";
import {
  formFieldValueSelector,
  allLevelsSelector,
  locationMatchErrorSelector,
} from "components/RCA/selectors";

import { FloatingLabel } from "components/common/FloatingLabel";

import { last } from "lodash";

const DepartmentField = ({ field }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const selectedLevels = values(
    useSelector((state) => formFieldValueSelector(state, fieldKey)),
  );

  const locationError = useSelector(locationMatchErrorSelector) || "";

  const [_selectedLevels, _setSelectedLevels] = useState({});

  useEffect(() => {
    if (selectedLevels) {
      _setSelectedLevels(keyBy(selectedLevels, (level) => level.level));
    }
  }, [JSON.stringify(selectedLevels)]);

  const selectDivision = (level, division, isSelected) => {
    const selectedLevel = _selectedLevels[level.level];
    const updatedSelectedLevels = isSelected
      ? {
          ..._selectedLevels,
          [level.level]: {
            ...selectedLevel,
            titles: selectedLevel.titles.filter((t) => t !== division.title),
          },
        }
      : {
          ..._selectedLevels,
          [level.level]: {
            ...selectedLevel,
            titles: [...selectedLevel?.titles, division.title],
          },
        };

    _setSelectedLevels(updatedSelectedLevels);
  };

  const setSelectedLevels = () => {
    dispatch(setFormFieldValue("concern", null));
    dispatch(setFormFieldValue("other_concern", null));
    dispatch(setLocationMatchError(""));
    dispatch(verifyValidLocation(values(_selectedLevels)));
    dispatch(setFormFieldValue(fieldKey, values(_selectedLevels)));
  };

  const ref = useRef();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    dispatch(loadLevels());
  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (isOpen && !ref.current.contains(event.target)) {
        setOpen(false);
        setSelectedLevels();
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, [isOpen, _selectedLevels]);

  const titles = values(_selectedLevels)
    .flatMap((d) => d.titles)
    .reduce((t, d) => t + ", " + d, "")
    .slice(2);

  const levels = useSelector((state) => allLevelsSelector(state)) || [];

  return (
    <LocationWrapper ref={ref} onClick={() => !isOpen && setOpen(true)}>
      <FloatingLabel
        id={fieldKey}
        label={field.name}
        placeholder={field.placeholder}
        value={titles}
        error={locationError}
        readOnly
      />
      {isOpen && (
        <Container width={levels?.length}>
          <MultiSelectWrapper>
            {levels.map((level) => {
              return (
                <CategorySelect width={levels?.length}>
                  <Category>{level.name}</Category>
                  <DescriptionSelect
                    borderRight={level.name !== last(levels).name}
                  >
                    {level.divisions.map((division) => {
                      const selectedLevel = _selectedLevels[level.level];
                      const isSelected = selectedLevel.titles.find(
                        (t) => t === division.title,
                      );

                      return (
                        <Description
                          key={division.title}
                          isSelected={isSelected}
                          onClick={(e) => {
                            e.stopPropagation();
                            selectDivision(level, division, isSelected);
                          }}
                        >
                          {division.title}
                        </Description>
                      );
                    })}
                  </DescriptionSelect>
                </CategorySelect>
              );
            })}
          </MultiSelectWrapper>
          <DoneButton
            type="button"
            onClick={() => {
              setOpen(false);
              setSelectedLevels();
            }}
          >
            Done
          </DoneButton>
        </Container>
      )}
      <p style={{ color: "red", margin: "2px 0px 1px 25px" }}>
        {locationError}
      </p>
    </LocationWrapper>
  );
};

export default DepartmentField;

import React from "react";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
import { Col } from "react-bootstrap";
import * as analytics from "utils/analytics";

import {
  markNotificationAsRead,
  loadNotifications,
  loadMoreNotifications,
} from "./actions";
import { NotificationList } from "./NotificationList";

class NotificationListCard extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { loadNotifications } = this.props;
    loadNotifications();
    analytics.logNotificationCenterLoad();
  }

  render() {
    return (
      <Col md={3} xs={12} className="notifications notifications--card">
        <NotificationList
          redirect={true}
          {...this.props}
          source="notification center"
        />
      </Col>
    );
  }
}

const mapStateToProps = ({ notification }) => ({
  notification: notification,
});

const mapDispatchToProps = {
  markNotificationAsRead,
  loadNotifications,
  loadMoreNotifications,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationListCard);

import React, { Component } from "react";
import { connect } from "react-redux";

import {
  selectAnalyzerUnit,
  selectAnalyzerGraph,
} from "components/DashboardPage/actions";

import { Card } from "components/common/Card";
import { NoDataFound } from "components/common/NoDataFound";
import Chart from "components/DashboardPage/Analyzer/charts";
import SkeletonGraph from "components/common/Skeleton/SkeletonGraph";
import SkeletonTable from "components/common/Skeleton/SkeletonTable";
import { _defaultChartParser } from "components/DashboardPage/parsers";

class ConcernDetail extends Component {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(props);
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.concerns !== this.props.concerns ||
      this.props.likes !== prevProps.likes ||
      this.props.selectedLevel !== prevProps.selectedLevel
    ) {
      const state = this.getStateFromProps(this.props);
      this.setState({ ...state });
    }
  };

  getStateFromProps = (props) => {
    const { children, selectedParent, selectedChild, list } = props.concerns;
    const selected = selectedChild.id ? selectedChild : selectedParent;
    let data =
      (selectedChild.id && children[selectedParent.id]
        ? children[selectedParent.id]
        : list) || [];

    data = data.filter((concern) => concern.name !== "All");
    if (selected.name !== "All" && data.length) {
      const groupedConcerns = groupOtherConcerns(selected, data);
      data = [selected, groupedConcerns];
    }
    data = _defaultChartParser(data, "name");
    return { data };
  };

  renderLoading = () => {
    const { analyzer, graph } = this.props;
    let type = analyzer.type;
    if (graph === "table") type = graph;

    const Skeleton = skeletons[type];

    return <Skeleton />;
  };

  isLoading = () => {
    const { loading, concerns } = this.props;
    return loading || !concerns.selectedParent.id;
  };

  renderGraph = () => {
    const { unit, graph, analyzer } = this.props;
    const { data } = this.state;
    return <Chart analyzer={analyzer} unit={unit} graph={graph} data={data} />;
  };

  renderNoData = () => <NoDataFound />;

  render = () => {
    const { analyzer, unit, graph } = this.props;
    return (
      <Card
        units={analyzer.units}
        selectedUnit={unit}
        graphs={analyzer.graphs}
        selectedGraph={graph}
        heading={analyzer.heading}
        selectUnit={(u) => this.props.selectAnalyzerUnit(analyzer.slug, u)}
        selectGraph={(g) => this.props.selectAnalyzerGraph(analyzer.slug, g)}
        type={analyzer.type}
        tooltip={analyzer.heading && analyzer.tooltips?.[0]}
      >
        {this.isLoading()
          ? this.renderLoading()
          : !this.props.error && this.state.data.labels.length
          ? this.renderGraph()
          : this.renderNoData()}
      </Card>
    );
  };
}

const mapDispatchToProps = {
  selectAnalyzerUnit,
  selectAnalyzerGraph,
};

const mapStateToProps = (state, ownProps) => {
  return {
    likes: state.dashboard.insights.likes,
    concerns: state.dashboard.concerns,
    loading: state.dashboard.concerns.loading,
    error: state.dashboard.concerns.error,
    selectedLevel: state.dashboard.insights.feedback_level,
    unit:
      state.dashboard.selectedUnits[ownProps.analyzer.slug] ||
      ownProps.analyzer.units[0],
    graph:
      state.dashboard.selectedGraphs[ownProps.analyzer.slug] ||
      ownProps.analyzer.graphs[0],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConcernDetail);

const skeletons = {
  graph: SkeletonGraph,
  table: SkeletonTable,
};

const groupOtherConcerns = (selectedConcern, concerns) => {
  const groupedConcerns = concerns.reduce(
    (groupOtherConcerns, concern) => {
      if (concern.id !== selectedConcern.id) {
        groupOtherConcerns.count += concern.count;
        groupOtherConcerns.percentage += parseFloat(concern.percentage);
      }
      return groupOtherConcerns;
    },
    { count: 0, percentage: 0, name: "Others" },
  );
  groupedConcerns.percentage = groupedConcerns.percentage.toFixed(1) + "%";
  return groupedConcerns;
};

import * as constants from "./constants";

export const loadCustomerUser = () => {
  return {
    type: constants.LOAD_CUSTOMER_USERS,
  };
};

export const loadCustomerUserSuccess = (data) => {
  return {
    type: constants.LOAD_CUSTOMER_USERS_SUCCESS,
    data,
  };
};

export const loadCustomerUserError = (err) => {
  return {
    type: constants.LOAD_CUSTOMER_USERS,
    err,
  };
};

export const loadNewUserPage = () => {
  return {
    type: constants.LOAD_NEW_USER_PAGE,
  };
};

import { call, select, put, takeLatest } from "redux-saga/effects";

import * as apis from "./apis";
import * as constants from "./constants";
import * as actions from "./actions";
import { getOrganizationId } from "../../Organization/selectors";

function* loadNotifications() {
  try {
    const organizationId = yield select(getOrganizationId);
    const apiData = yield call(apis.getNotifications, organizationId);
    yield put(actions.loadNotificationsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadNotificationsError(error));
  }
}

function* notificationsWatcher() {
  yield takeLatest(constants.LOAD_NOTIFICATIONS, loadNotifications);
}

function* loadMoreNotifications(action) {
  try {
    const apiData = yield call(apis.getMoreNotifications, action.url);
    yield put(actions.loadMoreNotificationsSuccess(apiData));
  } catch (error) {
    yield put(actions.loadMoreNotificationsError(error));
  }
}

function* loadMoreNotificationsWatcher() {
  yield takeLatest(constants.LOAD_MORE_NOTIFICATIONS, loadMoreNotifications);
}

function* markAsReadNotification(action) {
  try {
    const organizationId = yield select(getOrganizationId);
    yield call(
      apis.markAsReadNotification,
      organizationId,
      action.notificationId,
    );
  } catch (error) {
    console.log({ error });
  }
}

function* markAsReadNotificationWatcher() {
  yield takeLatest(
    constants.REMOTE_MARK_NOTIFICATION_AS_READ,
    markAsReadNotification,
  );
}

export const sagas = [
  notificationsWatcher,
  loadMoreNotificationsWatcher,
  markAsReadNotificationWatcher,
];

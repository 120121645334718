import { initialState } from "../../../initialState/initialState";
import {
  FETCHING_INSIGHTS,
  INSIGHTS_SUCCESS,
  INSIGHTS_FAILURE,
  CLEAR_INSIGHTS,
  KPI_DETAIL_SUCCESS,
  FETCHING_KPI_DETAIL,
  SELECTED_KPI_UPDATE,
  SELECTED_CHILD_CONCERN,
  KPI_FEEDBACK_SUCCESS,
  FETCHING_KPI_FEEDBACK,
  CLEAR_SELECTED_FILTERS,
} from "../../../constants/constants";

const insightsReducer = (state = initialState.insights, action) => {
  switch (action.type) {
    case FETCHING_INSIGHTS:
      return {
        ...state,
        insights: [],
        fetchingInsights: true,
      };

    case INSIGHTS_SUCCESS:
      return {
        ...state,
        fetchingInsights: false,
        isFetchingConcernDetail: true,
        fetchingCustomerSegments: true,
        concernList: action.concernList,
        selectedConcern: action.concernList[0],
      };

    case FETCHING_KPI_DETAIL:
      return {
        ...state,
        isFetchingConcernDetail: true,
        concernDetail: {},
      };

    case SELECTED_KPI_UPDATE:
      return {
        ...state,
        selectedConcern: action.kpi,
        selectedChildConcern: null,
      };

    case SELECTED_CHILD_CONCERN:
      return {
        ...state,
        selectedChildConcern: action.concern,
      };

    case CLEAR_SELECTED_FILTERS:
      return {
        ...state,
        selectedChildConcern: "",
      };

    case INSIGHTS_FAILURE:
      return Object.assign({}, state, {
        fetchingInsights: false,
        insights: [],
        insightsError: action.err,
      });

    case KPI_DETAIL_SUCCESS:
      return {
        ...state,
        isFetchingConcernDetail: false,
        concernDetail: action.detail,
      };

    case FETCHING_KPI_FEEDBACK:
      return {
        ...state,
        fetchingFeedback: true,
        feedback: {},
      };

    case KPI_FEEDBACK_SUCCESS:
      return {
        ...state,
        fetchingFeedback: false,
        feedback: action.feedback,
      };

    case CLEAR_INSIGHTS:
      return Object.assign({}, state, { insights: [] });

    default:
      return state;
  }
};

export default insightsReducer;

import React from "react";

export const Notification = ({ id, status, onClick }) => {
  return (
    <i
      className={`notification fa fa-bell-o ${!status && "disabled"}`}
      onClick={(e) => {
        status ? onClick(e, id) : e.preventDefault();
      }}
      title={status ? "remind" : "cannot remind"}
    />
  );
};

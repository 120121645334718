import React from "react";
import numeral from "numeral";
import { startCase, truncate, fill, zipWith } from "lodash";
import { Bar, Doughnut, Pie, Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

import i18n from "../../../i18n";

import Table from "components/DashboardPage/Table/ChartTable";
import { NoDataFound } from "components/common/NoDataFound";
import { formatNumeral } from "utils/genericHelper";

const charts = {
  bar: Bar,
  pie: Pie,
  line: Line,
  donut: Doughnut,
};

const Chart = (props) => {
  const { analyzer, graph, unit } = props;
  const { t } = useTranslation();

  if (!graph) {
    return;
  }

  if (graph === "table") {
    const data = props.data.cumulative || props.data;
    return <Table unit={unit} data={data} analyzer={analyzer} />;
  }

  const data =
    graph === "line"
      ? getDataForTimeSeries(props)
      : getDataForCumulativeStats(props);

  if (circularCharts.includes(graph) && isAllZero(data)) {
    return <NoDataFound message={t("Empty Chart")} />;
  }

  const Chart = charts[graph];
  return (
    <Chart
      redraw
      data={data}
      options={{
        ...defaultOptions,
        ...chartOptions[graph](unit, analyzer),
        ...analyzer.chart_options,
      }}
      height={300}
      width={600}
    />
  );
};

const getDataForCumulativeStats = ({ analyzer, data, graph, unit }) => {
  let _data = data.cumulative || data;
  const labels = groupExtraLabelsTogether(_data.labels, analyzer.max_bars);
  return {
    labels,
    datasets: _data[unit].map((d, datasetIndex) => {
      const { values, ...rest } = d;
      const _values = groupExtraValuesTogether(values, analyzer.max_bars);
      const datasetArgs = { analyzer, datasetIndex, values: _values, labels };
      const options = datasetOptions[graph](datasetArgs);
      return {
        data: _values.map((v) => parseFloat(v)),
        ...options,
        ...rest,
        ...analyzerDatasetOptions(analyzer, graph),
      };
    }),
  };
};

const getDataForTimeSeries = ({ analyzer, data, unit }) => {
  const _data = data.time_series || data;
  const datasets = groupExtraDatasetsTogether(_data[unit], analyzer.max_bars);
  return {
    labels: _data.labels,
    datasets: datasets.map((d, datasetIndex) => {
      const { values, label, ...rest } = d;
      const labels = _data.labels;
      const datasetArgs = { values, label, labels, analyzer, datasetIndex };
      const options = datasetOptions.line(datasetArgs);
      return {
        data: values,
        label,
        ...options,
        ...rest,
        ...analyzer.dataset_options,
      };
    }),
  };
};

const defaultOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      display: true,
    },
  },
};

const datasetOptions = {
  bar: (datasetArgs) => {
    const backgroundColor = getChartColors(datasetArgs);
    const feedbackByHour =
      datasetArgs.analyzer.name === "durational_count_hour_wise_analysis";
    const barThickness = feedbackByHour ? 20 : 35;
    return { backgroundColor, barThickness };
  },
  pie: (datasetArgs) => {
    const backgroundColor = getChartColors(datasetArgs);
    return { backgroundColor, barThickness: 20 };
  },
  donut: (datasetArgs) => {
    const backgroundColor = getChartColors(datasetArgs);
    return { backgroundColor, barThickness: 20 };
  },
  line: ({ analyzer, label, values, datasetIndex }) => {
    const color =
      analyzer.label_colors?.[label] ||
      generateChartColors(values)[datasetIndex % values.length];
    return {
      backgroundColor: "transparent",
      borderColor: color,
      pointHitRadius: 20,
    };
  },
};

const chartOptions = {
  bar: (unit, analyzer) => {
    return {
      plugins: {
        ...tooltipOptions(unit, analyzer),
        legend: {
          display: false,
        },
      },
      ...scalesOptions(unit, analyzer),
    };
  },
  donut: (unit, analyzer) => {
    return {
      cutout: "65%",
      plugins: {
        ...tooltipOptions(unit, analyzer),
      },
    };
  },
  pie: (unit, analyzer) => {
    return {
      cutout: "65%",
      plugins: {
        ...tooltipOptions(unit, analyzer),
      },
    };
  },
  line: (unit, analyzer) => {
    return {
      tension: 0.3,
      elements: {
        point: {
          radius: 0,
        },
      },
      plugins: {
        ...tooltipOptions(unit, analyzer),
      },
      ...scalesOptions(unit, analyzer),
    };
  },
};

const analyzerDatasetOptions = (analyzer, graph) => {
  return analyzer.dataset_options?.[graph] || analyzer.dataset_options;
};

const scalesOptions = (unit, analyzer) => {
  const options = analyzer.scales_options || DEFAULT_SCALES_OPTIONS;
  const suggestedMinMax = {
    suggestedMax: unit === "percentage" ? 100 : 10,
    suggestedMin: 0,
  };
  let scales = {
    x: {
      grid: {
        display: false,
      },
      ticks: {},
    },
  };

  const y_axes = options?.y;
  if (y_axes?.length >= 2) {
    y_axes.forEach(
      (y_axis) => (scales[y_axis.id] = { ...y_axis, ...suggestedMinMax }),
    );
  } else {
    const yAxisLabel =
      options?.y?.title?.text || analyzer.y_axis_label || analyzer.x_axis_label;

    scales["y"] = {
      ...suggestedMinMax,
      ticks: {
        callback: (value) => numeral(value).format("0,0"),
      },
      title: {
        display: true,
        text:
          unit === "percentage"
            ? i18n.t("Percentage")
            : i18n.t(yAxisLabel) || i18n.t(startCase(unit)),
      },
    };
  }

  return { scales };
};

const DEFAULT_SCALES_OPTIONS = {
  x: { grid: { display: false }, ticks: {} },
  y: { title: { display: true }, ticks: {}, beginAtZero: true },
};

const tooltipOptions = (unit, analyzer) => {
  return {
    tooltip: {
      callbacks: {
        title: (context) => context[0].label || "",
        label: (context) => {
          const value = context.formattedValue;
          const label = context.dataset.label;
          const isPercentage = unit === "percentage";
          const prefix = isPercentage ? "" : analyzer.prefix || "";
          const suffix = isPercentage ? "" : analyzer.suffix || "";
          const v = formatNumeral(value, {
            isPercentage,
            format: analyzer.number_format,
          });
          return `${label}: ${prefix}${v}${suffix}`;
        },
      },
    },
  };
};

const getChartColors = ({ analyzer, values, labels }) => {
  const backgroundColor = analyzer.label_colors
    ? labels.map((label) => analyzer.label_colors[label])
    : generateChartColors(values);

  return backgroundColor;
};

const groupExtraLabelsTogether = (labels, limit) => {
  if (!limit || labels.length <= limit) return labels;
  limit = limit - 1;
  const _labels = labels.slice(0, limit);
  _labels.push("Others");
  return _labels;
};

const groupExtraValuesTogether = (values, limit) => {
  if (!limit || values.length <= limit) return values;
  limit = limit - 1;
  const _values = values.slice(0, limit);
  const others = values
    .slice(limit, values.length)
    .reduce((sum, v) => sum + v, 0);
  _values.push(others);
  return _values;
};

const groupExtraDatasetsTogether = (datasets, limit) => {
  if (!limit || datasets.length <= limit) return datasets;
  limit = limit - 1;

  const _datasets = datasets.slice(0, limit);

  const combinedDataset = datasets.slice(limit, datasets.length).reduce(
    (combined, dataset) => {
      const values = zipWith(combined.values, dataset.values, (a, b) => a + b);
      return { ...combined, values };
    },
    {
      label: "Others",
      values: fill(Array(datasets[0].values.length), 0),
    },
  );
  _datasets.push(combinedDataset);

  return _datasets;
};

const generateChartColors = (values) => {
  if (!generatedColors[values.length]) {
    generatedColors[values.length] = pickColors.ordered(values);
  }
  return generatedColors[values.length];
};

const pickColors = {
  ordered: (values) => {
    let idx = -1;
    return values.map(() => {
      if (idx >= colors.length - 1) idx = -1;
      idx++;
      return colors[idx];
    });
  },

  random: (values) => {
    let _colors = [...colors];
    return values.map(() => {
      if (!_colors.length) _colors = [...colors];
      const randomIndex = Math.floor(Math.random() * _colors.length);
      const [randomColor] = _colors.splice(randomIndex, 1);
      return randomColor;
    });
  },
};

const generatedColors = {};

const circularCharts = ["donut", "pie"];

const isAllZero = (data) =>
  data.datasets.every((dataset) => dataset.data.every((v) => !parseFloat(v)));

const colors = [
  "#FF7F0E",
  "#F5BE40",
  "#32A9B8",
  "#1F77B4",
  "#CF3722",
  "#248139",
];

export default Chart;

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { Wrapper, Label } from "./styled";

import { setFormFieldValue } from "components/RCA/actions";
import {
  formFieldValueSelector,
  isFieldRequired,
} from "components/RCA/selectors";

const LoopField = ({ field }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const [isOpen, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);

  const ref = useRef();

  const selectedLoop =
    useSelector((state) => formFieldValueSelector(state, fieldKey)) || null;

  const selectLoop = (loop) => {
    dispatch(setFormFieldValue("concern", null));
    dispatch(setFormFieldValue("other_concern", null));
    dispatch(setFormFieldValue(fieldKey, loop?.value));
  };

  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const isLoopRequired = useSelector((state) => isFieldRequired(state, "loop"));

  const loopOptions = [
    { label: "Inner", value: "I" },
    { label: "Outer", value: "O" },
  ];

  if (!isLoopRequired) {
    loopOptions.unshift({ label: "None", value: null });
  }

  let defaultValue;
  if (selectedLoop) {
    defaultValue = loopOptions.find((loop) => loop.value == selectedLoop);
  }

  const customStyles = (value) => ({
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${value ? "red" : "#e9e9e9"}`,
      minHeight: "46px",
      padding: "2px 24px",
    }),
  });

  const checkForError = (e) => {
    if (!defaultValue && isLoopRequired) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <Wrapper ref={ref} onClick={() => setOpen(!isOpen)}>
      <Label htmlFor={fieldKey}>{field.name}</Label>
      <Select
        value={defaultValue}
        name="team_lead"
        options={loopOptions}
        classNamePrefix="select"
        onBlur={checkForError}
        onChange={selectLoop}
        styles={customStyles(isError)}
      />
    </Wrapper>
  );
};

export default LoopField;

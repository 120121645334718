import React, { Component } from "react";
import ConcernTrendPercentage from "./ConcernTrendPercentage";
import ImpactOnScore from "./ImpactOnScore";

class ConcernTrendHeader extends Component {
  render() {
    const { analyzer } = this.props;

    return (
      <div className="line-header" style={{ marginTop: 0 }}>
        <ImpactOnScore analyzer={analyzer} />
        <ConcernTrendPercentage />
      </div>
    );
  }
}

export default ConcernTrendHeader;

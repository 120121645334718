import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import isEmpty from "lodash/isEmpty";
import { Bar } from "react-chartjs-2";
import _ from "lodash";

import { Panel } from "../../common/Panel";
import { getSatisfactionLevelChartData } from "../../../utils/chartsData";
import { loadSatisfactionLevel } from "../actions";

class SatisfactionLevel extends Component {
  constructor(props) {
    super(props);
  }

  getAPI() {
    const { loadSatisfactionLevel } = this.props;
    loadSatisfactionLevel();
  }

  componentDidMount() {
    this.getAPI();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps.selectedFilters, this.props.selectedFilters)) {
      this.getAPI();
    }
  }

  render() {
    const { satisfactionLevel } = this.props;
    const { fetching, analysis } = satisfactionLevel;
    if (isEmpty(analysis) || fetching) {
      return (
        <Panel header="Satisfaction Level">
          <Spinner name="wandering-cubes" fadeIn="none" />
        </Panel>
      );
    }

    return (
      <Panel header={`Satisfaction Level: ${analysis.stats.csat || 0}`}>
        <div>
          <Bar
            data={getSatisfactionLevelChartData(analysis)}
            height={280}
            options={{
              maintainAspectRatio: false,
              tooltip: {
                mode: "index",
              },
              scales: {
                x: [
                  {
                    grid: {
                      display: false,
                    },
                  },
                ],
                y: [
                  {
                    ticks: {},
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Percentage",
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = ({ selectedFilters, dashboard }) => ({
  selectedFilters,
  satisfactionLevel: dashboard.satisfactionLevel,
});

export default connect(mapStateToProps, { loadSatisfactionLevel })(
  SatisfactionLevel,
);

import React from "react";
import { Button, LeftArrowImage } from "./styled";
import GoBackImage from "../../../assets/img/go-back-arrow.png";

export const GoBackButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <LeftArrowImage src={GoBackImage} alt="" />
      Go Back
    </Button>
  );
};

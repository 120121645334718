import styled, { css } from "styled-components";
import Spinner from "react-spinkit";

export const FeedbackDetailList = styled.div`
  background-color: white;
  ${(props) =>
    props.isFromNotification
      ? css`
          max-height: 87vh;
        `
      : css`
          max-height: 80vh;
        `}
  border-radius: 20px;
  border: ${(props) => props.selected && `3px solid var(--primary-color);`};
  margin-right: 20px;
  width: 100%;
  height: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
`;

export const ScrollableFeedback = styled.div`
  overflow: auto;
  ${(props) =>
    props.isFromNotification
      ? css`
          max-height: 84vh;
        `
      : css`
          max-height: 77vh;
        `}
`;

export const UserDetail = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 4px;
  align-items: flex-start;
`;

export const OptionText = styled.span`
  margin-right: 30px;
  position: relative;
  padding-left: 20px;
  ${(props) => props.fullWidth && "display: block;"}

  &:before {
    position: absolute;
    content: "";
    top: 4px;
    left: 0;
    height: 12px;
    width: 12px;
    border-radius: 2px;
    border: 1px solid #707070;
  }

  ${(props) =>
    props.isSelected &&
    css`
      &:before {
        border-color: var(--primary-color);
        background: var(--primary-color);
      }

      &:after {
        position: absolute;
        content: "";
        left: 4px;
        top: 5px;
        width: 4px;
        height: 7px;
        border: solid white;
        border-width: 0 1px 1px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    `}
`;

export const QuestionContainer = styled.div`
  margin-bottom: 20px;
`;

export const OptionQuestion = styled.span`
  padding-left: 15px;
  position: relative;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 7px;
  display: block;

  &:before {
    position: absolute;
    content: "";
    top: 9px;
    left: 0;
    height: 7px;
    width: 7px;
    border-radius: 100%;
    background: var(--primary-color);
  }
`;

export const DetailHolder = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 50%;
`;

export const UserLogo = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
`;

export const UserInfo = styled.div`
  flex-grow: 1;
`;

export const ContactLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const FlexLineBreak = styled.div`
  flex-basis: 100%;
  width: 0px; 
  height: 5px; 
  overflow: hidden;
`;

export const Link = styled.div`
  margin-right: 15px;
  a {
    color: #707070;
  }
`;

export const UserLocation = styled.div`
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin-top: 16px;
`;

export const UserName = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
`;

export const TimeInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const FeedbackActions = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const UserDevice = styled.div`
  display: flex;

  span {
    display: flex;
    align-items: flex-end;

    margin-left: 10px;

    img {
      max-width: 36px;
      height: 24px;
    }
  }
`;
export const FeedbackLabel = styled.div`
  color: ${(props) => (props.dnc ? "white" : props.foregroundColor)};
  background-color: ${(props) =>
    props.dnc ? "#F44A27" : props.backgroundColor};
  padding: 2px 7px;
  margin-left: 10px;
`;
export const UserLocationInfo = styled.div`
  margin-right: 20px;
`;

export const IconHolder = styled.div`
  margin-right: 5px;
  display: inline-block;
`;

export const DeviceIconHolder = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const UserDetailBlock = styled.div`
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #b2b2b2;
`;

export const FeedbackHolder = styled.div`
  padding: ${(props) => (props.selected ? `11px 20px 20px 20px` : `20px`)};
`;

export const TimeHolder = styled.div`
  display: block;
  margin-bottom: 10px;
`;

export const TextHolder = styled.div`
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 8px;
`;

export const TextTitle = styled.span`
  display: block;
  color: var(--primary-color);
  margin-bottom: 7px;
`;

export const Pagination = styled.div`
  text-align: right;
  margin: -14px 0 0 0;
  padding: 10px 10px 10px 0;
  background: #fff;
  ${(props) => props.hide && "display: bl"}
`;

export const FeedbackText = styled.span`
  display: inline-block;
  padding: 0 10px;
`;

export const PaginationNum = styled.div`
  display: inline-block;
  padding: 2px 10px;
  margin: 0 10px 0 0;
  background: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
`;

export const Button = styled.button`
  background: none;
  border: none;
`;

export const SmallSpinner = styled(Spinner)`
  transform: scale(0.5) !important;
`;

import ReactGA from "react-ga";

import { shiftFilterToString } from "components/common/ShiftFilter/ShiftFilterLabel";
import { customRouteAnalytics } from "utils/customRouteAnalyticsModule";

export const logDashboardOpened = () => {
  generateAnalyticsEvent({
    category: "Dashboard Opened",
    action: "Dashboard Opened",
    label: "Dashboard Opened",
  });
};

export const logOverallActiveTime = (activeTimeDiff) => {
  generateAnalyticsEvent({
    category: "overall time",
    action: `${activeTimeDiff}`,
    label: "overall Active time",
    value: activeTimeDiff,
    nonInteraction: null,
    transport: "beacon",
  });
};

export const logOverallPageViewTime = (page, activeTimeDiff) => {
  generateAnalyticsEvent({
    category: "page View Time",
    label: `${page}`,
    action: `${activeTimeDiff}`,
    value: activeTimeDiff,
    nonInteraction: null,
    transport: "beacon",
  });
};

export const logUserEngagement = (event_label, event_action) => {
  generateAnalyticsEvent({
    category: "User Engagement",
    label: event_label || "dashboard link clicked",
    action: event_action || "dashboard link clicked",
  });
};

export const logReportDownload = (downloadLink) => {
  generateAnalyticsEvent({
    category: "Report",
    label: "Downloaded",
    action: `${downloadLink}`,
  });
};

export const logHelpButtonClick = () => {
  generateAnalyticsEvent({
    category: "Help Button",
    label: "Help Button Click",
    action: `Help Button Clicked`,
  });
};

export const logFilterChange = (filterName, filterLabel, shouldStringify) => {
  generateAnalyticsEvent({
    category: "Filters",
    action: filterName,
    label: JSON.stringify(filterLabel) ? shouldStringify : filterLabel,
  });
};

export const logProjectFilterChange = (questionnaires, groups) => {
  generateAnalyticsEvent({
    category: "Filters",
    action: "Project filter",
    label: `${JSON.stringify(questionnaires)} - ${JSON.stringify(groups)}`,
  });
};

export const logNpsFilter = (filterName, filters) => {
  generateAnalyticsEvent({
    category: "filters",
    label: `Deep Filter: ${filterName}`,
    action: `${filterName} ${JSON.stringify(filters)}`,
  });
};

export const logSwitchProjectFromPicker = (questionnaire) => {
  generateAnalyticsEvent({
    category: "General",
    action: "Switch Project from Picker",
    label: JSON.stringify(questionnaire),
  });
};

export const logSearchFilter = (searchValue) => {
  generateAnalyticsEvent({
    category: "filters",
    label: "Search",
    action: `Search filter Changed: ${searchValue}`,
  });
};

export const logMediaViewed = (mediaAttachment) => {
  generateAnalyticsEvent({
    category: "Media Attachments",
    label: "Media viewed",
    action: `${mediaAttachment}`,
  });
};

export const logLogoutAction = (pathName) => {
  generateAnalyticsEvent({
    category: "logout",
    label: `User logout action fired`,
    action: `${pathName}`,
  });
};

export const logGoHomeAction = () => {
  generateAnalyticsEvent({
    category: "General",
    action: "Go Home",
  });
};

export const logGraphSelect = (graph, heading) => {
  generateAnalyticsEvent({
    category: "Boxes",
    action: "Graph Select",
    label: `${graph} - ${heading}`,
  });
};

export const logUnitSelect = (unit, heading) => {
  generateAnalyticsEvent({
    category: "Boxes",
    action: "Unit Select",
    label: `${unit} - ${heading}`,
  });
};

export const logCustomDateRangeFilter = (dateLabel) => {
  generateAnalyticsEvent({
    category: "Filters",
    action: "Custom Date Range",
    label: dateLabel,
  });
};

export const logDefaultDateRangeFilter = (dateLabel) => {
  generateAnalyticsEvent({
    category: "Filters",
    action: "Default Date Range",
    label: dateLabel,
  });
};

export const logShiftFilter = (startTime, endTime, days) => {
  const shiftFilterString = shiftFilterToString(startTime, endTime, days);
  const { sDays, sStartTime, sEndTime } = shiftFilterString;
  const label = `${sStartTime} - ${sEndTime} ${sDays ? " | " + sDays : ""}`;

  generateAnalyticsEvent({
    category: "Filters",
    action: "Shift Filter",
    label,
  });
};

export const logNotificationLinkClick = (source, id) => {
  generateAnalyticsEvent({
    category: "notification",
    label: "notification link clicked",
    action: `${source} ${id}`,
  });
};

export const logNotificationDropDownOpen = () => {
  generateAnalyticsEvent({
    category: "notification",
    label: "notification button click",
    action: "notification dropdown open",
  });
};

export const logNotificationAPICall = () => {
  generateAnalyticsEvent({
    category: "notification",
    label: "load more notification",
    action: "notification api call",
  });
};

export const logNotificationCenterLoad = () => {
  generateAnalyticsEvent({
    category: "notification",
    label: "notificatino center View",
    action: "notification center loaded",
  });
};

export const logSeeAllButtonClick = () => {
  generateAnalyticsEvent({
    category: "notification",
    label: "see all activity button",
    action: "see all activity button clicked",
  });
};

export const logTableSort = (columnHeading, heading) => {
  generateAnalyticsEvent({
    category: "Boxes",
    action: "Table Sort",
    label: `${columnHeading} - ${heading}`,
  });
};

export const logCommunicationModeChange = (mode) => {
  generateAnalyticsEvent({
    category: "connectbox",
    label: "communication mode changed",
    action: `${mode}`,
  });
};

export const logMessageSentToCustomer = (message, mode) => {
  generateAnalyticsEvent({
    category: "connectbox",
    label: "message sent to customer",
    action: `${message}, ${mode}`,
  });
};

export const logInvoiceButtonClick = () => {
  generateAnalyticsEvent({
    category: "invoice",
    label: "invoice detail list view",
    action: `invoice button clicked`,
  });
};

export const logInvoiceListView = (source) => {
  generateAnalyticsEvent({
    category: "invoice",
    label: "list view",
    action: source,
  });
};

export const logInvoiceSearch = (searchValue) => {
  generateAnalyticsEvent({
    ategory: "invoice",
    label: "search",
    action: searchValue,
  });
};

export const logInvoiceLoadMore = (url) => {
  generateAnalyticsEvent({
    category: "invoice",
    label: "load more",
    action: url,
  });
};

export const logInvoiceModalClose = () => {
  generateAnalyticsEvent({
    category: "invoice",
    label: "close",
    action: "modal closed",
  });
};

export const logInvoiceDetailView = (invoiceId, invoiceNumber) => {
  generateAnalyticsEvent({
    category: "invoice",
    label: "detail view",
    action: `id: ${invoiceId}, invoice no: ${invoiceNumber}`,
  });
};

export const logFeedbackComments = (comment) => {
  generateAnalyticsEvent({
    category: "Feedback Remarks",
    label: "Feedback Comments",
    action: comment,
  });
};

export const logFeedbackAction = (action) => {
  generateAnalyticsEvent({
    category: "Feedback Remarks",
    label: "Feedback Action",
    action: action,
  });
};

export const logMediaPreviewModeOpen = (attachment) => {
  generateAnalyticsEvent({
    category: "Media Attachments",
    label: "Media Preview mode opened",
    action: `${attachment}`,
  });
};

export const logMediaPreviewModeClose = () => {
  generateAnalyticsEvent({
    category: "Media Attachments",
    label: "Media Preview mode closed",
    action: "Media Preview mode closed",
  });
};

export const logSelectedConcernFilterChange = (concernId) => {
  generateAnalyticsEvent({
    category: "filters",
    label: "Selected Concern",
    action: `Selected Concern Filter Changed: ${concernId}`,
  });
};

export const logFeedbackLevelFilterChange = (feedbackLevel) => {
  generateAnalyticsEvent({
    category: "filters",
    label: "Feedback Level",
    action: `Feedback Level Filter Changed: ${feedbackLevel}`,
  });
};

export const logPageSwitch = (previousRack, currentRack) => {
  if (previousRack && customRouteAnalytics.isRouteExist(previousRack.route)) {
    const totalPageTimeDelta = customRouteAnalytics.getPageTimeDelta(
      previousRack.route,
    );

    // dispatch an event with total time spend on page on google analytics
    generateAnalyticsEvent({
      category: "Usage time",
      action: "Page Switch",
      label: `${previousRack.route}`,
      value: totalPageTimeDelta,
    });

    // reset route map when its logged on google.
    customRouteAnalytics.resetRoute(previousRack.route);
  }

  customRouteAnalytics.startTracking(currentRack.route);
};

export const logPageViewTime = (page, activeTimeDiff) => {
  generateAnalyticsEvent({
    category: "page View Time",
    label: `${page}`,
    action: `${activeTimeDiff}`,
    value: activeTimeDiff,
  });
};

export const initializeAnalytics = (environment) => {
  const environmentID = ENVIRONMENT_ID[environment];
  ReactGA.initialize(environmentID, {
    debug: environmentID === "qa",
    titleCase: false,
    send_page_view: false,
  });
};

const ENVIRONMENT_ID = {
  prod: "UA-164383136-3",
  qa: "UA-164383136-6",
  stg: "UA-164383136-4",
  dev: "UA-164383136-4",
};

export const generateAnalyticsEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
}) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  });
};

export const setAnalyticsValue = (object) => {
  ReactGA.set(object);
};

export const setAnalyticsPageView = (page) => {
  ReactGA.pageview(page);
};

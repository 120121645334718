import styled, { keyframes } from "styled-components";

const animation = keyframes`
0% {
    transform: rotate(0deg);
  }
100% {
    transform: rotate(360deg);
  }
`;

const MiniSpinner = styled.div`
  display: inline-block;
  &::after {
    content: " ";
    display: block;
    width: ${(props) => props.size || "30"}px;
    height: ${(props) => props.size || "30"}px;
    border-radius: 50%;
    border: ${(props) => props.thickness || 2}px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: ${animation} 0.8s ease-in-out infinite;
  }
`;

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 44%;
  left: 50%;
`;

export default MiniSpinner;

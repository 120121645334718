import * as constants from "./constants";

export const loadFeedbackList = () => ({
  type: constants.LOAD_FEEDBACK_LIST,
});

export const loadFeedbackListSuccess = (data) => ({
  type: constants.LOAD_FEEDBACK_LIST_SUCCESS,
  data,
});

export const loadFeedbackListError = (err) => ({
  type: constants.LOAD_FEEDBACK_LIST_ERROR,
  err,
});

export const loadMoreFeedbacks = (nextUrl) => ({
  type: constants.LOAD_MORE_FEEDBACKS,
  nextUrl,
});

export const loadMoreFeedbacksSuccess = (data) => ({
  type: constants.LOAD_MORE_FEEDBACKS_SUCCESS,
  data,
});

export const loadMoreFeedbacksError = (err) => ({
  type: constants.LOAD_MORE_FEEDBACKS_ERROR,
  err,
});

export const loadUnprocessedStats = () => ({
  type: constants.LOAD_UNPROCESSED_STATS,
});

export const loadUnprocessedStatsSuccess = (count) => ({
  type: constants.LOAD_UNPROCESSED_STATS_SUCCESS,
  payload: count,
});

export const loadUnprocessedStatsError = () => {
  return {
    type: constants.LOAD_UNPROCESSED_STATS_ERROR,
  };
};

export const loadSelectedFeedback = (uuid) => ({
  type: constants.LOAD_SELECTED_FEEDBACK,
  uuid,
});

export const loadChatMessages = (feedbackId) => ({
  type: constants.LOAD_CHAT_MESSAGES,
  feedbackId,
});

export const loadSelectedFeedbackSuccess = (data) => ({
  type: constants.LOAD_SELECTED_FEEDBACK_SUCCESS,
  data,
});

export const loadSelectedFeedbackError = (err) => ({
  type: constants.LOAD_SELECTED_FEEDBACK_ERROR,
  err,
});

export const loadMoreSelectedFeedback = (feedbackId, noFilters) => ({
  type: constants.LOAD_MORE_SELECTED_FEEDBACK,
  feedbackId,
  noFilters,
});

export const loadNextPageSelectedFeedback = (url) => ({
  type: constants.LOAD_NEXT_PAGE_SELECTED_FEEDBACK,
  url,
});

export const loadMoreSelectedFeedbackSuccess = (data) => ({
  type: constants.LOAD_MORE_SELECTED_FEEDBACK_SUCCESS,
  data,
});

export const loadMoreSelectedFeedbackError = (err) => ({
  type: constants.LOAD_MORE_SELECTED_FEEDBACK_ERROR,
  err,
});

export const removeSelectedFeedback = () => ({
  type: constants.REMOVE_SELECTED_FEEDBACK,
});

export const loadRemarks = (feedbackId) => ({
  type: constants.LOAD_REMARKS,
  feedbackId,
});

export const loadRemarksSuccess = (data) => ({
  type: constants.LOAD_REMARKS_SUCCESS,
  data,
});

export const loadRemarksError = (err) => ({
  type: constants.LOAD_REMARKS_ERROR,
  err,
});

export const loadChatMessagesSuccess = (chatMessages, feedbackId) => ({
  type: constants.LOAD_CHAT_MESSAGES_SUCCESS,
  chatMessages,
  feedbackId,
});

export const loadChatMessagesError = (err) => ({
  type: constants.LOAD_CHAT_MESSAGES_ERROR,
  err,
});

export const submitRemarks = (payload) => ({
  type: constants.SUBMIT_REMARKS,
  payload,
});

export const submitRemarksSuccess = (payload) => ({
  type: constants.SUBMIT_REMARKS_SUCCESS,
  payload,
});

export const submitRemarksError = (payload) => ({
  type: constants.SUBMIT_REMARKS_ERROR,
  payload,
});

export const postChatMessage = (payload) => ({
  type: constants.POST_CHAT_MESSAGE,
  payload,
});

export const postChatMessageSuccess = (payload) => ({
  type: constants.POST_CHAT_MESSAGE_SUCCESS,
  payload,
});

export const postChatMessageError = (payload) => ({
  type: constants.POST_CHAT_MESSAGE_ERROR,
  payload,
});

export const clearSelectedFeedback = () => ({
  type: constants.CLEAR_SELECTED_FEEDBACK,
});

export const toggleFeedbackTag = (feedback, tag, remove) => ({
  type: constants.TOGGLE_FEEDBACK_TAG,
  payload: { feedback, tag, remove },
});

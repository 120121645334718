import React from "react";
import ReactTable from "./ReactTable";

const Table = (props) => {
  const { headers, data } = props.data;
  const columns = React.useMemo(() => {
    const cols = headers.map((header) => {
      return { Header: header, accessor: header };
    });
    cols[0].sticky = "left";
    return cols;
  }, [headers, data]);

  const _data = React.useMemo(() => {
    return data.map((values) => {
      const row = {};
      headers.forEach((header, i) => {
        let value = values[i] ?? "--";
        if (typeof value === "number") {
          value = parseFloat(value);
        }
        row[header] = value;
      });
      return row;
    });
  }, [headers, data]);

  return (
    <ReactTable
      data={_data}
      columns={columns}
      heading={props.heading}
      analyzer={props.analyzer}
      nextURL={props.data.nextURL}
    />
  );
};

export default Table;

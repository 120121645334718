import React, { useEffect } from "react";
import { parse, format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

import {
  selectRCAProject,
  startNewProject,
  loadRCAProjectList,
  updateProjectListPage,
} from "components/RCA/actions";
import {
  projectListSelector,
  questionnaireIDSelector,
  rcaTemplateIdSelector,
  rcaProjectListCurrentPageSelector,
  rcaTotalProjectsCountSelector,
  selectUser,
} from "components/RCA/selectors";
import {
  Wrapper,
  NewProjectButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Title,
  Status,
  RCANotConfigured,
} from "./styled";

import Spinner, { SpinnerContainer } from "components/common/MiniSpinner";

import { RCAProjectStatus, QUERY_PARAM_DELIMITER } from "constants/constants";

const ProjectList = () => {
  const dispatch = useDispatch();

  const currPage = useSelector(rcaProjectListCurrentPageSelector);
  const totalProjects = useSelector(rcaTotalProjectsCountSelector);
  const projectList = useSelector(projectListSelector);

  const questionnaireID = useSelector(questionnaireIDSelector);

  const rcaTemplateId = useSelector(rcaTemplateIdSelector);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(loadRCAProjectList());
  }, [questionnaireID, currPage]);

  if (projectList.loading || !projectList.data) {
    return (
      <SpinnerContainer className="tset">
        <Spinner size="55" />
      </SpinnerContainer>
    );
  }

  if (!rcaTemplateId) {
    return (
      <RCANotConfigured>
        Root Cause Analysis is not configured for this survey
      </RCANotConfigured>
    );
  }

  return (
    <Wrapper>
      {user?.permissions.can_edit_rca_project && (
        <NewProjectButton onClick={() => dispatch(startNewProject())}>
          Start a new project
        </NewProjectButton>
      )}
      <ProjectTable />
      <ProjectPagination totalProjects={totalProjects} currPage={currPage} />
    </Wrapper>
  );
};

const ProjectTable = () => {
  const dispatch = useDispatch();
  const highLightedProjectID =
    window.location.search?.split(QUERY_PARAM_DELIMITER)[1]?.split("=")[1] ||
    null;

  const projectList = useSelector(projectListSelector);

  if (!projectList.data.length) {
    return (
      <RCANotConfigured empty>
        No projects have been started yet
      </RCANotConfigured>
    );
  }

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Project</Th>
            <Th>Problem</Th>
            <Th>Location</Th>
            <Th>Project Owner</Th>
            <Th>Start Date</Th>
            <Th>Status</Th>
            <Th>Benchmark State</Th>
            <Th>Current State</Th>
            <Th>Desired State</Th>
            <Th>Delta</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projectList.data.map((project) => {
            let color =
              project.status?.status == RCAProjectStatus.DEPLOYMENT_DENIED
                ? "#ff5349"
                : project.status?.status == RCAProjectStatus.PROGRESS
                ? "#fee73d"
                : project.status?.status == RCAProjectStatus.DEPLOYMENT_PENDING
                ? "#fdb138"
                : "#a8cf5f";

            const location =
              project.location
                ?.flatMap((d) => d.titles)
                .reduce((t, d) => t + ", " + d, "")
                .slice(2) || "";
            return (
              <Tr
                key={project.id}
                isHighlighted={project.id == highLightedProjectID}
                onClick={() => dispatch(selectRCAProject(project.id))}
              >
                <Title>{project.project_name || "--"}</Title>
                <Td>{project.problem || "--"}</Td>
                <Td>{location || "--"}</Td>
                <Td>{project.owner_name || "--"}</Td>
                <Td>
                  {format(
                    parse(project.project_start_date, "yyyy-MM-dd", new Date()),
                    "MMMM dd, yyyy",
                  ) || "--"}
                </Td>
                <Status color={color}>{project.status.message || "--"}</Status>
                <Td>{formatNumber(project.concern_benchmark_state)}</Td>
                <Td>{formatNumber(project.concern_current_state)}</Td>
                <Td>{formatNumber(project.concern_desired_state)}</Td>
                {formatCell(project.delta_concern_state?.toFixed(2)) || "--"}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </div>
  );
};

const ProjectPagination = ({ totalProjects, currPage }) => {
  const dispatch = useDispatch();
  let totalPages = 1;
  const pageSize = 8;
  if (totalProjects) {
    totalPages = Math.ceil(totalProjects / pageSize);
  }

  const changePage = (page) => {
    dispatch(updateProjectListPage(page.selected + 1));
  };

  return (
    <ReactPaginate
      previousLabel="<"
      nextLabel=">"
      breakLabel="..."
      breakClassName="break-me"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={changePage}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
      initialPage={currPage - 1}
    />
  );
};

const formatNumber = (number) => {
  if (!number) {
    return "--";
  }
  return parseFloat(number).toFixed(2) + "%";
};

const formatCell = (cell) => {
  let value = parseFloat(cell);
  let style = "DEFAULT";
  value = cell ?? "--";

  if (cell > 0) {
    style = "POSITIVE";
  } else if (cell < 0) {
    style = "NEGATIVE";
    value = Math.abs(cell);
  }
  const [color, arrow] = cellStyles[style];
  return (
    <Td color={color}>
      {arrow} {value}
    </Td>
  );
};

const cellStyles = {
  POSITIVE: ["red", "▲"],
  NEGATIVE: ["green", "▼"],
  DEFAULT: [null, null],
};

export default ProjectList;

export default [
  { label: "12:00AM", index: 0 },
  { label: "12:15AM", index: 1 },
  { label: "12:30AM", index: 2 },
  { label: "12:45AM", index: 3 },
  { label: "01:00AM", index: 4 },
  { label: "01:15AM", index: 5 },
  { label: "01:30AM", index: 6 },
  { label: "01:45AM", index: 7 },
  { label: "02:00AM", index: 8 },
  { label: "02:15AM", index: 9 },
  { label: "02:30AM", index: 10 },
  { label: "02:45AM", index: 11 },
  { label: "03:00AM", index: 12 },
  { label: "03:15AM", index: 13 },
  { label: "03:30AM", index: 14 },
  { label: "03:45AM", index: 15 },
  { label: "04:00AM", index: 16 },
  { label: "04:15AM", index: 17 },
  { label: "04:30AM", index: 18 },
  { label: "04:45AM", index: 19 },
  { label: "05:00AM", index: 20 },
  { label: "05:15AM", index: 21 },
  { label: "05:30AM", index: 22 },
  { label: "05:45AM", index: 23 },
  { label: "06:00AM", index: 24 },
  { label: "06:15AM", index: 25 },
  { label: "06:30AM", index: 26 },
  { label: "06:45AM", index: 27 },
  { label: "07:00AM", index: 28 },
  { label: "07:15AM", index: 29 },
  { label: "07:30AM", index: 30 },
  { label: "07:45AM", index: 31 },
  { label: "08:00AM", index: 32 },
  { label: "08:15AM", index: 33 },
  { label: "08:30AM", index: 34 },
  { label: "08:45AM", index: 35 },
  { label: "09:00AM", index: 36 },
  { label: "09:15AM", index: 37 },
  { label: "09:30AM", index: 38 },
  { label: "09:45AM", index: 39 },
  { label: "10:00AM", index: 40 },
  { label: "10:15AM", index: 41 },
  { label: "10:30AM", index: 42 },
  { label: "10:45AM", index: 43 },
  { label: "11:00AM", index: 44 },
  { label: "11:15AM", index: 45 },
  { label: "11:30AM", index: 46 },
  { label: "11:45AM", index: 47 },
  { label: "12:00PM", index: 48 },
  { label: "12:15PM", index: 49 },
  { label: "12:30PM", index: 50 },
  { label: "12:45PM", index: 51 },
  { label: "01:00PM", index: 52 },
  { label: "01:15PM", index: 53 },
  { label: "01:30PM", index: 54 },
  { label: "01:45PM", index: 55 },
  { label: "02:00PM", index: 56 },
  { label: "02:15PM", index: 57 },
  { label: "02:30PM", index: 58 },
  { label: "02:45PM", index: 59 },
  { label: "03:00PM", index: 60 },
  { label: "03:15PM", index: 61 },
  { label: "03:30PM", index: 62 },
  { label: "03:45PM", index: 63 },
  { label: "04:00PM", index: 64 },
  { label: "04:15PM", index: 65 },
  { label: "04:30PM", index: 66 },
  { label: "04:45PM", index: 67 },
  { label: "05:00PM", index: 68 },
  { label: "05:15PM", index: 69 },
  { label: "05:30PM", index: 70 },
  { label: "05:45PM", index: 71 },
  { label: "06:00PM", index: 72 },
  { label: "06:15PM", index: 73 },
  { label: "06:30PM", index: 74 },
  { label: "06:45PM", index: 75 },
  { label: "07:00PM", index: 76 },
  { label: "07:15PM", index: 77 },
  { label: "07:30PM", index: 78 },
  { label: "07:45PM", index: 79 },
  { label: "08:00PM", index: 80 },
  { label: "08:15PM", index: 81 },
  { label: "08:30PM", index: 82 },
  { label: "08:45PM", index: 83 },
  { label: "09:00PM", index: 84 },
  { label: "09:15PM", index: 85 },
  { label: "09:30PM", index: 86 },
  { label: "09:45PM", index: 87 },
  { label: "10:00PM", index: 88 },
  { label: "10:15PM", index: 89 },
  { label: "10:30PM", index: 90 },
  { label: "10:45PM", index: 91 },
  { label: "11:00PM", index: 92 },
  { label: "11:15PM", index: 93 },
  { label: "11:30PM", index: 94 },
  { label: "11:45PM", index: 95 },
];

import React from "react";
import { Col } from "react-bootstrap";
import * as analytics from "utils/analytics";

export const AlertMessage = ({ analytics_event }) => {
  return (
    <Col md={4} className="options alert-message">
      <a
        href={analytics_event.url}
        onClick={() =>
          analytics.logUserEngagement(
            analytics_event.ga_event_label,
            analytics_event.ga_event_action,
          )
        }
        target="_blank"
        rel="noreferrer"
      >
        <span className="icon">
          <i className="fa fa-link"></i>
        </span>
        <span className="text">{analytics_event.text}</span>
      </a>
    </Col>
  );
};

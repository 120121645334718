import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FeedbackTransactionAmount } from "components/FeedbackPage/SelectedFeedback/FeedbackTransactionAmount";
import { isCustomerView } from "components/FeedbackPage/selectors";
import { pickScoreView } from "components/FeedbackPage/ScoreView";
import { FeedbackMedia } from "./FeedbackMedia";
import * as utils from "utils/genericHelper";
import * as Styled from "./styled";
import FeedbackTags from "components/FeedbackPage/FeedbackTags/FeedbackTags";
import { getFeedbackActionFilterLabel } from "components/selectors";
import { TooltipText } from "components/common/Tooltip";
import { FeedbackActionFilter } from "constants/constants";
import { getFeedbackActionAttributes } from "utils/feedbackActionLabel";

import desktop from "assets/svg/desktop.svg";
import smartphone from "assets/svg/smartphone.svg";

import macOS from "assets/svg/macOS.svg";
import android from "assets/svg/android.svg";
import windows from "assets/svg/windows.svg";
import linux from "assets/svg/linux.svg";
import { getFormatedDate } from "utils/dateUtils";

const DEVICE_MAPPING = {
  Phone: smartphone,
  Desktop: desktop,
};

const OS_ICON_MAPPINGS = {
  Android: android,
  MacOS: macOS,
  IPhone: macOS,
  Windows: windows,
  Linux: linux,
};

const SelectedFeedback = (props) => {
  const feedback = props.selectedFeedback;
  const selected = props.selected;
  const questions = _getQuestions(feedback);
  const customerView = isCustomerView();
  return (
    <Styled.FeedbackHolder selected={selected}>
      <FeedbackDetails feedback={feedback} />
      {!customerView && <FeedbackTransactionAmount feedback={feedback} />}
      {questions.map((question) => {
        return <FeedbackQuestion key={question.id} question={question} />;
      })}
    </Styled.FeedbackHolder>
  );
};

const FeedbackDetails = ({ feedback }) => {
  const ScoreView = pickScoreView(feedback);
  const permissions = useSelector((state) => state.auth.user.permissions);
  const FeedbackActionFilterLabel = useSelector(getFeedbackActionFilterLabel);

  let feebackActionAttributes =
    feedback.feedback_action &&
    getFeedbackActionAttributes(
      feedback,
      FeedbackActionFilterLabel,
      feedback.feedback_action.type,
    );
  const { primary, feedback_division, invite_division } = feedback.division;
  const { t } = useTranslation();
  const {
    unique_id: companyID,
    name: companyName,
    manager
  } = feedback.customer?.company ?? {};
  const { code: managerCode } = manager ?? {};

  return (
    <Styled.UserDetailBlock>
      <Styled.UserDetail>
        <Styled.DetailHolder>
          <Styled.UserLogo>
            {ScoreView && (
              <ScoreView
                scoreLabel={feedback.score_label}
                score={feedback.score}
              />
            )}
          </Styled.UserLogo>
          <Styled.UserInfo>
            <Styled.UserName>
              {feedback.respondent_info?.name || "--"}
            </Styled.UserName>
            <Styled.ContactLinks>
              <Styled.Link>
                <a
                  href={utils.getPhoneNumberLink(
                    feedback.respondent_info?.cell_phone,
                  )}
                  onClick={e => !feedback.respondent_info?.cell_phone && e.preventDefault()}
                >
                  <Styled.IconHolder>
                    <i className="fa fa-phone" />
                  </Styled.IconHolder>
                  {feedback.respondent_info?.cell_phone || "--"}
                </a>
              </Styled.Link>
              <Styled.Link>
                <a 
                  href={utils.getEmailLink(feedback.respondent_info?.email)}
                  onClick={e => !feedback.respondent_info?.email && e.preventDefault()}
                >
                  <Styled.IconHolder>
                    <i className="fa fa-envelope" />
                  </Styled.IconHolder>
                  {feedback.respondent_info?.email || "--"}
                </a>
              </Styled.Link>
              <Styled.FlexLineBreak />
              {companyID && <Styled.Link>
                <a href="#" onClick={e => e.preventDefault()}>
                  <Styled.IconHolder>
                    <i className="fa fa-id-badge" />
                  </Styled.IconHolder>
                  {companyID || "--"}
                </a>
              </Styled.Link>}
              {managerCode && <Styled.Link>
                <a href="#" onClick={e => e.preventDefault()}>
                  <Styled.IconHolder>
                    <i className="fa fa-user-md" />
                  </Styled.IconHolder>
                  {managerCode || "--"}
                </a>
              </Styled.Link>}
              {companyName && <Styled.Link>
                <a href="#" onClick={e => e.preventDefault()}>
                  <Styled.IconHolder>
                    <i className="fa fa-briefcase" />
                  </Styled.IconHolder>
                  {companyName || "--"}
                </a>
              </Styled.Link>}
            </Styled.ContactLinks>
          </Styled.UserInfo>
        </Styled.DetailHolder>
        <Styled.TimeInfo>
          <Styled.TimeHolder>
            {getFormatedDate(new Date(feedback.created_at))}
          </Styled.TimeHolder>
          <Styled.FeedbackActions>
            {feedback.can_contact === false && (
              <Styled.FeedbackLabel dnc>
                {t(FeedbackActionFilterLabel[FeedbackActionFilter.DO_NOT_CALL])}
              </Styled.FeedbackLabel>
            )}
            {feedback.feedback_action && permissions.api_action_stats && (
              <Styled.FeedbackLabel
                backgroundColor={feebackActionAttributes.backgroudColor}
                foregroundColor={feebackActionAttributes.foregroudColor}
              >
                {feebackActionAttributes.labelText}
              </Styled.FeedbackLabel>
            )}
          </Styled.FeedbackActions>
          <Styled.UserDevice>
            {feedback.device && (
              <>
                {feedback.device.type && (
                  <span className="tooltipRel">
                    <Styled.DeviceIconHolder>
                      <img
                        src={DEVICE_MAPPING[feedback.device.type]}
                        alt={feedback.device.type}
                      />
                    </Styled.DeviceIconHolder>
                    <TooltipText
                      align="bottomLeft"
                      tooltipText={feedback.device.type}
                    />
                  </span>
                )}
                {feedback.device.name && (
                  <span className="tooltipRel">
                    <Styled.IconHolder>
                      <img
                        src={OS_ICON_MAPPINGS[feedback.device.name]}
                        alt={feedback.device.name}
                      />
                    </Styled.IconHolder>
                    <TooltipText
                      align="bottomRight"
                      tooltipText={feedback.device.os}
                    />
                  </span>
                )}
              </>
            )}
            {feedback.location && <span>{feedback.location}</span>}
          </Styled.UserDevice>
        </Styled.TimeInfo>
      </Styled.UserDetail>
      <Styled.UserLocation>
        {primary && (
          <Styled.UserLocationInfo>
            <Styled.IconHolder>
              <i className="fa fa-map-marker" />
            </Styled.IconHolder>
            {primary.title}
          </Styled.UserLocationInfo>
        )}
        {invite_division && (
          <Styled.UserLocationInfo>
            <Styled.IconHolder>
              <i className="fa fa-location-arrow" />
            </Styled.IconHolder>
            {invite_division.title}
          </Styled.UserLocationInfo>
        )}
        {feedback_division && (
          <Styled.UserLocationInfo>
            <Styled.IconHolder>
              <i className="fa fa-cube" />
            </Styled.IconHolder>
            {feedback_division.title}
          </Styled.UserLocationInfo>
        )}
        {feedback.taker && (
          <Styled.UserLocationInfo>
            <Styled.IconHolder>
              <i className="fa fa-user" />
            </Styled.IconHolder>
            {feedback.taker.name}
          </Styled.UserLocationInfo>
        )}
        <FeedbackTags feedback={feedback} />
      </Styled.UserLocation>
    </Styled.UserDetailBlock>
  );
};

const FeedbackQuestion = ({ question }) => {
  const text = question.text_short || question.text;
  const Option = question.isDrilledDown ? FeedbackSubOptions : FeedbackOption;

  const permissions = useSelector((state) => state.auth.user.permissions);

  const options = question.isDrilledDown
    ? question.options.flatMap((o) => o.sub_options)
    : question.options;

  const isOpedEnded = options.length === 1 && options[0].other_text;
  const singleSelection = options.filter((o) => o.isSelected).length == 1;
  const fullWidth = options.reduce((t, o) => t + 5 + o.text.length, 0) > 120;

  return (
    <Styled.QuestionContainer>
      <Styled.OptionQuestion>{utils.boldText(text)}</Styled.OptionQuestion>
      {!isOpedEnded &&
        question.options
          .filter((o) => permissions.can_view_available_options || o.isSelected)
          .map((option) => {
            return (
              <Option key={option.id} fullWidth={fullWidth} option={option} />
            );
          })}
      {options
        .filter((option) => option.other_text || option.attachments.length)
        .map((option) => {
          return (
            <Styled.TextHolder key={option.id}>
              {!singleSelection && (
                <Styled.TextTitle>{option.text}</Styled.TextTitle>
              )}
              {option.other_text}
              {option.attachments.length > 0 && (
                <FeedbackMedia attachments={option.attachments} />
              )}
            </Styled.TextHolder>
          );
        })}
    </Styled.QuestionContainer>
  );
};

const FeedbackOption = ({ option, fullWidth }) => {
  return (
    <>
      <Styled.OptionText fullWidth={fullWidth} isSelected={option.isSelected}>
        {option.text}
      </Styled.OptionText>
    </>
  );
};

const FeedbackSubOptions = ({ option, fullWidth }) => {
  const permissions = useSelector((state) => state.auth.user.permissions);

  return (
    <div>
      <Styled.TextTitle>{option.text}</Styled.TextTitle>
      {option.sub_options
        .filter((so) => permissions.can_view_available_options || so.isSelected)
        .map((subOption) => {
          return (
            <FeedbackOption
              key={option.id}
              option={subOption}
              fullWidth={fullWidth}
            />
          );
        })}
    </div>
  );
};

export default SelectedFeedback;

const _getQuestions = (feedback) =>
  feedback.detail
    .map(_mergeQuestionFeedbackOptions)
    .filter(_filterUnansweredQuestions)
    .flatMap(_createDrilledDownQuestion)
    .filter(_filterDrilledDownQuestionSubOption);

const _mergeQuestionFeedbackOptions = (question) => {
  const { available_options, ..._question } = question;
  const options = available_options.map((option) => {
    const feedbackOption = question.options.find(
      (feedbackOption) => feedbackOption.option.id === option.id,
    );

    const sub_options = option.sub_options.map((subOption) => {
      const feedbackSubOption = feedbackOption?.option.sub_options.find(
        (feedbackSubOption) => feedbackSubOption.option.id === subOption.id,
      );
      return {
        ...subOption,
        isSelected: !!feedbackSubOption?.id,
        feedbackOptionId: feedbackSubOption?.id || null,
        other_text: feedbackSubOption?.other_text || "",
        attachments: feedbackSubOption?.attachments || [],
      };
    });

    return {
      ...option,
      sub_options,
      isSelected: !!feedbackOption?.id,
      feedbackOptionId: feedbackOption?.id || null,
      other_text: feedbackOption?.other_text || "",
      attachments: feedbackOption?.attachments || [],
    };
  });
  return { ..._question, options };
};

const _createDrilledDownQuestion = (question) => [
  { ...question },
  {
    ...question,
    isDrilledDown: true,
    id: question.id + "/dd",
    options: question.options.filter((o) =>
      o.sub_options.some((so) => so.isSelected),
    ),
  },
];

const _filterDrilledDownQuestionSubOption = (question) =>
  !question.isDrilledDown || question.options.some((o) => o.sub_options.length);

const _filterUnansweredQuestions = (question) =>
  question.options.some((o) => o.isSelected);

import React from "react";
import { Table } from "react-bootstrap";
import { format } from "date-fns";
import { isTruthy } from "../../../utils/genericHelper";
import { LoadMore } from "../../common/LoadMore";
import CustomScroll from "react-custom-scroll";
import { HighLightText } from "../../common/HighLightText";
import numeral from "numeral";
import { getTotalItemsSold } from "./selectors";

export const InvoiceList = ({
  displayInvoice,
  invoices,
  primaryColor,
  loadMoreInvoices,
  searchValue,
  close,
  selectedFilters,
}) => {
  return (
    <CustomScroll heightRelativeToParent="350px">
      <Table className="invoice-table">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Transaction Timestamp</th>
            <th>Total Amount</th>
            <th>Total Quantity Sold</th>
            <th>Score</th>
            <th>Payment Method</th>
            <th>Feedback Status</th>
          </tr>
        </thead>
        <tbody>
          {isTruthy(invoices.apiData.results) ? (
            invoices.apiData.results.map((invoice) => {
              return (
                <tr
                  className="table-row"
                  onClick={(e) => displayInvoice(e, invoice)}
                  key={`invoice_list_${invoice.id}`}
                >
                  <td>
                    <HighLightText
                      value={invoice.invoice_no}
                      match={searchValue}
                    />
                  </td>
                  <td>
                    {format(
                      new Date(invoice.generated_at),
                      "d MMMM yyyy, h:mm aaa",
                    )}
                  </td>
                  <td>{numeral(invoice.total_price).format("0,0")}</td>
                  <td>{getTotalItemsSold(invoice)}</td>
                  <td>{invoice.score || "--"}</td>
                  <td>{invoice.payment_method || "--"}</td>
                  <td>
                    {invoice.feedback_url_uuid ? (
                      <a
                        href={`/feedback/${invoice.feedback_url_uuid.slice(
                          0,
                          -2,
                        )}?feedback_group=${selectedFilters.feedbackGroup}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {invoice.status}
                      </a>
                    ) : (
                      invoice.status
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="not-found" key="invoice_list_no_found">
              <td colSpan="7">No Invoice Found</td>
            </tr>
          )}
          <tr className="invoice-loadmore" key="invoice_list_load_more">
            <td colSpan="7">
              <LoadMore
                isLoading={invoices.isMoreFeedbackPending}
                nextUrl={invoices.apiData.next}
                onClickHandler={loadMoreInvoices}
                primaryColor={primaryColor}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </CustomScroll>
  );
};

import { getRequest } from "../../../utils/request";

export const getConcernListApi = (organizationId, filtersQuery) => {
  let url = `/v1/organizations/${organizationId}/insights/concerns/?${filtersQuery}`;

  return getRequest(url);
};

export const ConcernDetailApi = (ConcernId, organizationId, filtersQuery) => {
  let url = `/v1/organizations/${organizationId}/insights/concerns/${ConcernId}/?${filtersQuery}`;

  return getRequest(url);
};

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  Col,
  Button,
  FormControl,
  FormGroup,
  Form,
  Modal,
  Alert,
} from "react-bootstrap";
import { connect } from "react-redux";
import CSSTransitionGroup from "react-transition-group/CSSTransition";

import { updatePassword } from "./actions";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        password: null,
        new_password: null,
        confirm_password: null,
      },
      error: null,
    };

    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
  }

  onValueChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitHandler = (e) => {
    const { updatePassword } = this.props;
    e.preventDefault();

    updatePassword(this.state.data).then((error) => {
      this.setState({ error });
    });
  };

  render() {
    const { show, hide } = this.props;
    const { error, data } = this.state;
    const { t } = this.props;

    const isValid =
      !data.password ||
      !data.new_password ||
      !data.confirm_password ||
      data.new_password !== data.confirm_password;
    const validateConfirmPassword = data.confirm_password
      ? data.new_password === data.confirm_password
        ? "success"
        : "error"
      : null;

    return (
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("changePassword")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: "10px 15px",
              margin: "-16px -15px 15px",
              borderStyle: "solid",
              borderWidth: "1px 0",
              color: "#735c0f",
              backgroundColor: "#ffea7f",
              borderColor: "rgba(176,136,0,0.3)",
            }}
          >
            <span>{t("passwordDesp")}</span>
          </div>

          <CSSTransitionGroup
            classNames="animated"
            appear={true}
            timeout={{
              appear: 500,
              enter: 300,
              exit: 300,
            }}
            enter={false}
            exit={false}
          >
            <div>
              {error ? (
                <Alert size="sm" variant="danger">
                  <p>{t(error)}</p>
                </Alert>
              ) : null}
            </div>
          </CSSTransitionGroup>

          <Form
            onSubmit={this.onSubmitHandler}
            horizontal
            style={{ marginTop: "25px" }}
          >
            <FormGroup>
              <Col md={{ span: 6, offset: 3 }}>
                <FormControl
                  type="password"
                  name="password"
                  placeholder={t("currentPassword")}
                  onChange={this.onValueChange}
                />
              </Col>
            </FormGroup>

            <FormGroup>
              <Col md={{ span: 6, offset: 3 }}>
                <FormControl
                  type="password"
                  name="new_password"
                  placeholder={t("newPassword")}
                  onChange={this.onValueChange}
                />
              </Col>
            </FormGroup>

            <FormGroup validationState={validateConfirmPassword}>
              <Col md={{ span: 6, offset: 3 }}>
                <FormControl
                  type="password"
                  name="confirm_password"
                  placeholder={t("confirmPassword")}
                  onChange={this.onValueChange}
                />
                <FormControl.Feedback />
              </Col>
            </FormGroup>

            <Modal.Footer>
              <Button disabled={isValid} type="submit" variant="success">
                {t("update")}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  updatePassword,
};

export default connect(
  null,
  mapDispatchToProps,
)(withTranslation()(ChangePassword));

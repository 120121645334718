import React, { Component } from "react";
import * as analytics from "utils/analytics";
import { telemetry } from "./telemetry";

const UnAuthPages = ["/login", "/forgot-password"];

export default function WithTracker(
  WrappedComponent,
  props = {},
  options = {},
) {
  const trackPage = (page) => {
    analytics.setAnalyticsValue({
      page,
      ...options,
    });
    analytics.setAnalyticsPageView(page);
  };

  const HOC = class Wrapper extends Component {
    componentDidMount() {
      let page = window.location.pathname;
      trackPage(page);
      telemetry.setPageStartTime(page);
    }

    componentWillUnmount() {
      const pageAnalytics = telemetry.calculatePageTimeDelta();
      let page = window.location.pathname;
      analytics.logPageViewTime(page, pageAnalytics.activeTimeDiff);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
}

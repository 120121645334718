import React, { Component } from "react";
import { Table } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import CustomScroll from "react-custom-scroll";
import Spinner from "react-spinkit";

class PriorityListStats extends Component {
  render() {
    const { analysis, fetching, error } = this.props;
    if (fetching || error)
      return <Spinner name="wandering-cubes" fadeIn="none" />;

    return (
      <div className="priority-list">
        {Array.isArray(analysis) ? (
          <CustomScroll heightRelativeToParent="100%">
            <Table responsive hover>
              <tbody>
                {analysis.map((priority, i) => {
                  return (
                    <tr key={i} style={{ padding: 9 }}>
                      <td>
                        {priority.name} <br />
                        <span className="parent-option">
                          {priority.parent_name}
                        </span>
                      </td>
                      <td className="priority-value">
                        + {priority.priority_value}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CustomScroll>
        ) : (
          <div className="error-message">
            <i className="fa fa-warning" />
            {analysis.detail}
          </div>
        )}
      </div>
    );
  }
}

export default PriorityListStats;

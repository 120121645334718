import React from "react";
import { useTranslation } from "react-i18next";

export const NoDataFound = ({ message = "No Data Found" }) => {
  const { t } = useTranslation();
  let messageWithTranslation = "";

  if (message === "No Data Found") {
    messageWithTranslation = t("noDataFound");
  } else {
    messageWithTranslation = message;
  }

  return <div className="no-data-found">{messageWithTranslation}</div>;
};

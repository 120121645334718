import styled from "styled-components";
import {
  displayFlex,
  flexAlign,
  flexJustify,
  flexDirection,
} from "../../../ui/utils";

export const FilterDropdownItem = styled.button`
  display: block;
  width: 100%;
  padding: 0.375em 1em;
  clear: both;
  font-weight: 400;
  color: ${(props) => (props.selected ? "#fff" : "#707070")};
  text-align: inherit;
  background-color: ${(props) =>
    props.selected ? "var(--primary-color)" : "transparent"};
  border: 0;

  &:hover,
  &.selected {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const FilterDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  margin-top: -5px;
  left: 0;
  right: auto;
  min-width: auto;
  max-width: 160px;
  max-height: 308px;
  z-index: 10;
  display: ${(props) => (props.open ? "block" : "none")};
  color: #707070;
  text-align: center;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  -webkit-box-shadow: 0 -1px 8px 2px rgba(34, 36, 38, 0.15);
  box-shadow: 0 -1px 8px 2px rgba(34, 36, 38, 0.15);
  overflow-y: auto;
  overflow-x: hidden;

  &::before {
    position: absolute;
    top: -9px;
    left: 20px;
    display: inline-block;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    content: "";
  }
`;

export const FilterToolTip = styled.span`
  visibility: hidden;
  width: auto;
  min-width: 120px;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;

  &::before {
    content: "${(props) => props.tooltipText && props.tooltipText}";
  }
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--primary-color) transparent transparent transparent;
  }
`;

export const FilterItemRemove = styled.div`
  position: relative;
  background: none;
  border: none;
  width: 18px;
  height: 13px;
  line-height: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate3d(-10px, 0, 0);
  display: inline-block;
  width: 0;
  transition: all 0.3s ease-in-out;

  &::before {
    content: "x";
    position: absolute;
    font-size: 1em;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    line-height: 100%;
    font-weight: 500;
    font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  }
`;

export const FilterItem = styled.button`
  border: none;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : "rgb(112 112 112 / 0.35)"};
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  margin: 5px 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover > ${FilterItemRemove} {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0px, 0, 0);
    width: 10px;
    margin-left: 5px;
  }
`;

export const FilterList = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("center")}
  ${flexJustify("flex-start")}
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;

  &:hover > ${FilterItem}:not(:hover) {
    background-color: rgb(112 112 112 / 0.35);
  }
`;

export const FilterLabel = styled.label`
  color: #707070;
  margin: 0 10px 0 0;
  position: relative;

  &:hover ${FilterToolTip} {
    visibility: visible;
    opacity: 1;
  }

  &:hover ~ ${FilterList} > ${FilterItem} {
    background-color: rgb(112 112 112 / 0.35);
  }
`;

export const FilterType = styled.div`
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("center")}
  ${flexJustify("flex-start")}
  position: relative;
  padding: 3px 12px;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:hover {
    background-color: rgb(180 181 181 / 0.18);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  ${displayFlex()}
  ${flexDirection("row")}
  ${flexAlign("flex-start")}
  ${flexJustify("flex-start")}
  flex-wrap: wrap;
`;

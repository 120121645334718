import { createSelector } from "reselect";

export const selectNotificationsData = createSelector(
  (state) => state.notification.data,
  (notificationsData) => notificationsData,
);

export const selectNotifications = createSelector(
  selectNotificationsData,
  (data) => data.results,
);

export const selectUnreadNotifications = createSelector(
  selectNotificationsData,
  (data) => data.unread_count,
);

import * as constants from "./constants";
import * as apis from "./apis";

export const getInvoices = (customerId, inviteId, search_text, group) => {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_INVOICES });
    apis
      .getInvoicesApi({
        customer: customerId,
        invite: inviteId,
        search_text: search_text,
        group: group,
      })
      .then(
        (data) => {
          dispatch(getInvoicesSuccess(data));
        },
        (err) => {
          dispatch(getInvoicesError(err));
        },
      );
  };
};

export const getInvoicesSuccess = (data) => ({
  type: constants.LOAD_INVOICES_SUCCESS,
  data,
});

export const getInvoicesError = (err) => ({
  type: constants.LOAD_INVOICES_ERROR,
  err,
});

export const getMoreInvoices = (nextUrl) => {
  return (dispatch) => {
    dispatch({ type: constants.LOAD_MORE_INVOICES });
    apis.getMoreInvoicesApi(nextUrl).then(
      (data) => {
        dispatch(getMoreInvoicesSuccess(data));
      },
      (err) => {
        dispatch(getMoreInvoicesError(err));
      },
    );
  };
};

export const getMoreInvoicesSuccess = (data) => ({
  type: constants.LOAD_MORE_INVOICES_SUCCESS,
  data,
});

export const getMoreInvoicesError = (err) => ({
  type: constants.LOAD_MORE_INVOICES_ERROR,
  err,
});

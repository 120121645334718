import {
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest,
} from "utils/request";

export const RCAProjectListApi = (questionnaireID, currPage) =>
  getRequest(
    `rca/v1/rca_record/list?questionnaire=${questionnaireID}&page=${currPage}`,
  );

export const RCAProjectApi = (templateId) =>
  getRequest(`/api/rca/v1/rca_template/${templateId}/detail/`);

export const RCAProjectDataApi = (id) =>
  getRequest(`rca/v1/rca_record/${id}/detail/`);

export const saveRCAFormApi = (id, data) => {
  if (id && id != "null") {
    return patchRequest(`rca/v1/rca_record/create_update/${id}`, data);
  }
  return postRequest(`rca/v1/rca_record/create_update/`, data);
};

export const deleteRCAProjectApi = (id) => {
  return deleteRequest(`rca/v1/rca_record/${id}/delete/`);
};

export const teamLeadsApi = (organizationID) =>
  getRequest(`users/list?organization=${organizationID}&is_rca_lead=true`);

export const teamMembersApi = (rcaLead) =>
  getRequest(`v1/users/tag/list?rca_lead_user=${rcaLead}`);

export const concernApi = (
  organizationID,
  questionnaireId,
  divisionParams,
  loop,
) => {
  if (loop) {
    return getRequest(
      `v1/organizations/${organizationID}/insights/v2/concerns/?&questionnaire=${questionnaireId}&loop=${loop}&division=${divisionParams}&likes=false&rca=true`,
    );
  } else {
    return getRequest(
      `v1/organizations/${organizationID}/insights/v2/concerns/?&questionnaire=${questionnaireId}&division=${divisionParams}&likes=false&rca=true`,
    );
  }
};

export const levelsApi = (organizationID, questionnaireID, rcaTemplateId) =>
  getRequest(
    `v1/organizations/${organizationID}/divisions/levels?divisions=True&questionnaire=${questionnaireID}&rca_template=${rcaTemplateId}`,
  );

export const usersApi = (organizationID) =>
  getRequest(`users/list?organization=${organizationID}`);

export const analysisApi = (projectId, deploymentDate, questionnaireID) =>
  getRequest(
    `dashboard/v1/rca/${projectId}/analysis?deployment_date=${deploymentDate}&questionnaire=${questionnaireID}`,
  );

export const verifyLocationApi = (organizationID, location) =>
  getRequest(
    `api/organizations/${organizationID}/divisions/verify-location/?location=${location}`,
  );

import { omit } from "lodash";

export const defaultQuestionnaireState = {
  title: "",
  description: "",
  show_on_kiosk: false,
  for_go: false,
  questions: [],
  questionnaire_divisions: [],
  questionnaire_division_roles: [],
  questionnaire_roles: [],
  questionnaire_antispammers: [],
  invite_expires_after_hours: 1,
  invite_reminder_after_hours: 1,
  invite_reminder_limit: 1,
  freeze_until: null,
  colors: null,
  font_styles: null,
  font_sizes: null,
  positive_thankyou_image: null,
  neutral_thankyou_image: null,
  negative_thankyou_image: null,
  default_thankyou_image: null,
  logo: null,
  background_image: null,
};

export const defaultQuestionForm = {
  is_active: true,
  text: "",
  text_urdu: "",
  heading: "",
  heading_urdu: "",
  background_image: "",
  text_short: "",
  type: null,
  kind: null,
  app_screen_type: null,
  is_decider: false,
  is_required: false,
  show_on_kiosk: false,
  options: [],
  min_option_selection_count: null,
  min_sub_option_selection_count: null,
  max_option_selection_count: null,
  max_sub_option_selection_count: null,
  chart_type: null,
  depends_upon: [],
  question_images: [],
  is_statement_visible: true,
  is_master_question: false,
  master_question: null,
  representation_type: null,
};

export const defaultOptionForm = {
  is_active: true,
  text: "",
  text_urdu: "",
  depends_upon: [],
  image: null,
  is_other: false,
  sub_options: [],
  is_randomizable: false,
  concern: null,
  other_text_placeholder: "Please Specify",
  score: "0",
};

export const defaultSubOptionForm = {
  ...omit(defaultOptionForm, ["sub_options"]),
};

export const initialState = {
  ui: {
    tabs: {
      activeTab: "survey",
      list: [
        { id: "survey", title: "Survey" },
        { id: "theme", title: "Theme" },
        { id: "configuration", title: "Configuration" },
        { id: "preview", title: "Preview" },
      ],
    },
    isSubmitting: false,
    loading: {
      questionnaire: false,
      surveys: false,
    },
    success: {
      questionnaire: false,
    },
    error: {
      questionnaire: null,
      surveys: null,
    },
  },
  questionnaire: defaultQuestionnaireState,
  // store the selected item virtual id
  selected: {
    question: -1,
    option: -1,
    subOption: -1,
  },
  config: {
    surveyTypes: [],
    surveyAntiSpammers: [],
    roles: [],
    userRolePermissions: [],
    feedbackGroups: [],
    divisions: [],
    questionKinds: [],
    questionTypes: [],
    optionLoopTypes: [],
    optionScoreTypes: [],
    questionScreenTypes: [],
    chartTypes: [],
    surveyColors: null,
    surveyFontStyles: null,
    surveyFontSizes: null,
    supportedFontStyles: [],
    surveys: [],
  },
  surveyList: {
    surveys: [],
    submitSurvey: false,
    fetching: false,
    error: null,
    currentPage: 1,
  },
};

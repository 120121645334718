import React, { Component } from "react";
import { Col, Modal, Button } from "react-bootstrap";
import styled from "styled-components";
import { produce } from "immer";
import { InvoiceList } from "./InvoiceList";
import { InvoiceDetail } from "./InvoiceDetail";
import { InvoiceDetailList } from "./InvoiceDetailList";
import { SearchBoxWithTranslation } from "../../common/SearchBox";
import { getInvoices, getMoreInvoices } from "./actions";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import * as analytics from "utils/analytics";

class InvoiceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showInvoiceList: true,
      invoice: { items: [] },
      searchValue: "",
      isFeedbackInvoice: props.isFeedbackInvoice || false,
    };
    this.displayInvoices = this.displayInvoices.bind(this);
    this.displayInvoice = this.displayInvoice.bind(this);
    this.close = this.close.bind(this);
    this.showAllInvoices = this.showAllInvoices.bind(this);
    this.loadMoreInvoices = this.loadMoreInvoices.bind(this);
    this.searchInvoices = this.searchInvoices.bind(this);
    this.setSearchValue = this.setSearchValue.bind(this);
    this.getAllInvoicesAPI = this.getAllInvoicesAPI.bind(this);
  }

  displayInvoices(e) {
    const { customerId, inviteId, getInvoices, selectedFilters } = this.props;
    getInvoices(customerId, inviteId, "", selectedFilters.feedbackGroup);
    this.setState(
      produce((draft) => {
        draft.showModal = true;
      }),
    );
    analytics.logInvoiceButtonClick();
  }

  getAllInvoicesAPI(searchValue) {
    const { customerId, getInvoices, selectedFilters } = this.props;
    getInvoices(
      customerId,
      "",
      searchValue || "",
      selectedFilters.feedbackGroup,
    );
  }

  showAllInvoices(source) {
    this.getAllInvoicesAPI();
    this.setState({
      showModal: true,
      showInvoiceList: true,
      isFeedbackInvoice: false,
      invoice: {},
    });
    analytics.logInvoiceListView(source);
  }

  setSearchValue(value) {
    this.setState({
      searchValue: value,
    });
  }

  searchInvoices(searchValue) {
    if (searchValue) {
      this.setState({
        searchValue: searchValue,
        showInvoiceList: false,
        isFeedbackInvoice: true,
      });
    } else {
      this.setState({
        searchValue: searchValue,
        showInvoiceList: true,
        isFeedbackInvoice: false,
      });
    }
    this.getAllInvoicesAPI(searchValue);
    analytics.logInvoiceSearch(searchValue);
  }

  loadMoreInvoices(url) {
    const { getMoreInvoices } = this.props;
    getMoreInvoices(url);
    analytics.logInvoiceLoadMore(url);
  }

  close(e) {
    this.setState(
      produce((draft) => {
        draft.showModal = false;
        draft.isFeedbackInvoice = this.props.isFeedbackInvoice || false;
        draft.showInvoiceList = true;
        draft.searchValue = "";
      }),
    );
    analytics.logInvoiceModalClose();
  }

  displayInvoice(e, invoice) {
    this.setState({
      showInvoiceList: false,
      invoice: invoice,
    });
    analytics.logInvoiceDetailView(invoice.id, invoice.invoice_no);
  }

  render() {
    let { organization, invoices, inviteId, selectedFilters } = this.props;
    let {
      showModal,
      showInvoiceList,
      invoice,
      searchValue,
      isFeedbackInvoice,
    } = this.state;
    let organizationColor = organization.features.colors;
    return (
      <Col className="clear-col-padding" md={inviteId ? 3 : 12} sm={12} xs={12}>
        {inviteId ? (
          <InvoiceButton
            left={this.props.permissions.can_add_tag_to_feedback}
            onClick={this.displayInvoices}
          >
            INVOICE
          </InvoiceButton>
        ) : (
          <i
            className="fa fa-file-o invoice-icon"
            onClick={() => this.showAllInvoices("customer page icon")}
          />
        )}
        <Modal
          className={"invoices-modal"}
          backdrop={true}
          size="lg"
          show={showModal}
          onHide={this.close}
        >
          <Modal.Header closeButton>
            <Modal.Title className="filter-header invoice-header">
              {showInvoiceList && !isFeedbackInvoice
                ? "INVOICE LIST"
                : "INVOICE DETAILS"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: "250px" }}>
            {invoices.isPending ? (
              <Spinner name="wandering-cubes" fadeIn="none" />
            ) : isFeedbackInvoice ? (
              <InvoiceDetailList
                invoices={invoices}
                searchValue={searchValue}
                primaryColor={organizationColor && organizationColor.primary}
                loadMoreInvoices={this.loadMoreInvoices}
                close={this.close}
                selectedFilters={selectedFilters}
              />
            ) : showInvoiceList ? (
              <InvoiceList
                displayInvoice={this.displayInvoice}
                invoices={invoices}
                primaryColor={organizationColor && organizationColor.primary}
                loadMoreInvoices={this.loadMoreInvoices}
                searchValue={searchValue}
                close={this.close}
                selectedFilters={selectedFilters}
              />
            ) : (
              <InvoiceDetail
                invoice={invoice}
                searchValue={searchValue}
                close={this.close}
                selectedFilters={selectedFilters}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            {showInvoiceList && !isFeedbackInvoice ? (
              <SearchBoxWithTranslation
                value={searchValue}
                colors={organizationColor}
                onSubmit={this.searchInvoices}
                onChange={this.setSearchValue}
              />
            ) : (
              <Button
                onClick={() => this.showAllInvoices("see all invoices button")}
                style={
                  organizationColor && {
                    background: organizationColor.primary,
                    color: organizationColor.text || "whitesmoke",
                  }
                }
              >
                See all invoices
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </Col>
    );
  }
}

const mapStateToProps = ({
  auth,
  invoices,
  selectedFilters,
  organization,
}) => ({
  invoices: invoices,
  selectedFilters: selectedFilters,
  permissions: auth.user.permissions,
  organization: organization.organization,
});

export default connect(mapStateToProps, {
  getInvoices,
  getMoreInvoices,
})(InvoiceModal);

export const InvoiceButton = styled.div`
  padding: 10px;
  color: white;
  background: var(--primary-color);
  text-align: center;
  cursor: pointer;
  float: ${(props) => (props.left ? "left" : "right")};
`;

import React, { useState } from "react";
import AnimatedNumber from "react-animated-number";
import {
  CardValueWrapper,
  CardValue as Value,
  CardValueCaption,
  CardHeading,
  CardHeader,
  ValueText,
} from "./styled";
import { TooltipText } from "../Tooltip";
import { formatNumber } from "utils/genericHelper";

export const CardValue = ({
  caption,
  value,
  suffix,
  prefix,
  sizeClasses,
  headingClass,
  asHeader,
  textColor,
  headingColor,
  isPercentage,
  tooltip,
  hover,
  numberFormat,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const isNumber = typeof value === "number";
  const val = isHovered ? hover : value;
  const suf = isHovered ? "" : suffix;
  const text = `${prefix ?? ""}${isNumber ? val.toFixed?.(1) : val}${
    suf ?? ""
  }`;
  const size = scaleDown(text);
  const isHoverApplicable = hover && isNumber && !isPercentage;
  const isAbbreviatd = isHoverApplicable && !isHovered;
  return (
    <CardValueWrapper className="ui-card-value_wrapper">
      {asHeader && caption && (
        <CardHeader className="ui-card-header">
          <CardHeading
            className={`ui-card-heading tooltipRel ${headingClass}`}
            headingColor={headingColor}
          >
            {caption}
            {tooltip && <TooltipText tooltipText={tooltip} />}
          </CardHeading>
        </CardHeader>
      )}
      <Value
        onMouseEnter={() => isHoverApplicable && setIsHovered(true)}
        onMouseLeave={() => isHoverApplicable && setIsHovered(false)}
        textColor={textColor}
        className={`ui-card-value ${sizeClasses}`}
      >
        <ValueText size={size}>
          {prefix}
          {isNumber ? (
            <AnimatedNumber
              component="text"
              value={val}
              formatValue={(n) =>
                formatNumber(
                  n,
                  isAbbreviatd || isPercentage ? "0,0.0" : numberFormat,
                )
              }
            />
          ) : (
            <>
              {formatNumber(
                parseFloat(value),
                isAbbreviatd || isPercentage ? "0,0.0" : numberFormat,
              )}
            </>
          )}
          {suf}
        </ValueText>
      </Value>
      {!asHeader && caption && (
        <CardValueCaption className="ui-card-caption" textColor={textColor}>
          {caption}
        </CardValueCaption>
      )}
    </CardValueWrapper>
  );
};

const scaleDown = (text) => {
  if (text.length > 14) {
    return "25px";
  } else if (text.length > 12) {
    return "30px";
  }
};

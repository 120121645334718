import React from "react";
import { useSelector } from "react-redux";

import { selectOrganization } from "components/Organization/selectors";
import { selectQuestionnaireId, selectUserName } from "./selectors";
import { DemoIFrame } from "./styled";

const host = window.location.origin;
const baseUrl = host + "/web-survey/#";

const SurveyDemo = () => {
  const user = useSelector(selectUserName);
  const organization = useSelector(selectOrganization);
  const questionnaireId = useSelector(selectQuestionnaireId);
  const src = `${baseUrl}/?demo=true&slug=${organization.slug}&questionnaireId=${questionnaireId}&customer=${user}`;
  return <DemoIFrame src={src}></DemoIFrame>;
};

export default SurveyDemo;

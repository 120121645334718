import styled, { css } from "styled-components";

export const Td = styled.td`
  padding: 14px 20px;
  min-width: calc(100vw / 9.5);
  background-color: #ffffff;
  width: 100% !important;

  &:not(:first-child),
  &:not(:nth-child(2)) {
    text-align: center;
  }

  &[data-sticky-last-left-td="true"],
  &[data-sticky-td="true"] {
    position: sticky;
    top: auto;
    left: 0;
    z-index: 2 !important;
    background-color: #efefef;
    color: #707070;
    font-weight: bold;
    word-break: break-word;
  }
`;

export const Tr = styled.tr`
  min-width: 100%;
  width: 100% !important;

  &:not(:last-child) {
    border-bottom: 1px solid #b4b5b5;
  }
`;

export const Th = styled.th`
  padding: 18px 20px;
  font-weight: bold;
  color: #707070;
  min-width: calc(100vw / 9.5);
  background-color: #efefef;
  width: 100% !important;

  &:not(:first-child),
  &:not(:nth-child(2)) {
    text-align: center;
  }

  &[data-sticky-last-left-td="true"],
  &[data-sticky-td="true"] {
    position: sticky;
    top: auto;
    left: 0;
    z-index: 3;
  }

  svg {
    opacity: 0;
    fill: currentColor;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: opacity 0.1s;
  }

  &:hover svg {
    opacity: 1;
  }

  .tableSorters {
    position: relative;

    &.sorted svg {
      opacity: 1;
      visibility: visible;
    }
  }

  text {
    display: flex;
    margin: auto;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Tbody = styled.tbody`
  display: inline-block;
  min-width: 100%;

  ${Tr}:hover {
    ${Td} {
      background-color: var(--secondary-color-light);
    }
  }
`;

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 3;
  display: inline-block;
  min-width: 100%;
  margin-bottom: 3px;
`;

export const Table = styled.table`
  min-width: 100%;
  color: #4d4f5c;
  background-color: #ffffff;
  display: table;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 0px;
  max-height: 375px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: block;

  ${(props) =>
    props.tableHeight &&
    css`
      @media (min-width: 576px) {
        max-height: ${props.tableHeight.sm};
      }
      @media (min-width: 768px) {
        max-height: ${props.tableHeight.md};
      }
      @media (min-width: 992px) {
        max-height: ${props.tableHeight.lg};
      }
    `}
`;

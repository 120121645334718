import styled, { css } from "styled-components";
export const Wrapper = styled.div`
  padding: 0 83px 40px;
  @media screen and (max-width: 1200px) {
    padding: 0 33px 40px;
  }
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")}px;
  position: relative;
`;

export const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? width : "auto")}px;
  position: relative;
`;

export const ToolTip = styled.span`
  background-color: #133b7d;
  padding: 5px 4px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: var(--text-color);
  position: absolute;
  bottom: -30%;
  max-width: 135px;
  right: 0;
  display: none;
  box-shadow: #00000014 0px 3px 6px;
  margin-top: 5px;
  &::after {
    content: "▲";
    position: absolute;
    bottom: 80%;
    font-size: 17px;
    color: #133b7d;
    right: 7%%;
  }
`;

export const NextButton = styled.button`
  border: 1px solid ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  color: ${(props) => (props.disabled ? "#313131" : "var(--text-color)")};
  font-size: 15px;
  padding: 8px 48px;
  margin-bottom: 45px;
  font-size: 15px;
  margin-top: 50px;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#06265b")};
  }
  &:hover ${ToolTip} {
    display: block;
    bottom: -5%;
    right: 5%;
    transition: 0.5s ease-in-out right;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;
// Location Styles Start

// Location Styles Start

export const DoneButton = styled.button`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: white;
  background-color: var(--primary-color);
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  float: right;
  margin-right: 16px;
  margin-top: 16px;
  margin-bottom: 9px;
  padding: 2px 15px;
  margin: 16px 9px 16px 0;
`;

export const Description = styled.h6`
  font-size: 12px;
  color: black;
  margin-bottom: 13px;
  &:hover {
    cursor: pointer;
  }
  text-align: center;
  ${(props) =>
    props.isSelected &&
    css`
      font-weight: bold;
      letter-spacing: 1px;
      text-shadow: 0.5px 0 black;
    `}
`;

export const DescriptionSelect = styled.div`
  height: 170px;
  overflow: auto;
  width: 100%;
  border-right: ${(props) => (props.borderRight ? "1px solid #e9e9e9" : "")};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Category = styled.span`
  color: #7c7c7c;
  font-size: 12px;
  font-size: "Roboto", Arial, Helvetica, sans-serif;
  margin-bottom: 5px;
`;

export const CategorySelect = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => `${parseInt(120 / props.width)}%`};
  align-items: center;
`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Container = styled.div`
  background-color: var(--text-color);
  position: absolute;
  width: ${(props) => `${25 * props.width}%`};
  z-index: 8;
  top: 100%;
  box-shadow: 0 6px 10px #00000014;
`;

export const FormInput = styled.input`
  border-radius: 3px;
  background-color: var(--text-color);
  border: 1px solid ${(props) => (props.isError ? "red" : "#e9e9e9")};
  padding: 0 26px;
  height: ${({ height }) => (height ? height : 46)}px;
  font-size: 14px;
  font-family: "Roboto-Medium", Arial, Helvetica, sans-serif;
  color: #242424;
  &:hover {
    border-color: rgb(179, 179, 179);
  }
  ${(props) =>
    props.placeholder &&
    css`
      ::placeholder {
        color: #d3d3d3;
        font-family: "SegoeUI", Arial, Helvetica, sans-serif;
      }
    `}
  ${(props) =>
    props.error &&
    css`
      border: 2px solid #f33;
    `}
`;

export const Label = styled.label`
  font-size: 12px;
  color: #666666;
  position: absolute;
  left: 28px;
  margin-bottom: 0;
  top: -9px;
  background: rgb(255 255 255);
`;

// Location Styled Ended

// Custom Styles for Detail Section Start

export const FieldWrapper = styled.div`
  width: calc((100% / 2) - 88px);
  @media screen and (max-width: 1200px) {
    width: calc((100% / 2) - 38px);
  }
  @media screen and (max-width: 1059px) {
    width: calc((100% / 2) - 8px);
  }
  @media screen and (max-width: 1059px) {
    width: calc((100% / 1) - 40px);
    margin: 0 auto;
  }
  @media screen and (max-width: 768px) {
    width: calc((100% / 1) - 20px);
  }
`;

export const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 83px;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    padding: 0 33px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

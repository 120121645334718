import styled from "styled-components";

export const DeleteButton = styled.button`
  background-color: #133b7d;
  color: var(--text-color);
  border-radius: 3px;
  font-size: 15px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  border: 1px solid #133b7d;
  padding: 10px 10px;
  width: 86px;
  &:hover {
    background-color: #06265b;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    width: 80px;
    padding: 6px 11px;
  }
`;

export const CancelButton = styled(DeleteButton)`
  background-color: var(--text-color);
  color: #133b7d;
  margin-left: 22px;
  &:hover {
    background-color: #06265b;
    color: var(--text-color);
  }
`;

export const ButtonWrapper = styled.div`
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  display: flex;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const ProjectName = styled.span`
  font-weight: bold;
`;

export const Title = styled.p`
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #242424;
  font-weight: 200;
  text-align: center;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Modal = styled.div`
  border: 1px solid #707070;
  border-radius: 5px;
  margin: 40% auto 0;
  background-color: var(--text-color);
  padding: 36px;
  margin-top: 10%;
  max-width: 420px;

  @media (max-width: 768px) {
    max-width: 300px;
    padding: 20px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(80, 80, 80, 0.7);
  z-index: 3;
`;

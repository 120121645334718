import React, { Component } from "react";
import i18n from "i18n";

import isEmpty from "lodash/isEmpty";
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Spinner from "react-spinkit";
import CSSTransitionGroup from "react-transition-group/CSSTransition";

import { getUserDetail, updateUserDetail } from "./actions";
import ChangePassword from "./ChangePassword/ChangePassword";
import { UserProfileImage, UserProfileInitials } from "./styled";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      avatar: null,
      showModal: false,
      avatarError: [],
    };

    this.onValueChange = this.onValueChange.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.hideChangePasswordModal = this.hideChangePasswordModal.bind(this);
  }

  componentDidMount() {
    const { getUserDetail } = this.props;

    getUserDetail().then((user) => {
      this.setState({
        user,
      });
    });
  }

  showChangePasswordModal = () => {
    this.setState({
      showModal: true,
    });
  };

  hideChangePasswordModal = () => {
    this.setState({
      showModal: false,
    });
  };

  onValueChange = (e) => {
    this.setState({
      user: {
        ...this.state.user,
        [e.target.name]: e.target.value,
      },
    });
  };

  onRemoveAvatar = () => {
    this.setState({
      avatar: null,
      user: {
        ...this.state.user,
        avatar: null,
      },
    });
  };

  onAvatarChange = (e) => {
    let avatarError = [];
    let avatar = e.target.files[0];
    let fr = new FileReader();

    // Validate
    if (avatar) {
      // Size
      let size = avatar.size / 1024000;
      if (size > 1.0) {
        avatarError.push(i18n.t("imageSize1MB"));
        this.setState({ avatarError });
      }

      // Dimensions
      fr.readAsDataURL(avatar);
      fr.onload = () => {
        let img = new Image();
        img.onload = () => {
          if (img.width !== img.height) {
            avatarError.push(i18n.t("uploadSquareImage"));
          }
        };
        img.src = fr.result;
      };
      this.setState({
        avatar,
        avatarError,
        user: {
          ...this.state.user,
          avatar: window.URL.createObjectURL(avatar),
        },
      });
    }
  };

  onSubmitHandler = (e) => {
    e.preventDefault();
    const { updateUserDetail, profile } = this.props;
    const { user, avatar } = this.state;

    let unAuth = profile.username !== user.username ? true : false;

    updateUserDetail(user, avatar, unAuth).then((user) => {
      if (user) {
        this.setState({
          user: user,
        });
      } else {
        this.setState({
          user: profile,
        });
      }
    });
  };

  render() {
    const { fetchingUserProfile, updatingUserProfile, languages, errors, t } =
      this.props;
    let { user, avatarError } = this.state;
    if (fetchingUserProfile || !user)
      return <Spinner name="wandering-cubes" fadeIn="none" />;

    let langOptions = [];
    for (let code in languages) {
      langOptions.push({ label: languages[code], value: code });
    }

    const defaultValue = langOptions.find(
      (option) => option.value === user.preferred_lang,
    );

    return (
      <div className="userProfileWrapper t-wrap">
        {!updatingUserProfile ? (
          <div className="t-holder">
            <div className="main-holder">
              <div className="avatar-holder">
                <label
                  htmlFor="choose-file"
                  label="Choose Photo"
                  className="avatar"
                  style={{
                    backgroundColor: !user.avatar
                      ? "var(--secondary-color)"
                      : "transparent",
                  }}
                >
                  {user.avatar ? (
                    <UserProfileImage src={user.avatar} />
                  ) : user.first_name || user.last_name ? (
                    <UserProfileInitials>
                      {user.first_name ? user.first_name[0] : null}
                      {user.last_name ? user.last_name[0] : null}
                    </UserProfileInitials>
                  ) : null}
                  <div className="edit-icon" title={t("fileSizeSquareImg")}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </div>
                </label>
                {user.avatar && (
                  <div
                    className="remove-icon"
                    title={t("removePic")}
                    onClick={this.onRemoveAvatar}
                  >
                    <i
                      className="fa fa-times cross-icon"
                      aria-hidden="true"
                    ></i>
                  </div>
                )}
                <input
                  type="file"
                  id="choose-file"
                  onChange={this.onAvatarChange}
                />
                <CSSTransitionGroup
                  classNames="animated"
                  appear={true}
                  timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 300,
                  }}
                  enter={false}
                  exit={false}
                >
                  <div>
                    {!isEmpty(avatarError) ? (
                      <Alert variant="danger">
                        <ul>
                          {avatarError.map((err, index) => {
                            return <li key={index}>{err}</li>;
                          })}
                        </ul>
                      </Alert>
                    ) : null}
                  </div>
                </CSSTransitionGroup>
              </div>
              <div className="edit-bar">
                <div className="user-info">
                  <i className="fa fa-id-card-o" aria-hidden="true"></i>
                  <p>
                    <span className="name">{user.full_name}</span>
                    <span className="role">{user.role.title}</span>
                  </p>
                </div>
                <div className="edit-icon">
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </div>
              </div>

              <div className="col-md-10 col-md-push-1">
                <Form
                  onSubmit={this.onSubmitHandler}
                  horizontal
                  className="form-wrap"
                >
                  <FormGroup>
                    <Row>
                      <Col md={9}>
                        <CSSTransitionGroup
                          classNames="animated"
                          appear={true}
                          timeout={{
                            appear: 500,
                            enter: 300,
                            exit: 300,
                          }}
                          enter={false}
                          exit={false}
                        >
                          <div>
                            {!isEmpty(errors) && errors.non_field_errors ? (
                              <Alert variant="danger">
                                <p>{errors.non_field_errors.join(" | ")}</p>
                              </Alert>
                            ) : null}
                          </div>
                        </CSSTransitionGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        {t("username")}
                      </Col>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          name="username"
                          placeholder={t("username")}
                          value={user.username}
                          onChange={this.onValueChange}
                          title={t("usernameChangeLogout")}
                          disabled
                        />
                        <CSSTransitionGroup
                          classNames="animated"
                          appear={true}
                          timeout={{
                            appear: 500,
                            enter: 300,
                            exit: 300,
                          }}
                          enter={false}
                          exit={false}
                        >
                          <div>
                            {!isEmpty(errors) && errors.username ? (
                              <Alert variant="danger">
                                <p>{t(errors.username[0])}</p>
                              </Alert>
                            ) : null}
                          </div>
                        </CSSTransitionGroup>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        {t("firstName")}
                      </Col>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          name="first_name"
                          placeholder={t("firstName")}
                          value={user.first_name}
                          onChange={this.onValueChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        {t("lastName")}
                      </Col>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          name="last_name"
                          placeholder={t("lastName")}
                          value={user.last_name}
                          onChange={this.onValueChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        Email
                      </Col>
                      <Col md={9}>
                        <FormControl
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={user.email}
                          onChange={this.onValueChange}
                        />
                        <CSSTransitionGroup
                          classNames="animated"
                          appear={true}
                          timeout={{
                            appear: 500,
                            enter: 300,
                            exit: 300,
                          }}
                          enter={false}
                          exit={false}
                        >
                          <div>
                            {!isEmpty(errors) && errors.email ? (
                              <Alert variant="danger">
                                <p>{t(errors.email[0])}</p>
                              </Alert>
                            ) : null}
                          </div>
                        </CSSTransitionGroup>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        {t("phoneNumber")}
                      </Col>
                      <Col md={9}>
                        <FormControl
                          type="text"
                          name="cell_phone"
                          placeholder={t("phoneNumber")}
                          value={
                            (user.cell_phone !== "null" && user.cell_phone) ||
                            ""
                          }
                          onChange={this.onValueChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  {/* <FormGroup>
                    <Row>
                      <Col as={FormLabel} md={3}>
                        {t("preferredLanguage")}
                      </Col>
                      <Col md={9}>
                        <Select
                          value={defaultValue}
                          options={langOptions}
                          classNamePrefix={t("preferredLanguage")}
                          onChange={e => {
                            this.setState({
                              ...this.state,
                              user: {
                                ...this.state.user,
                                preferred_lang: e.value,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </FormGroup> */}
                </Form>
                <Row>
                  <Col md={6}>
                    <Button
                      style={{ float: "left" }}
                      onClick={this.showChangePasswordModal}
                      variant="outline-dark"
                    >
                      {t("changePassword")}
                    </Button>
                  </Col>
                  <Col md={6}>
                    <Button
                      onClick={this.onSubmitHandler}
                      disabled={updatingUserProfile || !isEmpty(avatarError)}
                      type="submit"
                      variant="success"
                      style={{ float: "right" }}
                    >
                      {t("update")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <Spinner name="wandering-cubes" fadeIn="none" />
        )}

        <ChangePassword
          show={this.state.showModal}
          hide={this.hideChangePasswordModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchingUserProfile: state.profile.fetchingUserProfile,
  updatingUserProfile: state.profile.updatingUserProfile,
  profile: state.profile.detail,
  errors: state.profile.errors,
  // languages: state.auth.languages,
});

const mapDispatchToProps = {
  getUserDetail,
  updateUserDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Profile));

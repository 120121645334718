import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Row, Col } from "react-bootstrap";
import { format } from "date-fns";
import { isTruthy } from "../../../utils/genericHelper";
import { HighLightText } from "../../common/HighLightText";
import numeral from "numeral";
import { getTotalItemsSold } from "./selectors";

export const InvoiceDetail = ({
  invoice,
  searchValue,
  close,
  selectedFilters,
}) => {
  const { t } = useTranslation();

  return (
    <div className="invoice-details">
      <Table>
        <thead className="recepit-header">
          <tr>
            <th colSpan="6">
              <Row>
                <div className="table-heads">Division</div>
                <div className="table-body">{invoice.division.title}</div>
              </Row>
              <Row>
                <div className="table-heads">Invoice ID</div>
                <div className="table-body">
                  <HighLightText
                    value={invoice.invoice_no}
                    match={searchValue}
                  />
                </div>
              </Row>
              <Row>
                <div className="table-heads">Transaction Timestamp</div>
                <div className="table-body">
                  {format(
                    new Date(invoice.generated_at),
                    "d MMMM yyyy, h:mm aaa",
                  )}
                </div>
              </Row>
              <Row>
                <div className="table-heads">Feedback ID</div>
                <div className="table-body">
                  {invoice.feedback_url_uuid && invoice.feedback ? (
                    <a
                      href={`/feedback/${invoice.feedback_url_uuid.slice(
                        0,
                        -2,
                      )}?feedback_group=${selectedFilters.feedbackGroup}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {invoice.feedback}
                    </a>
                  ) : (
                    "--"
                  )}
                </div>
              </Row>
            </th>
          </tr>
          <tr>
            <th colSpan="6">
              <Row>
                <div className="table-heads">Total Amount</div>
                <div className="table-body">
                  {numeral(invoice.total_price).format("0,0")}
                </div>
              </Row>
              <Row>
                <div className="table-heads">Total Quantity Sold</div>
                <div className="table-body">{getTotalItemsSold(invoice)}</div>
              </Row>
              <Row>
                <div className="table-heads">Payment Method</div>
                <div className="table-body">{invoice.payment_method}</div>
              </Row>
            </th>
          </tr>
          <tr>
            <th className="row-no">#</th>
            <th className="item-name">Item name</th>
            <th className="code-name">Item code</th>
            <th className="price">Price</th>
            <th className="quantity">Quantity</th>
            <th className="total">Total</th>
          </tr>
        </thead>
        <tbody>
          {isTruthy(invoice.items) ? (
            invoice.items.map((item, index) => {
              return (
                <tr className="table-row">
                  <td className="row-no">{index + 1}</td>
                  <td className="item-name">
                    <HighLightText
                      value={item.product.name}
                      match={searchValue}
                    />
                  </td>
                  <td className="code-name">
                    <HighLightText
                      value={item.product.code}
                      match={searchValue}
                    />
                  </td>
                  <td className="price">{numeral(item.price).format("0,0")}</td>
                  <td className="quantity">
                    {numeral(item.quantity).format("0,0")}
                  </td>
                  <td className="total-item-price">
                    {numeral(item.price * item.quantity).format("0,0")}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="not-found">
              <td colSpan="6">{t("noDataFound")}</td>
            </tr>
          )}
        </tbody>
        <tfoot className="receipt-footer">
          <tr className="total">
            <td colSpan={5}>TOTAL</td>
            <td colSpan={1}>{numeral(invoice.total_price).format("0,0")}</td>
          </tr>
        </tfoot>
      </Table>
    </div>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Fields from "./Fields";

import { Wrapper } from "./styled";

import {
  SectionButton,
  SectionButtonWrapper,
  ToolTip,
} from "components/RCA/Form/styled";

import {
  saveRCAForm,
  toggleMode,
  setFormFieldValue,
} from "components/RCA/actions";

import {
  formFieldValueSelector,
  currentLoggedInUserIdSelector,
} from "components/RCA/selectors";

import { RCAProjectStatus } from "constants/constants";

const DeploymentApprovalSection = ({ fields, isError }) => {
  const dispatch = useDispatch();

  const selectedTeamLead =
    useSelector((state) => formFieldValueSelector(state, "team_lead")) || null;
  const loggedInUserId = useSelector(currentLoggedInUserIdSelector);
  const owner =
    useSelector((state) => formFieldValueSelector(state, "owner")) || null;

  const isOwnerLoggedIn = owner?.id === loggedInUserId;
  const isLeadLoggedIn = loggedInUserId === selectedTeamLead?.id;

  const sendDeploymentRequest = (status) => {
    dispatch(setFormFieldValue("status", status));
    dispatch(saveRCAForm());
    dispatch(toggleMode("VIEW"));
  };

  const [onClick, buttonText, toolTipText] = isLeadLoggedIn
    ? [
        () => sendDeploymentRequest(RCAProjectStatus.DEPLOYED),
        "Deploy the project",
        "If you are satisfied with the changes, submit to deploy the project",
      ]
    : isOwnerLoggedIn
    ? [
        () => sendDeploymentRequest(RCAProjectStatus.DEPLOYMENT_PENDING),
        "Send request to Team Lead for deployment approval",
        "If you want to do any change in project details do the change and send the request to approver for acceptance",
      ]
    : [];

  return (
    <Wrapper>
      {fields.map((field) => {
        const Field = Fields[field.field_type];
        return <Field key={field.key} field={field} />;
      })}
      <SectionButtonWrapper>
        {(isLeadLoggedIn || isOwnerLoggedIn) && (
          <SectionButton onClick={onClick} disabled={isError}>
            <ToolTip>
              {isError
                ? "Kindly fill in all the fields to submit"
                : toolTipText}
            </ToolTip>
            {buttonText}
          </SectionButton>
        )}
      </SectionButtonWrapper>
    </Wrapper>
  );
};

export default DeploymentApprovalSection;

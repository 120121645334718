import { initialState } from "../initialState/initialState";
import * as constants from "../constants/constants";

const divisionsReducer = (state = initialState.divisions, action) => {
  switch (action.type) {
    case constants.LOAD_DIVISIONS_SUCCESS:
      return Object.assign({}, state, { divisions: action.divisions });

    default:
      return state;
  }
};

export default divisionsReducer;

import React, { Suspense, Component } from "react";
import Spinner, { SpinnerContainer } from "components/common/MiniSpinner";

export const LazyLoading = (ActualComponent) => {
  return class LazyComponent extends Component {
    render() {
      return (
        <Suspense
          fallback={
            <SpinnerContainer className="tset">
              <Spinner size="55" />
            </SpinnerContainer>
          }
        >
          <ActualComponent {...this.props} />
        </Suspense>
      );
    }
  };
};

import styled from "styled-components";
import { Button, Tabs, Tab, Dropdown } from "react-bootstrap";
import { DropdownContainer } from "components/common/DropdownContainer";

export const BootDropdownMenu = styled(Dropdown.Menu)`
  font-size: 13px;
  padding: 0;
  z-index: 9;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  margin: 5px 0 !important;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #efefef;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 20px;
    right: auto;
    top: -20px;
    z-index: -1;
  }

  .dateRangePicker {
    .rdrDateRangeWrapper {
      width: 100%;
    }

    .rdrDateDisplay {
      &Wrapper {
        background-color: transparent;
        display: none;
      }

      &ItemActive {
        border-color: var(--primary-color);
      }
    }

    .rdrNextPrevButton {
      background-color: #eee;

      &:hover {
        background-color: #ccc;
      }

      &.rdrPprevButton i {
        border-color: transparent var(--primary-color) transparent transparent;
      }

      &.rdrNextButton i {
        border-color: transparent transparent transparent var(--primary-color);
      }
    }

    .rdrSelected,
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      background: var(--primary-color);
      top: 3px;
      left: 0;
      right: 0;
      bottom: 3px;
    }

    .rdrStartEdge {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    .rdrEndEdge {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
    }

    .rdrDay,
    .rdrDayStartPreview,
    .rdrDayEndPreview,
    .rdrDayInPreview {
      color: var(--primary-color) !important;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background: var(--primary-color);
      bottom: 0;
      width: 10px;
    }

    .rdrMonth {
      width: 17rem;
    }
  }
`;

export const BootDropdownToogle = styled(Dropdown.Toggle)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
  }
`;

export const BootDropdown = styled(Dropdown)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

export const PresetCalendarItem = styled.div`
  background-color: transparent;
  color: #707070;
  padding: 7px;
  width: 45px;
  height: 45px;
  margin: 0 auto;
  line-height: 2.3;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const PresetCalendar = styled.div`
  width: calc(100% / 3);
  min-width: 45px;
  text-align: center;
  margin: 10px 0;
`;

export const PresetCalendarContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  width: 17rem;
  padding: 0 0.833em 1.666em 0.833em;

  &:not(:last-child) {
    border-right: 1px solid #efefef;
  }
`;

export const PresetFilterContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
`;

export const PresetFilterWrapper = styled.div``;

export const TimePickerContainer = styled(DropdownContainer)`
  margin: 0 auto;
`;

export const Label = styled.label`
  display: inline-block;
  text-transform: none;
  margin: 0;
  padding: 0 1em 0 0;
  cursor: pointer;
  color: #707070;
  font-size: 0.9em;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 33px;
  height: 15px;
  border-radius: 15px;
  background: #efefef;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 0;
    background: #adadad;
    transition: 0.2s;
    margin-top: -2px;
    bottom: 0;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 33px;
  height: 14px;

  &:checked + ${CheckBoxLabel} {
    background: rgba(137, 193, 32, 0.4);

    &::after {
      margin-left: 15px;
      background: #89c120;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 0.833em;
`;

export const DateFilterTab = styled(Tab)``;

export const DateFilterTabs = styled(Tabs)`
  background-color: #efefef;
  padding: 10px 10px 0;
  border: none;

  .nav-link {
    border: none;

    &.active {
      border: none;
    }
    &:hover {
      border: none;
    }
  }
`;

export const DatePickerLabel = styled(Label)``;

export const DatePickerWrapper = styled.div``;

export const ActionButton = styled(Button)`
  border-color: transparent;
  background: #ccc;
  color: #fff;
  border-radius: 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.apply {
    background-color: #89c120;
    border-color: #89c120;
  }
`;

export const ActionContainer = styled.div`
  margin-top: 20px;
  margin-left: auto;
`;

export const ModalFooter = styled.div`
  text-align: left;
  padding: 0.6em 0.9em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column wrap;

  ${DatePickerLabel} {
    margin-bottom: 0.8em;
  }

  ${DatePickerWrapper} {
    &:not(:last-child) {
      margin-right: 2em;
    }
  }

  .custom_dropdown-wrapper {
    width: 100px;
    min-width: 5em;

    .custom_dropdown {
      min-width: 100%;
      padding: 0.78571429em 1em 0.78571429em 1em;
      background-color: #eee;
      border-color: #707070;

      i {
        display: none;
      }

      &-text {
        color: #707070;
      }

      &-text::after {
        display: none;
      }

      &-menu {
        max-height: 200px;
        margin: 13px 0 20px;
      }

      &-menu-list {
        max-height: 165px;
      }

      &[open] {
        border-color: var(--primary-color);
        background: #fff;

        .custom_dropdown-text {
          color: var(--primary-color);
        }
      }
    }
  }
`;

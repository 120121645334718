import { initialState } from "../../initialState/initialState";
import {
  FETCHING_USER_DETAIL,
  USER_DETAIL_SUCCESS,
  UPDATING_USER_DETAIL,
  USER_DETAIL_FAIL,
} from "./constants";

const profileReducers = (state = initialState.profile, action) => {
  switch (action.type) {
    case FETCHING_USER_DETAIL:
      return Object.assign({}, state, {
        fetchingUserProfile: true,
        updatingUserProfile: false,
      });

    case USER_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        fetchingUserProfile: false,
        updatingUserProfile: false,
        detail: action.user,
        errors: {},
      });

    case USER_DETAIL_FAIL:
      return Object.assign({}, state, {
        updatingUserProfile: false,
        errors: action.err,
      });

    case UPDATING_USER_DETAIL:
      return Object.assign({}, state, {
        updatingUserProfile: true,
      });

    default:
      return state;
  }
};

export default profileReducers;

import { getRequest, postRequest, patchRequest } from "utils/request";

export const createUpdateSurveyApi = (
  organizationId,
  payload,
  surveyId = "",
) => {
  let url = `/organizations/${organizationId}/questionnaires/v2/create_update/${surveyId}`;
  return surveyId ? patchRequest(url, payload) : postRequest(url, payload);
};

export const getSurveyConfigApi = (organizationId) => {
  let url = `/v1/organizations/${organizationId}/configurations`;
  return getRequest(url);
};

export const getSingleSurveyApi = (orgId, surveyId) => {
  let url = `/organizations/${orgId}/questionnaires/v2/${surveyId}/detail`;

  return getRequest(url);
};

export const getSurveyList = (orgId, page, searchable) => {
  let url = `/organizations/${orgId}/questionnaires/v1/questionnaire_list/?searchable=${searchable}&&${
    page ? `page=${page}` : ""
  }`;

  return getRequest(url);
};

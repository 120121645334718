import styled from "styled-components";

export const InnerListItem = styled.li`
  font-size: 16px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: #242424;
  margin-bottom: 0;
  position: relative;
  margin: 14px 0;
  &::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 1px;
    background-color: #e9e9e9;
    left: -40px;
    top: 50%;
  }
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: ${(props) => (props.verticalLine ? "26px" : "0px")};
    background-color: #e9e9e9;
    left: 49px;
    top: 100%;
  }
  &:last-child {
    &::after {
      content: none;
    }
  }
`;

export const ListItem = styled.li`
  font-size: 16px;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  color: #242424;
  margin-bottom: 0;
  position: relative;
  margin: 14px 0;
  &::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 1px;
    background-color: #e9e9e9;
    left: -40px;
    top: 50%;
  }
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: ${(props) => (props.verticalLine ? "27px" : "0px")};
    background-color: #e9e9e9;
    left: 49px;
    top: 100%;
  }
`;

export const ElementHeading = styled.h6`
  font-size: 16px;
  font-family: "Roboto-Medium", Helvetica, Arial, sans-serif;
  color: #242424;
  margin-bottom: 0;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 27px;
    background-color: #e9e9e9;
    left: 49px;
    top: 100%;
  }
`;

export const Wrapper = styled.ul`
  list-style: none;
  margin-left: 50px;
`;

export const ParentWrapper = styled.div`
  position: relative;
`;

export const FamilyContainer = styled.div`
  background-color: var(--text-color);
  width: 100%;
  border-radius: 4px;
  padding: 22px 27px 26px;
`;

export const FullWidth = styled.div`
  background-color: #e9e9e9;
  padding: 24px 26px 4px;
`;

export const SingleElement = styled.div`
  width: ${(props) => (props.isFullWidth ? "100%" : "calc((100% / 4) - 49px)")};
  margin: ${(props) => (props.isFullWidth ? "inherit" : "0 24px")};
  @media screen and (max-width: 1200px) {
    margin: 0 12px;
    width: ${(props) =>
      props.isFullWidth ? "100%" : "calc((100% / 4) - 24px)"};
  }
  @media screen and (max-width: 1059px) {
    margin: 0 24px;
    width: ${(props) =>
      props.isFullWidth ? "100%" : "calc((100% / 3) - 24px)"};
  }
  @media screen and (max-width: 991px) {
    width: ${(props) =>
      props.isFullWidth ? "100%" : "calc((100% / 2) - 50px);"};
  }
`;

export const MultipleElementsContainer = styled.div`
  background-color: #e9e9e9;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media screen and (max-width: 768px) {
    padding: 0 0 15px;
    justify-content: flex-start;
  }
`;

export const initialState = {
  results: {
    loading: false,
    error: false,
    analyzers: [],
  },
  script: {
    loading: false,
    error: false,
    questions: [],
  },
};

import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import {
  BootDropdown,
  BootDropdownToogle,
  BootDropdownMenu,
  FilterModalHeader,
  FiltersModalBody,
  FiltersModalFooter,
  FiltersTagTypes,
  FiltersTagWrapper,
  FilterTagTitle,
  FiltersTagContainer,
  FilterTag,
  ActionContainer,
  ActionButton,
  CheckBoxContainer,
  Label,
  CheckBox,
  CheckBoxWrapper,
  CheckBoxLabel,
} from "./styled";
import { TooltipText } from "../Tooltip";
import { TOOLTIPS } from "constants/constants";

class GenericFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: this.getSelectedFiltersFromProps(props),
      visible: false,
    };
  }
  ref = createRef();

  getSelectedFiltersFromProps = (props) => {
    const selectedFilters = { ...props.selectedFilters };
    return selectedFilters;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedFilters !== prevProps.selectedFilters) {
      const selectedFilters = this.getSelectedFiltersFromProps(this.props);
      this.setState({ selectedFilters });
    }
  };

  setFilter = (filterKey, tag, singleSelect) => {
    const filterState = singleSelect
      ? {}
      : { ...this.state.selectedFilters[filterKey] };

    const isSelected = filterState[tag.key];
    isSelected ? delete filterState[tag.key] : (filterState[tag.key] = tag);

    const selectedFilters = {
      ...this.state.selectedFilters,
      [filterKey]: filterState,
    };

    this.setState({ ...this.state, selectedFilters: selectedFilters });
  };

  componentDidMount = () => {
    document.body.addEventListener("click", this.onBodyClick);
  };

  componentWillUnmount = () => {
    document.body.removeEventListener("click", this.onBodyClick);
  };

  onBodyClick = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.onHide();
    }
  };

  toggleSegregation = () => {
    this.setState({
      ...this.state,
      selectedFilters: {
        ...this.state.selectedFilters,
        condition: this.state.selectedFilters?.condition == "or" ? "and" : "or",
      }
    })
  };

  onHide = () => this.setState({ visible: false });

  applyFilter = () => {
    this.props.applyFilter(this.state.selectedFilters);
    this.setState({ visible: false });
  };

  resetFilter = () => {
    const keys = this.props.filterSet.filters.map((f) => f.key);
    this.props.resetFilter(keys);
    this.setState({ visible: true });
  };

  render = () => {
    const { filterSet, Icon, noScroll, t } = this.props;
    if (!filterSet?.filters.length) return null;

    return (
      <>
        <BootDropdown
          ref={this.ref}
          show={this.state.visible}
          id={this.props.id}
        >
          <BootDropdownToogle
            className="option tooltipRel"
            id={`${this.props.id}-dropdown`}
            onClick={() => this.setState({ visible: !this.state.visible })}
            alignEnd
          >
            <Icon />
            {!this.state.visible && this.props.tooltip && (
              <TooltipText
                tooltipText={this.props.tooltip}
                align="bottomLeft"
              />
            )}
          </BootDropdownToogle>
          {this.state.visible && filterSet && (
            <BootDropdownMenu x-placement="bottom-left">
              {filterSet.title && filterSet.title === "Question Filter" ? (
                <CheckBoxContainer>
                  <div className="tooltipRel">
                    <Label>
                      {t("Aggregate")}
                      <TooltipText
                        tooltipText={t(TOOLTIPS.AGGREGATE_FILTER)}
                        align="bottomLeft"
                      />
                    </Label>
                  </div>
                  <CheckBoxWrapper>
                    <CheckBox
                      id="question-filter-checkbox"
                      type="checkbox"
                      checked={this.state.selectedFilters?.condition == "and"}
                      onClick={this.toggleSegregation}
                    />
                    <CheckBoxLabel htmlFor="checkbox" onClick={this.toggleSegregation} />
                  </CheckBoxWrapper>
                  <div className="tooltipRel">
                    <Label>
                      {t("Segregate")}
                      <TooltipText
                        tooltipText={t(TOOLTIPS.SEGREGATE_FILTER)}
                        align="bottomRight"
                      />
                    </Label>
                  </div>
                </CheckBoxContainer>
                ) : (
                  <FilterModalHeader>{t(filterSet.title)}</FilterModalHeader>
                )
             }
              <FiltersModalBody>
                <FiltersTagTypes noScroll={noScroll}>
                  {filterSet.filters.map((filter, i) => {
                    const FilterIcon = filter.icon;
                    return (
                      <FiltersTagWrapper key={i}>
                        {filter.title && (
                          <FilterTagTitle>{filter.title}</FilterTagTitle>
                        )}
                        {filter.icon && (
                          <FilterTagTitle>
                            <div className="tooltipRel d-inline">
                              <FilterIcon />
                              {filter.tooltip && (
                                <TooltipText
                                  tooltipText={filter.tooltip}
                                  align="bottomLeft"
                                />
                              )}
                            </div>
                          </FilterTagTitle>
                        )}
                        <FiltersTagContainer>
                          {filter.tags.map((tag) => {
                            const isSelected =
                              this.state.selectedFilters[filter.key]?.[tag.key];
                            return (
                              <FilterTag
                                key={tag.key}
                                onClick={() =>
                                  this.setFilter(
                                    filter.key,
                                    tag,
                                    filter.singleSelect,
                                  )
                                }
                                selected={isSelected}
                              >
                                {tag.title}
                              </FilterTag>
                            );
                          })}
                        </FiltersTagContainer>
                      </FiltersTagWrapper>
                    );
                  })}
                </FiltersTagTypes>
              </FiltersModalBody>
              <FiltersModalFooter>
                <ActionContainer>
                  <ActionButton
                    onClick={this.resetFilter}
                    className="btn btn-primary"
                  >
                    {t("resetAll")}
                  </ActionButton>
                  <ActionButton
                    onClick={this.applyFilter}
                    className="btn btn-primary apply"
                  >
                    {t("apply")}
                  </ActionButton>
                </ActionContainer>
              </FiltersModalFooter>
            </BootDropdownMenu>
          )}
        </BootDropdown>
      </>
    );
  };
}

export default withTranslation()(GenericFilter);

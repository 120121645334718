import { initialState } from "../initialState/initialState";
import * as constants from "../constants/constants";

const groupsReducer = (state = initialState.groups, action) => {
  switch (action.type) {
    case constants.STORE_GROUPS:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default groupsReducer;

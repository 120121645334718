import { initialState } from "initialState/initialState";
import {
  setFilters,
  getFilters,
  getQueryString,
} from "utils/filtersHelperFuncs";
import * as constants from "constants/constants";

const divisionLevelReducer = (
  state = getFilters() || initialState.selectedFilters,
  action,
) => {
  let newState = initialState;
  switch (action.type) {
    case constants.SET_FILTER:
      newState = Object.assign({}, state, action.filter);
      onFiltersUpdate(newState);
      return newState;

    case constants.SET_GENERIC_FILTERS: {
      const { filters } = action.payload;
      newState = { ...state, ...filters };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.RESET_GENERIC_FILTERS: {
      const { keys } = action.payload;
      newState = { ...state };
      keys.forEach((key) => {
        newState[key] = {};
      });
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.TOGGLE_GENERIC_TAG: {
      const { filterKey, tag } = action.payload;
      const filterState = { ...state[filterKey] };
      filterState[tag.key]
        ? delete filterState[tag.key]
        : (filterState[tag.key] = tag.key);
      newState = { ...state, [filterKey]: filterState };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.RESET_GENERIC_TAGS: {
      const { filterKey, tags } = action.payload;
      const filter = { ...state[filterKey] };
      tags.forEach((tag) => {
        delete filter[tag];
      });
      newState = { ...state, [filterKey]: filter };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.SET_DATE_TIME_FILTER: {
      const { startDate, endDate, dateFilterType, defaultDateRange } =
        action.payload;
      newState = {
        ...state,
        startDate,
        endDate,
        dateFilterType,
        defaultDateRange,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.SET_PRESET_FILTER: {
      const { months, years } = action.payload;
      newState = {
        ...state,
        months,
        years,
        dateFilterType: constants.DateFilterType.PRESET,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.SET_SHIFT_FILTER: {
      const { startTime, endTime, days } = action.payload;
      newState = {
        ...state,
        days,
        endTime,
        startTime,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.RESET_SHIFT_FILTER: {
      newState = {
        ...state,
        days: initialState.selectedFilters.days,
        endTime: initialState.selectedFilters.endTime,
        startTime: initialState.selectedFilters.startTime,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.SELECT_DASHBOARD: {
      const { dashboard, isSwitch } = action.payload;
      let questionnaires = { [dashboard.id]: true };
      let groups = { [dashboard.group]: true };
      if (dashboard.id === "omni") {
        if (isSwitch) {
          questionnaires = {};
          groups = {};
        } else {
          questionnaires = state.questionnaires;
          groups = state.groups;
        }
      }
      newState = {
        ...state,
        groups,
        questionnaires,
        concern: null,
        feedback_level: null,
        options: isSwitch ? null : state.options,
        locations: isSwitch ? null : state.locations,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.SELECT_INSIGHT_CONCERN:
      newState = { ...state, concern: action.payload.concern.id };
      onFiltersUpdate(newState);
      return newState;

    case constants.SELECT_INSIGHT_CHILD_CONCERN:
      newState = { ...state, concern: action.payload.concern.id };
      onFiltersUpdate(newState);
      return newState;

    case constants.RESET_SELECTED_CONCERN:
      newState = { ...state, concern: null };
      onFiltersUpdate(newState);
      return newState;

    case constants.SELECT_RACKS:
      newState = {
        ...state,
        concern: null,
        feedback_level: null,
        likes: null,
        search: "",
        analysisType: null,
        transactionAnalysisType: null,
      };
      onFiltersUpdate(newState);
      return newState;

    case constants.SET_DIVISION_LEVEL:
      newState = Object.assign({}, state, {
        division: action.division,
        level: action.level,
      });
      onFiltersUpdate(newState);
      return newState;

    case constants.SET_ANALYSIS_TYPE:
      newState = {
        ...state,
        analysisType: action.analysisType,
      };
      onFiltersUpdate(newState, false);
      return newState;

    case constants.SET_FEEDBACK_FILTER:
      newState = {
        ...state,
        [action.payload.filterType]: action.payload.filter,
      };
      onFiltersUpdate(newState);
      return newState;

    case constants.SEARCH_QUERY:
      newState = {
        ...state,
        search: action.search,
      };
      // NOTE: don't set filters on this action, it is fired when searchbox is umounted which causes crash on re-login because of null feedback group.
      onFiltersUpdate(newState, false);
      return newState;

    case constants.CLEAR_LOCATIONS:
      newState = Object.assign({}, state, {
        locations: [],
      });
      onFiltersUpdate(newState);
      return newState;

    case constants.CLEAR_SELECTED_FILTERS: {
      const { isOmni, startDate, endDate } = action.payload;
      const questionnaires = isOmni
        ? initialState.questionnaires
        : state.questionnaires;
      const groups = isOmni ? initialState.groups : state.groups;
      const { analysisType, transactionAnalysisType } = state;
      newState = {
        ...initialState.selectedFilters,
        startDate,
        endDate,
        questionnaires,
        groups,
        analysisType,
        transactionAnalysisType,
      };
      onFiltersUpdate(newState);
      return newState;
    }

    case constants.CLEAR_ACTION:
      newState = Object.assign({}, state, {
        actionType: null,
        sortBy: initialState.selectedFilters.sortBy,
      });
      onFiltersUpdate(newState);
      return newState;

    case constants.SET_FEEDBACK_LEVEL_FILTER:
      newState = {
        ...state,
        feedback_level: action.value,
        concern: null,
      };
      onFiltersUpdate(newState);
      return newState;

    case constants.SET_LIKES_FILTER:
      newState = {
        ...state,
        likes: action.payload.likes,
        concern: action.payload.likes ? null : action.payload.resetConcern.id,
      };
      onFiltersUpdate(newState);
      return newState;

    default:
      return state;
  }
};

export default divisionLevelReducer;

const onFiltersUpdate = (filters, cache = true) => {
  filters.query = getQueryString(filters);
  if (cache) setFilters(filters);
};

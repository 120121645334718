import React, { Component } from "react";
import Spinner from "react-spinkit";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { clearSelectedFeedback } from "actions/feedback/selectedFeedback";
import { loadNextPageSelectedFeedback } from "components/FeedbackPage/actions";
import {
  ScrollMenu,
  VisibilityContext,
  getItemsPos,
} from "react-horizontal-scrolling-menu";
import FeedbackCard from "./FeedbackCard";
import "./FeedbackDetailList.css";
import * as Styled from "./styled";

class FeedbackDetailList extends Component {
  state = { selected: [] };

  componentWillUnmount() {
    this.props.clearSelectedFeedback();
  }

  updateSelected(itemSelected, id) {
    this.setState((state) => ({
      selected: itemSelected ? state.selected : [id],
    }));
  }

  resetSelected() {
    this.setState((state) => ({
      selected: [],
    }));
  }

  render() {
    const { user, selectedFeedbacks } = this.props;
    const { selected } = this.state;
    const { loadNextPageSelectedFeedback } = this.props;
    const { t } = this.props;

    const isItemSelected = (id) => {
      if (selected.length != 0) return !!selected.find((el) => el === id);
      else {
        this.updateSelected(false, id);
      }
    };

    const handleClick = (id) => () => {
      const itemSelected = isItemSelected(id);
      this.updateSelected(itemSelected, id);
    };

    const onUpdate = ({ isLastItemVisible }) => {
      if (
        isLastItemVisible &&
        selectedFeedbacks.next &&
        !selectedFeedbacks.isMoreSelectedFeedbackLoading
      ) {
        loadNextPageSelectedFeedback(selectedFeedbacks.next);
      }
    };

    if (selectedFeedbacks.isLoading) {
      if (selected.length != 0) {
        this.resetSelected();
      }
      return (
        <div className="col-md-8" style={{ height: "70vh" }}>
          <Spinner name="wandering-cubes" fadeIn="none" style={{ zIndex: 5 }} />
        </div>
      );
    }
    const isNotFound =
      !selectedFeedbacks.data.length && !selectedFeedbacks.isLoading;
    if (isNotFound) {
      return (
        <div className="no-selection col-md-8" style={{ height: "70vh" }}>
          <h2>
            <i className="fa fa-exclamation-triangle" />
            {t("selectFeedbackFromList")}
          </h2>
        </div>
      );
    }
    return (
      <Col
        sm={12}
        md={
          this.props.fullScreen ? (this.props.isFromNotification ? 9 : 12) : 8
        }
      >
        {selectedFeedbacks.isMoreSelectedFeedbackLoading && (
          <div
            style={{
              position: "absolute",
              right: "3%",
              bottom: this.props.isFromNotification ? "105%" : "106%",
            }}
          >
            <Styled.SmallSpinner
              name="wandering-cubes"
              fadeIn="none"
              style={{ zIndex: 5 }}
            />
          </div>
        )}
        <ScrollMenu //Css of Scroll Container div is also modified in app.scss by the name of
          onUpdate={onUpdate} //(.react-horizontal-scrolling-menu--scroll-container)
          itemClassName={
            selectedFeedbacks.data.length == 1
              ? "horizontal-scroll-item-full-screen"
              : "horizontal-scroll-item"
          }
          wrapperClassName={"horizontal-scroll-wrapper"}
        >
          {selectedFeedbacks.data.map((selectedFeedback) => (
            <FeedbackCard
              selectedFeedback={selectedFeedback}
              user={user}
              itemId={selectedFeedback.id}
              key={selectedFeedback.id}
              isDetailPage={this.props.isDetailPage}
              fullScreen={this.props.fullScreen}
              isFromNotification={this.props.isFromNotification}
              onClick={handleClick(selectedFeedback.id)}
              selected={isItemSelected(selectedFeedback.id)}
            />
          ))}
        </ScrollMenu>
      </Col>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  selectedFeedbacks: state.feedback.selectedFeedback,
});

const mapDispatchToProps = {
  clearSelectedFeedback,
  loadNextPageSelectedFeedback,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FeedbackDetailList));

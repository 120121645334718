export const initialState = {
  feedbackList: {
    isLoading: false,
    isMoreFeedbackLoading: false,
    isError: false,
    data: [],
    nextUrl: null,
  },
  selectedFeedback: {
    isLoading: false,
    isMoreSelectedFeedbackLoading: false,
    isRemarksLoading: false,
    isChatMessagesLoading: false,
    isError: false,
    next: "",
    previous: "",
    data: [],
  },
  unprocessedStats: {
    isLoading: false,
    count: 0,
  },
};

import React from "react";
import NPS from "./NPS/NPS";
import GenderGroupAnalysis from "./GenderGroupAnalysis/GenderGroupAnalysis";
import Benchmarks from "./Benchmarks/Benchmarks";
import CustomerSegmentation from "./CustomerSegmentation/CustomerSegmentation";
import SatisfactionLevel from "./SatisfactionLevel/SatisfactionLevel";
import CompetitorAnalysis from "./CompetitorAnalysis/CompetitorAnalysis";
import PriorityList from "./PriorityList/PriorityList";
import TimeSeriesGraph from "./TimeSeries/";
import SemanticAnalysis from "./SemanticAnalysis/SemanticAnalysis";
import WordCloud from "../common/WordCloud/";
import { ProjectsOverviewTable } from "./ProjectOverviewTable";
import TopConcerns from "./TopConcerns/TopConcerns";
import { Card, CardValue } from "components/common/Card";
import Comments from "../common/Comments";
import { MediaSlider } from "./Slider";
import ConcernTrend from "components/InsightsPage/Insights/ConcernTrend";
import ConcernDetail from "components/InsightsPage/Insights/ConcernDetail";

const DummyAnalyzer = () => {
  return (
    <Card heading="WIP">
      <CardValue suffix="!" caption="Work in Progress" />
    </Card>
  );
};

export const Analyzers = {
  nps_chart: {
    component: NPS,
    permission: "api_net_promoters_score",
  },
  time_series_analysis: {
    component: TimeSeriesGraph,
    permission: "api_time_series",
  },
  csat: {
    component: SatisfactionLevel,
    permission: "api_satisfaction",
  },
  competitor_analysis: {
    component: CompetitorAnalysis,
    permission: "api_competitor_analysis",
  },
  customer_segmentation: {
    component: CustomerSegmentation,
    permission: "api_customer_segmentation",
  },
  priority_list: {
    component: PriorityList,
    permission: "api_priority_list",
  },
  semantic_analyzer: {
    component: SemanticAnalysis,
    permission: "api_semantic_analysis",
  },
  feedback_option_media: {
    component: MediaSlider,
    permission: "api_feedback_option_media",
  },
  concern_stats_trend: { component: ConcernTrend },
  concern_stats_detail: { component: ConcernDetail },
  top_keywords: { component: WordCloud },
  top_positive_concerns: { component: DummyAnalyzer },
  top_negative_concerns: { component: DummyAnalyzer },
  feedback_comments_list: {
    component: Comments,
    permission: "api_comments",
  },
  questionnaire_stats: {
    component: ProjectsOverviewTable,
    permission: "api_questionnaire_stats",
  },
};

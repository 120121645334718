import React from "react";

export const Header = ({ organization }) => {
  const logoFeatures = organization.features.dashboard.logo,
    logo = organization.logo_landscape;
  return (
    <header id="header" className="responsive-header tl visible">
      <div className="logo-container logo-wrapper setup-profile-panel-logo">
        <img className="" src={logo} width={`${logoFeatures.topbar}%`} alt="" />
      </div>
    </header>
  );
};

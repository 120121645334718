import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

import {
  SliderWrapper,
  SliderLabel,
  SliderButton,
  SliderIcon,
  ViewFeedbackLabel,
} from "./styled";
import { NoDataFound } from "components/common/NoDataFound";
import {
  CardBox,
  CardWrapper,
  CardHeader,
  CardHeading,
  CardBody,
} from "components/common/Card";
import { TooltipText } from "components/common/Tooltip";
import { TOOLTIP_MESSAGE } from "constants/constants";
import SkeletonSlider from "components/common/Skeleton/SkeletonSlider";
import { useNavigate } from "react-router-dom";

export const Slider = ({ media, selectedFilters, loadMedia }) => {
  const { t } = useTranslation();
  const { isLoading, isError, data } = media;
  const [isOverlay, setOverlay] = useState(false);
  const [autoPlay, setAutoPlay] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    loadMedia();
  }, [selectedFilters]);

  const handleAutoPlay = () => {
    setAutoPlay(!autoPlay);

    setTimeout(() => {
      setOverlay(false);
    }, 500);
  };

  const handleOnViewFeedbackClick = () => {
    navigate(
      `/feedback/customer-recovery/${data.results[currentImageIndex].feedback_encoded_url}`,
    );
  };

  return (
    <CardBox className="ui-card">
      <CardWrapper className="ui-card-wrapper">
        <CardHeader className="ui-card-header">
          <CardHeading className="ui-card-heading tooltipRel">
            {t("pictureSlider")}
            <TooltipText tooltipText={TOOLTIP_MESSAGE.PICTURE_SLIDER} />
          </CardHeading>
        </CardHeader>
        <CardBody className="ui-card-body">
          {isLoading ? (
            <SkeletonSlider />
          ) : isError ? (
            <NoDataFound />
          ) : (
            <>
              <SliderWrapper
                onMouseEnter={() => setOverlay(true)}
                onMouseLeave={() => setOverlay(false)}
              >
                <Carousel
                  showThumbs={false}
                  autoPlay={autoPlay}
                  infiniteLoop={true}
                  swipeable={true}
                  transitionTime={2}
                  onChange={(index) => setCurrentImageIndex(index)}
                >
                  {data.results.map((media) => (
                    <React.Fragment>
                      <img src={media.attachment} />
                      <SliderLabel>{media.concern_name}</SliderLabel>
                    </React.Fragment>
                  ))}
                </Carousel>
                <SliderButton show={isOverlay} onClick={handleAutoPlay}>
                  <SliderIcon
                    className={`fa ${autoPlay ? "fa-pause" : "fa-play"}`}
                  />
                </SliderButton>
              </SliderWrapper>
            </>
          )}
        </CardBody>
        <ViewFeedbackLabel onClick={handleOnViewFeedbackClick}>
          {t("viewFeedback")}
        </ViewFeedbackLabel>
      </CardWrapper>
    </CardBox>
  );
};

import { putRequest } from "utils/request";
import { UNAUTH_USER } from "constants/constants";
import { getOrganizationSlug } from "utils/genericHelper";

const updatePassword = (params) => {
  return (dispatch) => {
    const url = "v1/users/change-password";
    return putRequest(url, params).then((response) => {
      if (response?.status && response.status !== 200) {
        return response.data.non_field_errors[0];
      }
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("roleTitle");
      localStorage.removeItem("roleAbbreviation");
      localStorage.removeItem(`${getOrganizationSlug()}_tokenExpiresAt`);
      localStorage.removeItem(`${getOrganizationSlug()}_token`);

      dispatch({ type: UNAUTH_USER });
    });
  };
};

export { updatePassword };

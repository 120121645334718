import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setFormFieldValue } from "components/RCA/actions";

import { formFieldValueSelector } from "components/RCA/selectors";
import {
  Wrapper,
  Input,
  SubCauseDiv,
  Para,
  Span,
  Container,
  ButtonWrapper,
  NextButton,
  ToolTip,
} from "./styled";

import { FloatingLabel } from "components/common/FloatingLabel";

import { keys } from "lodash";

import { MAX_ROOT_CAUSES_DEPTH } from "constants/constants";

const RootCauseSection = ({ handleNext, isError }) => {
  const dispatch = useDispatch();

  const [topLevelConcernIDs, setTopLevelConcernIDs] = useState([1]);

  const selectedRootCauses = useSelector((state) =>
    formFieldValueSelector(state, "root_causes"),
  ) || {
    1: {
      cause: "",
      rootCauses: [],
    },
  };

  const [causes, setCauses] = useState(selectedRootCauses);
  const [focusedCauses, setFocusedCauses] = useState({});

  const selectedConcern = useSelector((state) =>
    formFieldValueSelector(state, "concern"),
  );
  const selectedOtherConcern = useSelector((state) =>
    formFieldValueSelector(state, "other_concern"),
  );

  const selectedConcernName =
    selectedConcern?.name || selectedOtherConcern || "";

  useEffect(() => {
    const _causes = { ...causes, ...selectedRootCauses };

    const _topLevelConcernIDs = keys(_causes)
      .filter((concernID) => concernID.split(".").length === 1)
      .map((ID) => Number(ID));

    setTopLevelConcernIDs(_topLevelConcernIDs);
    dispatch(setFormFieldValue("root_causes", causes));
  }, [causes]);

  useEffect(() => {
    let _focusedCauses = {};
    let isFocused = true;
    keys(causes).map((causeID) => {
      _focusedCauses[causeID] = isFocused && !causes[causeID].cause;
      if (!causes[causeID].cause) {
        isFocused = causes[causeID].cause;
      }
    });
  }, []);

  const handleSubmit = () => {
    dispatch(setFormFieldValue("root_causes", causes));
    handleNext();
  };

  const addSubInputElement = (event) => {
    const currentCause = event.target.name;
    const causeValue = event.target.value;
    const totalConcerns = currentCause.split(".");
    const causesDepth = totalConcerns.length;
    const lastLevelConcern = totalConcerns[causesDepth - 1];
    const newConcern = causesDepth > 1 ? null : Number(lastLevelConcern) + 1;

    let newCauses;
    if (
      causeValue != "" &&
      causesDepth < MAX_ROOT_CAUSES_DEPTH &&
      !topLevelConcernIDs.includes(Number(currentCause) + 1)
    ) {
      newCauses = {
        ...causes,
        [currentCause]: {
          cause: causeValue,
          rootCauses: [`${currentCause}.1`],
        },
        [`${currentCause}.1`]: {
          cause: "",
          rootCauses: [],
        },
      };
      if (newConcern) {
        newCauses[`${newConcern}`] = {
          cause: "",
          rootCauses: [],
        };
      }
      if (totalConcerns <= 1) {
        const lastConcern = topLevelConcernIDs[causesDepth - 1];
        setTopLevelConcernIDs([...topLevelConcernIDs, lastConcern + 1]);
      }
      setCauses(newCauses);
    }
    let _focusedCauses = {};
    let isFocused = true;
    keys(newCauses).map((causeID) => {
      if (causeID.split(".")[0] === currentCause.split(".")[0]) {
        _focusedCauses[causeID] = isFocused && !newCauses[causeID].cause;
        if (!newCauses[causeID].cause) {
          isFocused = newCauses[causeID].cause;
        }
      } else {
        _focusedCauses[causeID] = false;
      }
    });
    setFocusedCauses(_focusedCauses);
  };

  const handleChange = (event) => {
    const concernID = event.target.name;
    setCauses({
      ...causes,
      [concernID]: {
        ...causes[concernID],
        cause: event.target.value,
      },
    });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      e.target.blur();
    }
  };
  const createRootCauses = (rootCauses, marginLeft) => {
    if (!rootCauses.length) {
      return [];
    }

    let subInputElement;
    let rootCausesInputElements = [];
    let subRootCauses;
    marginLeft += 1;

    rootCauses.forEach((cause) => {
      subInputElement = (
        <SubCauseDiv marginLeft={marginLeft}>
          <Input
            type="text"
            name={`${cause}`}
            id={`${cause}`}
            onBlur={addSubInputElement}
            placeholder={`Add Cause ${cause}`}
            onChange={handleChange}
            value={causes[cause].cause}
            marginLeft={marginLeft}
            onKeyDown={handleKeyDown}
            autoFocus={focusedCauses[cause]}
          />
        </SubCauseDiv>
      );
      rootCausesInputElements.push(subInputElement);

      subRootCauses = causes[cause]?.rootCauses || [];

      let subRootCauseElements;

      if (subRootCauses.length) {
        subRootCauseElements = createRootCauses(subRootCauses, marginLeft);
      }

      rootCausesInputElements =
        rootCausesInputElements.concat(subRootCauseElements);
    });
    return rootCausesInputElements;
  };

  const createCauses = () => {
    let inputElements = [];

    topLevelConcernIDs.forEach((concernID) => {
      let marginLeft = 1;
      const rootCauses = causes[concernID]["rootCauses"];
      const topLevelInputElement = (
        <Container marginLeft={marginLeft}>
          <Input
            type="text"
            name={`${concernID}`}
            id={`${concernID}`}
            onBlur={addSubInputElement}
            placeholder={`Add Cause ${concernID}`}
            onChange={handleChange}
            value={causes[concernID].cause}
            marginLeft={marginLeft}
            onKeyDown={handleKeyDown}
            autoFocus={focusedCauses[concernID]}
          />
        </Container>
      );
      inputElements.push(topLevelInputElement);

      let subElements;
      subElements = createRootCauses(rootCauses, marginLeft);

      inputElements = inputElements.concat(subElements);
    });
    return inputElements;
  };

  return (
    <Wrapper>
      <FloatingLabel
        label="Problem to solve"
        width="250px"
        readOnly={true}
        value={selectedConcernName}
      />
      <Para>
        Add the root causes for <Span>{selectedConcernName}</Span> concern
      </Para>
      {createCauses()}
      <ButtonWrapper>
        <NextButton disabled={isError} onClick={handleSubmit}>
          Next
          {isError ? (
            <ToolTip>
              You have to add atleast one cause with three root causes
            </ToolTip>
          ) : (
            ""
          )}
        </NextButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

const ENTER_KEY_CODE = 13;

export default RootCauseSection;

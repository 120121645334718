export const LOAD_FEEDBACK_LIST = "LOAD_FEEDBACK_LIST",
  LOAD_FEEDBACK_LIST_SUCCESS = "LOAD_FEEDBACK_LIST_SUCCESS",
  LOAD_FEEDBACK_LIST_ERROR = "LOAD_FEEDBACK_LIST_ERROR",
  LOAD_UNPROCESSED_STATS = "LOAD_UNPROCESSED_STATS",
  LOAD_UNPROCESSED_STATS_SUCCESS = "LOAD_UNPROCESSED_STATS_SUCCESS",
  LOAD_UNPROCESSED_STATS_ERROR = "LOAD_UNPROCESSED_STATS_ERROR",
  LOAD_MORE_FEEDBACKS = "LOAD_MORE_FEEDBACKS",
  LOAD_MORE_FEEDBACKS_SUCCESS = "LOAD_MORE_FEEDBACKS_SUCCESS",
  LOAD_MORE_FEEDBACKS_ERROR = "LOAD_MORE_FEEDBACKS_ERROR",
  LOAD_SELECTED_FEEDBACK = "LOAD_SELECTED_FEEDBACK",
  LOAD_SELECTED_FEEDBACK_SUCCESS = "LOAD_SELECTED_FEEDBACK_SUCCESS",
  LOAD_SELECTED_FEEDBACK_ERROR = "LOAD_SELECTED_FEEDBACK_ERROR",
  LOAD_MORE_SELECTED_FEEDBACK = "LOAD_MORE_SELECTED_FEEDBACK",
  LOAD_NEXT_PAGE_SELECTED_FEEDBACK = "LOAD_NEXT_PAGE_SELECTED_FEEDBACK",
  LOAD_MORE_SELECTED_FEEDBACK_SUCCESS = "LOAD_MORE_SELECTED_FEEDBACK_SUCCESS",
  LOAD_MORE_SELECTED_FEEDBACK_ERROR = "LOAD_MORE_SELECTED_FEEDBACK_ERROR",
  REMOVE_SELECTED_FEEDBACK = "REMOVE_SELECTED_FEEDBACK",
  LOAD_REMARKS = "LOAD_REMARKS",
  LOAD_REMARKS_SUCCESS = "LOAD_REMARKS_SUCCESS",
  LOAD_REMARKS_ERROR = "LOAD_REMARKS_ERROR",
  SUBMIT_REMARKS = "SUBMIT_REMARKS",
  SUBMIT_REMARKS_SUCCESS = "SUBMIT_REMARKS_SUCCESS",
  SUBMIT_REMARKS_ERROR = "SUBMIT_REMARKS_ERROR",
  POST_FEEDBACK_ACTION = "POST_SELECTED_FEEDBACK_ACTION",
  POST_FEEDBACK_LIST_ITEM_ACTION = "POST_FEEDBACK_LIST_ITEM_ACTION",
  CLEAR_SELECTED_FEEDBACK = "CLEAR_SELECTED_FEEDBACK",
  LOAD_CHAT_MESSAGES = "LOAD_CHAT_MESSAGES",
  LOAD_CHAT_MESSAGES_SUCCESS = "LOAD_CHAT_MESSAGES_SUCCESS",
  LOAD_CHAT_MESSAGES_ERROR = "LOAD_CHAT_MESSAGES_ERROR",
  POST_CHAT_MESSAGE = "POST_CHAT_MESSAGE",
  POST_CHAT_MESSAGE_SUCCESS = "POST_CHAT_MESSAGE_SUCCESS",
  POST_CHAT_MESSAGE_ERROR = "POST_CHAT_MESSAGE_ERROR",
  TOGGLE_FEEDBACK_TAG = "TOGGLE_FEEDBACK_TAG";

export const CUSTOMER_MESSAGE_TYPES = [
  {
    key: "One Way",
    value: 1,
  },
  {
    key: "Two Way",
    value: 2,
  },
];

import { call, takeLatest, select, put } from "redux-saga/effects";
import { setAnalyticsValue } from "utils/analytics";
import * as actions from "./actions";
import * as apis from "./apis";
import * as constants from "../constants/constants";
import { getOrganizationSlug } from "../utils/genericHelper";
import {
  getOrganizationId,
  selectOrganization,
} from "./Organization/selectors";
import { RGB_Log_Shade } from "./Organization/helper";
import { setDateTimeFilter } from "components/DashboardPage/actions";
import { DateFilterType } from "constants/constants";
import { getDateRange } from "utils/filtersHelperFuncs";
import { toggleFeedbackTag } from "components/FeedbackPage/actions";

const setDocumentColors = (colors) => {
  const body = document.querySelector("html");
  if (colors.primary) {
    body.style.setProperty("--primary-color", colors.primary);

    body.style.setProperty(
      "--primary-color-light",
      RGB_Log_Shade(0.75, colors.primary),
    );
  }
  if (colors.secondary) {
    body.style.setProperty("--secondary-color", colors.secondary);

    body.style.setProperty(
      "--secondary-color-light",
      RGB_Log_Shade(0.75, colors.secondary),
    );
  }
  if (colors.darker) {
    body.style.setProperty("--darker", colors.darker);
  }
  if (colors.text) {
    body.style.setProperty("--text-color", colors.text);
  }
  body.style.setProperty(
    "--level1Rack-bgcolor",
    colors.level1RackBackground || "#e0e0e0",
  );
  body.style.setProperty(
    "--level2Rack-bgcolor",
    colors.level2RackBackground || "#eaeaea",
  );
  body.style.setProperty(
    "--level1Rack-text",
    colors.level1RackText || colors.primary,
  );
  body.style.setProperty(
    "--level2Rack-text",
    colors.level2RackText || colors.primary,
  );
  body.style.setProperty(
    "--level2Rack-highlight",
    colors.level2RackHighlight || colors.primary,
  );
  body.style.setProperty(
    "--filters-color",
    colors.filtersColor || colors.primary,
  );
};

function* loadOrganizationDetail() {
  try {
    const slug = getOrganizationSlug();
    const organization = yield call(apis.organizationApi, slug);
    localStorage.setItem(`${slug}_organizationId`, organization.id);
    setDocumentColors(organization.features.colors);

    yield put(actions.getOrganizationSuccess(organization));
    yield setInitialDateFilters();
    setAnalyticsValue({ metric1: organization.id });
    setAnalyticsValue({ dimension1: organization.id });
  } catch (error) {
    yield put(actions.getOrganizationError(error));
  }
  yield put(actions.loadConcerns());
}

export function* setInitialDateFilters() {
  const organization = yield select(selectOrganization);
  const { dateFilterType } = yield select((state) => state.selectedFilters);
  if (!dateFilterType) {
    const dateConfig = getInitialDateConfig(
      organization.features.dashboard?.date_range,
    );
    yield put(setDateTimeFilter(...dateConfig));
  }
}

const getInitialDateConfig = (range) => {
  const defaultDateRange = range || "Last 7 days";
  const [startDate, endDate] = getDateRange(range);
  return [startDate, endDate, DateFilterType.CUSTOM, defaultDateRange];
};

function* organizationWatcher() {
  yield takeLatest(constants.LOAD_ORGANIZATION, loadOrganizationDetail);
}

function* loadOrganizationConcerns() {
  try {
    const organizationId = yield select(getOrganizationId);
    const concerns = yield call(apis.getOrganizationConcerns, organizationId);
    yield put(actions.loadConcernsSuccess(concerns));
  } catch (error) {
    yield put(actions.loadConcernsError(error));
  }
}

function* organizationConcernsWatcher() {
  yield takeLatest(constants.LOAD_CONCERNS, loadOrganizationConcerns);
}

function* loadOrganizationTags() {
  try {
    const data = yield call(apis.loadOrganizationTagsApi);
    yield put(actions.loadOrganizationTagsSuccess(data.results));
  } catch (error) {
    //
  }
}

function* organizationTagsWatcher() {
  yield takeLatest(constants.LOAD_ORGANIZATION_TAGS, loadOrganizationTags);
}

function* createOrganizationTag(action) {
  try {
    const data = yield call(apis.createOrganizationTagApi, action.payload.tag);
    if (data.status) throw "Request error";
    yield put(
      actions.createOrganizationTagSuccess(data, action.payload.feedback),
    );
    yield put(toggleFeedbackTag(action.payload.feedback, data));
  } catch (error) {
    yield put(actions.createOrganizationTagError(action.payload.feedback));
  }
}
function* organizationCreateTagWatcher() {
  yield takeLatest(constants.CREATE_ORGANIZATION_TAG, createOrganizationTag);
}

export const sagas = [
  organizationWatcher,
  organizationConcernsWatcher,
  organizationTagsWatcher,
  organizationCreateTagWatcher,
];

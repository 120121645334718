import styled from "styled-components";

export const Link = styled.div`
  color: ${(props) =>
    props.level1Rack ? "var(--level1Rack-text)" : "var(--level2Rack-text)"};
  font-size: 1em;
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
  padding: 15px;
  margin: 0 20px;
  font-weight: normal;
  cursor: pointer;

  &.selected,
  &:hover {
    color: white;
    background-color: var(--level2Rack-highlight);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &:nth-child(2) {
    z-index: 4;
  }

  &:nth-child(odd) {
    background-color: var(--level1Rack-bgcolor);
  }

  &:nth-child(even) {
    background-color: var(--level2Rack-bgcolor);
  }

  &:first-child ${Link} {
    &.selected,
    &:hover {
      color: var(--level1Rack-text);
      background-color: transparent;
    }
    &.selected {
      font-weight: bold;
    }
  }
`;

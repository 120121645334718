import React, { useState, useEffect, useRef } from "react";
import {
  SelectWrapper,
  Select,
  Label,
  Text,
  DropdownMenu,
  MenuItem,
  DropdownMenuLabel,
  DropdownMenuItemList,
  MenuItemValue,
} from "./styled";

export const CustomDropdown = ({
  options,
  selectedOption,
  onClick,
  label,
  menuLabel,
  getLabel,
  getType,
  getKey,
  getStatus,
  onToggle,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
        onToggle && onToggle(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  const renderedOptions = options.map((option) => {
    const type = getType && !option.hideType && getType(option);
    const label = getLabel(option);
    const key = getKey(option);
    const selected =
      selectedOption && key === getKey(selectedOption) && "selected";
    const status = getStatus ? getStatus(option) : "";

    if (type) {
      return (
        <MenuItem
          key={key}
          className={`item custom_dropdown-item ${status} ${selected}`}
          onClick={() => onClick(option)}
        >
          {label}
          {type && <MenuItemValue>&nbsp;{type}</MenuItemValue>}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        key={key}
        className={`item custom_dropdown-item ${status} ${selected}`}
        onClick={() => onClick(option)}
      >
        {label}
      </MenuItem>
    );
  });

  //   <DropdownMenuLabel
  //   key={key}
  //   style={{ cursor: "pointer" }}
  //   className="custom_dropdown-label"
  //   onClick={() => onClick(option)}
  // >
  //   {label}
  // </DropdownMenuLabel>

  return (
    <SelectWrapper className="custom_dropdown-wrapper" ref={ref} open={open}>
      {label && <Label className="custom_dropdown-label">{label}</Label>}
      <Select
        className={`custom_dropdown ${open && "visible active"}`}
        onClick={() => {
          setOpen(!open);
          onToggle && onToggle(true);
        }}
        open={open && "visible active"}
      >
        <i className="fa fa-angle-down"></i>
        <Text className="custom_dropdown-text">
          {getLabel(selectedOption)}
          {/* <span>&nbsp;{getType(selectedOption)}</span> */}
        </Text>
        <DropdownMenu className="custom_dropdown-menu" open={open && "visible"}>
          {menuLabel && (
            <DropdownMenuLabel className="custom_dropdown-label">
              {menuLabel}
            </DropdownMenuLabel>
          )}
          <DropdownMenuItemList className="custom_dropdown-menu-list">
            {renderedOptions}
          </DropdownMenuItemList>
        </DropdownMenu>
      </Select>
    </SelectWrapper>
  );
};

import React, { useEffect } from "react";
import { useDispatch, connect } from "react-redux";

import { setFormFieldValue } from "components/RCA/actions";
import {
  formFieldValueSelector,
  allConcernsSelector,
} from "components/RCA/selectors";

import { FloatingLabel } from "components/common/FloatingLabel";

import { last } from "lodash";

const TextField = ({ field, content, readOnly }) => {
  const dispatch = useDispatch();

  const fieldKey = field.field_type.toLowerCase();

  const concernStates = ["concern_current_state", "concern_desired_state"];

  const onChange = (value) => {
    if (!concernStates.includes(fieldKey)) {
      dispatch(setFormFieldValue(fieldKey, value));
      return;
    }
    // REVIEW: Why set it to null?
    if (!value) {
      dispatch(setFormFieldValue(fieldKey, null));
    }
    if (
      PERCENTAGE_RGX.test(value) &&
      parseFloat(value) <= 100.0 &&
      value.replace("%", "").length <= 6
    ) {
      dispatch(setFormFieldValue(fieldKey, value));
    }
  };

  useEffect(() => {
    if (fieldKey === "concern_current_state" && readOnly) {
      const currentState = last(content.split(" "));
      dispatch(setFormFieldValue(fieldKey, parseFloat(currentState)));
    }
  }, []);

  return (
    <>
      <FloatingLabel
        key={field.id}
        label={field.placeholder}
        value={fieldKey === "owner" ? content?.full_name : content}
        onChange={onChange}
        placeholder={field.placeholder}
        readOnly={readOnly}
        disabled={readOnly}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  let content, readOnly;
  const fieldKey = ownProps.field.field_type.toLowerCase();
  if (fieldKey === "concern_current_state") {
    const allConcerns = allConcernsSelector(state)?.flatMap((concern) => [
      concern,
      ...concern.children,
    ]);
    const selectedConcern = formFieldValueSelector(state, "concern") || null;
    const selectedConcernInfo = allConcerns.find(
      (concern) => concern.id === selectedConcern?.id,
    );
    [content, readOnly] = selectedConcernInfo
      ? [
          selectedConcernInfo.name + " - " + selectedConcernInfo.percentage,
          true,
        ]
      : [formFieldValueSelector(state, fieldKey) || "", false];
  } else {
    [content, readOnly] = [
      formFieldValueSelector(state, fieldKey) || "",
      fieldKey === "owner",
    ];
  }
  return { content, readOnly };
};

export default connect(mapStateToProps)(TextField);

// const PERCENTAGE_RGX = /\d+%?$/;
const PERCENTAGE_RGX =
  /(^100(\.0{0,2})?(%)?$)|(^([1-9]([0-9])?|0)(\.[0-9]{0,2})?(%)?$)/;

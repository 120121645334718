import styled, { css } from "styled-components";
import { Button, Dropdown } from "react-bootstrap";
import { DropdownContainer } from "components/common/DropdownContainer";

export const ActionButton = styled(Button)`
  border-color: transparent;
  background: #ccc;
  color: #fff;
  border-radius: 0;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &.apply {
    background-color: #89c120;
    border-color: #89c120;
  }

  &.reset {
    margin-right: auto;
  }
`;

export const ActionContainer = styled.div`
  margin-left: auto;
  padding: 0.833em;
  text-align: right;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const Label = styled.label`
  display: inline-block;
  text-transform: none;
  padding: 0 1em 0 0;
  cursor: pointer;
  color: #707070;
  font-size: 0.9em;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 33px;
  height: 15px;
  border-radius: 15px;
  background: #efefef;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 0;
    background: #adadad;
    transition: 0.2s;
    margin-top: -2px;
    bottom: 0;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 33px;
  height: 14px;

  &:checked + ${CheckBoxLabel} {
    background: rgba(137, 193, 32, 0.4);

    &::after {
      margin-left: 15px;
      background: #89c120;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding: 0.833em;
`;

export const DayPickerItem = styled.span`
  background-color: ${(props) =>
    props.isSelected ? "var(--primary-color)" : "transparent"};
  padding: 6px;
  border-radius: 50%;
  height: 22px;
  width: 22px;
  color: ${(props) => (props.isSelected ? "#fff" : "#707070")};
  display: inline-block;
  line-height: 0.8;
  text-align: center;
  font-size: 12px;

  &:hover {
    background-color: var(--primary-color);
    color: #fff;
  }
`;

export const DayPickerLabel = styled(Label)`
  text-align: left;
  display: block;
  margin-bottom: 0px;
`;

export const DayPickerContainer = styled.div`
  text-align: center;
  border-bottom: 1px solid #efefef;
  padding: 0 1.6em;
`;

export const DayPickerItemsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.8rem 0rem;
`;

export const DatePickerLabel = styled(Label)``;

export const DatePickerWrapper = styled.div`
  margin: 0 0.5rem;
`;

export const TimePickerContainer = styled(DropdownContainer)`
  margin: 0 auto;
  padding: 0.833em;
  display: flex;
  align-items: center;
  justify-content: space-around;

  ${DatePickerLabel} {
    margin-bottom: 0.8em;
  }

  .custom_dropdown-wrapper {
    width: 100px;
    min-width: 5em;

    .custom_dropdown {
      min-width: 100%;
      padding: 0.78571429em 1em 0.78571429em 1em;
      background-color: #eee;
      border-color: #eee;
      ${(props) => props.switchedOn && "border-color: #707070;"}

      i {
        display: none;
      }

      &-text {
        color: #707070;
      }

      &-text::after {
        display: none;
      }

      &-menu {
        max-height: 200px;
        margin: 13px 0 20px;
      }

      &-menu-list {
        max-height: 165px;
      }

      &[open] {
        border-color: var(--primary-color);
        background: #fff;

        .custom_dropdown-text {
          color: var(--primary-color);
        }
      }
    }
  }
`;

export const ShiftFilterContainer = styled.div``;

export const BootDropdownMenu = styled(Dropdown.Menu)`
  font-size: 13px;
  padding: 0;
  z-index: 9;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  box-shadow: 0 -6px 15px 5px rgba(34, 36, 38, 0.15);
  margin: 5px 0 !important;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 10px solid #fff;
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    position: absolute;
    left: 20px;
    right: auto;
    top: -20px;
    z-index: -1;
  }
`;

export const BootDropdownToogle = styled(Dropdown.Toggle)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: 1px solid var(--primary-color) !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: var(--primary-color) !important;
  }
`;

export const BootDropdown = styled(Dropdown)`
  &:hover,
  &:focus,
  &:focus-within,
  &:visited,
  &:active {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
`;

import { ConcernDetailApi, getConcernListApi } from "./apis";
import {
  FETCHING_KPI_DETAIL,
  KPI_DETAIL_SUCCESS,
  SELECTED_KPI_UPDATE,
  SELECTED_CHILD_CONCERN,
  FETCHING_INSIGHTS,
  INSIGHTS_SUCCESS,
  INSIGHTS_FAILURE,
  CLEAR_INSIGHTS,
  SET_FEEDBACK_LEVEL_FILTER,
  SET_LIKES_FILTER,
} from "../../../constants/constants";

export const successInsights = (concernList) => ({
  type: INSIGHTS_SUCCESS,
  concernList,
});

export const failureInsight = (err) => ({
  type: INSIGHTS_FAILURE,
  err,
});

export const fetchingInsights = () => ({ type: FETCHING_INSIGHTS });

const _clear = () => ({ type: CLEAR_INSIGHTS });

export const getInsights = (id, filtersQuery) => {
  return (dispatch) => {
    dispatch(fetchingInsights());

    getConcernListApi(id, filtersQuery)
      .then((res) => {
        dispatch(successInsights(res.data));
        if (res.data.length) {
          let concern = res.data[0];
          dispatch(updateSelectedKpi(concern));
          dispatch(fetchConcernDetail(concern.id, id, filtersQuery));
          return concern;
        }
      })
      .catch((err) => dispatch(failureInsight(err)));
  };
};

export const clearInsights = () => (dispatch) => dispatch(_clear());

export const setFeedbackLevelFilter = (value) => (dispatch) =>
  dispatch({ type: SET_FEEDBACK_LEVEL_FILTER, value });

export const setLikesFilters = (value) => (dispatch) =>
  dispatch({ type: SET_LIKES_FILTER, value });

export const fetchConcernDetail = (concernId) => ({
  type: FETCHING_KPI_DETAIL,
  concernId,
});

export const successConcernDetail = (detail) => ({
  type: KPI_DETAIL_SUCCESS,
  detail,
});

// export const fetchConcernDetail = (ConcernId, organizationId, filtersQuery) => {
//   return dispatch => {
//     dispatch(fetchConcernDetail());

//     ConcernDetailApi(ConcernId, organizationId, filtersQuery)
//       .then(res => dispatch(_successConcernDetail(res.data)))
//       .catch(err => console.log(err))
//   }
// };

export const updateSelectedKpi = (kpi) => {
  return {
    type: SELECTED_KPI_UPDATE,
    kpi,
  };
};

export const updateSelectedChildConcern = (concern) => {
  return {
    type: SELECTED_CHILD_CONCERN,
    concern,
  };
};

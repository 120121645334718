import * as constants from "constants/constants";

export const loadRCAProjectList = () => {
  return {
    type: constants.LOAD_RCA_PROJECT_LIST,
  };
};
export const loadRCAProjectListSuccess = (projects) => {
  return {
    type: constants.LOAD_RCA_PROJECT_LIST_SUCCESS,
    payload: projects,
  };
};

export const loadRCAProjectListError = (error) => {
  return {
    type: constants.LOAD_RCA_PROJECT_LIST_ERROR,
    payload: error,
  };
};

export const loadRCAProject = (templateId) => {
  return {
    type: constants.LOAD_RCA_PROJECT,
    templateId,
  };
};

export const loadRCAProjectSuccess = (form, owner) => {
  return {
    type: constants.LOAD_RCA_PROJECT_SUCCESS,
    payload: form,
    owner,
  };
};

export const loadRCAProjectError = (error) => {
  return {
    type: constants.LOAD_RCA_PROJECT_ERROR,
    payload: error,
  };
};

export const loadRCAProjectData = (id) => {
  return {
    type: constants.LOAD_RCA_PROJECT_DATA,
    payload: { id },
  };
};

export const loadRCAProjectDataSuccess = (data) => {
  return {
    type: constants.LOAD_RCA_PROJECT_DATA_SUCCESS,
    payload: data,
  };
};

export const loadRCAProjectDataError = (error) => {
  return {
    type: constants.LOAD_RCA_PROJECT_DATA_ERROR,
    payload: error,
  };
};

export const selectRCAProject = (projectId) => {
  return {
    type: constants.SELECT_RCA_PROJECT,
    payload: projectId,
  };
};

export const startNewProject = () => {
  return {
    type: constants.START_NEW_PROJECT,
  };
};

export const viewProjectList = () => {
  return {
    type: constants.VIEW_PROJECT_LIST,
  };
};

export const setFormFieldValue = (key, value) => {
  return {
    type: constants.SET_FORM_FIELD_VALUE,
    payload: { key, value },
  };
};

export const setRCAOptionsFieldValue = (id, content) => {
  return {
    type: constants.SET_RCA_OPTIONS_FIELD_VALUE,
    payload: { id, content },
  };
};

export const saveRCAForm = () => {
  return {
    type: constants.SAVE_RCA_FORM,
  };
};

export const switchRCAView = () => {
  return {
    type: constants.SWITCH_RCA_VIEW,
  };
};

export const deleteRCAProject = () => {
  return {
    type: constants.DELETE_RCA_PROJECT,
  };
};

export const toggleMode = (mode) => {
  return {
    type: constants.TOGGLE_MODE,
    mode,
  };
};

export const saveRCAFormSuccess = () => {
  return {
    type: constants.SAVE_RCA_FORM_SUCCESS,
  };
};

export const saveRCAFormError = (error) => {
  return {
    type: constants.SAVE_RCA_FORM_ERROR,
    payload: error,
  };
};

export const loadTeamLeads = () => {
  return {
    type: constants.LOAD_TEAM_LEADS,
  };
};

export const loadTeamLeadsSuccess = (teamLeads) => {
  return {
    type: constants.LOAD_TEAM_LEADS_SUCCESS,
    teamLeads,
  };
};

export const loadTeamMembers = (teamLeadID) => {
  return {
    type: constants.LOAD_TEAM_MEMBERS,
    teamLeadID,
  };
};

export const loadTeamMembersSuccess = (teamMembers, userId) => {
  return {
    type: constants.LOAD_TEAM_MEMBERS_SUCCESS,
    teamMembers,
    userId,
  };
};

export const loadConcern = () => {
  return {
    type: constants.LOAD_CONCERN,
  };
};

export const loadConcernSuccess = (concerns) => {
  return {
    type: constants.LOAD_CONCERN_SUCCESS,
    concerns,
  };
};

export const loadLevels = () => {
  return {
    type: constants.LOAD_LEVELS,
  };
};

export const loadLevelsSuccess = (levels) => {
  return {
    type: constants.LOAD_LEVELS_SUCCESS,
    levels,
  };
};

export const loadUsers = () => {
  return {
    type: constants.LOAD_USERS,
  };
};

export const loadUsersSuccess = (users) => {
  return {
    type: constants.LOAD_USERS_SUCCESS,
    users,
  };
};

export const setLocationMatchError = (locationError) => {
  return {
    type: constants.SET_LOCATION_MATCH_ERROR,
    locationError,
  };
};

export const loadConcernAnalysis = () => {
  return {
    type: constants.LOAD_CONCERN_ANALYSIS,
  };
};

export const loadConcernAnalysisSuccess = (payload) => {
  return {
    type: constants.LOAD_CONCERN_ANALYSIS_SUCCESS,
    payload,
  };
};

export const loadConcernAnalysisError = () => {
  return {
    type: constants.LOAD_CONCERN_ANALYSIS_ERROR,
  };
};

export const setTeamMembersEmpty = () => {
  return {
    type: constants.SET_TEAM_MEMBERS_EMPTY,
  };
};

export const verifyValidLocation = (location) => {
  return {
    type: constants.VERIFY_VALID_LOCATION,
    location,
  };
};

export const updateProjectListPage = (page) => {
  return {
    type: constants.UPDATE_PROJECT_LIST_PAGE,
    page,
  };
};

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import GenericFilter from "components/common/GenericFilter/GenericFilter";
import * as analytics from "utils/analytics";
import {
  storeFilterSet,
  setGenericFilters,
  resetGenericFilters,
} from "components/DashboardPage/actions";

import { ReactComponent as LocationFilterIcon } from "assets/svg/location_filter.svg";
import { loadDivisions } from "components/common/filters/actions";

const LocationFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tooltip, setTooltip] = useState(t("Loading"));

  const permissions = useSelector((state) => state.auth.user.permissions);
  const selectedFilters = useSelector((state) => state.selectedFilters);

  useEffect(() => {
    dispatch(loadDivisions());
  }, [selectedFilters.questionnaires]);

  const divisions = useSelector((state) => state.divisions.divisions);

  useEffect(() => {
    const filterSet = createDivisionFilterSet(t, divisions);
    const tooltip = createTooltipText(filterSet);
    dispatch(storeFilterSet(filterSet));
    setTooltip(tooltip);
  }, [divisions]);

  const filterSet =
    useSelector(
      (state) => state.dashboard.filterSets[t(LocationFilterSet.title)],
    ) || LocationFilterSet;

  if (!permissions[LOCATION_FILTER_PERMISSION]) {
    return null;
  }

  return (
    <GenericFilter
      tooltip={tooltip}
      id="location-filter"
      filterSet={filterSet}
      Icon={LocationFilterIcon}
      selectedFilters={selectedFilters}
      applyFilter={(selectedFilters) => {
        analytics.logFilterChange("Division", selectedFilters.locations, true);
        dispatch(setGenericFilters(selectedFilters));
      }}
      resetFilter={(keys) => dispatch(resetGenericFilters(keys))}
    />
  );
};

export default LocationFilter;

export const createDivisionFilterSet = (t, divisions) => {
  return {
    title: t("locationFilter"),
    filters: (divisions || []).map((division) => {
      return {
        key: "locations",
        title: division.name,
        tags: division.divisions.map((tag) => {
          return {
            key: `${division.level}:${tag.title}`,
            title: tag.title,
            groups: tag.groups,
          };
        }),
      };
    }),
  };
};

const createTooltipText = (filterSet) => {
  if (!filterSet) return "Filter for Location";

  const text = filterSet.filters.reduce(
    (text, filter) => text + `${filter.title}, `,
    "Filter for ",
  );
  return text.slice(0, text.length - 2);
};

const LOCATION_FILTER_PERMISSION = "filter_location";

const LocationFilterSet = {
  title: "locationFilter",
  filters: [{ key: "locations", title: "Loading...", tags: [] }],
};

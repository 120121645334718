import React from "react";
import * as analytics from "utils/analytics";

const HelpComponent = ({ colors }) => {
  const countClicks = (e) => {
    analytics.logHelpButtonClick();
  };

  return (
    <div
      className="help"
      style={{
        background: colors && colors.primary,
      }}
      onClick={countClicks}
    >
      <a
        style={{ color: colors && colors.text }}
        href="https://sentimeter.atlassian.net/servicedesk/customer/portal/1/create/1"
        target="_blank"
        rel="noopener noreferrer"
      >
        Help?
      </a>
    </div>
  );
};

export default HelpComponent;

import styled, { css } from "styled-components";
import AddCause from "assets/img/add-cause.png";

export const ToolTip = styled.span`
  background-color: #133b7d;
  padding: 5px 4px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 10px;
  color: var(--text-color);
  position: absolute;
  bottom: -30%;
  max-width: 135px;
  right: 0;
  display: none;
  box-shadow: #00000014 0px 3px 6px;
  &::after {
    content: "◀";
    position: absolute;
    bottom: 15%;
    left: -7%;
    font-size: 17px;
    color: #133b7d;
  }
`;

export const NextButton = styled.button`
  border: 1px solid ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#133b7d")};
  color: ${(props) => (props.disabled ? "#313131" : "var(--text-color)")};
  font-size: 15px;
  padding: 8px 48px;
  margin-bottom: 45px;
  font-size: 15px;
  margin-top: 50px;
  &:hover {
    background-color: ${(props) => (props.disabled ? "#e9e9e9" : "#06265b")};
  }
  &:hover ${ToolTip} {
    display: block;
    bottom: 5%;
    transition: 0.5s ease-in-out right;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;

export const Container = styled.div`
  margin-left: 58px;
`;

export const SubCauseDiv = styled.div`
  margin-left: ${(props) => 105 * props.marginLeft}px;
`;

export const Input = styled.input`
  width: calc(100% - ${(props) => props.marginLeft + "px"});
  border: 1px solid #e9e9e9;
  border-radius: 3px;
  color: #505050;
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  height: ${({ height }) => (height ? height : 46)}px;
  background-image: url(${AddCause});
  background-repeat: no-repeat;
  background-position: 16px 50%;
  background-color: var(--text-color);
  padding: 0 52px;
  margin-bottom: 20px;
  &:hover {
    border-color: rgb(179, 179, 179);
  }
  ${(props) =>
    props.placeholder &&
    css`
      ::placeholder {
        color: #d3d3d3;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
      }
    `}
`;

export const Span = styled.span`
  font-size: 16px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #242424;
  font-weight: 700;
`;

export const Para = styled.p`
  margin: 20px 0 20px 32px;
  font-size: 16px;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  color: #242424;
  font-weight: 100;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 0 83px;
  background-color: var(--text-color);
  @media screen and (max-width: 1200px) {
    padding: 0 33px;
  }
`;

import React from "react";
import { Col, Row } from "react-bootstrap";

import { Analyzers } from "components/DashboardPage/analyzers";
import AnalyzerComponent from "components/DashboardPage/Analyzer/Analyzer";

export const AnalyzerRows = ({ analyzers }) => {
  return (
    <Row>
      {analyzers.map((analyzer) => {
        const Analyzer = Analyzers[analyzer.name]
          ? Analyzers[analyzer.name].component
          : AnalyzerComponent;

        return (
          <>
            <Col
              key={analyzer.slug}
              lg={analyzer.sizes.lg}
              sm={analyzer.sizes.sm}
            >
              <Analyzer analyzer={analyzer} />
            </Col>
            {analyzer.last_in_row && <div className="w-100 mt-4"></div>}
          </>
        );
      })}
    </Row>
  );
};

export default AnalyzerRows;

import React, { Component } from "react";
import i18n from "i18n";
import CSSTransitionGroup from "react-transition-group/CSSTransition";
import { Alert, Button, Form, FormControl, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "react-spinkit";

import { getOrganization } from "../actions";
import { forgotPasswordRequest } from "../../api/forgotpassword";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      requestError: false,
      requestSuccess: false,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
  }

  onChangeHandler(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmitHandler(e) {
    e.preventDefault();

    function successCallback(res) {
      this.setState({ requestSuccess: true });
    }

    function errorCallback(err) {
      this.setState({ requestError: true });
      // console.log(err);
    }

    forgotPasswordRequest(this.state.email).then(
      successCallback.bind(this),
      errorCallback.bind(this),
    );
  }

  render() {
    const { organization, verifyingOrganization } = this.props;

    if (
      organization === {} ||
      verifyingOrganization ||
      typeof organization === "undefined"
    )
      return <Spinner name="wandering-cubes" fadeIn="none" />;

    const colors = organization.features.colors,
      logoFeatures = organization.features.dashboard.logo,
      logo = organization.logo_landscape;

    return (
      <div id="forgot-password">
        <div className="forgot-password-holder">
          <CSSTransitionGroup
            classNames="animated"
            appear={true}
            timeout={{
              appear: 500,
              enter: 300,
              exit: 300,
            }}
            enter={false}
            exit={false}
          >
            <div className="inner cover">
              <div className="logo">
                <img
                  src={logo}
                  style={{ width: `${logoFeatures.login}%` }}
                  alt=""
                />
              </div>

              {this.state.requestError ? (
                <CSSTransitionGroup
                  transitionName="animated"
                  transitionAppear={true}
                  transitionAppearTimeout={500}
                  transitionEnterTimeout={300}
                  transitionLeaveTimeout={300}
                  transitionEnter={false}
                  transitionLeave={false}
                >
                  <Alert variant="danger">
                    <p>{i18n.t("wrongEmail")}</p>
                  </Alert>
                </CSSTransitionGroup>
              ) : null}
              {this.state.requestSuccess ? (
                <CSSTransitionGroup
                  classNames="animated"
                  appear={true}
                  timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 300,
                  }}
                  enter={false}
                  exit={false}
                >
                  <Alert variant="success">
                    {i18n.t("emailSent")}
                    <Link to="/">{i18n.t("goToHomePage")}</Link>
                  </Alert>
                </CSSTransitionGroup>
              ) : null}

              <Form onSubmit={this.onSubmitHandler}>
                <FormGroup>
                  <i
                    className="fa fa-user-circle-o"
                    style={{
                      color: colors.primary,
                    }}
                  />
                  <FormControl
                    type="email"
                    name="email"
                    placeholder="Email"
                    className="input-1"
                    onChange={this.onChangeHandler}
                  />
                </FormGroup>

                <FormGroup>
                  <i
                    className="fa fa-unlock"
                    style={{
                      color: colors.primary,
                    }}
                  />
                </FormGroup>

                <Button
                  type="submit"
                  className="button-1"
                  bsPrefix="btn-danger btn-lg btn-block"
                  style={{
                    backgroundColor: colors.primary,
                    color: colors.secondary,
                  }}
                >
                  {i18n.t("reset")}
                </Button>
              </Form>
            </div>
          </CSSTransitionGroup>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  organization: state.organization.organization,
  verifyingOrganization: state.organization.verifyingOrganization,
});

export default connect(mapStateToProps, { getOrganization })(ForgotPassword);

import React, { useState, useEffect, useRef } from "react";
import { FloatingLabel } from "components/common/FloatingLabel";
import {
  Container,
  DropdownContainer,
  DropdownMenu,
  SubDropdownContainer,
  SubDropdownMenu,
  ItemText,
} from "./styled";

import { ToolTip } from "components/common/FloatingLabel/styled";

const CascadedSelect = ({
  options = [],
  onSelect,
  selectedOption,
  disabled,
  field,
  toolTip,
}) => {
  const [isOpen, setOpen] = useState(false);

  const ref = useRef();
  useEffect(() => {
    const onBodyClick = (event) => {
      if (!ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, []);

  return (
    <Container ref={ref} onClick={() => setOpen(!isOpen)}>
      <FloatingLabel
        key={field.id}
        label={field.name}
        value={selectedOption?.name || ""}
        placeholder={field.placeholder}
        readOnly={true}
        disabled={disabled}
      >
        {disabled && <ToolTip>{toolTip}</ToolTip>}
      </FloatingLabel>
      {isOpen && !disabled && (
        <DropdownContainer>
          {options.map((option) => {
            const hasChildren = option.nested?.length;
            return (
              <Option
                option={option}
                onSelect={onSelect}
                hasChildren={hasChildren}
              />
            );
          })}
        </DropdownContainer>
      )}
    </Container>
  );
};
const Option = ({ option, onSelect, hasChildren }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <DropdownMenu
      onClick={() => !hasChildren && onSelect(option)}
      onMouseEnter={() => hasChildren && setOpen(true)}
      onMouseLeave={() => hasChildren && setOpen(false)}
    >
      <ItemText hasChildren={hasChildren}>
        {option.name + (option.percentage ? " - " + option.percentage : "")}
      </ItemText>
      {isOpen && (
        <SubDropdownContainer>
          {option.nested.map((subOption) => {
            return (
              <SubDropdownMenu
                key={subOption.id}
                onClick={() => onSelect(subOption)}
              >
                <ItemText>
                  {subOption.name + " - " + subOption.percentage}
                </ItemText>
              </SubDropdownMenu>
            );
          })}
        </SubDropdownContainer>
      )}
    </DropdownMenu>
  );
};
export default CascadedSelect;

import { FeedbackAction } from "constants/constants";
const getCounterClassName = (feedback) => {
  if (feedback.score <= 6) {
    return "red";
  } else if (feedback.score === 7 || feedback.score === 8) {
    return "orange";
  } else {
    return "green";
  }
};

const getActionIcon = (type) => {
  if (type === FeedbackAction.RECOVERED) {
    return "check";
  }
  if (type === FeedbackAction.UNRECOVERABLE) {
    return "ban";
  }
  if (type === FeedbackAction.UNPROCESSED) {
    return "times";
  }
  if (type === FeedbackAction.NOT_RECOVERED) {
    return "times";
  }
  if (type === FeedbackAction.IN_PROGRESS) {
    return "times";
  }
};

export { getCounterClassName, getActionIcon };

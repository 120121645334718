import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { unauthUser } from "components/LoginPage/actions";
import { removeAuthLocalStorage } from "components/LoginPage/saga";
import { getOrganizationSlug, guessTimezone } from "utils/genericHelper";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// axios default settings
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const getRequest = (url, withoutHeaders, cancelToken) => {
  const authHeader = getAuthHeader();
  return axios
    .get(
      addTimezone(url),
      withoutHeaders
        ? {}
        : {
            cancelToken,
            headers: {
              ...authHeader,
            },
          },
    )
    .then(
      (resp) => {
        return resp.data;
      },
      (err) => {
        throw err;
      },
    );
};

export const postRequest = (url, params) => {
  const authHeader = getAuthHeader();
  return axios
    .post(addTimezone(url), params, {
      headers: {
        ...authHeader,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => err.response);
};

export const putRequest = (url, params) => {
  const authHeader = getAuthHeader();
  return axios
    .put(addTimezone(url), params, {
      headers: {
        ...authHeader,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => err.response);
};

export const axiosPutRequest = (url, params) => {
  const authHeader = getAuthHeader();
  return axios.put(addTimezone(url), params, {
    headers: {
      ...authHeader,
    },
  });
};

export const patchRequest = (url, params) => {
  const authHeader = getAuthHeader();
  return axios
    .patch(addTimezone(url), params, {
      headers: {
        ...authHeader,
      },
    })
    .then((resp) => resp.data)
    .catch((err) => err.response);
};

export const deleteRequest = (url) => {
  const authHeader = getAuthHeader();
  return axios
    .delete(addTimezone(url), {
      headers: {
        ...authHeader,
      },
    })
    .then(
      (resp) => {
        return resp;
      },
      (err) => {
        throw err.response.data;
      },
    );
};

const getAuthHeader = () => {
  const JWT = localStorage.getItem(`${getOrganizationSlug()}_token`);

  return JWT?.length
    ? {
        Authorization: `JWT ${JWT}`,
      }
    : {};
};

const addTimezone = (url) => {
  if (url.includes("?")) {
    return `${url}&tz=${guessTimezone()}`;
  }
  return `${url}?tz=${guessTimezone()}`;
};

export const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response?.status === 401) {
        dispatch(unauthUser());
        removeAuthLocalStorage();
      } else {
        return Promise.reject(error);
      }
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor,
    );

    setIsSet(true);
    return () => axios.interceptors.response.eject(interceptor);
  }, []);
  return isSet && children;
};
